import {
  ExportDropdownOption,
  PortfolioExposureWidgetProps,
  TableSettings,
} from '../../../types';

export const exposureWidgetInvestmentsBySectorSettings = (
  zipFileName: string
): TableSettings => {
  return {
    fileName: zipFileName,
    name: 'Sector',
    exportId: 'sector',
    columnVisibility: {},
    headerMapping: [
      {
        columnId: 'category',
        label: 'Sector',
      },
      {
        columnId: 'count',
        label: 'Count',
      },
      {
        columnId: 'cost',
        label: 'Cost',
      },
      {
        columnId: 'valuation',
        label: 'Valuation',
      },
    ],
    columnOrder: ['category', 'count', 'cost', 'valuation'],
    meta: {
      columns: {
        category: {
          styling: {
            align: 'right',
          },
        },
        cost: {
          styling: {
            align: 'right',
          },
        },
        count: {
          styling: {
            align: 'right',
          },
        },
        valuation: {
          styling: {
            align: 'right',
          },
        },
      },
    },
  };
};

export const exposureWidgetInvestmentsByIndustrySettings = (
  zipFileName: string
): TableSettings => {
  return {
    fileName: zipFileName,
    name: 'Industry',
    exportId: 'industry',
    columnVisibility: {},
    headerMapping: [
      {
        columnId: 'category',
        label: 'Industry',
      },
      {
        columnId: 'count',
        label: 'Count',
      },
      {
        columnId: 'cost',
        label: 'Cost',
      },
      {
        columnId: 'valuation',
        label: 'Valuation',
      },
    ],
    columnOrder: ['category', 'count', 'cost', 'valuation'],
    meta: {...exposureWidgetInvestmentsBySectorSettings(zipFileName).meta},
  };
};

export const exposureWidgetInvestmentsByInstrumentSettings = (
  zipFileName: string
): TableSettings => {
  return {
    fileName: zipFileName,
    name: 'Instrument type',
    exportId: 'instrument-type',
    columnVisibility: {},
    headerMapping: [
      {
        columnId: 'category',
        label: 'Instrument',
      },
      {
        columnId: 'count',
        label: 'Count',
      },
      {
        columnId: 'cost',
        label: 'Cost',
      },
      {
        columnId: 'valuation',
        label: 'Valuation',
      },
    ],
    columnOrder: ['category', 'count', 'cost', 'valuation'],
    meta: {...exposureWidgetInvestmentsBySectorSettings(zipFileName).meta},
  };
};

export const exsposureWidgetImagesExportPayload = (
  value: string,
  zipFileName: string
) => {
  return {
    id: `exposure-chart-${value}`,
    fileName: zipFileName,
    opts: [
      {
        fileName: `${zipFileName}_TransparentBackground`,
        type: 'image/svg+xml',
        ext: 'svg',
        bgColor: 'transparent',
        elsToFilter: ['exposure-chart-radio-buttons'],
      },
      {
        fileName: `${zipFileName}_WithBackground`,
        type: 'image/svg+xml',
        ext: 'svg',
        bgColor: 'black',
        elsToFilter: ['exposure-chart-radio-buttons'],
      },
      {
        fileName: `${zipFileName}_TransparentBackground`,
        type: 'image/png',
        ext: 'png',
        bgColor: 'transparent',
        elsToFilter: ['exposure-chart-radio-buttons'],
      },
      {
        fileName: `${zipFileName}_WithBackground`,
        type: 'image/png',
        ext: 'png',
        bgColor: 'black',
        elsToFilter: ['exposure-chart-radio-buttons'],
      },
    ],
  };
};

const generateDataForExport = (
  widgets: PortfolioExposureWidgetProps[],
  option: ExportDropdownOption
) => {
  const selectedPortfolioExposureWidget = widgets
    .filter(widget => widget.exportId === option.value)
    .map(widget => widget.data);
  return selectedPortfolioExposureWidget[0];
};

export const exsposureWidgetTableExportPayload = (
  widgets: PortfolioExposureWidgetProps[],
  option: ExportDropdownOption,
  zipFileName: string
) => {
  return [
    {
      data: generateDataForExport(widgets, option),
      mappings:
        option.value ===
        exposureWidgetInvestmentsBySectorSettings(zipFileName).exportId
          ? exposureWidgetInvestmentsBySectorSettings(zipFileName).headerMapping
          : option.value ===
            exposureWidgetInvestmentsByIndustrySettings(zipFileName).exportId
          ? exposureWidgetInvestmentsByIndustrySettings(zipFileName)
              .headerMapping
          : option.value ===
            exposureWidgetInvestmentsByInstrumentSettings(zipFileName).exportId
          ? exposureWidgetInvestmentsByInstrumentSettings(zipFileName)
              .headerMapping
          : [],
      settings:
        option.value ===
        exposureWidgetInvestmentsBySectorSettings(zipFileName).exportId
          ? exposureWidgetInvestmentsBySectorSettings(zipFileName)
          : option.value ===
            exposureWidgetInvestmentsByIndustrySettings(zipFileName).exportId
          ? exposureWidgetInvestmentsByIndustrySettings(zipFileName)
          : option.value ===
            exposureWidgetInvestmentsByInstrumentSettings(zipFileName).exportId
          ? exposureWidgetInvestmentsByInstrumentSettings(zipFileName)
          : [],
    },
  ];
};

export const investmentFocusExportOptions: ExportDropdownOption[] = [
  {
    label: 'Global.Sector',
    value: 'sector',
  },
  {
    label: 'Global.Industry',
    value: 'industry',
  },
  {
    label: 'Global.InstrumentType',
    value: 'instrument-type',
  },
];
