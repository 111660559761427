import React, {ChangeEvent, useMemo, useRef, useState, useEffect} from 'react';
import classnames from 'classnames';
import {SelectInputProps, SelectOption, StyleVariant} from '../../../types';
import {useOnOutsideClick} from '../../../hooks';
import {DropdownList, Icon, DropdownOption} from '../index';
import styles from './SelectInput.module.scss';
import {useTranslation} from 'react-i18next';
import {formatToHyphenatedString} from '../../../utils/strings';

const SelectInput: React.FC<SelectInputProps> = props => {
  const {
    label,
    labelIconProps,
    placeholder = '',
    options = [],
    value,
    variant = StyleVariant.Primary,
    renderButton,
    className,
    errorMessage,
    validate,
    withSeparator = false,
    separatorIndex = 2,
    controllable = true,
    handleSelect,
    ...selectProps
  } = props;
  const {t} = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef<HTMLSelectElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [inputState, setInputState] = useState('');
  const [selectState, setSelectState] = useState(value);
  const [filteredOptions, setFilteredOptions] =
    useState<SelectOption[]>(options);

  const inputRef = useRef<HTMLInputElement>(null);
  const withSearch = options.length > 5;

  const currentKey = useMemo(() => {
    let currentKey: string | number = '';
    options.forEach(item => {
      if (item.value === selectState) {
        currentKey = item.label;
      }
    });
    return currentKey;
  }, [options, selectState]);

  useEffect(() => {
    if (options) {
      setFilteredOptions(options);
    }
  }, [options]);

  const handleClick = (value: string | number) => {
    setSelectState(value);
    if (selectRef.current) {
      selectRef.current.value = value.toString();
      selectRef.current.dispatchEvent(new Event('change', {bubbles: true}));
      if (validate) {
        validate({[`${selectProps.name}`]: value});
      }
    }
    handleSelect && handleSelect(value);
    setFilteredOptions(options);
    setInputState('');
    setIsOpen(false);
  };

  const handleToggleOpen = () => {
    if (withSearch && isOpen) return;
    setIsOpen(prev => !prev);
    if (withSearch) {
      setFilteredOptions(options);
      setInputState('');
      setTimeout(() => {
        inputRef.current?.focus();
      }, 100);
    }
  };

  const handleClickChevron = () => {
    if (!withSearch || !isOpen) return;
    setIsOpen(false);
  };

  const handleChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    setInputState(event.target.value);
    const regexp = new RegExp(event.target.value.trim().toLowerCase());
    const searchResult = options.filter(
      item => item.label.toString().toLowerCase().trim().search(regexp) >= 0
    );
    setFilteredOptions(searchResult);
  };

  useOnOutsideClick(wrapperRef, () => {
    setIsOpen(false);
    if (withSearch) {
      setFilteredOptions(options);
      setInputState('');
    }
  });

  useEffect(() => {
    if (controllable) {
      setSelectState(value);
    }
  }, [value, controllable]);

  return (
    <div
      ref={wrapperRef}
      className={classnames(
        styles.wrapper,
        styles[`wrapper__${variant}`],
        className,
        isOpen && styles.active
      )}
    >
      {label && (
        <div className={styles.inputLabelContainer}>
          <label className={styles.inputLabel}>
            {t(label)}
            {selectProps.required && <span className={styles.asterisk}>*</span>}
          </label>
          {labelIconProps?.labelIcon && (
            <Icon
              name={labelIconProps.labelIcon}
              className={labelIconProps.labelIconClassName}
              tooltip={labelIconProps.labelIconTooltipContent}
              tooltipPosition={labelIconProps.labelIconTooltipContentPosition}
              tooltipClasses={labelIconProps.labelIconTooltipClasses}
              hasTooltipArrow={labelIconProps.labelIconTooltipArrow}
            />
          )}
        </div>
      )}
      <select
        {...selectProps}
        onChange={selectProps.onChange}
        className={styles.select}
        ref={selectRef}
      >
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {/* If there is no matching key pass label as default value */}
            {t(option.label as string, option.label as string)}
          </option>
        ))}
      </select>
      {renderButton ? (
        renderButton({label: currentKey, handleToggleOpen, isOpen})
      ) : (
        <button
          disabled={selectProps.disabled}
          onClick={handleToggleOpen}
          className={classnames(
            styles.button,
            isOpen && styles.active,
            !currentKey && styles.isPlaceholder,
            errorMessage && styles.selectInvalid
          )}
          type="button"
          data-test={`select-value-${formatToHyphenatedString(currentKey)}`}
        >
          {withSearch && isOpen ? (
            <input
              ref={inputRef}
              type="text"
              value={inputState}
              onChange={handleChangeSearch}
              className={classnames(styles.input)}
            />
          ) : (
            <span className={styles.buttonText}>
              {t(
                currentKey || placeholder || 'SelectInput.Placeholder',
                currentKey || placeholder || 'SelectInput.Placeholder'
              )}
            </span>
          )}
          <Icon
            onClick={handleClickChevron}
            className={styles.caret}
            name={'chevron-down'}
          />
          <span className={styles.errorMessage}>{errorMessage}</span>
          <Icon
            className={classnames(styles.icon, styles['icon__danger'])}
            name={'exclamation-circle'}
          />
        </button>
      )}
      {isOpen && (
        <DropdownList>
          {filteredOptions.map((option, idx) => (
            <div
              className={(option.visible === false && styles.notVisible) || ''}
            >
              <DropdownOption
                id={option.id}
                key={idx}
                value={option.value}
                label={t(option.label as string, option.label as string)} // If there is no matching key pass label as default value
                isActive={selectState === option.value}
                handleClickOption={handleClick}
                withSeparator={
                  withSeparator &&
                  filteredOptions.length === options.length &&
                  idx === separatorIndex
                }
              />
            </div>
          ))}
        </DropdownList>
      )}
    </div>
  );
};

export default SelectInput;
