import {PayloadAction} from '@reduxjs/toolkit';
import {
  DocumentTag,
  FileAlreadyExist,
  FileItem,
  FileUploadContext,
  FileUploadProcess,
  FileUploadStatus,
  FileUploadSuccessPayload,
  UploadDocuments,
  UploadFilePayload,
  UploadOneFilePayload,
} from '../../types';
import {call, delay, put, select, take, takeEvery} from 'redux-saga/effects';
import {deleteFile, downloadFile, uploadFile} from '../../api/uploadFile';
import {acquireAuthResult, msalInstance} from '../../index';
import {
  addDocumentExplorerDownloadPendingId,
  deleteDocumentExplorerFile,
  documentExplorerCancelUploadFileAction,
  documentExplorerDeleteFileAction,
  documentExplorerDownloadFileAction,
  documentExplorerUploadFileAction,
  removeDocumentExplorerDownloadPendingId,
  setDocumentExplorerIsRequestPending,
  updateDocumentExplorerCustomTags,
  updateDocumentExplorerData,
  updateDocumentExplorerDocumentsCount,
} from '../../reducers/documentExplorerSlice';
import {
  fileAlreadyExistsAction,
  setFileAlreadyExists,
  setFileUploadData,
  updateFileUploadData,
} from '../../reducers/fileUploadSlice';
import {
  checkFileNameIsAllowed,
  checkFileTypeIsAllowed,
  generalResponseErrorResolver,
} from '../../utils';
import {setGlobalInformation} from '../../reducers/globalInformationSlice';
import {
  addUploadDocumentsDownloadPendingId,
  deleteUploadDocumentsFile,
  removeUploadDocumentsDownloadPendingId,
  setReportingPeriodsInfoFilesCount,
  setUploadDocumentsIsRequestPending,
  updateUploadDocumentsData,
  uploadDocumentsDeleteFileAction,
  uploadDocumentsDownloadFileAction,
  uploadDocumentsUploadFileAction,
} from '../../reducers/uploadDocumentsSlice';
import {isAxiosError} from 'axios';
import {
  setFundPortfolioSelectedTransactionsDeletedFilesCount,
  setFundPortfolioSelectedTransactionsUploadedFilesCount,
} from '../../reducers/fundDetailsPortfolioTransactionsSlice';
import {setReportingTableUploadedFilesCount} from '../../reducers/reportingTableSlice';
import {
  setCompanyInvestmentOverviewSelectedTransactionsUploadedFilesCount,
  setCompanyInvestmentOverviewSelectedTransactionsDeletedFilesCount,
} from '../../reducers/companyDetailsInvestmentOverview';
import {RootState} from '../../store';

let controller: AbortController;

export const fileUploadSaga = function* fileUploadSaga({
  payload,
}: PayloadAction<UploadFilePayload>) {
  controller = new AbortController();
  const {files} = payload;
  const supportedFiles = Array.from(files).filter(
    file =>
      checkFileTypeIsAllowed(file.type) && checkFileNameIsAllowed(file.name)
  );
  const filesForUpload = supportedFiles.map(file => ({
    id: `${file.name}-${Date.now()}`,
    fileName: file.name,
    fileType: file.type,
    status: FileUploadStatus.Ready,
  }));
  if (supportedFiles.length) {
    const {accessToken} = yield call(acquireAuthResult, msalInstance);
    yield put(setFileUploadData(filesForUpload));
    for (let i = 0; i < supportedFiles.length; i++) {
      const fileForUpload = filesForUpload[i];
      try {
        yield put(
          updateFileUploadData({
            ...fileForUpload,
            status: FileUploadStatus.Pending,
          })
        );

        const response: {
          fileItem: FileItem;
          customTags: Array<DocumentTag>;
          documentCount: number;
        } = yield call(
          uploadFile,
          accessToken,
          {
            ...payload,
            file: supportedFiles[i],
          },
          controller.signal
        );

        yield fileUploadSuccessSaga({
          fileUploadContext: payload.fileUploadContext,
          response,
          fileForUpload: fileForUpload,
          transactions: payload.transactions,
          columns: payload.columns,
        });
      } catch (error) {
        let status = null;
        let detail = null;

        if (isAxiosError(error)) {
          status = error?.response?.status;
          detail = error?.response?.data?.detail;
        }

        if (status === 422 && detail === 'File already exists.') {
          yield fileAlreadyExistSaga(
            {
              filePayload: {
                ...payload,
                file: supportedFiles[i],
              },
              fileForUpload,
            },
            accessToken
          );
        } else {
          yield put(
            updateFileUploadData({
              ...fileForUpload,
              status: FileUploadStatus.Failure,
            })
          );
        }
      }
    }
  }
};

export const fileAlreadyExistSaga = function* fileAlreadyExistSaga(
  payload: {
    filePayload: UploadOneFilePayload;
    fileForUpload: FileUploadProcess;
  },
  accessToken: string
) {
  controller = new AbortController();
  const {file} = payload.filePayload;
  try {
    yield put(setFileAlreadyExists(file.name));

    const action: PayloadAction<{option: string}> = yield take([
      fileAlreadyExistsAction.type,
    ]);

    const option = action.payload.option;

    if (
      option === FileAlreadyExist.ReplaceFile ||
      option === FileAlreadyExist.KeepBoth
    ) {
      const response: {
        fileItem: FileItem;
        customTags: Array<DocumentTag>;
        documentCount: number;
      } = yield call(
        uploadFile,
        accessToken,
        {
          ...payload.filePayload,
          file: file,
        },
        controller.signal,
        option
      );

      yield fileUploadSuccessSaga({
        fileUploadContext: payload.filePayload.fileUploadContext,
        response,
        fileForUpload: payload.fileForUpload,
        option,
        transactions: payload.filePayload.transactions,
        columns: payload.filePayload.columns,
      });
    } else {
      yield put(
        updateFileUploadData({
          ...payload.fileForUpload,
          status: FileUploadStatus.Failure,
        })
      );
    }
  } catch (error) {
    yield put(
      updateFileUploadData({
        ...payload.fileForUpload,
        status: FileUploadStatus.Failure,
      })
    );
  }
};

export const fileCancelUploadSaga = function* fileCancelUploadSaga() {
  controller.abort();

  yield delay(1000);
};

export const fileUploadSuccessSaga = function* fileUploadSuccessSaga({
  fileUploadContext,
  response,
  fileForUpload,
  option,
  transactions = [],
  columns = [],
}: FileUploadSuccessPayload) {
  if (fileUploadContext === FileUploadContext.UploadDocuments) {
    const data: UploadDocuments | null = yield select(
      (state: RootState) => state.uploadDocuments.data
    );
    // Do we have this document in table already
    const document = data?.data.data.some(
      doc => doc.fileName === response.fileItem.fileName
    );

    if (
      option !== FileAlreadyExist.ReplaceFile ||
      (option === FileAlreadyExist.ReplaceFile && !document)
    ) {
      if (transactions) {
        yield put(
          setFundPortfolioSelectedTransactionsUploadedFilesCount(transactions)
        );
        yield put(
          setCompanyInvestmentOverviewSelectedTransactionsUploadedFilesCount(
            transactions
          )
        );
      }

      if (columns) {
        yield put(setReportingTableUploadedFilesCount(columns));
        yield put(setReportingPeriodsInfoFilesCount(columns));
      }
    }
  }

  // If replacing, remove old file first from the store
  if (option === FileAlreadyExist.ReplaceFile) {
    if (fileUploadContext === FileUploadContext.DocumentExplorer) {
      yield put(
        deleteDocumentExplorerFile({
          deleteBy: 'fileName',
          value: fileForUpload.fileName,
        })
      );
    } else if (fileUploadContext === FileUploadContext.UploadDocuments) {
      yield put(
        deleteUploadDocumentsFile({
          deleteBy: 'fileName',
          value: fileForUpload.fileName,
        })
      );
    }
  }

  if (fileUploadContext === FileUploadContext.DocumentExplorer) {
    yield put(updateDocumentExplorerData(response.fileItem));
    yield put(updateDocumentExplorerCustomTags(response.customTags));
    yield put(updateDocumentExplorerDocumentsCount(response.documentCount));
  } else if (fileUploadContext === FileUploadContext.UploadDocuments) {
    yield put(updateUploadDocumentsData(response.fileItem));
  }

  yield put(
    updateFileUploadData({
      ...fileForUpload,
      status: FileUploadStatus.Success,
    })
  );
};

export const fileDeleteSaga = function* fileDeleteSaga({
  payload,
}: PayloadAction<{
  id: string;
  fileUploadContext: FileUploadContext;
  transactions: string[];
}>) {
  const {id, transactions} = payload;
  if (id) {
    const {accessToken} = yield call(acquireAuthResult, msalInstance);
    try {
      if (payload.fileUploadContext === FileUploadContext.DocumentExplorer) {
        yield put(setDocumentExplorerIsRequestPending(true));
        yield call(deleteFile, accessToken, id);
        yield put(deleteDocumentExplorerFile({deleteBy: 'id', value: id}));
      } else if (
        payload.fileUploadContext === FileUploadContext.UploadDocuments
      ) {
        yield put(setUploadDocumentsIsRequestPending(true));
        yield call(deleteFile, accessToken, id);
        yield put(deleteUploadDocumentsFile({deleteBy: 'id', value: id}));
        if (transactions) {
          yield put(
            setFundPortfolioSelectedTransactionsDeletedFilesCount(transactions)
          );
          yield put(
            setCompanyInvestmentOverviewSelectedTransactionsDeletedFilesCount(
              transactions
            )
          );
        }
      }
    } catch (err) {
      console.warn(err);
      const responseError = generalResponseErrorResolver(err);
      if (responseError) {
        yield put(setGlobalInformation(responseError));
      }
    } finally {
      if (payload.fileUploadContext === FileUploadContext.DocumentExplorer) {
        yield put(setDocumentExplorerIsRequestPending(false));
      } else if (
        payload.fileUploadContext === FileUploadContext.UploadDocuments
      ) {
        yield put(setUploadDocumentsIsRequestPending(false));
      }
    }
  }
};

export const fileDownloadSaga = function* fileDownloadSaga({
  payload,
}: PayloadAction<{id: string; fileUploadContext: FileUploadContext}>) {
  const {id} = payload;
  if (id) {
    const {accessToken} = yield call(acquireAuthResult, msalInstance);
    try {
      if (payload.fileUploadContext === FileUploadContext.DocumentExplorer) {
        yield put(addDocumentExplorerDownloadPendingId(id));
      } else if (
        payload.fileUploadContext === FileUploadContext.UploadDocuments
      ) {
        yield put(addUploadDocumentsDownloadPendingId(id));
      }
      yield call(downloadFile, accessToken, id);
    } catch (err) {
      console.warn(err);
      const responseError = generalResponseErrorResolver(err);
      if (responseError) {
        yield put(setGlobalInformation(responseError));
      }
    } finally {
      if (payload.fileUploadContext === FileUploadContext.DocumentExplorer) {
        yield put(removeDocumentExplorerDownloadPendingId(id));
      } else if (
        payload.fileUploadContext === FileUploadContext.UploadDocuments
      ) {
        yield put(removeUploadDocumentsDownloadPendingId(id));
      }
    }
  }
};
export const fileUploadSagaWatcher = function* fileUploadSagaWatcher() {
  yield takeEvery(documentExplorerUploadFileAction.type, fileUploadSaga);
  yield takeEvery(uploadDocumentsUploadFileAction.type, fileUploadSaga);
  yield takeEvery(documentExplorerDeleteFileAction.type, fileDeleteSaga);
  yield takeEvery(uploadDocumentsDeleteFileAction.type, fileDeleteSaga);
  yield takeEvery(documentExplorerDownloadFileAction.type, fileDownloadSaga);
  yield takeEvery(uploadDocumentsDownloadFileAction.type, fileDownloadSaga);
  yield takeEvery(
    documentExplorerCancelUploadFileAction.type,
    fileCancelUploadSaga
  );
};
