import React, {useMemo, useState} from 'react';
import styles from './ExpandableDescriptionCell.module.scss';
import {InfoHeaderType} from '../../../../../types';
import {Icon} from '../../../index';
import {useTranslation} from 'react-i18next';

const ExpandableDescriptionCell: React.FC<InfoHeaderType> = ({value}) => {
  const {t} = useTranslation();
  const [expanded, setExpanded] = useState(false);

  const expandable = ((value as string) || '').length > 255;

  const currentValue = useMemo<string>(() => {
    if (!value) return '';
    if (expanded) return value as string;
    return (value as string).slice(0, 255) + '...';
  }, [value, expanded]);

  return (
    <div className={styles.wrapper}>
      {currentValue}{' '}
      {expandable && (
        <span
          onClick={() => setExpanded(prev => !prev)}
          className={styles.expandButton}
        >
          {t(expanded ? `Global.ShowLess` : 'Global.ShowMore')}{' '}
          <Icon name={expanded ? `chevron-up` : 'chevron-down'} />
        </span>
      )}
    </div>
  );
};

export default ExpandableDescriptionCell;
