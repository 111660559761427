import {
  DateRangePresetTypes,
  GroupedSelectOption,
  MonitoringChartDateRange,
} from '../types';
import {DateRangePreset} from '../ui-components/global/date-range-picker/component/date-range-presets/DateRangePresets';
import {
  last12MonthsRange,
  lastMonthRange,
  lastQuarterRange,
  previouslySelectedRange,
} from '../utils/date-range-presets';

export const companyMonitoringChartDateRangeOptions = [
  {
    id: 'custom',
    label: 'Global.Custom',
    value: MonitoringChartDateRange.Custom,
  },
  {
    id: '6M',
    label: '6M',
    value: MonitoringChartDateRange.SixMonths,
  },
  {
    id: '1Y',
    label: '1Y',
    value: MonitoringChartDateRange.OneYear,
  },
  {
    id: '3Y',
    label: '3Y',
    value: MonitoringChartDateRange.ThreeYears,
  },
  {
    id: '5Y',
    label: '5Y',
    value: MonitoringChartDateRange.FiveYears,
  },
  {
    id: '10Y',
    label: '10Y',
    value: MonitoringChartDateRange.TenYears,
  },
];

export const metricsTableRowCountOptions: GroupedSelectOption[] = [
  {id: '20', label: 'Global.Show20', value: 20},
  {id: '50', label: 'Global.Show50', value: 50},
  {id: '100', label: 'Global.Show100', value: 100},
];

export const metricsChartDateRangePresets: DateRangePreset[] = [
  {label: DateRangePresetTypes.lastMonth, handler: lastMonthRange},
  {
    label: DateRangePresetTypes.lastQuarter,
    handler: lastQuarterRange,
  },
  {
    label: DateRangePresetTypes.last12Months,
    handler: last12MonthsRange,
  },
  {
    label: DateRangePresetTypes.custom,
    handler: previouslySelectedRange,
  },
];
