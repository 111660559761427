import React from 'react';
import styles from './QuestionnaireGroupSubHeaderRow.module.scss';
import classnames from 'classnames';

type QuestionnaireGroupSubHeaderRowProps = {
  name: string;
};

const QuestionnaireGroupSubHeaderRow = ({
  name,
}: QuestionnaireGroupSubHeaderRowProps) => {
  return (
    <div className={classnames(styles.row)}>
      <div className={classnames(styles.groupSubHeaderRowLeft)}>
        <span className={styles.groupSubHeaderCellSpan}>{name}</span>
      </div>
      <div className={classnames(styles.groupSubHeaderRowRight)}></div>
    </div>
  );
};

export default QuestionnaireGroupSubHeaderRow;
