import React from 'react';
import classnames from 'classnames';
import useBreakpoint from '../../../../../hooks/useBreakpoint';
import FundInfoFunds from '../fund-info-funds/FundInfoFunds';
import FundTabsRow from '../fund-tabs-row/FundTabsRow';
import {Icon, ValueFormat} from '../../../../global';
import {FundState, FundSummaryWidgetProps, KpiTabs} from '../../../../../types';
import {Link, useLocation} from 'react-router-dom';
import homeLayoutStyles from './FundWidgetHeader.module.scss';
import pageLayoutStyles from './FundWidgetHeaderTemp.module.scss';
import {valueFormat} from '../../../../../utils/value-format';
import {useTranslation} from 'react-i18next';

const FundWidgetHeader: React.FC<FundSummaryWidgetProps> = ({
  available,
  commitment,
  companiesCount,
  companiesLabel,
  currency,
  dpiRvpiTvpi,
  fundColor,
  fundName,
  nav,
  netIrr,
  vintage,
  clickHandler,
  activeTab = KpiTabs.Nav,
  isFundOnboarding = false,
  id,
  isDataCurrent,
  asAt,
  state,
}): JSX.Element => {
  const {t} = useTranslation();
  const {isXSmall, isSmall} = useBreakpoint();
  const {pathname} = useLocation();
  const styles = pathname === '/' ? homeLayoutStyles : pageLayoutStyles;
  return (
    <div>
      <div
        style={{
          background:
            state === FundState.Inactive
              ? 'rgb(var(--colors-gray-6))'
              : `rgb(var(--colors-${fundColor}))`,
        }}
        className={styles.headerLine}
      />
      <div className={styles.headerWrapper}>
        <div
          className={styles.fundNameRow}
          data-test="fund-summary-widget-title"
        >
          <Link to={`/funds/${id}/overview`}>
            <p className={styles.fundName}>{fundName}</p>
          </Link>
        </div>
        <div
          className={classnames(
            styles.fundInfo,
            !isDataCurrent && styles.fundInfo__dataNotCurrent
          )}
        >
          <div className={styles.year}>{vintage}</div>
          {!isFundOnboarding && (
            <div className={styles.infoWrapper}>
              <p className={styles.label}>{commitment.label}</p>
              <div className={styles.value}>
                <span>{currency}</span>
                <ValueFormat
                  value={commitment.value}
                  format={commitment.format}
                />
              </div>
            </div>
          )}
          {!isFundOnboarding && available && (
            <div className={styles.infoWrapper}>
              <p className={styles.label}>{available.label}</p>
              <div className={styles.value}>
                <span>{currency}</span>
                <ValueFormat
                  value={available.value}
                  format={available.format}
                />
              </div>
            </div>
          )}
        </div>
        {!isDataCurrent && (
          <div className={styles.asAtWrapper}>
            <div className={styles.asAt}>
              <Icon className={styles.asAtIcon} name={'warning'} />
              <div>
                {t('Global.DataBelow')}{' '}
                {valueFormat(asAt?.value, asAt?.format).value}
              </div>
            </div>
          </div>
        )}
      </div>
      {!isFundOnboarding ? (
        <FundTabsRow
          clickHandler={clickHandler}
          activeTab={activeTab}
          nav={nav}
          netIrr={netIrr}
          dpiRvpiTvpi={dpiRvpiTvpi}
          currency={currency}
          isXSmall={isXSmall}
          isSmall={isSmall}
        />
      ) : (
        <FundInfoFunds
          commitment={commitment}
          available={available}
          currency={currency}
        />
      )}
    </div>
  );
};

export default FundWidgetHeader;
