import axios from 'axios';
import config from '../config';
import {DataCollectionDetails, ReportingTableData} from '../types/';

export interface HistoricalReportingDataResponse {
  id: string;
  isHistorical: boolean;
  reportingData: ReportingTableData;
}

export const getDataCollectionDetails = async (
  token: string,
  workflowId: string
) => {
  const {data} = await axios.get<DataCollectionDetails>(
    `${config.APP_URI}/data-collection-details/${workflowId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );
  return data;
};

export const getDataCollectionHistoricalReportingData = async (
  token: string,
  workflowId: string
) => {
  const {data} = await axios.get<HistoricalReportingDataResponse>(
    `${config.APP_URI}/data-collection/reporting/history/${workflowId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );
  return data;
};
