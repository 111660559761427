import React from 'react';
import {ColumnDef, createColumnHelper} from '@tanstack/react-table';
import Th from '../cells/th/Th';
import Td from '../cells/td/Td';
import NameCell from '../cells/name-cell/NameCell';
import ExpanderCell from '../cells/expander-cell/ExpanderCell';
import {
  FlexHAlign,
  HeaderMapping,
  FundsRow,
  FundState,
} from '../../../../types/index';
import {getTableColumnTestAttr} from '../../../../utils/strings';

const genFundsTableColumnDefs = (
  headerMapping: HeaderMapping[],
  isActive: boolean
): ColumnDef<FundsRow, any>[] => {
  const columnHelper = createColumnHelper<FundsRow>();

  return [
    columnHelper.accessor('fundName', {
      id: 'fundName',
      header: props => (
        <Th
          {...props}
          headerMapping={headerMapping}
          align={FlexHAlign.start}
          width={'max(100vw*0.18, 150px)'}
        />
      ),
      cell: props => (
        <Td
          {...props}
          align={FlexHAlign.start}
          width={'320px'}
          canHide={false}
          id={getTableColumnTestAttr(headerMapping, props.column.id)}
        >
          <NameCell
            name={props?.getValue<string>()}
            color={props.row.original.fundColor}
            expanded={props.row.getIsExpanded()}
            linkTo={`/funds/${props.row.original.id}/overview`}
            isActive={props.row.original.state === FundState.Active}
          />
        </Td>
      ),
    }),
    columnHelper.accessor('currency', {
      id: 'currency',
      header: props => <Th {...props} headerMapping={headerMapping} />,
      cell: props => (
        <Td
          {...props}
          align={FlexHAlign.center}
          id={getTableColumnTestAttr(headerMapping, props.column.id)}
        />
      ),
    }),
    columnHelper.accessor(row => row.asAt?.value, {
      id: 'asAt',
      header: props => <Th {...props} headerMapping={headerMapping} />,
      cell: props => (
        <Td
          {...props}
          align={FlexHAlign.center}
          nestedAccessorFn={original => original.asAt}
          id={getTableColumnTestAttr(headerMapping, props.column.id)}
        />
      ),
    }),
    columnHelper.accessor(row => row.vintage, {
      id: 'vintage',
      header: props => <Th headerMapping={headerMapping} {...props} />,
      cell: props => (
        <Td
          {...props}
          align={FlexHAlign.center}
          id={getTableColumnTestAttr(headerMapping, props.column.id)}
        />
      ),
    }),
    columnHelper.accessor(row => row.commitment.value, {
      id: 'commitment',
      header: props => (
        <Th {...props} headerMapping={headerMapping} align={FlexHAlign.end} />
      ),
      cell: props => (
        <Td
          {...props}
          align={FlexHAlign.end}
          nestedAccessorFn={original => original.commitment}
          id={getTableColumnTestAttr(headerMapping, props.column.id)}
        />
      ),
    }),
    columnHelper.accessor(row => row.available.value, {
      id: 'available',
      header: props => (
        <Th {...props} headerMapping={headerMapping} align={FlexHAlign.end} />
      ),
      cell: props => (
        <Td
          {...props}
          align={FlexHAlign.end}
          nestedAccessorFn={original => original.available}
          id={getTableColumnTestAttr(headerMapping, props.column.id)}
        />
      ),
    }),
    columnHelper.accessor(row => row.nav?.value, {
      id: 'nav',
      header: props => (
        <Th {...props} headerMapping={headerMapping} align={FlexHAlign.end} />
      ),
      cell: props => (
        <Td
          {...props}
          align={FlexHAlign.end}
          nestedAccessorFn={original => original.nav}
          id={getTableColumnTestAttr(headerMapping, props.column.id)}
        />
      ),
    }),
    columnHelper.accessor(row => row.netIrr?.value, {
      id: 'netIrr',
      header: props => (
        <Th {...props} headerMapping={headerMapping} align={FlexHAlign.end} />
      ),
      cell: props => (
        <Td
          {...props}
          align={FlexHAlign.end}
          nestedAccessorFn={original => original.netIrr}
          id={getTableColumnTestAttr(headerMapping, props.column.id)}
        />
      ),
    }),
    columnHelper.accessor(row => row.dpiData?.value, {
      id: 'dpiData',
      header: props => (
        <Th {...props} headerMapping={headerMapping} align={FlexHAlign.end} />
      ),
      cell: props => (
        <Td
          {...props}
          align={FlexHAlign.end}
          nestedAccessorFn={original => original.dpiData}
          id={getTableColumnTestAttr(headerMapping, props.column.id)}
        />
      ),
    }),
    columnHelper.accessor(row => row.rvpiData?.value, {
      id: 'rvpiData',
      header: props => (
        <Th {...props} headerMapping={headerMapping} align={FlexHAlign.end} />
      ),
      cell: props => (
        <Td
          {...props}
          align={FlexHAlign.end}
          nestedAccessorFn={original => original.rvpiData}
          id={getTableColumnTestAttr(headerMapping, props.column.id)}
        />
      ),
    }),
    columnHelper.accessor(row => row.tvpiData?.value, {
      id: 'tvpiData',
      header: props => (
        <Th {...props} headerMapping={headerMapping} align={FlexHAlign.end} />
      ),
      cell: props => (
        <Td
          {...props}
          align={FlexHAlign.end}
          nestedAccessorFn={original => original.tvpiData}
          id={getTableColumnTestAttr(headerMapping, props.column.id)}
        />
      ),
    }),
    columnHelper.display({
      id: 'expander',
      header: props => (
        <Th
          {...props}
          align={FlexHAlign.center}
          width={'40px'}
          headerMapping={headerMapping}
        />
      ),
      cell: props => {
        const row = props.row;
        const isExpanded = row.getIsExpanded();

        return row.getCanExpand() ? (
          <Td
            {...props}
            align={FlexHAlign.center}
            canHide={false}
            id="table-column-expand"
          >
            <ExpanderCell expanded={isExpanded} />
          </Td>
        ) : null;
      },
    }),
  ];
};

export default genFundsTableColumnDefs;
