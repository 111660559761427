import axios from 'axios';
import config from '../config';
import {FeeAnalysisData, FundOperational, InsightsData} from '../types';

export const getFundDetailsOperational = async (token: string, id: string) => {
  const {data} = await axios.get<FundOperational>(
    `${config.APP_URI}/fund-operationals/${id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );
  return data;
};

export const getFeeAnalysisData = async (
  token: string,
  fundId: string,
  from: string,
  to: string,
  includeManagementFees: boolean,
  entityId?: string
) => {
  const {data} = await axios.get<FeeAnalysisData>(
    `${
      config.APP_URI
    }/fund-operational/fee-analysis/${fundId}?from=${from}&to=${to}&includeManagementFees=${includeManagementFees}${
      entityId && entityId !== 'all' ? `&entityIds=${entityId}` : ''
    }`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );
  return data;
};

export const getFundInsightsData = async (
  token: string,
  fundId: string,
  date: string
) => {
  const {data} = await axios.get<InsightsData>(
    `${config.APP_URI}/fund-operational/insights/${fundId}?date=${date}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );
  return data;
};
