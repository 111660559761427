import React, {useMemo} from 'react';
import classnames from 'classnames';
import {
  ActivityStatuses,
  ActivityStatusProps,
  ActivityStatusType,
  StyleVariant,
  WorkflowStatus,
} from '../../../types';
import {getActivityColor, getWorkflowActivityStatus} from '../../../utils';
import styles from './ActivityStatus.module.scss';

const ActivityStatus: React.FC<ActivityStatusProps> = ({
  activity,
  variant = StyleVariant.Primary,
  type = ActivityStatusType.Default,
  classNames,
}) => {
  const activityColor = useMemo(() => {
    if (type === ActivityStatusType.Default) {
      return getActivityColor(activity as ActivityStatuses | boolean);
    }
    if (type === ActivityStatusType.Workflow) {
      return getWorkflowActivityStatus(activity as WorkflowStatus);
    }
    return '';
  }, [type, activity]);
  return (
    <div
      style={{backgroundColor: activityColor}}
      className={classnames(styles.wrapper, styles[`wrapper__${variant}`], {
        [`${classNames}`]: !!classNames,
      })}
    />
  );
};

export default ActivityStatus;
