import React, {PropsWithChildren, useEffect, useMemo, useState} from 'react';
import {HeaderContext, RowData, SortDirection} from '@tanstack/react-table';
import {HeaderMapping, FlexHAlign} from '../../../../../types/index';
import classnames from 'classnames';
import {ValueFormat} from '../../../../global';
import styles from './Th.module.scss';
import TableSortIcon from '../TableSortIcon';
import {formatToHyphenatedString} from '../../../../../utils/strings';

type Props<TData> = HeaderContext<TData, unknown> &
  PropsWithChildren<{
    id?: string;
    headerMapping?: HeaderMapping[];
    footerMapping?: any[];
    align?: any;
    padding?: string;
    width?: string | {min: string; max: string};
    className?: string;
    enableSorting?: boolean;
  }>;

const Th = <TData extends RowData>({
  id,
  header,
  column,
  children,
  headerMapping,
  footerMapping,
  align = FlexHAlign.center,
  width,
  padding = '',
  className,
  enableSorting = true,
}: Props<TData>) => {
  const [hovered, setHovered] = useState<boolean>(false);

  const colHeader = headerMapping?.find(
    (item: HeaderMapping) => item.columnId === column.id
  );

  const colFooter = footerMapping?.find(
    (item: any) => item.columnId === column.id
  );

  useEffect(() => {
    const sortDir = colHeader?.sortDirection;
    if (sortDir) {
      header.column.toggleSorting(sortDir === 'asc' ? false : true);
    }
  }, []);

  const getHeaderLabelAlignment = (align: FlexHAlign) => {
    if (align === FlexHAlign.start) {
      return 'left';
    }

    if (align === FlexHAlign.end) {
      return 'right';
    }

    return 'center';
  };

  const alignHeaderLabel = useMemo(
    () => getHeaderLabelAlignment(align),
    [align]
  );

  const sortingOnClickHandler = () => {
    if (!colFooter && colHeader) {
      header.column.toggleSorting();
    }
  };

  return (
    <th
      key={header.id}
      style={
        typeof width === 'string'
          ? {width, padding}
          : {minWidth: width?.min, maxWidth: width?.max, padding}
      }
      colSpan={header.colSpan}
      data-test={
        id || `table-header-${formatToHyphenatedString(colHeader?.label ?? '')}`
      }
    >
      {header.isPlaceholder ? null : (
        <div
          key={header.id}
          className={classnames(
            styles.wrapper,
            styles[`wrapper__${align}`],
            header.column.getIsSorted()
              ? styles.headerColor__sorted
              : !colFooter && colHeader && styles.headerColor,
            className
          )}
          style={{
            textAlign: alignHeaderLabel,
          }}
          onClick={sortingOnClickHandler}
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
        >
          {!colFooter ? (
            colHeader && (
              <>
                <span style={{marginRight: '6px'}}>{colHeader?.label}</span>
                {enableSorting && (
                  <TableSortIcon
                    hovered={hovered}
                    sorted={!!header.column.getIsSorted()}
                    direction={
                      !header.column.getIsSorted()
                        ? 'default'
                        : (header.column.getIsSorted() as SortDirection)
                    }
                  />
                )}
                {children && children}
              </>
            )
          ) : (
            <>
              <span className={className}>{colFooter.label}</span>
              <span className={className}>
                {colFooter?.value && (
                  <ValueFormat
                    value={colFooter?.value}
                    format={colFooter?.format}
                  />
                )}
              </span>
            </>
          )}
        </div>
      )}
    </th>
  );
};

export default Th;
