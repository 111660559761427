import React, {ReactElement} from 'react';
import styles from './FeeAnalysisHeader.module.scss';
import {SegmentedControl, ValueFormat} from '../../global';
import {
  FeeAnalysisTabs,
  FeeAnalysisViewTypeTabs,
  Func,
  StyleVariant,
} from '../../../types';
import classnames from 'classnames';
import {
  feeAnalysisTabsOptions,
  feeAnalysisTabsViewOptions,
} from '../../../constants/funds';
import {SegmentedControlVariants} from '../../global/segmented-control/SegmentedControl';

interface FeeAnalysisHeaderType {
  id: string;
  title: string;
  subtitle: string;
  currency?: string;
  format?: string;
  value?: number;
  activeFeeType?: FeeAnalysisTabs;
  activeFeeViewType?: FeeAnalysisViewTypeTabs;
  changeActiveFeeType?: Func<[FeeAnalysisTabs], void>;
  changeActiveFeeViewType?: Func<[FeeAnalysisViewTypeTabs], void>;
  variant?: StyleVariant;
  className?: string;
  disabled: boolean;
  exportButton: ReactElement;
}

const FeeAnalysisHeader: React.FC<FeeAnalysisHeaderType> = ({
  id,
  title,
  subtitle,
  currency,
  format,
  value,
  activeFeeType,
  activeFeeViewType,
  changeActiveFeeType,
  changeActiveFeeViewType,
  variant = StyleVariant.Primary, // can be primary or secondary
  className = '',
  disabled,
  exportButton,
}) => {
  return (
    <div
      id={id}
      className={classnames(
        styles.wrapper,
        styles[`wrapper__${variant}`],
        className
      )}
    >
      <div>
        <div className={styles.titleWrapper}>
          <p className={styles.title}>{title}</p>
          {variant === StyleVariant.Primary ? (
            <span className={styles.valueWrapper}>
              <span className={styles.currency}>{currency}</span>
              <ValueFormat value={value} format={format} />
            </span>
          ) : null}
        </div>
        <p className={styles.subtitle}>{subtitle}</p>
      </div>

      <div className={styles.rightBlock}>
        {variant === StyleVariant.Primary ? (
          <>
            <SegmentedControl
              id={`${id}-segmented-control-tab-views-options`}
              options={feeAnalysisTabsViewOptions}
              onChange={value =>
                changeActiveFeeViewType &&
                changeActiveFeeViewType(value as FeeAnalysisViewTypeTabs)
              }
              disabled={disabled}
              variant={SegmentedControlVariants.Secondary}
              value={activeFeeViewType}
            />
            <SegmentedControl
              id={`${id}-segmented-control-tab-options`}
              options={feeAnalysisTabsOptions}
              onChange={value =>
                changeActiveFeeType &&
                changeActiveFeeType(value as FeeAnalysisTabs)
              }
              value={activeFeeType}
              disabled={disabled}
              className={styles.primarySegmentedControl2}
            />
          </>
        ) : null}
        {variant === StyleVariant.Secondary ? (
          <div className={styles.valueWrapper}>
            <span className={styles.currency}>{currency}</span>
            <ValueFormat value={value} format={format} />
          </div>
        ) : null}

        {exportButton}
      </div>
    </div>
  );
};

export default FeeAnalysisHeader;
