import {combineReducers} from '@reduxjs/toolkit';
import {History} from 'history';
import {connectRouter} from 'connected-react-router';
import themeSlice from './reducers/themeSlice';
import authSlice from './reducers/authSlice';
import pageSlice from './reducers/pageSlice';
import searchSlice from './reducers/searchSlice';
import globalInformationSlice from './reducers/globalInformationSlice';
import clientDashboardSlice from './reducers/clientDashboardSlice';
import fundsSlice from './reducers/fundsSlice';
import fundDetailsOverviewSlice from './reducers/fundDetailsOverviewSlice';
import fundDetailsStructureSlice from './reducers/fundDetailsStructureSlice';
import fundDetailsPortfolioSlice from './reducers/fundDetailsPortfolioSlice';
import fundDetailsNearCastingSlice from './reducers/fundDetailsNearCastingSlice';
import companiesSlice from './reducers/companiesSlice';
import companyDetailsCompanySlice from './reducers/companyDetailsCompanySlice';
import companyDetailsInvestmentOverviewSlice from './reducers/companyDetailsInvestmentOverview';
import companyDetailsMonitoringSlice from './reducers/companyDetailsMonitoringSlice';
import fundDetailsOperationalSlice from './reducers/fundDetailsOperationalSlice';
import fundDetailsPortfolioTransactionsSlice from './reducers/fundDetailsPortfolioTransactionsSlice';
import clientAdminUsersSlice from './reducers/clientAdminUsersSlice';
import userProfileServiceSlice from './reducers/userProfileServiceSlice';
import clientAdminTemplateConfigSlice from './reducers/clientAdminTemplateConfigSlice';
import clientAdminWorkflowConfigSlice from './reducers/clientAdminWorkflowConfigSlice';
import clientAdminTemplateConfigDetailsSlice from './reducers/clientAdminTemplateConfigDetailsSlice';
import clientPortfolioCompanyUsersSlice from './reducers/clientAdminPortfolioCompanyUsersSlice';
import dataCollectionSlice from './reducers/dataCollectionSlice';
import dataCollectionDetailsSlice from './reducers/dataCollectionDetailsSlice';
import reportingSlice from './reducers/reportingSlice';
import documentExplorerSlice from './reducers/documentExplorerSlice';
import fileUploadSlice from './reducers/fileUploadSlice';
import reportingTableSlice from './reducers/reportingTableSlice';
import uploadDocumentsSlice from './reducers/uploadDocumentsSlice';
import scenarioDetailsReportSlice from './reducers/scenarioDetailsReportSlice';
import scenarioDetailsMainInfoSlice from './reducers/scenarioDetailsMainInfoSlice';
import scenarioDetailsValuationSlice from './reducers/scenarioDetailsValuationSlice';
import scenarioDetailsTransactionsSlice from './reducers/scenarioDetailsTransactionsSlice';
import scenarioOnePageReportMainSlice from './reducers/scenarioOnePageReportMainSlice';
import scenarioOnePageReportOverviewSlice from './reducers/scenarioOnePageReportOverviewSlice';
import scenarioOnePageReportPortfolioPerformanceSlice from './reducers/scenarioOnePageReportPortfolioPerformanceSlice';
import scenarioOnePageReportPortfolioSlice from './reducers/scenarioOnePageReportPortfolioSlice';
import featureFlagsSlice from './reducers/featureFlagsSlice';
import portfolioCompaniesChartDataSlice from './reducers/portfolioCompaniesChartDataSlice';
import filePreviewSlice from './reducers/filePreviewSlice';

// combine application reducers
const createRootReducer = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    featureFlags: featureFlagsSlice,
    page: pageSlice,
    theme: themeSlice,
    auth: authSlice,
    search: searchSlice,
    globalInformation: globalInformationSlice,
    clientDashboard: clientDashboardSlice,
    funds: fundsSlice,
    fundDetails: combineReducers({
      fundOverview: fundDetailsOverviewSlice,
      fundStructure: fundDetailsStructureSlice,
      fundPortfolio: fundDetailsPortfolioSlice,
      fundPortfolioTransactions: fundDetailsPortfolioTransactionsSlice,
      fundOperational: fundDetailsOperationalSlice,
      fundNearCasting: fundDetailsNearCastingSlice,
    }),
    scenarioDetails: combineReducers({
      report: scenarioDetailsReportSlice,
      main: scenarioDetailsMainInfoSlice,
      valuation: scenarioDetailsValuationSlice,
      transactions: scenarioDetailsTransactionsSlice,
    }),
    scenarioOnePageReport: combineReducers({
      main: scenarioOnePageReportMainSlice,
      overview: scenarioOnePageReportOverviewSlice,
      portfolioPerformance: scenarioOnePageReportPortfolioPerformanceSlice,
      portfolio: scenarioOnePageReportPortfolioSlice,
    }),
    companies: companiesSlice,
    companiesChartData: portfolioCompaniesChartDataSlice,
    companyDetails: combineReducers({
      companyInvestmentOverview: companyDetailsInvestmentOverviewSlice,
      company: companyDetailsCompanySlice,
      companyMonitoring: companyDetailsMonitoringSlice,
    }),
    userAdministration: combineReducers({
      users: clientAdminUsersSlice,
    }),
    dataCollectionAdministration: combineReducers({
      templateConfiguration: clientAdminTemplateConfigSlice,
      templateConfigurationDetails: clientAdminTemplateConfigDetailsSlice,
      workflowConfiguration: clientAdminWorkflowConfigSlice,
      portfolioCompanyUsers: clientPortfolioCompanyUsersSlice,
    }),
    dataCollection: combineReducers({
      main: dataCollectionSlice,
      details: dataCollectionDetailsSlice,
    }),
    userProfile: userProfileServiceSlice,
    companyAdmin: combineReducers({
      reporting: reportingSlice,
    }),
    documents: combineReducers({
      documentExplorer: documentExplorerSlice,
    }),
    reportingTable: reportingTableSlice,
    fileUpload: fileUploadSlice,
    uploadDocuments: uploadDocumentsSlice,
    filePreview: filePreviewSlice,
  });

export default createRootReducer;
