import React, {useRef, useState} from 'react';
import classNames from 'classnames';
import {useOnOutsideClick} from '../../../hooks';
import {SearchResult, Func} from '../../../types/index';
import {NavSearchResults} from '../index';
import {SearchInput} from '../../global';
import styles from './NavSearch.module.scss';
import {useTranslation} from 'react-i18next';

interface NavSearchProps {
  className?: string;
  isLoading: boolean;
  isActive: boolean;
  searchResults?: SearchResult;
  handleChangeValue?: Func<[string], void>;
  handleClearValue?: Func<[], void>;
}

const NavSearch: React.FC<NavSearchProps> = ({
  className,
  handleClearValue,
  handleChangeValue,
  isActive,
  isLoading,
  searchResults,
}) => {
  const {t} = useTranslation();
  const [value, setValue] = useState<string>('');
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const searchRef = useRef<HTMLDivElement>(null);

  const onSearchInputChange = (val: string): void => {
    setValue(val);
    if (handleChangeValue) {
      handleChangeValue(val);
    }
  };

  const cancelSearchResults = (): void => {
    if (!isLoading && isActive) {
      setValue('');
      setIsFocused(false);
      if (handleClearValue) {
        handleClearValue();
      }
    }
  };

  useOnOutsideClick(searchRef, cancelSearchResults);

  return (
    <div
      ref={searchRef}
      className={classNames(
        styles.wrapper,
        !!className && className,
        isFocused && styles.isFocused
      )}
    >
      <SearchInput
        isFocused={isFocused}
        isLoading={isLoading}
        isActive={isActive}
        onChange={onSearchInputChange}
        value={value}
        placeholder={t('NavigationMenu.SearchInput.Placeholder')}
        cancelSearch={cancelSearchResults}
        onChangeFocus={setIsFocused}
      />
      <NavSearchResults
        isActive={isActive}
        isLoading={isLoading}
        searchResults={searchResults}
        onClick={cancelSearchResults}
        searchValue={value}
      />
    </div>
  );
};

export default NavSearch;
