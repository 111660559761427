import React, {useEffect, useMemo, useState} from 'react';
import CustomYAxisTick from '../../../../global/custom-y-axis-tick/CustomYAxisTick';

import {ReactComponent as BrushTraveller} from '../../../../../assets/icons/brush-traveller.svg';
import {
  BundledExportProps,
  ButtonStyle,
  ControllerOption,
  Func,
  FundPerformanceKpiGroups,
  NavTimeSeries,
  OptionValue,
  TabData,
  TicksType,
} from '../../../../../types';
import {moicLegendItems} from '../../consts';
import NavCustomLegend from '../nav-custom-legend/NavCustomLegend';
import {
  arrayOfStringValuesToDateConverter,
  calcDomainRange,
  calcXaxisTicks,
  dateToMilliseconds,
} from '../../../../../utils/benchmarking';
import {
  Area,
  Brush,
  CartesianGrid,
  ComposedChart,
  Label,
  Legend,
  Line,
  LineChart,
  ReferenceArea,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import {
  fundPerformanceMoicChartSettings,
  getImagesExportPayload,
} from './FundPerformanceMoicChartSettings';
import FundPerformanceNavTooltip from '../fund-performance-nav-chart/components/FundPerformanceNavTooltip';
import {
  IconButton,
  LiveDataChartLabelContent,
  LiveDataChartShape,
  Loader,
} from '../../../../global';
import styles from './FundPerformanceMoicChart.module.scss';
import NavXAXisTick from '../fund-performance-nav-chart/components/NavXAxisTick';
import {
  useChartHover,
  useMultiAxesTicksCalculation,
} from '../../../../../hooks';

interface FundPerformanceMoicProps extends TabData {
  getBenchmarkData?: Func<[string, string, FundPerformanceKpiGroups], void>;
  clearBenchmarkData?: Func<[FundPerformanceKpiGroups], void>;
  exportHandler: Func<[BundledExportProps], void>;
  entityName?: string;
  nearCastingData?: TabData;
  showExport?: boolean;
}

const FundPerformanceMoicChart: React.FC<FundPerformanceMoicProps> = ({
  timeSeries: reportedTimeSeries,
  nearCastingData,
  netIrrData,
  benchmarking,
  benchmarkingTimeSeries,
  getBenchmarkData,
  clearBenchmarkData,
  exportHandler,
  entityName,
  id,
  showExport = true,
  signOffDate,
}) => {
  const timeSeries = useMemo(
    () => ({
      data: [
        ...(reportedTimeSeries.data || []),
        ...(nearCastingData?.timeSeries?.data || []),
      ],
    }),
    [reportedTimeSeries, nearCastingData]
  );
  const [isBenchmarking, setIsBenchmarking] = useState(false);
  const [activeKpi, setActiveKpi] = useState<ControllerOption | null>(null);
  const [activeSource, setActiveSource] = useState<OptionValue | null>(null);
  const [showBenchmarking, setShowBenchmarking] = useState(false);
  const [benchmarkingRange, setBenchmarkingRange] = useState<number>(
    benchmarkingTimeSeries ? benchmarkingTimeSeries.data.length - 1 : 0
  );
  const {hovered, handleMouseMove, handleMouseLeave} = useChartHover();
  const [exportInProgress, setExportInProgress] = useState<boolean>(false);
  const [range, setRange] = useState<number>(
    (timeSeries.data || []).length - 1
  );
  const [rangeIndexes, setRangeIndexes] = useState<[number, number]>([
    0,
    (timeSeries.data?.length || 1) - 1,
  ]);
  const [activeCharts, setActiveCharts] = useState<string[]>(
    moicLegendItems.map(({value}) => value)
  );

  useEffect(() => {
    setBenchmarkingRange(
      benchmarkingTimeSeries ? benchmarkingTimeSeries.data.length - 1 : 0
    );
  }, [benchmarkingTimeSeries]);

  const benchmarkingData = useMemo(() => {
    return (
      benchmarkingTimeSeries &&
      arrayOfStringValuesToDateConverter(benchmarkingTimeSeries.data)
    );
  }, [benchmarkingTimeSeries]);

  const isNearCastingEmpty =
    !nearCastingData?.timeSeries || !nearCastingData?.timeSeries?.data?.length;
  const nearCastingStartingFrom = (
    reportedTimeSeries?.data?.slice(-1)[0] as any
  )?.date;

  const chartData = useMemo(() => {
    return (
      timeSeries.data?.map(item => ({
        ...item,
        x: new Date((item as any).date as string),
      })) || []
    );
  }, [timeSeries]);

  const {from, to} = useMemo(() => {
    if (isBenchmarking && benchmarkingTimeSeries) {
      return calcDomainRange(benchmarkingTimeSeries.data);
    }
    if (timeSeries.data) {
      return calcDomainRange(chartData, rangeIndexes);
    }
    return {from: new Date(), to: new Date(), length: 0};
  }, [benchmarkingTimeSeries, isBenchmarking, chartData, rangeIndexes]);

  const xAxisTicksType = useMemo<TicksType>(() => {
    const rangeIdx = range >= chartData.length ? chartData.length - 1 : range;
    const {start, end} =
      isBenchmarking && benchmarkingData
        ? dateToMilliseconds(
            benchmarkingData[0].x,
            benchmarkingData[benchmarkingRange].x
          )
        : chartData && chartData.length > 0 && range < chartData.length
        ? dateToMilliseconds(chartData[0].x, chartData[rangeIdx].x)
        : dateToMilliseconds(new Date(), new Date());

    const yearsRange = Math.floor((end - start) / 3.154e10);

    if (yearsRange >= 3) return TicksType.Year;
    return TicksType.Quarter;
  }, [benchmarkingRange, chartData, range]);

  const xAxisFormat = useMemo(() => {
    if (xAxisTicksType === TicksType.Year) return 'yyyy';
    return "QQ 'YY";
  }, [xAxisTicksType]);

  const getXAxisTicks = useMemo(() => {
    if (isBenchmarking && benchmarkingData) {
      return calcXaxisTicks(benchmarkingData, xAxisTicksType).map(
        (item: string) => new Date(item)
      );
    }
    return calcXaxisTicks(
      chartData as NavTimeSeries[],
      xAxisTicksType,
      true
    ).map((item: string) => new Date(item));
  }, [xAxisTicksType, isBenchmarking, benchmarkingData, chartData]);

  const onBrushChange = ({startIndex, endIndex}: any) => {
    isBenchmarking && benchmarkingTimeSeries
      ? setBenchmarkingRange(endIndex - startIndex)
      : setRange(endIndex - startIndex);
    setRangeIndexes([startIndex, endIndex]);
  };

  const onBenchmarkToggle = () => {
    setShowBenchmarking(prevState => !prevState);
    if (isBenchmarking) {
      setIsBenchmarking(false);
      setActiveKpi(null);
      clearBenchmarkData && clearBenchmarkData(FundPerformanceKpiGroups.Moic);
    }
  };

  const onBenchmarkOptionChange = (
    activeKpi: ControllerOption,
    activeSource: OptionValue
  ) => {
    setActiveKpi(activeKpi);
    setActiveSource(activeSource);
    setIsBenchmarking(true);

    getBenchmarkData &&
      getBenchmarkData(
        activeKpi.name,
        activeSource.name,
        FundPerformanceKpiGroups.Moic
      );
  };

  const {
    maxLeftYAxis,
    minLeftAxis,
    maxRightAxis,
    minRightAxis,
    ticksRightAxis,
    ticksLeftAxis,
  } = useMultiAxesTicksCalculation({
    data: chartData,
    leftAxisDataKeys: ['Gross IRR', 'Net IRR'],
    rightAxisDataKeys: ['MOIC'],
  });

  const CustomTraveller = (props: any) => {
    return <BrushTraveller x={props.x - 7} y={props.y} />;
  };

  const handleBundledExport = async () => {
    setExportInProgress(true);

    const zipFileName = `${entityName}_FundPerformance_MOIC`;

    const tableSettings = fundPerformanceMoicChartSettings(zipFileName);

    const tableExportPayload = [
      {
        data: timeSeries.data,
        mappings: tableSettings.headerMapping,
        settings: tableSettings,
      },
    ];

    await exportHandler({
      zipFileName,
      tableExportPayload,
      imagesExportPayload: getImagesExportPayload(zipFileName),
    } as unknown as BundledExportProps);

    setExportInProgress(false);
  };

  const getLineProps = (dataKey: string, color: string) => ({
    connectNulls: true,
    dot: false,
    strokeWidth: 2,
    dataKey,
    stroke: color,
    activeDot: {
      stroke: color,
      fill: color,
      strokeWidth: 5,
      strokeOpacity: 0.5,
      r: 6.5,
    },
  });

  const brushLineProps = {
    dot: false,
    connectNulls: true,
    stroke: 'rgb(var(--colors-lan-blue))',
  };

  return (
    <div className={styles.wrapper}>
      {isBenchmarking && !benchmarkingData && <Loader loaderType={'funds'} />}

      <div
        style={{opacity: isBenchmarking && !benchmarkingData ? 0 : 1}}
        id="performance-moic-chart"
      >
        <ResponsiveContainer width="100%" height={590}>
          <ComposedChart
            data={
              isBenchmarking && !!benchmarkingData
                ? (benchmarkingData as any)
                : (chartData as any)
            }
            margin={{top: 35, left: 60, right: 60}}
            onMouseMove={handleMouseMove}
            onMouseLeave={handleMouseLeave}
          >
            <defs>
              <linearGradient
                id="nearCastingGradient"
                x1="24.4311"
                y1="6.58801e-06"
                x2="458.185"
                y2="407.236"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor={`rgb(var(--colors-nearcasting-gradient1))`} />
                <stop
                  offset="1"
                  stopColor={`rgb(var(--colors-nearcasting-gradient2))`}
                />
              </linearGradient>
            </defs>
            <defs>
              <linearGradient
                id="benchmarkingGradient"
                gradientTransform="rotate(90)"
              >
                <stop
                  offset="57%"
                  stopColor={`rgba(var(--colors-harlequin),0.32`}
                />
                <stop
                  offset="100%"
                  stopColor={`rgba(var(--colors-japanese-laurel),0.12)`}
                />
              </linearGradient>
              <linearGradient
                id="signOffDataGradient"
                gradientTransform="rotate(0)"
              >
                <stop offset="0%" stopColor={`rgba(65, 65, 65, 0.8)`} />
                <stop offset="50%" stopColor={`rgba(65, 65, 65, 0.51)`} />
                <stop offset="100%" stopColor={`rgba(52, 51, 51, 0.2)`} />
              </linearGradient>
            </defs>
            <CartesianGrid
              vertical={false}
              strokeDasharray="2"
              stroke={`rgb(var(--colors-gray-6))`}
            />
            {!isNearCastingEmpty && (
              <ReferenceArea
                x1={new Date(nearCastingStartingFrom).getTime()}
                ifOverflow="hidden"
                fill="url(#nearCastingGradient)"
                fillOpacity={0.3}
              />
            )}
            <Tooltip
              content={
                <FundPerformanceNavTooltip
                  dateLabel="quarter"
                  format={netIrrData?.format}
                  isBenchmarking={isBenchmarking}
                  tooltipFormat={netIrrData?.format}
                  activeKpi={activeKpi?.label}
                  activeSource={activeSource?.label}
                />
              }
            />
            <Line
              {...getLineProps('Gross IRR', 'rgb(var(--colors-orange-peel))')}
              yAxisId={0}
              type="stepAfter"
              hide={!activeCharts.includes('gross_irr')}
            />
            {!isNearCastingEmpty && (
              <Line
                {...getLineProps(
                  'NC Gross IRR',
                  'rgb(var(--colors-orange-peel))'
                )}
                yAxisId={0}
                type="stepAfter"
                strokeDasharray="6 4"
              />
            )}
            <Line
              yAxisId={0}
              type="stepAfter"
              hide={!activeCharts.includes('net_irr')}
              {...getLineProps('Net IRR', 'rgb(var(--colors-lan-violet))')}
            />
            {!isNearCastingEmpty && (
              <Line
                {...getLineProps('NC Net IRR', 'rgb(var(--colors-lan-violet))')}
                yAxisId={0}
                type="stepAfter"
                strokeDasharray="6 4"
              />
            )}
            <Line
              {...getLineProps('MOIC', 'rgb(var(--colors-lan-blue))')}
              yAxisId={1}
              type="stepAfter"
              hide={!activeCharts.includes('moic')}
            />
            {!isNearCastingEmpty && (
              <Line
                yAxisId={1}
                type="stepAfter"
                {...getLineProps('NC MOIC', 'rgb(var(--colors-lan-blue))')}
                strokeDasharray="6 4"
              />
            )}
            {id === 'fund-performance-row' && (
              <rect
                y={437}
                width="100%"
                height={52}
                // TODO: Replace color after updating the wrapper structure of the chart
                fill="#272727"
                fillOpacity={0.75}
              />
            )}
            <XAxis
              dataKey="x"
              type="number"
              domain={[from.getTime(), to.getTime()]}
              scale="time"
              interval={0}
              axisLine={false}
              height={60}
              tickLine={false}
              ticks={getXAxisTicks as any}
              tick={
                <NavXAXisTick
                  showAxis={true}
                  fill="gray-3"
                  fontSize={12}
                  yOffset={30}
                  xAxisFormat={xAxisFormat}
                />
              }
            />
            <Area
              dataKey={
                activeKpi?.name === 'gross_irr'
                  ? 'benchmarking_gross_irr'
                  : 'benchmarking_moic'
              }
              yAxisId={activeKpi?.name === 'gross_irr' ? 0 : 1}
              fill="url(#benchmarkingGradient)"
              stroke={`rgb(var(--colors-lan-green))`}
              strokeOpacity={0}
              activeDot={{
                stroke: `rgb(var(--colors-lan-green))`,
                fill: `rgb(var(--colors-lan-green))`,
                strokeWidth: 5,
                strokeOpacity: 0.5,
                r: 6.5,
              }}
            />
            <YAxis
              axisLine={false}
              tickLine={false}
              yAxisId={0}
              width={1}
              domain={[minLeftAxis, maxLeftYAxis]}
              ticks={ticksLeftAxis}
              tick={
                <CustomYAxisTick
                  showAxis={true}
                  xOffset={-20}
                  yOffset={4}
                  fill="gray-3"
                  fontSize={12}
                  fontWeight={500}
                  format={netIrrData?.format}
                />
              }
            />
            <YAxis
              axisLine={false}
              tickLine={false}
              yAxisId={1}
              width={1}
              domain={[minRightAxis, maxRightAxis]}
              ticks={ticksRightAxis}
              orientation={
                isBenchmarking && activeKpi?.name === 'moic' ? 'left' : 'right'
              }
              tick={
                <CustomYAxisTick
                  showAxis={true}
                  xOffset={activeKpi?.name === 'moic' ? -20 : 20}
                  yOffset={4}
                  format={'auto'}
                  fill="gray-3"
                  fontSize={12}
                  fontWeight={500}
                />
              }
            />

            {signOffDate && (
              <ReferenceArea
                isFront={true}
                x1={new Date(signOffDate).getTime()}
                shape={props => (
                  <LiveDataChartShape
                    {...props}
                    fill="url(#signOffDataGradient)"
                  />
                )}
              >
                {hovered && (
                  <Label
                    value={'In-flight data'}
                    content={LiveDataChartLabelContent}
                  />
                )}
              </ReferenceArea>
            )}
            <Legend
              verticalAlign="top"
              align="left"
              wrapperStyle={{
                paddingBottom: '45px',
                paddingTop: '30px',
                marginLeft: '-22px',
              }}
              iconType="circle"
              content={
                <NavCustomLegend
                  id="fund-performance-moic-chart-legend"
                  items={moicLegendItems}
                  benchmarking={benchmarking}
                  onBenchmarkToggle={onBenchmarkToggle}
                  showBenchmarking={showBenchmarking}
                  onBenchmarkOptionChange={onBenchmarkOptionChange}
                  activeKpi={activeKpi}
                  isBenchmarking={isBenchmarking}
                  activeCharts={activeCharts}
                  setActiveCharts={setActiveCharts}
                  exportButton={
                    showExport ? (
                      <IconButton
                        className="performance-chart-export"
                        onClick={handleBundledExport} // exportHandler
                        styleType={ButtonStyle.Primary}
                        icon="export"
                        loading={exportInProgress} // exportInProgress
                        disabled={showBenchmarking}
                        id="performance-moic-chart-export-btn"
                      />
                    ) : undefined
                  }
                />
              }
            />

            <Brush
              className="performance-chart-brush"
              fill={`rgb(var(--colors-black))`}
              dataKey="x"
              height={100}
              travellerWidth={0}
              traveller={<CustomTraveller />}
              onChange={onBrushChange}
              data={!isNearCastingEmpty ? chartData : undefined}
              tickFormatter={() => ''}
            >
              <LineChart>
                <CartesianGrid
                  vertical={true}
                  horizontal={false}
                  strokeDasharray="2"
                  stroke={`rgb(var(--colors-gray-6))`}
                />
                <Line
                  {...brushLineProps}
                  dataKey={
                    activeKpi?.name === 'gross_irr' ? 'Gross IRR' : 'MOIC'
                  }
                  stroke={
                    activeKpi?.name === 'gross_irr'
                      ? `rgb(var(--colors-orange-peel))`
                      : `rgb(var(--colors-lan-blue))`
                  }
                  type="stepAfter"
                />
                {!isNearCastingEmpty && (
                  <Line
                    {...brushLineProps}
                    dataKey={'NC MOIC'}
                    type="stepAfter"
                    strokeDasharray="6 4"
                  />
                )}
                {!isNearCastingEmpty && (
                  <ReferenceArea
                    x1={new Date(nearCastingStartingFrom).getTime()}
                    ifOverflow="visible"
                    fill="url(#nearcastingGradient)"
                    fillOpacity={0.3}
                  />
                )}
                {signOffDate && (
                  <ReferenceArea
                    x1={new Date(signOffDate).getTime()}
                    ifOverflow="visible"
                    shape={props => (
                      <LiveDataChartShape
                        {...props}
                        bottomMargin={30}
                        fill="url(#signOffDataGradient)"
                      />
                    )}
                  />
                )}
                {(!isNearCastingEmpty || signOffDate) && (
                  <XAxis
                    dataKey="x"
                    domain={[
                      chartData[0].x.getTime(),
                      chartData.slice(-1)[0].x.getTime(),
                    ]}
                    scale="time"
                    type="number"
                    axisLine={false}
                    height={0}
                    interval={0}
                    tickLine={false}
                  />
                )}
              </LineChart>
            </Brush>
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default FundPerformanceMoicChart;
