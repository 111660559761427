import React from 'react';
import {CustomAxisTickProps} from '../../../types';
import {valueFormat} from '../../../utils/value-format';

type YAxisProps = Pick<
  CustomAxisTickProps,
  | 'x'
  | 'y'
  | 'payload'
  | 'xOffset'
  | 'yOffset'
  | 'showAxis'
  | 'format'
  | 'locale'
  | 'fill'
  | 'fontSize'
  | 'fontWeight'
>;

const CustomYAxisTick: React.FC<YAxisProps> = ({
  x,
  y,
  payload,
  xOffset = 0,
  yOffset = 0,
  showAxis = false,
  format,
  fill,
  fontSize = 10,
  fontWeight = 400,
  locale,
}): JSX.Element => {
  const value = valueFormat(payload?.value, format, locale).value;
  const suffix = valueFormat(payload?.value, format, locale).suffix;

  return (
    <g transform={`translate(${x},${y})`}>
      {showAxis && (
        <text
          x={xOffset}
          y={yOffset}
          textAnchor="middle"
          fill={`rgb(var(--colors-${fill}))`}
          fontSize={fontSize}
          fontWeight={fontWeight}
        >
          {suffix ? `${value}${suffix}` : value}
        </text>
      )}
    </g>
  );
};

export default CustomYAxisTick;
