import React from 'react';
import {Button, Icon, Modal} from '../../global';
import styles from './ConfirmationModal.module.scss';
import {ButtonStyle, ConfirmationModalProps} from '../../../types';
import i18n from '../../../i18n';

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
  message,
  icon = 'warning',
  iconBgColor = 'red',
  confirmButtonText,
  cancelButtonText = i18n.t('Global.Close'),
  customContent,
  title,
  isLoading = false,
  zIndex = 10,
}) => {
  return (
    <Modal
      title={title}
      onClose={onClose}
      isOpen={isOpen}
      className={styles.modalWrapper}
      zIndex={zIndex}
    >
      <div className={styles.wrapper}>
        {customContent ? (
          customContent
        ) : (
          <div className={styles.inner}>
            <div
              className={styles.iconWrapper}
              style={{backgroundColor: `rgb(var(--colors-${iconBgColor}))`}}
            >
              <Icon className={styles.icon} name={icon} />
            </div>
            <div className={styles.message}>{message}</div>
          </div>
        )}
        <div className={styles.buttons}>
          <Button
            styleType={ButtonStyle.Primary}
            text={confirmButtonText}
            onClick={onConfirm}
            className={styles.lightThemePrimaryButton}
            loading={isLoading}
          />
          <Button
            onClick={onClose}
            text={cancelButtonText}
            styleType={ButtonStyle.Secondary}
            className={styles.lightThemeSecondaryButton}
            disabled={isLoading}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
