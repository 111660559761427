import React from 'react';
import classnames from 'classnames';
import styles from './SkeletonLoader.module.scss';

interface SkeletonLoaderProps {
  width?: number | string;
  height?: number | string;
  baseColor?: string;
  highlightColor?: string;
  isCircle?: boolean;
  animationDirection?: 'to-right' | 'to-bottom';
  className?: string;
}

const SkeletonLoader: React.FC<SkeletonLoaderProps> = ({
  width = '100%',
  baseColor = 'var(--colors-gray-7)',
  highlightColor = 'var(--colors-gray-3)',
  height = '',
  isCircle = false,
  animationDirection = 'to-right',
  className = '',
}) => {
  return (
    <span
      className={classnames(
        styles.wrapper,
        isCircle && styles.circle,
        className
      )}
      style={{
        width,
        height: isCircle ? width : height,
      }}
    >
      &zwnj;
      <span
        className={classnames(
          styles.loader,
          animationDirection === 'to-right' && styles.animatedToRight,
          animationDirection === 'to-bottom' && styles.animatedToBottom
        )}
        style={{
          backgroundImage: `linear-gradient(${
            animationDirection === 'to-right' ? '90deg' : '0deg'
          }, rgba(${baseColor}, 0), rgb(${highlightColor}), rgba(${baseColor}, 0))`,
        }}
      />
    </span>
  );
};

export default SkeletonLoader;
