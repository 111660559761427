import {AreaChartProps, BarChartProps} from 'recharts/index';
import {FundPerformanceWidgetProps} from './fundOverview';
import {SortDirection} from '@tanstack/react-table';
import {FundState} from './enums';
import {InvestmentPerformanceData} from './companyInvestmentOverview';
import {LayoutSettings} from './userLayoutProperty';
import {AxisMetricsOption, ChartDataFilterConfig} from './';

export interface ClientDashboard {
  id: string;
  type: string;
  clientName: string;
  asAt: AsAt;
  quarterlyData: QuarterlyDataAvailability;
  axisMetricsOptions: AxisMetricsOption[];
  fundData: FundData;
  companyData: CompanyData;
  savedChartsFilterConfig: ChartDataFilterConfig[];
}

export interface FundData {
  type: string;
  fundLabel: string;
  fundCount: number;
  widgets: Array<FundSummaryWidgetProps>;
  headerMapping: HeaderMapping[];
  layoutSettings?: LayoutSettings;
}

export interface HeaderMapping {
  columnId: string;
  label: string;
  sortDirection?: SortDirection;
}

export interface CompanyData {
  type: string;
  companyLabel: string;
  companyCount: number;
  performingCompanyStats: {
    performing: number;
    underPerforming: number;
  };
  isLoading: boolean;
  widgets: Array<ActiveCompanyData | ExitedCompanyData | WrittenOffCompanyData>;
}

export interface FundSummaryWidgetProps {
  id: string;
  type: string;
  fundName: string;
  fundColor: string;
  companiesLabel: string;
  companiesCount: number;
  vintage: number;
  currency: string;
  state: FundState;
  commitment: FundInfoCommitmentAvailable;
  available: FundInfoCommitmentAvailable;
  fundOnboardingLabel?: string;
  nav?: SummaryWidgetChartProps | null;
  netIrr?: SummaryWidgetChartProps | null;
  dpiRvpiTvpi?: SummaryWidgetChartProps | null;
  dpiData?: kpiData;
  rvpiData?: kpiData;
  tvpiData?: kpiData;
  clickHandler?: (id: KpiTabs) => void;
  activeTab?: KpiTabs;
  isFundOnboarding?: boolean;
  fundPerformanceData?: FundPerformanceWidgetProps;
  isDataCurrent?: boolean;
  asAt?: AsAt;
  signOffDate?: string | null;
}

export interface FundInfoCommitmentAvailable {
  id: string;
  label: string;
  value: number;
  format: string;
}

export interface SummaryWidgetChartProps {
  id?: string;
  label?: string;
  value?: number;
  format?: string;
  currency?: string | null;
  tooltipFormat?: string;
  yAxisFormat?: string;
  deltaIndicator?: DeltaIndicatorProps | null;
  dpiData?: kpiData;
  rvpiData?: kpiData;
  tvpiData?: kpiData;
  piccData?: kpiData;
  netIrrData?: kpiData;
  grossIrrData?: kpiData;
  moicData?: kpiData;
  timeSeries?: AreaChartProps | BarChartProps;
  isTrendingUp?: boolean;
  signOffDate?: string | null;
}

interface kpiData {
  label: string;
  value: number;
  format: string;
  deltaIndicator?: DeltaIndicatorProps;
}

export interface AsAt {
  id?: string;
  label: string;
  value: string;
  format: string;
  startDate?: string;
}

export interface QuarterlyDataAvailability {
  date: string;
  startDate: string;
  endDate: string;
}
export interface DeltaIndicatorProps {
  isUp?: boolean;
  value?: string;
  remarks?: string;
}

export interface CompanyFinancialData {
  label: string;
  value: number;
  format: string;
  deltaIndicator?: DeltaIndicatorProps;
}

export enum KpiTabs {
  Nav = 'nav',
  NetIrr = 'net-irr',
  DpiRvpi = 'dpi-rvpi-tvpi',
  Moic = 'moic',
  Picc = 'picc',
}

export interface FormattedDate {
  value: string;
  format: string;
}

export interface BaseCompanyData {
  id: string;
  type: string;
  companyName: string;
  companyIcon?: string;
  status: string;
  fundName: string | null;
  fundColor: string | null;
  currency: string;
  holdingPeriod: {
    label: string;
    value: string | number;
    format?: string;
  };
  firstInvestment: FormattedDate;
  sortingValue?: string;
  isDataCurrent?: boolean;
  asAt: AsAt;
  signOffDate?: string | null;
  performanceData?: Array<InvestmentPerformanceData>;
  investmentGroupId?: string;
  industry: string | null;
  sector: string | null;
  region: string | null;
}

export interface ActiveCompanyData extends BaseCompanyData {
  type: 'ActiveCompanyData';
  cost: CompanyFinancialData;
  debtCost: CompanyFinancialData;
  equityCost: CompanyFinancialData;
  unrealisedGainLoss: CompanyFinancialData;
  investmentIncome: CompanyFinancialData;
  totalCost: CompanyFinancialData;
  valuation: CompanyFinancialData;
  irr: CompanyFinancialData | null;
  totalRealised: CompanyFinancialData;
  realised: CompanyFinancialData;
  realisedLoss: CompanyFinancialData;
  totalValue: CompanyFinancialData;
  roi: CompanyFinancialData;
  instrumentTypes: Array<string> | null;
  investmentDetails: Array<ActiveCompanyData> | null;
}

export interface ExitedCompanyData extends BaseCompanyData {
  type: 'ExitedCompanyData';
  totalCost: CompanyFinancialData;
  realised: CompanyFinancialData;
  roi: CompanyFinancialData;
  irr: CompanyFinancialData | null;
  exitDate: FormattedDate;
  moic: CompanyFinancialData;
  totalGainLoss: CompanyFinancialData;
  investmentDetails: Array<ExitedCompanyData> | null;
}

export interface WrittenOffCompanyData extends BaseCompanyData {
  type: 'WrittenOffCompanyData';
  writtenOffDate: FormattedDate;
  realisedLoss?: CompanyFinancialData;
  totalCost?: CompanyFinancialData;
  investmentDetails: Array<WrittenOffCompanyData> | null;
}
