import {createAction, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {SearchResult} from '../types/index';

export const searchAction = createAction<string>('funds/search');

interface FundSearch {
  searchResults: SearchResult | undefined;
  isActive: boolean;
  isLoading: boolean;
}

const initialState = {
  searchResults: undefined,
  isLoading: false,
  isActive: false,
} as FundSearch;

const searchSlice = createSlice({
  name: 'fund-search',
  initialState,
  reducers: {
    getSearchResults(state: FundSearch) {
      state.isActive = true;
      state.isLoading = true;
    },
    setSearchResults(state: FundSearch, action: PayloadAction<SearchResult>) {
      state.searchResults = action.payload;
      state.isLoading = false;
    },
    clearSearchResults(state: FundSearch) {
      state.searchResults = undefined;
      state.isLoading = false;
      state.isActive = false;
    },
  },
});

export const {getSearchResults, setSearchResults, clearSearchResults} =
  searchSlice.actions;
export default searchSlice.reducer;
