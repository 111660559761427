import styles from './DotsMenuCell.module.scss';
import Icon from '../../../Icon/Icon';
import {useCallback, useState} from 'react';
import Modal from '../../../modal/Modal';
import classnames from 'classnames';
import {useTranslation} from 'react-i18next';

export interface DotsMenuItem {
  label: string;
  action: VoidFunction;
  isDelete?: boolean;
}

type DotsMenuCellProps = {
  menuItems: DotsMenuItem[];
};

const DotsMenuCell = ({menuItems}: DotsMenuCellProps): JSX.Element => {
  const {t} = useTranslation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleMenu = useCallback(
    () => setIsMenuOpen(!isMenuOpen),
    [isMenuOpen]
  );

  return (
    <div className={styles.wrapper}>
      <button
        className={classnames(styles.button, {
          [styles.buttonActive]: isMenuOpen,
        })}
        onClick={toggleMenu}
      >
        <Icon name="multidot" className={styles.dots} />
      </button>
      <div className={styles.menuModal}>
        <Modal
          isPortal={false}
          isDismissable={false}
          isOpen={isMenuOpen}
          onClose={toggleMenu}
          children={
            <>
              {menuItems?.map(({label, action, isDelete}) => (
                <>
                  {isDelete && <div className={styles.modalRowBreak} />}
                  <div
                    onClick={() => {
                      action();
                      toggleMenu();
                    }}
                    className={classnames(styles.modalRow, {
                      [styles.modalRowDelete]: isDelete,
                    })}
                  >
                    {t(label)}
                  </div>
                </>
              ))}
            </>
          }
        />
      </div>
    </div>
  );
};

export default DotsMenuCell;
