import React, {useMemo} from 'react';
import {ResponsiveContainer, Sankey} from 'recharts';

const SankeyChartPlaceholder: React.FC = () => {
  const placeholderData = useMemo(
    () => ({
      nodes: Array.from(Array(5)).map(_ => ({name: ''})),
      links: Array.from(Array(4)).map((_, idx) => ({
        source: 0,
        target: idx + 1,
        value: 25,
      })),
    }),
    []
  );
  return (
    <ResponsiveContainer width="100%" height={450}>
      <Sankey
        nodeWidth={8}
        nodePadding={10}
        linkCurvature={0.51}
        data={placeholderData}
        iterations={0}
        link={{stroke: 'rgb(var(--colors-gray-6))', strokeOpacity: 0.25}}
        node={{fill: 'rgb(var(--colors-gray-6))', stroke: 'none'}}
      />
    </ResponsiveContainer>
  );
};

export default SankeyChartPlaceholder;
