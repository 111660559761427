import {call, put, takeLatest, delay} from 'redux-saga/effects';
import {acquireAuthResult, msalInstance} from '../..';
import {errorHandlerAction} from '../../actions';
import {
  getSearchResults,
  setSearchResults,
  clearSearchResults,
  searchAction,
} from '../../reducers/searchSlice';
import {search} from '../../api/search';
import {SearchResult} from '../../types/index';

export const searchSaga = function* searchSaga({payload}: any) {
  yield delay(500);
  if (payload) {
    try {
      yield put(getSearchResults());
      const {accessToken} = yield call(acquireAuthResult, msalInstance);
      const response: SearchResult = yield call(search, accessToken, payload);
      yield put(setSearchResults(response));
    } catch (error) {
      console.warn(error);
      yield put(clearSearchResults());
      yield put(errorHandlerAction({error}));
    }
  } else {
    yield put(clearSearchResults());
  }
};

export const searchSagaWatcher = function* searchSagaWatcher() {
  yield takeLatest(searchAction.type, searchSaga);
};
