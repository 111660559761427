import {all, call, put, fork, takeLatest, select} from 'redux-saga/effects';
import {RootState} from '../../store';
import {
  acquireAuthResult,
  acquireTokenRedirectRequest,
  msalInstance,
} from '../../index';
import {getClientDashboard} from '../../api/getClientDashboard';
import {ActiveCompanyData, ClientDashboard} from '../../types/index';
import {errorHandlerAction} from '../../actions';
import {
  getClientDashboardCompanyData,
  setClientDashboard,
  setClientDashboardCompanyData,
  clearClientDashboard,
} from '../../reducers/clientDashboardSlice';
import {aadLoginSuccessAction} from '../../reducers/authSlice';
import {getClientDashboardCompanies} from '../../api/getClientDashboardCompanies';
import {getSavedFilterConfigCompaniesHelperSaga} from '../companies/getAllCompaniesSaga';

export const getClientDashboardSaga = function* getClientDashboardSaga({
  payload,
}: any): any {
  const accountInfo = msalInstance.getAllAccounts();
  if (accountInfo?.length > 0) {
    const isUserProfileUpdatePending = yield select(
      (state: RootState) => state.userProfile.isLoading
    );
    if (!isUserProfileUpdatePending) {
      yield put(clearClientDashboard());
    }

    try {
      const {accessToken} = yield call(acquireAuthResult, msalInstance);
      const dashboardResponse: ClientDashboard = yield call(
        getClientDashboard,
        accessToken
      );
      yield put(setClientDashboard(dashboardResponse));

      const skipReloadChartData = payload?.shouldSkipReloadingChartData;
      const config = dashboardResponse.savedChartsFilterConfig;

      if (!skipReloadChartData && config?.length > 0) {
        yield fork(getSavedFilterConfigCompaniesHelperSaga, {
          payload: {config},
          type: 'companies/get-saved-config-companies',
        });
      }
    } catch (err) {
      console.warn(err);
      yield put(errorHandlerAction({error: err}));
    }
  } else {
    yield call(acquireTokenRedirectRequest, msalInstance);
  }
};

export const getClientDashboardCompanyDataSaga =
  function* getClientDashboardCompanyDataSaga({payload}: any): any {
    const accountInfo = msalInstance.getAllAccounts();
    if (accountInfo?.length > 0) {
      try {
        const {accessToken} = yield call(acquireAuthResult, msalInstance);
        const dashboardCompanyResponse: ActiveCompanyData[] = yield call(
          getClientDashboardCompanies,
          accessToken,
          payload
        );
        yield put(setClientDashboardCompanyData(dashboardCompanyResponse));
      } catch (err) {
        console.warn(err);
        yield put(errorHandlerAction({error: err}));
      }
    }
  };

export const getClientDashboardSagaWatcher =
  function* getClientDashboardSagaWatcher() {
    yield all([
      takeLatest(aadLoginSuccessAction.type, getClientDashboardSaga),
      takeLatest(
        getClientDashboardCompanyData.type,
        getClientDashboardCompanyDataSaga
      ),
    ]);
  };
