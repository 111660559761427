import React from 'react';
import {valueFormat} from '../../../../../utils/value-format';
import styles from './SankeyTooltip.module.scss';

const SankeyTooltip = (props: any) => {
  const {payload, sankeyLinksData} = props;

  const nodeValue =
    sankeyLinksData[payload?.[0]?.payload?.payload?.sourceLinks?.[0]];

  if (payload?.length) {
    return (
      <div className={styles.wrapper}>
        <p className={styles.name}>
          {payload[0].payload?.payload?.target?.name ||
            payload[0].payload?.payload?.name}
          :
        </p>
        <p className={styles.currency}>{props.currency}</p>
        <p className={styles.value}>
          {
            valueFormat(
              nodeValue?.value ||
                payload[0].payload?.payload?.target?.value ||
                payload[0].payload?.payload?.value,
              props.tooltipFormat
            ).value
          }
        </p>
      </div>
    );
  }

  return null;
};

export default SankeyTooltip;
