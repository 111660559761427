import React from 'react';
import classnames from 'classnames';
import {Link} from 'react-router-dom';
import {Func, Tab, StyleVariant} from '../../../types/index';
import styles from './Tabs.module.scss';
import {useTranslation} from 'react-i18next';
import {formatToHyphenatedString} from '../../../utils/strings';

interface TabsProps {
  tabs: Tab[];
  activeTab: Tab;
  clickHandler?: Func<[Tab], void>;
  tabVariant?: StyleVariant;
  className?: string;
}

const Tabs: React.FC<TabsProps> = ({
  tabs,
  activeTab,
  clickHandler,
  tabVariant = StyleVariant.Primary, // can be primary or secondary for now
  className = '',
}) => {
  const {t} = useTranslation();
  return (
    <div className={classnames(styles.wrapper, className)}>
      {tabs.map(tab => {
        const className = classnames(
          styles.button,
          styles[`button__${tabVariant}`],
          tab.id === activeTab.id && styles.active,
          tab.disabled && styles.disabled,
          tab.navigationBlocked && styles.navigationBlocked
        );
        return !tab.link ? (
          <div
            key={tab.id}
            className={className}
            onClick={() => !tab.disabled && clickHandler && clickHandler(tab)}
            data-test={formatToHyphenatedString(tab.label)}
            aria-selected={tab.id === activeTab.id}
            role="tab"
          >
            {t(tab.label)}
          </div>
        ) : (
          <Link
            key={tab.id}
            to={tab.link}
            className={className}
            data-test={formatToHyphenatedString(tab.label)}
            aria-selected={tab.id === activeTab.id}
          >
            {t(tab.label)}
          </Link>
        );
      })}
    </div>
  );
};

export default Tabs;
