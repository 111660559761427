import React from 'react';
import {Row} from '@tanstack/react-table';
import EntityPreview from '../../../fund-structure/entity-preview/EntityPreview';
import {EntityRow} from '../../../../types';

const EntitiesTableExpandedRow = ({row}: {row: Row<EntityRow>}) => {
  return (
    <EntityPreview
      type={row.original.type}
      name={row.original.name}
      headerData={row.original.headerData}
      serviceProviders={row.original.serviceProviders}
      capitalData={row.original.capitalData}
      fundEntityId={row.original.fundEntityId}
    />
  );
};

export default EntitiesTableExpandedRow;
