import {
  HeaderMapping,
  ImagesExportPayload,
  MetricsChartDataItem,
  TableExportPayload,
  TableMeta,
  TableSettings,
} from '../../../types';

const getMeta: TableMeta = (headerMapping: HeaderMapping[]) => ({
  columns: headerMapping.reduce((acc: TableMeta, item) => {
    if (item.columnId === 'date') {
      return {
        ...acc,
        date: {
          styling: {
            align: 'left',
          },
        },
      };
    }
    return {
      ...acc,
      [item.columnId]: {
        styling: {
          align: 'right',
        },
      },
    };
  }, {}),
});
export const metricsTableSettings = (
  zipFileName: string,
  headerMapping: HeaderMapping[]
): TableSettings => {
  return {
    fileName: zipFileName,
    name: 'Monitoring',
    columnVisibility: {},
    headerMapping: headerMapping,
    columnOrder: headerMapping.map(item => item.columnId),
    meta: getMeta(headerMapping),
  };
};

export const getMetricsTableExportPayload = (
  data: MetricsChartDataItem[],
  headerMapping: HeaderMapping[],
  zipFileName: string
): TableExportPayload[] => {
  return [
    {
      data: data,
      mappings: headerMapping,
      settings: metricsTableSettings(zipFileName, headerMapping),
    },
  ];
};

export const getMetricsChartExportPayload = (
  zipFileName: string
): ImagesExportPayload => {
  return {
    id: 'monitoring-metrics-chart',
    fileName: zipFileName,
    opts: [
      {
        fileName: `${zipFileName}_TransparentBackground`,
        type: 'image/svg+xml',
        ext: 'svg',
        bgColor: 'transparent',
        elsToFilter: ['monitoring-metrics-chart-tooltip'],
      },
      {
        fileName: `${zipFileName}_WithBackground`,
        type: 'image/svg+xml',
        ext: 'svg',
        bgColor: 'black',
        elsToFilter: ['monitoring-metrics-chart-tooltip'],
      },
      {
        fileName: `${zipFileName}_TransparentBackground`,
        type: 'image/png',
        ext: 'png',
        bgColor: 'transparent',
        elsToFilter: ['monitoring-metrics-chart-tooltip'],
      },
      {
        fileName: `${zipFileName}_WithBackground`,
        type: 'image/png',
        ext: 'png',
        bgColor: 'black',
        elsToFilter: ['monitoring-metrics-chart-tooltip'],
      },
    ],
  };
};
