import {acquireAuthResult, msalInstance} from '../../index';
import {all, call, put, takeEvery} from 'redux-saga/effects';
import {PayloadAction} from '@reduxjs/toolkit';
import {replace} from 'connected-react-router';
import routePaths from '../../routePaths';
import {
  ClientAdminPortfolioCompanyUsers,
  CompanyUser,
  FormStatus,
  UserRole,
} from '../../types';
import {getClientAdminPortfolioCompanyUsers} from '../../api/getClientAdminPortfolioCompanyUsers';
import {errorHandlerAction} from '../../actions';
import {
  deletePortfolioCompanyUserDataAction,
  reactivatePortfolioCompanyUserDataAction,
  resendInvitePortfolioCompanyUserDataAction,
  setClientAdminPortfolioCompanyUsersFormStatus,
  setClientAdminPortfolioCompanyUsersIsReinvitePending,
  setClientAdminPortfolioCompanyUsersIsRequestPending,
  setClientAminPortfolioCompanyUsersData,
  updateClientAdminPortfolioCompanyUsersData,
  updatePortfolioCompanyUserDataAction,
} from '../../reducers/clientAdminPortfolioCompanyUsersSlice';
import {
  reactivateUser,
  removeUser,
  resendUserInvite,
  updateUser,
} from '../../api/getClientAdminUsers';
import {usersResponseErrorResolver} from '../../utils';
import {setGlobalInformation} from '../../reducers/globalInformationSlice';
import {globalInfoMessage} from '../../constants';
import i18n from '../../i18n';

export const clientAdminPortfolioCompanyUsersSaga =
  function* clientAdminPortfolioCompanyUsersSaga(): any {
    const accountInfo = msalInstance.getAllAccounts();

    if (accountInfo?.length > 0) {
      const userRole = accountInfo[0].idTokenClaims?.extension_ApplicationRole;
      if (userRole !== UserRole.ClientAdmin) {
        return yield put(replace(routePaths.HOME));
      }
      try {
        const {accessToken} = yield call(acquireAuthResult, msalInstance);
        const response: ClientAdminPortfolioCompanyUsers = yield call(
          getClientAdminPortfolioCompanyUsers,
          accessToken
        );
        yield put(setClientAminPortfolioCompanyUsersData(response));
      } catch (err) {
        console.warn(err);
        yield put(errorHandlerAction({error: err}));
      }
    }
  };

export const updatePortfolioCompanyUserSaga =
  function* updatePortfolioCompanyUserSaga({
    payload,
  }: PayloadAction<CompanyUser>) {
    try {
      const {accessToken} = yield call(acquireAuthResult, msalInstance);
      yield put(
        setClientAdminPortfolioCompanyUsersFormStatus(FormStatus.Pending)
      );

      const response: CompanyUser = yield call(
        updateUser,
        accessToken,
        payload.id,
        payload
      );
      yield put(updateClientAdminPortfolioCompanyUsersData(response));
      yield put(
        setClientAdminPortfolioCompanyUsersFormStatus(FormStatus.Ready)
      );
    } catch (error: any) {
      // error resolver helper
      const responseError = usersResponseErrorResolver(error);
      if (responseError) {
        yield put(setGlobalInformation(responseError));
      }
      yield put(
        setClientAdminPortfolioCompanyUsersFormStatus(FormStatus.Ready)
      );
      console.warn(error);
    }
  };

export const deletePortfolioCompanyUserSaga =
  function* deletePortfolioCompanyUserSaga({
    payload,
  }: PayloadAction<{id: string}>) {
    try {
      const {accessToken} = yield call(acquireAuthResult, msalInstance);
      yield put(setClientAdminPortfolioCompanyUsersIsRequestPending(true));

      const response: CompanyUser = yield call(
        removeUser,
        accessToken,
        payload.id
      );
      yield put(updateClientAdminPortfolioCompanyUsersData(response));
      yield put(setClientAdminPortfolioCompanyUsersIsRequestPending(false));
    } catch (error: any) {
      const responseError = usersResponseErrorResolver(error);
      if (responseError) {
        yield put(setGlobalInformation(responseError));
      }
      yield put(setClientAdminPortfolioCompanyUsersIsRequestPending(false));
      console.warn(error);
    }
  };

export const resendInvitePortfolioCompanyUserSaga =
  function* resendInvitePortfolioCompanyUserSaga({
    payload,
  }: PayloadAction<{id: string}>) {
    try {
      const {accessToken} = yield call(acquireAuthResult, msalInstance);
      yield put(setClientAdminPortfolioCompanyUsersIsReinvitePending(true));
      const response: CompanyUser = yield call(
        resendUserInvite,
        accessToken,
        payload
      );
      yield put(updateClientAdminPortfolioCompanyUsersData(response));

      const reinviteSuccessInfoMsg = globalInfoMessage();

      yield put(setGlobalInformation(reinviteSuccessInfoMsg));
      yield put(setClientAdminPortfolioCompanyUsersIsReinvitePending(false));
    } catch (error: any) {
      const responseError = usersResponseErrorResolver(error);
      if (responseError) {
        yield put(setGlobalInformation(responseError));
      }
      yield put(setClientAdminPortfolioCompanyUsersIsReinvitePending(false));
      console.warn(error);
    }
  };

export const reactivatePortfolioCompanyUserSaga =
  function* reactivatePortfolioCompanyUserSaga({
    payload,
  }: PayloadAction<{id: string}>) {
    try {
      const {accessToken} = yield call(acquireAuthResult, msalInstance);
      yield put(setClientAdminPortfolioCompanyUsersIsReinvitePending(true));
      const response: CompanyUser = yield call(
        reactivateUser,
        accessToken,
        payload
      );
      yield put(updateClientAdminPortfolioCompanyUsersData(response));
      const reactivateSuccessInfoMsg = {
        ...globalInfoMessage(),
        message: i18n.t('Admin.UserAdministration.Notification.Reactivated'),
      };

      yield put(setGlobalInformation(reactivateSuccessInfoMsg));
      yield put(setClientAdminPortfolioCompanyUsersIsReinvitePending(false));
    } catch (error: any) {
      const responseError = usersResponseErrorResolver(error);
      if (responseError) {
        yield put(setGlobalInformation(responseError));
      }
      yield put(setClientAdminPortfolioCompanyUsersIsReinvitePending(false));
      console.warn(error);
    }
  };

export const clientAdminPortfolioCompanyUsersSagaWatcher =
  function* clientAdminPortfolioCompanyUsersSagaWatcher() {
    yield all([
      takeEvery(
        updatePortfolioCompanyUserDataAction.type,
        updatePortfolioCompanyUserSaga
      ),
      takeEvery(
        deletePortfolioCompanyUserDataAction.type,
        deletePortfolioCompanyUserSaga
      ),
      takeEvery(
        resendInvitePortfolioCompanyUserDataAction.type,
        resendInvitePortfolioCompanyUserSaga
      ),
      takeEvery(
        reactivatePortfolioCompanyUserDataAction.type,
        reactivatePortfolioCompanyUserSaga
      ),
    ]);
  };
