import React, {Fragment} from 'react';
import styles from './ReportingPeriodsInfo.module.scss';
import {ReportingPeriodsInfoProps} from '../../../../../types';
import ValueFormat from '../../../value-format/ValueFormat';
import {useTranslation} from 'react-i18next';
import classNames from 'classnames';

const ReportingPeriodsInfo: React.FC<ReportingPeriodsInfoProps> = ({
  reportingPeriods,
}) => {
  const {t} = useTranslation();
  return (
    <>
      <p className={styles.title}>{t('Documents.ReportingPeriodInfo.Title')}</p>

      <div className={styles.wrapper}>
        <span className={styles.reportingPeriodsHeader}>
          {t('Global.ReportingGroup')}
        </span>
        <span className={styles.reportingPeriodsHeader}>
          {t('Global.Report')}
        </span>
        <span className={styles.reportingPeriodsHeader}>
          {t('Global.Template')}
        </span>
        <span className={styles.reportingPeriodsHeader}>
          {t('Global.Period')}
        </span>
        <span
          className={classNames(styles.reportingPeriodsHeader, styles.filesCol)}
        >
          {t('Global.Files')}
        </span>

        {reportingPeriods.map(item => (
          <Fragment key={item.id}>
            <span>{item.reportingGroup}</span>
            <span>{item.report}</span>
            <span>{item.template}</span>
            <span>
              <ValueFormat
                value={item.period?.value}
                format={item.period?.format}
              />
            </span>
            <span className={styles.filesCol}>{item.files || '-'}</span>
          </Fragment>
        ))}
      </div>
    </>
  );
};

export default ReportingPeriodsInfo;
