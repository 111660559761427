import {createAction, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {
  ActiveCompanyData,
  CapitalWidgetType,
  FundOverview,
  NavTimeSeries,
  ComponentWidgetType,
  FundPerformanceKpiGroups,
} from '../types';

export const updatePortfolioCompaniesAction = createAction(
  'fund-details-overview/update-companies',
  (fundId: string, page: number) => {
    return {
      payload: {
        fundId,
        page,
      },
    };
  }
);

export const getFundBenchmarkingDataAction = createAction(
  'fund-details-overview/get-benchmarking-data',
  (
    fundId: string,
    kpiName: string,
    source: string,
    kpiGroupName: FundPerformanceKpiGroups
  ) => {
    return {
      payload: {
        fundId,
        kpiName,
        source,
        kpiGroupName,
      },
    };
  }
);

interface FundDetailsOverviewData {
  data: FundOverview | null;
}

const initialState: FundDetailsOverviewData = {
  data: null,
};

const fundDetailsOverviewSlice = createSlice({
  name: 'fund-details-overview',
  initialState,
  reducers: {
    setFundOverviewData(
      state: FundDetailsOverviewData,
      action: PayloadAction<FundOverview>
    ) {
      state.data = action.payload;
    },
    clearFundOverviewData(state: FundDetailsOverviewData) {
      state.data = null;
    },
    updatePortfolioCompaniesList(
      state: FundDetailsOverviewData,
      action: PayloadAction<{
        page: number;
        widgets: ActiveCompanyData[];
      }>
    ) {
      const {page, widgets} = action.payload;
      if (state.data?.portfolioData?.data) {
        state.data.portfolioData.data[page] = widgets;
      }
    },
    getFundOverviewCapitalData(
      state: FundDetailsOverviewData,
      action: PayloadAction<{fundId: string; date: string}>
    ) {
      if (state.data?.capitalData) {
        state.data.capitalData.isLoading = true;
      }
    },
    updateFundOverviewCapitalData(
      state: FundDetailsOverviewData,
      action: PayloadAction<CapitalWidgetType[]>
    ) {
      if (state.data?.capitalData) {
        state.data.capitalData.widgets = action.payload;
        state.data.capitalData.isLoading = false;
      }
    },
    getFundOverviewExposureData(
      state: FundDetailsOverviewData,
      action: PayloadAction<{
        fundId: string;
        date: string;
        sortValue: string;
        sortDirection: string;
      }>
    ) {
      if (state.data?.overallFundExposureData) {
        state.data.overallFundExposureData.isLoading = true;
      }
    },
    updateFundOverviewExposureData(
      state: FundDetailsOverviewData,
      action: PayloadAction<ComponentWidgetType[]>
    ) {
      if (state.data?.overallFundExposureData) {
        state.data.overallFundExposureData.widgets = action.payload;
        state.data.overallFundExposureData.isLoading = false;
      }
    },
    setFundBenchmarkingData(
      state: FundDetailsOverviewData,
      action: PayloadAction<{
        data: NavTimeSeries[];
        kpiGroupName: FundPerformanceKpiGroups;
      }>
    ) {
      const {data, kpiGroupName} = action.payload;
      if (state.data?.fundPerformanceData.widgets[0][kpiGroupName]) {
        state.data.fundPerformanceData.widgets[0][
          kpiGroupName
        ].benchmarkingTimeSeries = {data};
      }
    },
    clearFundBenchmarkingData(
      state: FundDetailsOverviewData,
      action: PayloadAction<{
        kpiGroupName: FundPerformanceKpiGroups;
      }>
    ) {
      const {kpiGroupName} = action.payload;
      if (
        state.data?.fundPerformanceData.widgets[0][kpiGroupName]
          .benchmarkingTimeSeries
      ) {
        state.data.fundPerformanceData.widgets[0][
          kpiGroupName
        ].benchmarkingTimeSeries = null;
      }
    },
  },
});

export const {
  setFundOverviewData,
  clearFundOverviewData,
  updatePortfolioCompaniesList,
  updateFundOverviewCapitalData,
  updateFundOverviewExposureData,
  getFundOverviewCapitalData,
  getFundOverviewExposureData,
  setFundBenchmarkingData,
  clearFundBenchmarkingData,
} = fundDetailsOverviewSlice.actions;

export default fundDetailsOverviewSlice.reducer;
