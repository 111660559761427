import {all, call, put, takeEvery} from 'redux-saga/effects';
import {acquireAuthResult, msalInstance} from '../..';
import {PayloadAction} from '@reduxjs/toolkit';
import {errorHandlerAction} from '../../actions';
import {
  clearScenarioDetailsMainInfoData,
  clearScenarioDuplicated,
  createNewScenarioAction,
  getScenarioDetailsMainInfoDataAction,
  setLoading,
  setScenarioEditMode,
  updateScenarioAction,
} from '../../reducers/scenarioDetailsMainInfoSlice';
import {
  postScenariosNewData,
  getScenarioData,
  updateScenarioData,
} from '../../api/getScenarioMainInfo';
import {setScenarioMainInfoData} from '../../reducers/scenarioDetailsMainInfoSlice';
import {NearCastingScenario, UpdateNearCastingScenario} from '../../types';

export const scenarioDetailsMainSaga = function* scenarioDetailsMainSaga({
  payload,
}: PayloadAction<{
  match: any;
  id: string;
}>) {
  const id: string = payload?.match?.params?.id;
  if (id) {
    yield put(setLoading());
    yield put(clearScenarioDetailsMainInfoData());
    try {
      const {accessToken} = yield call(acquireAuthResult, msalInstance);
      const response: NearCastingScenario = yield call(
        getScenarioData,
        accessToken,
        id
      );
      yield put(setScenarioMainInfoData(response));
      yield put(setLoading());
    } catch (err) {
      console.warn(err);
      yield put(setLoading());
      yield put(errorHandlerAction({error: err}));
    }
  }
};

export const editScenarioSaga = function* editScenarioSaga({
  payload,
}: PayloadAction<{
  match: any;
  id: string;
}>) {
  const id: string = payload?.match?.params?.id;
  if (id) {
    yield put(setLoading());
    yield put(clearScenarioDetailsMainInfoData());
    try {
      const {accessToken} = yield call(acquireAuthResult, msalInstance);
      const response: NearCastingScenario = yield call(
        getScenarioData,
        accessToken,
        id
      );
      yield put(setScenarioMainInfoData(response));
      yield put(setScenarioEditMode());
      yield put(setLoading());
    } catch (err) {
      console.warn(err);
      yield put(setLoading());
      yield put(errorHandlerAction({error: err}));
    }
  }
};

export const postScenarioDetailsMainSaga =
  function* postScenarioDetailsMainSaga({payload}: PayloadAction) {
    yield put(setLoading());
    yield put(clearScenarioDetailsMainInfoData());
    try {
      const {accessToken} = yield call(acquireAuthResult, msalInstance);
      const response: NearCastingScenario = yield call(
        postScenariosNewData,
        accessToken,
        payload
      );
      yield put(setScenarioMainInfoData(response));
      yield put(setLoading());
    } catch (err) {
      console.warn(err);
      yield put(setLoading());
      yield put(errorHandlerAction({error: err}));
    }
  };

export const updateScenarioDetailsSaga = function* updateScenarioDetailsSaga({
  payload,
}: PayloadAction<{
  id: string;
  updatePayload: UpdateNearCastingScenario;
}>) {
  yield put(setLoading());
  yield put(clearScenarioDetailsMainInfoData());
  try {
    const {accessToken} = yield call(acquireAuthResult, msalInstance);
    const response: NearCastingScenario = yield call(
      updateScenarioData,
      accessToken,
      payload.id,
      payload.updatePayload
    );
    yield put(setScenarioMainInfoData(response));
    yield put(clearScenarioDuplicated());
    yield put(setScenarioEditMode());
    yield put(setLoading());
  } catch (err) {
    console.warn(err);
    yield put(setLoading());
    yield put(errorHandlerAction({error: err}));
  }
};

export const scenarioDetailsMainInfoSagaWatcher =
  function* scenarioDetailsMainInfoSagaWatcher() {
    yield all([
      takeEvery(
        getScenarioDetailsMainInfoDataAction.type,
        scenarioDetailsMainSaga
      ),
    ]);
    yield all([
      takeEvery(createNewScenarioAction.type, postScenarioDetailsMainSaga),
    ]);
    yield all([
      takeEvery(updateScenarioAction.type, updateScenarioDetailsSaga),
    ]);
  };
