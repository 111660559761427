import React, {useMemo} from 'react';
import classnames from 'classnames';
import {
  ActivityBadgeProps,
  ExternalLinkProps,
  FundInfoCellData,
  InfoHeaderType,
  InfoHeaderTypeNames,
  InstrumentTypesProps,
  StyleVariant,
} from '../../../types';
import {
  ActivityBadge,
  CellLabel,
  ChartCell,
  CommonCell,
  ExpandableDescriptionCell,
  ExternalLink,
  FundInfoCell,
  InstrumentTypes,
  ListItemsCell,
} from '../';
import styles from './InfoHeader.module.scss';

export interface InfoHeaderProps {
  data: InfoHeaderType[];
  className?: string;
  styleType?: StyleVariant;
  gridColumnWidth?: number;
}

const InfoHeader: React.FC<InfoHeaderProps> = ({
  data,
  className = '',
  styleType = StyleVariant.Primary,
  gridColumnWidth = null,
}) => {
  const {gridData, flexData} = useMemo(() => {
    const gridData = data.filter(
      item => item.type !== InfoHeaderTypeNames.FundInfo
    );
    const flexData = data.filter(
      item => item.type === InfoHeaderTypeNames.FundInfo
    );
    return {
      gridData,
      flexData,
    };
  }, [data]);

  const transformedGridData = useMemo<InfoHeaderType[]>(() => {
    return gridData.map(item => {
      if (item.value !== undefined && item.value !== null) return item;
      return {
        label: item.label,
        value: '-',
        gridColumn: item.gridColumn,
        gridColumnStart: item.gridColumnStart,
      };
    });
  }, [gridData]);

  return (
    <div
      className={classnames(
        styles.wrapper,
        className,
        styles[`wrapper__${styleType}`]
      )}
    >
      <div className={styles.flexInner}>
        {flexData.map((item, idx) => (
          <div key={idx} className={styles.flexColumn}>
            <CellLabel label={item.label} tooltip={item.tooltip} />
            {item.type === InfoHeaderTypeNames.FundInfo &&
            Array.isArray(item.value) ? (
              <FundInfoCell
                key={idx}
                value={item.value as FundInfoCellData[]}
              />
            ) : null}
          </div>
        ))}
      </div>
      <div
        style={{
          ...(gridColumnWidth
            ? {
                gridTemplateColumns: `repeat(auto-fill, minmax(${gridColumnWidth}px, 1fr))`,
              }
            : {}),
        }}
        className={styles.gridInner}
      >
        {transformedGridData.map((item, idx) => {
          return (
            <div
              className={classnames(
                styles.column,
                item.id === 'fund.structure-entity.lei.code' &&
                  styles.column__nobreak
              )}
              key={(item.value || '') + (item.label || '') + idx}
              style={{
                ...(item.gridColumn ? {gridColumn: item.gridColumn} : {}),
                ...(item.gridColumnStart
                  ? {gridColumnStart: item.gridColumnStart}
                  : {}),
              }}
            >
              {item.type !== InfoHeaderTypeNames.DonutChart ? (
                <CellLabel
                  label={item.label}
                  tooltip={item.tooltip}
                  id="info-header"
                />
              ) : null}
              {item.type === InfoHeaderTypeNames.Icon ? (
                <InstrumentTypes
                  icons={(item.value as InstrumentTypesProps).icons}
                />
              ) : item.type === InfoHeaderTypeNames.Pill ? (
                <ActivityBadge
                  label={(item.value as ActivityBadgeProps).label}
                  color={(item.value as ActivityBadgeProps).color}
                  textColor={(item.value as ActivityBadgeProps).textColor}
                />
              ) : item.type === InfoHeaderTypeNames.Link ? (
                <ExternalLink links={(item.value as ExternalLinkProps).links} />
              ) : item.type === InfoHeaderTypeNames.DonutChart ? (
                <ChartCell
                  label={item.label}
                  tooltip={item.tooltip}
                  value={item.value as number}
                  format={item.format}
                />
              ) : item.type === InfoHeaderTypeNames.Expandable ? (
                <ExpandableDescriptionCell value={item.value} />
              ) : item.type === InfoHeaderTypeNames.ItemsList ? (
                <ListItemsCell items={item.value as string[]} />
              ) : item.type === InfoHeaderTypeNames.FundBars ? (
                <FundInfoCell
                  key={idx}
                  value={item.value as FundInfoCellData[]}
                  variant={StyleVariant.Secondary}
                />
              ) : (
                <CommonCell {...item} id="info-header" />
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default InfoHeader;
