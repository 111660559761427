import React, {RefObject, useLayoutEffect, useRef, useState} from 'react';
import classnames from 'classnames';
import {Func} from '../../../../../types';
import {IconButton} from '../../../index';
import styles from './ScrollButtons.module.scss';

interface ScrollButtonsProps {
  scrollPosition: 'left' | 'middle' | 'right';
  handleScrollBy: Func<[number], void>;
  containerRef: RefObject<HTMLDivElement>;
}

const ScrollButtons: React.FC<ScrollButtonsProps> = ({
  scrollPosition,
  handleScrollBy,
  containerRef,
}) => {
  const [buttonsState, setButtonsState] = useState<'top' | 'fixed' | 'bottom'>(
    'bottom'
  );

  const buttonsRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const offset = (el: HTMLElement | null) => {
      if (!el) return;
      const rect = el.getBoundingClientRect();
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      return rect.top + scrollTop;
    };
    const containerPosition = offset(containerRef.current);
    const onScroll = () => {
      if (!containerPosition || !containerRef.current) return;
      if (
        containerPosition + containerRef.current.clientHeight + 80 >
        window.scrollY + window.innerHeight
      ) {
        if (containerPosition + 80 > window.scrollY + window.innerHeight) {
          setButtonsState('top');
        } else {
          setButtonsState('fixed');
        }
      } else {
        setButtonsState('bottom');
      }
    };
    onScroll();
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  });

  return (
    <div
      ref={buttonsRef}
      className={classnames(styles.buttons, styles[`buttons__${buttonsState}`])}
    >
      <IconButton
        icon="chevron-left"
        disabled={scrollPosition === 'left'}
        onClick={() => handleScrollBy(-200)}
        className={classnames(styles.button)}
      />
      <IconButton
        icon="chevron-right"
        disabled={scrollPosition === 'right'}
        onClick={() => handleScrollBy(200)}
        className={classnames(styles.button)}
      />
    </div>
  );
};

export default ScrollButtons;
