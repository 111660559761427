import React from 'react';
import classnames from 'classnames';
import styles from './LoadingOverlay.module.scss';
import {ButtonSpinner} from '../../';

interface LoadingOverlayProps {
  className?: string;
}

const LoadingOverlay: React.FC<LoadingOverlayProps> = ({className = ''}) => {
  return (
    <div className={classnames(styles.wrapper, className)}>
      <ButtonSpinner className={styles.spinnerWrapper} />
    </div>
  );
};

export default LoadingOverlay;
