import {FileUploadProcess} from '../types';
import {createAction, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {getUploadIsFinished} from '../utils';

export const fileAlreadyExistsAction = createAction(
  'file-upload/file-already-exists',
  (option: string | null) => {
    return {
      payload: {
        option,
      },
    };
  }
);

interface FileUploadSlice {
  data: FileUploadProcess[] | null;
  showNotification: boolean;
  isNotificationMinimized: boolean;
  fileAlreadyExists: string | null;
}

const initialState: FileUploadSlice = {
  data: null,
  showNotification: false,
  isNotificationMinimized: false,
  fileAlreadyExists: null,
};

export const fileUploadSlice = createSlice({
  name: 'file-upload',
  initialState,
  reducers: {
    setFileUploadData(
      state: FileUploadSlice,
      action: PayloadAction<FileUploadProcess[] | null>
    ) {
      if (action.payload) {
        const prevState = state.data ?? [];
        const uploadsAreFinished = getUploadIsFinished(prevState);

        if (!prevState.length || (prevState && !uploadsAreFinished)) {
          state.showNotification = true;
        }
        state.data = [...prevState, ...action.payload];
      } else {
        state.data = action.payload;
      }
    },
    updateFileUploadData(
      state: FileUploadSlice,
      action: PayloadAction<FileUploadProcess>
    ) {
      if (state.data) {
        state.data = state.data.map(item => {
          if (item.id !== action.payload.id) return item;
          return action.payload;
        });

        const uploadsAreFinished = getUploadIsFinished(state.data ?? []);

        if (uploadsAreFinished) {
          state.showNotification = true;
          state.isNotificationMinimized = false;
        }
      }
    },
    setFileUploadShowNotification(
      state: FileUploadSlice,
      action: PayloadAction<boolean>
    ) {
      state.showNotification = action.payload;
    },
    setFileUploadIsNotificationMinimized(
      state: FileUploadSlice,
      action: PayloadAction<boolean>
    ) {
      state.isNotificationMinimized = action.payload;
    },
    setFileAlreadyExists(
      state: FileUploadSlice,
      action: PayloadAction<string | null>
    ) {
      state.fileAlreadyExists = action.payload;
    },
  },
});

export default fileUploadSlice.reducer;
export const {
  setFileUploadData,
  updateFileUploadData,
  setFileUploadShowNotification,
  setFileAlreadyExists,
  setFileUploadIsNotificationMinimized,
} = fileUploadSlice.actions;
