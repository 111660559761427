import {useAppSelector} from './useReduxHooks';
import {navInfoSelector} from '../selectors/';
import {useLocation} from 'react-router-dom';
import {matchPath} from 'react-router';
import routePaths from '../routePaths';
import {useEffect, useState} from 'react';
import {NavInfoDataType, PathName, SectionTag} from '../types';

const pathList = [
  {
    name: PathName.Home,
    path: routePaths.HOME,
    sectionTag: SectionTag.Dashboard,
  },
  {
    name: PathName.Funds,
    path: routePaths.FUNDS,
    sectionTag: SectionTag.Funds,
  },
  {
    name: PathName.FundOverview,
    path: routePaths.FUND_OVERVIEW,
    sectionTag: SectionTag.FundOverview,
  },
  {
    name: PathName.FundOperational,
    path: routePaths.FUND_OPERATIONAL,
    sectionTag: SectionTag.FundOperational,
  },
  {
    name: PathName.FundStructure,
    path: routePaths.FUND_STRUCTURE,
    sectionTag: SectionTag.FundStructure,
  },
  {
    name: PathName.FundPortfolio,
    path: routePaths.FUND_PORTFOLIO,
    sectionTag: SectionTag.FundPortfolioSummary,
  },
  {
    name: PathName.FundPortfolioTransactions,
    path: routePaths.FUND_PORTFOLIO_TRANSACTIONS,
    sectionTag: SectionTag.FundPortfolioTransactions,
  },
  {
    name: PathName.PortfolioCompanies,
    path: routePaths.PORTFOLIO_COMPANIES,
    sectionTag: SectionTag.PortfolioCompanies,
  },
  {
    name: PathName.PortfolioCompanyDetails,
    path: routePaths.PORTFOLIO_COMPANY_DETAILS,
    sectionTag: null,
  },
  {
    name: PathName.PortfolioCompanyCompany,
    path: routePaths.PORTFOLIO_COMPANY_COMPANY,
    sectionTag: SectionTag.PortfolioCompany,
  },
  {
    name: PathName.PortfolioCompanyInvestmentOverview,
    path: routePaths.PORTFOLIO_COMPANY_INVESTMENT_OVERVIEW,
    sectionTag: SectionTag.PortfolioCompanyInvestmentOverview,
  },
  {
    name: PathName.PortfolioCompanyMonitoring,
    path: routePaths.PORTFOLIO_COMPANY_MONITORING,
    sectionTag: SectionTag.PortfolioCompanyMonitoring,
  },
  {
    name: PathName.DataCollectionDetails,
    path: routePaths.DATA_COLLECTION_DETAILS,
    sectionTag: SectionTag.DataCollectionDetails,
  },
  {
    name: PathName.Reporting,
    path: routePaths.REPORTING,
    sectionTag: SectionTag.DataCollectionReporting,
  },
];

const useNavInfo = (): NavInfoDataType => {
  const [navInfoData, setNavInfoData] = useState<NavInfoDataType>({
    name: null,
    asAt: null,
    withLogo: false,
    color: null,
    allCompaniesCount: null,
    sectionTag: null,
  });

  const {pathname} = useLocation();
  const state = useAppSelector(state => state);
  const data = navInfoSelector(state);

  useEffect(() => {
    const matchedPath = pathList.find(item =>
      matchPath(pathname, {
        path: item.path,
        exact: true,
        strict: false,
      })
    );
    switch (matchedPath?.name) {
      case PathName.Home:
        setNavInfoData({
          name: data.clientDashboard?.clientName ?? null,
          asAt: data.clientDashboard?.asAt ?? null,
          withLogo: true,
          color: null,
          allCompaniesCount: null,
          sectionTag: matchedPath.sectionTag,
        });
        break;
      case PathName.Funds:
        setNavInfoData({
          name: data.funds?.clientName ?? null,
          asAt: data.funds?.asAt ?? null,
          withLogo: true,
          color: null,
          allCompaniesCount: null,
          sectionTag: matchedPath.sectionTag,
        });
        break;
      case PathName.FundOverview:
      case PathName.FundOperational:
      case PathName.FundStructure:
      case PathName.FundPortfolio:
      case PathName.FundPortfolioTransactions:
        setNavInfoData({
          name: data.fundName ?? null,
          asAt: data.asAtFund ?? null,
          withLogo: false,
          color: data.fundColor ?? null,
          allCompaniesCount: null,
          sectionTag: matchedPath.sectionTag,
        });
        break;
      case PathName.PortfolioCompanies:
        setNavInfoData({
          name: 'Portfolio companies',
          asAt: null,
          withLogo: false,
          color: null,
          allCompaniesCount: data.companies?.allCompaniesCount ?? null,
          sectionTag: matchedPath.sectionTag,
        });
        break;
      case PathName.PortfolioCompanyDetails:
      case PathName.PortfolioCompanyCompany:
      case PathName.PortfolioCompanyMonitoring:
      case PathName.PortfolioCompanyInvestmentOverview:
        setNavInfoData({
          name: data.companyName ?? null,
          asAt: data.asAtCompany ?? null,
          withLogo: true,
          color: data.companyColor ?? null,
          allCompaniesCount: null,
          sectionTag: matchedPath.sectionTag,
        });
        break;
      case PathName.DataCollectionDetails:
        setNavInfoData({
          name: data.companyName ?? null,
          asAt: data.asAtCompany ?? null,
          withLogo: true,
          color: data.companyColor ?? null,
          allCompaniesCount: null,
          sectionTag: matchedPath.sectionTag,
        });
        break;
      case PathName.Reporting:
        setNavInfoData({
          name: data.companyName ?? null,
          asAt: data.asAtCompany ?? null,
          withLogo: true,
          color: data.companyColor ?? null,
          allCompaniesCount: null,
          sectionTag: matchedPath.sectionTag,
        });
        break;
      default:
        setNavInfoData({
          name: null,
          asAt: null,
          withLogo: false,
          color: null,
          allCompaniesCount: null,
          sectionTag: null,
        });
    }
  }, [pathname, data]);

  return navInfoData;
};

export default useNavInfo;
