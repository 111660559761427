import React, {Fragment, useRef, useState} from 'react';
import {
  ExpandedState,
  useReactTable,
  getCoreRowModel,
  getExpandedRowModel,
  ColumnDef,
  flexRender,
  Row,
  getSortedRowModel,
  SortingState,
  TableMeta as TanstackTableMeta,
} from '@tanstack/react-table';
import styles from './TransactionsTable.module.scss';
import {GenericExpandedRow} from '../../../../types';
import {useTableScroll} from '../../../../hooks';
import ScrollButtons from '../components/scroll-buttons/ScrollButtons';
import ScrollContainer from '../components/scroll-container/ScrollContainer';
import classNames from 'classnames';

export type ColumnVisibilityState = Record<string, boolean>;

export type ColumnOrderState = string[];

export type TransactionsTableProps<TData> = {
  data: TData[];
  columns: ColumnDef<TData>[];
  expandedView: ({row}: GenericExpandedRow<TData>) => React.ReactElement;
  expandedHandler?: (row: Row<TData>) => void;
  getRowCanExpand: (row: Row<TData>) => boolean;
  columnVisibility?: ColumnVisibilityState;
  columnOrder?: ColumnOrderState;
  scrollableFromWidth?: string;
  defaultSorting?: SortingState;
  className?: string;
  meta?: TanstackTableMeta<TData>;
};

// @TODO Should extend the GenericTable to support pagination which will be needed for new pages
const TransactionsTable = <TData extends object>({
  data,
  columns,
  expandedView,
  expandedHandler,
  getRowCanExpand,
  columnVisibility = {},
  columnOrder = [],
  scrollableFromWidth,
  defaultSorting = [],
  className,
  meta,
}: TransactionsTableProps<TData>): JSX.Element => {
  const [sorting, setSorting] = useState<SortingState>(defaultSorting);
  const [expanded, setExpanded] = React.useState<ExpandedState>({});

  const scrollRef = useRef<HTMLDivElement>(null);

  const {handleScrollContainer, handleScrollBy, scrollPosition, isScrollable} =
    useTableScroll({scrollContainerRef: scrollRef});

  const table = useReactTable({
    data,
    columns,
    getRowCanExpand,
    state: {
      expanded,
      sorting,
      columnVisibility,
      columnOrder,
    },
    onSortingChange: setSorting,
    onExpandedChange: setExpanded,
    getSubRows: (row: any) => row.subRows,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    ...(meta ? {meta} : {}),
  });

  // Expand all rows
  if (!table.getIsAllRowsExpanded()) table.toggleAllRowsExpanded(true);

  return (
    <div className={classNames(styles.wrapper, className)}>
      {isScrollable && (
        <ScrollButtons
          handleScrollBy={handleScrollBy}
          scrollPosition={scrollPosition}
          containerRef={scrollRef}
        />
      )}
      <ScrollContainer
        ref={scrollRef}
        onScroll={handleScrollContainer}
        scrollableFromWidth={scrollableFromWidth}
      >
        <table className={styles.table}>
          <thead>
            {table.getHeaderGroups().map(headerGroup => (
              <tr className={styles.theadRow} key={headerGroup.id}>
                {headerGroup.headers.map(header => {
                  return (
                    <Fragment key={header.id}>
                      {/* This is a header row */}
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                    </Fragment>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map(row => {
              return (
                <Fragment key={row.id}>
                  <tr
                    className={classNames(
                      styles.tbodyRow,
                      table.options?.meta?.getRowClassNames?.(row)
                    )}
                  >
                    {/* This is a normal row */}
                    {row.getVisibleCells().map(cell => {
                      return (
                        <Fragment key={cell.id}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </Fragment>
                      );
                    })}
                  </tr>
                </Fragment>
              );
            })}
          </tbody>
          <tfoot>
            {/* This is a footer row */}
            {table.getFooterGroups().map(footerGroup => (
              <tr key={footerGroup.id}>
                {footerGroup.headers.map(header => (
                  <Fragment key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.footer,
                          header.getContext()
                        )}
                  </Fragment>
                ))}
              </tr>
            ))}
          </tfoot>
        </table>
      </ScrollContainer>
    </div>
  );
};

export default TransactionsTable;
