import React, {useRef} from 'react';
import classnames from 'classnames';
import {ButtonSize, Func} from '../../../../../types';
import {IconButton} from '../../../../global';
import {useOnOutsideClick} from '../../../../../hooks';
import styles from './InsightTooltip.module.scss';

interface InsightTooltipProps {
  info: string;
  handleClose: Func<[], void>;
  isOpen: boolean;
}
const InsightTooltip: React.FC<InsightTooltipProps> = ({
  info,
  handleClose,
  isOpen,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  useOnOutsideClick(ref, handleClose);
  return (
    <div
      ref={ref}
      className={classnames(styles.wrapper, isOpen && styles.open)}
    >
      <IconButton
        onClick={handleClose}
        icon={'cross'}
        className={styles.closeButton}
        buttonSize={ButtonSize.Small}
      />
      <div className={styles.inner}>
        <div
          className={styles.content}
          dangerouslySetInnerHTML={{__html: info}}
        />
      </div>
    </div>
  );
};

export default InsightTooltip;
