/**
 * App config
 */
const config = {
  // General
  VERSION: process.env.VERSION,
  BUILD: process.env.BUILD,
  IS_PROD: process.env.NODE_ENV === 'production',
  PUBLIC_URL: process.env.PUBLIC_URL || '/',
  LOCALE: process.env.LOCALE || 'en',
  MSAL_CLIENT_ID: process.env.REACT_APP_MSAL_CLIENT_ID,
  MSAL_AUTHORITY: process.env.REACT_APP_MSAL_AUTHORITY,
  MSAL_KNOWN_AUTHORITIES: process.env.REACT_APP_MSAL_KNOWN_AUTHORITIES,
  MSAL_REDIRECT_URI: process.env.REACT_APP_MSAL_REDIRECT_URI,
  MSAL_POST_LOGOUT_REDIRECT_URI:
    process.env.REACT_APP_MSAL_POST_LOGOUT_REDIRECT_URI,
  MSAL_STORE_AUTH_STATE_IN_COOKIE:
    process.env.REACT_APP_MSAL_STORE_AUTH_STATE_IN_COOKIE === 'true',
  MSAL_SECURE_COOKIES: process.env.REACT_APP_MSAL_SECURE_COOKIES === 'true',
  APP_URI: process.env.REACT_APP_APP_URI,
  API_SCOPES: process.env.REACT_APP_API_SCOPES || '',
  GA_TAG_MANAGER_ID: process.env.REACT_APP_GA_TAG_MANAGER_ID,
  SEGMENT_KEY: process.env.REACT_APP_SEGMENT_KEY,
  YFILES_LICENSE_KEY: process.env.REACT_APP_YFILES_LICENSE_KEY,
  LAUNCHDARKLY_CLIENT_ID: process.env.REACT_APP_LAUNCHDARKLY_CLIENT_ID,
  SESSION_TIMEOUT_MS: process.env.REACT_APP_SESSION_TIMEOUT_MS,
  APP_ENV: process.env.REACT_APP_APP_ENV,
};

export default config;
