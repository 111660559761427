import Gradient from 'javascript-color-gradient';
import {TreemapColorRange, TreemapData, TreemapNodeData} from '../types';

export const getTreemapDataWithColors = (
  treemapData: TreemapData,
  colors: TreemapColorRange[]
): TreemapData => {
  const generatedColors = generateColors(treemapData.children.length, colors);
  const dataWithColors: TreemapNodeData[] = treemapData.children
    .slice()
    .sort((a, b) => b.value - a.value)
    .map((item, index) => {
      return {
        ...item,
        color: generatedColors[index],
        sankeyData: {
          ...item.sankeyData,
          color: generatedColors[index],
        },
      };
    });

  return {
    ...treemapData,
    children: dataWithColors,
  };
};

export const createGradients = (
  firstColor: string,
  secondColor: string,
  numOfGradients: number
) => {
  if (numOfGradients === 2) {
    // If we need just two colors
    return [firstColor, secondColor];
  }

  if (numOfGradients <= 1) {
    // If we need just one color, use middle value
    const middleColor = new Gradient()
      .setColorGradient(firstColor, secondColor)
      .setMidpoint(3)
      .getColors();

    return [middleColor[1]];
  }

  // If we need more than two colors
  return new Gradient()
    .setColorGradient(firstColor, secondColor)
    .setMidpoint(numOfGradients)
    .getColors();
};

// generateColors function supports how many color ranges we want
const generateColors = (
  numberOfColors: number,
  colors: TreemapColorRange[]
) => {
  const numberOfShades = Math.round(numberOfColors / 4);
  const lastNumberOfShades = numberOfColors - numberOfShades * 3;

  return colors
    .map((color, index) => {
      if (index === colors.length - 1) {
        return createGradients(color.first, color.last, lastNumberOfShades);
      }
      return createGradients(color.first, color.last, numberOfShades);
    })
    .flat(2);
};

export const brightnessByColor = (color: string) => {
  const isHEX = color.indexOf('#') === 0;
  const isRGB = color.indexOf('rgb') === 0;
  let r = 0;
  let g = 0;
  let b = 0;

  if (isHEX) {
    const m = color
      .substr(1)
      .match(color.length === 7 ? /(\S{2})/g : /(\S{1})/g);
    if (m) {
      r = parseInt(m[0], 16);
      g = parseInt(m[1], 16);
      b = parseInt(m[2], 16);
    }
  }
  if (isRGB) {
    const m = color.match(/(\d+){3}/g);
    if (m) {
      r = +m[0];
      g = +m[1];
      b = +m[2];
    }
  }
  return (r * 299 + g * 587 + b * 114) / 1000;
};
