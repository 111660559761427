import {put, all, takeLatest} from 'redux-saga/effects';
import {clearFundDetailsDataAction} from '../../actions';
import {clearFundOverviewData} from '../../reducers/fundDetailsOverviewSlice';
import {clearFundNearCastingData} from '../../reducers/fundDetailsNearCastingSlice';
import {clearFundPortfolioData} from '../../reducers/fundDetailsPortfolioSlice';
import {clearFundStructureData} from '../../reducers/fundDetailsStructureSlice';
import {clearFundOperationalData} from '../../reducers/fundDetailsOperationalSlice';
import {clearFundPortfolioTransactionsData} from '../../reducers/fundDetailsPortfolioTransactionsSlice';

export const clearFundDetailsSaga = function* clearFundDetailsSaga() {
  yield all([
    put(clearFundOverviewData()),
    put(clearFundPortfolioData()),
    put(clearFundPortfolioTransactionsData()),
    put(clearFundStructureData()),
    put(clearFundOperationalData()),
    put(clearFundNearCastingData()),
  ]);
};

export const fundDetailsSagaWatcher = function* fundDetailsSagaWatcher() {
  yield all([
    takeLatest(clearFundDetailsDataAction.type, clearFundDetailsSaga),
  ]);
};
