import React from 'react';
import {Trans} from 'react-i18next';

const SystemTagsTooltipContent: React.FC<{className: string}> = ({
  className,
}) => {
  return (
    <div className={className}>
      <Trans
        i18nKey="Documents.UploadFileForm.systemTagsTooltipContent.Intro"
        components={[<b>System tags</b>]}
      />
      <ul>
        <li>
          <Trans
            i18nKey="Documents.UploadFileForm.systemTagsTooltipContent.EntityTags"
            components={[<b>Entity tags</b>]}
          />
        </li>
        <li>
          <Trans
            i18nKey="Documents.UploadFileForm.systemTagsTooltipContent.SectionTags"
            components={[<b>Section tags</b>]}
          />
        </li>
      </ul>
    </div>
  );
};

export default SystemTagsTooltipContent;
