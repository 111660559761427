import {createAction, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {
  AskAIData,
  AskAIQueryPayload,
  DocumentExplorer,
  DocumentReportingPeriodsByEntityTagsPayload,
  DocumentTag,
  DocumentTransactionData,
  DocumentTransactionsByEntityTagsPayload,
  FileEditFormOptions,
  FileEditFormPayload,
  FileItem,
  FileUploadContext,
  FormStatus,
  HeaderMapping,
  ReportingPeriodsData,
  UploadFilePayload,
} from '../types';

export const documentExplorerUploadFileAction = createAction(
  'documents/document-explorer/upload-file',
  (payload: UploadFilePayload) => ({
    payload,
  })
);

export const documentExplorerCancelUploadFileAction = createAction(
  'documents/document-explorer/cancel-upload-file'
);

export const documentExplorerDeleteFileAction = createAction(
  'documents/document-explorer/delete-file',
  (id: string, fileUploadContext: FileUploadContext) => ({
    payload: {
      id,
      fileUploadContext,
    },
  })
);

export const getFileEditOptionsAction = createAction(
  'documents/document-explorer/get-file-edit-form-options',
  (id: string) => {
    return {
      payload: {
        id,
      },
    };
  }
);

export const documentExplorerDownloadFileAction = createAction(
  'documents/document-explorer/download-file',
  (id: string, fileUploadContext: FileUploadContext) => {
    return {
      payload: {
        id,
        fileUploadContext,
      },
    };
  }
);

export const putFileEditUpdateAction = createAction(
  'documents/document-explorer/put-file-edit-update',
  (payload: FileEditFormPayload) => {
    return {
      payload,
    };
  }
);

export const postAskAIQueryAction = createAction(
  'documents/document-explorer/post-ask-ai-query',
  (payload: AskAIQueryPayload) => {
    return {
      payload,
    };
  }
);

export const getDocumentTransactionsByEntityTagsAction = createAction(
  'documents/document-explorer/get-document-transactions-by-entity-tags',
  (payload: DocumentTransactionsByEntityTagsPayload) => {
    return {
      payload,
    };
  }
);

export const getDocumentReportingPeriodsByEntityTagsAction = createAction(
  'documents/document-explorer/get-document-reporting-periods-by-entity-tags',
  (payload: DocumentReportingPeriodsByEntityTagsPayload) => {
    return {
      payload,
    };
  }
);

interface DocumentExplorerSlice {
  data: DocumentExplorer | null;
  formStatus: FormStatus;
  error: string | null;
  formError: Record<string, string> | null;
  isRequestPending: boolean;
  isAskAIPending: boolean;
  fileEditFormOptions: FileEditFormOptions | null;
  downloadPendingIds: string[];
}

const initialState: DocumentExplorerSlice = {
  data: null,
  formStatus: FormStatus.Ready,
  error: null,
  formError: null,
  isRequestPending: false,
  isAskAIPending: false,
  fileEditFormOptions: null,
  downloadPendingIds: [],
};

const documentExplorerSlice = createSlice({
  name: 'documents/document-explorer',
  initialState,
  reducers: {
    setDocumentExplorerData(
      state: DocumentExplorerSlice,
      action: PayloadAction<DocumentExplorer>
    ) {
      state.data = action.payload;
    },
    updateDocumentExplorerData(
      state: DocumentExplorerSlice,
      action: PayloadAction<FileItem>
    ) {
      if (state.data) {
        state.data.data.data = [...state.data.data.data, action.payload];
      }
    },
    updateDocumentExplorerCustomTags(
      state: DocumentExplorerSlice,
      action: PayloadAction<Array<DocumentTag>>
    ) {
      if (state.data) {
        state.data.customTags = action.payload;
      }
    },
    updateDocumentExplorerDocumentsCount(
      state: DocumentExplorerSlice,
      action: PayloadAction<number>
    ) {
      if (state.data) {
        state.data.documentsCount = action.payload;
      }
    },
    deleteDocumentExplorerFile(
      state: DocumentExplorerSlice,
      action: PayloadAction<{deleteBy: 'id' | 'fileName'; value: string}>
    ) {
      const removeFile = (fileItems: FileItem[]) =>
        fileItems.filter(
          file => file[action.payload.deleteBy] !== action.payload.value
        );

      if (state.data) {
        state.data.data.data = removeFile(state.data.data.data);

        // If askAIData exist we should remove file from its file collection to
        if (state.data?.askAIData?.data?.data) {
          state.data.askAIData.data.data = removeFile(
            state.data.askAIData.data.data
          );
        }

        state.data.documentsCount = state.data.documentsCount - 1;
      }
    },
    setDocumentExplorerError(
      state: DocumentExplorerSlice,
      action: PayloadAction<string | null>
    ) {
      state.error = action.payload;
    },
    setDocumentExplorerFormStatus(
      state: DocumentExplorerSlice,
      action: PayloadAction<FormStatus>
    ) {
      state.formStatus = action.payload;
    },
    setDocumentExplorerFormError(
      state: DocumentExplorerSlice,
      action: PayloadAction<Record<string, string> | null>
    ) {
      state.formError = action.payload;
    },
    setDocumentExplorerIsRequestPending(
      state: DocumentExplorerSlice,
      action: PayloadAction<boolean>
    ) {
      state.isRequestPending = action.payload;
    },
    setDocumentExplorerIsAskAIPending(
      state: DocumentExplorerSlice,
      action: PayloadAction<boolean>
    ) {
      state.isAskAIPending = action.payload;
    },
    setDocumentExplorerAskAIData(
      state: DocumentExplorerSlice,
      action: PayloadAction<AskAIData | null>
    ) {
      if (state.data) {
        state.data.askAIData = action.payload;
      }
    },
    setFileEditOptionsData(
      state: DocumentExplorerSlice,
      action: PayloadAction<FileEditFormOptions | null>
    ) {
      state.fileEditFormOptions = action.payload;
    },
    addDocumentExplorerDownloadPendingId(
      state: DocumentExplorerSlice,
      action: PayloadAction<string>
    ) {
      state.downloadPendingIds = [...state.downloadPendingIds, action.payload];
    },
    removeDocumentExplorerDownloadPendingId(
      state: DocumentExplorerSlice,
      action: PayloadAction<string>
    ) {
      state.downloadPendingIds = [
        ...state.downloadPendingIds.filter(id => id !== action.payload),
      ];
    },
    updateFileData(
      state: DocumentExplorerSlice,
      action: PayloadAction<FileItem>
    ) {
      const updateFile = (fileItems: FileItem[]) =>
        fileItems.map(file => {
          if (file.id === action.payload.id) {
            return action.payload;
          }
          return file;
        });

      if (state.data) {
        state.data.data.data = updateFile(state.data.data.data);

        // If askAIData exist we should update file in its file collection to
        if (state.data?.askAIData?.data?.data) {
          state.data.askAIData.data.data = updateFile(
            state.data.askAIData.data.data
          );
        }
      }
    },
    setDocumentExplorerFileTransactions(
      state: DocumentExplorerSlice,
      action: PayloadAction<{
        headerMapping: HeaderMapping[];
        data: DocumentTransactionData[];
      }>
    ) {
      if (state.fileEditFormOptions) {
        state.fileEditFormOptions.allTransactions = action.payload;
      }
    },
    setIsTransactionsRequestPending(
      state: DocumentExplorerSlice,
      action: PayloadAction<boolean>
    ) {
      if (state.fileEditFormOptions) {
        state.fileEditFormOptions.isTransactionsRequestPending = action.payload;
      }
    },
    setDocumentExplorerFileReportingPeriods(
      state: DocumentExplorerSlice,
      action: PayloadAction<{
        headerMapping: HeaderMapping[];
        data: ReportingPeriodsData[];
      }>
    ) {
      if (state.fileEditFormOptions) {
        state.fileEditFormOptions.allReportingPeriods = action.payload;
      }
    },
    setIsReportingPeriodsRequestPending(
      state: DocumentExplorerSlice,
      action: PayloadAction<boolean>
    ) {
      if (state.fileEditFormOptions) {
        state.fileEditFormOptions.isReportingPeriodsRequestPending =
          action.payload;
      }
    },
  },
});

export default documentExplorerSlice.reducer;
export const {
  setDocumentExplorerData,
  updateDocumentExplorerData,
  updateDocumentExplorerCustomTags,
  updateDocumentExplorerDocumentsCount,
  deleteDocumentExplorerFile,
  setDocumentExplorerIsRequestPending,
  setDocumentExplorerIsAskAIPending,
  setDocumentExplorerAskAIData,
  setDocumentExplorerError,
  setDocumentExplorerFormError,
  setDocumentExplorerFormStatus,
  setFileEditOptionsData,
  addDocumentExplorerDownloadPendingId,
  removeDocumentExplorerDownloadPendingId,
  updateFileData,
  setDocumentExplorerFileTransactions,
  setIsTransactionsRequestPending,
  setDocumentExplorerFileReportingPeriods,
  setIsReportingPeriodsRequestPending,
} = documentExplorerSlice.actions;
