import React, {Fragment} from 'react';
import {Rectangle, Layer} from 'recharts';
import {valueFormat} from '../../../../utils/value-format';

const SankeyNode = ({
  x,
  y,
  width,
  height,
  index,
  payload,
  color,
  total,
  currency,
  sankeyLinksData,
}: any) => {
  return (
    <Layer key={`CustomNode${index}`}>
      <Rectangle
        x={x}
        y={y}
        width={width}
        height={height}
        fill={color}
        fillOpacity="1"
      />
      {x !== 0 && (
        <Fragment>
          {index === 1 ? (
            <text
              textAnchor={'end'}
              x={x + width + 596}
              y={10}
              fontSize="12"
              fontWeight={500}
              fill="rgb(var(--colors-lantern-main-color))"
              stroke="none"
            >
              {currency}
            </text>
          ) : null}
          <text
            textAnchor={'start'}
            x={x + width + 32}
            y={y + 6 + height / 2}
            fontSize="14"
            fontWeight={500}
            fill="rgb(var(--colors-lantern-main-color))"
            stroke="none"
          >
            {payload.name}
          </text>
          <text
            textAnchor={'start'}
            x={x + width + 6 + 400}
            y={y + 6 + height / 2}
            fontSize="14"
            fontWeight={400}
            fill="rgb(var(--colors-lantern-main-color))"
            stroke="none"
          >
            {(
              ((sankeyLinksData[payload?.sourceLinks?.[0]]?.value ||
                payload.value) /
                total) *
              100
            ).toFixed(1)}
            %
          </text>
          <text
            textAnchor={'end'}
            x={x + width + 6 + 590}
            y={y + 6 + height / 2}
            fontSize="14"
            fontWeight={400}
            fill="rgb(var(--colors-lantern-main-color))"
            stroke="none"
          >
            {
              valueFormat(
                sankeyLinksData[payload.sourceLinks[0]]?.value,
                sankeyLinksData[payload.sourceLinks[0]]?.format || ''
              ).value
            }
            {
              valueFormat(
                sankeyLinksData[payload.sourceLinks[0]]?.value,
                sankeyLinksData[payload.sourceLinks[0]]?.format || ''
              ).suffix
            }
          </text>
        </Fragment>
      )}
    </Layer>
  );
};

export default SankeyNode;
