import React, {
  ChangeEvent,
  FocusEvent,
  KeyboardEvent,
  useRef,
  useState,
} from 'react';
import {DropdownWrapper, Input} from '../index';
import {DropdownWrapperAlignmentType, Func, InputProps} from '../../../types';
import classnames from 'classnames';
import styles from './AutoCompleteInput.module.scss';
import {useOnOutsideClick} from '../../../hooks';

interface AutoCompleteInputProps extends InputProps {
  options: string[];
  onChange: Func<[ChangeEvent<HTMLInputElement>], void>;
  onCallSearch?: Func<[string], void>;
  onClearOptions: Func<[], void>;
}
const AutoCompleteInput: React.FC<AutoCompleteInputProps> = ({
  options,
  onChange,
  onClearOptions,
  onCallSearch,
  ...inputProps
}) => {
  const autocompleteWrapperRef = useRef<HTMLDivElement>(null);
  const [currentOption, setCurrentOption] = useState(0);
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);

  const autoCompleteValue = isOpenDropdown ? options[currentOption] : undefined;
  const handleClickOption = (value: string) => {
    onChange({
      target: {value, name: inputProps.name},
    } as ChangeEvent<HTMLInputElement>);
    onClearOptions();
    setCurrentOption(0);
    setIsOpenDropdown(false);
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.value) {
      onClearOptions();
    }
    onChange(event);
    onCallSearch && onCallSearch(event.target.value);
    setIsOpenDropdown(!!event.target.value);
  };
  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (
      (event.key === 'Tab' ||
        event.key === 'Enter' ||
        event.key === 'ArrowRight') &&
      options[currentOption]
    ) {
      event.preventDefault();
      onChange({
        target: {
          value: options[currentOption],
          name: inputProps.name,
        },
      } as ChangeEvent<HTMLInputElement>);
      onClearOptions();
      setCurrentOption(0);
      setIsOpenDropdown(false);
    }

    if (event.key === 'ArrowDown' && options.length) {
      event.preventDefault();
      setCurrentOption(prev => (prev === options.length - 1 ? 0 : ++prev));
    }

    if (event.key === 'ArrowUp' && options.length) {
      event.preventDefault();
      setCurrentOption(prev => (prev === 0 ? options.length - 1 : --prev));
    }
  };

  const handleBlurInput = (event: FocusEvent<HTMLInputElement>) => {
    if (
      autocompleteWrapperRef.current &&
      autocompleteWrapperRef.current.contains(event.target as Node)
    ) {
      return;
    }
  };

  useOnOutsideClick(autocompleteWrapperRef, event => {
    if (
      autocompleteWrapperRef.current &&
      autocompleteWrapperRef.current.contains(event.target as Node)
    ) {
      return;
    }
    if (!isOpenDropdown) {
      return;
    }
    onClearOptions();
    setCurrentOption(0);
    setIsOpenDropdown(false);
  });

  return (
    <div
      ref={autocompleteWrapperRef}
      className={classnames(
        styles.dropdownWrapper,
        isOpenDropdown && styles.isOpen
      )}
    >
      <DropdownWrapper
        isOpen={isOpenDropdown && !!options.length}
        alignment={DropdownWrapperAlignmentType.Left}
        customButton={() => (
          <div className={styles.inputWrapper}>
            <Input
              autoComplete={'off'}
              autoCompleteValue={autoCompleteValue}
              onKeyDown={handleKeyDown}
              onBlur={handleBlurInput}
              onChange={handleChange}
              {...inputProps}
            />
          </div>
        )}
      >
        <div className={styles.options}>
          {options.map((option, idx) => (
            <div
              key={idx}
              onClick={() => handleClickOption(option)}
              role="button"
              className={classnames(
                styles.optionsItem,
                currentOption === idx && styles.selected
              )}
            >
              {option}
            </div>
          ))}
        </div>
      </DropdownWrapper>
    </div>
  );
};

export default AutoCompleteInput;
