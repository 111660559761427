import React, {Fragment, useMemo} from 'react';

const LiveDataChartLabelContent: React.FC = (props: any) => {
  const {rectX, rectY, textX, textY} = useMemo(() => {
    if (!props) return {};
    if (props.viewBox?.width < 130) {
      return {
        rectX: props.viewBox?.x - 125,
        rectY: props.viewBox?.y + props.viewBox?.height - 35,
        textX: props.viewBox?.x - 65,
        textY: props.viewBox?.y + props.viewBox?.height - 15,
      };
    }
    return {
      rectX: props.viewBox?.x + 5,
      rectY: props.viewBox?.y + props.viewBox?.height - 35,
      textX: props.viewBox?.x + 65,
      textY: props.viewBox?.y + props.viewBox?.height - 15,
    };
  }, [props]);

  if (!props) return null;
  return (
    <Fragment>
      <rect
        x={rectX}
        y={rectY}
        width={120}
        height={30}
        rx={8}
        ry={8}
        fill={`rgb(var(--colors-gray-4))`}
      />
      <text
        textAnchor={'middle'}
        fill={`rgb(var(--colors-black))`}
        fontSize={14}
        fontWeight={400}
        x={textX}
        y={textY}
      >
        {props.value}
      </text>
    </Fragment>
  );
};

export default LiveDataChartLabelContent;
