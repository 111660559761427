import {format, getQuarter} from 'date-fns';
import {formatWithLocale, getTimeAgoFormattedValue} from './date';
import {t} from 'i18next';

export const valueFormat = (
  value: string | number | undefined,
  formatValue: string | undefined,
  locale: string = 'en-GB'
): {value: string | number; suffix?: string} => {
  if (value === undefined) return {value: ''};
  if (!formatValue) return {value};
  switch (formatValue) {
    case `MMM yy`: {
      return {value: formatWithLocale(new Date(value), formatValue)};
    }
    case `MMM 'yy`: {
      return {value: formatWithLocale(new Date(value), formatValue)};
    }
    case 'MMM yyyy': {
      return {value: formatWithLocale(new Date(value), formatValue)};
    }
    case 'MMMM yyyy': {
      return {value: formatWithLocale(new Date(value), formatValue)};
    }
    case 'MMMM': {
      return {value: formatWithLocale(new Date(value), formatValue)};
    }
    case 'dd MMM': {
      return {value: formatWithLocale(new Date(value), formatValue)};
    }
    case 'd MMM': {
      return {value: formatWithLocale(new Date(value), formatValue)};
    }
    case 'dd-MM-yy': {
      return {value: formatWithLocale(new Date(value), formatValue)};
    }
    case 'dd-MM-yyyy': {
      return {value: formatWithLocale(new Date(value), formatValue)};
    }
    case 'time-since': {
      const date = new Date(value);
      return {value: getTimeAgoFormattedValue(date)};
    }
    case 'QQ/YYYY': {
      const date = new Date(value);
      return {value: `Q${getQuarter(date)}/${format(date, 'yyyy')}`};
    }
    case '%d years, %d months': {
      const years = Math.floor(+value);
      const months = Math.floor((+value % 1) * 12);
      const yearsString = years > 1 ? t('Global.Years') : t('Global.Year');
      const monthsString = months > 1 ? t('Global.Months') : t('Global.Month');
      return {
        value:
          (years > 0 ? `${years} ${yearsString}` : '') +
          (years > 0 && months > 0 ? ', ' : '') +
          (months > 0 ? `${months} ${monthsString}` : ''),
      };
    }
    case '%d years': {
      const years = Math.floor(+value);
      const yearsString = years > 1 ? 'years' : 'year';
      return {value: `${years} ${yearsString}`};
    }
    case '%dy %dm': {
      const years = Math.floor(+value);
      const months = Math.floor((+value % 1) * 12);
      return {
        value:
          (years > 0 ? `${years}y` : '') +
          (years > 0 && months > 0 ? ' ' : '') +
          (months > 0 ? `${months}m` : ''),
      };
    }
    case 'QQ YY': {
      const date = new Date(value);
      return {value: `Q${getQuarter(date)} ${format(date, 'yy')}`};
    }
    case "QQ 'YY": {
      const date = new Date(value);
      return {value: `Q${getQuarter(date)} '${format(date, 'yy')}`};
    }
    case 'QQ': {
      const date = new Date(value);
      return {value: `Q${getQuarter(date)}`};
    }
    case 'yyyy': {
      const date = new Date(value);
      return {value: format(date, formatValue)};
    }
    case formatValue.match(/^\.\d\D/)?.input: {
      if (isNaN(+value)) {
        return {value};
      }
      const digits = formatValue.match(/\d/);
      let suffix = formatValue.replace(/^\.\d\D/, '');
      let newValue = +value;
      if (suffix === 'B') {
        newValue = newValue / 1e9;
      }
      if (suffix === 'm') {
        newValue = newValue / 1e6;
      }
      if (suffix === 'k') {
        newValue = newValue / 1000;
      }
      if (suffix === 'M') {
        newValue = newValue / 1e6;
        suffix = '';
      }

      return {
        value: `${Number(newValue.toFixed(+(digits || 0))).toLocaleString(
          locale,
          {
            minimumFractionDigits: +(digits || 0),
            maximumFractionDigits: +(digits || 0),
          }
        )}`,
        suffix,
      };
    }
    case 'en-GB': {
      return {
        value: Number(value).toLocaleString('en-GB', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
      };
    }
    case '-en-GB': {
      return {
        value: `(${Number(value).toLocaleString('en-GB', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })})`,
      };
    }
    case 'auto': {
      const formatter = new Intl.NumberFormat(locale, {
        notation: 'compact',
        maximumFractionDigits: 2,
        // @ts-ignore
        signDisplay: 'negative',
      });

      return {
        value: allReplace(formatter.format(Number(value)), {
          B: 'bn',
          M: 'm',
          K: 'k',
        }),
      };
    }
    case 'numericFormat': {
      // Ensure the value is a number then format it with Intl.NumberFormat
      if (
        typeof value === 'number' ||
        (typeof value === 'string' && !isNaN(+value))
      ) {
        const formattedValue = new Intl.NumberFormat(locale, {
          minimumFractionDigits: value === 0 ? 2 : 0, // For zero value, enforce two decimals
          maximumFractionDigits: 2, // For non-zero values, allow up to two decimals
          // @ts-ignore
          signDisplay: 'negative',
        }).format(value as any);

        return {value: formattedValue};
      }
      return {value};
    }
    default:
      return {value};
  }
};

export const allReplace = (str: string, obj: Record<string, string>) => {
  for (const x in obj) {
    str = str.replace(new RegExp(x, 'g'), obj[x]);
  }
  return str;
};
