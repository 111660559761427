import React, {Fragment} from 'react';
import classnames from 'classnames';
import {useLocation} from 'react-router-dom';
import homeLayoutStyles from './SectionHeader.module.scss';
import pageLayoutStyles from './SectionHeaderTemp.module.scss';
import {Button, IconButton} from '../../';
import {Func, ButtonStyle} from '../../../types/index';
import {formatToHyphenatedString} from '../../../utils/strings';

interface SectionHeaderProps {
  label?: string;
  labelType?: string;
  count?: number;
  withActionButton?: boolean;
  buttonText?: string;
  buttonIcon?: string;
  buttonId?: string;
  buttonType?: ButtonStyle;
  childrenLeft?: React.ReactNode[] | null;
  childrenRight?: React.ReactNode[] | null;
  onClick: Func<[] | any, void>;
  className?: string;
  id?: string;
}

const SectionHeader: React.FC<SectionHeaderProps> = ({
  label,
  labelType = 'default', // Can be default or large
  count,
  withActionButton = true,
  buttonText = '',
  buttonIcon = '',
  buttonType,
  buttonId,
  childrenLeft = null,
  childrenRight = null,
  onClick,
  className = '',
  id = '',
}): JSX.Element => {
  const {pathname} = useLocation();
  const styles = pathname === '/' ? homeLayoutStyles : pageLayoutStyles;

  const isActionButtonIconOnly = buttonIcon !== '' && buttonText === '';

  return (
    <div className={classnames(styles.wrapper, className)}>
      <div className={styles.left}>
        {label && (
          <div
            className={classnames(
              styles.label,
              labelType === 'large' && styles.large
            )}
            data-test={`section-header-${formatToHyphenatedString(label)}`}
          >
            {label}
          </div>
        )}
        {count && (
          <div className={styles.count} data-test={`${id}-count`}>
            {count}
          </div>
        )}
        {childrenLeft?.map((childLeft: React.ReactNode, i: number) => (
          <Fragment key={i}>{childLeft}</Fragment>
        ))}
      </div>
      {childrenRight && (
        <div className={styles.right}>
          {childrenRight?.map((childRight: React.ReactNode, i: number) => (
            <Fragment key={i}>{childRight}</Fragment>
          ))}
          {withActionButton && !isActionButtonIconOnly && (
            <Button
              text={buttonText}
              icon={buttonIcon}
              styleType={buttonType}
              onClick={onClick}
              className={styles.button}
              id={buttonId || `${id}-${buttonIcon}-btn`}
            />
          )}
          {withActionButton && isActionButtonIconOnly && (
            <IconButton
              icon={buttonIcon}
              styleType={buttonType}
              onClick={onClick}
              className={styles.button}
              id={buttonId || `${id}-${buttonIcon}-btn`}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default SectionHeader;
