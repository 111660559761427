import React from 'react';
import classnames from 'classnames';
import styles from './ReportingTableTemplateHeaderCell.module.scss';
import {ReportingTableCell} from '../../../../../types/reportingTable';

type ReportingTableTemplateHeaderCellProps = {
  cell: ReportingTableCell;
  classNames: string;
};

const ReportingTableTemplateHeaderCell = ({
  cell,
  classNames,
}: ReportingTableTemplateHeaderCellProps) => {
  return (
    <th
      className={classnames(styles.cell, {
        [`${classNames}`]: !!classNames,
        [styles.notVisible]: !cell.isVisible,
      })}
    >
      <span>{cell.data?.value}</span>
    </th>
  );
};

export default ReportingTableTemplateHeaderCell;
