import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {DashboardCompaniesParams} from '../api/getClientDashboardCompanies';
import {RootState} from '../store';
import {
  ActiveCompanyData,
  ClientDashboard,
  FundSummaryWidgetProps,
} from '../types/index';

interface ClientDashboardSlice {
  data: ClientDashboard | null;
}

const initialState: ClientDashboardSlice = {
  data: null,
};

const clientDashboardSlice = createSlice({
  name: 'client-dashboard',
  initialState,
  reducers: {
    setClientDashboard(
      state: ClientDashboardSlice,
      action: PayloadAction<ClientDashboard>
    ) {
      state.data = action.payload;
    },
    clearClientDashboard(state: ClientDashboardSlice) {
      state.data = null;
    },
    getClientDashboardCompanyData(
      state: ClientDashboardSlice,
      action: PayloadAction<DashboardCompaniesParams>
    ) {
      if (state.data) {
        state.data.companyData.isLoading = true;
      }
    },
    setClientDashboardCompanyData(
      state: ClientDashboardSlice,
      action: PayloadAction<ActiveCompanyData[]>
    ) {
      if (state.data) {
        state.data.companyData.widgets = action.payload;
        state.data.companyData.isLoading = false;
      }
    },
    setFundsData(
      state: ClientDashboardSlice,
      action: PayloadAction<FundSummaryWidgetProps[]>
    ) {
      if (state.data) {
        state.data.fundData.widgets = action.payload;
      }
    },
  },
});

export const {
  setClientDashboard,
  clearClientDashboard,
  getClientDashboardCompanyData,
  setClientDashboardCompanyData,
  setFundsData,
} = clientDashboardSlice.actions;

export default clientDashboardSlice.reducer;

export const selectClientDashboard = (state: RootState) =>
  state.clientDashboard.data;
