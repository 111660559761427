import React, {createContext, useState} from 'react';
import {Func} from '../types';
import {Tooltip} from '../ui-components';

export enum Position {
  Top = 'top',
  Left = 'left',
  Bottom = 'bottom',
  Right = 'right',
}

interface ShowTooltipArgs {
  target: HTMLElement;
  position: Position;
  content: React.ReactElement | string | null;
  className?: string;
  hasArrow?: boolean;
}

interface ContextProps {
  show: boolean;
  hasArrow?: boolean;
  target: HTMLElement | null;
  content: React.ReactElement | string | null;
  position: Position;
  className?: string;
  showTooltip: Func<[ShowTooltipArgs], any>;
  hideTooltip: Func<[], void>;
}

type TooltipProviderProps = {
  children: JSX.Element;
};

export const TooltipContext = createContext<ContextProps>({} as ContextProps);

export const ToolTipProvider = ({children}: TooltipProviderProps) => {
  const [show, setShow] = useState<boolean>(false);
  const [hasArrow, setHasArrow] = useState<boolean>(true);
  const [position, setPosition] = useState<Position>(Position.Bottom);
  const [content, setContent] = useState<React.ReactElement | string | null>(
    null
  );
  const [target, setTarget] = useState<HTMLElement | null>(null);
  const [className, setClassName] = useState<string | undefined>();

  const showTooltip: Func<[ShowTooltipArgs], any> = ({
    target,
    position,
    content,
    className,
    hasArrow = true,
  }) => {
    setShow(true);
    setContent(content);
    setPosition(position);
    setTarget(target);
    setClassName(className);
    setHasArrow(hasArrow);
  };

  const hideTooltip: Func<[], void> = () => {
    setShow(false);
    setContent(null);
    setTarget(null);
    setHasArrow(false);
  };

  return (
    <TooltipContext.Provider
      value={{
        show,
        target,
        content,
        position,
        showTooltip,
        hideTooltip,
        className,
        hasArrow,
      }}
    >
      {children}
      <Tooltip />
    </TooltipContext.Provider>
  );
};
