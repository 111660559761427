import React, {Fragment} from 'react';
import {useLocation} from 'react-router-dom';
import {ExitedCompanyData} from '../../../../../types/index';
import BaseInfo from '../base-info/BaseInfo';
import FinancialData from '../financial-data/FinancialData';
import {ValueFormat} from '../../../../global';
import homeLayoutStyles from '../../CompanyWidget.module.scss';
import pageLayoutStyles from '../../CompanyWidgetTemp.module.scss';
import {useTranslation} from 'react-i18next';

const ExitedCompany: React.FC<ExitedCompanyData> = props => {
  const {fundColor, irr, totalCost, roi, currency, exitDate} = props;
  const {t} = useTranslation();
  const {pathname} = useLocation();
  const styles = pathname === '/' ? homeLayoutStyles : pageLayoutStyles;

  return (
    <div
      className={styles.wrapper}
      style={{borderColor: `rgb(var(--colors-${fundColor}))`}}
      data-test="company-widget"
    >
      <BaseInfo
        data={props}
        rightTopComponent={
          <Fragment>
            <div>{t('Global.Exited')}</div>
            <ValueFormat value={exitDate?.value} format={"QQ 'YY"} />
          </Fragment>
        }
      />
      <div className={styles.bottom} data-test="company-widget-tab-row">
        <FinancialData data={totalCost} currency={currency} />
        <FinancialData data={roi} />
        <FinancialData data={irr!} />
      </div>
    </div>
  );
};

export default ExitedCompany;
