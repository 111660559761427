import React, {ChangeEvent, DragEvent, useRef, useState} from 'react';
import styles from './FileUploader.module.scss';
import {FileUploaderProps} from '../../../types';
import {Button, Icon} from '../index';
import classnames from 'classnames';
import {useTranslation} from 'react-i18next';
import {allowedFileTypes} from '../../../constants';

const FileUploader: React.FC<FileUploaderProps> = ({
  children,
  onDropHandler,
  topLeftChildren,
  className,
}) => {
  const [isDragEntered, setIsDragEntered] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const dragRef = useRef<HTMLDivElement>(null);

  const {t} = useTranslation();

  const handleDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragEntered(false);

    if (!event.dataTransfer.files) return;
    event.dataTransfer.clearData();
    onDropHandler(event.dataTransfer.files);
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    if (!event.target.files) return;
    onDropHandler(event.target.files);
  };

  const handleClickButton = () => {
    if (inputRef.current) {
      inputRef.current.value = '';
      inputRef.current?.click();
    }
  };

  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDragEnter = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();

    if (event.target !== dragRef.current) {
      setIsDragEntered(true);
    }
  };

  const handleDragLeave = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();

    if (event.target === dragRef.current) {
      setIsDragEntered(false);
    }
  };

  return (
    <div
      ref={dragRef}
      className={classnames(
        styles.wrapper,
        isDragEntered && styles[`wrapper__dragEntered`],
        className
      )}
      onDrop={handleDrop}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
    >
      <div className={styles.top} data-file-uploader-top="">
        {topLeftChildren}
        <div className={styles.uploadBlock} data-file-uploader-upload-block="">
          <Icon className={styles.icon} name={'document-upload'} />
          {t('FileUploader.Title.DragDrop')}
          <Button
            id="upload-document-btn"
            onClick={handleClickButton}
            text={t('Global.Upload')}
          />
          <input
            ref={inputRef}
            type="file"
            multiple={true}
            className={styles.input}
            onChange={handleChange}
            accept={allowedFileTypes.join(', ')}
          />
        </div>
      </div>
      <div className={styles.inner}>{children}</div>
      {isDragEntered && (
        <div className={styles.footer}>
          <div className={styles.dragHint}>
            <Icon className={styles.icon} name={'document-upload'} />
            {t('FileUploader.Title.Drop')}
          </div>
        </div>
      )}
    </div>
  );
};

export default FileUploader;
