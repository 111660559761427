import React from 'react';
import {ColumnDef, createColumnHelper} from '@tanstack/react-table';
import Th from '../cells/th/Th';
import Td from '../cells/td/Td';
import {
  FlexHAlign,
  Func,
  HeaderMapping,
  SelectTransactionsTableRowItem,
} from '../../../../types';
import {Checkbox, TextTruncation} from '../../index';
import styles from './SelectTransactionsTableColumnDefs.module.scss';

const genSelectTransactionsTableColumnDefs = (
  headerMapping: HeaderMapping[],
  currentSelectedTransactions: String[],
  checkHandler: Func<any, any>
): ColumnDef<SelectTransactionsTableRowItem, any>[] => {
  const columnHelper = createColumnHelper<SelectTransactionsTableRowItem>();

  return [
    columnHelper.accessor('isSelected', {
      id: 'isSelected',
      header: props => <th style={{width: '35px'}}></th>,
      cell: props => (
        <Td
          {...props}
          align={FlexHAlign.start}
          width={'35px'}
          canHide={false}
          skipExtend={true}
        >
          <Checkbox
            onChange={e => checkHandler(e.target.checked, props.row.original)}
            label={''}
            checked={currentSelectedTransactions.includes(
              props.row.original.id
            )}
          />
        </Td>
      ),
    }),
    columnHelper.accessor('fundName', {
      id: 'fundName',
      header: props => (
        <Th
          {...props}
          headerMapping={headerMapping}
          align={FlexHAlign.start}
          width={'max(100vw*0.09, 120px)'}
        />
      ),
      cell: props => (
        <Td
          {...props}
          align={FlexHAlign.start}
          width={'max(100vw*0.09, 120px)'}
          canHide={false}
          skipExtend={true}
        >
          <TextTruncation
            text={props?.getValue<string>()}
            tooltipClassname={styles.tooltipWrapper}
          />
        </Td>
      ),
    }),
    columnHelper.accessor('companyName', {
      id: 'companyName',
      header: props => (
        <Th
          {...props}
          headerMapping={headerMapping}
          align={FlexHAlign.start}
          width={'max(100vw*0.13, 150px)'}
        />
      ),
      cell: props => (
        <Td
          {...props}
          align={FlexHAlign.start}
          width={'max(100vw*0.25, 150px)'}
          canHide={false}
          skipExtend={true}
        >
          <TextTruncation
            text={props?.getValue<string>()}
            tooltipClassname={styles.tooltipWrapper}
          />
        </Td>
      ),
    }),
    columnHelper.accessor('instrumentType', {
      id: 'instrumentType',
      header: props => (
        <Th {...props} headerMapping={headerMapping} align={FlexHAlign.start} />
      ),
      cell: props => (
        <Td {...props} align={FlexHAlign.start} skipExtend={true}>
          <TextTruncation
            text={props?.getValue<string>()}
            tooltipClassname={styles.tooltipWrapper}
          />
        </Td>
      ),
    }),
    columnHelper.accessor(row => row.shares?.value ?? 0, {
      id: 'shares',
      header: props => (
        <Th {...props} headerMapping={headerMapping} align={FlexHAlign.end} />
      ),
      cell: props => (
        <Td
          {...props}
          align={FlexHAlign.end}
          nestedAccessorFn={original => original.shares}
          skipExtend={true}
        />
      ),
    }),
    columnHelper.accessor('currency', {
      id: 'currency',
      header: props => (
        <Th {...props} headerMapping={headerMapping} align={FlexHAlign.start} />
      ),
      cell: props => (
        <Td {...props} align={FlexHAlign.start} skipExtend={true} />
      ),
    }),
    columnHelper.accessor(row => row.cost?.value ?? 0, {
      id: 'cost',
      header: props => (
        <Th {...props} headerMapping={headerMapping} align={FlexHAlign.end} />
      ),
      cell: props => (
        <Td
          {...props}
          align={FlexHAlign.end}
          nestedAccessorFn={original => original.cost}
          skipExtend={true}
        />
      ),
    }),
    columnHelper.accessor(row => row.date?.value, {
      id: 'date',
      header: props => (
        <Th headerMapping={headerMapping} {...props} align={FlexHAlign.start} />
      ),
      cell: props => (
        <Td
          {...props}
          align={FlexHAlign.start}
          nestedAccessorFn={original => original.date}
          skipExtend={true}
        />
      ),
    }),
    columnHelper.accessor(row => row.file, {
      id: 'file',
      header: props => (
        <Th
          headerMapping={headerMapping}
          {...props}
          align={FlexHAlign.end}
          width={'42px'}
        />
      ),
      cell: props => (
        <Td
          {...props}
          align={FlexHAlign.end}
          skipExtend={true}
          width={'42px'}
          emptyValue={'-'}
        />
      ),
    }),
  ];
};

export default genSelectTransactionsTableColumnDefs;
