import React, {useMemo} from 'react';
import {FundState, ActivityBadgeProps, UserStatus} from '../../../../../types';
import {ActivityBadge} from '../../..';

type StatusCellProps = {
  status: UserStatus | FundState;
  skipExtend?: boolean;
  className?: string;
};

const StatusCell = ({status, skipExtend, className}: StatusCellProps) => {
  const createActivityBadgePropsObj = (
    label: string,
    color?: string,
    textColor?: string,
    fontSize?: number,
    fontWeight?: number
  ): ActivityBadgeProps => ({
    label,
    color,
    textColor,
    fontSize,
    fontWeight,
  });

  const pillPropsByUserStatus = useMemo<ActivityBadgeProps | null>(() => {
    switch (status) {
      case UserStatus.Active:
        return createActivityBadgePropsObj(
          'Active',
          'lan-green',
          'black',
          14,
          400
        );
      case UserStatus.Pending:
        return createActivityBadgePropsObj(
          'Pending',
          'gray-6',
          'white',
          14,
          400
        );
      case UserStatus.Removed:
        return createActivityBadgePropsObj(
          'Removed',
          'gray-6',
          'white',
          14,
          400
        );
      case UserStatus.Locked:
        return createActivityBadgePropsObj(
          'Locked',
          'red', // locked status
          'white',
          14,
          400
        );
      case FundState.Active:
        return createActivityBadgePropsObj(
          'Active',
          'lan-green',
          'black',
          14,
          400
        );
      case FundState.Inactive:
        return createActivityBadgePropsObj(
          'Inactive',
          'gray-6',
          'white',
          14,
          400
        );
      default:
        return null;
    }
  }, [status]);

  return pillPropsByUserStatus && <ActivityBadge {...pillPropsByUserStatus} />;
};

export default StatusCell;
