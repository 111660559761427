import React, {Fragment} from 'react';
import classnames from 'classnames';
import {NavLink, useLocation} from 'react-router-dom';
import routePaths from '../../../routePaths';
import {Icon} from '../../global';
import styles from './NavMenu.module.scss';
import LanternSecondaryLogoWhite from '../../global/logos/LanternSecondaryLogoWhite';
import {Trans, useTranslation} from 'react-i18next';

interface NavMenuProps {
  isSticky?: boolean;
  isCompanyAdmin: boolean;
  isAdmin: boolean;
  releaseDataCollectionEnabled: any;
  releaseDocumentRepositoryEnabled: any;
}

const NavMenu: React.FC<NavMenuProps> = (props: NavMenuProps): JSX.Element => {
  const {t} = useTranslation();
  const {pathname} = useLocation();

  const getLinkClassName = (isActive: boolean) =>
    classnames(styles.link, isActive && styles.active);

  return (
    <ul
      className={classnames(styles.navMenu, props.isSticky && styles.sticky)}
      data-test="navigation"
    >
      <li className={styles.listItem}>
        <NavLink
          to={props.isAdmin ? routePaths.HOME : routePaths.REPORTING}
          className={styles.link}
        >
          <LanternSecondaryLogoWhite
            className={styles.logo}
            gradientId={'nav-menu-secondary-logo'}
          />
        </NavLink>
      </li>
      <hr />
      {/*
        Internal admin and user nav links
     */}
      {props.isAdmin ? (
        <Fragment>
          <li className={styles.listItem}>
            <NavLink
              to={routePaths.HOME}
              className={() => getLinkClassName(pathname === '/')}
              data-test="navigation-home-link"
            >
              <Icon name={'home'} className={styles.listIcon} />
              <span>{t('NavigationMenu.Home')}</span>
            </NavLink>
          </li>
          <li className={styles.listItem}>
            <NavLink
              to={routePaths.FUNDS}
              className={(isActive: boolean) =>
                getLinkClassName(
                  isActive || pathname.includes('/scenarios/funds/')
                )
              }
              data-test="navigation-funds-link"
            >
              <Icon name={'funds'} className={styles.listIcon} />
              <span>{t('NavigationMenu.Funds')}</span>
            </NavLink>
          </li>
          <li className={styles.listItem}>
            <NavLink
              to={routePaths.PORTFOLIO_COMPANIES}
              className={getLinkClassName}
              data-test="navigation-portfolio-link"
            >
              <Icon name={'companies'} className={styles.listIcon} />
              <span>{t('Global.Portfolio')}</span>
            </NavLink>
          </li>
          {props.releaseDataCollectionEnabled ? (
            <li className={styles.listItem}>
              <NavLink
                to={routePaths.DATA_COLLECTION}
                className={() =>
                  getLinkClassName(
                    pathname.includes(routePaths.DATA_COLLECTION) &&
                      !pathname.includes(routePaths.ADMIN_DATA_COLLECTION)
                  )
                }
                data-test="navigation-data-collection-link"
              >
                <Icon className={styles.listIcon} name={'data-collection'} />
                <span>
                  <Trans i18nKey="NavigationMenu.DataCollection.Br" />
                </span>
              </NavLink>
            </li>
          ) : null}
          {props.releaseDocumentRepositoryEnabled ? (
            <li className={styles.listItem}>
              <NavLink
                to={routePaths.DOCUMENTS_DOCUMENT_EXPLORER}
                className={getLinkClassName}
                data-test="navigation-documents-link"
              >
                <Icon name={'document'} className={styles.listIcon} />
                <span>{t('Global.Documents')}</span>
              </NavLink>
            </li>
          ) : null}
        </Fragment>
      ) : null}

      {/*
        Company admin nav links
     */}
      {props.isCompanyAdmin ? (
        <>
          {props.releaseDataCollectionEnabled && (
            <li className={styles.listItem}>
              <NavLink to={routePaths.REPORTING} className={getLinkClassName}>
                <Icon className={styles.listIcon} name={'data-collection'} />
                <span>{t('NavigationMenu.Reporting')}</span>
              </NavLink>
            </li>
          )}
          {props.releaseDocumentRepositoryEnabled && (
            <li className={styles.listItem}>
              <NavLink
                to={routePaths.DOCUMENTS_DOCUMENT_EXPLORER}
                className={getLinkClassName}
              >
                <Icon name={'document'} className={styles.listIcon} />
                <span>{t('Global.Documents')}</span>
              </NavLink>
            </li>
          )}
        </>
      ) : null}

      {/*
        @TODO As a part of ticket LP-1285 we'll remove NavLinks to the pages that are not developed currently until they development is planed and finished.
     */}
      {/*<hr />*/}
      {/*<li className={styles.listItem}>*/}
      {/*  <NavLink*/}
      {/*    to={'#'}*/}
      {/*    className={classnames(styles.link, styles.disabled)}*/}
      {/*    onClick={e => e.preventDefault()}*/}
      {/*  >*/}
      {/*    <Icon name={'tasks'} />*/}
      {/*    <span>Tasks</span>*/}
      {/*  </NavLink>*/}
      {/*</li>*/}
      {/*<li className={styles.listItem}>*/}
      {/*  <NavLink*/}
      {/*    to={'#'}*/}
      {/*    className={classnames(styles.link, styles.disabled)}*/}
      {/*    onClick={e => e.preventDefault()}*/}
      {/*  >*/}
      {/*    <Icon name={'shortcuts'} />*/}
      {/*    <span>Shortcuts</span>*/}
      {/*  </NavLink>*/}
      {/*</li>*/}
      {/*<li className={styles.listItem}>*/}
      {/*  <NavLink*/}
      {/*    to={'#'}*/}
      {/*    className={classnames(styles.link, styles.disabled)}*/}
      {/*    onClick={e => e.preventDefault()}*/}
      {/*  >*/}
      {/*    <Icon name={'activity'} />*/}
      {/*    <span>Activity</span>*/}
      {/*  </NavLink>*/}
      {/*</li>*/}
      {/*<li className={styles.listItem}>*/}
      {/*  <NavLink*/}
      {/*    to={'#'}*/}
      {/*    className={classnames(styles.link, styles.disabled)}*/}
      {/*    onClick={e => e.preventDefault()}*/}
      {/*  >*/}
      {/*    <Icon name={'news'} />*/}
      {/*    <span>News</span>*/}
      {/*  </NavLink>*/}
      {/*</li>*/}
    </ul>
  );
};

export default NavMenu;
