import React from 'react';
import {
  CompanyUser,
  CompanyUserRow,
  FormStatus,
  Func,
  Persona,
} from '../../../../types';
import {Row} from '@tanstack/react-table';
import styles from './PCUsersTableExpandedRow.module.scss';
import UpdateUserForm from '../../../forms/update-user-form/UpdateUserForm';
import {updateUserFormSchema} from '../../../../constants';

export type PCUsersTableExpandedRowProps<TData> = {
  row: Row<TData>;
  onSubmitForm: Func<[CompanyUser], void>;
  onRemoveUser: Func<[string], void>;
  onReInviteUser: Func<[string], void>;
  onReactivateUser: Func<[string], void>;
  personaOptions: Persona[];
  formStatus: FormStatus;
  isRequestPending: boolean;
  isReinvitePending: boolean;
};
const PCUsersTableExpandedRow = ({
  row,
  onSubmitForm,
  onRemoveUser,
  onReInviteUser,
  onReactivateUser,
  personaOptions,
  formStatus,
  isRequestPending,
  isReinvitePending,
}: PCUsersTableExpandedRowProps<CompanyUserRow>): JSX.Element => {
  return (
    <div className={styles.wrapper}>
      <UpdateUserForm
        data={row.original}
        personaOptions={personaOptions}
        formSchema={updateUserFormSchema}
        onSubmit={state => onSubmitForm(state as CompanyUser)}
        onCancel={row.getToggleExpandedHandler()}
        onClickReInviteUser={onReInviteUser}
        onClickReactivateUser={onReactivateUser}
        onClickRemoveUser={onRemoveUser}
        formStatus={formStatus}
        isRequestPending={isRequestPending}
        isReinvitePending={isReinvitePending}
      />
    </div>
  );
};

export default PCUsersTableExpandedRow;
