import React, {useMemo, useState} from 'react';
import {Cell, Pie, PieChart, ResponsiveContainer, Tooltip} from 'recharts';
import {ExposureChartType, Func, WidgetData} from '../../../types';
import {useChartAnimated} from '../../../hooks';
import Legend from './components/legend-item/Legend';
import FundExposureChartTooltip from './components/fund-exposure-chart-tooltip/FundExposureChartTooltip';
import styles from './FundExposureChart.module.scss';
import {hyphenUp} from '../fund-exposure-widget/FundExposureWidgetSettings';
import {IconButton} from '../../global';

export interface ActiveItem {
  name: string;
  value: number;
  color: string;
  index: number;
}

export interface FundExposureChartProps extends WidgetData {
  onEditHandler?: Func<[], void>;
  showEditPen?: boolean;
}

const FundExposureChart: React.FC<FundExposureChartProps> = ({
  label,
  timeSeries,
  unitLabel,
  placeHolder,
  onEditHandler = () => {},
  showEditPen,
}) => {
  const [activeItem, setActiveItem] = useState<ActiveItem | null>(null);

  const {ref, isAnimated} = useChartAnimated();

  const {data} = timeSeries as ExposureChartType;

  const colors = useMemo(
    () => [
      '--colors-green-haze',
      '--colors-tropical-forest',
      '--colors-pacific-blue',
      '--colors-allports',
      '--colors-flirt',
      '--colors-pompadour',
      '--colors-pigment-indigo',
      '--colors-revolver',
      '--colors-turbo',
    ],
    []
  );

  const chartData = useMemo<ExposureChartType['data']>(() => {
    if (!data?.length) {
      return [{name: '', value: 1, color: '--colors-gray-6'}];
    }
    return data.map((item, index) => ({
      ...item,
      color:
        item.name === 'Other (balancing figure)'
          ? '--colors-gray-6'
          : colors[index % colors.length],
    }));
  }, [timeSeries, colors]);

  const handleMouseEnter = (data: any, index: number) => {
    setActiveItem({
      value: data.percent * 100,
      name: data.name,
      color: data.fill,
      index,
    });
  };

  return (
    <div
      className={styles.wrapper}
      id={`exposure-chart-${label && hyphenUp(label)}`}
    >
      {showEditPen && (
        <IconButton
          onClick={onEditHandler}
          icon={'pencil'}
          className={styles.editButton}
        />
      )}
      <p className={styles.title}>{label}</p>
      <div className={styles.content}>
        <div ref={ref} className={styles.chart}>
          <div className={styles.chartInner}>
            <ResponsiveContainer width="100%" aspect={1}>
              <PieChart>
                <Pie
                  data={chartData}
                  hide={!isAnimated}
                  dataKey="value"
                  innerRadius={'90%'}
                  outerRadius={'100%'}
                  fill="#82ca9d"
                  onMouseLeave={() => setActiveItem(null)}
                  onMouseEnter={handleMouseEnter}
                  isAnimationActive={isAnimated}
                  startAngle={360}
                  endAngle={0}
                >
                  {chartData?.map((item: any, index: number) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={`rgb(var(${item.color})`}
                      stroke={'none'}
                      style={{outline: 'none'}}
                    />
                  ))}
                </Pie>
                <Tooltip
                  content={() => (
                    <FundExposureChartTooltip content={activeItem} />
                  )}
                />
              </PieChart>
            </ResponsiveContainer>
          </div>
        </div>
        {data?.length > 0 ? (
          <Legend
            unitLabel={unitLabel}
            activeItem={activeItem ? activeItem.index : -1}
            data={data}
            colors={colors}
          />
        ) : (
          <div className={styles.placeholderLegend}>{placeHolder}</div>
        )}
      </div>
    </div>
  );
};

export default FundExposureChart;
