import {createSlice} from '@reduxjs/toolkit';
import {Theme} from '../types/index';

interface AppTheme {
  currentTheme: Theme;
}

const initialState = {currentTheme: Theme.DARK} as AppTheme;

const themeSlice = createSlice({
  name: 'theme-provider',
  initialState,
  reducers: {
    toggleTheme(state: AppTheme) {
      state.currentTheme =
        state.currentTheme === Theme.DARK ? Theme.LIGHT : Theme.DARK;
    },
  },
});

export const {toggleTheme} = themeSlice.actions;
export default themeSlice.reducer;
