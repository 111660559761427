import {TableSettings} from '../../../../../types';

export const fundPerformanceMoicChartSettings = (
  zipFileName: string
): TableSettings => {
  return {
    fileName: zipFileName,
    name: 'Fund Performance MOIC',
    columnVisibility: {quarter: false},
    headerMapping: [
      {
        columnId: 'Gross IRR',
        label: 'Gross IRR',
      },
      {
        columnId: 'Net IRR',
        label: 'Net IRR',
      },
      {
        columnId: 'MOIC',
        label: 'MOIC',
      },
      {
        columnId: 'quarter',
        label: 'Quarter',
      },
      {
        columnId: 'date',
        label: 'Date',
      },
    ],
    columnOrder: ['Gross IRR', 'Net IRR', 'MOIC', 'quarter', 'date'],
    meta: {
      columns: {
        'Gross IRR': {
          formatter: 'percentage',
          styling: {
            align: 'right',
          },
        },
        'Net IRR': {
          formatter: 'percentage',
          styling: {
            align: 'right',
          },
        },
        MOIC: {
          styling: {
            align: 'right',
          },
        },
        quarter: {
          styling: {
            align: 'right',
          },
        },
        date: {
          styling: {
            align: 'right',
          },
          formatter: 'numFmt:dd-mm-yyyy',
        },
      },
    },
  };
};

export const getImagesExportPayload = (zipFileName: string) => {
  return {
    id: 'performance-moic-chart',
    fileName: zipFileName,
    opts: [
      {
        fileName: `${zipFileName}_TransparentBackground`,
        type: 'image/svg+xml',
        ext: 'svg',
        bgColor: 'transparent',
        elsToFilter: [
          'performance-chart-brush',
          'performance-chart-export',
          'performance-benchmark-toggle',
          'recharts-tooltip-wrapper',
        ],
      },
      {
        fileName: `${zipFileName}_WithBackground`,
        type: 'image/svg+xml',
        ext: 'svg',
        bgColor: '#202020',
        elsToFilter: [
          'performance-chart-brush',
          'performance-chart-export',
          'performance-benchmark-toggle',
          'recharts-tooltip-wrapper',
        ],
      },
      {
        fileName: `${zipFileName}_TransparentBackground`,
        type: 'image/png',
        ext: 'png',
        bgColor: 'transparent',
        elsToFilter: [
          'performance-chart-brush',
          'performance-chart-export',
          'performance-benchmark-toggle',
          'recharts-tooltip-wrapper',
        ],
      },
      {
        fileName: `${zipFileName}_WithBackground`,
        type: 'image/png',
        ext: 'png',
        bgColor: '#202020',
        elsToFilter: [
          'performance-chart-brush',
          'performance-chart-export',
          'performance-benchmark-toggle',
          'recharts-tooltip-wrapper',
        ],
      },
    ],
  };
};
