import React, {useMemo, useRef, useState} from 'react';
import {Func, InsightsData, ButtonStyle} from '../../../types';
import {AsAtController, InsightWidgetLite, SectionHeader} from '../../index';
import styles from './InsightDashboard.module.scss';
import classnames from 'classnames';
import CustomizeOrderPanel from '../../global/customize-order-panel/CustomizeOrderPanel';
import {UpdateLayoutPropertyPayload} from '../../../types/userLayoutProperty';
import {useTranslation} from 'react-i18next';

interface InsightDashboardProps extends InsightsData {
  id: string;
  updateInsightData?: Func<[string], void>;
  onUpdateLayoutSettings?: Func<[UpdateLayoutPropertyPayload], void>;
}
const InsightDashboard: React.FC<InsightDashboardProps> = ({
  id,
  title,
  widgets,
  asAt,
  firstDate,
  lastDate,
  updateInsightData,
  layoutSettings,
  onUpdateLayoutSettings,
}) => {
  const {t} = useTranslation();
  const [currentDate, setCurrentDate] = useState(asAt.value);
  const [panelOpen, setPanelOpen] = useState(false);
  const [order, setOrder] = useState(
    widgets.map(item => ({id: item.id, label: item.title}))
  );
  const wrapperRef = useRef<HTMLDivElement>(null);

  const reorderedWidgets = useMemo(
    () =>
      order.map(orderItem => {
        const widget = widgets.find(({id}) => id === orderItem.id);
        if (widget)
          return {
            id: orderItem.id,
            widget: widget,
          };
        return null;
      }),
    [widgets, order]
  );
  const handleChangeAsAt = (date: string) => {
    setCurrentDate(date);
    updateInsightData && updateInsightData(date);
  };
  const handleSaveOrder = () => {
    setPanelOpen(false);
    if (onUpdateLayoutSettings) {
      onUpdateLayoutSettings({
        value: order.map(({id}) => id),
        layoutProperty: layoutSettings.layoutProperty,
      });
    }
  };
  const handleCancelOrder = () => {
    setPanelOpen(false);
  };

  return (
    <div id={id} className={styles.wrapper} data-test={id}>
      <SectionHeader
        id={id}
        className={styles.insightsHeader}
        label={title}
        onClick={() => setPanelOpen(prev => !prev)}
        withActionButton={!panelOpen}
        buttonIcon="pencil"
        buttonType={ButtonStyle.Primary}
        labelType={'large'}
        childrenRight={[
          <AsAtController
            label={t('Global.asAt')}
            startDate={firstDate.value}
            endDate={lastDate.value}
            value={currentDate}
            onChange={handleChangeAsAt}
          />,
        ]}
      />
      <div className={classnames(styles.panel, panelOpen && styles.open)}>
        <div className={styles.panelInner}>
          <CustomizeOrderPanel
            id={`${id}-customize-order-panel-order`}
            label={t('Global.CustomizeOrder')}
            countLabel={t(
              order.length > 1 ? 'Global.Insights' : 'Global.Insight',
              {count: order.length}
            )}
            options={order}
            onChange={setOrder}
            onCancel={handleCancelOrder}
            onSave={handleSaveOrder}
          />
        </div>
      </div>
      <div
        className={classnames(styles.insightsSection, panelOpen && styles.open)}
        ref={wrapperRef}
        data-test="insights-dashboard-wrapper"
      >
        {reorderedWidgets.map((widget, idx) =>
          widget ? (
            <div className={styles.insightsWidget} key={widget.id}>
              <InsightWidgetLite
                {...widget?.widget}
                wrapperRef={wrapperRef}
                index={idx}
                id="insights-dashboard-widget"
              />
            </div>
          ) : null
        )}
      </div>
    </div>
  );
};

export default InsightDashboard;
