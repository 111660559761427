import React, {useMemo} from 'react';

type Props = {
  hovered: boolean;
  sorted: boolean;
  direction: 'default' | 'asc' | 'desc';
  className?: string;
};

const TableSortIcon = ({hovered, sorted, direction, className}: Props) => {
  const strokeColorWhite = 'rgb(var(--colors-white))';
  const strokeColorGray = 'rgb(var(--colors-gray-4))';
  const strokeColor = useMemo(
    () =>
      sorted ? strokeColorWhite : hovered ? strokeColorWhite : strokeColorGray,
    [hovered, sorted]
  );

  return (
    <svg
      className={className}
      width="8"
      height="16"
      viewBox="0 0 8 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {(direction === 'asc' || direction === 'default') && (
        <path
          d="M1.3335 5L4.00016 2.33333L6.66683 5"
          stroke={strokeColor}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      )}
      {(direction === 'desc' || direction === 'default') && (
        <path
          d="M1.3335 11L4.00016 13.6667L6.66683 11"
          stroke={strokeColor}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      )}
    </svg>
  );
};

export default TableSortIcon;
