import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {FundStructure, CapitalWidgetType, ComponentWidgetType} from '../types';

interface FundDetailsStructureData {
  data: FundStructure | null;
}

const initialState: FundDetailsStructureData = {
  data: null,
};

const fundDetailsStructureSlice = createSlice({
  name: 'fund-details-structure',
  initialState,
  reducers: {
    setFundStructureData(
      state: FundDetailsStructureData,
      action: PayloadAction<FundStructure>
    ) {
      state.data = action.payload;
    },
    getFundStructureCapitalData(
      state: FundDetailsStructureData,
      action: PayloadAction<{fundId: string; date: string}>
    ) {
      const entity = state.data?.entitiesData[0].data.find(
        fundEntity => fundEntity.fundEntityId === action.payload.fundId
      );
      if (entity?.capitalData) {
        entity.capitalData.isLoading = true;
      }
    },
    updateFundStructureCapitalData(
      state: FundDetailsStructureData,
      action: PayloadAction<{widgets: CapitalWidgetType[]; fundId: string}>
    ) {
      const entity = state.data?.entitiesData[0].data.find(
        fundEntity => fundEntity.fundEntityId === action.payload.fundId
      );
      if (entity?.capitalData) {
        entity.capitalData.widgets = action.payload.widgets;
        entity.capitalData.isLoading = false;
      }
    },
    getFundStructureInvestorsData(
      state: FundDetailsStructureData,
      action: PayloadAction<{
        fundId: string;
        sortValue: string;
        sortDirection: string;
      }>
    ) {
      if (state.data?.structureInvestorsData) {
        state.data.structureInvestorsData.isLoading = true;
      }
    },
    updateFundStructureInvestorsData(
      state: FundDetailsStructureData,
      action: PayloadAction<ComponentWidgetType[]>
    ) {
      if (state.data?.structureInvestorsData) {
        state.data.structureInvestorsData.widgets = action.payload;
        state.data.structureInvestorsData.isLoading = false;
      }
    },
    clearFundStructureData(state: FundDetailsStructureData) {
      state.data = null;
    },
  },
});

export const {
  setFundStructureData,
  clearFundStructureData,
  getFundStructureCapitalData,
  updateFundStructureCapitalData,
  getFundStructureInvestorsData,
  updateFundStructureInvestorsData,
} = fundDetailsStructureSlice.actions;

export default fundDetailsStructureSlice.reducer;
