import React, {useMemo, useState} from 'react';
import classnames from 'classnames';
import {
  InvestmentPerformance,
  InvestmentPerformanceTabSection,
  StyleVariant,
  Tab,
} from '../../../types';
import {SectionHeader} from '../../dashboard';
import {PerformanceWidget} from '../../fund-portfolio';
import styles from './InvestmentPerformanceDashboard.module.scss';
import {Tabs} from '../../global';

interface InvestmentPerformanceDashboardProps extends InvestmentPerformance {
  className?: string;
}

const InvestmentPerformanceDashboard: React.FC<
  InvestmentPerformanceDashboardProps
> = ({label, data, className = '', signOffDate}): JSX.Element => {
  const [activeTab, setActiveTab] = useState<Tab>({
    label: data[0]?.data[0]?.name ?? '',
    value: `${data[0]?.label || ''}-${data[0]?.data[0]?.name || ''}`,
    id: `0-0`,
  });

  const tabs = useMemo<InvestmentPerformanceTabSection[]>(() => {
    return data?.map((item, idx1) => ({
      label: item.label,
      tabs:
        item.data.map(({name}, idx2) => ({
          label: name,
          value: `${item.label}-${name}`,
          id: `${idx1}-${idx2}`,
        })) || [],
    }));
  }, [data]);

  const hasTabs = useMemo(
    () => tabs.length > 1 || tabs.some(tab => tab.tabs.length > 1),
    [tabs]
  );

  const {activeData, isConsolidatedData} = useMemo(() => {
    const sectionIdx = Number((activeTab.id as string).split('-')[0]);
    const tabIdx = Number((activeTab.id as string).split('-')[1]);
    const activeData = data[sectionIdx]?.data[tabIdx]?.performanceData;
    const isConsolidatedData =
      data[sectionIdx]?.data[tabIdx]?.isConsolidatedData;
    return {activeData, isConsolidatedData};
  }, [data, activeTab]);

  return (
    <div className={classnames(styles.wrapper, className)}>
      <SectionHeader
        label={label}
        labelType={'large'}
        onClick={() => null}
        withActionButton={false}
        className={styles.kpisSectionHeader}
      />
      {hasTabs && (
        <div className={styles.tabsSectionsWrapper}>
          {tabs.map((section, idx) => (
            <div key={idx} className={styles.tabsSection}>
              <div className={styles.tabsSectionLabel}>{section.label}</div>
              <Tabs
                className={styles.tabs}
                tabVariant={StyleVariant.Secondary}
                tabs={section.tabs}
                activeTab={activeTab}
                clickHandler={setActiveTab}
              />
            </div>
          ))}
        </div>
      )}
      {activeData && (
        <PerformanceWidget
          signOffDate={signOffDate}
          type={activeData.type}
          headerData={activeData.headerData}
          roiIrr={activeData.roiIrr}
          totalCost={activeData.totalCost}
          totalValue={activeData.totalValue}
          isConsolidatedData={isConsolidatedData}
        />
      )}
    </div>
  );
};

export default InvestmentPerformanceDashboard;
