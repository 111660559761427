import React from 'react';
import {ButtonSize, ButtonStyle} from '../../../types';
import styles from './Button.module.scss';
import classnames from 'classnames';
import {Icon} from '../index';
import ButtonSpinner from '../button-spinner/ButtonSpinner';

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  text: string;
  styleType?: ButtonStyle;
  size?: ButtonSize;
  icon?: string;
  loading?: boolean;
  className?: string;
}

const Button: React.FC<ButtonProps> = ({
  id,
  text,
  styleType = ButtonStyle.Primary,
  size = ButtonSize.Medium,
  onClick,
  disabled,
  icon,
  loading = false,
  className,
}) => {
  return (
    <button
      id={id}
      className={classnames(
        styles.wrapper,
        styles[`wrapper__${styleType}`],
        styles[size],
        loading && styles.loading,
        className
      )}
      onClick={onClick}
      disabled={disabled}
      data-testid="btn"
      data-test={id}
    >
      {icon ? <Icon name={icon} className={styles.icon} /> : null}
      {loading ? <ButtonSpinner /> : null}
      <span className={styles.text}>{text}</span>
    </button>
  );
};

export default Button;
