import {all, call, put, select, takeEvery} from 'redux-saga/effects';
import {RootState} from '../../store';
import {acquireAuthResult, msalInstance} from '../../';
import {
  DataCollection,
  DataCollectionWorkflowTableRowItem,
  FormStatus,
  ManageWorkflowOptions,
  StartWorkflowPayloadExternal,
  StartWorkflowPayloadInternal,
  UpdateActiveWorkflowPayload,
} from '../../types';
import {
  getDataCollection,
  getManageWorkflowOptions,
  startWorkflow,
  stopWorkflow,
  updateActiveWorkflow,
} from '../../api/getDataCollection';
import {errorHandlerAction} from '../../actions';
import {
  getManageWorkflowOptionsAction,
  setDataCollectionData,
  setDataCollectionError,
  setDataCollectionManageWorkflowFormError,
  setManageWorkflowFormStatus,
  setManageWorkflowOptions,
  startWorkflowAction,
  stopWorkflowActions,
  updateActiveWorkflowAction,
  updateDataCollectionWorkflowTableItem,
} from '../../reducers/dataCollectionSlice';
import {PayloadAction} from '@reduxjs/toolkit';
import {responseErrorResolver} from '../../utils';
import i18n from '../../i18n';

export const dataCollectionSaga = function* dataCollectionSaga() {
  try {
    const {accessToken} = yield call(acquireAuthResult, msalInstance);
    const response: DataCollection = yield call(getDataCollection, accessToken);
    yield put(setDataCollectionData(response));
  } catch (err) {
    console.warn(err);
    yield put(errorHandlerAction({error: err}));
  }
};

export const getManageWorkflowOptionsSaga =
  function* getManageWorkflowOptionsSaga({
    payload,
  }: PayloadAction<{id: string}>) {
    try {
      const {accessToken} = yield call(acquireAuthResult, msalInstance);
      const response: ManageWorkflowOptions = yield call(
        getManageWorkflowOptions,
        accessToken,
        payload.id
      );
      yield put(setManageWorkflowOptions(response));
    } catch (err) {
      console.warn(err);
      yield put(errorHandlerAction({error: err}));
    }
  };

export const startWorkflowSaga = function* startWorkflowSaga({
  payload,
}: PayloadAction<StartWorkflowPayloadExternal | StartWorkflowPayloadInternal>) {
  if (payload) {
    yield call(manageWorkflowErrorHelperSaga);
    try {
      const {accessToken} = yield call(acquireAuthResult, msalInstance);
      yield put(setManageWorkflowFormStatus(FormStatus.Pending));
      const {id, reportingGroupId, ...rest} = payload;
      const response: DataCollectionWorkflowTableRowItem = yield call(
        startWorkflow,
        accessToken,
        id,
        rest
      );
      yield put(setManageWorkflowFormStatus(FormStatus.Success));
      yield put(
        updateDataCollectionWorkflowTableItem({
          data: response,
          reportingGroupId,
        })
      );
    } catch (err) {
      console.warn(err);
      yield put(setManageWorkflowFormStatus(FormStatus.Ready));
      const {error, formError} = responseErrorResolver(
        err,
        i18n.t('DataCollection.StartWorkflow.Error')
      );
      yield put(setDataCollectionError(error));
      yield put(setDataCollectionManageWorkflowFormError(formError));
    }
  }
};

export const updateActiveWorkflowSaga = function* updateActiveWorkflowSaga({
  payload,
}: PayloadAction<UpdateActiveWorkflowPayload>) {
  if (payload) {
    yield call(manageWorkflowErrorHelperSaga);
    try {
      const {accessToken} = yield call(acquireAuthResult, msalInstance);
      yield put(setManageWorkflowFormStatus(FormStatus.Pending));
      const {id, reportingGroupId, ...rest} = payload;
      const response: DataCollectionWorkflowTableRowItem = yield call(
        updateActiveWorkflow,
        accessToken,
        id,
        rest
      );
      yield put(setManageWorkflowFormStatus(FormStatus.Success));
      yield put(
        updateDataCollectionWorkflowTableItem({
          data: response,
          reportingGroupId,
        })
      );
    } catch (err) {
      console.warn(err);
      yield put(setManageWorkflowFormStatus(FormStatus.Ready));
      const {error, formError} = responseErrorResolver(
        err,
        i18n.t('UpdateWorkflow.Error')
      );
      yield put(setDataCollectionError(error));
      yield put(setDataCollectionManageWorkflowFormError(formError));
    }
  }
};

export const stopWorkflowSaga = function* stopWorkflowSaga({
  payload,
}: PayloadAction<
  Pick<StartWorkflowPayloadExternal, 'id' | 'reportingGroupId'>
>) {
  if (payload) {
    yield call(manageWorkflowErrorHelperSaga);
    try {
      const {accessToken} = yield call(acquireAuthResult, msalInstance);
      yield put(setManageWorkflowFormStatus(FormStatus.Pending));
      const response: DataCollectionWorkflowTableRowItem = yield call(
        stopWorkflow,
        accessToken,
        payload.id
      );
      yield put(setManageWorkflowFormStatus(FormStatus.Success));
      yield put(
        updateDataCollectionWorkflowTableItem({
          data: response,
          reportingGroupId: payload.reportingGroupId,
        })
      );
    } catch (err) {
      console.warn(err);
      yield put(setManageWorkflowFormStatus(FormStatus.Ready));
      const {error, formError} = responseErrorResolver(
        err,
        i18n.t('DataCollection.StopWorkflow.Error')
      );
      yield put(setDataCollectionError(error));
      yield put(setDataCollectionManageWorkflowFormError(formError));
    }
  }
};

const manageWorkflowErrorHelperSaga =
  function* manageWorkflowErrorHelperSaga() {
    const error: string | null = yield select(
      (state: RootState) => state.dataCollection.main.error
    );
    const formError: string | null = yield select(
      (state: RootState) => state.dataCollection.main.formError
    );
    if (error) {
      yield put(setDataCollectionError(null));
    }
    if (formError) {
      yield put(setDataCollectionManageWorkflowFormError(null));
    }
  };

export const dataCollectionSagaWatcher = function* dataCollectionSagaWatcher() {
  yield all([
    takeEvery(
      getManageWorkflowOptionsAction.type,
      getManageWorkflowOptionsSaga
    ),
    takeEvery(startWorkflowAction.type, startWorkflowSaga),
    takeEvery(updateActiveWorkflowAction.type, updateActiveWorkflowSaga),
    takeEvery(stopWorkflowActions.type, stopWorkflowSaga),
  ]);
};
