import React from 'react';
import styles from './DocumentNameCell.module.scss';
import classnames from 'classnames';
import {Icon, TextTruncation} from '../../../../index';
import {useFileIcon} from '../../../../../../hooks';

type DocumentNameCellProps = {
  fileName: string;
  fileType: string;
  fileSize: number; // maybe will be useful
  isPrivate: boolean;
};

const DocumentNameCell = ({
  fileName,
  fileType,
  isPrivate,
}: DocumentNameCellProps): JSX.Element => {
  const fileIcon = useFileIcon(fileType);

  return (
    <div className={classnames(styles.wrapper)}>
      <Icon className={styles.fileIcon} name={fileIcon} />
      <TextTruncation className={styles.documentName} text={fileName} />
      {isPrivate ? <Icon className={styles.lockIcon} name={'lock'} /> : null}
    </div>
  );
};

export default DocumentNameCell;
