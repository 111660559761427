import React from 'react';
import classnames from 'classnames';
import {StyleVariant} from '../../../types/index';
import {SkeletonLoader} from '../../global';
import ListItemSkeleton from './component/list-item/ListItemSkeleton';
import styles from './CapitalWidget.module.scss';

interface CapitalWidgetSkeletonProps {
  variant?: StyleVariant;
}

const CapitalWidgetSkeleton: React.FC<CapitalWidgetSkeletonProps> = ({
  variant = StyleVariant.Primary,
}) => {
  return (
    <div className={classnames(styles.wrapper, styles[`wrapper__${variant}`])}>
      <div className={styles.title}>
        <SkeletonLoader width={'50%'} />
      </div>
      <div className={styles.columns}>
        <div className={styles.list}>
          {Array.from(Array(6)).map((_, idx) => (
            <ListItemSkeleton key={idx} />
          ))}
        </div>
        <SkeletonLoader
          className={styles.chart}
          isCircle
          width={'100%'}
          height={100}
        />
      </div>
    </div>
  );
};

export default CapitalWidgetSkeleton;
