import React, {useState} from 'react';
import {SectionHeader} from '../../dashboard';
import {AsAtController} from '../../global';
import CapitalWidget from '../capital-widget/CapitalWidget';
import {CapitalData, ExportDropdownOption, Func} from '../../../types/index';
import styles from './FundCapitalDashboard.module.scss';
import {CapitalWidgetSkeleton} from '../index';
import ExportDropdown from '../../global/export-dropdown/ExportDropdown';
import {useTranslation} from 'react-i18next';

interface FundCapitalDashboardProps extends CapitalData {
  handleUpdateData: Func<[string, string], void>;
  fundId: string;
  exportOptions: ExportDropdownOption[];
  exportHandler: Func<[ExportDropdownOption], void>;
}

const FundCapitalDashboard: React.FC<FundCapitalDashboardProps> = ({
  title,
  asAt,
  firstDate,
  isLoading,
  widgets,
  handleUpdateData,
  fundId,
  exportOptions,
  exportHandler,
}) => {
  const {t} = useTranslation();
  const [currentDate, setCurrentDate] = useState(asAt.value);
  const handleChangeAsAt = (date: string) => {
    setCurrentDate(date);
    handleUpdateData(fundId, date);
  };

  return (
    <div className={styles.wrapper}>
      <SectionHeader
        label={title}
        labelType={'large'}
        onClick={() => {}}
        withActionButton={false}
        className={styles.capitalSectionHeader}
        childrenRight={[
          <AsAtController
            label={t('Global.asAt')}
            startDate={firstDate.value}
            endDate={asAt.value}
            value={currentDate}
            onChange={handleChangeAsAt}
          />,
          <ExportDropdown
            exportOptions={exportOptions}
            exportHandler={exportHandler}
          />,
        ]}
      />
      <div className={styles.widgets}>
        {!isLoading
          ? widgets.map(widget => (
              <div key={widget.id + widget.label} className={styles.widgetItem}>
                <CapitalWidget {...widget} />
              </div>
            ))
          : Array.from(Array(2)).map((_, idx) => (
              <div key={idx} className={styles.widgetItem}>
                <CapitalWidgetSkeleton />
              </div>
            ))}
      </div>
    </div>
  );
};

export default FundCapitalDashboard;
