import {ColumnDef, createColumnHelper} from '@tanstack/react-table';
import {
  DataCollectionWorkflowTableRowItem,
  FlexHAlign,
  Func,
  HeaderMapping,
} from '../../../../types';
import Th from '../cells/th/Th';
import Td from '../cells/td/Td';
import React from 'react';
import styles from './DataCollectionWorkflowTableColumnDefs.module.scss';
import {ActivityTimeline, IconButton} from '../../index';
import ChevronLinkCell from '../cells/chevron-link-cell/ChevronLinkCell';
import StatusTooltipInfo from './status-tooltip-info/StatusTooltipInfo';
import routePaths from '../../../../routePaths';

export const genDataCollectionWorkflowTableColumnDefs = (
  headerMapping: HeaderMapping[],
  openEditModalHandler: Func<[DataCollectionWorkflowTableRowItem], void>
): ColumnDef<DataCollectionWorkflowTableRowItem, any>[] => {
  const columnHelper = createColumnHelper<DataCollectionWorkflowTableRowItem>();
  return [
    columnHelper.accessor('portfolioCompany', {
      id: 'portfolioCompany',
      header: props => (
        <Th {...props} headerMapping={headerMapping} align={FlexHAlign.start} />
      ),
      cell: props => (
        <Td className={styles.cell} {...props} align={FlexHAlign.start} />
      ),
    }),
    columnHelper.accessor('status', {
      id: 'status',
      header: props => (
        <Th {...props} headerMapping={headerMapping} align={FlexHAlign.start}>
          <StatusTooltipInfo />
        </Th>
      ),
      cell: props => (
        <Td className={styles.cell} {...props} align={FlexHAlign.start}>
          <ActivityTimeline activity={props.row.original.status} />
        </Td>
      ),
    }),
    columnHelper.accessor('template', {
      id: 'template',
      header: props => (
        <Th {...props} headerMapping={headerMapping} align={FlexHAlign.start} />
      ),
      cell: props => (
        <Td className={styles.cell} {...props} align={FlexHAlign.start} />
      ),
    }),
    columnHelper.accessor('reportingInterval', {
      id: 'reportingInterval',
      header: props => (
        <Th {...props} headerMapping={headerMapping} align={FlexHAlign.start} />
      ),
      cell: props => (
        <Td className={styles.cell} {...props} align={FlexHAlign.start} />
      ),
    }),
    columnHelper.accessor(row => row.workflow || '', {
      id: 'workflow',
      header: props => (
        <Th {...props} headerMapping={headerMapping} align={FlexHAlign.start} />
      ),
      cell: props => (
        <Td
          className={styles.cell}
          {...props}
          align={FlexHAlign.start}
          emptyValue="-"
        />
      ),
    }),
    columnHelper.accessor(row => row.assignee || '', {
      id: 'assignee',
      header: props => (
        <Th {...props} headerMapping={headerMapping} align={FlexHAlign.start} />
      ),
      cell: props => (
        <Td
          className={styles.cell}
          {...props}
          align={FlexHAlign.start}
          emptyValue="-"
        />
      ),
    }),
    columnHelper.accessor(row => row.owner.label, {
      id: 'owner',
      header: props => (
        <Th {...props} headerMapping={headerMapping} align={FlexHAlign.start} />
      ),
      cell: props => (
        <Td className={styles.cell} {...props} align={FlexHAlign.start} />
      ),
    }),
    columnHelper.accessor(row => row.workflowStatus.label, {
      id: 'workflowStatus',
      header: props => (
        <Th {...props} headerMapping={headerMapping} align={FlexHAlign.start} />
      ),
      cell: props => (
        <Td className={styles.cell} {...props} align={FlexHAlign.start} />
      ),
    }),
    columnHelper.display({
      id: 'edit',
      header: props => (
        <Th
          {...props}
          align={FlexHAlign.center}
          width={'40px'}
          headerMapping={headerMapping}
        />
      ),
      cell: props => (
        <Td {...props} align={FlexHAlign.end}>
          <IconButton
            className={styles.edit}
            onClick={() => openEditModalHandler(props.row.original)} // Open edit modal handler
            icon={'pencil'}
          />
        </Td>
      ),
    }),
    columnHelper.display({
      id: 'linkTo',
      header: props => (
        <Th
          {...props}
          align={FlexHAlign.center}
          width={'32px'}
          headerMapping={headerMapping}
        />
      ),
      cell: props => (
        <Td
          className={styles.cell}
          {...props}
          align={FlexHAlign.center}
          canHide={false}
        >
          <ChevronLinkCell
            linkTo={`${routePaths.DATA_COLLECTION}/${props.row.original.id}`}
          />
        </Td>
      ),
    }),
  ];
};

export default genDataCollectionWorkflowTableColumnDefs;
