import {
  NavData,
  ScenarioPortfolioPerformanceData,
  SummaryWidgetChartProps,
} from '../types';

export const addNearCastingKeysToNavChart = (
  navData: NavData,
  isNearCasting = false
) => ({
  ...navData,
  timeSeries: {
    data: navData.timeSeries.data.map((item, index) => {
      const {NAV, jCurve, ...rest} = item;
      const dataLength = navData.timeSeries.data.length;
      if (dataLength > 1 && index >= dataLength - 2) {
        return {...item, 'NC NAV': NAV, 'NC jCurve': jCurve};
      }

      if (isNearCasting) {
        return {...rest, 'NC NAV': NAV, 'NC jCurve': jCurve};
      }
      return item;
    }),
  },
});

export const addNearCastingKeysToMoicChart = (
  data: SummaryWidgetChartProps,
  isNearCasting = false
) => ({
  ...data,
  timeSeries: {
    data: data?.timeSeries?.data?.map((item: any, index) => {
      const {'Gross IRR': grossIrr, 'Net IRR': netIrr, MOIC, ...rest} = item;
      const dataLength = data?.timeSeries?.data?.length || 0;
      if (dataLength > 1 && index === dataLength - 1) {
        return {
          ...item,
          'NC Gross IRR': grossIrr,
          'NC Net IRR': netIrr,
          'NC MOIC': MOIC,
        };
      }

      if (isNearCasting) {
        return {
          ...rest,
          'NC Gross IRR': grossIrr,
          'NC Net IRR': netIrr,
          'NC MOIC': MOIC,
        };
      }

      return item;
    }),
  },
});

export const addNearCastingKeysToPiccChart = (
  data: SummaryWidgetChartProps,
  isNearCasting = false
) => ({
  ...data,
  timeSeries: {
    data: data?.timeSeries?.data?.map((item: any, index) => {
      const {PICC, DPI, RVPI, TVPI, ...rest} = item;
      const dataLength = data?.timeSeries?.data?.length || 0;
      if (dataLength > 1 && index === dataLength - 1) {
        return {
          ...item,
          'NC PICC': PICC,
        };
      }

      if (isNearCasting) {
        return {
          ...rest,
          'NC PICC': PICC,
          'NC DPI': DPI,
          'NC RVPI': RVPI,
          'NC TVPI': TVPI,
        };
      }

      return item;
    }),
  },
});

export const addNearCastingKeysToTotalValueChart = (
  data: ScenarioPortfolioPerformanceData,
  isNearCasting = false
) => ({
  ...data,
  timeSeries: {
    data: data?.timeSeries?.data?.map((item: any, index) => {
      const {'Total value': totalValue, ...rest} = item;
      const dataLength = data?.timeSeries?.data?.length || 0;
      if (dataLength > 1 && index === dataLength - 1) {
        return {
          ...item,
          'NC Total value': totalValue,
        };
      }

      if (isNearCasting) {
        return {
          ...rest,
          'NC Total value': totalValue,
        };
      }

      return item;
    }),
  },
});

export const addNearCastingKeysToRoiIrrChart = (
  data: ScenarioPortfolioPerformanceData,
  isNearCasting = false
) => ({
  ...data,
  timeSeries: {
    data: data?.timeSeries?.data?.map((item: any, index) => {
      const {'Net IRR': netIrr, ROI, ...rest} = item;
      const dataLength = data?.timeSeries?.data?.length || 0;
      if (dataLength > 1 && index === dataLength - 1) {
        return {
          ...item,
          'NC Net IRR': netIrr,
          'NC ROI': ROI,
        };
      }

      if (isNearCasting) {
        return {
          ...rest,
          'NC Net IRR': netIrr,
          'NC ROI': ROI,
        };
      }

      return item;
    }),
  },
});

export const getHalfBarWidthInTime = (
  to: Date,
  from: Date,
  range: number,
  chartWidth: number
) => {
  const timeSpan = to.getTime() - from.getTime();
  const pixelToTimeRatio = timeSpan / chartWidth;
  const barWidth = chartWidth / range;
  return (barWidth / 2) * pixelToTimeRatio;
};
