import {all, call, put, takeEvery} from 'redux-saga/effects';
import {acquireAuthResult, msalInstance} from '../..';
import {PayloadAction} from '@reduxjs/toolkit';
import {errorHandlerAction} from '../../actions';
import {
  getScenarioOnePageReportPortfolioPerformanceDataAction,
  setLoading,
  setScenarioOnePageReportPortfolioPerformanceNearCastingData,
} from '../../reducers/scenarioOnePageReportPortfolioPerformanceSlice';
import {
  FundPortfolio,
  ScenarioReportInvestmentData,
  ScenarioReportPortfolioSummaryData,
} from '../../types';
import {getScenarioPortfolioPerformanceData} from '../../api/getScenarioPortfolioPerformance';

export const scenarioOnePageReportPortfolioPerformanceSaga =
  function* scenarioOnePageReportPortfolioPerformanceSaga({
    payload,
  }: PayloadAction<{
    match: any;
  }>) {
    const id: string = payload?.match?.params?.id;
    if (id) {
      yield put(setLoading());
      try {
        const {accessToken} = yield call(acquireAuthResult, msalInstance);
        const response: FundPortfolio = yield call(
          getScenarioPortfolioPerformanceData,
          accessToken,
          id
        );

        yield put(
          setScenarioOnePageReportPortfolioPerformanceNearCastingData({
            portfolioSummary: response.portfolioPerformanceData
              .widgets[0] as ScenarioReportPortfolioSummaryData,
            investmentFocus: response.portfolioExposureData
              .widgets as any as ScenarioReportInvestmentData[],
          })
        );
        yield put(setLoading());
      } catch (err) {
        console.warn(err);
        yield put(setLoading());
        yield put(errorHandlerAction({error: err}));
      }
    }
  };

export const ScenarioOnePageReportPortfolioPerformanceSagaWatcher =
  function* ScenarioOnePageReportPortfolioPerformanceSagaWatcher() {
    yield all([
      takeEvery(
        getScenarioOnePageReportPortfolioPerformanceDataAction.type,
        scenarioOnePageReportPortfolioPerformanceSaga
      ),
    ]);
  };
