import axios from 'axios';
import config from '../config';
import {ClientAdminUsers, CompanyUser, UserData} from '../types';

export const getClientAdminUsersData = async (token: string) => {
  const {data} = await axios.get<ClientAdminUsers[]>(
    `${config.APP_URI}/admin/user-configurations`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );
  return data[0];
};

export const updateUser = async (
  token: string,
  id: string,
  payload: UserData | CompanyUser
) => {
  const result = await axios.put(`${config.APP_URI}/users/${id}`, payload, {
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  return result.data;
};

export const removeUser = async (token: string, id: string) => {
  const result = await axios.put(
    `${config.APP_URI}/user/delete/${id}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );

  return result.data;
};

export const inviteUser = async (
  token: string,
  payload: Pick<UserData, 'role' | 'email'>
) => {
  const result = await axios.post(`${config.APP_URI}/users`, payload, {
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  return result.data;
};

export const resendUserInvite = async (
  token: string,
  payload: Pick<UserData, 'id'>
) => {
  const result = await axios.get(
    `${config.APP_URI}/user/resend-invite/${payload.id}`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return result.data;
};

export const reactivateUser = async (
  token: string,
  payload: Pick<UserData, 'id'>
) => {
  const result = await axios.put(
    `${config.APP_URI}/user/reactivate/${payload.id}`,
    {},
    {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return result.data;
};
