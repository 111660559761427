import {createAction, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {
  DataCollectionDetails,
  DataCollectionSliceData,
  ReportingTableTemplateType,
} from '../types';

export const getDataCollectionHistoricalReporting = createAction(
  'data-collection-details/get-data-collection-historical-reporting',
  (id: string, templateType: ReportingTableTemplateType) => {
    return {
      payload: {
        id,
        templateType,
      },
    };
  }
);

interface DataCollectionDetailsSlice {
  data: DataCollectionSliceData | null;
  isHistoricalDataLoading: boolean;
}

const initialState: DataCollectionDetailsSlice = {
  data: null,
  isHistoricalDataLoading: false,
};

const dataCollectionDetailsSlice = createSlice({
  name: 'data-collection/details',
  initialState,
  reducers: {
    setDataCollectionDetailsData(
      state: DataCollectionDetailsSlice,
      action: PayloadAction<DataCollectionDetails>
    ) {
      state.data = {
        ...action.payload,
        data: action.payload.data.map(({reportingData, ...rest}) => ({
          ...rest,
          reportingTableDataType:
            reportingData.type as ReportingTableTemplateType,
        })),
      };
    },
    clearDataCollectionDetailsData(state: DataCollectionDetailsSlice) {
      state.data = null;
    },
    setDataCollectionHistoricalData(
      state: DataCollectionDetailsSlice,
      action: PayloadAction<{
        isHistorical: boolean;
        reportingTableDataType: ReportingTableTemplateType;
      }>
    ) {
      if (state.data) {
        state.data.data.forEach((reportingGroup, idx: number) => {
          if (
            state?.data?.data &&
            action.payload.reportingTableDataType ===
              reportingGroup.reportingTableDataType
          ) {
            state.data.data[idx].isHistorical = action.payload.isHistorical;
          }
        });
      }
    },
    setIsHistoricalDataLoading(
      state: DataCollectionDetailsSlice,
      action: PayloadAction<boolean>
    ) {
      state.isHistoricalDataLoading = action.payload;
    },
  },
});

export default dataCollectionDetailsSlice.reducer;

export const {
  setDataCollectionDetailsData,
  clearDataCollectionDetailsData,
  setDataCollectionHistoricalData,
  setIsHistoricalDataLoading,
} = dataCollectionDetailsSlice.actions;
