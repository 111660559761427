import React from 'react';
import {EntityPreviewServiceProps} from '../../../../../types/index';
import styles from './EntityPreviewService.module.scss';
import {useTranslation} from 'react-i18next';

const EntityPreviewService: React.FC<EntityPreviewServiceProps> = ({
  label,
  data,
}) => {
  const {t} = useTranslation();
  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>{label}</div>
      <div className={styles.table}>
        <div className={styles.row}>
          <div className={styles.head}>{t('Global.Type')}</div>
          <div className={styles.head}>{t('Global.Provider')}</div>
          <div className={styles.head}>
            {t('Funds.EntityPreviewService.Title.LengthReleationship')}
          </div>
        </div>
        {data.map((item, idx) => (
          <div className={styles.row} key={idx}>
            <div data-label={'Type'} className={styles.cell}>
              {item.type}
            </div>
            <div data-label={'Provider'} className={styles.cell}>
              {item.provider}
            </div>
            <div data-label={'Length of relationship'} className={styles.cell}>
              {item.lengthOfRelationship}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default EntityPreviewService;
