import React from 'react';
import {Button} from '../global';
import {ButtonStyle} from '../../types';
import styles from './PrevNextButtons.module.scss';

interface PrevNextButtonsProps {
  handlePrimary: (e: any) => void;
  handleSecondary: () => void;
  primaryText: string;
  secondaryText: string;
  isSubmitDisabled?: boolean;
}

const PrevNextButtons = ({
  handlePrimary,
  handleSecondary,
  primaryText,
  secondaryText,
  isSubmitDisabled,
}: PrevNextButtonsProps) => {
  return (
    <div className={styles.buttonsWrapper}>
      <Button
        text={secondaryText}
        styleType={ButtonStyle.Secondary}
        onClick={handleSecondary}
      />
      <Button
        disabled={isSubmitDisabled}
        onClick={handlePrimary}
        className={styles.main}
        text={primaryText}
        styleType={ButtonStyle.Primary}
      />
    </div>
  );
};

export default PrevNextButtons;
