import React, {useMemo} from 'react';
import {
  Button,
  Checkbox,
  Icon,
  Modal,
  MultiSelect,
  Tag,
  TagInput,
} from '../../global';
import {
  ButtonStyle,
  FileUploadContext,
  FormStatus,
  MultiSelectValue,
  StyleVariant,
  TagItem,
  UploadFileFormProps,
  UploadFileFormState,
} from '../../../types';
import {useTranslation} from 'react-i18next';
import styles from './UploadFileForm.module.scss';
import {useFormState, useIsSubmitDisabled, useTooltip} from '../../../hooks';
import {
  uploadFileFormInitialState,
  uploadFileFormSchema,
} from '../../../constants';
import {
  checkFileNameIsAllowed,
  checkFileTypeIsAllowed,
  getDocumentIconByType,
} from '../../../utils';
import {Position} from '../../../context/TooltipContext';
import SystemTagsTooltipContent from './components/system-tags-tooltip-content/SystemTagsTooltipContent';
import CustomTagsTooltipContent from './components/custom-tags-tooltip-content/CustomTagsTooltipContent';
import classnames from 'classnames';

const UploadFileForm: React.FC<UploadFileFormProps> = ({
  isOpen,
  onClose,
  onSubmit,
  formStatus,
  files,
  options,
  formError,
  error,
}) => {
  const {
    formState,
    handleChange,
    errors,
    validate,
    setFormState,
    isFormStateChanged,
  } = useFormState<UploadFileFormState>(
    uploadFileFormInitialState,
    uploadFileFormSchema
  );
  const {t} = useTranslation();
  const {showTooltip, hideTooltip} = useTooltip();

  const fileList = useMemo(() => {
    let result: File[] = [];
    for (let i = 0; i < files.length; i++) {
      result = [...result, files[i]];
    }
    return result;
  }, [files]);
  const handleSubmit = () => {
    const validated = validate(formState);
    if (!validated) return;
    onSubmit({
      ...formState,
      files,
      fileUploadContext: FileUploadContext.DocumentExplorer,
    });
  };

  const handleChangeCustomTags = (value: TagItem[]) => {
    setFormState(prev => ({
      ...prev,
      customTags: value,
    }));
  };

  const handleChangeSystemTags = (event: MultiSelectValue) => {
    validate({[event.target.name]: event.target.value});
    handleChange(event);
  };

  const handleMouseEnter = (content: JSX.Element) => (e: React.MouseEvent) => {
    showTooltip({
      content,
      position: Position.Top,
      target: e.currentTarget as HTMLElement,
      className: styles.tooltip,
    });
  };

  const {isSubmitDisabled} = useIsSubmitDisabled(
    formError,
    errors,
    error,
    isFormStateChanged
  );

  const sectionTags = useMemo(() => {
    return options.sectionTags.map(tag => ({
      ...tag,
      value: tag.id,
      ...(tag.label === 'Data Collection Details' ||
      tag.label === 'Reporting Period'
        ? {mandatory: true, visible: false}
        : {}),
    }));
  }, [options.sectionTags]);

  return (
    <Modal
      title={t('Documents.UploadFileForm.ModalTitle')}
      onClose={onClose}
      closeOnOutsideClick={false}
      isOpen={isOpen}
      variant={StyleVariant.Primary}
      className={styles.wrapper}
      footerChildren={
        <div className={styles.buttons}>
          <Button
            text={t('Add')}
            onClick={handleSubmit}
            loading={formStatus === FormStatus.Pending}
            className={styles.lightThemePrimaryButton}
            disabled={isSubmitDisabled}
          />
          <Button
            styleType={ButtonStyle.Secondary}
            text={t('Global.Cancel')}
            onClick={onClose}
            disabled={formStatus === FormStatus.Pending}
            className={styles.lightThemeSecondaryButton}
          />
        </div>
      }
    >
      <div className={styles.inner}>
        <div className={styles.fileList}>
          {fileList.map((file, idx) => {
            const isFileAllowed = checkFileTypeIsAllowed(file.type);
            const isFileNameAllowed = checkFileNameIsAllowed(file.name);
            return (
              <div
                key={idx}
                className={classnames(
                  styles.fileListItem,
                  !isFileAllowed && styles.notSupported,
                  !isFileNameAllowed && styles.notSupported
                )}
              >
                <Icon
                  className={styles.fileListItemIcon}
                  name={
                    isFileAllowed ? getDocumentIconByType(file.type) : 'warning'
                  }
                />{' '}
                <div>
                  {file.name}
                  {!isFileAllowed && (
                    <div className={styles.fileTypeError}>
                      {t('File type is not supported')}
                    </div>
                  )}
                  {!isFileNameAllowed && (
                    <div className={styles.fileTypeError}>
                      {t(
                        'These special characters are not allowed in file name: " \\ / : | < > * ?'
                      )}
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
        <div className={styles.inputsGrid}>
          <div className={styles.columnLabel}>
            {t('Global.SystemTags')}{' '}
            <span
              className={styles.hint}
              onMouseEnter={handleMouseEnter(
                <SystemTagsTooltipContent className={styles.tooltip} />
              )}
              onMouseLeave={hideTooltip}
            >
              <Icon className={styles.infoIcon} name={'info'} />
            </span>
          </div>
          <div className={styles.columnLabel}>
            {t('Global.CustomTags')}
            <span
              className={styles.hint}
              onMouseEnter={handleMouseEnter(
                <CustomTagsTooltipContent className={styles.tooltip} />
              )}
              onMouseLeave={hideTooltip}
            >
              <Icon className={styles.infoIcon} name={'info'} />
            </span>
          </div>
          <MultiSelect
            required={true}
            checkboxVariant={StyleVariant.Secondary}
            label={t('Choose entity tags')}
            name={'entityTags'}
            variant={StyleVariant.Secondary}
            className={styles.tagsDropdown}
            value={formState.entityTags}
            onChange={handleChangeSystemTags}
            options={options.entityTags.map(tagGroup =>
              tagGroup.map(tag => ({...tag, value: tag.id}))
            )}
            withSearch={true}
            errorMessage={errors.entityTags.message}
            validate={validate}
            optionChildren={({label, color}) => (
              <Tag
                label={label}
                color={color}
                variant={StyleVariant.Secondary}
              />
            )}
            scrollIntoView={true}
          />
          <TagInput
            label={t('Choose or add custom tags')}
            value={formState.customTags}
            variant={StyleVariant.Secondary}
            onChange={handleChangeCustomTags}
            options={options.customTags}
            optionChildren={({label, color}) => (
              <Tag
                label={label}
                color={color}
                variant={StyleVariant.Secondary}
              />
            )}
          />
          <MultiSelect
            checkboxVariant={StyleVariant.Secondary}
            label={t('Choose section tags')}
            name={'sectionTags'}
            className={styles.tagsDropdown}
            variant={StyleVariant.Secondary}
            value={formState.sectionTags}
            onChange={handleChangeSystemTags}
            options={sectionTags}
            withSearch={true}
            optionChildren={({label, color}) => (
              <Tag
                label={label}
                color={color}
                variant={StyleVariant.Secondary}
              />
            )}
            scrollIntoView={true}
          />
          <div className={styles.accessInputRow}>
            <div className={styles.columnLabel}>{t('Global.Access')}</div>
            <Checkbox
              label={t('Global.Private')}
              checked={formState.isPrivate}
              name={'isPrivate'}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default UploadFileForm;
