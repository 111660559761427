import {createSelector} from '@reduxjs/toolkit';
import {RootState} from '../store';

const scenarioDetails = (state: RootState) => state.scenarioDetails;

const scenarioDetailsSelector = createSelector(
  [scenarioDetails],
  fundDetails => {
    const data = fundDetails.report.data;
    const {
      fundName,
      asAt,
      fundColor,
      id,
      status,
      fundPerformanceData,
      signOffDate,
    } = data || {};

    const valuation = fundDetails?.valuation?.data;
    const {startDate, endDate} = valuation || {};

    const {lastReportedDate} =
      fundDetails?.main?.data ||
      valuation ||
      fundDetails?.transactions?.data ||
      {};

    return {
      fundName,
      asAt,
      fundColor,
      id,
      status,
      fundPerformanceData,
      startDate,
      endDate,
      signOffDate,
      lastReportedDate,
    };
  }
);

export default scenarioDetailsSelector;
