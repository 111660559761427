import React, {Fragment, ReactElement, useMemo, useState} from 'react';
import classnames from 'classnames';
import {useLocation} from 'react-router-dom';
import {
  ActiveDashboardView,
  ButtonStyle,
  CustomizeOrderOption,
  Func,
  FundData,
  FundSummaryWidgetProps,
  KpiTabs,
} from '../../../types';
import {
  Button,
  FundSummaryWidget,
  IconButton,
  SectionHeader,
  SegmentedControl,
  SelectDropdown,
} from '../../';
import {
  fundSectionHeaderDropdownOptions,
  fundSectionHeaderOptions,
} from '../../../constants';
import {fundListSwitchOptions} from '../../../constants';
import {SegmentedControlVariants} from '../../global/segmented-control/SegmentedControl';
import homeLayoutStyles from './FundDashboard.module.scss';
import pageLayoutStyles from './FundDashboardTemp.module.scss';
import CustomizeOrderPanel from '../../global/customize-order-panel/CustomizeOrderPanel';
import {UpdateLayoutPropertyPayload} from '../../../types/userLayoutProperty';
import {useTranslation} from 'react-i18next';

interface FundDashboardProps extends Omit<FundData, 'headerMapping'> {
  id: string;
  withToggleView?: boolean;
  withActionButton?: boolean;
  withControlsHidden?: boolean;
  activeView?: ActiveDashboardView;
  setActiveView?: Func<[ActiveDashboardView], void>;
  onActionButtonClick?: React.MouseEventHandler<HTMLButtonElement>;
  className?: string;
  table?: ReactElement;
  exportHandler?: Func<[void], void>;
  exportInProgress?: boolean;
  isReorderingAvailable?: boolean;
  onUpdateLayoutSettings?: Func<[UpdateLayoutPropertyPayload], void>;
  isHomePage?: boolean;
  onFundsDataUpdate?: Func<[FundSummaryWidgetProps[]], void>;
}

const FundDashboard: React.FC<FundDashboardProps> = ({
  id,
  fundLabel,
  fundCount,
  widgets,
  withToggleView = false,
  withActionButton = true,
  withControlsHidden = false,
  activeView = ActiveDashboardView.Tiles,
  setActiveView,
  onActionButtonClick = () => null,
  className = '',
  table,
  exportHandler = () => null,
  exportInProgress,
  isReorderingAvailable,
  layoutSettings,
  onUpdateLayoutSettings,
  onFundsDataUpdate,
  isHomePage,
}) => {
  const {t} = useTranslation();
  const [panelOpen, setPanelOpen] = useState(false);
  const [activeTabGroup, setActiveTabGroup] = useState<KpiTabs | undefined>(
    KpiTabs.Nav
  );
  const [order, setOrder] = useState<CustomizeOrderOption[]>(
    widgets.map(item => ({id: item.id, label: item.fundName}))
  );

  const showFundSectionHeaderOptions = useMemo(
    () =>
      widgets.length > 1 &&
      !withControlsHidden &&
      activeView === ActiveDashboardView.Tiles,
    [widgets, withControlsHidden, activeView]
  );

  const handleChangeActiveTabGroup = (value: KpiTabs) => {
    setActiveTabGroup(value);
  };

  const handleChangeOrder = (order: CustomizeOrderOption[]) => {
    setOrder(order);
    if (onFundsDataUpdate) {
      let result: FundSummaryWidgetProps[] = [];
      order.forEach(orderItem => {
        const widget = widgets.find(({id}) => id === orderItem.id);
        if (widget) result = [...result, widget];
      });
      onFundsDataUpdate(result);
    }
  };

  const handleClearActiveTabGroup = () => {
    setActiveTabGroup(undefined);
  };

  const handleActiveViewChange = (val: ActiveDashboardView): void => {
    if (setActiveView) {
      setActiveView(val);
    }
  };

  const handleSaveOrder = () => {
    setPanelOpen(false);
    if (onUpdateLayoutSettings) {
      onUpdateLayoutSettings({
        value: order.map(({id}) => id),
        layoutProperty: layoutSettings?.layoutProperty || '',
      });
    }
  };
  const handleCancelOrder = () => {
    setPanelOpen(false);
  };

  const {pathname} = useLocation();
  const styles = pathname === '/' ? homeLayoutStyles : pageLayoutStyles;

  return (
    <section id={id} className={classnames(styles.wrapper, className)}>
      <SectionHeader
        label={fundLabel}
        count={fundCount}
        className={styles.sectionHeader}
        withActionButton={false}
        onClick={() => {}}
        id={'home-page-active-funds'}
        childrenRight={[
          showFundSectionHeaderOptions && (
            <Fragment>
              <div className={styles.segmentedControl}>
                <SegmentedControl
                  id={`${id}-segmented-control-1`}
                  options={fundSectionHeaderOptions}
                  onChange={value =>
                    handleChangeActiveTabGroup(value as KpiTabs)
                  }
                  value={activeTabGroup}
                />
              </div>

              <div className={styles.selectDropdown}>
                <SelectDropdown
                  id={`${id}-select-dropdown`}
                  onChange={value =>
                    handleChangeActiveTabGroup(value as KpiTabs)
                  }
                  options={fundSectionHeaderDropdownOptions}
                  value={activeTabGroup || 'Custom'}
                  placeHolderKey={activeTabGroup ? undefined : 'Custom'}
                />
              </div>
            </Fragment>
          ),
          withToggleView && (
            <Fragment>
              <SegmentedControl
                id={`${id}-segmented-control-2`}
                options={fundListSwitchOptions}
                onChange={val =>
                  handleActiveViewChange(val as ActiveDashboardView)
                }
                value={activeView}
                variant={SegmentedControlVariants.Secondary}
                className={styles.viewSwitchToggle}
              />
              <IconButton
                id={`${id}-export`}
                onClick={exportHandler}
                styleType={ButtonStyle.Primary}
                icon="export"
                loading={exportInProgress}
              />
            </Fragment>
          ),
          <div className={styles.buttonsWrapper}>
            {withActionButton && (
              <Button
                id={`${id}-show-all`}
                className={styles.actionButton}
                text={t('FundDashboard.Button.ShowAllFunds')}
                onClick={onActionButtonClick}
              />
            )}
            {isReorderingAvailable &&
              activeView === ActiveDashboardView.Tiles && (
                <IconButton
                  id={`${id}-reordering-toggle`}
                  styleType={ButtonStyle.Secondary}
                  onClick={() => setPanelOpen(prev => !prev)}
                  icon={'pencil'}
                />
              )}
          </div>,
        ]}
      />

      {activeView === ActiveDashboardView.Tiles && (
        <div className={styles.fundsSectionWrapper}>
          <div
            className={classnames(
              styles.fundsSection,
              panelOpen && styles.open
            )}
          >
            {widgets.map((widget, idx) =>
              !isHomePage || idx < 6 ? (
                <div key={widget.id || idx} className={styles.sectionWidget}>
                  <FundSummaryWidget
                    {...widget}
                    activeTab={activeTabGroup}
                    clickHandler={handleClearActiveTabGroup}
                  />
                </div>
              ) : null
            )}
          </div>
          {isReorderingAvailable && (
            <div className={classnames(styles.panel, panelOpen && styles.open)}>
              <div className={styles.panelInner}>
                <CustomizeOrderPanel
                  id={`${id}-reordering`}
                  label={t('Global.CustomizeOrder')}
                  countLabel={t(
                    order.length > 1 ? 'Global.Funds' : 'Global.Fund',
                    {count: order.length}
                  )}
                  options={order}
                  onChange={handleChangeOrder}
                  onCancel={handleCancelOrder}
                  onSave={handleSaveOrder}
                />
              </div>
            </div>
          )}
        </div>
      )}
      {activeView === ActiveDashboardView.Table && table}
    </section>
  );
};

export default FundDashboard;
