import {put, all, takeLatest} from 'redux-saga/effects';
import clearScenarioDetailsDataAction from '../../actions/scenarioDetailsActions';
import {clearScenarioDetailsReportData} from '../../reducers/scenarioDetailsReportSlice';
import {clearScenarioDetailsMainInfoData} from '../../reducers/scenarioDetailsMainInfoSlice';
import {clearScenarioDetailsValuationData} from '../../reducers/scenarioDetailsValuationSlice';

export const clearScenarioDetailsSaga = function* clearScenarioDetailsSaga() {
  yield all([
    put(clearScenarioDetailsReportData()),
    put(clearScenarioDetailsMainInfoData()),
    put(clearScenarioDetailsValuationData()),
  ]);
};

export const scenarioDetailsSagaWatcher =
  function* scenarioDetailsSagaWatcher() {
    yield all([
      takeLatest(clearScenarioDetailsDataAction.type, clearScenarioDetailsSaga),
    ]);
  };
