import axios from 'axios';
import config from '../config';
import {
  ChartDataFilterConfig,
  ClientActiveCompaniesResponse,
  Companies,
} from '../types/index';

export const getAllCompanies = async (token: string, page?: number) => {
  const {data} = await axios.get<Companies[]>(
    `${config.APP_URI}/portfolio-company-lists`,
    {
      params: {
        page: page || 1,
      },
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );
  return data[0];
};

export const getActiveFundInvestmentQuarterlyData = async (
  token: string,
  date?: string
) => {
  const {data} = await axios.get<ClientActiveCompaniesResponse[]>(
    `${config.APP_URI}/portfolio-company-active-list?date=${date ?? ''}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );
  return data[0];
};

export const getActivePortfolioMonitoringQuarterlyData = async (
  token: string,
  date?: string
) => {
  const {data} = await axios.get<ClientActiveCompaniesResponse[]>(
    `${config.APP_URI}/portfolio-company-collected-data?date=${date ?? ''}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );
  return data[0];
};

export const saveChartDataFilterConfig = async (
  token: string,
  payload: ChartDataFilterConfig
) => {
  const {data} = await axios.post(
    `${config.APP_URI}/chart-filter-config`,
    {
      chartType: payload.chartType,
      filterConfig: payload.filterConfig,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );
  return data;
};
