import React, {useMemo, useState} from 'react';
import styles from './MetricsTable.module.scss';
import {
  MetricsChartDataItem,
  MetricsTableProps,
  PaginationType,
} from '../../../types';
import {LoadingOverlay, Pagination, SelectDropdown, ValueFormat} from '../../';
import {metricsTableRowCountOptions} from '../../../constants';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';

const MetricsTable: React.FC<MetricsTableProps> = ({
  id,
  data,
  config,
  isRequestPending,
  tableHeaderMapping,
}) => {
  const [rowsShown, setRowsShown] = useState<number>(
    metricsTableRowCountOptions[0].value as number
  );
  const [page, setPage] = useState(0);

  const pages = Math.ceil(data.length / Number(rowsShown));

  const handleChangeRowsInPage = (value: number) => {
    setRowsShown(value);
    setPage(0);
  };

  const paginatedTableData = useMemo(
    () =>
      data.slice(
        page * Number(rowsShown),
        page * Number(rowsShown) + Number(rowsShown)
      ),
    [data, page, rowsShown]
  );

  const columnHelper = createColumnHelper<MetricsChartDataItem>();

  const columns = tableHeaderMapping.map(headerItem =>
    columnHelper.accessor(row => row[headerItem.columnId], {
      id: headerItem.columnId,
      header: headerItem.renderLabel,
      cell: info => {
        if (headerItem.columnId === 'date') {
          return (
            <ValueFormat
              value={info.getValue() as string}
              format={'dd-MM-yyyy'}
            />
          );
        }
        if (info.getValue() === null) return '-';
        return (
          <ValueFormat value={info.getValue() as string} format={'auto'} />
        );
      },
    })
  );

  const table = useReactTable({
    data: paginatedTableData,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  if (!config.length) return null;

  return (
    <div id={id} className={styles.wrapper}>
      {isRequestPending ? <LoadingOverlay className={styles.overlay} /> : null}
      <div className={styles.tableWrapper}>
        <div className={styles.tableInner}>
          <table className={styles.table}>
            <thead>
              {table.getHeaderGroups().map(headerGroup => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map(header => (
                    <th className={styles.tableTh} key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map(row => (
                <tr key={row.id}>
                  {row.getVisibleCells().map(cell => (
                    <td className={styles.tableTd} key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className={styles.footer}>
        {pages > 1 && (
          <div className={styles.paginationWrapper}>
            <Pagination
              pages={pages}
              onChange={page => setPage(page - 1)}
              type={PaginationType.Numbers}
              page={page + 1}
            />
          </div>
        )}
        <SelectDropdown
          id={`${id}-select-dropdown-row-count-options`}
          onChange={value => handleChangeRowsInPage(value as number)}
          options={[
            ...metricsTableRowCountOptions,
            {id: 'all', label: 'Global.ShowAll', value: data.length},
          ]}
          value={rowsShown}
        />
      </div>
    </div>
  );
};

export default MetricsTable;
