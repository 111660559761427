import axios from 'axios';
import config from '../config';
import {
  CreateNearCastingScenarioCompany,
  NearCastingScenarioCompanyTransactionPayload,
  NearCastingScenarioCompany,
  NearCastingScenarioCompanyTransaction,
  NearCastingScenarioCompanyFormData,
} from '../types';

export const savePortfolioCompanyNearCastingTransaction = async (
  token: string,
  payload: {transaction: NearCastingScenarioCompanyTransactionPayload}
) => {
  if (!payload.transaction.id) {
    const {data} = await axios.post<NearCastingScenarioCompanyTransaction>(
      `${config.APP_URI}/near_casting/portfolio_company_transactions`,
      {...payload.transaction},
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
        },
      }
    );
    return data;
  }

  const {data} = await axios.put<NearCastingScenarioCompanyTransaction[]>(
    `${config.APP_URI}/near_casting/portfolio_company_transactions/${payload.transaction.id}`,
    payload.transaction,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );
  return data;
};

export const deletePortfolioCompanyNearCastingTransaction = async (
  token: string,
  id: string
) => {
  const {data} = await axios.delete(
    `${config.APP_URI}/near_casting/portfolio_company_transactions/${id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );

  return data;
};

export const postNewPortfolioCompany = async (
  token: string,
  payload: CreateNearCastingScenarioCompany
) => {
  const {data} = await axios.post<NearCastingScenarioCompany>(
    `${config.APP_URI}/near_casting/companies`,
    payload,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );

  return data;
};

export const getValuationCalc = async (token: string, id: string) => {
  const {data} = await axios.get<any>(
    `${config.APP_URI}/near_casting/calc_scenario/${id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );
  return data;
};

export const getPortfolioCompanyData = async (token: string, id: string) => {
  const {data} = await axios.get<NearCastingScenarioCompany>(
    `${config.APP_URI}/near_casting/companies/${id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );
  return data;
};

export const putEditPortfolioCompany = async (
  token: string,
  payload: NearCastingScenarioCompanyFormData,
  id: string
) => {
  const {data} = await axios.put(
    `${config.APP_URI}/near_casting/companies/${id}`,
    payload,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );

  return data;
};

export const deletePortfolioCompany = async (token: string, id: string) => {
  const {data} = await axios.delete(
    `${config.APP_URI}/near_casting/companies/${id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );

  return data;
};

export const importPCTransactions = async (
  token: string,
  ncCompanyId: string,
  file: File
) => {
  const formData = new FormData();
  formData.append('file', file);
  const {data} = await axios.post<NearCastingScenarioCompany>(
    `${config.APP_URI}/near_casting/companies/${ncCompanyId}/import_transactions`,
    formData,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );
  return data;
};
