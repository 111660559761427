import React, {
  ChangeEvent,
  KeyboardEvent,
  ReactNode,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import styles from './TagInput.module.scss';
import {
  SearchDirection,
  StyleVariant,
  TagInputProps,
  TagItem,
} from '../../../types';
import {DropdownList, Icon, Tag} from '../index';
import {useOnOutsideClick} from '../../../hooks';
import classnames from 'classnames';
import {useTranslation} from 'react-i18next';
import {searchByValue} from '../../../utils';

const TagInput: React.FC<TagInputProps> = ({
  value,
  onChange,
  onInputChange,
  options,
  label,
  placeholder = '',
  variant = StyleVariant.Primary,
  icon,
  className,
  disabled = false,
  optionChildren,
}) => {
  const {t} = useTranslation();
  const [inputState, setInputState] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const currentOptions = useMemo<(TagItem | TagItem[])[] | undefined>(() => {
    if (!options || !inputState || !isFocused) return;
    const searchResult: (TagItem | TagItem[])[] = searchByValue<TagItem>(
      options,
      inputState,
      {
        searchTarget: 'label',
        excludeTarget: value,
        sortDirection: SearchDirection.Asc,
      }
    );
    return searchResult ?? undefined;
  }, [options, inputState, isFocused, value]);

  const handleClickInner = () => {
    if (disabled) return;
    setIsFocused(true);
    inputRef.current?.focus();
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIsFocused(true);
    setInputState(event.target.value);
    onInputChange && onInputChange(event);
  };

  const handleClickOption = (tag: TagItem) => {
    onChange([...value, tag]);
    setInputState('');
    inputRef.current?.focus();
  };

  const handleKeyUpOption = (event: KeyboardEvent<HTMLButtonElement>) => {
    if (event.key === 'ArrowDown') {
      (event.currentTarget.nextSibling as HTMLButtonElement)?.focus();
    }
    if (event.key === 'ArrowUp') {
      (event.currentTarget.previousSibling as HTMLButtonElement)?.focus();
    }
  };

  const handleKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
    if (
      event.key === 'Backspace' &&
      !inputState.length &&
      !value[value.length - 1]?.mandatory
    ) {
      onChange(
        value.filter(tag => tag.label !== value[value.length - 1].label)
      );
      setInputState(value[value.length - 1]?.label || '');
    }
    if (
      event.key === 'ArrowDown' &&
      currentOptions?.length &&
      dropdownRef?.current?.firstElementChild
    ) {
      (
        dropdownRef.current.firstElementChild
          .firstElementChild as HTMLButtonElement
      )?.focus();
    }
    if ((event.key === 'Enter' || event.key === ',') && inputState.length) {
      onChange([
        ...value,
        ...inputState
          .split(',')
          .filter(label => label.trim())
          .map(label => {
            const existingTag = options
              ?.flatMap(innerArray => innerArray)
              .find(tag => tag.label === label.trim());
            return {
              id: existingTag?.id || null,
              mandatory: false,
              label: label.trim(),
            };
          }),
      ]);
      setInputState('');
    }
  };

  const handleRemoveTag = (tag: string) => {
    onChange(value.filter(item => tag !== item.label));
    inputRef.current?.focus();
  };

  const getOption = useCallback(
    (option: string, inputValue: string): ReactNode => {
      const bolded = option.match(new RegExp(inputValue, 'i')) || [];
      const rest = option.replace(new RegExp(inputValue, 'i'), '');
      return (
        <span>
          <b>{bolded[0]}</b>
          {rest}
        </span>
      );
    },
    []
  );

  useOnOutsideClick(wrapperRef, () => {
    setIsFocused(false);
  });

  const renderOption = useCallback(
    (option: TagItem, idx: number) => (
      <button
        onKeyUp={handleKeyUpOption}
        onClick={() => handleClickOption(option)}
        key={idx}
        className={classnames(
          styles.dropdownItem,
          option.visible === false && styles.notVisible
        )}
      >
        {optionChildren
          ? typeof optionChildren === 'function'
            ? optionChildren(option)
            : optionChildren
          : getOption(option.label, inputState)}
      </button>
    ),
    [inputState]
  );

  return (
    <div className={classnames(styles.wrapper, className)}>
      {label && <label className={styles.inputLabel}>{t(label)}</label>}
      <div
        className={classnames(styles.tagWrapper, isFocused && styles.focused)}
        ref={wrapperRef}
      >
        <div
          className={classnames(styles.inner, !!icon && styles.inner__withIcon)}
          onClick={handleClickInner}
        >
          {icon && <Icon name={icon} className={styles.icon} />}
          {value.map((tag, idx) => (
            <Tag
              {...tag}
              key={idx}
              variant={variant}
              onRemove={
                !disabled && !tag.mandatory
                  ? () => handleRemoveTag(tag.label)
                  : undefined
              }
            />
          ))}
          <input
            ref={inputRef}
            type="text"
            value={inputState}
            placeholder={value.length ? '' : t(placeholder)}
            onChange={handleChange}
            onKeyUp={handleKeyUp}
            className={classnames(styles.input)}
            disabled={disabled}
          />
        </div>
        {!!currentOptions?.length && (
          <DropdownList ref={dropdownRef}>
            {currentOptions.map((option, idx) =>
              Array.isArray(option) ? (
                <div className={styles.group} key={idx}>
                  {option.map((option, idx) => renderOption(option, idx))}
                </div>
              ) : (
                renderOption(option, idx)
              )
            )}
          </DropdownList>
        )}
      </div>
    </div>
  );
};

export default TagInput;
