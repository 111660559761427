import React from 'react';
import styles from './ListItemsCell.module.scss';

const ListItemsCell: React.FC<{items: string[]}> = ({
  items = [],
}): JSX.Element => {
  return (
    <div className={styles.wrapper}>
      {items.map((item, i) => (
        <div key={`${item}-${i}`} className={styles.item}>
          {item}
        </div>
      ))}
    </div>
  );
};

export default ListItemsCell;
