import axios from 'axios';
import config from '../config';
import {
  AsAt,
  CapitalData,
  FundPerformanceData,
  PortfolioPerformanceDataProps,
  ScenarioPortfolioCompanyData,
  ScenarioReportHeaderData,
  ScenarioReportPortfolioSummaryData,
} from '../types';

export interface ScenarioPortfolioResponse {
  companies: ScenarioPortfolioCompanyData[];
  headerData: ScenarioReportHeaderData[];
  capitalData: CapitalData;
  reportedCapitalData: CapitalData;
  reportedAsAt: AsAt;
  reportedFundPerformanceData: FundPerformanceData;
  reportedPortfolioPerformanceData: PortfolioPerformanceDataProps;
}

export interface ScenarioPortfolioCompanyPerformanceResponse {
  reportedData: ScenarioReportPortfolioSummaryData;
  nearcastingData: ScenarioReportPortfolioSummaryData;
}

export const getScenarioPortfolioData = async (token: string, id: string) => {
  const {data} = await axios.get<ScenarioPortfolioResponse>(
    `${config.APP_URI}/near_casting/scenario/${id}/report`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );
  return data;
};

export const getPortfolioCompanyPerformanceData = async (
  token: string,
  id: string
) => {
  const {data} = await axios.get<ScenarioPortfolioResponse>(
    `${config.APP_URI}/near_casting/companies/${id}/performance`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );
  return data;
};
