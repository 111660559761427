import React from 'react';
import styles from './BubbleChartWidget.module.scss';
import {BubbleChartData, Func} from '../../../types';
import {useChartAnimated} from '../../../hooks';
import {
  CartesianGrid,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  XAxis,
  YAxis,
  ZAxis,
  Tooltip,
  Cell,
} from 'recharts';
import CustomYAxisTick from '../custom-y-axis-tick/CustomYAxisTick';
import BubbleChartTooltip from './components/BubbleChartTooltip';

interface BubbleChartWidgetProps<T> {
  timeSeries: Array<BubbleChartData<T>>;
  yAxisLabel?: string;
  yAxisFilterLabel?: string;
  xAxisLabel?: string;
  xAxisFilterLabel?: string;
  yAxisFormat?: string;
  xAxisFormat?: string;
  className?: string;
  zAxisLabel?: string;
  zAxisFilterLabel?: string;
  zAxisFormat?: string;
  zAxisDomain?: number[];
  onBubbleClick?: Func<[T], void>;
}

const BubbleChartWidget = <T extends object>({
  timeSeries,
  yAxisFormat,
  xAxisFormat,
  onBubbleClick,
  yAxisLabel,
  yAxisFilterLabel,
  xAxisLabel,
  xAxisFilterLabel,
  zAxisLabel,
  zAxisFilterLabel,
  zAxisFormat,
  zAxisDomain,
}: BubbleChartWidgetProps<T>) => {
  const {ref, isAnimated} = useChartAnimated();

  const getBubbleShapeColor = (
    x?: number | null,
    y?: number | null
  ): string => {
    const positiveBubbleColor = 'rgba(153, 0, 255, 0.3)';
    const negativeBubbleColor = 'rgba(255, 0, 0, 0.3)';
    if (!x || !y) return positiveBubbleColor;

    return x < 0 || y < 0 ? negativeBubbleColor : positiveBubbleColor;
  };

  return (
    <div ref={ref} className={styles.wrapper}>
      <ResponsiveContainer id="bar-chart-widget" width="100%" height={550}>
        <ScatterChart margin={{left: 60, right: 30, bottom: 100, top: 40}}>
          <rect
            y={435}
            width="100%"
            height={52}
            fill={`rgb(var(--colors-gray-8))`}
          />
          <CartesianGrid
            vertical={false}
            strokeDasharray="2"
            stroke={`rgb(var(--colors-gray-6))`}
            width={1920}
            x={0}
          />
          <XAxis
            type="number"
            dataKey="x"
            axisLine={false}
            tickLine={false}
            label={{
              value: xAxisLabel,
              position: 'top',
              dx: 0,
              dy: 60,
              fontSize: '10px',
              fontWeight: '500',
              fill: `rgb(var( --colors-gray-3))`,
            }}
            tick={
              <CustomYAxisTick
                showAxis={true}
                xOffset={-20}
                yOffset={25}
                fill="gray-3"
                fontSize={12}
                fontWeight={500}
                format={xAxisFormat}
              />
            }
          />
          <YAxis
            type="number"
            dataKey="y"
            axisLine={false}
            tickLine={false}
            width={1}
            label={{
              value: yAxisLabel,
              position: 'insideTopLeft',
              dx: -50,
              dy: -30,
              fontSize: '10px',
              fontWeight: '500',
              fill: `rgb(var( --colors-gray-3))`,
            }}
            tick={
              <CustomYAxisTick
                showAxis={true}
                xOffset={-20}
                yOffset={4}
                fill="gray-3"
                fontSize={12}
                fontWeight={500}
                format={yAxisFormat}
              />
            }
          />
          <ZAxis
            domain={zAxisDomain || ['auto', 'auto']}
            range={[100, 3000]}
            dataKey="z"
            type="number"
          />
          {isAnimated && (
            <Scatter
              data={timeSeries}
              isAnimationActive
              shape="circle"
              onClick={props =>
                onBubbleClick && onBubbleClick(props.additionalData)
              }
            >
              {timeSeries.map(bubbleChartItem => (
                <Cell
                  key={`scatter-cell-${bubbleChartItem.id}`}
                  fill={getBubbleShapeColor(
                    bubbleChartItem.x,
                    bubbleChartItem.y
                  )}
                />
              ))}
            </Scatter>
          )}
          <Tooltip
            content={
              <BubbleChartTooltip
                xAxisLabel={xAxisLabel}
                xAxisFilterLabel={xAxisFilterLabel}
                xAxisFormat={xAxisFormat}
                yAxisLabel={yAxisLabel}
                yAxisFilterLabel={yAxisFilterLabel}
                yAxisFormat={yAxisFormat}
                zAxisLabel={zAxisLabel}
                zAxisFilterLabel={zAxisFilterLabel}
                zAxisFormat={zAxisFormat}
                zAxisDomain={zAxisDomain}
              />
            }
          />
        </ScatterChart>
      </ResponsiveContainer>
    </div>
  );
};

export default BubbleChartWidget;
