import {Button, Input, Modal} from '../../global';
import React from 'react';
import {
  ButtonStyle,
  ReportingGroupTemplateFormProps,
  ReportingGroupTemplateFormState,
  StyleVariant,
} from '../../../types';
import styles from './ReportingGroupTemplateForm.module.scss';
import {useFormState, useIsSubmitDisabled} from '../../../hooks';
import {addNewReportingGroupTemplateItemFormSchema} from '../../../constants';
import {useTranslation} from 'react-i18next';

const ReportingGroupTemplateForm: React.FC<ReportingGroupTemplateFormProps> = ({
  isOpen,
  onClose,
  onSubmit,
  onDelete,
  initialFormState,
  addNewTemplateInProgress,
  isDeleteInProgress = false,
  error,
  formError,
  resetErrors,
}) => {
  const {t} = useTranslation();
  const {formState, isFormStateChanged, validate, errors, handleChange} =
    useFormState<ReportingGroupTemplateFormState>(
      initialFormState,
      addNewReportingGroupTemplateItemFormSchema
    );

  const {isSubmitDisabled} = useIsSubmitDisabled(
    formError,
    errors,
    error,
    isFormStateChanged
  );
  const handleSubmit = () => {
    onSubmit(formState);
  };

  const handleDelete = () => {
    if (!formState.id) return;
    onDelete(formState.id);
  };

  const handleOnChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    if (error || formError) {
      resetErrors();
    }
    handleChange(e);
  };

  return (
    <Modal
      title={t(
        formState.id
          ? 'Admin.DataCollection.ReportingGroupTemplateForm.Title.Edit'
          : 'Admin.DataCollection.ReportingGroupTemplateForm.Title.AddBlank'
      )}
      subTitle={formState?.formSubtitle}
      isOpen={isOpen}
      onClose={onClose}
      variant={StyleVariant.Secondary}
      footerChildren={
        <div className={styles.buttons}>
          <Button
            text={t(
              formState.id
                ? 'Global.Save'
                : 'Admin.DataCollection.ReportingGroupTemplateForm.Title.Add'
            )}
            onClick={handleSubmit}
            loading={addNewTemplateInProgress}
            disabled={
              formState.name.trim().length < 1 ||
              formState.name.trim().length > 256 ||
              isDeleteInProgress ||
              isSubmitDisabled
            }
          />
          <Button
            styleType={ButtonStyle.Secondary}
            text={t('Global.Cancel')}
            onClick={onClose}
            disabled={addNewTemplateInProgress || isDeleteInProgress}
          />
          {formState.id && (
            <Button
              className={styles.deleteButton}
              styleType={ButtonStyle.Warning}
              text={t('Global.Delete')}
              onClick={handleDelete}
              loading={isDeleteInProgress}
              disabled={addNewTemplateInProgress}
            />
          )}
        </div>
      }
    >
      <div className={styles.wrapper}>
        {error ? <div className={styles.errorMessage}>{error}</div> : null}
        <div>
          <Input
            label={
              formState.id
                ? t(
                    'Admin.DataCollection.ReportingGroupTemplateForm.InputLabel.Template'
                  )
                : t(
                    'Admin.DataCollection.ReportingGroupTemplateForm.Title.AddBlank'
                  )
            }
            placeholder={t(
              'Admin.DataCollection.ReportingGroupTemplateForm.InputPlaceholder.Eg'
            )}
            errorMessage={t(errors.name.message) || formError?.name || ''}
            validate={validate}
            value={formState.name}
            onChange={handleOnChange}
            name={'name'}
            variant={StyleVariant.Secondary}
            disabled={addNewTemplateInProgress}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ReportingGroupTemplateForm;
