import React from 'react';
import classNames from 'classnames';
import {StyleVariant} from '../../../../../types/index';
import {useTooltip} from '../../../../../hooks';
import {Position} from '../../../../../context/TooltipContext';
import styles from './CellLabel.module.scss';
import {formatToHyphenatedString} from '../../../../../utils/strings';

interface CellLabelProps {
  id?: string;
  label?: string;
  tooltip?: string;
  variant?: StyleVariant;
}

const CellLabel: React.FC<CellLabelProps> = ({
  label,
  tooltip,
  variant = StyleVariant.Primary,
  id,
}) => {
  const {showTooltip, hideTooltip} = useTooltip();

  const handleMouseEnter = (content: string) => (e: React.MouseEvent) => {
    showTooltip({
      content,
      position: Position.Top,
      target: e.currentTarget as HTMLElement,
    });
  };

  return (
    <div
      className={classNames(styles.wrapper, styles[variant])}
      data-test={`${id}-${formatToHyphenatedString(label ?? '')}-label`}
    >
      {label}
      {tooltip && (
        <div
          className={styles.hint}
          onMouseEnter={handleMouseEnter(tooltip)}
          onMouseLeave={hideTooltip}
        >
          ?
        </div>
      )}
    </div>
  );
};

export default CellLabel;
