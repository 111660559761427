import React, {useRef} from 'react';
import {useTooltip} from '../../../../../hooks';
import {Position} from '../../../../../context/TooltipContext';
import styles from './StatusTooltipInfo.module.scss';
import {ActivityStatus, Icon} from '../../../index';
import {ActivityStatuses} from '../../../../../types';
import {useTranslation} from 'react-i18next';

const TooltipContent: React.FC = () => {
  const {t} = useTranslation();

  const statuses = [
    {
      label: t('Global.Submitted'),
      status: ActivityStatuses.Submitted,
    },
    {
      label: t('Global.Overdue'),
      status: ActivityStatuses.Overdue,
    },
    {
      label: t(
        'DataCollection.DataCollectionWorkflowTable.HeaderTooltip.DraftOption'
      ),
      status: ActivityStatuses.Pending,
    },
  ];
  return (
    <>
      <p>
        {t('DataCollection.DataCollectionWorkflowTable.HeaderTooltip.Title')}
      </p>
      <span className={styles.line} />
      {statuses.map((status, index) => (
        <div className={styles.status} key={index}>
          <ActivityStatus activity={status.status} />
          <p>{status.label}</p>
        </div>
      ))}
    </>
  );
};

const StatusTooltipInfo: React.FC = () => {
  const {showTooltip, hideTooltip} = useTooltip();
  const wrapperRef = useRef<HTMLDivElement>(null);

  const handleMouseLeave = () => {
    hideTooltip();
  };

  const handleMouseEnter = (e: React.MouseEvent) => {
    showTooltip({
      content: <TooltipContent />,
      position: Position.Top,
      target: e.currentTarget as HTMLElement,
      className: styles.tooltip,
      hasArrow: false,
    });
  };

  return (
    <div
      ref={wrapperRef}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={styles.wrapper}
    >
      <Icon className={styles.icon} name="info" />
    </div>
  );
};

export default StatusTooltipInfo;
