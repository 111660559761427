export interface DocumentLang {
  locale: string;
  camelCaseLocale: string;
}

export enum Locales {
  enGB = 'en-GB',
  enUS = 'en-US',
}

export enum DaysInAWeek {
  Sunday = 0,
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
}
