import {createAction, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {ScenarioReportFundPerformanceData} from '../types/scenarioOnePageReport';
import {CapitalData} from '../types';

export const getScenarioOnePageReportOverviewDataAction = createAction(
  'scenario-one-page-report-overview/get-scenario-overview-data',
  (payload: {id: string}) => {
    return {
      payload,
    };
  }
);

interface ScenarioOnePageReportOverviewData {
  performance: {
    reportedData: ScenarioReportFundPerformanceData | null;
    nearCastingData: ScenarioReportFundPerformanceData | null;
  } | null;
  capital: {
    reportedData: CapitalData | null;
    nearCastingData: CapitalData | null;
  } | null;
  isLoading: boolean;
}

const initialState: ScenarioOnePageReportOverviewData = {
  performance: null,
  capital: null,
  isLoading: false,
};

const scenarioOnePageReportOverviewSlice = createSlice({
  name: 'scenario-one-page-report-performance',
  initialState,
  reducers: {
    setScenarioOnePageReportPerformanceNearCastingData(
      state: ScenarioOnePageReportOverviewData,
      action: PayloadAction<{
        nearCastingData: ScenarioReportFundPerformanceData | null;
      }>
    ) {
      state.performance = {
        ...state.performance,
        reportedData: state.performance?.reportedData || null,
        nearCastingData: action.payload.nearCastingData,
      };
    },
    setScenarioOnePageReportPerformanceReportedData(
      state: ScenarioOnePageReportOverviewData,
      action: PayloadAction<{
        reportedData: ScenarioReportFundPerformanceData | null;
      }>
    ) {
      state.performance = {
        ...state.performance,
        nearCastingData: state.performance?.nearCastingData || null,
        reportedData: action.payload.reportedData,
      };
    },
    setScenarioOnePageReportCapitalData(
      state: ScenarioOnePageReportOverviewData,
      action: PayloadAction<{
        nearCastingData: CapitalData | null;
        reportedData: CapitalData | null;
      }>
    ) {
      state.capital = {
        ...state.capital,
        ...action.payload,
      };
    },
    clearScenarioOnePageReportOverviewData(
      state: ScenarioOnePageReportOverviewData
    ) {
      state.performance = null;
      state.capital = null;
    },
    setLoading(state: ScenarioOnePageReportOverviewData) {
      state.isLoading = !state.isLoading;
    },
  },
});

export const {
  setScenarioOnePageReportPerformanceNearCastingData,
  setScenarioOnePageReportPerformanceReportedData,
  setScenarioOnePageReportCapitalData,
  setLoading,
  clearScenarioOnePageReportOverviewData,
} = scenarioOnePageReportOverviewSlice.actions;

export default scenarioOnePageReportOverviewSlice.reducer;
