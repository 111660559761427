import React, {ReactElement} from 'react';
import styles from './QuestionnaireGroupHeaderRow.module.scss';
import classnames from 'classnames';
import {useTranslation} from 'react-i18next';

type QuestionnaireGroupHeaderRowProps = {
  name: string;
  info: ReactElement;
};

const QuestionnaireGroupHeaderRow = ({
  name,
  info,
}: QuestionnaireGroupHeaderRowProps) => {
  const {t} = useTranslation();
  return (
    <div className={classnames(styles.row)}>
      <div className={classnames(styles.groupHeaderRowLeft)}>
        <p className={styles.groupHeader}>
          {name}
          <span className={styles.groupHeaderGroup}> {t('Global.Group')}</span>
        </p>
      </div>
      <div className={classnames(styles.groupHeaderRowRigh)}>
        {info ? info : null}
      </div>
    </div>
  );
};

export default QuestionnaireGroupHeaderRow;
