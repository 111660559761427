import {createAction, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {FilePreviewData} from '../types';

export const getFilePreviewDataAction = createAction(
  'file-preview/get-file-preview-data',
  (id: string) => {
    return {
      payload: {
        id,
      },
    };
  }
);

interface FilePreviewSlice {
  data: FilePreviewData | null;
  isOpen: boolean;
  isRequestPending: boolean;
}

const initialState: FilePreviewSlice = {
  data: null,
  isOpen: false,
  isRequestPending: false,
};

const filePreviewSlice = createSlice({
  name: 'file-preview',
  initialState,
  reducers: {
    setFilePreviewData(
      state: FilePreviewSlice,
      action: PayloadAction<FilePreviewData>
    ) {
      state.data = action.payload;
    },
    setFilePreviewIsOpen(
      state: FilePreviewSlice,
      action: PayloadAction<boolean>
    ) {
      state.isOpen = action.payload;
    },
    setFilePreviewIsRequestPending(
      state: FilePreviewSlice,
      action: PayloadAction<boolean>
    ) {
      state.isRequestPending = action.payload;
    },
  },
});

export default filePreviewSlice.reducer;

export const {
  setFilePreviewData,
  setFilePreviewIsOpen,
  setFilePreviewIsRequestPending,
} = filePreviewSlice.actions;
