import axios from 'axios';
import config from '../config';
import {
  ClientAdminWorkflowConfiguration,
  ClientAdminWorkflowConfigurationOptions,
  CreatePCWorkflowConfigPayload,
} from '../types';

export const getClientAdminWorkflowConfig = async (token: string) => {
  const {data} = await axios.get<ClientAdminWorkflowConfiguration[]>(
    `${config.APP_URI}/admin/workflow-configurations`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );
  return data[0];
};

export const getClientAdminWorkflowConfigEditOptions = async (
  token: string,
  id: string
) => {
  const {data} = await axios.get<ClientAdminWorkflowConfigurationOptions>(
    `${config.APP_URI}/admin/workflow-configurations/get-options/${id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );
  return data;
};

export const addClientAdminWorkflowConfig = async (
  token: string,
  payload: CreatePCWorkflowConfigPayload
) => {
  return await axios.post(`${config.APP_URI}/workflows`, payload, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
  });
};

export const updateClientAdminWorkflowConfig = async (
  token: string,
  workflowSettings: CreatePCWorkflowConfigPayload,
  workflowId: string
) => {
  return await axios.put(
    `${config.APP_URI}/workflows/${workflowId}`,
    workflowSettings,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );
};
