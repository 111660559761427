import {createAction, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {
  CreateNearCastingScenarioCompany,
  FormStatus,
  NearCastingScenario,
  NearCastingScenarioCompany,
  NearCastingScenarioCompanyFormData,
  UploadSingleTransactionsFile,
  NearCastingScenarioCompanyTransaction,
  NearCastingScenarioCompanyTransactionPayload,
} from '../types';

export const getScenarioDetailsValuationDataAction = createAction(
  'scenario-details-valuation/get-scenario-valuation-data',
  (
    companyId: string,
    companyType: string,
    isFundCurrency: boolean,
    investmentGroupId: string
  ) => {
    return {
      payload: {
        companyId,
        companyType,
        isFundCurrency,
        investmentGroupId,
      },
    };
  }
);

export const savePortfolioCompanyNearCastingTransactionAction = createAction(
  'portfolio-company/save-near-casting-transaction',
  (payload: {transaction: NearCastingScenarioCompanyTransactionPayload}) => {
    return {
      payload,
    };
  }
);

export const updatePortfolioCompanyNearCastingTransactionAction = createAction(
  'portfolio-company/update-near-casting-transaction',
  (payload: {transaction: NearCastingScenarioCompanyTransactionPayload}) => {
    return {
      payload,
    };
  }
);

export const deletePortfolioCompanyNearCastingTransactionAction = createAction(
  'portfolio-company/delete-near-casting-transaction',
  (payload: {id: string; companyId: string}) => {
    return {
      payload,
    };
  }
);

export const getPortfolioCompanyDataAction = createAction(
  'portfolio-company/get',
  (payload: {id: string}) => {
    return {
      payload,
    };
  }
);

export const postScenarioDetailsPortfolioCompany = createAction(
  'scenario-details-valuation/post-portfolio-company',
  (payload: CreateNearCastingScenarioCompany) => ({payload})
);

export const deleteScenarioDetailsPortfolioCompany = createAction(
  'scenario-details-valuation/delete-portfolio-company',
  (payload: {id: string}) => ({payload})
);

export const putScenarioDetailsPortfolioCompany = createAction(
  'scenario-details-valuation/put-portfolio-company',
  (
    data:
      | NearCastingScenarioCompanyFormData
      | {[field: string]: string | number},
    id: string,
    tableIndex: number,
    field?: string,
    value?: string
  ) => {
    return {
      payload: {
        data,
        id,
        field,
        tableIndex,
        value,
      },
    };
  }
);

export const importPortfolioCompanyTransactions = createAction(
  'scenario-details-valuation/import-portfolio-company-transactions',
  (ncCompanyId: string, file: UploadSingleTransactionsFile) => {
    return {
      payload: {
        ncCompanyId,
        file,
      },
    };
  }
);

export const importScenarioTransactionsDownloadTemplateAction = createAction(
  'scenario-details-valuation/import-scenario-download-template-action',
  (templateId: string) => {
    return {
      payload: {
        templateId,
      },
    };
  }
);

export const saveAndCalculateValuation = createAction(
  'scenario-details-valuation/save-and-calculate',
  (fundId: string, id: string) => {
    return {
      payload: {
        fundId,
        id,
      },
    };
  }
);

interface ScenarioDetailsValuationData {
  data: NearCastingScenario | null;
  isLoading: boolean;
  transactionFormStatus: FormStatus;
}

const initialState: ScenarioDetailsValuationData = {
  data: null,
  isLoading: false,
  transactionFormStatus: FormStatus.Ready,
};

const scenarioDetailsValuationSlice = createSlice({
  name: 'scenario-details-valuation',
  initialState,
  reducers: {
    setScenarioValuationData(
      state: ScenarioDetailsValuationData,
      action: PayloadAction<NearCastingScenario>
    ) {
      state.data = action.payload;
    },
    clearScenarioDetailsValuationData(state: ScenarioDetailsValuationData) {
      state.data = null;
    },
    setLoading(state: ScenarioDetailsValuationData) {
      state.isLoading = !state.isLoading;
    },
    setTransactionFormStatus(
      state: ScenarioDetailsValuationData,
      action: PayloadAction<FormStatus>
    ) {
      state.transactionFormStatus = action.payload;
    },
    editTableRowData(
      state: ScenarioDetailsValuationData,
      action: PayloadAction<{
        data?: NearCastingScenarioCompanyFormData;
        field?: string;
        tableIndex: number;
        value?: string;
      }>
    ) {
      const updatedState = state.data?.companies?.map((elem, index: number) => {
        if (index !== action.payload.tableIndex) {
          return elem;
        }

        return {
          ...elem,
          ...(!!action.payload.field
            ? {[action.payload.field]: action.payload.value}
            : {...action.payload.data}),
        };
      });
      state.data = {
        ...state.data,
        companies: updatedState,
      } as NearCastingScenario;
    },
    addPortfolioCompanyNearCastingTransaction(
      state: ScenarioDetailsValuationData,
      action: PayloadAction<NearCastingScenarioCompanyTransaction>
    ) {
      const companyId = action.payload?.ncCompany?.id;
      const updatedState = state.data?.companies.map(company => {
        if (company.id !== companyId) {
          return company;
        }

        return {
          ...company,
          transactions: [action.payload, ...company.transactions],
        };
      });
      state.data = {
        ...state.data,
        companies: updatedState,
      } as NearCastingScenario;
    },
    addMultiplePortfolioCompanyNearCastingTransactions(
      state: ScenarioDetailsValuationData,
      action: PayloadAction<NearCastingScenarioCompany>
    ) {
      const companyId = action.payload.id;
      const updatedState = state.data?.companies.map(company => {
        if (company.id !== companyId) {
          return company;
        }

        return {
          ...company,
          cost: action.payload.cost,
          transactions: action.payload.transactions,
        };
      });
      state.data = {
        ...state.data,
        companies: updatedState,
      } as NearCastingScenario;
    },
    editPortfolioCompanyNearCastingTransaction(
      state: ScenarioDetailsValuationData,
      action: PayloadAction<NearCastingScenarioCompanyTransaction>
    ) {
      const companyId = action.payload?.ncCompany?.id;
      const transactionId = action.payload?.id;
      const updatedState = state.data?.companies.map(company => {
        if (company.id !== companyId) {
          return company;
        }

        const index = company.transactions.findIndex(
          transaction => transaction.id === transactionId
        );

        return {
          ...company,
          transactions: [
            ...company.transactions.slice(0, index),
            action.payload,
            ...company.transactions.slice(index + 1),
          ],
        };
      });
      state.data = {
        ...state.data,
        companies: updatedState,
      } as NearCastingScenario;
    },
    removePortfolioCompanyNearCastingTransaction(
      state: ScenarioDetailsValuationData,
      action: PayloadAction<{
        id: string;
        companyId: string;
      }>
    ) {
      const companyId = action.payload.companyId;
      const transactionId = action.payload?.id;
      const updatedState = state.data?.companies.map(company => {
        if (company.id !== companyId) {
          return company;
        }

        const index = company.transactions.findIndex(
          transaction => transaction.id === transactionId
        );

        return {
          ...company,
          transactions: [
            ...company.transactions.slice(0, index),
            ...company.transactions.slice(index + 1),
          ],
        };
      });
      state.data = {
        ...state.data,
        companies: updatedState,
      } as NearCastingScenario;
    },
    updateNearCastingPortfolioCompanyData(
      state: ScenarioDetailsValuationData,
      action: PayloadAction<NearCastingScenarioCompany>
    ) {
      const companyId = action.payload?.id;
      const updatedState = state.data?.companies.map(company => {
        if (company.id !== companyId) {
          return company;
        }

        return {
          ...company,
          cost: action.payload.cost,
        };
      });
      state.data = {
        ...state.data,
        companies: updatedState,
      } as NearCastingScenario;
    },
    removeNearCastingPortfolioCompanyData(
      state: ScenarioDetailsValuationData,
      action: PayloadAction<{id: string}>
    ) {
      const companyId = action.payload?.id;
      const updatedState = state.data?.companies.filter(
        company => company.id !== companyId
      );
      state.data = {
        ...state.data,
        companies: updatedState,
      } as NearCastingScenario;
    },
    setNewPortfolioCompany(
      state: ScenarioDetailsValuationData,
      action: PayloadAction<NearCastingScenarioCompany>
    ) {
      const updatedState = {
        ...state.data,
        companies: [
          {...action.payload, isCasting: true},
          ...(state.data?.companies || []),
        ],
      };
      state.data = updatedState as NearCastingScenario;
    },
  },
});

export const {
  setScenarioValuationData,
  setLoading,
  setTransactionFormStatus,
  clearScenarioDetailsValuationData,
  editTableRowData,
  addPortfolioCompanyNearCastingTransaction,
  editPortfolioCompanyNearCastingTransaction,
  removePortfolioCompanyNearCastingTransaction,
  addMultiplePortfolioCompanyNearCastingTransactions,
  setNewPortfolioCompany,
  updateNearCastingPortfolioCompanyData,
  removeNearCastingPortfolioCompanyData,
} = scenarioDetailsValuationSlice.actions;

export default scenarioDetailsValuationSlice.reducer;
