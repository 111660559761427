import React from 'react';
import {SkeletonLoader} from '../../global';
import styles from './CompanyWidget.module.scss';

const CompanyWidgetSkeleton: React.FC = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.top}>
        <div className={styles.topRow}>
          <div className={styles.topLeft} style={{width: '100%'}}>
            <SkeletonLoader isCircle width={24} className={styles.logo} />
            <div className={styles.companyName}>
              <SkeletonLoader width={'70%'} />
            </div>
            <div className={styles.fundInfo}>
              <SkeletonLoader width={'50%'} />
            </div>
            <div className={styles.fundInfo}>
              <SkeletonLoader width={'50%'} />
            </div>
          </div>
        </div>
        <div className={styles.topRow}>
          <div className={styles.topLeft} style={{width: '100%'}}>
            <div className={styles.specIcons}>
              <div style={{marginRight: 8, lineHeight: 0}}>
                <SkeletonLoader isCircle width={24} />
              </div>
              <div style={{marginRight: 8, lineHeight: 0}}>
                <SkeletonLoader isCircle width={24} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.bottom}>
        <div className={styles.bottomCell} style={{flex: '1 1 33%'}}>
          <div className={styles.bottomTitle}>
            <SkeletonLoader width={'50%'} />
          </div>
          <div className={styles.bottomValue}>
            <SkeletonLoader width={'80%'} />
          </div>
        </div>
        <div className={styles.bottomCell} style={{flex: '1 1 33%'}}>
          <div className={styles.bottomTitle}>
            <SkeletonLoader width={'50%'} />
          </div>
          <div className={styles.bottomValue}>
            <SkeletonLoader width={'80%'} />
          </div>
        </div>
        <div className={styles.bottomCell} style={{flex: '1 1 33%'}}>
          <div className={styles.bottomTitle}>
            <SkeletonLoader width={'50%'} />
          </div>
          <div className={styles.bottomValue}>
            <SkeletonLoader width={'80%'} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyWidgetSkeleton;
