import axios from 'axios';
import config from '../config';

export const getUploadDocuments = async (
  token: string,
  fund: string | null,
  portfolioCompany: string | null,
  sections: string[] | null,
  transactions: string[] | null,
  columns: string[] | null
) => {
  const fundQuery = `${fund ? `fund=${fund}&` : ''}`;
  const portfolioCompanyQuery = `${
    portfolioCompany ? `portfolio_company=${portfolioCompany}&` : ''
  }`;
  const sectionsQuery = `${sections ? `sections=${sections}&` : ''}`;
  const transactionsQuery = `${
    transactions ? `transactions=${transactions}` : ''
  }`;
  const reportingPeriodsQuery = `${columns ? `columns=${columns}` : ''}`;

  const queryParams = `${fundQuery}${portfolioCompanyQuery}${sectionsQuery}${transactionsQuery}${reportingPeriodsQuery}`;

  const {data} = await axios.get(
    `${config.APP_URI}/document-modal?${queryParams}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );
  return data;
};
