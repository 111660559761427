import axios, {AxiosError} from 'axios';
import i18n from '../i18n';
export const scenarioImportErrorResolver = (err: any) => {
  if (!axios.isAxiosError(err) || !err.response) {
    return {
      errorCode: null,
      errorMessage: i18n.t('Global.UnknownError'),
    };
  }

  const errorCode = err.code;
  let errorMessage: string;

  switch (errorCode) {
    case AxiosError.ERR_BAD_REQUEST:
      errorMessage = i18n.t('ScenarioImportErrorResolver.ErrorMessage.Parsing');
      break;
    default:
      errorMessage = i18n.t('ScenarioImportErrorResolver.ErrorMessage.Server');
      break;
  }

  return {
    errorCode,
    errorMessage,
  };
};
