import React from 'react';
import styles from './NameCell.module.scss';
import classnames from 'classnames';
import {useHistory} from 'react-router-dom';
import {Size} from '../../../../../types';
import {EntityLogo} from '../../../../global';

type NameCellProps = {
  name: string;
  color?: string;
  icon?: string;
  iconUrl?: string | null;
  expanded: boolean;
  linkTo?: string;
  skipExtend?: boolean;
  isActive?: boolean;
  className?: string;
  emptyValue?: JSX.Element | string;
  bigIcon?: boolean;
};

// @TODO InvestmentTypeCell component will be refactored to support 3 variants (line + string | icon + string | only string)
const NameCell = ({
  name,
  color,
  icon,
  iconUrl,
  expanded,
  linkTo,
  skipExtend,
  isActive,
  className,
  emptyValue = ' ',
  bigIcon,
}: NameCellProps): JSX.Element => {
  const history = useHistory();

  return (
    <div className={classnames(styles.wrapper)}>
      {color && (
        <span
          className={classnames(name && styles.line, {
            [styles.line__expanded]: expanded && !skipExtend,
            [styles.line__grayscaled]: !isActive,
          })}
          style={{
            backgroundColor: `rgb(var(--colors-${color}))`,
          }}
        ></span>
      )}
      {icon ? (
        <EntityLogo
          name={name}
          color={icon}
          url={iconUrl ?? null}
          size={expanded ? Size.Medium : Size.Small}
          className={classnames(styles.icon, {
            [styles.icon__expanded]: expanded && !skipExtend,
          })}
        />
      ) : null}
      <span
        className={classnames(className ? className : styles.name, {
          [styles.name_expanded]: expanded && !skipExtend,
        })}
      >
        {linkTo ? (
          <span
            className={classnames(styles.link)}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              history.push(linkTo);
            }}
          >
            {name}
          </span>
        ) : name ? (
          name
        ) : (
          emptyValue
        )}
      </span>
    </div>
  );
};

export default NameCell;
