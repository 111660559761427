import {all, call, put, takeEvery} from 'redux-saga/effects';
import {acquireAuthResult, msalInstance} from '../..';
import {PayloadAction} from '@reduxjs/toolkit';
import {errorHandlerAction} from '../../actions';
import {
  getScenarioOnePageReportOverviewDataAction,
  setLoading,
  setScenarioOnePageReportPerformanceNearCastingData,
} from '../../reducers/scenarioOnePageReportOverviewSlice';
import {FundListPerformanceResponse} from '../../api/getFundPerformanceData';
import {getScenarioFundPerformanceData} from '../../api/getScenarioFundPerformance';

export const scenarioOnePageReportOverviewSaga =
  function* scenarioOnePageReportOverviewSaga({
    payload,
  }: PayloadAction<{
    match: any;
  }>) {
    const id: string = payload?.match?.params?.id;
    if (id) {
      yield put(setLoading());
      try {
        const {accessToken} = yield call(acquireAuthResult, msalInstance);
        const response: FundListPerformanceResponse = yield call(
          getScenarioFundPerformanceData,
          accessToken,
          id
        );

        yield put(
          setScenarioOnePageReportPerformanceNearCastingData({
            nearCastingData: response.data,
          })
        );
        yield put(setLoading());
      } catch (err) {
        console.warn(err);
        yield put(setLoading());
        yield put(errorHandlerAction({error: err}));
      }
    }
  };

export const ScenarioOnePageReportOverviewSagaWatcher =
  function* ScenarioOnePageReportOverviewSagaWatcher() {
    yield all([
      takeEvery(
        getScenarioOnePageReportOverviewDataAction.type,
        scenarioOnePageReportOverviewSaga
      ),
    ]);
  };
