import axios from 'axios';
import config from '../config';
import {Funds} from '../types/index';

export const getAllFunds = async (token: string, page?: number) => {
  const {data} = await axios.get<Funds[]>(`${config.APP_URI}/fund-lists`, {
    params: {
      page: page || 1,
    },
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
  });
  return data[0];
};
