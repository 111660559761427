import React from 'react';
import classnames from 'classnames';
import styles from './Toggle.module.scss';
import {StyleVariant} from '../../../types';
import {formatToHyphenatedString} from '../../../utils/strings';

interface ToggleProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
  labelColor?: string;
  styleType?: StyleVariant;
  isReverse?: boolean;
}

const Toggle: React.FC<ToggleProps> = ({
  checked,
  label,
  labelColor,
  disabled = false,
  isReverse = false,
  styleType = StyleVariant.Secondary,
  ...inputProps
}): JSX.Element => {
  return (
    <div
      className={classnames(
        isReverse ? styles.reverseMainWrapper : styles.mainWrapper,
        disabled && styles.disabled
      )}
    >
      <p
        style={labelColor ? {color: labelColor} : {}}
        className={styles.label}
        data-test={formatToHyphenatedString(label)}
      >
        {label}
      </p>
      <label
        className={classnames(styles.wrapper, styles[`wrapper__${styleType}`])}
        data-testid="click-handler"
        data-test={`${formatToHyphenatedString(label)}-${checked}`}
      >
        <input
          disabled={disabled}
          type="checkbox"
          checked={checked}
          {...inputProps}
        />
        <span className={classnames(styles.slider, styles.round)}></span>
      </label>
    </div>
  );
};

export default Toggle;
