import {createAction, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {
  ScenarioReportHeaderData,
  ScenarioPortfolioCompanyData,
  ScenarioReportPortfolioSummaryData,
} from '../types/scenarioOnePageReport';

export const getScenarioOnePageReportPortfolioDataAction = createAction(
  'scenario-one-page-report-portfolio/get-scenario-portfolio-data',
  (payload: {id: string}) => {
    return {
      payload,
    };
  }
);

export const getScenarioOnePageReportCompanyPerformanceDataAction =
  createAction(
    'scenario-one-page-report-portfolio/get-scenario-company-performance-data',
    (payload: {id: string}) => {
      return {
        payload,
      };
    }
  );

interface ScenarioOnePageReportPortfolioData {
  headerData: ScenarioReportHeaderData[] | null;
  companies: ScenarioPortfolioCompanyData[] | null;
  companyPerformance: {
    reportedData: ScenarioReportPortfolioSummaryData;
    nearCastingData: ScenarioReportPortfolioSummaryData;
  } | null;
  isCompanyPerformanceLoading: boolean;
  isLoading: boolean;
}

const initialState: ScenarioOnePageReportPortfolioData = {
  headerData: null,
  companies: null,
  companyPerformance: null,
  isLoading: false,
  isCompanyPerformanceLoading: false,
};

const scenarioOnePageReportPortfolioSlice = createSlice({
  name: 'scenario-one-page-report-portfolio',
  initialState,
  reducers: {
    setScenarioOnePageReportPortfolioData(
      state: ScenarioOnePageReportPortfolioData,
      action: PayloadAction<{
        headerData: ScenarioReportHeaderData[] | null;
        companies: ScenarioPortfolioCompanyData[] | null;
      }>
    ) {
      state.headerData = action.payload.headerData;
      state.companies = action.payload.companies;
    },
    clearScenarioOnePageReportPortfolioData(
      state: ScenarioOnePageReportPortfolioData
    ) {
      state.headerData = null;
      state.companies = null;
    },
    setLoading(state: ScenarioOnePageReportPortfolioData) {
      state.isLoading = !state.isLoading;
    },

    setScenarioOnePageReportCompanyPerformanceData(
      state: ScenarioOnePageReportPortfolioData,
      action: PayloadAction<{
        reportedData: ScenarioReportPortfolioSummaryData;
        nearCastingData: ScenarioReportPortfolioSummaryData;
      }>
    ) {
      state.companyPerformance = action.payload;
    },
    clearScenarioOnePageReportCompanyPerformanceData(
      state: ScenarioOnePageReportPortfolioData
    ) {
      state.companyPerformance = null;
    },
    setLoadingCompanyPerformance(state: ScenarioOnePageReportPortfolioData) {
      state.isCompanyPerformanceLoading = !state.isCompanyPerformanceLoading;
    },
  },
});

export const {
  setScenarioOnePageReportPortfolioData,
  setLoading,
  clearScenarioOnePageReportPortfolioData,
  setLoadingCompanyPerformance,
  setScenarioOnePageReportCompanyPerformanceData,
  clearScenarioOnePageReportCompanyPerformanceData,
} = scenarioOnePageReportPortfolioSlice.actions;

export default scenarioOnePageReportPortfolioSlice.reducer;
