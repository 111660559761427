import {all, call, put, takeEvery} from 'redux-saga/effects';
import {acquireAuthResult, msalInstance} from '../..';
import {PayloadAction} from '@reduxjs/toolkit';
import {errorHandlerAction} from '../../actions';
import {
  addMultiplePortfolioCompanyNearCastingTransactions,
  addPortfolioCompanyNearCastingTransaction,
  clearScenarioDetailsValuationData,
  deletePortfolioCompanyNearCastingTransactionAction,
  deleteScenarioDetailsPortfolioCompany,
  editPortfolioCompanyNearCastingTransaction,
  editTableRowData,
  getScenarioDetailsValuationDataAction,
  importPortfolioCompanyTransactions,
  postScenarioDetailsPortfolioCompany,
  putScenarioDetailsPortfolioCompany,
  removeNearCastingPortfolioCompanyData,
  removePortfolioCompanyNearCastingTransaction,
  saveAndCalculateValuation,
  savePortfolioCompanyNearCastingTransactionAction,
  setLoading,
  setNewPortfolioCompany,
  setScenarioValuationData,
  setTransactionFormStatus,
  updateNearCastingPortfolioCompanyData,
  updatePortfolioCompanyNearCastingTransactionAction,
} from '../../reducers/scenarioDetailsValuationSlice';
import {
  deletePortfolioCompany,
  deletePortfolioCompanyNearCastingTransaction,
  getPortfolioCompanyData,
  getValuationCalc,
  importPCTransactions,
  postNewPortfolioCompany,
  putEditPortfolioCompany,
  savePortfolioCompanyNearCastingTransaction,
} from '../../api/getScenarioValuation';
import {
  CreateNearCastingScenarioCompany,
  FormStatus,
  NearCastingScenario,
  NearCastingScenarioCompany,
  NearCastingScenarioCompanyFormData,
  NearCastingScenarioCompanyTransaction,
  NearCastingScenarioCompanyTransactionPayload,
  UploadSingleTransactionsFile,
} from '../../types';
import i18n from '../../i18n';
import {getScenarioData} from '../../api/getScenarioMainInfo';
import {
  callToastError,
  callToastSuccess,
  DEFAULT_ERROR_TOAST_OPTIONS,
  DEFAULT_SUCCESSFUL_TOAST_OPTIONS,
  scenarioImportErrorResolver,
} from '../../utils';
import routePaths from '../../routePaths';
import {history} from '../../store';

export const postPortfolioCompanyNearCastingTransactionSaga =
  function* postPortfolioCompanyNearCastingTransactionSaga({
    payload,
  }: PayloadAction<{
    transaction: NearCastingScenarioCompanyTransactionPayload;
  }>) {
    yield put(setTransactionFormStatus(FormStatus.Pending));
    try {
      const {accessToken} = yield call(acquireAuthResult, msalInstance);
      const response: NearCastingScenarioCompanyTransaction = yield call(
        savePortfolioCompanyNearCastingTransaction,
        accessToken,
        payload
      );
      yield put(addPortfolioCompanyNearCastingTransaction(response));
      const ncCompanyId = response.ncCompany?.id;
      const companyResponse: NearCastingScenarioCompany = yield call(
        getPortfolioCompanyData,
        accessToken,
        ncCompanyId || ''
      );
      yield put(updateNearCastingPortfolioCompanyData(companyResponse));
      yield put(setTransactionFormStatus(FormStatus.Success));
    } catch (err) {
      console.warn(err);
      yield put(setLoading());
      yield put(errorHandlerAction({error: err}));
    }
  };

export const updatePortfolioCompanyNearCastingTransactionSaga =
  function* updatePortfolioCompanyNearCastingTransactionSaga({
    payload,
  }: PayloadAction<{
    transaction: NearCastingScenarioCompanyTransactionPayload;
  }>) {
    yield put(setTransactionFormStatus(FormStatus.Pending));
    try {
      const {accessToken} = yield call(acquireAuthResult, msalInstance);
      const response: NearCastingScenarioCompanyTransaction = yield call(
        savePortfolioCompanyNearCastingTransaction,
        accessToken,
        payload
      );
      yield put(editPortfolioCompanyNearCastingTransaction(response));
      const ncCompanyId = response.ncCompany?.id;
      const companyResponse: NearCastingScenarioCompany = yield call(
        getPortfolioCompanyData,
        accessToken,
        ncCompanyId as string
      );
      yield put(updateNearCastingPortfolioCompanyData(companyResponse));
      yield put(setTransactionFormStatus(FormStatus.Success));
    } catch (err) {
      console.warn(err);
      yield put(setLoading());
      yield put(errorHandlerAction({error: err}));
    }
  };

export const deletePortfolioCompanyNearCastingTransactionSaga =
  function* deletePortfolioCompanyNearCastingTransactionSaga({
    payload,
  }: PayloadAction<{
    id: string;
    companyId: string;
  }>) {
    try {
      const {accessToken} = yield call(acquireAuthResult, msalInstance);
      yield call(
        deletePortfolioCompanyNearCastingTransaction,
        accessToken,
        payload.id
      );
      const companyResponse: NearCastingScenarioCompany = yield call(
        getPortfolioCompanyData,
        accessToken,
        payload.companyId
      );
      yield put(updateNearCastingPortfolioCompanyData(companyResponse));
      yield put(removePortfolioCompanyNearCastingTransaction(payload));
    } catch (err) {
      console.warn(err);
      yield put(setLoading());
      yield put(errorHandlerAction({error: err}));
    }
  };

export const scenarioDetailsValuationSaga =
  function* scenarioDetailsValuationSaga({
    payload,
  }: PayloadAction<{
    match: any;
    id: string;
  }>) {
    const id: string = payload?.match?.params?.id;
    if (id) {
      yield put(setLoading());
      yield put(clearScenarioDetailsValuationData());
      try {
        const {accessToken} = yield call(acquireAuthResult, msalInstance);
        const response: NearCastingScenario = yield call(
          getScenarioData,
          accessToken,
          id
        );
        yield put(setScenarioValuationData(response));
        yield put(setLoading());
      } catch (err) {
        console.warn(err);
        yield put(setLoading());
        yield put(errorHandlerAction({error: err}));
      }
    }
  };

export const scenarioDetailsNewPortfolioCompany =
  function* scenarioDetailsNewPortfolioCompany({
    payload,
  }: PayloadAction<CreateNearCastingScenarioCompany>) {
    const id: string = payload?.scenario;
    if (id) {
      // yield put(setLoading());
      try {
        const {accessToken} = yield call(acquireAuthResult, msalInstance);
        const response: NearCastingScenarioCompany = yield call(
          postNewPortfolioCompany,
          accessToken,
          payload
        );
        yield put(setNewPortfolioCompany(response));
        // yield put(setLoading());
      } catch (err) {
        console.warn(err);
        // yield put(setLoading());
        yield put(errorHandlerAction({error: err}));
      }
    }
  };

export const scenarioDetailsDeletePortfolioCompany =
  function* scenarioDetailsDeletePortfolioCompany({
    payload,
  }: PayloadAction<{
    id: string;
  }>) {
    try {
      const {accessToken} = yield call(acquireAuthResult, msalInstance);
      yield call(deletePortfolioCompany, accessToken, payload.id);

      yield put(removeNearCastingPortfolioCompanyData(payload));
    } catch (err) {
      console.warn(err);
      yield put(setLoading());
      yield put(errorHandlerAction({error: err}));
    }
  };

export const scenarioDetailsEditPortfolioCompany =
  function* scenarioDetailsEditPortfolioCompany({
    payload,
  }: PayloadAction<{
    data: NearCastingScenarioCompanyFormData;
    id: string;
    tableIndex: number;
    field?: string;
    value?: string;
  }>) {
    if (payload.id) {
      try {
        const {accessToken} = yield call(acquireAuthResult, msalInstance);
        yield call(
          putEditPortfolioCompany,
          accessToken,
          payload.data,
          payload.id
        );
        const {data, tableIndex, value, field} = payload;
        if (!!field) {
          yield put(editTableRowData({field, tableIndex, value}));
        } else {
          yield put(editTableRowData({tableIndex, data}));
        }
      } catch (err) {
        console.warn(err);
        yield put(errorHandlerAction({error: err}));
      }
    }
  };

export const scenarioDetailsValuationSave =
  function* scenarioDetailsValuationSave({
    payload,
  }: PayloadAction<{
    id: string;
    fundId: string;
  }>) {
    yield put(setLoading());
    const {id, fundId} = payload;
    if (id && fundId) {
      try {
        const {accessToken} = yield call(acquireAuthResult, msalInstance);
        yield call(getValuationCalc, accessToken, id);
        history.push(
          `${routePaths.SCENARIOS}${routePaths.FUNDS}/${fundId}/scenarios/${id}/transactions`
        );
        yield call(
          callToastSuccess,
          i18n.t('ScenarioDetailsValuationSave.MessageSuccess'),
          DEFAULT_SUCCESSFUL_TOAST_OPTIONS
        );
        yield put(setLoading());
      } catch (err) {
        console.warn(err);
        yield call(
          callToastError,
          i18n.t('ScenarioDetailsValuationSave.MessageError'),
          DEFAULT_ERROR_TOAST_OPTIONS
        );
        yield put(setLoading());
        yield put(errorHandlerAction({error: err}));
      }
    }
  };

export const importPortfolioCompanyTransactionsSaga =
  function* importPorfolioCompanyTransactionsSaga({
    payload,
  }: PayloadAction<{
    ncCompanyId: string;
    file: UploadSingleTransactionsFile;
  }>) {
    try {
      const {accessToken} = yield call(acquireAuthResult, msalInstance);
      const {ncCompanyId, file} = payload;
      const companyResponse: NearCastingScenarioCompany = yield call(
        importPCTransactions,
        accessToken,
        ncCompanyId,
        file.file
      );
      yield put(
        addMultiplePortfolioCompanyNearCastingTransactions(companyResponse)
      );
      yield call(
        callToastSuccess,
        i18n.t('Global.TransactionMessage.Success'),
        DEFAULT_SUCCESSFUL_TOAST_OPTIONS
      );
      yield put(setTransactionFormStatus(FormStatus.Success));
    } catch (err) {
      const {errorMessage} = scenarioImportErrorResolver(err);
      yield call(callToastError, errorMessage, DEFAULT_ERROR_TOAST_OPTIONS);
      console.warn(err);
      yield put(errorHandlerAction({error: err}));
    }
  };

export const scenarioDetailsValuationSagaWatcher =
  function* scenarioDetailsValuationSagaWatcher() {
    yield all([
      takeEvery(
        getScenarioDetailsValuationDataAction.type,
        scenarioDetailsValuationSaga
      ),
      takeEvery(
        savePortfolioCompanyNearCastingTransactionAction.type,
        postPortfolioCompanyNearCastingTransactionSaga
      ),
      takeEvery(
        putScenarioDetailsPortfolioCompany.type,
        scenarioDetailsEditPortfolioCompany
      ),
      takeEvery(
        updatePortfolioCompanyNearCastingTransactionAction.type,
        updatePortfolioCompanyNearCastingTransactionSaga
      ),
      takeEvery(
        deletePortfolioCompanyNearCastingTransactionAction.type,
        deletePortfolioCompanyNearCastingTransactionSaga
      ),
      takeEvery(
        postScenarioDetailsPortfolioCompany.type,
        scenarioDetailsNewPortfolioCompany
      ),
      takeEvery(
        deleteScenarioDetailsPortfolioCompany.type,
        scenarioDetailsDeletePortfolioCompany
      ),
      takeEvery(saveAndCalculateValuation.type, scenarioDetailsValuationSave),
      takeEvery(
        importPortfolioCompanyTransactions.type,
        importPortfolioCompanyTransactionsSaga
      ),
    ]);
  };
