export const activeDataVisibilitySwitchPages = [
  'Dashboard',
  'Funds',
  'Portfolio Companies',
  'Fund Overview',
  'Fund Operational',
  'Fund Structure',
  'Fund Portfolio',
  'Fund Portfolio Transactions',
  'Portfolio Company Investment Overview',
  'Portfolio Company',
];
