import React from 'react';
import {Icon} from '../../global';
import styles from './PerformingCompaniesStats.module.scss';

interface PerformingCompaniesStatsProps {
  performingCompanyStats: {
    performing: number;
    underPerforming: number;
  };
}
const PerformingCompaniesStats: React.FC<PerformingCompaniesStatsProps> = ({
  performingCompanyStats,
}): JSX.Element => {
  const {performing, underPerforming} = performingCompanyStats;
  return (
    <div
      className={styles.wrapper}
      data-test="home-page-portfolio-companies-performing-stats-row"
    >
      <div
        className={styles.performing}
        data-test="home-page-portfolio-companies-performing-stats-up"
      >
        {performing} <Icon className={styles.icon} name={'stats-up'} />
      </div>
      <div
        className={styles.underPerforming}
        data-test="home-page-portfolio-companies-performing-stats-down"
      >
        {underPerforming} <Icon className={styles.icon} name={'stats-down'} />
      </div>
    </div>
  );
};

export default PerformingCompaniesStats;
