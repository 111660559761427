import {UploadFileFormState} from '../types';

export const uploadFileFormInitialState: UploadFileFormState = {
  entityTags: [],
  sectionTags: [],
  customTags: [],
  transactions: [],
  columns: [],
  isPrivate: false,
};
