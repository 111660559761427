import {CompanyAdminReportingGroup, Tab} from '../types';

export const getActiveReportingTab = (
  data: CompanyAdminReportingGroup[],
  tab: string
): Tab => {
  return data.reduce(
    (acc, item) => {
      if (item.label.toLowerCase() === tab) {
        return {
          label: item.label,
          value: item.label.toLowerCase(),
          id: item.id,
        };
      }
      return acc;
    },
    {label: '', value: '', id: ''}
  );
};
