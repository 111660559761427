import React from 'react';
import {Trans} from 'react-i18next';
import styles from './DocumentExplorerTablePlaceholder.module.scss';

const DocumentExplorerTablePlaceholder: React.FC = () => {
  return (
    <div className={styles.tablePlaceholder}>
      <div className={styles.tablePlaceholderSection}>
        <Trans i18nKey={'Documents.DocumentRepositoryTable.Header'} />
      </div>
      <div className={styles.tablePlaceholderSection}>
        <Trans
          i18nKey={'Documents.DocumentRepositoryTable.EntityTags'}
          components={[
            <div className={styles.tablePlaceholderTitle}>Entity tags</div>,
          ]}
        />
      </div>
      <div className={styles.tablePlaceholderSection}>
        <Trans
          i18nKey={'Documents.DocumentRepositoryTable.SectionTags'}
          components={[
            <div className={styles.tablePlaceholderTitle}>Section tags</div>,
          ]}
        />
      </div>
      <div className={styles.tablePlaceholderSection}>
        <Trans
          i18nKey={'Documents.DocumentRepositoryTable.Footer'}
          components={[
            <div className={styles.tablePlaceholderTitle}>Custom tags</div>,
          ]}
        />
      </div>
    </div>
  );
};

export default DocumentExplorerTablePlaceholder;
