import React, {ReactElement, useState} from 'react';
import styles from './SelectReportingPeriods.module.scss';
import {
  ButtonStyle,
  ReportingPeriodsData,
  Func,
  HeaderMapping,
} from '../../../../types';
import {Button, GenericTable, IconButton} from '../../../global';
import {useTranslation} from 'react-i18next';
import genSelectReportingPeriodsTableColumnDefs from '../../../global/table/select-reporting-periods-table/SelectReportingPeriodsColumnDefs';
import classnames from 'classnames';
import {selectReportingPeriodsTableDefaultSorting} from '../../../../constants';

type SelectReportingPeriodsProps = {
  title: string;
  reportingPeriods: {
    headerMapping: HeaderMapping[];
    data: ReportingPeriodsData[];
  } | null;
  selectedReportingPeriods: string[];
  onDone: Func<any, void>;
  onCancel: Func<[], void>;
};

const SelectReportingPeriods = ({
  title,
  reportingPeriods,
  selectedReportingPeriods,
  onDone,
  onCancel,
}: SelectReportingPeriodsProps) => {
  const [currentSelectedReportingPeriods, setCurrentSelectedReportingPeriods] =
    useState<String[]>(selectedReportingPeriods);

  const {t} = useTranslation();

  const checkHandler = (checked: boolean, id: string) => {
    if (checked) {
      setCurrentSelectedReportingPeriods(prev => [...prev, id]);
    } else {
      setCurrentSelectedReportingPeriods(prev =>
        prev.filter(item => item !== id)
      );
    }
  };

  const isDoneDisabled =
    selectedReportingPeriods.sort().toString() ===
    currentSelectedReportingPeriods.sort().toString();

  const onDoneHandler = () => {
    const selectedReportingPeriods = reportingPeriods?.data?.filter(period =>
      currentSelectedReportingPeriods.includes(period.id)
    );
    onDone(currentSelectedReportingPeriods, selectedReportingPeriods);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h3 className={styles.title}>{title}</h3>
        <IconButton
          styleType={ButtonStyle.Primary}
          icon={'cross'}
          onClick={onCancel}
        />
      </div>
      {reportingPeriods ? (
        <GenericTable
          data={reportingPeriods.data}
          columns={genSelectReportingPeriodsTableColumnDefs(
            reportingPeriods.headerMapping,
            currentSelectedReportingPeriods,
            checkHandler
          )}
          expandedView={(): ReactElement => <></>}
          getRowCanExpand={() => false}
          defaultSorting={selectReportingPeriodsTableDefaultSorting}
          className={classnames(styles.reportingPeriods, styles.scroll)}
        />
      ) : null}
      <div className={styles.footer}>
        <Button
          styleType={ButtonStyle.Primary}
          text={t('Global.Done')}
          onClick={onDoneHandler}
          disabled={isDoneDisabled}
        />
        <Button
          styleType={ButtonStyle.Secondary}
          text={t('Global.Cancel')}
          onClick={onCancel}
        />
      </div>
    </div>
  );
};

export default SelectReportingPeriods;
