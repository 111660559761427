import axios from 'axios';
import config from '../config';
import {
  ActiveCompanyData,
  FundOverview,
  NavTimeSeries,
  ComponentWidgetType,
} from '../types';

export interface FundPorfolioCompaniesParams {
  fundId: string;
  page?: number;
  maxResults?: number;
}

export const getFundDetailsOverview = async (token: string, id: string) => {
  const {data} = await axios.get<FundOverview>(
    `${config.APP_URI}/fund-overviews/${id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );
  return data;
};

export const getFundOverviewPorfolioCompanies = async (
  token: string,
  params: FundPorfolioCompaniesParams
) => {
  const {fundId, page, maxResults = 6} = params;
  const {data} = await axios.get<{widgets: ActiveCompanyData[]}>(
    `${config.APP_URI}/portfolio_companies/search-by-fund`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
      params: {
        fund: fundId,
        page,
        maxResults,
      },
    }
  );
  return data.widgets;
};

export const getFundCapitalData = async (
  token: string,
  fundId: string,
  date: string
) => {
  const {data} = await axios.get<ComponentWidgetType[]>(
    `${config.APP_URI}/fund-overviews/capital/${fundId}?date=${date}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );
  return data;
};

export const getFundExposureData = async (
  token: string,
  fundId: string,
  date: string,
  sortValue: string,
  sortDirection: string
) => {
  const {data} = await axios.get<ActiveCompanyData[]>(
    `${config.APP_URI}/fund-overviews/exposure/${fundId}?date=${date}&sorting=${sortValue}&order=${sortDirection}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );
  return data;
};

export const getBenchmarkingData = async (
  token: string,
  fundId: string,
  kpiName: string,
  source: string
) => {
  const {data} = await axios.get<NavTimeSeries[]>(
    `${config.APP_URI}/fund-overviews/benchmarking/${fundId}`,
    {
      params: {
        kpiName,
        source,
      },
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );
  return data;
};
