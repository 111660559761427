import {TableSettings} from '../../../../../types';

export const getPerformanceTotalCostChartSettings = (
  zipFileName: string
): TableSettings => {
  return {
    fileName: zipFileName,
    name: 'Current Cost',
    columnVisibility: {quarter: false},
    headerMapping: [
      {
        columnId: 'Other',
        label: 'Other',
      },
      {
        columnId: 'Debt',
        label: 'Debt',
      },
      {
        columnId: 'Equity',
        label: 'Equity',
      },
      {
        columnId: 'Current cost',
        label: 'Current cost',
      },
      {
        columnId: 'quarter',
        label: 'Quarter',
      },
      {
        columnId: 'date',
        label: 'Date',
      },
    ],
    columnOrder: ['Other', 'Debt', 'Equity', 'Current cost', 'quarter', 'date'],
    meta: {
      columns: {
        Other: {
          styling: {
            align: 'right',
          },
        },
        Debt: {
          styling: {
            align: 'right',
          },
        },
        Equity: {
          styling: {
            align: 'right',
          },
        },
        'Current cost': {
          styling: {
            align: 'right',
          },
        },
        quarter: {
          styling: {
            align: 'right',
          },
        },
        date: {
          styling: {
            align: 'right',
          },
          formatter: 'numFmt:dd-mm-yyyy',
        },
      },
    },
  };
};

export const getPerformanceTotalCostTableExportPayload = (
  data: readonly object[],
  zipFileName: string
) => {
  const tableSettings = getPerformanceTotalCostChartSettings(zipFileName);

  return [
    {
      data: data,
      mappings: tableSettings.headerMapping,
      settings: tableSettings,
    },
  ];
};

export const getperformanceTotalCostImagesExportPayload = (
  zipFileName: string,
  id: string | undefined
) => {
  return {
    id: `${id}-performance-total-cost-chart`,
    fileName: zipFileName,
    opts: [
      {
        fileName: `${zipFileName}_TransparentBackground`,
        type: 'image/svg+xml',
        ext: 'svg',
        bgColor: 'transparent',
        elsToFilter: ['performance-chart-export', 'recharts-tooltip-wrapper'],
      },
      {
        fileName: `${zipFileName}_WithBackground`,
        type: 'image/svg+xml',
        ext: 'svg',
        bgColor: '#202020',
        elsToFilter: ['performance-chart-export', 'recharts-tooltip-wrapper'],
      },
      {
        fileName: `${zipFileName}_TransparentBackground`,
        type: 'image/png',
        ext: 'png',
        bgColor: 'transparent',
        elsToFilter: ['performance-chart-export', 'recharts-tooltip-wrapper'],
      },
      {
        fileName: `${zipFileName}_WithBackground`,
        type: 'image/png',
        ext: 'png',
        bgColor: '#202020',
        elsToFilter: ['performance-chart-export', 'recharts-tooltip-wrapper'],
      },
    ],
  };
};
