import {
  AddTemplateConfigDetailsCustomGroupPayload,
  ClientAdminTemplateConfigDetails,
  UpdateReportingGroupTemplatePayload,
  UpdateTemplateConfigDetailsCustomGroupPayload,
  AddTemplateConfigDetailsCustomMetricsPayload,
  UpdateTemplateConfigDetailsCustomMetricsPayload,
  UpdateTemplateConfigDetailsGroupOrderPayload,
  TemplateConfigDetailsCustomGroup,
  TemplateConfigDetailsCustomGroupItem,
  ForecastGroupsAndMetricsFormGroup,
  UpdateForecastGroupsAndMetricsPayload,
} from '../types';
import axios from 'axios';
import config from '../config';

export const getTemplateConfigDetailsData = async (
  token: string,
  id: string
) => {
  const {data} = await axios.get<ClientAdminTemplateConfigDetails>(
    `${config.APP_URI}/admin/template-configuration/${id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );
  return data;
};

export const addTemplateConfigDetailsCustomGroup = async (
  token: string,
  payload: AddTemplateConfigDetailsCustomGroupPayload
) => {
  const {data} = await axios.post<
    TemplateConfigDetailsCustomGroup<TemplateConfigDetailsCustomGroupItem>
  >(
    `${config.APP_URI}/groups`,
    {...payload, parentId: payload.parentId ? payload.parentId : null},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );
  return data;
};

export const updateTemplateConfigDetailsCustomGroup = async (
  token: string,
  id: string,
  payload: UpdateTemplateConfigDetailsCustomGroupPayload
) => {
  const {data} = await axios.put<
    TemplateConfigDetailsCustomGroup<TemplateConfigDetailsCustomGroupItem>
  >(`${config.APP_URI}/groups/${id}`, payload, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
  });
  return data;
};

export const addTemplateConfigDetailsCustomMetric = async (
  token: string,
  payload: AddTemplateConfigDetailsCustomMetricsPayload
) => {
  const {data} = await axios.post<
    TemplateConfigDetailsCustomGroup<TemplateConfigDetailsCustomGroupItem>
  >(`${config.APP_URI}/rows`, payload, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
  });
  return data;
};

export const updateTemplateConfigDetailsCustomMetrics = async (
  token: string,
  id: string,
  payload: UpdateTemplateConfigDetailsCustomMetricsPayload
) => {
  const {data} = await axios.put<
    TemplateConfigDetailsCustomGroup<TemplateConfigDetailsCustomGroupItem>
  >(`${config.APP_URI}/rows/${id}`, payload, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
  });
  return data;
};
export const removeTemplateConfigDetailsMetric = async (
  token: string,
  id: string
) => {
  const {data} = await axios.delete(`${config.APP_URI}/rows/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
  });
  return data;
};

export const removeTemplateConfigDetailsCustomGroup = async (
  token: string,
  id: string
) => {
  const {data} = await axios.delete(`${config.APP_URI}/groups/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
  });
  return data;
};

export const updateTemplateConfigDetailsGroupsOrder = async (
  token: string,
  templateId: string,
  payload: UpdateTemplateConfigDetailsGroupOrderPayload
) => {
  const {data} = await axios.put<UpdateTemplateConfigDetailsGroupOrderPayload>(
    `${config.APP_URI}/templates/order-groups/${templateId}`,
    payload,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );
  return data;
};

export const updateReportingGroupTemplate = async (
  token: string,
  id: string,
  payload: UpdateReportingGroupTemplatePayload
) => {
  const {data} = await axios.put(`${config.APP_URI}/templates/${id}`, payload, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
  });
  return {name: data.name};
};

export const removeReportingGroupTemplate = async (
  token: string,
  id: string
) => {
  const {data} = await axios.delete<boolean>(
    `${config.APP_URI}/templates/${id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );
  return data;
};

export const getForecastGroupAndMetricsFormOptions = async (
  token: string,
  id: string
) => {
  const {data} = await axios.get<ForecastGroupsAndMetricsFormGroup[]>(
    `${config.APP_URI}/admin/template-configuration/forecast-groups-and-metrics-options/${id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );
  return data;
};

export const updateForecastGroupsAndMetrics = async (
  token: string,
  payload: UpdateForecastGroupsAndMetricsPayload
) => {
  const {data} = await axios.post<
    TemplateConfigDetailsCustomGroup<TemplateConfigDetailsCustomGroupItem>[]
  >(`${config.APP_URI}/templates/select-forecast-groups-metrics`, payload, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
  });
  return data;
};
