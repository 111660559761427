import React, {ReactElement} from 'react';
import {Func, ControllerOption, OptionValue} from '../../../../../types/index';
import {SegmentedControl, BenchmarkController} from '../../../../';
import {SegmentedControlVariants} from '../../../../global/segmented-control/SegmentedControl';
import Toggle from '../../../../global/toggle/Toggle';
import classnames from 'classnames';
import styles from './NavCustomLegend.module.scss';
import {useTranslation} from 'react-i18next';
import {formatToHyphenatedString} from '../../../../../utils/strings';

export type NavLegendItem = {
  id: string;
  label: string;
  value: string;
  color: string;
  withCircle?: boolean;
  readonly?: boolean;
};

interface KpiToggle {
  actions: NavLegendItem[];
  value: string;
  onChange: Func<[string], void>;
}

interface NavCustomLegendProps {
  id: string;
  items: NavLegendItem[];
  kpiToggle?: KpiToggle;
  className?: string;
  buttonVariant?: SegmentedControlVariants;
  benchmarking?: Array<ControllerOption>;
  onBenchmarkToggle?: Func<[void], void>;
  onBenchmarkOptionChange?: Func<[ControllerOption, OptionValue], void>;
  showBenchmarking: boolean;
  activeKpi?: ControllerOption | null;
  isBenchmarking?: boolean;
  exportButton?: ReactElement;
  activeCharts?: string[];
  setActiveCharts?: React.Dispatch<React.SetStateAction<string[]>>;
}

const NavCustomLegend: React.FC<NavCustomLegendProps> = ({
  id,
  items,
  kpiToggle,
  buttonVariant = SegmentedControlVariants.Primary,
  className,
  benchmarking,
  onBenchmarkToggle,
  showBenchmarking,
  onBenchmarkOptionChange,
  activeKpi,
  isBenchmarking,
  exportButton,
  activeCharts,
  setActiveCharts,
}): JSX.Element => {
  const {t} = useTranslation();
  const onActiveItemChange = (item: string | number): void => {
    kpiToggle?.onChange(item as string);
  };

  const onToggleClick = () => {
    onBenchmarkToggle?.();
  };

  const handleClickLegendItem = (value: string) => {
    if (!setActiveCharts) return;
    setActiveCharts(prev => {
      if (prev.includes(value)) return prev.filter(ss => ss !== value);
      return [...prev, value];
    });
  };

  return (
    <div id={id} className={styles.legendWrapper}>
      <div className={classnames(styles.wrapper, className)}>
        {kpiToggle && (
          <div
            className={classnames(
              styles.actions,
              isBenchmarking ? styles.disabled : ''
            )}
          >
            <SegmentedControl
              id={`${id}-kpis`}
              options={kpiToggle.actions}
              value={kpiToggle.value}
              onChange={onActiveItemChange}
              variant={buttonVariant}
            />
          </div>
        )}
        <div className={styles.legendItemsRow}>
          {items?.map((item, i) => (
            <div
              key={i}
              onClick={() => {
                if (item.readonly) return;
                handleClickLegendItem(item.value);
              }}
              className={classnames(
                styles.item,
                item.readonly && styles.readonly
              )}
              style={{
                opacity: activeKpi && item.value !== activeKpi?.name ? 0.2 : 1,
              }}
              data-test={`${id}-${formatToHyphenatedString(item.label)}`}
            >
              <div
                className={styles.itemCircle}
                style={{
                  backgroundColor:
                    activeCharts?.includes(item.value) || item.readonly
                      ? item.color
                      : `rgb(var(--colors-gray-4))`,
                }}
              />
              <p className={styles.itemLabel}>{t(item.label)}</p>
            </div>
          ))}
        </div>
      </div>

      <div className={styles.actions}>
        {benchmarking && kpiToggle?.value !== 'jCurve' && (
          <div
            className={`${styles.benchmarWrapper} performance-benchmark-toggle`}
          >
            <Toggle
              label={t('Global.Benchmark')}
              labelColor={'white'}
              onChange={onToggleClick}
              checked={showBenchmarking}
              // benchmarking disabled for V1
              disabled={true}
            />
            {showBenchmarking && (
              <div className={styles.benchamrking}>
                <BenchmarkController
                  options={benchmarking}
                  onBenchmarkOptionChange={onBenchmarkOptionChange}
                />
              </div>
            )}
          </div>
        )}

        {/* Chart export button */}
        {!!exportButton && exportButton}
      </div>
    </div>
  );
};

export default NavCustomLegend;
