import React, {useRef} from 'react';
import {useTooltip} from '../../../../../hooks';
import {Position} from '../../../../../context/TooltipContext';
import Checkbox from '../../../checkbox/Checkbox';
import {UpdateNewsPayload} from '../../../../../types';

type ResolvedCellProps = {
  id: string;
  checked: boolean;
  resolvedTooltip: string;
  onUpdateNews: (payload: UpdateNewsPayload) => void;
};
const ResolvedCell: React.FC<ResolvedCellProps> = ({
  id,
  checked,
  onUpdateNews,
  resolvedTooltip,
}) => {
  const {showTooltip, hideTooltip} = useTooltip();

  const wrapperRef = useRef<HTMLDivElement>(null);

  const handleShowTooltip = () => {
    if (!wrapperRef.current || !checked) return;
    showTooltip({
      content: resolvedTooltip,
      target: wrapperRef.current,
      position: Position.Top,
      hasArrow: false,
    });
  };

  return (
    <div
      ref={wrapperRef}
      onMouseEnter={handleShowTooltip}
      onMouseLeave={hideTooltip}
    >
      <Checkbox
        checked={checked}
        readOnly
        label={'Resolved'}
        onChange={event => {
          event.stopPropagation();
          onUpdateNews({
            id: id,
            resolved: event.target.checked,
          });
        }}
      />
    </div>
  );
};

export default ResolvedCell;
