import {all, call, put, select, takeEvery} from 'redux-saga/effects';
import {acquireAuthResult, msalInstance} from '../../index';
import {
  DocumentTag,
  FileEditFormOptions,
  FileEditFormPayload,
  FileItem,
  FormStatus,
  UploadDocuments,
  UploadDocumentsParams,
} from '../../types';
import {getUploadDocuments} from '../../api/getUploadDocuments';
import {
  getUploadDocumentsDataAction,
  getUploadDocumentsFileEditOptionsAction,
  putUploadDocumentsFileEditUpdateAction,
  setUploadDocumentsData,
  setUploadDocumentsError,
  setUploadDocumentsFileEditOptionsData,
  setUploadDocumentsFormError,
  setUploadDocumentsFormStatus,
  setUploadDocumentsIsRequestPending,
  updateUploadDocumentsFileData,
} from '../../reducers/uploadDocumentsSlice';
import {PayloadAction} from '@reduxjs/toolkit';
import {
  getFileEditFormOptions,
  putFileEditUpdate,
} from '../../api/getDocumentExplorer';
import {RootState} from '../../store';
import {responseErrorResolver} from '../../utils';
import i18n from '../../i18n';

export const getUploadDocumentsSaga = function* getUploadDocumentsSaga({
  payload,
}: PayloadAction<UploadDocumentsParams>) {
  try {
    yield put(setUploadDocumentsIsRequestPending(true));
    const {accessToken} = yield call(acquireAuthResult, msalInstance);
    const response: UploadDocuments[] = yield call(
      getUploadDocuments,
      accessToken,
      payload.fund,
      payload.portfolioCompany,
      payload.sections,
      payload.transactions,
      payload.columnIds
    );
    yield put(setUploadDocumentsData(response[0]));
    yield put(setUploadDocumentsIsRequestPending(false));
  } catch (err) {
    console.warn(err);
    yield put(setUploadDocumentsIsRequestPending(false));
  }
};

export const getUploadDocumentsFileEditOptionsSaga =
  function* getUploadDocumentsFileEditOptionsSaga({
    payload,
  }: PayloadAction<{id: string}>): any {
    yield call(uploadDocumentsErrorHelperSaga);
    try {
      yield put(setUploadDocumentsFileEditOptionsData(null));
      yield put(setUploadDocumentsFormStatus(FormStatus.Pending));
      const {accessToken} = yield call(acquireAuthResult, msalInstance);
      const response: FileEditFormOptions = yield call(
        getFileEditFormOptions,
        accessToken,
        payload.id
      );
      yield put(setUploadDocumentsFileEditOptionsData(response));
      yield put(setUploadDocumentsFormStatus(FormStatus.Ready));
    } catch (err) {
      console.warn(err);
      yield call(
        uploadDocumentsResponseErrorHelperSaga,
        err,
        i18n.t('DocumentExplorer.GetUploadDocumentsFileEditOptions.Error')
      );
    }
  };

export const putUploadDocumentsFileEditUpdateSaga =
  function* putUploadDocumentsFileEditUpdateSaga({
    payload,
  }: PayloadAction<FileEditFormPayload>): any {
    yield call(uploadDocumentsErrorHelperSaga);
    try {
      yield put(setUploadDocumentsFormStatus(FormStatus.Pending));
      const {accessToken} = yield call(acquireAuthResult, msalInstance);
      const response: {
        fileItem: FileItem;
        customTags: Array<DocumentTag>;
        documentCount: number;
      } = yield call(putFileEditUpdate, accessToken, payload);
      yield put(updateUploadDocumentsFileData(response.fileItem));
      yield put(setUploadDocumentsFormStatus(FormStatus.Success));
      yield put(setUploadDocumentsFileEditOptionsData(null));
    } catch (err) {
      console.warn(err);
      yield call(
        uploadDocumentsResponseErrorHelperSaga,
        err,
        i18n.t('DocumentExplorer.PutUploadDocumentsFileEditUpdate.Error')
      );
    }
  };

const uploadDocumentsErrorHelperSaga =
  function* uploadDocumentsErrorHelperSaga() {
    const error: string | null = yield select(
      (state: RootState) => state.uploadDocuments.error
    );
    const formError: string | null = yield select(
      (state: RootState) => state.uploadDocuments.formError
    );
    if (error) {
      yield put(setUploadDocumentsError(null));
    }
    if (formError) {
      yield put(setUploadDocumentsFormError(null));
    }
  };

const uploadDocumentsResponseErrorHelperSaga =
  function* uploadDocumentsResponseErrorHelperSaga(
    err: any,
    placeholder: string
  ) {
    yield put(setUploadDocumentsFormStatus(FormStatus.Ready));
    const {error, formError} = responseErrorResolver(err, placeholder);
    yield put(setUploadDocumentsError(error));
    yield put(setUploadDocumentsFormError(formError));
  };

export const uploadDocumentsSagaWatcher =
  function* uploadDocumentsSagaWatcher() {
    yield all([
      takeEvery(getUploadDocumentsDataAction.type, getUploadDocumentsSaga),
      takeEvery(
        getUploadDocumentsFileEditOptionsAction.type,
        getUploadDocumentsFileEditOptionsSaga
      ),
      takeEvery(
        putUploadDocumentsFileEditUpdateAction.type,
        putUploadDocumentsFileEditUpdateSaga
      ),
    ]);
  };
