import axios from 'axios';
import config from '../config';
import {
  ClientAdminTemplateConfiguration,
  TemplateConfigReportingGroup,
  TemplateConfigReportingGroupItem,
} from '../types';

export const getClientAdminTemplateConfig = async (token: string) => {
  const {data} = await axios.get<ClientAdminTemplateConfiguration[]>(
    `${config.APP_URI}/admin/template-configurations`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );
  return data[0];
};

export const addNewTemplate = async (
  token: string,
  reportingGroupId: string,
  name: string,
  templateParentId: string | null
) => {
  const {data} = await axios.post<
    TemplateConfigReportingGroup<TemplateConfigReportingGroupItem>
  >(
    `${config.APP_URI}/templates`,
    {
      reportingGroupId,
      name,
      templateParentId,
    },
    {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return data;
};
