interface LabelData {
  label: string;
  index: number;
}
export const getMissingDataIndex = (
  data?: ReadonlyArray<object>,
  label?: string
): LabelData => {
  let labelData = {
    label: '',
    index: -1,
  };

  data?.forEach((item: any, index, arr: any) => {
    if (item[label!] === null && arr[index + 1][label!] !== null) {
      if (arr[index + 1][label!]) {
        labelData.index = index + 1;
        labelData.label = arr[index + 1].quarter;
      } else {
        labelData.index = index;
        labelData.label = item.quarter;
      }
    }
  });

  return labelData;
};
