import {Slide, toast, ToastOptions} from 'react-toastify';
import {ReactComponent as Info} from '../assets/icons/toast-info-icon.svg';
import {ReactComponent as Error} from '../assets/icons/toast-warning-icon.svg';
import {ReactComponent as Success} from '../assets/icons/toast-success-icon.svg';
import React from 'react';
export const DEFAULT_TOAST_AUTOCLOSE_TIME = 5000;
export const DEFAULT_SUCCESSFUL_TOAST_OPTIONS: ToastOptions = {
  position: 'top-right',
  autoClose: DEFAULT_TOAST_AUTOCLOSE_TIME,
  hideProgressBar: true,
  closeOnClick: false,
  pauseOnHover: false,
  transition: Slide,
};

export const DEFAULT_ERROR_TOAST_OPTIONS: ToastOptions = {
  position: 'top-right',
  autoClose: DEFAULT_TOAST_AUTOCLOSE_TIME,
  hideProgressBar: true,
  closeOnClick: false,
  pauseOnHover: false,
  transition: Slide,
};

export const callToastSuccess = (
  value: string | null,
  options?: ToastOptions
) => {
  toast.success(value, {...options, icon: <Success />});
};

export const callToastInfo = (value: string | null, options?: ToastOptions) => {
  toast.info(value, {...options, icon: <Info />});
};

export const callToastError = (
  value: string | null,
  options?: ToastOptions
) => {
  toast.error(value, {...options, icon: <Error />});
};
