import React from 'react';
import {LogoProps} from '../../../types';

const LanternPrimaryLogoWhite: React.FC<LogoProps> = ({
  gradientId = 'lantern-primary-logo-gradient',
  ...props
}) => {
  return (
    <svg
      id="Layer_2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 278.01 38.4"
      {...props}
    >
      <defs>
        <style></style>
        <linearGradient
          id={gradientId}
          x1="74.44"
          y1="10.06"
          x2="5.82"
          y2="69.63"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".19" stopColor="#fff" />
          <stop offset=".48" stopColor="#fff" stopOpacity="0" />
        </linearGradient>
      </defs>
      <g id="Layer_1-2">
        <path
          fill="#fff"
          d="m30.84,35.7c0,.73-.26,1.36-.77,1.89-.51.53-1.13.79-1.86.79H2.68c-.73,0-1.36-.26-1.89-.79-.53-.53-.79-1.16-.79-1.89V2.73c0-.73.26-1.36.79-1.89C1.32.31,1.95.04,2.68.04s1.35.27,1.86.79c.51.53.77,1.16.77,1.89v30.35h22.9c.73,0,1.35.26,1.86.77.51.51.77,1.13.77,1.86Z"
        />
        <path
          fill="#fff"
          d="m124.54,5.36c-.73,0-1.36-.26-1.89-.77-.53-.51-.79-1.13-.79-1.86s.26-1.36.79-1.89,1.16-.79,1.89-.79h31.23c.73,0,1.36.27,1.89.79.53.53.79,1.16.79,1.89s-.27,1.35-.79,1.86c-.53.51-1.16.77-1.89.77h-12.93v30.35c0,.73-.27,1.36-.79,1.89-.53.53-1.16.79-1.89.79s-1.36-.26-1.89-.79c-.53-.53-.79-1.16-.79-1.89V5.36h-12.93Z"
        />
        <path
          fill="#fff"
          d="m191.67,33.07c.73,0,1.35.26,1.86.77.51.51.77,1.13.77,1.86s-.26,1.36-.77,1.89c-.51.53-1.13.79-1.86.79h-25.14c-.73,0-1.36-.26-1.89-.79-.53-.53-.79-1.16-.79-1.89V2.73c0-.73.26-1.36.79-1.89s1.16-.79,1.89-.79h25.09c.73,0,1.36.27,1.89.79.53.53.79,1.16.79,1.89s-.27,1.35-.79,1.86c-.53.51-1.16.77-1.89.77h-22.46v10.68h19.83c.73,0,1.35.26,1.86.77.51.51.77,1.13.77,1.86s-.26,1.36-.77,1.89c-.51.53-1.13.79-1.86.79h-19.83v11.72h22.52Z"
        />
        <path
          fill="#fff"
          d="m202.65,38.39c-.73,0-1.36-.26-1.89-.79-.53-.53-.79-1.16-.79-1.89V2.73c0-.73.26-1.36.79-1.89s1.16-.79,1.89-.79h20.32c1.72,0,3.33.33,4.85.99,1.52.66,2.84,1.55,3.97,2.68,1.13,1.13,2.03,2.46,2.68,3.97.66,1.52.99,3.13.99,4.85,0,1.39-.21,2.71-.63,3.97-.42,1.26-1.01,2.41-1.78,3.45-.77,1.04-1.68,1.94-2.74,2.71-1.06.77-2.21,1.35-3.45,1.75l8,9.59c.47.58.68,1.23.63,1.94s-.38,1.32-.96,1.83c-.51.4-1.08.6-1.7.6-.84,0-1.53-.31-2.08-.93l-10.13-12.16s-.09-.13-.16-.27h-15.17v10.68c0,.73-.26,1.36-.77,1.89-.51.53-1.13.79-1.86.79Zm2.63-18.68h17.69c.99,0,1.92-.19,2.79-.58s1.63-.89,2.27-1.53,1.15-1.4,1.53-2.27c.38-.88.57-1.81.57-2.79s-.19-1.92-.57-2.79c-.38-.88-.9-1.63-1.53-2.27-.64-.64-1.4-1.15-2.27-1.53-.88-.38-1.81-.57-2.79-.57h-17.69v14.35Z"
        />
        <path
          fill="#fff"
          d="m116.74,2.74c0-.73-.26-1.36-.77-1.89-.51-.53-1.13-.79-1.86-.79s-1.36.27-1.89.79-.79,1.16-.79,1.89v26.38L84.51.87s-.03-.03-.05-.04c0,0,0,0,0,0-.02-.02-.04-.03-.06-.05-.1-.09-.2-.18-.3-.26-.42-.31-.9-.47-1.45-.48,0,0-.01,0-.02,0,0,0,0,0,0,0-.01,0-.02,0-.03,0-.04,0-.07,0-.1,0-.68,0-1.26.23-1.76.7-.42.4-.67.88-.77,1.44,0,.02,0,.05,0,.07-.02.12-.04.24-.04.37,0,.02,0,.03,0,.05,0,.02,0,.03,0,.05v32.98c0,.73.26,1.36.79,1.89.53.53,1.16.79,1.89.79s1.35-.26,1.86-.79c.51-.53.77-1.16.77-1.89V9.34l26.92,28.23c.4.42.88.67,1.43.77.03,0,.07,0,.1.01.11.02.23.04.35.04.02,0,.03,0,.05,0,.01,0,.03,0,.04,0,.08,0,.15-.02.22-.02.09,0,.18,0,.27-.03.06-.01.12-.03.19-.05.11-.03.22-.06.33-.1,0,0,.02,0,.02-.01.29-.12.55-.29.8-.52.53-.5.8-1.12.8-1.86,0-.02,0-.03,0-.05,0-.02,0-.03,0-.05V2.74Z"
        />
        <path
          fill="#fff"
          d="m278.01,2.74c0-.73-.26-1.36-.77-1.89-.51-.53-1.13-.79-1.86-.79s-1.36.27-1.89.79-.79,1.16-.79,1.89v26.38L245.78.87s-.03-.03-.05-.04c0,0,0,0,0,0-.02-.02-.04-.03-.06-.05-.1-.09-.2-.18-.3-.26-.42-.31-.9-.47-1.45-.48,0,0-.01,0-.02,0,0,0,0,0,0,0-.01,0-.02,0-.03,0-.04,0-.07,0-.1,0-.68,0-1.26.23-1.76.7-.42.4-.67.88-.77,1.44,0,.02,0,.05,0,.07-.02.12-.04.24-.04.37,0,.02,0,.03,0,.05,0,.02,0,.03,0,.05v32.98c0,.73.26,1.36.79,1.89.53.53,1.16.79,1.89.79s1.35-.26,1.86-.79c.51-.53.77-1.16.77-1.89V9.34l26.92,28.23c.4.42.88.67,1.43.77.03,0,.07,0,.1.01.11.02.23.04.35.04.02,0,.03,0,.05,0,.01,0,.03,0,.04,0,.08,0,.15-.02.22-.02.09,0,.18,0,.27-.03.06-.01.12-.03.19-.05.11-.03.22-.06.33-.1,0,0,.02,0,.02-.01.29-.12.55-.29.8-.52.53-.5.8-1.12.8-1.86,0-.02,0-.03,0-.05,0-.02,0-.03,0-.05V2.74Z"
        />
        <path
          fill={`url(#${gradientId})`}
          d="m74.87,37.3c.32-.4.51-.85.55-1.36,0-.02,0-.04,0-.06,0-.06.02-.12.02-.18,0-.04-.01-.08-.01-.13,0-.12-.01-.24-.03-.36,0-.03,0-.05-.02-.08-.05-.25-.12-.49-.25-.73L57.53,1.42s-.02-.02-.02-.04c-.24-.47-.6-.83-1.09-1.09-.41-.21-.83-.3-1.26-.3-.43,0-.85.08-1.26.3-.49.26-.85.62-1.09,1.09,0,.01-.02.02-.02.04l-17.6,32.98c-.34.65-.4,1.33-.18,2.04.22.71.66,1.24,1.3,1.58.65.34,1.32.39,2.02.16.7-.23,1.22-.67,1.56-1.32l15.27-28.61,13.2,24.74h-13.2c-.73,0-1.36.26-1.89.79-.53.53-.79,1.16-.79,1.89s.26,1.35.79,1.86c.53.51,1.16.77,1.89.77h17.59s.04,0,.07,0c.14,0,.28-.01.42-.04.03,0,.05,0,.08-.02.12-.03.24-.06.36-.1.06-.02.13-.05.19-.08.04-.02.09-.03.13-.06.05-.02.08-.06.13-.08.06-.04.12-.07.18-.11.09-.07.18-.14.27-.21.02-.02.05-.04.07-.06.03-.03.05-.06.07-.09.05-.06.1-.11.15-.17Z"
        />
      </g>
    </svg>
  );
};

export default LanternPrimaryLogoWhite;
