import React from 'react';
import styles from './CommonReportingPeriods.module.scss';
import {CommonReportingPeriodsProps} from '../../../../../types';
import {valueFormat} from '../../../../../utils';

const CommonReportingPeriods = ({periods}: CommonReportingPeriodsProps) => {
  return (
    <div className={styles.wrapper}>
      {periods.map(period => (
        <div className={styles.period} key={period.value}>
          {valueFormat(period.value, period.format).value}
        </div>
      ))}
    </div>
  );
};

export default CommonReportingPeriods;
