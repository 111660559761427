import React from 'react';
import classnames from 'classnames';
import styles from './ReportingTablePaddingCell.module.scss';

type ReportingTablePaddingCellProps = {
  classNames?: string;
};

const ReportingTablePaddingCell = ({
  classNames,
}: ReportingTablePaddingCellProps) => {
  return (
    <td className={classnames(styles.cell)}>
      <div
        className={classnames(styles.cellInnerWrapper, {
          [`${classNames}`]: !!classNames,
        })}
      ></div>
    </td>
  );
};

export default ReportingTablePaddingCell;
