export const INDUSTRY = [
  {label: 'Agriculture', key: 'Global.Agriculture'},
  {label: 'Chemials', key: 'Global.Chemials'},
  {label: 'Defence', key: 'Global.Defence'},
  {label: 'Energy Equipment & Service', key: 'Global.EnergyEquipmentService'},
  {label: 'Food', key: 'Global.Food'},
  {label: 'Marine', key: 'Global.Marine'},
  {label: 'Mining', key: 'Global.Mining'},
];
export const SECTOR = [
  {label: 'Food', key: 'Global.Food'},
  {label: 'IT/Software', key: 'Global.IT'},
  {label: 'Marine', key: 'Global.Marine'},
  {label: 'Metal', key: 'Global.Metal'},
];
export const INSTRUMENT_TYPE = [
  'a-shares',
  'b-shares',
  'debt',
  'equity',
  'other',
];
