import React, {useCallback, useMemo, useState} from 'react';
import {useTooltip} from '../../../hooks';
import {Position} from '../../../context/TooltipContext';
import styles from './Tooltip.module.scss';
import classnames from 'classnames';

interface TooltipStyles {
  top?: number;
  left?: number;
  bottom?: number;
  right?: number;
}

const Tooltip: React.FC = () => {
  const {show, content, position, target, className, hasArrow} = useTooltip();
  const [tooltipSize, setTooltipSize] = useState<{
    width: number;
    height: number;
  }>({width: 0, height: 0});

  const style = useMemo<TooltipStyles>(() => {
    if (!target) return {};
    const targetBoundingBox = target.getBoundingClientRect();
    if (position === Position.Top)
      return {
        left:
          targetBoundingBox.left +
          targetBoundingBox.width / 2 -
          tooltipSize.width / 2,
        top: targetBoundingBox.top - tooltipSize.height - 7,
      };
    if (position === Position.Left) {
      return {
        top:
          targetBoundingBox.top +
          targetBoundingBox.height / 2 -
          tooltipSize.height / 2,
        left: targetBoundingBox.left - tooltipSize.width - 7,
      };
    }
    if (position === Position.Right) {
      return {
        top:
          targetBoundingBox.top +
          targetBoundingBox.height / 2 -
          tooltipSize.height / 2,
        left: targetBoundingBox.right + 7,
      };
    }
    return {
      top: targetBoundingBox.bottom + 7,
      left:
        targetBoundingBox.left +
        targetBoundingBox.width / 2 -
        tooltipSize.width / 2,
    };
  }, [target, position, tooltipSize]);

  const measuredRef = useCallback(
    (node: HTMLElement | null) => {
      if (node !== null) {
        setTooltipSize({
          width: node.getBoundingClientRect().width,
          height: node.getBoundingClientRect().height,
        });
      }
    },
    [show, target]
  );

  const classNames = {
    [styles.wrapper]: true,
    [styles.isShown]: show,
    [styles.hideArrow]: !hasArrow,
    [styles.top]: position === Position.Top,
    [styles.bottom]: position === Position.Bottom,
    [styles.left]: position === Position.Left,
    [styles.right]: position === Position.Right,
    [className || '']: !!className,
  };

  return (
    <div
      ref={measuredRef}
      style={{...style}}
      className={classnames(classNames)}
    >
      {content}
    </div>
  );
};

export default Tooltip;
