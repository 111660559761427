import React, {RefObject, useMemo} from 'react';
import {
  DateRange,
  DateRangePresetTypes,
  Func,
  MaskedDateInputMask,
  MaskedDateInputPattern,
} from '../../../../../types';
import MaskedDateInput from '../../../masked-inputs/masked-date-input/MaskedDateInput';
import styles from './ScrollableDateRangePicker.module.scss';
import {formatWithLocale, parseWithLocale} from '../../../../../utils';
import {useTranslation} from 'react-i18next';
import {Calendar} from '../../../../global';

type ScrollableDateRangePickerProps = {
  initialDateRange: DateRange;
  dateRange: DateRange | undefined;
  dateFormat: string;
  setDateRange: Func<[DateRange | undefined], void>;
  setSelectedPreset: Func<[DateRangePresetTypes], void>;
  selectedPreset: DateRangePresetTypes;
  dateInputValid: {
    from: boolean;
    to: boolean;
  };
  setDateInputsValid: Func<
    [
      {
        from: boolean;
        to: boolean;
      }
    ],
    void
  >;
};
const ScrollableDateRangePicker = ({
  initialDateRange,
  dateRange,
  dateFormat,
  setDateRange,
  setSelectedPreset,
  dateInputValid,
  setDateInputsValid,
  selectedPreset,
}: ScrollableDateRangePickerProps): JSX.Element => {
  const {t} = useTranslation();
  const initialValue = useMemo(
    () => ({
      from: dateRange?.from
        ? formatWithLocale(dateRange.from as Date, dateFormat)
        : '',
      to: dateRange?.to
        ? formatWithLocale(dateRange?.to as Date, dateFormat)
        : '',
    }),
    [dateRange, dateFormat]
  );

  const dateRangeSelectHandler = (range?: DateRange) => {
    setSelectedPreset(DateRangePresetTypes.custom);
    if (range && range.from !== range.to) {
      setDateRange(range);
      return;
    }

    setDateRange({
      from: dateRange?.from,
      to: undefined,
    });
  };

  const getOnAcceptHandler =
    (inputType: 'from' | 'to') =>
    (value: string, ref: RefObject<HTMLInputElement>) => {
      const isEmptyInput = value === 'dd-MM-yyyy';

      if (inputType === 'from' && isEmptyInput) {
        setDateRange({from: undefined, to: dateRange?.to});
        return;
      }

      if (inputType === 'to' && isEmptyInput) {
        setDateRange({from: dateRange?.from, to: undefined});
        return;
      }

      const placeholderChars = ['D', 'M', 'Y'];
      const isValidDate = !placeholderChars.some(item => value.includes(item));

      if (isValidDate) {
        const date: Date | undefined = parseWithLocale(
          value,
          MaskedDateInputPattern.dayMonthYearDashBetween
        );

        const setAcceptedState = (
          from: Date | undefined,
          to: Date | undefined
        ) => {
          setDateRange({from, to});
          setDateInputsValid({
            from: true,
            to: true,
          });
          if (document.activeElement === ref.current) {
            setSelectedPreset(DateRangePresetTypes.custom);
          }
        };

        if (
          inputType === 'from' &&
          dateRange?.to !== undefined &&
          date < dateRange.to
        ) {
          setAcceptedState(date, dateRange?.to);
          return;
        }

        if (
          inputType === 'from' &&
          dateRange?.to !== undefined &&
          date > dateRange.to
        ) {
          setDateInputsValid({
            from: false,
            to: true,
          });
          return;
        }

        if (
          inputType === 'to' &&
          dateRange?.from !== undefined &&
          dateRange.from < date
        ) {
          setAcceptedState(dateRange?.from, date);
          return;
        }
      }

      if (inputType === 'from') {
        setDateInputsValid({
          from: false,
          to: true,
        });
        return;
      }

      if (inputType === 'to') {
        setDateInputsValid({
          from: true,
          to: false,
        });
        return;
      }
    };

  return (
    <div className={styles.wrapper}>
      <div className={styles.dateInputWrapper}>
        <MaskedDateInput
          label={t('Global.From')}
          initialValue={initialValue}
          pattern={{
            mask: MaskedDateInputMask.dayMonthYearDashBetween,
            date: MaskedDateInputPattern.dayMonthYearDashBetween,
          }}
          onAccept={getOnAcceptHandler('from')}
          invalid={!dateInputValid.from}
        />
        <MaskedDateInput
          label={t('Global.To')}
          initialValue={initialValue}
          pattern={{
            mask: MaskedDateInputMask.dayMonthYearDashBetween,
            date: MaskedDateInputPattern.dayMonthYearDashBetween,
          }}
          onAccept={getOnAcceptHandler('to')}
          invalid={!dateInputValid.to}
        />
      </div>

      <Calendar
        selectedPreset={selectedPreset}
        range={dateRange}
        setRange={dateRangeSelectHandler}
        availableRange={initialDateRange}
      />
    </div>
  );
};

export default ScrollableDateRangePicker;
