import React, {useRef, useState} from 'react';
import {DndProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import {
  ButtonStyle,
  CustomizeOrderOption,
  CustomizeOrderPanelProps,
} from '../../../types';
import DragPanel from './components/drag-panel/DragPanel';
import styles from './CustomizeOrderPanel.module.scss';
import {Button} from '../index';
import classnames from 'classnames';
import {useTranslation} from 'react-i18next';

const CustomizeOrderPanel: React.FC<CustomizeOrderPanelProps> = ({
  id,
  options,
  onChange,
  label,
  countLabel,
  onSave,
  onCancel,
  withoutActionButtons,
  className,
}) => {
  const {t} = useTranslation();
  const [state, setState] = useState<CustomizeOrderOption[]>(options);
  const initialOrder = useRef<CustomizeOrderOption[]>(options);

  const onMoveCard = (
    dragIndex: number,
    hoverIndex: number,
    parentIndex: number | undefined
  ) => {
    const move = (array: any[], from: number, to: number) => {
      array.splice(to, 0, array.splice(from, 1)[0]);
    };
    setState((prev: CustomizeOrderOption[]) => {
      const newState = [...prev];
      if (parentIndex !== undefined) {
        move(newState[parentIndex].subItems || [], dragIndex, hoverIndex);
        return newState;
      }
      move(newState, dragIndex, hoverIndex);
      return newState;
    });
  };

  const onDropCard = () => {
    onChange(state);
  };

  const handleCancel = () => {
    onChange(initialOrder.current);
    setState(initialOrder.current);
    onCancel();
  };

  const handleSave = () => {
    initialOrder.current = state;
    onSave();
  };

  return (
    <div id={id} className={classnames(styles.wrapper, className)}>
      {!withoutActionButtons && (
        <div className={styles.top}>
          <div className={styles.title}>{label}</div>
          <div className={styles.buttons}>
            <Button
              id={`${id}-save`}
              onClick={handleSave}
              text={t('Global.Save')}
            />
            <Button
              id={`${id}-cancel`}
              onClick={handleCancel}
              styleType={ButtonStyle.Secondary}
              text={t('Global.Cancel')}
            />
          </div>
        </div>
      )}
      <div className={styles.count}>
        {options.length} {countLabel}
      </div>
      <DndProvider backend={HTML5Backend}>
        <div className={styles.options}>
          <div className={styles.optionsInner}>
            {state.map((item, index) => (
              <DragPanel
                key={item.id}
                index={index}
                moveCard={onMoveCard}
                dropCard={onDropCard}
                id={item.id}
                label={item.label}
                subItems={item.subItems}
                groupLabel={t(item.groupLabel as string)}
              />
            ))}
          </div>
        </div>
      </DndProvider>
    </div>
  );
};

export default CustomizeOrderPanel;
