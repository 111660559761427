import {useAppSelector} from './useReduxHooks';
import {userProfileSelector} from '../selectors';

const useUserData = () => {
  const state = useAppSelector(state => state);
  const data = userProfileSelector(state);
  return data;
};

export default useUserData;
