import React, {useEffect, useMemo, useState} from 'react';
import {DealTeamWidgetProps, PaginationType} from '../../../types';
import styles from './DealTeamWidget.module.scss';
import {IconButton, Pagination} from '../../global';
import classnames from 'classnames';
import {useTranslation} from 'react-i18next';

const DealTeamWidget: React.FC<DealTeamWidgetProps> = ({
  label,
  data,
  onEditHandler,
}) => {
  const {t} = useTranslation();
  const [page, setPage] = useState(0);

  const maxPages = Math.ceil(data.length / 3);

  const currentPageData = useMemo(
    () => data.slice(page * 3, page * 3 + 3),
    [data, page]
  );

  useEffect(() => {
    if (page > maxPages - 1) {
      // Set to last available page or to first page
      setPage(maxPages - 1 < 0 ? 0 : maxPages - 1);
    }
  }, [maxPages, page]);

  return (
    <div className={styles.wrapper}>
      <IconButton
        onClick={onEditHandler}
        icon={'pencil'}
        className={styles.editButton}
      />
      <p className={styles.title}>{label}</p>
      <div className={styles.teamListLabel}>
        {t('Portfolio.OwnershipControl.BoardSeat')}
      </div>
      <div className={styles.teamList}>
        {!currentPageData.length && (
          <div className={styles.teamItem}>
            {t('Portfolio.DealTeamWidget.EditAddLabel')}{' '}
            <div className={styles.teamIndicator} />
          </div>
        )}
        {currentPageData.map((item, idx) => (
          <div key={idx} className={styles.teamItem}>
            {item.member}
            <div
              className={classnames(
                styles.teamIndicator,
                item.boardSeat && styles.active
              )}
            />
          </div>
        ))}
      </div>
      <div className={styles.paginationWrapper}>
        {data.length > 3 ? (
          <Pagination
            type={PaginationType.Numbers}
            pages={maxPages}
            onChange={page => setPage(page - 1)}
            page={page + 1}
          />
        ) : null}
      </div>
    </div>
  );
};

export default DealTeamWidget;
