import axios from 'axios';
import config from '../config';
import {UpdateLayoutPropertyPayload} from '../types/userLayoutProperty';

export const updateLayoutProperty = async (
  token: string,
  payload: UpdateLayoutPropertyPayload
) => {
  const result = await axios.post(
    `${config.APP_URI}/user-profile-layout-property/save`,
    payload,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );
  return result.status === 204;
};
