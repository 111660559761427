import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import {acquireAuthResult, msalInstance} from '../../index';
import {
  AskAIQueryPayload,
  DocumentExplorer,
  DocumentTag,
  DocumentTransactionData,
  DocumentTransactionsByEntityTagsPayload,
  FileEditFormOptions,
  FileEditFormPayload,
  FileItem,
  FormStatus,
  HeaderMapping,
  ReportingPeriodsData,
} from '../../types';
import {
  setDocumentExplorerData,
  getFileEditOptionsAction,
  setDocumentExplorerError,
  setDocumentExplorerFormError,
  setDocumentExplorerFormStatus,
  setDocumentExplorerFileTransactions,
  setFileEditOptionsData,
  putFileEditUpdateAction,
  updateFileData,
  updateDocumentExplorerCustomTags,
  updateDocumentExplorerDocumentsCount,
  postAskAIQueryAction,
  setDocumentExplorerIsAskAIPending,
  setDocumentExplorerAskAIData,
  getDocumentTransactionsByEntityTagsAction,
  setIsTransactionsRequestPending,
  getDocumentReportingPeriodsByEntityTagsAction,
  setDocumentExplorerFileReportingPeriods,
  setIsReportingPeriodsRequestPending,
} from '../../reducers/documentExplorerSlice';
import {
  getDocumentExplorerData,
  getDocumentReportingPeriodsByEntityTags,
  getDocumentTransactionsByEntityTags,
  getFileEditFormOptions,
  postAskAiQuery,
  putFileEditUpdate,
} from '../../api/getDocumentExplorer';
import {PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../../store';
import {responseErrorResolver} from '../../utils';
import i18n from '../../i18n';

export const documentExplorerSaga = function* documentExplorerSaga() {
  try {
    const {accessToken} = yield call(acquireAuthResult, msalInstance);
    const response: DocumentExplorer = yield call(
      getDocumentExplorerData,
      accessToken
    );
    yield put(setDocumentExplorerData(response));
  } catch (err) {
    console.warn(err);
  }
};

export const getFileEditOptionsSaga = function* getFileEditOptionsSaga({
  payload,
}: PayloadAction<{id: string}>): any {
  yield call(documentExplorerErrorHelperSaga);
  try {
    yield put(setFileEditOptionsData(null));
    yield put(setDocumentExplorerFormStatus(FormStatus.Pending));
    const {accessToken} = yield call(acquireAuthResult, msalInstance);
    const response: FileEditFormOptions = yield call(
      getFileEditFormOptions,
      accessToken,
      payload.id
    );
    yield put(setFileEditOptionsData(response));
    yield put(setDocumentExplorerFormStatus(FormStatus.Ready));
  } catch (err) {
    console.warn(err);
    yield call(
      documentExplorerResponseErrorHelperSaga,
      err,
      i18n.t('DocumentExplorer.GetFileEditOptions.Error')
    );
  }
};

export const putFileEditUpdateSaga = function* putFileEditUpdateSaga({
  payload,
}: PayloadAction<FileEditFormPayload>): any {
  yield call(documentExplorerErrorHelperSaga);
  try {
    yield put(setDocumentExplorerFormStatus(FormStatus.Pending));
    const {accessToken} = yield call(acquireAuthResult, msalInstance);
    const response: {
      fileItem: FileItem;
      customTags: Array<DocumentTag>;
      documentCount: number;
    } = yield call(putFileEditUpdate, accessToken, payload);
    yield put(updateFileData(response.fileItem));
    yield put(updateDocumentExplorerCustomTags(response.customTags));
    yield put(updateDocumentExplorerDocumentsCount(response.documentCount));
    yield put(setDocumentExplorerFormStatus(FormStatus.Success));
    yield put(setFileEditOptionsData(null));
  } catch (err) {
    console.warn(err);
    yield call(
      documentExplorerResponseErrorHelperSaga,
      err,
      i18n.t('DocumentExplorer.PutFileEditUpdate.Error')
    );
  }
};

export const postAskAIQuerySaga = function* postAskAIQuerySaga({
  payload,
}: PayloadAction<AskAIQueryPayload>): any {
  yield call(documentExplorerErrorHelperSaga);
  try {
    yield put(
      setDocumentExplorerAskAIData({
        summary: null,
        data: null,
      })
    );
    yield put(setDocumentExplorerIsAskAIPending(true));
    const {accessToken} = yield call(acquireAuthResult, msalInstance);
    const response: any = yield call(postAskAiQuery, accessToken, payload);
    yield put(setDocumentExplorerAskAIData(response));
    yield put(setDocumentExplorerIsAskAIPending(false));
  } catch (err) {
    console.warn(err);
    yield call(
      documentExplorerResponseErrorHelperSaga,
      err,
      i18n.t('DocumentExplorer.AskAIQuery.Error')
    );
  }
};

const getDocumentTransactionsByEntityTagsSaga =
  function* getDocumentTransactionsByEntityTagsSaga({
    payload,
  }: PayloadAction<DocumentTransactionsByEntityTagsPayload>): any {
    yield call(documentExplorerErrorHelperSaga);
    try {
      yield put(setIsTransactionsRequestPending(true));
      const {accessToken} = yield call(acquireAuthResult, msalInstance);
      const response: {
        headerMapping: HeaderMapping[];
        data: DocumentTransactionData[];
      }[] = yield call(
        getDocumentTransactionsByEntityTags,
        accessToken,
        payload.entityTags
      );
      console.warn('response', response);
      yield put(setDocumentExplorerFileTransactions(response[0]));
      yield put(setIsTransactionsRequestPending(false));
    } catch (err) {
      console.warn(err);
      yield call(
        documentExplorerResponseErrorHelperSaga,
        err,
        i18n.t('DocumentExplorer.GetDocumentTransactionsByEntityTags.Error')
      );
      yield put(setIsTransactionsRequestPending(false));
    }
  };

const getDocumentReportingPeriodsByEntityTagsSaga =
  function* getDocumentReportingPeriodsByEntityTagsSaga({
    payload,
  }: PayloadAction<DocumentTransactionsByEntityTagsPayload>): any {
    yield call(documentExplorerErrorHelperSaga);
    try {
      yield put(setIsReportingPeriodsRequestPending(true));
      const {accessToken} = yield call(acquireAuthResult, msalInstance);
      const response: {
        headerMapping: HeaderMapping[];
        data: ReportingPeriodsData[];
      }[] = yield call(
        getDocumentReportingPeriodsByEntityTags,
        accessToken,
        payload.entityTags
      );

      yield put(setDocumentExplorerFileReportingPeriods(response[0]));
      yield put(setIsReportingPeriodsRequestPending(false));
    } catch (err) {
      console.warn(err);
      yield call(
        documentExplorerResponseErrorHelperSaga,
        err,
        i18n.t('DocumentExplorer.GetDocumentReportingPeriodsByEntityTags.Error')
      );
      yield put(setIsReportingPeriodsRequestPending(false));
    }
  };

const documentExplorerErrorHelperSaga =
  function* documentExplorerErrorHelperSaga() {
    const error: string | null = yield select(
      (state: RootState) => state.documents.documentExplorer.error
    );
    const formError: string | null = yield select(
      (state: RootState) => state.documents.documentExplorer.formError
    );
    if (error) {
      yield put(setDocumentExplorerError(null));
    }
    if (formError) {
      yield put(setDocumentExplorerFormError(null));
    }
  };

const documentExplorerResponseErrorHelperSaga =
  function* documentExplorerResponseErrorHelperSaga(
    err: any,
    placeholder: string
  ) {
    yield put(setDocumentExplorerFormStatus(FormStatus.Ready));
    const {error, formError} = responseErrorResolver(err, placeholder);
    yield put(setDocumentExplorerError(error));
    yield put(setDocumentExplorerFormError(formError));
  };

export const documentExplorerSagaWatcher =
  function* documentExplorerSagaWatcher() {
    yield all([
      takeEvery(getFileEditOptionsAction.type, getFileEditOptionsSaga),
      takeEvery(putFileEditUpdateAction.type, putFileEditUpdateSaga),
      takeEvery(
        getDocumentTransactionsByEntityTagsAction.type,
        getDocumentTransactionsByEntityTagsSaga
      ),
      takeEvery(
        getDocumentReportingPeriodsByEntityTagsAction.type,
        getDocumentReportingPeriodsByEntityTagsSaga
      ),
      takeLatest(postAskAIQueryAction.type, postAskAIQuerySaga),
    ]);
  };
