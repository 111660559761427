import React from 'react';
import {useFormState} from '../../../hooks';
import {
  ButtonStyle,
  SaveChartFormProps,
  SaveChartFormState,
  StyleVariant,
} from '../../../types';
import {Button, Input, Modal} from '../../';
import styles from './SaveChartForm.module.scss';
import {saveChartFormSchema} from '../../../constants';
import {useTranslation} from 'react-i18next';

const SaveChartForm: React.FC<SaveChartFormProps> = ({
  data,
  isOpen,
  onSubmit,
  onClose,
  resetErrors,
  inProgress,
  error,
  formError,
}) => {
  const {t} = useTranslation();
  const {formState, validate, errors, handleChange} =
    useFormState<SaveChartFormState>(data, saveChartFormSchema);

  const handleSubmit = () => {
    onSubmit(formState);
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (error || formError) {
      resetErrors();
    }
    handleChange(e);
  };

  return (
    <Modal
      title={`${t(formState.id ? 'Global.Update' : 'Global.Save')} ${t(
        'SaveChartForm.Title.CopyChart'
      )}`}
      isOpen={isOpen}
      onClose={onClose}
      variant={StyleVariant.Primary}
      footerChildren={
        <div className={styles.actionButtons}>
          <Button
            disabled={formState.name.length < 1 || inProgress}
            text={t(`${formState.id ? 'Global.Update' : 'Global.Save'}`)}
            onClick={handleSubmit}
            loading={inProgress}
            className={styles.lightThemePrimaryButton}
          />
          <Button
            styleType={ButtonStyle.Secondary}
            text={t('Global.Cancel')}
            onClick={onClose}
            disabled={inProgress}
            className={styles.lightThemeSecondaryButton}
          />
        </div>
      }
    >
      <div className={styles.wrapper}>
        {error ? <div className={styles.errorMessage}>{error}</div> : null}
        <div className={styles.input}>
          <Input
            label={t(
              'Portfolio.MonitoringDashboard.SaveChartForm.InputLabel.Chart'
            )}
            errorMessage={t(errors.name.message) || formError?.name || ''}
            validate={validate}
            value={formState.name}
            onChange={handleOnChange}
            variant={StyleVariant.Primary}
            name={'name'}
          />
        </div>
      </div>
    </Modal>
  );
};

export default SaveChartForm;
