import React, {useEffect, useRef} from 'react';
import classnames from 'classnames';
import {Func} from '../../../types/index';
import {Icon} from '../index';
import styles from './SearchInput.module.scss';

interface SearchInputProps {
  onChange?: Func<[string], void>;
  className?: string;
  value?: string;
  placeholder?: string;
  isLoading?: boolean;
  isActive?: boolean;
  cancelSearch?: Func<[], void>;
  isFocused: boolean;
  onChangeFocus: Func<[boolean], void>;
}

const SearchInput: React.FC<SearchInputProps> = ({
  isActive,
  onChange = null,
  className = '',
  value = '',
  placeholder = 'Search',
  cancelSearch,
  onChangeFocus,
  isFocused,
  isLoading,
}): JSX.Element => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!isActive && !(document.activeElement === inputRef.current)) {
      onChangeFocus(false);
    }
  }, [isActive]);

  const setSearchValue = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (onChange) {
      onChange(e.target.value);
    }
  };

  const handleClearValue = (): void => {
    if (onChange) {
      onChange('');
    }
  };

  const handleClose = (): void => {
    if (cancelSearch && !isLoading) {
      onChangeFocus(false);
      cancelSearch();
    }
  };

  return (
    <div
      id="search-wrapper"
      className={classnames(
        styles.wrapper,
        className,
        isFocused && styles.focused,
        isActive && styles.active
      )}
    >
      <label htmlFor="search-input" className={styles.icon}>
        <Icon
          name={'search'}
          className={classnames(styles.searchIcon, isFocused && styles.focused)}
        />
      </label>

      <input
        id="search-input"
        ref={inputRef}
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={setSearchValue}
        className={classnames(isFocused && styles.focused)}
        onFocus={() => onChangeFocus(true)}
        onBlur={() => !value && onChangeFocus(false)}
      />

      {value?.length > 0 && (
        <button onClick={handleClearValue} className={styles.buttonClear}>
          Clear
        </button>
      )}

      <button onClick={handleClose} className={styles.buttonClose}>
        <Icon name={'cross'} />
      </button>
    </div>
  );
};

export default SearchInput;
