import {createAction, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {FeeAnalysisData, FundOperational, InsightsData} from '../types';

export const updateFeeAnalysisDataAction = createAction(
  'fund-details-operational/update-fee-analysis',
  (
    fundId: string,
    from: string,
    to: string,
    includeManagementFees: boolean,
    entityId?: string
  ) => {
    return {
      payload: {
        fundId,
        from,
        to,
        includeManagementFees,
        entityId,
      },
    };
  }
);

export const updateInsightsDataAction = createAction(
  'fund-details-operational/update-insights',
  (fundId: string, date: string) => {
    return {
      payload: {
        fundId,
        date,
      },
    };
  }
);

interface FundDetailsOperationalData {
  data: FundOperational | null;
  feeAnalysisUpdateInProgress: boolean;
}

const initialState: FundDetailsOperationalData = {
  data: null,
  feeAnalysisUpdateInProgress: false,
};

const fundDetailsOperationalSlice = createSlice({
  name: 'fund-details-operational',
  initialState,
  reducers: {
    setFundOperationalData(
      state: FundDetailsOperationalData,
      action: PayloadAction<FundOperational>
    ) {
      state.data = action.payload;
    },
    clearFundOperationalData(state: FundDetailsOperationalData) {
      state.data = null;
    },
    updateFeeAnalysisData(
      state: FundDetailsOperationalData,
      action: PayloadAction<FeeAnalysisData>
    ) {
      if (state.data) {
        state.data.feeAnalysis = action.payload;
        state.feeAnalysisUpdateInProgress = false;
      }
    },
    setFeeAnalysisUpdateInProgress(
      state: FundDetailsOperationalData,
      action: PayloadAction<boolean>
    ) {
      state.feeAnalysisUpdateInProgress = action.payload;
    },
    updateInsightsData(
      state: FundDetailsOperationalData,
      action: PayloadAction<InsightsData>
    ) {
      if (state.data) {
        state.data.insights = action.payload;
      }
    },
  },
});

export const {
  setFundOperationalData,
  clearFundOperationalData,
  updateFeeAnalysisData,
  updateInsightsData,
  setFeeAnalysisUpdateInProgress,
} = fundDetailsOperationalSlice.actions;

export default fundDetailsOperationalSlice.reducer;
