import React from 'react';
import {
  FlexHAlign,
  HeaderMapping,
  TemplateConfigReportingGroupItemRow,
} from '../../../../types';
import {ColumnDef, createColumnHelper} from '@tanstack/react-table';
import Th from '../cells/th/Th';
import Td from '../cells/td/Td';
import ChevronLinkCell from '../cells/chevron-link-cell/ChevronLinkCell';
import routePaths from '../../../../routePaths';
import styles from './TemplateConfigReportingTable.module.scss';

const genTemplateConfigreportingTableColumnDefs = (
  headerMapping: HeaderMapping[]
): ColumnDef<TemplateConfigReportingGroupItemRow, any>[] => {
  const columnHelper =
    createColumnHelper<TemplateConfigReportingGroupItemRow>();

  return [
    columnHelper.accessor('template', {
      id: 'template',
      header: props => (
        <Th {...props} headerMapping={headerMapping} align={FlexHAlign.start} />
      ),
      cell: props => (
        <Td className={styles.cell} {...props} align={FlexHAlign.start} />
      ),
    }),
    columnHelper.accessor('reportingType', {
      id: 'reportingType',
      header: props => (
        <Th {...props} headerMapping={headerMapping} align={FlexHAlign.start} />
      ),
      cell: props => (
        <Td className={styles.cell} {...props} align={FlexHAlign.start} />
      ),
    }),
    columnHelper.accessor(row => row.lastChanged?.value, {
      id: 'lastChanged',
      header: props => (
        <Th {...props} headerMapping={headerMapping} align={FlexHAlign.start} />
      ),
      cell: props => (
        <Td
          className={styles.cell}
          {...props}
          align={FlexHAlign.start}
          nestedAccessorFn={original => original.lastChanged}
        />
      ),
    }),
    columnHelper.accessor('changedBy', {
      id: 'changedBy',
      header: props => (
        <Th {...props} headerMapping={headerMapping} align={FlexHAlign.start} />
      ),
      cell: props => (
        <Td className={styles.cell} {...props} align={FlexHAlign.start} />
      ),
    }),
    columnHelper.accessor('status', {
      id: 'status',
      header: props => (
        <Th {...props} headerMapping={headerMapping} align={FlexHAlign.start} />
      ),
      cell: props => (
        <Td className={styles.cell} {...props} align={FlexHAlign.start} />
      ),
    }),
    columnHelper.display({
      id: 'linkTo',
      header: props => (
        <Th
          {...props}
          align={FlexHAlign.center}
          width={'32px'}
          headerMapping={headerMapping}
        />
      ),
      cell: props => (
        <Td
          className={styles.cell}
          {...props}
          align={FlexHAlign.center}
          canHide={false}
        >
          <ChevronLinkCell
            linkTo={`${routePaths.ADMIN_DATA_COLLECTION_TEMPLATE_CONFIGURATION}/${props.row.original.id}`}
          />
        </Td>
      ),
    }),
  ];
};

export default genTemplateConfigreportingTableColumnDefs;
