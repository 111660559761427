import React, {useMemo, useState} from 'react';
import {CapitalWidgetType, StyleVariant} from '../../../types';
import ListItem from './component/list-item/ListItem';
import Chart from './component/chart/Chart';
import styles from './CapitalWidget.module.scss';
import classnames from 'classnames';

interface LegendDataType {
  name: string;
  value: number;
  format: string;
  color?: string;
}

interface LegendType extends LegendDataType {
  children?: LegendDataType[];
}

interface CapitalWidgetProps extends CapitalWidgetType {
  variant?: StyleVariant;
}

const CapitalWidget: React.FC<CapitalWidgetProps> = ({
  label,
  data,
  currency,
  variant = StyleVariant.Primary,
}) => {
  const [activeName, setActiveName] = useState('');
  const colors = useMemo(
    () => [
      '--colors-lan-violet',
      '--colors-turbo',
      '--colors-green-haze',
      '--colors-pacific-blue',
      '--colors-flirt',
      '--colors-chelsea-gem',
      '--colors-pigment-indigo',
      '--colors-revolver',
    ],
    []
  );

  const chartData = useMemo(
    () =>
      data.timeSeries?.data
        ?.filter(item => !item.isNotInChart)
        .map((item, idx) => ({
          ...item,
          fill: `rgb(var(${colors[idx]}))`,
          stroke: 'none',
        })),
    [data.timeSeries, colors]
  );

  const legendData = useMemo<LegendType[]>(() => {
    const result: LegendType[] = [];
    let colorIdx = -1;
    data.timeSeries?.data?.forEach(item => {
      if (!item.isNotInChart) {
        colorIdx++;
      }
      if (item.grouping) {
        const groupIdx = result.findIndex(
          item2 => item2.name === item.grouping
        );
        if (groupIdx > -1) {
          const value =
            item.grouping === item.name ? item.value : result[groupIdx].value;
          const children =
            item.grouping === item.name
              ? [...result[groupIdx].children!]
              : [
                  ...result[groupIdx].children!,
                  {...item, color: !item.isNotInChart ? colors[colorIdx] : ''},
                ];

          result[groupIdx] = {
            ...result[groupIdx],
            value,
            children,
          };
          return;
        }
        const children =
          item.grouping === item.name
            ? []
            : [{...item, color: !item.isNotInChart ? colors[colorIdx] : ''}];

        result.push({
          name: item.grouping,
          format: item.format,
          value: item.name === item.grouping ? item.value : 0,
          color:
            item.name === 'Total Distributed' ? colors[colorIdx] : undefined,
          children,
        });
        return;
      }
      result.push({...item, color: !item.isNotInChart ? colors[colorIdx] : ''});
    });
    return result;
  }, [data, colors]);

  return (
    <div
      id={`capital-chart-${label}`}
      className={classnames(styles.wrapper, styles[`wrapper__${variant}`])}
    >
      <div className={styles.columns}>
        <div className={styles.list}>
          <div className={styles.title}>
            <span>{label}</span>
            {currency && <span className={styles.currency}>{currency}</span>}
          </div>
          {legendData.map(item =>
            item.children ? (
              <div key={item.value + item.name} className={styles.group}>
                <ListItem activeName={activeName} {...item} />

                <div className={styles.groupList}>
                  {item.children.map(item => (
                    <ListItem
                      activeName={activeName}
                      key={item.value + item.name}
                      {...item}
                    />
                  ))}
                </div>
              </div>
            ) : (
              <ListItem
                activeName={activeName}
                key={item.value + item.name}
                {...item}
              />
            )
          )}
        </div>
        <div className={styles.chart}>
          <Chart
            onMouseEnter={name => setActiveName(name)}
            onMouseLeave={() => setActiveName('')}
            data={chartData || []}
            currency={currency}
          />
        </div>
      </div>
    </div>
  );
};

export default CapitalWidget;
