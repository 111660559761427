import React, {useRef} from 'react';
import {useTooltip} from '../../../../../hooks';
import {useTranslation} from 'react-i18next';
import {Position} from '../../../../../context/TooltipContext';
import {Icon} from '../../../';
import styles from './ImpactTooltip.module.scss';

const ImpactTooltip: React.FC = () => {
  const {showTooltip, hideTooltip} = useTooltip();
  const wrapperRef = useRef<HTMLDivElement>(null);

  const {t} = useTranslation();

  const handleMouseLeave = () => {
    hideTooltip();
  };

  const handleMouseEnter = (e: React.MouseEvent) => {
    showTooltip({
      content: (
        <div>
          {t('Portfolio.LatestNewsDashboard.ImpactTooltip.Description')}
        </div>
      ),
      position: Position.Top,
      target: e.currentTarget as HTMLElement,
      className: styles.tooltip,
    });
  };

  return (
    <div
      ref={wrapperRef}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={styles.wrapper}
    >
      <Icon className={styles.icon} name="info" />
    </div>
  );
};

export default ImpactTooltip;
