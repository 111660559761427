import React, {FormEvent, Fragment, useMemo} from 'react';
import {z} from 'zod';
import {useFormState, useIsSubmitDisabled} from '../../../hooks';
import {ActivityBadge, Button, Input, SelectInput} from '../../global';
import {
  ActivityBadgeProps,
  ButtonStyle,
  CompanyUser,
  FormStatus,
  StyleVariant,
  UpdateUserFormProps,
  UserRole,
  UserStatus,
} from '../../../types';
import styles from './UpdateUserForm.module.scss';
import {userRoleSelectOptions} from '../../../constants';
import {useTranslation} from 'react-i18next';

const UpdateUserForm: React.FC<UpdateUserFormProps> = ({
  data,
  personaOptions,
  formSchema,
  onSubmit,
  onCancel,
  onClickReInviteUser,
  onClickReactivateUser,
  onClickRemoveUser,
  formStatus,
  isRequestPending,
  isReinvitePending,
}) => {
  const {
    formState,
    setFormState,
    errors,
    validate,
    handleChange,
    isFormStateChanged,
  } = useFormState(data, z.object(formSchema));

  const {t} = useTranslation();

  const {isSubmitDisabled} = useIsSubmitDisabled(
    null,
    errors,
    null,
    isFormStateChanged
  );

  const activityBadgeInfo: ActivityBadgeProps | null = useMemo(() => {
    switch (data.status) {
      case UserStatus.Active:
        return {
          label: 'Global.Active',
          color: 'lan-green',
          textColor: 'black',
        };
      case UserStatus.Pending:
        return {
          label: 'Global.Pending',
          color: 'gray-6',
          textColor: 'white',
        };
      case UserStatus.Removed:
        return {
          label: 'Global.Removed',
          color: 'gray-6',
          textColor: 'white',
        };
      case UserStatus.Locked:
        return {
          label: 'Global.Locked',
          color: 'red',
          textColor: 'white',
        };
      default:
        return null;
    }
  }, [data.status]);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSubmit(formState);
  };

  const isResendInviteEnabled: boolean =
    formState.status === UserStatus.Pending ||
    (formState.status === UserStatus.Removed &&
      !data.registrationDate &&
      formState.role !== UserRole.CompanyAdmin);

  const isReactivateUserEnabled: boolean =
    formState.role !== UserRole.CompanyAdmin &&
    formState.status === UserStatus.Removed &&
    !!data.registrationDate;

  return (
    <form className={styles.wrapper} onSubmit={handleSubmit}>
      <div className={styles.formTop}>
        <div className={styles.formInputs}>
          {formSchema.portfolioCompany && (
            <Fragment>
              <Input
                id="portfolio-company"
                name="portfolioCompany"
                type="text"
                placeholder={t('Global.PortfolioCompany')}
                label={t('Global.PortfolioCompany')}
                disabled={true}
                variant={StyleVariant.Secondary}
                value={(formState as CompanyUser).portfolioCompany || ''}
                onChange={handleChange}
                errorMessage={t(errors.portfolioCompany.message)}
                validate={validate}
                autoComplete="off"
              />
              <div />
            </Fragment>
          )}
          <Input
            id="email"
            name="email"
            type="email"
            placeholder={t('Global.EmailAddress')}
            label={t('Global.EmailAddress')}
            disabled={true}
            variant={StyleVariant.Secondary}
            value={formState.email}
            onChange={handleChange}
            errorMessage={t(errors.email.message)}
            validate={validate}
            autoComplete="off"
          />
          <div className={styles.status}>
            <p className={styles.statusLabel}>{t('Global.Status')}</p>
            {activityBadgeInfo && (
              <ActivityBadge
                label={activityBadgeInfo?.label}
                color={activityBadgeInfo?.color}
                textColor={activityBadgeInfo?.textColor}
                fontWeight={400}
                fontSize={14}
              />
            )}
          </div>
          <Input
            id="first-name"
            name="firstName"
            type="text"
            placeholder=""
            label={t('Global.FirstName')}
            variant={StyleVariant.Secondary}
            value={formState.firstName || ''}
            onChange={handleChange}
            errorMessage={t(errors.firstName.message)}
            validate={validate}
            autoComplete="off"
            disabled={formState.status !== UserStatus.Active}
          />
          <Input
            id="last-name"
            name="lastName"
            type="text"
            placeholder=""
            label={t('Global.LastName')}
            variant={StyleVariant.Secondary}
            value={formState.lastName || ''}
            onChange={handleChange}
            errorMessage={t(errors.lastName.message)}
            validate={validate}
            autoComplete="off"
            disabled={formState.status !== UserStatus.Active}
          />
          {formSchema.role && (
            <SelectInput
              name="role"
              type="text"
              placeholder=""
              label={t('UpdateUserForm.SelectInput.Label.UserRole')}
              variant={StyleVariant.Secondary}
              value={formState.role}
              options={userRoleSelectOptions}
              onChange={handleChange}
              autoComplete="off"
              disabled={formState.status !== UserStatus.Active}
            />
          )}
          <Input
            id="job"
            name="job"
            type="text"
            placeholder=""
            label={t('UpdateUserForm.Input.Label.Job')}
            variant={StyleVariant.Secondary}
            value={formState.job || ''}
            onChange={handleChange}
            errorMessage={t(errors.job.message)}
            validate={validate}
            autoComplete="off"
            disabled={formState.status !== UserStatus.Active}
          />
          <SelectInput
            name="persona"
            type="text"
            placeholder="-"
            label={t('Global.Persona')}
            variant={StyleVariant.Secondary}
            value={formState?.persona?.value || undefined}
            options={personaOptions || []}
            onChange={event => {
              const persona = personaOptions.find(
                option => option.value === event.target.value
              );
              setFormState(prev => ({
                ...prev,
                persona: persona !== undefined ? persona : null,
              }));
            }}
            autoComplete="off"
            disabled={formState.status !== UserStatus.Active}
          />
        </div>
        <div className={styles.sideButtons}>
          {isResendInviteEnabled && (
            <Button
              onClick={e => {
                e.preventDefault();
                onClickReInviteUser(formState.id);
              }}
              styleType={ButtonStyle.Secondary}
              icon="airplane-right"
              text={t('UpdateUserForm.ButtonLabel.ResendInvite')}
              disabled={formStatus === FormStatus.Pending || isRequestPending}
              loading={isReinvitePending}
              id="resend-invite-btn"
            />
          )}
          {isReactivateUserEnabled && (
            <Button
              onClick={e => {
                e.preventDefault();
                onClickReactivateUser(formState.id);
              }}
              styleType={ButtonStyle.Secondary}
              icon="airplane-right"
              text={t('UpdateUserForm.ButtonLabel.ReInviteUser')}
              disabled={formStatus === FormStatus.Pending || isRequestPending}
              loading={isReinvitePending}
              id="re-invite-user-btn"
            />
          )}
          {onClickRemoveUser && (
            <Button
              onClick={e => {
                e.preventDefault();
                onClickRemoveUser(formState.id);
              }}
              styleType={ButtonStyle.Warning}
              icon="trash-can"
              text={t('UpdateUserForm.ButtonLabel.RemoveUser')}
              disabled={
                formStatus === FormStatus.Pending ||
                formState.status === UserStatus.Removed ||
                isReinvitePending
              }
              loading={isRequestPending}
              id="remove-user-btn"
            />
          )}
        </div>
      </div>
      <div className={styles.formBottom}>
        <Button
          onClick={() => {}}
          styleType={ButtonStyle.Primary}
          text={t('Global.Submit')}
          type="submit"
          loading={formStatus === FormStatus.Pending}
          disabled={
            isSubmitDisabled ||
            isRequestPending ||
            formState.status !== UserStatus.Active ||
            isReinvitePending
          }
          id="sumbit-btn"
        />
        <Button
          onClick={onCancel}
          styleType={ButtonStyle.Secondary}
          text={t('Global.Cancel')}
          disabled={formStatus === FormStatus.Pending}
          id="cancel-btn"
        />
      </div>
    </form>
  );
};

export default UpdateUserForm;
