import {useMemo} from 'react';

const useFileIcon = (fileType: string) => {
  return useMemo(() => {
    let icon;

    switch (fileType) {
      case 'pdf':
        icon = 'pdf';
        break;
      case 'xls':
      case 'xlsx':
      case 'xlsm':
      case 'csv':
        icon = 'excel';
        break;
      case 'doc':
      case 'docx':
      case 'txt':
      case 'rtf':
        icon = 'word';
        break;
      case 'pptx':
        icon = 'powerpoint';
        break;
      case 'jpeg':
      case 'jpg':
      case 'png':
      case 'gif':
      case 'bmp':
      case 'svg':
        icon = 'image';
        break;
      case 'mp3':
      case 'wav':
      case 'ogg':
        icon = 'audio';
        break;
      case 'mp4':
      case 'avi':
      case 'mov':
        icon = 'video';
        break;
      default:
        icon = 'document'; // Generic file icon
    }

    return icon;
  }, [fileType]);
};

export default useFileIcon;
