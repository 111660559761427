import React from 'react';
import {
  ActiveCompanyData,
  ExitedCompanyData,
  WrittenOffCompanyData,
} from '../../../types/index';
import {
  isActiveCompany,
  isExitedCompany,
  isWrittenOffCompany,
} from '../../../utils';
import ActiveCompany from './components/active-company/ActiveCompany';
import ExitedCompany from './components/exited-company/ExitedCompany';
import WrittenOffCompany from './components/writtenoff-company/WrittenOffCompany';

const CompanyWidget: React.FC<
  ActiveCompanyData | ExitedCompanyData | WrittenOffCompanyData
> = props => {
  if (isActiveCompany(props)) return <ActiveCompany {...props} />;
  if (isExitedCompany(props)) return <ExitedCompany {...props} />;
  if (isWrittenOffCompany(props)) return <WrittenOffCompany {...props} />;

  return null;
};

export default CompanyWidget;
