import React, {useEffect, useMemo, useState} from 'react';
import CustomYAxisTick from '../../../../global/custom-y-axis-tick/CustomYAxisTick';
import NavCustomLegend from '../../../../fund-overview/fund-performance-widget/components/nav-custom-legend/NavCustomLegend';
import {totalCostLegendItems, totalCostTooltipOrder} from '../../consts';
import {SegmentedControlVariants} from '../../../../global/segmented-control/SegmentedControl';
import {valueFormat} from '../../../../../utils';
import FundPerformanceMoicTooltip from '../../../../fund-overview/fund-performance-widget/components/fund-performance-moic-chart/components/FundPerformanceMoicTooltip';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Label,
  LabelList,
  Legend,
  ReferenceArea,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import {PerformanceWidgetChartProps} from '../../PerformanceWidget';
import {
  BundledExportProps,
  ButtonStyle,
  NavTimeSeries,
  TicksType,
} from '../../../../../types';
import {useChartAnimated, useChartHover} from '../../../../../hooks';
import {
  IconButton,
  LiveDataChartLabelContent,
  LiveDataChartShape,
} from '../../../../global';
import {
  getperformanceTotalCostImagesExportPayload,
  getPerformanceTotalCostTableExportPayload,
} from './PerformanceTotalCostChartSettings';
import styles from './PerformanceTotalCostChart.module.scss';
import {genPortfolioLegendActions} from '../../../../../utils/performanceWidget';
import NavXAXisTick from '../../../../fund-overview/fund-performance-widget/components/fund-performance-nav-chart/components/NavXAxisTick';
import {
  calcDomainRange,
  calcXaxisTicks,
  calcXaxisPadding,
  calcXaxisTickXOffset,
} from '../../../../../utils/benchmarking';
import {getHalfBarWidthInTime} from '../../../../../utils';

const CHART_X_MARGINS = 70;

const PerformanceTotalCostChart: React.FC<PerformanceWidgetChartProps> = ({
  timeSeries: reportedTimeSeries,
  nearCastingData,
  activeKpi,
  setActiveKpi,
  exportHandler,
  format,
  tooltipFormat,
  yAxisFormat,
  currency,
  entityName,
  isConsolidatedData,
  id,
  showExport = true,
  signOffDate,
}) => {
  const [selectedBar, setSelectedBar] = useState(null);
  const {ref, isAnimated} = useChartAnimated();
  const {hovered, handleMouseMove, handleMouseLeave} = useChartHover();
  const [exportInProgress, setExportInProgress] = useState<boolean>(false);
  const isNearCastingEmpty =
    !nearCastingData?.timeSeries || !nearCastingData?.timeSeries?.data?.length;
  const timeSeries = useMemo(
    () => ({
      data: [
        ...(reportedTimeSeries?.data || []),
        ...(nearCastingData?.timeSeries?.data || []),
      ],
    }),
    [reportedTimeSeries, nearCastingData]
  );
  const [chartWidth, setChartWidth] = useState(0);
  const nearCastingStartingFrom = (
    reportedTimeSeries?.data?.slice(-1)[0] as any
  )?.date;

  const chartData = useMemo(() => {
    return (
      timeSeries.data?.map(item => ({
        ...item,
        x: new Date((item as any).date as string),
      })) || []
    );
  }, [timeSeries]);

  const getXAxisTicks = useMemo(() => {
    return calcXaxisTicks(
      chartData as NavTimeSeries[],
      chartData?.length > 7 ? TicksType.Year : TicksType.Quarter,
      true
    ).map((item: string) => new Date(item));
  }, [chartData]);

  const {from, to} = useMemo(() => {
    return calcDomainRange(chartData);
  }, [chartData]);

  const halfBarWidthInTime = useMemo(
    () =>
      getHalfBarWidthInTime(
        to,
        from,
        chartData?.length - 1,
        chartWidth - CHART_X_MARGINS
      ),
    [to, from, chartData, chartWidth]
  );

  const LabelFormatter = (value: number) => {
    if (value !== 0) {
      return `${valueFormat(value, format).value}${
        valueFormat(value, format).suffix
      }`;
    }
  };
  const onMouseEnterBar = (e: any, index: any) => {
    setSelectedBar(index);
  };

  const onMouseLeaveBar = () => {
    setSelectedBar(null);
  };

  const handleBundledExport = async () => {
    setExportInProgress(true);

    const zipFileName = `${entityName}_CurrentCost`;

    await exportHandler({
      zipFileName,
      tableExportPayload: getPerformanceTotalCostTableExportPayload(
        timeSeries.data || [],
        zipFileName
      ),
      imagesExportPayload: getperformanceTotalCostImagesExportPayload(
        zipFileName || '',
        id
      ),
    } as unknown as BundledExportProps);

    setExportInProgress(false);
  };

  useEffect(() => {
    if (ref.current) {
      setChartWidth(ref.current.offsetWidth);
    }

    // Optional: Add event listener for window resize to update width
    const handleResize = () => {
      if (ref.current) {
        setChartWidth(ref.current.offsetWidth);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div ref={ref}>
      <ResponsiveContainer
        height={560}
        width="100%"
        id={`${id}-performance-total-cost-chart`}
      >
        <BarChart
          data={chartData as any}
          margin={{top: 35, left: 30, right: 40, bottom: 72}}
          onMouseLeave={handleMouseLeave}
          onMouseMove={handleMouseMove}
        >
          <defs>
            <linearGradient
              id="nearCastingGradient"
              x1="24.4311"
              y1="6.58801e-06"
              x2="458.185"
              y2="407.236"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#7517C8" />
              <stop offset="1" stopColor="#472DE3" />
            </linearGradient>
          </defs>
          <defs>
            <linearGradient id="DeptGradient" gradientTransform="rotate(90)">
              <stop offset="0%" stopColor={`rgba(var(--colors-gray-5),0.56)`} />
              <stop
                offset="100%"
                stopColor={`rgba(var(--colors-gray-7),0.8)`}
              />
            </linearGradient>
          </defs>
          <defs>
            <linearGradient
              id="ConvertableGradient"
              gradientTransform="rotate(90)"
            >
              <stop
                offset="0%"
                stopColor={`rgba(var(--colors-lan-blue),0.93)`}
              />
              <stop
                offset="100%"
                stopColor={`rgba(var(--colors-pacific-blue),0.93)`}
              />
            </linearGradient>
          </defs>
          <defs>
            <linearGradient id="EquityGradient" gradientTransform="rotate(180)">
              <stop
                offset="26%"
                stopColor={`rgba(var(--colors-lan-violet),0.93)`}
              />
              <stop
                offset="-87%"
                stopColor={`rgba(var(--colors-lan-blue),0.93)`}
              />
            </linearGradient>
            <linearGradient
              id="signOffDataGradient"
              gradientTransform="rotate(0)"
            >
              <stop offset="0%" stopColor={`rgba(65, 65, 65, 0.8)`} />
              <stop offset="50%" stopColor={`rgba(65, 65, 65, 0.51)`} />
              <stop offset="100%" stopColor={`rgba(52, 51, 51, 0.2)`} />
            </linearGradient>
          </defs>
          <CartesianGrid
            vertical={false}
            strokeDasharray="2"
            stroke={`rgb(var(--colors-gray-6))`}
            x={0}
            width={1920}
          />
          <YAxis
            axisLine={false}
            tickLine={false}
            width={1}
            label={{
              value: currency,
              position: 'top',
              dx: 0,
              dy: -13,
              fontSize: '12px',
              fontWeight: '500',
              fill: `rgb(var( --colors-gray-3))`,
            }}
            tick={
              <CustomYAxisTick
                showAxis={true}
                xOffset={10}
                yOffset={4}
                fill="gray-3"
                fontSize={12}
                format={yAxisFormat}
                fontWeight={500}
              />
            }
          />
          <XAxis
            dataKey="x"
            type="number"
            domain={[from.getTime(), to.getTime()]}
            scale="time"
            interval={0}
            axisLine={false}
            height={60}
            tickLine={false}
            ticks={getXAxisTicks as any}
            padding={calcXaxisPadding(chartData.length)}
            tick={
              <NavXAXisTick
                showAxis={true}
                fill="gray-3"
                fontSize={12}
                yOffset={15}
                xOffset={
                  isNearCastingEmpty
                    ? 0
                    : calcXaxisTickXOffset(chartData.length)
                }
                xAxisFormat={chartData?.length > 7 ? 'yyyy' : `QQ 'YY`}
              />
            }
          />
          {!isNearCastingEmpty && (
            <ReferenceArea
              x1={new Date(nearCastingStartingFrom).getTime()}
              x2={chartData.slice(-1)[0].x.getTime() + halfBarWidthInTime}
              ifOverflow="visible"
              fill="url(#nearCastingGradient)"
              fillOpacity={0.3}
            />
          )}
          <Bar
            isAnimationActive={isAnimated}
            hide={!isAnimated}
            dataKey="Current cost"
            fill="rgb(var(--colors-orange-peel))"
            stroke="rgb(var(--colors-orange-peel)"
            strokeOpacity={0}
          />
          <Bar
            isAnimationActive={isAnimated}
            hide={!isAnimated}
            dataKey="Equity"
            stackId={1}
            barSize={chartData?.length > 7 ? 28 : 56}
            fill="url(#EquityGradient)"
            stroke="rgb(var(--colors-lan-violet)"
            strokeOpacity={0}
            onMouseEnter={onMouseEnterBar}
            onMouseLeave={onMouseLeaveBar}
          >
            {timeSeries?.data?.map((_, index) => (
              <Cell
                key={`cell-${index}`}
                fill="url(#EquityGradient)"
                opacity={selectedBar === index ? 0.5 : 1}
              />
            ))}
          </Bar>
          <Bar
            isAnimationActive={isAnimated}
            hide={!isAnimated}
            dataKey="Debt"
            stackId={1}
            barSize={chartData?.length > 7 ? 28 : 56}
            fill="url(#DeptGradient)"
            stroke="rgb(var(--colors-gray-5))"
            strokeOpacity={0}
            onMouseEnter={onMouseEnterBar}
            onMouseLeave={onMouseLeaveBar}
          >
            {timeSeries?.data?.map((_, index) => (
              <Cell
                key={`cell-${index}`}
                fill="url(#DeptGradient)"
                opacity={selectedBar === index ? 0.5 : 1}
              />
            ))}
          </Bar>

          <Bar
            isAnimationActive={isAnimated}
            hide={!isAnimated}
            dataKey="Other"
            stackId={1}
            barSize={chartData?.length > 7 ? 28 : 56}
            fill="url(#ConvertableGradient)"
            stroke="rgb(var(--colors-lan-blue)"
            strokeOpacity={0}
            onMouseEnter={onMouseEnterBar}
            onMouseLeave={onMouseLeaveBar}
          >
            <LabelList
              dataKey="Current cost"
              position="top"
              fill="rgb(var(--colors-orange-peel))"
              fontSize={12}
              formatter={LabelFormatter}
            />
            {timeSeries?.data?.map((_, index) => (
              <Cell
                key={`cell-${index}`}
                fill="url(#ConvertableGradient)"
                opacity={selectedBar === index ? 0.5 : 1}
              />
            ))}
          </Bar>
          <Tooltip
            cursor={false}
            offset={55}
            content={
              <FundPerformanceMoicTooltip
                payloadOrder={totalCostTooltipOrder}
                format={tooltipFormat}
                headerKey="quarter"
                currency={currency}
              />
            }
          />

          {signOffDate && (
            <ReferenceArea
              x1={new Date(signOffDate).getTime()}
              shape={props => (
                <LiveDataChartShape
                  {...props}
                  rightMargin={100}
                  fill="url(#signOffDataGradient)"
                />
              )}
            >
              {hovered && (
                <Label
                  value={'In-flight data'}
                  content={LiveDataChartLabelContent}
                />
              )}
            </ReferenceArea>
          )}
          <Legend
            iconSize={12}
            verticalAlign="top"
            align="right"
            content={
              <NavCustomLegend
                id="performance-total-cost-chart-legend"
                kpiToggle={{
                  actions: genPortfolioLegendActions(isConsolidatedData),
                  value: activeKpi,
                  onChange: setActiveKpi,
                }}
                items={totalCostLegendItems}
                buttonVariant={SegmentedControlVariants.Tertiary}
                className={styles.legendRow}
                showBenchmarking={false}
                exportButton={
                  showExport ? (
                    <IconButton
                      className="performance-chart-export"
                      onClick={handleBundledExport}
                      styleType={ButtonStyle.Primary}
                      icon="export"
                      loading={exportInProgress}
                      id="performance-total-cost-chart-export-btn"
                    />
                  ) : undefined
                }
              />
            }
            wrapperStyle={{
              paddingLeft: '20px',
              paddingBottom: '56px',
            }}
            formatter={value => (
              <span
                style={{
                  color: 'rgb(var(--colors-gray-4))',
                  fontSize: 12,
                }}
              >
                {value}
              </span>
            )}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default PerformanceTotalCostChart;
