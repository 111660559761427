import React from 'react';
import {
  ComponentWidgetType,
  TimelineChartProps,
  StyleVariant,
} from '../../../types/index';
import {TimeLineChart, InfoHeader} from '../../';
import styles from './TimelineWidget.module.scss';

interface TimelineWidgetProps extends ComponentWidgetType {
  isHoverStateDisabled?: boolean;
  variant?: StyleVariant;
}

const TimelineWidget: React.FC<TimelineWidgetProps> = ({
  headerData,
  data,
  isHoverStateDisabled,
  variant,
}) => {
  return (
    <div className={styles.wrapper}>
      {headerData && <InfoHeader data={headerData} />}
      {data && (
        <TimeLineChart
          variant={variant}
          isHoverStateDisabled={isHoverStateDisabled}
          scaledYears={(data[0].timeSeries as TimelineChartProps).scaledYears}
          data={(data[0].timeSeries as TimelineChartProps).data}
        />
      )}
    </div>
  );
};

export default TimelineWidget;
