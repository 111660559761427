import React, {useState} from 'react';
import {CompanyWidget, SectionHeader} from '../../dashboard';
import CompanyWidgetSkeleton from '../../dashboard/company-widget/CompanyWidgetSkeleton';
import {Pagination} from '../../';
import {InfoHeader} from '../';
import {PortfolioData, Func} from '../../../types';
import styles from './FundPortfolioDashboard.module.scss';

interface FundPortfolioDashboardProps extends PortfolioData {
  fundId: string;
  updatePortfolioCompanies: Func<[string, number], void>;
}

const FundPortfolioDashboard: React.FC<FundPortfolioDashboardProps> = ({
  title,
  pages,
  headerData,
  data,
  fundId,
  updatePortfolioCompanies,
}): JSX.Element => {
  const [currentPage, setCurrentPage] = useState<number>(1);

  const onCompaniesPageChange = (page: number): void => {
    setCurrentPage(page);
    if (!data[page]) {
      updatePortfolioCompanies(fundId, page);
    }
  };
  return (
    <div className={styles.wrapper}>
      <SectionHeader
        label={title}
        labelType={'large'}
        onClick={() => null}
        buttonText="Show portfolio"
        className={styles.portfolioSectionHeader}
      />
      <InfoHeader
        data={headerData}
        className={styles.portfolioInfoHeader}
        gridColumnWidth={240}
      />
      <div className={styles.portfolioWidgets}>
        {data[currentPage] ? (
          <div
            className={styles.sectionWrapper}
            key={currentPage}
            data-test="fund-portfolio-company-widgets-wrapper"
          >
            {data[currentPage]?.map((widget, idx: number) => (
              <div className={styles.sectionWidget} key={idx}>
                <CompanyWidget {...widget} />
              </div>
            ))}
          </div>
        ) : (
          <div className={styles.sectionWrapper} key={currentPage}>
            {Array.from(Array(6)).map((_, i) => (
              <div key={i} className={styles.sectionWidget}>
                <CompanyWidgetSkeleton />
              </div>
            ))}
          </div>
        )}
      </div>
      <Pagination
        pages={pages}
        onChange={onCompaniesPageChange}
        className={styles.portfolioPagination}
      />
    </div>
  );
};

export default FundPortfolioDashboard;
