import React from 'react';
import classnames from 'classnames';
import {ValueFormat} from '../../../../global';
import styles from './ListItem.module.scss';

interface ListItemProps {
  name: string;
  value: number;
  format: string;
  color?: string;
  activeName?: string;
}

const ListItem: React.FC<ListItemProps> = ({
  value,
  name,
  format,
  color,
  activeName,
}) => {
  return (
    <div
      className={classnames(
        styles.listItem,
        activeName === name && styles.active,
        activeName && activeName !== name && styles.inActive
      )}
      key={value + name}
    >
      {color && (
        <div
          className={styles.listItemColor}
          style={{backgroundColor: `rgb(var(${color})`}}
        />
      )}
      <div className={styles.listItemName}>{name}</div>
      <div className={styles.listItemValue}>
        <ValueFormat value={value} format={format} />
      </div>
    </div>
  );
};

export default ListItem;
