import {
  InviteUserForm,
  MetricType,
  ReportingGroupTemplateFormState,
  SelectOption,
  TemplateConfigDetailsCustomGroupFormState,
  UserRole,
  WorkflowStatus,
  TemplateConfigDetailsCustomMetricsFormState,
  CompanyGeneralInfoFormState,
  AcquisitionInformationFormState,
  GlobalInformationProps,
  ButtonStyle,
  GlobalInformationActionType,
  CustomTopicsFormState,
} from '../types';
import {SortingState} from '@tanstack/react-table';
import {ManageWorkflowFormState} from '../types/manageWorkflowForm';
import i18n from '../i18n';

export const userRoleSelectOptions: SelectOption[] = [
  {
    id: 'admin',
    value: UserRole.ClientAdmin,
    label: 'Admin',
  },
  {
    id: 'user',
    value: UserRole.FundManager,
    label: 'User',
  },
  // {
  //   value: UserRole.CompanyAdmin,
  //   label: 'Company Admin',
  // },
];

export const inviteFormInitialState: InviteUserForm = {
  email: '',
  role: UserRole.FundManager,
};

export const usersTableDefaultSorting: SortingState = [
  {
    desc: false,
    id: 'firstName',
  },
];

export const companyUsersTableDefaultSorting: SortingState = [
  {
    desc: true,
    id: 'portfolioCompany',
  },
];

export const customMetricsOption: SelectOption = {
  id: 'custom-metrics',
  label: 'Custom metrics',
  value: '',
};

export const customGroupOption: SelectOption = {
  id: 'custom-group',
  label:
    'Admin.DataCollection.TemplateConfigDetailsCustomGroupForm.SelectInputOption.Custom',
  value: '',
};

export const customGroupFormInitialState: TemplateConfigDetailsCustomGroupFormState =
  {
    parentId: '',
    name: '',
  };

export const customMetricsFormInitialState: TemplateConfigDetailsCustomMetricsFormState =
  {
    id: '',
    groupId: '',
    parentId: null,
    name: '',
    type: MetricType.Custom,
    reportingName: '',
    description: '',
    unitType: '',
    unitLabel: '',
    mandatory: false,
    reportingType: '',
    question: '',
    answerOptions: [],
    standard: [],
  };

export const customTopicsFormInitialState: CustomTopicsFormState = {
  id: '',
  groupId: '',
  parentId: '',
  name: '',
  type: MetricType.Custom,
  reportingName: '',
  description: '',
  unitType: '',
  unitLabel: '',
  reportingType: '',
  mandatory: false,
  question: '',
  answerOptions: [],
  standard: [],
};

export const addNewReportingGroupTemplateItemFormInitialState: ReportingGroupTemplateFormState =
  {
    id: null,
    name: '',
    reportingGroupId: '',
    templateParentId: null,
  };

export const manageWorkflowInitialState: ManageWorkflowFormState = {
  id: '',
  workflowCollectionType: '',
  internalAssigneesList: '',
  portfolioCompanyAssignee: 'someemail@domain.com',
  reportingCurrency: '',
  requestDate: '+ 10 working days',
  reminderDate: '+ 10 working days',
  dueDate: '+10 working days',
  templateId: 'template-id',
  reportingInterval: 'Quarterly',
  firstPeriod: `Q1 '22`,
  ownerId: 'user-id',
  workflowStatus: {
    id: WorkflowStatus.Active,
    label: 'Global.Active',
    date: '02-05-2022',
  },
};

export const companyGeneralInfoInitialFormState: CompanyGeneralInfoFormState = {
  companyName: '',
  geographicRegion: '',
  sector: '',
  industry: '',
  headquartersCountry: '',
  state: '',
  stage: '',
  companyRegistrationNumber: '',
  reportingCurrency: '',
  website: '',
  facebook: '',
  youtube: '',
  twitter: '',
  linkedIn: '',
  instagram: '',
  description: '',
  fiscalYearEndMonth: 'december',
  fiscalYearEndDay: '31',
};

export const companyAcquisitionInfoInitialFormState: AcquisitionInformationFormState =
  {
    enterpriseValue: '',
    acquisitionMultiple: '',
    fullyDilutedOwnership: '',
    multipleOf: '',
    investmentRole: '',
    coInvestors: [],
  };

export const globalInfoMessage = (): GlobalInformationProps => ({
  type: 'info',
  message: i18n.t('Admin.UserAdministration.Notification.InvitationSent'),
  icon: 'check',
  isDismissable: true,
  actions: [
    {
      text: i18n.t('Global.CloseWindow'),
      style: ButtonStyle.Primary,
      action: GlobalInformationActionType.Dismiss,
      themeOverride: 'lightThemeSecondaryButton',
      customClassName: 'singleButton',
    },
  ],
});

export const workFlowConfigTable: SortingState = [
  {
    desc: false,
    id: 'portfolioCompany',
  },
];

export const templateConfig: SortingState = [
  {
    desc: true,
    id: 'lastChanged',
  },
];
