import React from 'react';
import {
  HeaderMapping,
  FlexHAlign,
  ButtonSize,
  NearCastingScenarioCompanyTransaction,
} from '../../../../../types';
import {ColumnDef, createColumnHelper} from '@tanstack/react-table';
import Th from '../../cells/th/Th';
import Td from '../../cells/td/Td';
import {IconButton} from '../../../index';
import styles from './index.module.scss';

const ScenarioValuationExpandedTableColumns = (
  headerMapping: HeaderMapping[],
  handleEdit: (transaction: NearCastingScenarioCompanyTransaction) => void,
  handleDelete: (id?: string) => void,
  transactionIdToRemove: string,
  fundCurrency: string
): ColumnDef<NearCastingScenarioCompanyTransaction, any>[] => {
  const columnHelper =
    createColumnHelper<NearCastingScenarioCompanyTransaction>();

  return [
    columnHelper.accessor('instrument', {
      id: 'instrument',
      header: props => (
        <Th {...props} headerMapping={headerMapping} align={FlexHAlign.start} />
      ),
      cell: props => <Td {...props} align={FlexHAlign.start} />,
    }),
    columnHelper.accessor('currency', {
      id: 'currency',
      header: props => (
        <Th
          {...props}
          headerMapping={headerMapping}
          align={FlexHAlign.start}
          width={'100px'}
        />
      ),
      cell: props => (
        <Td {...props} align={FlexHAlign.start}>
          {fundCurrency}
        </Td>
      ),
    }),
    columnHelper.accessor('shares', {
      id: 'shares',
      header: props => (
        <Th {...props} headerMapping={headerMapping} align={FlexHAlign.start} />
      ),
      cell: props => <Td {...props} align={FlexHAlign.start} />,
    }),
    columnHelper.accessor('date', {
      id: 'date',
      header: props => (
        <Th
          {...props}
          headerMapping={headerMapping}
          align={FlexHAlign.end}
          width={'140px'}
        />
      ),
      cell: props => <Td {...props} align={FlexHAlign.end} />,
    }),
    columnHelper.accessor('invested', {
      id: 'invested',
      header: props => (
        <Th {...props} headerMapping={headerMapping} align={FlexHAlign.end} />
      ),
      cell: props => <Td {...props} align={FlexHAlign.end} />,
    }),
    columnHelper.accessor('realisedGains', {
      id: 'realisedGains',
      header: props => (
        <Th {...props} headerMapping={headerMapping} align={FlexHAlign.end} />
      ),
      cell: props => <Td {...props} align={FlexHAlign.end} />,
    }),
    columnHelper.accessor('investmentIncome', {
      id: 'investmentIncome',
      header: props => (
        <Th {...props} headerMapping={headerMapping} align={FlexHAlign.end} />
      ),
      cell: props => <Td {...props} align={FlexHAlign.end} />,
    }),
    columnHelper.accessor('comment', {
      id: 'comment',
      header: props => (
        <Th {...props} headerMapping={headerMapping} align={FlexHAlign.start} />
      ),
      cell: props => <Td {...props} align={FlexHAlign.start} />,
    }),
    columnHelper.display({
      id: 'edit',
      header: props => (
        <Th
          {...props}
          align={FlexHAlign.center}
          width={'74px'}
          headerMapping={headerMapping}
        />
      ),
      cell: props => (
        <Td {...props} align={FlexHAlign.end}>
          {props.row.original.isNcTransaction ? (
            <>
              <IconButton
                buttonSize={ButtonSize.Small}
                onClick={() => handleEdit(props.row.original)}
                icon="pencil"
                disabled={transactionIdToRemove === props.row.original.id}
                className={styles.actionButton}
              />
              <IconButton
                buttonSize={ButtonSize.Small}
                onClick={() => handleDelete(props.row.original.id)}
                icon="cross"
                disabled={transactionIdToRemove === props.row.original.id}
                className={styles.actionButton}
              />
            </>
          ) : null}
        </Td>
      ),
    }),
  ];
};

export default ScenarioValuationExpandedTableColumns;
