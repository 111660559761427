import {z} from 'zod';
import {
  completeUrlRegex,
  dotOnStartOrEndRegex,
  fileNameRegex,
  httpRegex,
} from './regExps';

export const basicInfoFormSchema = z.object({
  name: z.string().min(1, 'BasicInfoForm.ErrorMessage.NameR'),
  website: z
    .string()
    .min(1, 'BasicInfoForm.ErrorMessage.WebsiteR')
    .regex(
      /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/g,
      'BasicInfoForm.ErrorMessage.WebsiteInvalid'
    ),
  description: z.string().max(200, 'BasicInfoForm.ErrorMessage.Description'),
  currency: z.string(),
  country: z.string(),
  fiscalYearEnd: z.string(),
  state: z.string(),
  sector: z.string(),
  primaryMarket: z.string(),
  industry: z.string(),
});

export const addNewReportingGroupTemplateItemFormSchema = z.object({
  name: z
    .string()
    .min(1, 'ReportingGroupTemplateForm.ErrorMessage.NameRequired')
    .trim()
    .min(1, 'ReportingGroupTemplateForm.ErrorMessage.NameMin')
    .max(256, 'ReportingGroupTemplateForm.ErrorMessage.NameLong'),
});

export const templateConfigDetailsCustomMetricsFormSchema = z.object({
  name: z
    .string()
    .min(1, 'TemplateConfigDetailsCustomMetricsForm.ErrorMessage.NameRequired'),
  reportingName: z
    .string()
    .min(
      1,
      'TemplateConfigDetailsCustomMetricsForm.ErrorMessage.ReportingNameR'
    )
    .trim()
    .min(
      1,
      'TemplateConfigDetailsCustomMetricsForm.ErrorMessage.ReportingNameMin'
    )
    .max(
      256,
      'TemplateConfigDetailsCustomMetricsForm.ErrorMessage.ReportingNameLong'
    ),
  unitLabel: z
    .string()
    .min(1, 'Global.ErrorMessage.UnitLabelR')
    .trim()
    .min(1, 'TemplateConfigDetailsCustomMetricsForm.ErrorMessage.UnitLabelMin')
    .max(
      256,
      'TemplateConfigDetailsCustomMetricsForm.ErrorMessage.UnitLabelLong'
    ),
  description: z
    .string()
    .min(1, 'TemplateConfigDetailsCustomMetricsForm.ErrorMessage.DescriptionR')
    .trim()
    .min(
      1,
      'TemplateConfigDetailsCustomMetricsForm.ErrorMessage.DescriptionMin'
    ),
  unitType: z
    .string()
    .min(1, 'TemplateConfigDetailsCustomMetricsForm.ErrorMessage.UnitTypeR'),
  reportingType: z.string().min(1, 'Global.ErrorMessage.ReportingTypeR'),
});

export const templateConfigDetailsCustomGroupFormSchema = z.object({
  name: z
    .string()
    .min(1, 'TemplateConfigDetailsCustomGroupForm.ErrorMessage.GroupR')
    .trim()
    .min(1, 'TemplateConfigDetailsCustomGroupForm.ErrorMessage.GroupMin')
    .max(256, 'TemplateConfigDetailsCustomGroupForm.ErrorMessage.GroupLong'),
});

export const workflowConfigEditFormSchema = z.object({
  templateId: z
    .string()
    .min(1, 'UpdatePCWorkflowConfigForm.ErrorMessage.TemplateR'),
  reportingType: z.string().min(1, 'Global.ErrorMessage.ReportingTypeR'),
  reportingInterval: z
    .string()
    .min(1, 'UpdatePCWorkflowConfigForm.ErrorMessage.IntervalR'),
  firstPeriod: z
    .string()
    .min(1, 'UpdatePCWorkflowConfigForm.ErrorMessage.PeriodR'),
  userId: z.string().min(1, 'UpdatePCWorkflowConfigForm.ErrorMessage.OwnerR'),
});

const baseWorkflowSchema = z.object({
  reportingCurrency: z
    .string()
    .min(1, 'ManageWorkflowForm.ErrorMessage.CurrencyR'),
  templateId: z.string().min(1, 'ManageWorkflowForm.ErrorMessage.TemplateR'),
  reportingInterval: z
    .string()
    .min(1, 'ManageWorkflowForm.ErrorMessage.IntervalR'),
  firstPeriod: z.string().min(1, 'ManageWorkflowForm.ErrorMessage.PeriodR'),
  ownerId: z.string().min(1, 'ManageWorkflowForm.ErrorMessage.OwnerR'),
});

export const internalCollectionSchema = baseWorkflowSchema.extend({
  internalAssigneesList: z
    .string()
    .min(1, 'ManageWorkflowForm.ErrorMessage.InternalR'),
});

export const externalCollectionSchema = baseWorkflowSchema.extend({
  portfolioCompanyAssignee: z
    .string()
    .min(1, 'ManageWorkflowForm.ErrorMessage.EmailR')
    .email('ManageWorkflowForm.ErrorMessage.EmailInvalid'),
  requestDate: z.string().min(1, 'ManageWorkflowForm.ErrorMessage.DateR'),
  reminderDate: z.string().min(1, 'ManageWorkflowForm.ErrorMessage.ReminderR'),
});

const websiteUrl = z
  .string()
  .max(256, 'CompanyGeneralInfoForm.ErrorMessage.websiteUrl.Max')
  .transform((val, ctx) => {
    let completeUrl = val;
    if (!httpRegex.test(completeUrl)) {
      completeUrl = `https://${completeUrl}`;
    }
    if (!completeUrlRegex.test(completeUrl)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'CompanyGeneralInfoForm.ErrorMessage.websiteUrl.Invalid',
      });

      return z.NEVER;
    }

    return completeUrl;
  });

// const socialMediaUrl = (regex: RegExp, message?: string) =>
//   z
//     .string()
//     .max(256, '256 characters max')
//     .transform((val, ctx) => {
//       let completeUrl = val;
//       if (!httpRegex.test(completeUrl)) {
//         completeUrl = `https://${completeUrl}`;
//       }
//       if (!completeUrlRegex.test(completeUrl)) {
//         ctx.addIssue({
//           code: z.ZodIssueCode.custom,
//           message: 'Invalid Link',
//         });

//         return z.NEVER;
//       }
//       if (!regex.test(completeUrl)) {
//         ctx.addIssue({
//           code: z.ZodIssueCode.custom,
//           message: message || 'Invalid Link',
//         });

//         return z.NEVER;
//       }
//       return completeUrl;
//     });

export const companyGeneralInfoFormSchema = z
  .object({
    companyName: z
      .string()
      .max(256, 'CompanyGeneralInfoForm.ErrorMessage.websiteUrl.Max'),
    companyRegistrationNumber: z
      .string()
      .max(13, 'CompanyGeneralInfoForm.ErrorMessage.CompanyRegistration.Max'),
    website: z.union([websiteUrl, z.literal('')]),
    facebook: z.union([websiteUrl, z.literal('')]),
    youtube: z.union([websiteUrl, z.literal('')]),
    twitter: z.union([websiteUrl, z.literal('')]),
    linkedIn: z.union([websiteUrl, z.literal('')]),
    instagram: z.union([websiteUrl, z.literal('')]),
    description: z.string(),
  })
  .partial();

export const acquisitionInformationFormSchema = z.object({
  enterpriseValue: z.number({
    invalid_type_error: 'AcquisitionInformationForm.ErrorMessage.EnterpriseNum',
  }),
  acquisitionMultiple: z.number({
    invalid_type_error:
      'AcquisitionInformationForm.ErrorMessage.AcquisitionNum',
  }),
  fullyDilutedOwnership: z.string(),
});

export const saveChartFormSchema = z.object({
  name: z
    .string()
    .min(1, 'SaveChartForm.ErrorMessage.NameR')
    .trim()
    .min(1, 'SaveChartForm.ErrorMessage.NameMin')
    .max(256, 'SaveChartForm.ErrorMessage.NameLong'),
});

export const updateUserFormSchema = {
  email: z
    .string()
    .email('Global.ErrorMessage.EmailInvalid')
    .min(1, 'Global.ErrorMessage.EmailR'),
  firstName: z.string().min(1, 'Global.ErrorMessage.NameR'),
  lastName: z.string().min(1, 'Global.ErrorMessage.LastR'),
  portfolioCompany: z.string().min(1, 'Portfolio company field is required'),
  persona: z.string().min(1, 'PCUsersTableExpandedRow.ErrorMessage.PersonaR'),
  job: z.string().min(1, 'PCUsersTableExpandedRow.ErrorMessage.JobR'),
};

export const manageUserFormSchema = {
  email: z
    .string()
    .email('Global.ErrorMessage.EmailInvalid')
    .min(1, 'Global.ErrorMessage.EmailR'),
  firstName: z.string().min(1, 'Global.ErrorMessage.NameR'),
  lastName: z.string().min(1, 'Global.ErrorMessage.LastR'),
  persona: z.string().min(1, 'PCUsersTableExpandedRow.ErrorMessage.PersonaR'),
  job: z.string().min(1, 'PCUsersTableExpandedRow.ErrorMessage.JobR'),
  role: z.string().min(1, 'PCUsersTableExpandedRow.ErrorMessage.RoleR'),
};

export const customTopicsFormSchema = z.object({
  reportingName: z
    .string()
    .min(1, 'CustomTopicsForm.ErrorMessage.TopicR')
    .trim()
    .min(1, 'CustomTopicsForm.ErrorMessage.TopicMin')
    .max(256, 'CustomTopicsForm.ErrorMessage.TopicMax'),
  description: z
    .string()
    .min(1, 'CustomTopicsForm.ErrorMessage.DescriptionR')
    .trim()
    .min(1, 'CustomTopicsForm.ErrorMessage.DescriptionMin'),
  question: z
    .string()
    .min(1, 'CustomTopicsForm.ErrorMessage.QuestionR')
    .trim()
    .min(1, 'CustomTopicsForm.ErrorMessage.QuestionMin'),
  unitLabel: z
    .string()
    .min(1, 'Global.ErrorMessage.UnitLabelR')
    .trim()
    .min(1, 'CustomTopicsForm.ErrorMessage.UnitMin')
    .max(256, 'CustomTopicsForm.ErrorMessage.UnitLong'),
  reportingType: z.string().min(1, 'Global.ErrorMessage.ReportingTypeR'),
  answerOptions: z
    .array(
      z.object({
        value: z.union([z.string(), z.number()]),
        label: z
          .string()
          .min(1, 'CustomTopicsForm.ErrorMessage.AnswerR')
          .trim()
          .min(1, 'CustomTopicsForm.ErrorMessage.AnswerMin')
          .max(256, 'CustomTopicsForm.ErrorMessage.AnswerMax'),
      })
    )
    .min(2, 'CustomTopicsForm.ErrorMessage.AnswerOptionMin'),
});

export const uploadFileFormSchema = z.object({
  entityTags: z
    .array(z.string())
    .min(1, 'UploadFileForm.ErrorMessage.EntityMin'),
  sectionTags: z.array(z.string()),
  customTags: z.array(
    z.object({
      id: z.union([z.string(), z.null()]),
      label: z.string(),
    })
  ),
  isPrivate: z.boolean(),
});

export const fileEditFormSchema = uploadFileFormSchema.extend({
  fileName: z
    .string()
    .trim()
    .min(1, 'FileEditForm.ErrorMessage.FileR')
    .max(256, 'FileEditForm.ErrorMessage.FileMax')
    .regex(fileNameRegex, 'Documents.FileEditForm.ErrorMessage.Char')
    .regex(dotOnStartOrEndRegex, 'Documents.FileEditForm.ErrorMessage.Dot'),
});

const getNearCastingMonetaryValueValidationSchema = (isOptional?: boolean) => {
  const nearCastingMonetaryValueValidationSchema = isOptional
    ? z.string().optional()
    : z.string().min(1, 'Global.ErrorMessage.FieldR');

  return nearCastingMonetaryValueValidationSchema
    .refine(x => {
      if (x === undefined) return false;
      const [, decimalPart] = x.split('.');
      return decimalPart === undefined || decimalPart.length <= 2;
    }, 'Global.ErrorMessage.TwoDecimalMax')
    .transform(x => {
      return Number(x);
    })
    .refine(x => !isNaN(x), 'Global.ErrorMessage.InvalidValue')
    .refine(x => {
      const absValue = Math.abs(x);
      if (!Number.isInteger(absValue)) {
        return absValue.toString().length <= 15 && absValue > 1e-3; // for floats
      }
      return absValue <= 999999999999999; // for integers
    }, 'Global.ErrorMessage.15Max');
};

const nearCastingIntegerValueValidationSchema = z.coerce
  .number()
  .int()
  .max(999999999999999, 'Global.ErrorMessage.15Max')
  .min(-999999999999999, 'Global.ErrorMessage.15Max');

export const scenarioMainInfoFormSchema = z.object({
  name: z
    .string()
    .min(1, 'Global.ErrorMessage.FieldR')
    .max(300, 'ScenarioForm.ErrorMessage.NameMax'),
  endDate: z.string().min(1, 'Global.ErrorMessage.FieldR'),
  nci: getNearCastingMonetaryValueValidationSchema(true),
  description: z
    .string()
    .max(2000, 'ScenarioForm.ErrorMessage.DescriptionMax')
    .optional(),
});

export const portfolioCompanyFormSchema = z.object({
  name: z
    .string()
    .min(1, 'Global.ErrorMessage.FieldR')
    .max(100, 'Global.ErrorMessage.100Max'),
  valuation: getNearCastingMonetaryValueValidationSchema(),
  comment: z.string().max(100, 'Global.ErrorMessage.100Max').optional(),
  industry: z.string().optional(),
  sector: z.string().optional(),
});

export const transactionFormSchema = z.object({
  instrument: z.string().min(1, 'Global.ErrorMessage.FieldR'),
  date: z.coerce.date(),
  transactionType: z.string().min(1, 'Global.ErrorMessage.FieldR'),
  invested: getNearCastingMonetaryValueValidationSchema(true),
  shares: nearCastingIntegerValueValidationSchema.optional().or(z.literal('')),
  realisedGains: getNearCastingMonetaryValueValidationSchema(true).or(
    z.literal('')
  ),
  investmentIncome: getNearCastingMonetaryValueValidationSchema(true).or(
    z.literal('')
  ),
  comment: z.string().max(100, 'Global.ErrorMessage.100Max').optional(),
});

export const addCapitalFormSchema = z.object({
  date: z.date(),
  value: getNearCastingMonetaryValueValidationSchema(),
  comment: z.string().max(100, 'Global.ErrorMessage.100Max').optional(),
  type: z.string().min(1, 'Global.ErrorMessage.FieldR'),
});

export const addFeeFormSchema = z.object({
  vendor: z.string().max(100, 'Global.ErrorMessage.100Max'),
  amount: getNearCastingMonetaryValueValidationSchema(),
  date: z.date(),
  fee_type: z.string(),
  local_currency: z.string(),
  comment: z.string().max(100, 'Global.ErrorMessage.100Max').optional(),
});

export const addOtherFormSchema = z.object({
  date: z.date(),
  amount: getNearCastingMonetaryValueValidationSchema(),
  comment: z.string().max(100, 'Global.ErrorMessage.100Max').optional(),
  description: z
    .string()
    .min(1, 'Global.ErrorMessage.FieldR')
    .max(100, 'Global.ErrorMessage.100Max'),
  currency: z.string(),
});

export const createDynamicSchema = (transactionType: string) => {
  let dynamicFields = {};

  if (transactionType === 'buy') {
    dynamicFields = {
      shares: nearCastingIntegerValueValidationSchema
        .and(z.coerce.number().positive('Global.ErrorMessage.PositiveNum'))
        .optional()
        .or(z.literal('')),
    };
  }
  if (transactionType === 'income') {
    dynamicFields = {
      investmentIncome: getNearCastingMonetaryValueValidationSchema(true)
        .refine(value => value > 0, 'Global.ErrorMessage.PositiveNum')
        .or(z.literal('')),
    };
  }
  if (transactionType === 'sell') {
    dynamicFields = {
      shares: nearCastingIntegerValueValidationSchema
        .and(z.coerce.number().negative('Global.ErrorMessage.NegativeNum'))
        .optional()
        .or(z.literal('')),
      realisedGains: getNearCastingMonetaryValueValidationSchema(true)
        .optional()
        .or(z.literal('')),
      invested: getNearCastingMonetaryValueValidationSchema(true)
        .refine(value => value < 0, 'Global.ErrorMessage.NegativeNum')
        .or(z.literal('')),
    };
  }

  return transactionFormSchema.extend(dynamicFields);
};
