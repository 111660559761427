import axios, {AxiosError} from 'axios';
import {
  ButtonStyle,
  GlobalInformationActionType,
  FormResponseError,
  GlobalInformationProps,
} from '../types';
import i18n from '../i18n';

export const generalResponseErrorResolver = (
  err: any
): GlobalInformationProps | null => {
  if (axios.isAxiosError(err) && err.response) {
    const error = err as AxiosError<FormResponseError>;
    // 409 Conflict shows as a result of company admin being active but not assigned to a workflow
    // according to the requirements: still show same message as 401
    // update: to be used on all pages and force logout if 401 or 409
    if (error.response?.status === 401 || error.response?.status === 409) {
      return {
        type: 'error',
        message: i18n.t('GeneralResponseErrorResolver.Message'),
        icon: 'warning',
        isDismissable: false,
        actions: [
          {
            text: i18n.t('NavigationMenu.LogOut'),
            style: ButtonStyle.Primary,
            action: GlobalInformationActionType.Logout,
          },
        ],
      };
    }
    return null;
  }

  return null;
};
