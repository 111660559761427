import React, {useMemo, useState} from 'react';
import classnames from 'classnames';
import {proxy} from 'comlink';
import {useFundsDetails} from '../../../hooks';
import {downloadZippedExport, htmlToImage} from '../../../utils';
import FundTabsRow from '../../dashboard/fund-summary-widget/components/fund-tabs-row/FundTabsRow';
import {
  BundledExportProps,
  Func,
  FundPerformanceKpiGroups,
  FundPerformanceWidgetProps,
  KpiTabs,
  StyleVariant,
} from '../../../types';
import FundPerformanceMoicChart from './components/fund-performance-moic-chart/FundPerformanceMoicChart';
import FundPerformancePiccChart from './components/fund-performance-picc-chart/FundPerformancePiccChart';
import FundPerformanceNavChart from './components/fund-performance-nav-chart/FundPerformanceNavChart';
import styles from './FundPerformanceWidget.module.scss';
import {exportsWorker} from '../../../utils/export';

interface FundPerformanceWidgetComponent extends FundPerformanceWidgetProps {
  getBenchmarkData?: Func<[string, string, FundPerformanceKpiGroups], void>;
  clearBenchmarkData?: Func<[FundPerformanceKpiGroups], void>;
}

const FundPerformanceWidget: React.FC<FundPerformanceWidgetComponent> = ({
  nav,
  netIrrGrossIrrMOIC,
  piccDpiRvpiTvpi,
  clickHandler,
  styleType = StyleVariant.Primary,
  getBenchmarkData,
  clearBenchmarkData,
  id,
  signOffDate,
}) => {
  const [activeTab, setActiveTab] = useState(KpiTabs.Nav);

  const {fundName} = useFundsDetails();

  const getEntityForExportZipFile = useMemo(() => {
    if (fundName) {
      return fundName.split(' ').join('');
    }
    return '';
  }, [fundName]);

  const handleSetActiveTab = (id: KpiTabs) => {
    setActiveTab(id);
    if (clickHandler) {
      clickHandler(id);
    }
  };

  const exportHandler = async ({
    zipFileName,
    tableExportPayload,
    imagesExportPayload,
  }: BundledExportProps) => {
    const exportedData = await exportsWorker.exportZippedChartData(
      tableExportPayload,
      imagesExportPayload,
      proxy(htmlToImage)
    );

    downloadZippedExport({
      name: zipFileName,
      blob: exportedData,
    });
  };

  return (
    <div
      className={classnames(styles.wrapper, styles[`wrapper__${styleType}`])}
    >
      <FundTabsRow
        clickHandler={handleSetActiveTab}
        activeTab={activeTab}
        nav={nav}
        netIrrGrossIrrMOIC={netIrrGrossIrrMOIC}
        piccDpiRvpiTvpi={piccDpiRvpiTvpi}
        className={styles.tabsRow}
        variant={StyleVariant.Secondary}
      />
      <div className={styles.inner}>
        {activeTab === KpiTabs.Nav && (
          <FundPerformanceNavChart
            {...nav}
            getBenchmarkData={getBenchmarkData}
            clearBenchmarkData={clearBenchmarkData}
            exportHandler={exportHandler}
            entityName={getEntityForExportZipFile}
            id={id}
            signOffDate={signOffDate}
          />
        )}
        {activeTab === KpiTabs.NetIrr && (
          <FundPerformanceMoicChart
            {...netIrrGrossIrrMOIC}
            getBenchmarkData={getBenchmarkData}
            clearBenchmarkData={clearBenchmarkData}
            exportHandler={exportHandler}
            entityName={getEntityForExportZipFile}
            id={id}
            signOffDate={signOffDate}
          />
        )}
        {activeTab === KpiTabs.DpiRvpi && (
          <FundPerformancePiccChart
            {...piccDpiRvpiTvpi}
            exportHandler={exportHandler}
            entityName={getEntityForExportZipFile}
            id={id}
            signOffDate={signOffDate}
          />
        )}
      </div>
    </div>
  );
};

export default FundPerformanceWidget;
