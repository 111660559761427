import React from 'react';
import {ColumnDef, createColumnHelper} from '@tanstack/react-table';
import Th from '../../cells/th/Th';
import Td from '../../cells/td/Td';
import NameCell from '../../cells/name-cell/NameCell';
import {FlexHAlign, HeaderMapping} from '../../../../../types/index';
import {
  PortfolioTransactionData,
  PortfolioTransactionsRow,
} from '../../../../../types';
import routePaths from '../../../../../routePaths';
import styles from '../TransactionsTable.module.scss';
import Checkbox from '../../../checkbox/Checkbox';
import IconButton from '../../../icon-button/IconButton';
import {getTableColumnTestAttr} from '../../../../../utils/strings';

const genPortfolioTransactionsTableColumnDefs = (
  headerMapping: HeaderMapping[],
  footerMapping: any[],
  currentSelectedTransactions: PortfolioTransactionData[],
  allCheckboxState: {
    checked: boolean;
    isIndeterminate: boolean;
  },
  handleTransactionSelection: (
    checked: boolean,
    transaction?: PortfolioTransactionData
  ) => void,
  handleOpenUploadDocumentsModal: (
    transaction: PortfolioTransactionData
  ) => void
): ColumnDef<PortfolioTransactionsRow, any>[] => {
  const columnHelper = createColumnHelper<PortfolioTransactionsRow>();

  return [
    columnHelper.accessor('isSelected', {
      id: 'isSelected',
      header: props => (
        <th
          className={styles.checkboxWrapper}
          data-test="table-header-is-selected"
        >
          <Checkbox
            label={''}
            checked={allCheckboxState.checked}
            isIndeterminate={allCheckboxState.isIndeterminate}
            onChange={e => handleTransactionSelection(e.target.checked)}
          />
        </th>
      ),
      cell: props => (
        <Td
          {...props}
          align={FlexHAlign.start}
          canHide={false}
          skipExtend={true}
          id="table-column-is-selected"
        >
          <Checkbox
            onChange={e =>
              handleTransactionSelection(e.target.checked, props.row.original)
            }
            label={''}
            checked={
              currentSelectedTransactions &&
              currentSelectedTransactions.some(
                item => item.id === props.row.original.id
              )
            }
          />
        </Td>
      ),
      footer: props => (
        <Th
          {...props}
          id="table-footer-is-selected"
          footerMapping={footerMapping}
          align={FlexHAlign.start}
          width={'max(100vw*0.01, 30px)'}
          className={styles.footerLabel}
        />
      ),
    }),
    columnHelper.accessor('companyName', {
      id: 'companyName',
      header: props => (
        <Th
          {...props}
          headerMapping={headerMapping}
          align={FlexHAlign.start}
          width={'max(100vw*0.21, 150px)'}
        />
      ),
      cell: props => (
        <Td
          {...props}
          align={FlexHAlign.start}
          width={'max(100vw*0.18, 150px)'}
          canHide={false}
          skipExtend={true}
          id={getTableColumnTestAttr(headerMapping, props.column.id)}
        >
          <NameCell
            name={props?.getValue<string>()}
            icon={
              props.row.original.companyColor
                ? props.row.original.companyColor
                : undefined
            }
            iconUrl={props.row.original.companyLogo}
            expanded={false}
            skipExtend={true}
            className={styles.nameCell}
            // TODO: Linking to Investment Overview for V1, later on according to subscription
            linkTo={`${routePaths.PORTFOLIO_COMPANIES}/${props.row.original.companyId}/investment-overview`}
          />
        </Td>
      ),
      footer: props => (
        <Th
          {...props}
          id="table-footer-portfolio-company"
          footerMapping={footerMapping}
          align={FlexHAlign.start}
          width={'max(100vw*0.18, 150px)'}
          padding={'0 0 0 24px'}
          className={styles.footerLabel}
        />
      ),
    }),
    columnHelper.accessor('instrumentType', {
      id: 'instrumentType',
      header: props => (
        <Th {...props} headerMapping={headerMapping} align={FlexHAlign.start} />
      ),
      cell: props => (
        <Td
          {...props}
          align={FlexHAlign.start}
          skipExtend={true}
          id={getTableColumnTestAttr(headerMapping, props.column.id)}
        />
      ),
      footer: props => (
        <Th
          {...props}
          id="table-footer-instrument-type"
          footerMapping={footerMapping}
          align={FlexHAlign.start}
          width={'max(100vw*0.18, 150px)'}
          className={styles.footerLabel}
        />
      ),
    }),
    columnHelper.accessor('currency', {
      id: 'currency',
      header: props => (
        <Th {...props} headerMapping={headerMapping} align={FlexHAlign.start} />
      ),
      cell: props => (
        <Td
          {...props}
          align={FlexHAlign.start}
          skipExtend={true}
          id={getTableColumnTestAttr(headerMapping, props.column.id)}
        />
      ),
      footer: props => (
        <Th
          {...props}
          id="table-footer-currency"
          footerMapping={footerMapping}
          align={FlexHAlign.start}
          width={'max(100vw*0.02, 96px)'}
          className={styles.footerLabel}
        />
      ),
    }),
    columnHelper.accessor(row => row.shares?.value ?? 0, {
      id: 'shares',
      header: props => (
        <Th {...props} headerMapping={headerMapping} align={FlexHAlign.end} />
      ),
      cell: props => (
        <Td
          {...props}
          align={FlexHAlign.end}
          nestedAccessorFn={original => original.shares}
          skipExtend={true}
          id={getTableColumnTestAttr(headerMapping, props.column.id)}
        />
      ),
      footer: props => (
        <Th
          {...props}
          id="table-footer-shares"
          footerMapping={footerMapping}
          align={FlexHAlign.end}
          width={'max(100vw*0.18, 150px)'}
          className={styles.footerLabel}
        />
      ),
    }),
    columnHelper.accessor(row => row.date?.value, {
      id: 'date',
      header: props => (
        <Th headerMapping={headerMapping} {...props} align={FlexHAlign.end} />
      ),
      cell: props => (
        <Td
          {...props}
          align={FlexHAlign.end}
          nestedAccessorFn={original => original.date}
          skipExtend={true}
          id={getTableColumnTestAttr(headerMapping, props.column.id)}
        />
      ),
      footer: props => (
        <Th
          {...props}
          id="table-footer-date"
          footerMapping={footerMapping}
          align={FlexHAlign.end}
          width={'max(100vw*0.18, 150px)'}
          className={styles.footerLabel}
        />
      ),
    }),
    columnHelper.accessor(row => row.cost?.value ?? 0, {
      id: 'cost',
      header: props => (
        <Th {...props} headerMapping={headerMapping} align={FlexHAlign.end} />
      ),
      cell: props => (
        <Td
          {...props}
          align={FlexHAlign.end}
          nestedAccessorFn={original => original.cost}
          skipExtend={true}
          id={getTableColumnTestAttr(headerMapping, props.column.id)}
        />
      ),
      footer: props => (
        <Th
          {...props}
          id="table-footer-cost"
          footerMapping={footerMapping}
          align={FlexHAlign.end}
          width={'max(100vw*0.18, 150px)'}
          className={styles.footerLabel}
        />
      ),
    }),
    columnHelper.accessor(row => row.realisedGains?.value ?? 0, {
      id: 'realisedGains',
      header: props => (
        <Th {...props} headerMapping={headerMapping} align={FlexHAlign.end} />
      ),
      cell: props => (
        <Td
          {...props}
          align={FlexHAlign.end}
          nestedAccessorFn={original => original.realisedGains}
          skipExtend={true}
          id={getTableColumnTestAttr(headerMapping, props.column.id)}
        />
      ),
      footer: props => (
        <Th
          {...props}
          id="table-footer-realised-gain-loss"
          footerMapping={footerMapping}
          align={FlexHAlign.end}
          width={'max(100vw*0.18, 150px)'}
          className={styles.footerLabel}
        />
      ),
    }),
    columnHelper.accessor(row => row.investmentIncome?.value ?? 0, {
      id: 'investmentIncome',
      header: props => (
        <Th {...props} headerMapping={headerMapping} align={FlexHAlign.end} />
      ),
      cell: props => (
        <Td
          {...props}
          align={FlexHAlign.end}
          nestedAccessorFn={original => original.investmentIncome}
          skipExtend={true}
          id={getTableColumnTestAttr(headerMapping, props.column.id)}
        />
      ),
      footer: props => (
        <Th
          {...props}
          id="table-footer-investment-income"
          footerMapping={footerMapping}
          align={FlexHAlign.end}
          width={'max(100vw*0.18, 150px)'}
          className={styles.footerLabel}
        />
      ),
    }),
    columnHelper.accessor('file', {
      id: 'file',
      header: props => (
        <Th {...props} headerMapping={headerMapping} align={FlexHAlign.end} />
      ),
      cell: props => (
        <Td
          {...props}
          align={FlexHAlign.end}
          skipExtend={true}
          emptyValue={'-'}
          id={getTableColumnTestAttr(headerMapping, props.column.id)}
        />
      ),
      footer: props => (
        <Th
          {...props}
          id="table-footer-file"
          footerMapping={footerMapping}
          align={FlexHAlign.start}
          width={'max(100vw*0.02, 96px)'}
          className={styles.footerLabel}
        />
      ),
    }),
    columnHelper.display({
      id: 'upload',
      header: props => <Th {...props} id="table-header-upload-document" />,
      cell: props => (
        <Td
          {...props}
          align={FlexHAlign.start}
          canHide={false}
          skipExtend={true}
          id="table-column-upload-document"
        >
          <IconButton
            className={styles.actionButton}
            onClick={() => handleOpenUploadDocumentsModal(props.row.original)}
            icon={'document'}
          />
        </Td>
      ),
    }),
  ];
};

export default genPortfolioTransactionsTableColumnDefs;
