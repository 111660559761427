import {TableSettings} from '../../../../../types';

export const fundPerformancePiccChartSettings = (
  zipFileName: string
): TableSettings => {
  return {
    fileName: zipFileName,
    name: 'Fund Performance PICC',
    columnVisibility: {quarter: false},
    headerMapping: [
      {
        columnId: 'PICC',
        label: 'PICC',
      },
      {
        columnId: 'DPI',
        label: 'DPI',
      },
      {
        columnId: 'RVPI',
        label: 'RVPI',
      },
      {
        columnId: 'TVPI',
        label: 'TVPI',
      },
      {
        columnId: 'quarter',
        label: 'Quarter',
      },
      {
        columnId: 'date',
        label: 'Date',
      },
    ],
    columnOrder: ['PICC', 'DPI', 'RVPI', 'TVPI', 'quarter', 'date'],
    meta: {
      columns: {
        PICC: {
          styling: {
            align: 'right',
          },
        },
        DPI: {
          styling: {
            align: 'right',
          },
        },
        RVPI: {
          styling: {
            align: 'right',
          },
        },
        TVPI: {
          styling: {
            align: 'right',
          },
        },
        quarter: {
          styling: {
            align: 'right',
          },
        },
        date: {
          styling: {
            align: 'right',
          },
          formatter: 'numFmt:dd-mm-yyyy',
        },
      },
    },
  };
};

export const getImagesExportPayload = (zipFileName: string) => {
  return {
    id: 'performance-picc-chart',
    fileName: zipFileName,
    opts: [
      {
        fileName: `${zipFileName}_TransparentBackground`,
        type: 'image/svg+xml',
        ext: 'svg',
        bgColor: 'transparent',
        elsToFilter: [
          'performance-chart-brush',
          'performance-chart-export',
          'performance-benchmark-toggle',
          'recharts-tooltip-wrapper',
        ],
      },
      {
        fileName: `${zipFileName}_WithBackground`,
        type: 'image/svg+xml',
        ext: 'svg',
        bgColor: '#202020',
        elsToFilter: [
          'performance-chart-brush',
          'performance-chart-export',
          'performance-benchmark-toggle',
          'recharts-tooltip-wrapper',
        ],
      },
      {
        fileName: `${zipFileName}_TransparentBackground`,
        type: 'image/png',
        ext: 'png',
        bgColor: 'transparent',
        elsToFilter: [
          'performance-chart-brush',
          'performance-chart-export',
          'performance-benchmark-toggle',
          'recharts-tooltip-wrapper',
        ],
      },
      {
        fileName: `${zipFileName}_WithBackground`,
        type: 'image/png',
        ext: 'png',
        bgColor: '#202020',
        elsToFilter: [
          'performance-chart-brush',
          'performance-chart-export',
          'performance-benchmark-toggle',
          'recharts-tooltip-wrapper',
        ],
      },
    ],
  };
};
