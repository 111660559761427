import axios from 'axios';
import config from '../config';
import {UserProfileData} from '../types/index';

export const getUserProfile = async (token: string) => {
  const {data} = await axios.get<UserProfileData>(
    `${config.APP_URI}/user-profile`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );
  return data;
};

export const getUserDataVisibility = async (
  token: string,
  userId: string,
  value: boolean
) => {
  const {data} = await axios.put<UserProfileData>(
    `${config.APP_URI}/user/${userId}/set-show-signed-off-data`,
    {
      showSignedOffData: value,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );
  return data;
};
