import React, {RefObject, useState} from 'react';
import classnames from 'classnames';
import {useChartAnimated} from '../../../hooks';
import {ButtonStyle, InsightWidgetData} from '../../../types';
import {IconButton} from '../../global';
import InsightTooltip from './components/insight-tooltip/InsightTooltip';
import AnimatedCount from './components/animated-count/AnimatedCount';
import DeltaIndicator from '../../dashboard/fund-summary-widget/components/fund-widget-header/components/delta-indicator/DeltaIndicator';
import styles from './InsightWidget.module.scss';

interface InsightWidgetProps extends InsightWidgetData {
  wrapperRef?: RefObject<HTMLElement>;
  index?: number;
}

const InsightWidgetLite: React.FC<InsightWidgetProps> = ({
  id,
  title,
  data,
  info,
  deltaIndicator,
}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const {ref: elemRef, isAnimated} = useChartAnimated();

  return (
    <div
      ref={elemRef}
      className={classnames(styles.wrapper, tooltipOpen && styles.tooltipOpen)}
      data-test={id}
    >
      <div className={styles.top}>
        <div className={styles.title}>{title}</div>
        {info && (
          <IconButton
            className={styles.infoButton}
            disabled={tooltipOpen}
            styleType={ButtonStyle.Primary}
            onClick={() => setTooltipOpen(prev => !prev)}
            icon="info"
            id={`${id}-info-btn`}
          />
        )}
      </div>
      <div className={styles.value}>
        {isAnimated && <AnimatedCount duration={1000} number={data.value} />}
        <span className={styles.unit}>{data.unit}</span>
        {deltaIndicator && <DeltaIndicator {...deltaIndicator} />}
      </div>
      {info && (
        <InsightTooltip
          handleClose={() => setTooltipOpen(false)}
          isOpen={tooltipOpen}
          info={info}
        />
      )}
    </div>
  );
};

export default InsightWidgetLite;
