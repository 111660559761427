import React from 'react';
import {Pie, PieChart, ResponsiveContainer} from 'recharts';
import {useTooltip} from '../../../../../hooks';
import {Position} from '../../../../../context/TooltipContext';
import {InfoHeaderType} from '../../../../../types/index';
import {CellLabel, ValueFormat} from '../../../index';
import styles from './ChartCell.module.scss';

const ChartCell: React.FC<InfoHeaderType> = ({
  value,
  format,
  label,
  tooltip,
}) => {
  const {showTooltip, hideTooltip} = useTooltip();

  const handleMouseEnter =
    (content: string | undefined) => (e: React.MouseEvent) => {
      if (!content) return;
      showTooltip({
        content,
        position: Position.Top,
        target: e.currentTarget as HTMLElement,
      });
    };

  const data = [
    {value, fill: `rgb(var(--colors-gray-4))`},
    {
      value: 100 - +((value as number | undefined | string) || 0),
      fill: `rgb(var(--colors-gray-7))`,
    },
  ];
  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <CellLabel label={label} />
        <div className={styles.value}>
          <ValueFormat
            value={value as number | undefined | string}
            format={format}
          />
        </div>
      </div>
      <div
        className={styles.chart}
        onMouseEnter={handleMouseEnter(tooltip)}
        onMouseLeave={hideTooltip}
      >
        <ResponsiveContainer aspect={1} width="100%">
          <PieChart>
            <Pie
              data={data}
              dataKey="value"
              startAngle={-270}
              innerRadius="85%"
              outerRadius="100%"
              stroke="none"
            />
          </PieChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default ChartCell;
