import {useAppSelector} from './useReduxHooks';
import {dataCollectionAdminSelector} from '../selectors';

const useAdminDataCollection = () => {
  const state = useAppSelector(state => state);
  const data = dataCollectionAdminSelector(state);
  return data;
};

export default useAdminDataCollection;
