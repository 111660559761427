import React, {useEffect, useRef, useState} from 'react';
import {AccordionProps, StyleVariant} from '../../../types';
import styles from './Accordion.module.scss';
import {Icon} from '../index';
import classnames from 'classnames';

const Accordion: React.FC<AccordionProps> = ({
  title,
  subTitle,
  headerChildren = [],
  children,
  isDefaultExpanded = false,
  styleVariant = StyleVariant.Primary,
}) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(isDefaultExpanded);
  const contentInnerRef = useRef<HTMLDivElement>(null);
  const [contentHeight, setContentHeight] = useState<string | number>(
    isDefaultExpanded
      ? 'max-content'
      : contentInnerRef.current
      ? contentInnerRef.current.scrollHeight + 2
      : 0
  );

  useEffect(() => {
    if (!contentInnerRef.current) return;
    const resizeObserver = new ResizeObserver(() => {
      if (!contentInnerRef.current) return;
      setContentHeight(contentInnerRef.current.scrollHeight + 2);
    });
    resizeObserver.observe(contentInnerRef.current);
    return () => resizeObserver.disconnect();
  }, []);

  return (
    <div
      className={classnames(
        styles.wrapper,
        isExpanded && styles.expanded,
        styles[`wrapper__${styleVariant}`]
      )}
    >
      <div className={styles.header}>
        <div className={styles.title}>{title}</div>
        {subTitle && <div className={styles.subTitle}>{subTitle}</div>}
        {isExpanded && (
          <div className={styles.headerChildren}>{headerChildren}</div>
        )}
        <div
          onClick={() => setIsExpanded(prev => !prev)}
          className={styles.expandButton}
        >
          <Icon name={'chevron-down'} />
        </div>
      </div>
      <div
        style={{
          maxHeight: isExpanded ? contentHeight : 0,
        }}
        className={classnames(styles.content, isExpanded && styles.expanded)}
      >
        <div ref={contentInnerRef}>{children}</div>
      </div>
    </div>
  );
};

export default Accordion;
