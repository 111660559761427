import React, {useState} from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import {useChartAnimated} from '../../../hooks';
import CustomYAxisTick from '../custom-y-axis-tick/CustomYAxisTick';
import styles from './BarChartWidget.module.scss';
import {BarChartData, Func} from '../../../types';
import classnames from 'classnames';
import BarChartTooltip from './components/BarChartTooltip';
import BarChartLabel from './components/BarChartLabel';

interface BarChartWidgetProps<T> {
  timeSeries: Array<BarChartData<T>>;
  yAxisFormat?: string;
  className?: string;
  onBarClick?: Func<[T], void>;
  yAxisLabel?: string;
}
const BarChartWidget = <T extends object>({
  timeSeries,
  yAxisFormat,
  className,
  onBarClick,
  yAxisLabel,
}: BarChartWidgetProps<T>) => {
  const {ref, isAnimated} = useChartAnimated();
  const [hoverActive, setHoverActive] = useState(null);

  const isSafari =
    navigator.userAgent.indexOf('Chrome') === -1 &&
    navigator.userAgent.indexOf('Safari') > -1;

  return (
    <div
      ref={ref}
      className={classnames(styles.wrapper, className)}
      id="bar-chart-widget-wrapper"
    >
      <ResponsiveContainer id="bar-chart-widget" width="100%" height={435}>
        <BarChart data={timeSeries} margin={{left: 60, right: 16}}>
          <defs>
            <linearGradient
              id="barChartWidgetGradient"
              x1="0"
              x2="0"
              y1="0"
              y2="1"
            >
              <stop offset={0} stopColor={`rgb(153, 0, 255)`} />
              <stop offset={'33%'} stopColor={`rgb(127, 0, 229)`} />
              <stop offset={'67%'} stopColor={`rgb(102, 0, 204)`} />
              <stop offset={'100%'} stopColor={`rgb(51, 0, 153)`} />
            </linearGradient>
            <linearGradient
              id="barChartWidgetGradientNegative"
              x1="0"
              x2="0"
              y1="0"
              y2="1"
            >
              <stop offset={0} stopColor={`rgba(219, 7, 7, 1)`} />
              <stop offset={'100%'} stopColor={`rgba(255, 100, 100, 1)`} />
            </linearGradient>
          </defs>
          <CartesianGrid
            vertical={false}
            strokeDasharray="2"
            stroke={`rgb(var(--colors-gray-6))`}
            width={1920}
            x={0}
          />
          <XAxis dataKey="x" axisLine={false} tickLine={false} tick={false} />
          <YAxis
            dataKey="y"
            axisLine={false}
            tickLine={false}
            width={1}
            tick={
              <CustomYAxisTick
                showAxis={true}
                xOffset={-20}
                yOffset={4}
                fill="gray-3"
                fontSize={12}
                fontWeight={500}
                format={yAxisFormat}
              />
            }
          />
          <Bar
            shape={({
              dataKey,
              tooltipPosition,
              tooltipPayload,
              additionalData,
              ...restProps
            }: any) => {
              const y =
                restProps.height > 0
                  ? restProps.y
                  : restProps.y - Math.abs(restProps.height);
              return (
                <rect
                  {...restProps}
                  y={y}
                  height={Math.abs(restProps.height)}
                  style={{
                    cursor: 'pointer',
                    fillOpacity: hoverActive === restProps.index ? 0.8 : 1,
                    fill:
                      restProps.value >= 0
                        ? `url(#barChartWidgetGradient)`
                        : `url(#barChartWidgetGradientNegative)`,
                    stroke:
                      hoverActive === restProps.index
                        ? 'rgb(var(--colors-white))'
                        : 'none',
                  }}
                  onMouseEnter={() => setHoverActive(restProps.index)}
                  onMouseLeave={() => setHoverActive(null)}
                />
              );
            }}
            isAnimationActive={isAnimated}
            hide={!isAnimated}
            dataKey={'y'}
            fill={'none'}
            barSize={30}
            minPointSize={5}
            onClick={props => onBarClick && onBarClick(props.additionalData)}
          >
            {!isSafari && (
              <LabelList
                dataKey={'x'}
                position="insideBottom"
                angle={-90}
                content={<BarChartLabel />}
              />
            )}
          </Bar>
          <Tooltip
            cursor={false}
            content={<BarChartTooltip yAxisLabel={yAxisLabel} />}
            offset={20}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default BarChartWidget;
