import React from 'react';
import {Button} from '../../global';
import {ButtonStyle} from '../../../types';
import styles from './ExpandedRowHeader.module.scss';
import {useTranslation} from 'react-i18next';

interface Props {
  transactionsCount: number;
  handleImport: () => void;
  handleAddNew: () => void;
  disabled: boolean;
}

const ExpandedRowHeader = ({
  transactionsCount,
  handleImport,
  handleAddNew,
  disabled,
}: Props) => {
  const {t} = useTranslation();
  return (
    <div className={styles.headerWrapper}>
      <div className={styles.transactionsWrapper}>
        <div className={styles.transactions}>{t('Global.Transactions')}</div>
        <div className={styles.transactionsCount}>{transactionsCount}</div>
      </div>
      <div className={styles.buttonsWrapper}>
        <Button
          onClick={handleImport}
          styleType={ButtonStyle.Secondary}
          text={t(
            'ScenarioDetailsValuation.ExpandedRowHeader.Button.Label.Import'
          )}
          disabled={disabled}
        />
        <Button
          onClick={handleAddNew}
          styleType={ButtonStyle.Primary}
          text={t('Global.AddNew')}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

export default ExpandedRowHeader;
