import {ColumnDef, createColumnHelper} from '@tanstack/react-table';
import {
  DocumentRepositoryTableRowItem,
  FileActionTypes,
  FlexHAlign,
  Func,
  HeaderMapping,
  TagItem,
  UserRole,
} from '../../../../types';
import Th from '../cells/th/Th';
import Td from '../cells/td/Td';
import React from 'react';
import styles from './DocumentRepositoryTableColumnDefs.module.scss';
import {IconButton} from '../..';
import DocumentNameCell from './components/document-name-cell/DocumentNameCell';
import TagsCell from './components/tags-cell/TagsCell';
import classnames from 'classnames';
import {formatToHyphenatedString} from '../../../../utils/strings';

export const genDocumentRepositoryColumnDefs = (
  headerMapping: HeaderMapping[],
  userRole: UserRole[] | undefined,
  filteredTags: TagItem[],
  downloadPendingIds: string[],
  openEditModalHandler: Func<[string], void>,
  openDeleteConfirmModalHandler: Func<[{id: string; name: string}], void>,
  onDownloadFile: Func<[string], void>,
  isActionButtonsDisabled?: boolean
): ColumnDef<DocumentRepositoryTableRowItem, any>[] => {
  const columnHelper = createColumnHelper<DocumentRepositoryTableRowItem>();
  return [
    columnHelper.accessor(row => row.fileName, {
      id: 'fileName',
      header: props => (
        <Th {...props} headerMapping={headerMapping} align={FlexHAlign.start} />
      ),
      cell: props => (
        <Td
          className={styles.cell}
          {...props}
          align={FlexHAlign.start}
          children={
            <DocumentNameCell
              fileName={props.row.original.fileName}
              fileType={props.row.original.fileType}
              fileSize={props.row.original.fileSize}
              isPrivate={props.row.original.isPrivate}
            />
          }
        />
      ),
    }),
    columnHelper.accessor(row => row.entityTags, {
      id: 'systemTags',
      header: props => (
        <Th {...props} headerMapping={headerMapping} align={FlexHAlign.start} />
      ),
      cell: props => (
        <Td
          className={styles.tagsCell}
          {...props}
          align={FlexHAlign.start}
          children={
            <TagsCell
              tagItems={[
                ...props.row.original.entityTags,
                ...props.row.original.sectionTags,
              ].filter(tag => !filteredTags.some(ss => ss.id === tag.id))}
            />
          }
        />
      ),
    }),
    columnHelper.accessor(row => row.customTags, {
      id: 'customTags',
      header: props => (
        <Th {...props} headerMapping={headerMapping} align={FlexHAlign.start} />
      ),
      cell: props => (
        <Td
          className={styles.tagsCell}
          {...props}
          align={FlexHAlign.start}
          children={
            <TagsCell
              tagItems={
                props.row.original.customTags.filter(
                  tag =>
                    !filteredTags.some(
                      ss => ss.id === tag.id || ss.label === tag.label
                    )
                ) ?? []
              }
            />
          }
        />
      ),
    }),
    columnHelper.accessor(row => row.owner, {
      id: 'owner',
      header: props => (
        <Th {...props} headerMapping={headerMapping} align={FlexHAlign.start} />
      ),
      cell: props => (
        <Td className={styles.cell} {...props} align={FlexHAlign.start} />
      ),
    }),
    columnHelper.accessor(row => row.lastModified.value, {
      id: 'lastModified',
      header: props => (
        <Th {...props} headerMapping={headerMapping} align={FlexHAlign.start} />
      ),
      cell: props => {
        return (
          <Td
            className={styles.cell}
            {...props}
            align={FlexHAlign.start}
            nestedAccessorFn={original => original.lastModified}
          />
        );
      },
    }),
    columnHelper.display({
      id: 'edit',
      header: props => (
        <Th
          {...props}
          align={FlexHAlign.center}
          width={'40px'}
          headerMapping={headerMapping}
        />
      ),
      cell: props => {
        const isDownloadPending =
          downloadPendingIds &&
          downloadPendingIds.includes(props.row.original.id);
        return (
          <Td {...props} align={FlexHAlign.end}>
            {!isActionButtonsDisabled && (
              <div className={styles.actionButtons}>
                {props.row.original.actions.map(action => {
                  if (action.type === FileActionTypes.Edit) {
                    return (
                      <IconButton
                        id={`document-repository-table-${formatToHyphenatedString(
                          action.type
                        )}-btn`}
                        key={`${props.row.original.id}-${action.type}`}
                        className={styles.actionButton}
                        onClick={() =>
                          openEditModalHandler(props.row.original.id)
                        }
                        icon={'pencil'}
                        disabled={!action.enabled}
                      />
                    );
                  }

                  if (action.type === FileActionTypes.Download) {
                    return (
                      <IconButton
                        id={`document-repository-table-${formatToHyphenatedString(
                          action.type
                        )}-btn`}
                        key={`${props.row.original.id}-${action.type}`}
                        loading={isDownloadPending}
                        className={classnames(
                          styles.actionButton,
                          isDownloadPending && styles.isVisible
                        )}
                        onClick={() => onDownloadFile(props.row.original.id)}
                        icon={'download'}
                        disabled={!action.enabled}
                      />
                    );
                  }

                  if (action.type === FileActionTypes.Delete) {
                    return (
                      <IconButton
                        id={`document-repository-table-${formatToHyphenatedString(
                          action.type
                        )}-btn`}
                        key={`${props.row.original.id}-${action.type}`}
                        className={styles.actionButton}
                        onClick={() =>
                          openDeleteConfirmModalHandler({
                            id: props.row.original.id,
                            name: props.row.original.fileName,
                          })
                        }
                        disabled={!action.enabled}
                        icon={'trash-can'}
                      />
                    );
                  }

                  return <></>;
                })}
              </div>
            )}
          </Td>
        );
      },
    }),
  ];
};

export default genDocumentRepositoryColumnDefs;
