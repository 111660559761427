import React, {useState} from 'react';
import {PortfolioExposureWidgetProps} from '../../../types/index';
import {SegmentedControl} from '../../global';
import {SegmentedControlVariants} from '../../global/segmented-control/SegmentedControl';
import Chart from './components/chart/Chart';
import Table from './components/table/Table';
import styles from './PortfolioExposureWidget.module.scss';
import {useChartAnimated} from '../../../hooks';

enum Tabs {
  Chart = 'chart',
  Table = 'table',
}

const PortfolioExposureWidget: React.FC<PortfolioExposureWidgetProps> = ({
  title,
  currency,
  data,
  labels,
  format,
}) => {
  const [activeTab, setActiveTab] = useState<Tabs>(Tabs.Chart);

  const {ref, isAnimated} = useChartAnimated();

  return (
    <div
      className={styles.wrapper}
      ref={ref}
      id={`exposure-chart-${title.split(' ').join('-').toLowerCase()}`}
    >
      <div className={styles.title}>
        <span>{title}</span>
        <SegmentedControl
          id="portfolio-exposure-widget-segmented-control"
          options={[
            {id: 'chart', icon: 'chart', value: Tabs.Chart},
            {id: 'table', icon: 'table', value: Tabs.Table},
          ]}
          value={activeTab}
          onChange={val => setActiveTab(val as Tabs)}
          variant={SegmentedControlVariants.Secondary}
          className="exposure-chart-radio-buttons"
        />
      </div>
      {activeTab === Tabs.Table && (
        <Table
          data={data}
          currency={currency}
          labels={labels}
          format={format}
        />
      )}
      {activeTab === Tabs.Chart && (
        <Chart isAnimated={isAnimated} data={data} labels={labels} />
      )}
    </div>
  );
};

export default PortfolioExposureWidget;
