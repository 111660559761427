import {TableSettings} from '../../../../../types';

export const performanceRoiIrrChartSettings = (
  zipFileName: string
): TableSettings => {
  return {
    fileName: zipFileName,
    name: 'Net IRR ROI',
    columnVisibility: {quarter: false},
    headerMapping: [
      {
        columnId: 'Net IRR',
        label: 'Net IRR',
      },
      {
        columnId: 'ROI',
        label: 'ROI',
      },
      {
        columnId: 'quarter',
        label: 'Quarter',
      },
      {
        columnId: 'date',
        label: 'Date',
      },
    ],
    columnOrder: ['Net IRR', 'ROI', 'quarter', 'date'],
    meta: {
      columns: {
        'Net IRR': {
          formatter: 'percentage',
          styling: {
            align: 'right',
          },
        },
        ROI: {
          styling: {
            align: 'right',
          },
        },
        quarter: {
          styling: {
            align: 'right',
          },
        },
        date: {
          styling: {
            align: 'right',
          },
          formatter: 'numFmt:dd-mm-yyyy',
        },
      },
    },
  };
};

export const performanceRoiChartSettings = (
  zipFileName: string
): TableSettings => {
  return {
    fileName: zipFileName,
    name: 'Net ROI',
    columnVisibility: {quarter: false},
    headerMapping: [
      {
        columnId: 'ROI',
        label: 'ROI',
      },
      {
        columnId: 'quarter',
        label: 'Quarter',
      },
      {
        columnId: 'date',
        label: 'Date',
      },
    ],
    columnOrder: ['ROI', 'quarter', 'date'],
    meta: {
      columns: {
        ROI: {
          styling: {
            align: 'right',
          },
        },
        quarter: {
          styling: {
            align: 'right',
          },
        },
        date: {
          styling: {
            align: 'right',
          },
          formatter: 'numFmt:dd-mm-yyyy',
        },
      },
    },
  };
};

export const getPerformanceRoiIrrTableExportPayload = (
  data: readonly object[],
  isConsolidatedData: boolean,
  zipFileName: string
) => {
  const performanceRoiChartTableSettings =
    performanceRoiChartSettings(zipFileName);
  const performanceRoiIrrChartTableSettings =
    performanceRoiIrrChartSettings(zipFileName);

  return [
    {
      data,
      mappings: isConsolidatedData
        ? performanceRoiChartTableSettings.headerMapping
        : performanceRoiIrrChartTableSettings.headerMapping,
      settings: isConsolidatedData
        ? performanceRoiChartTableSettings
        : performanceRoiIrrChartTableSettings,
    },
  ];
};

export const getPerformanceRoiIrrImagesExportPayload = (
  zipFileName: string,
  id: string | undefined
) => {
  return {
    id: `${id}-performance-roi-irr-chart`,
    fileName: zipFileName,
    opts: [
      {
        fileName: `${zipFileName}_TransparentBackground`,
        type: 'image/svg+xml',
        ext: 'svg',
        bgColor: 'transparent',
        elsToFilter: ['performance-chart-export', 'recharts-tooltip-wrapper'],
      },
      {
        fileName: `${zipFileName}_WithBackground`,
        type: 'image/svg+xml',
        ext: 'svg',
        bgColor: '#202020',
        elsToFilter: ['performance-chart-export', 'recharts-tooltip-wrapper'],
      },
      {
        fileName: `${zipFileName}_TransparentBackground`,
        type: 'image/png',
        ext: 'png',
        bgColor: 'transparent',
        elsToFilter: ['performance-chart-export', 'recharts-tooltip-wrapper'],
      },
      {
        fileName: `${zipFileName}_WithBackground`,
        type: 'image/png',
        ext: 'png',
        bgColor: '#202020',
        elsToFilter: ['performance-chart-export', 'recharts-tooltip-wrapper'],
      },
    ],
  };
};
