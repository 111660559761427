import React, {Fragment, useEffect, useMemo, useRef, useState} from 'react';
import {
  FileUploader,
  GenericTable,
  LoadingSpinner,
  Modal,
} from '../../../../global';
import CommonTags from '../common-tags/CommonTags';
import styles from './UploadDocumentsModal.module.scss';
import {
  ButtonStyle,
  DocumentRepositoryTableRowItem,
  SectionTag,
  UploadDocumentsModalProps,
  UserRole,
} from '../../../../../types';
import {DocumentExplorerTablePlaceholder} from '../../../../document-explorer';
import genDocumentRepositoryColumnDefs from '../../../table/document-repository-table/DocumentRepositoryTableColumnDefs';
import {documentRepositoryTableDefaultSorting} from '../../../../../constants';
import {useTranslation} from 'react-i18next';
import TransactionsInfo from '../transactions-info/TransactionsInfo';
import ReportingPeriodsInfo from '../transactions-info/ReportingPeriodsInfo';
import CommonReportingPeriods from '../common-reporting-periods/CommonReportingPeriods';
import NoDocumentsAvailable from '../transactions-info/NoDocumentsAvailable';

const UploadDocumentsModal: React.FC<UploadDocumentsModalProps> = ({
  isOpen,
  onClose,
  isLoading,
  onDropHandler,
  data,
  tags,
  handleOpenDeleteModal,
  handleDownloadFile,
  downloadPendingIds,
  userRole,
  handleOpenEditModal,
  sectionTag,
  transactionsInfo,
  reportingPeriodsInfo,
}) => {
  const fileUploaderWrapperRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState<number | undefined>(0);
  const {t} = useTranslation();

  const isActionButtonsDisabled =
    sectionTag === SectionTag.DataCollectionDetails ||
    sectionTag === SectionTag.DataCollectionReporting;

  const documentRepositoryColumnDefs = useMemo(() => {
    return genDocumentRepositoryColumnDefs(
      data?.headerMapping ?? [],
      userRole,
      [],
      downloadPendingIds,
      handleOpenEditModal,
      handleOpenDeleteModal,
      handleDownloadFile,
      isActionButtonsDisabled
    );
  }, [data?.data, downloadPendingIds]);

  const isCompanyAdmin = useMemo(
    () => userRole?.includes(UserRole.CompanyAdmin),
    [userRole]
  );

  useEffect(() => {
    setHeight(fileUploaderWrapperRef.current?.offsetHeight);
  }, [isLoading, fileUploaderWrapperRef.current?.offsetHeight, data?.data]);

  return (
    <Modal
      title={''}
      onClose={onClose}
      closeOnOutsideClick={false}
      isOpen={isOpen}
      className={styles.modalWrapper}
      closeButtonStyleType={ButtonStyle.Primary}
      style={{
        height: isLoading ? '60vh' : height ? `${height}px` : '60vh',
      }}
    >
      {isLoading ? (
        <LoadingSpinner className={styles.loadingSpinner} />
      ) : (
        <div ref={fileUploaderWrapperRef}>
          <FileUploader
            className={styles.fileUploader}
            onDropHandler={onDropHandler}
            topLeftChildren={
              reportingPeriodsInfo?.length ? (
                <CommonReportingPeriods
                  periods={reportingPeriodsInfo.map(item => item.period)}
                />
              ) : tags &&
                sectionTag !== SectionTag.FundPortfolioTransactions &&
                sectionTag !== SectionTag.PortfolioCompanyInvestmentOverview &&
                sectionTag !== SectionTag.DataCollectionDetails &&
                sectionTag !== SectionTag.DataCollectionReporting ? (
                <CommonTags title={t('Global.CommonTags')} tags={tags} />
              ) : null
            }
          >
            <div className={styles.fileUploadContent}>
              {sectionTag === SectionTag.FundPortfolioTransactions ||
              sectionTag === SectionTag.PortfolioCompanyInvestmentOverview ||
              sectionTag === SectionTag.DataCollectionDetails ||
              sectionTag === SectionTag.DataCollectionReporting ? (
                <div>
                  {tags && !isCompanyAdmin ? (
                    <div className={styles.tagsWrapper}>
                      <CommonTags title={t('Global.CommonTags')} tags={tags} />
                    </div>
                  ) : null}
                  {transactionsInfo ? (
                    <div className={styles.tagsWrapper}>
                      <TransactionsInfo transactionsInfo={transactionsInfo} />
                    </div>
                  ) : null}
                  {reportingPeriodsInfo?.length ? (
                    <div className={styles.tagsWrapper}>
                      <ReportingPeriodsInfo
                        reportingPeriods={reportingPeriodsInfo}
                      />
                    </div>
                  ) : null}
                </div>
              ) : null}
              {data?.data.length ? (
                <GenericTable<DocumentRepositoryTableRowItem>
                  data={data.data}
                  columns={documentRepositoryColumnDefs}
                  expandedView={() => <Fragment />}
                  getRowCanExpand={() => false}
                  defaultSorting={documentRepositoryTableDefaultSorting}
                  className={styles.documentsTable}
                  columnVisibility={{
                    systemTags: !userRole?.includes(UserRole.CompanyAdmin),
                  }}
                />
              ) : reportingPeriodsInfo?.length ? (
                <NoDocumentsAvailable />
              ) : (
                <DocumentExplorerTablePlaceholder />
              )}
            </div>
          </FileUploader>
        </div>
      )}
    </Modal>
  );
};

export default UploadDocumentsModal;
