export const portoflioLegendActions = [
  {
    id: 'id-1',
    label: 'Global.TotalCost',
    value: 'total-cost',
    color: `rgb(var(--colors-gray-4))`,
    readonly: true,
  },
  {
    id: 'id-2',
    label: 'Global.TotalValue',
    value: 'total-value',
    color: `rgb(var(--colors-gray-4))`,
    readonly: true,
  },
  {
    id: 'id-3',
    label: 'Global.ROI/IRR',
    value: 'roi-irr',
    color: `rgb(var(--colors-gray-4))`,
    readonly: true,
  },
];

export const totalCostLegendItems = [
  {
    id: 'current-cost',
    label: 'Global.CurrentCost',
    value: 'current-cost',
    color: `rgb(var(--colors-orange-peel))`,
    readonly: true,
  },
  {
    id: 'other',
    label: 'Global.Other',
    value: 'other',
    color: `rgb(var(--colors-lan-blue))`,
    readonly: true,
  },
  {
    id: 'debt',
    label: 'Global.Debt',
    value: 'debt',
    color: `rgb(var(--colors-gray-5))`,
    readonly: true,
  },
  {
    id: 'equity',
    label: 'Global.Equity',
    value: 'equity',
    color: `rgb(var(--colors-lan-violet))`,
    readonly: true,
  },
];
export const totalValueLegendItems = [
  {
    id: 'total-value',
    label: 'Global.TotalValue',
    value: 'total-value',
    color: `rgb(var(--colors-white))`,
    readonly: true,
  },
  {
    id: 'total-cost',
    label: 'Global.TotalCost',
    value: 'cost',
    color: `rgb(var(--colors-orange-peel))`,
    readonly: true,
  },
  {
    id: 'current-valuation',
    label: 'Global.CurrentValuation',
    value: 'valuation',
    color: 'rgb(var(--colors-lan-violet))',
    readonly: true,
  },
  {
    id: 'total-realised',
    label: 'PerformanceTotalValueChart.LegendLabel.TotalRealised',
    value: 'realised',
    color: 'rgb(var(--colors-gray-6))',
    readonly: true,
  },
];
export const roiIrrLegendItems = [
  {
    id: 'roi',
    label: 'Global.ROI',
    value: 'roi',
    color: `rgb(var(--colors-orange-peel))`,
    readonly: true,
  },
  {
    id: 'net-irr',
    label: 'Global.NetIRR',
    value: 'net-irr',
    color: `rgb(var(--colors-lan-violet))`,
    readonly: true,
  },
];

export const totalCostTooltipOrder = {
  'Current cost': 0,
  Other: 1,
  Debt: 2,
  Equity: 3,
};

export const totalValueTooltipOrder = {
  'NC Total value': 0,
  'Total value': 0,
  'Total cost': 1,
  'Current valuation': 2,
  'Total realised': 3,
};
