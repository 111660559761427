import React from 'react';
import {AreaChartLabelProps} from '../../../../../../../types/index';

type MissingDataProps = Pick<
  AreaChartLabelProps,
  'x' | 'index' | 'labelData' | 'labelStyles'
>;

const AreaChartMissingData: React.FC<MissingDataProps> = ({
  x,
  index,
  labelData,
  labelStyles,
}): JSX.Element | null => {
  return index === labelData?.index ? (
    <g>
      <line
        x1={x}
        x2={x}
        y1={labelStyles?.lineY1}
        y2={labelStyles?.lineY2}
        stroke="white"
      ></line>
      <rect
        x={x! - labelStyles?.rectX!}
        y={labelStyles?.rectY}
        width={labelStyles?.rectWidth}
        height={labelStyles?.rectHeight}
        rx={labelStyles?.rectRx}
        fill={`rgba(var(--colors-black),0.85)`}
      />
      <text
        fontSize={labelStyles?.fontSize}
        fill={`rgb(var(--colors-lantern-main-color))`}
        textAnchor="middle"
        y={labelStyles?.textY}
        x={x}
      >
        {labelData?.label}
      </text>
    </g>
  ) : null;
};

export default AreaChartMissingData;
