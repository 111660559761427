import React from 'react';
import {useLocation} from 'react-router-dom';
import {CompanyFinancialData} from '../../../../../types/index';
import {ValueFormat} from '../../../../global';
import homeLayoutStyles from './FinancialData.module.scss';
import pageLayoutStyles from './FinancialDataTemp.module.scss';
import {formatToHyphenatedString} from '../../../../../utils/strings';

interface FinancialDataProps {
  data?: CompanyFinancialData;
  currency?: string;
}

const FinancialData: React.FC<FinancialDataProps> = ({data, currency}) => {
  const {pathname} = useLocation();
  const styles = pathname === '/' ? homeLayoutStyles : pageLayoutStyles;

  if (!data) return null;

  const {value, label, format} = data;

  return (
    <div
      className={styles.wrapper}
      data-test={`company-widget-${formatToHyphenatedString(label)}-tab`}
    >
      <div className={styles.label}>{label}</div>
      <div className={styles.value}>
        {currency && <span className={styles.currency}>{currency}</span>}
        <ValueFormat value={value} format={format} />
      </div>
    </div>
  );
};

export default FinancialData;
