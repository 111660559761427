import {createSelector} from '@reduxjs/toolkit';
import {RootState} from '../store';

const fundDetails = (state: RootState) => state.fundDetails;

const fundDetailsSelector = createSelector([fundDetails], fundDetails => {
  const data =
    fundDetails.fundOverview.data ||
    fundDetails.fundStructure.data ||
    fundDetails.fundPortfolio.data ||
    fundDetails.fundPortfolioTransactions.data ||
    fundDetails.fundOperational.data;

  const {fundName, fundColor, id, status} = data || {};

  return {
    fundName,
    fundColor,
    id,
    status,
  };
});

export default fundDetailsSelector;
