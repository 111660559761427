import React from 'react';
import {InstrumentTypesProps} from '../../../../../types/index';
import {Icon} from '../../../';
import styles from './InstrumentTypes.module.scss';
import {getInstrumentIconTooltip} from '../../../../../utils/getInstrumentIconTooltip';

const InstrumentTypes: React.FC<InstrumentTypesProps> = ({
  icons,
}): JSX.Element => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        {icons?.map((icon, i) => (
          <Icon
            tooltip={getInstrumentIconTooltip(icon)}
            name={icon}
            key={i}
            className={styles.instrumentIcon}
          />
        ))}
      </div>
    </div>
  );
};

export default InstrumentTypes;
