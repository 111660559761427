import {useState} from 'react';
import {ZodObject, ZodRawShape, ZodIssue} from 'zod';
import {Func, SchemaValidationErrors, ValidationPayload} from '../types';

const genErrorsEmptyState = <T extends ZodRawShape>(
  schema: ZodObject<T>
): SchemaValidationErrors => {
  const errors: SchemaValidationErrors = {};
  Array.from<string>(schema.keyof().options).forEach(
    item => (errors[item] = {message: '', nestedFields: {}})
  );

  return errors;
};

const useSchemaValidation = <T extends ZodRawShape>(
  schema: ZodObject<T>
): [
  SchemaValidationErrors,
  Func<[ValidationPayload], boolean>,
  Func<[], void>
] => {
  const [errors, setErrors] = useState<SchemaValidationErrors>(
    genErrorsEmptyState(schema)
  );

  const validate = (payload: ValidationPayload): boolean => {
    const payloadArray = Object.entries(payload);
    if (payloadArray.length === 1) {
      const [name, value] = payloadArray[0];
      const result = schema.shape[name].safeParse(value);
      if (result.success) {
        setErrors(errors => ({
          ...errors,
          [name]: {message: '', nestedFields: {}},
        }));
        return result.success;
      }

      setErrors(errors => ({
        ...errors,
        [name]: {
          message: result.error.formErrors.formErrors[0] || '',
          nestedFields: result.error.formErrors.fieldErrors,
        },
      }));

      return result.success;
    }

    const result = schema.safeParse(payload);
    let submitErrors: SchemaValidationErrors = {};
    if (!result.success) {
      result.error.issues.forEach((issue: ZodIssue) => {
        submitErrors[issue.path[0]] = {
          message: issue.message,
          nestedFields: {},
        };
      });

      setErrors({
        ...genErrorsEmptyState(schema),
        ...submitErrors,
      });
    }
    return result.success;
  };

  const resetSchemaValidationErrors = () => {
    // Reset errors to initial empty state
    setErrors(genErrorsEmptyState(schema));
  };

  return [errors, validate, resetSchemaValidationErrors];
};

export default useSchemaValidation;
