import {all, call, put, takeEvery} from 'redux-saga/effects';
import {acquireAuthResult, msalInstance} from '../..';
import {PayloadAction} from '@reduxjs/toolkit';
import {errorHandlerAction} from '../../actions';
import {
  getScenarioOnePageReportMainDataAction,
  setLoading,
  setScenarioOnePageReportMainData,
} from '../../reducers/scenarioOnePageReportMainSlice';
import {getScenarioData} from '../../api/getScenarioMainInfo';
import {NearCastingScenario} from '../../types';

export const scenarioOnePageReportMainSaga =
  function* scenarioOnePageReportMainSaga({
    payload,
  }: PayloadAction<{
    match: any;
  }>) {
    const id: string = payload?.match?.params?.id;
    if (id) {
      yield put(setLoading());
      try {
        const {accessToken} = yield call(acquireAuthResult, msalInstance);
        const response: NearCastingScenario = yield call(
          getScenarioData,
          accessToken,
          id
        );
        yield put(setScenarioOnePageReportMainData(response));
        yield put(setLoading());
      } catch (err) {
        console.warn(err);
        yield put(setLoading());
        yield put(errorHandlerAction({error: err}));
      }
    }
  };

export const ScenarioOnePageReportMainSagaWatcher =
  function* ScenarioOnePageReportMainSagaWatcher() {
    yield all([
      takeEvery(
        getScenarioOnePageReportMainDataAction.type,
        scenarioOnePageReportMainSaga
      ),
    ]);
  };
