import {createAction, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {FundNearCastingDetailed, FundScenarioState} from '../types/index';

export const getNearCastingAllScenariosDataAction = createAction(
  'fund-details-near-casting/get-near-casting-data',
  (
    companyId: string,
    companyType: string,
    isFundCurrency: boolean,
    investmentGroupId: string
  ) => {
    return {
      payload: {
        companyId,
        companyType,
        isFundCurrency,
        investmentGroupId,
      },
    };
  }
);

export const deleteNearCastingScenarioAction = createAction(
  'fund-details-near-casting/delete-near-casting-scenario',
  (id: string, scenarioName: string, fundId: string, navigateTo?: string) => {
    return {
      payload: {
        id,
        scenarioName,
        fundId,
        navigateTo,
      },
    };
  }
);

export const publishNearCastingScenarioAction = createAction(
  'fund-details-near-casting/publish-near-casting-scenario',
  (scenarioId: string, navigateTo?: string) => {
    return {
      payload: {
        scenarioId,
        status: FundScenarioState.Published,
        navigateTo: navigateTo,
      },
    };
  }
);

export const duplicateNearCastingScenarioAction = createAction(
  'fund-details-near-casting/duplicate-near-casting-scenario',
  (fundId: string, scenarioId: string, navigateTo?: string) => {
    return {
      payload: {
        fundId,
        scenarioId,
        navigateTo,
      },
    };
  }
);

export const archiveNearCastingScenarioAction = createAction(
  'fund-details-near-casting/archive-near-casting-scenario',
  (scenarioId: string, navigateTo?: string) => {
    return {
      payload: {
        scenarioId,
        status: FundScenarioState.Archived,
        navigateTo,
      },
    };
  }
);

interface FundDetailsNearCastingData {
  data: FundNearCastingDetailed | null;
  isLoading: boolean;
}

const initialState: FundDetailsNearCastingData = {
  data: null,
  isLoading: false,
};

const fundDetailsNearCastingSlice = createSlice({
  name: 'fund-near-casting',
  initialState,
  reducers: {
    setFundNearCastingData(
      state: FundDetailsNearCastingData,
      action: PayloadAction<FundNearCastingDetailed>
    ) {
      state.data = action.payload;
    },
    clearFundNearCastingData(state: FundDetailsNearCastingData) {
      state.data = null;
    },
    setLoading(state: FundDetailsNearCastingData) {
      state.isLoading = !state.isLoading;
    },
  },
});

export const {setFundNearCastingData, setLoading, clearFundNearCastingData} =
  fundDetailsNearCastingSlice.actions;

export default fundDetailsNearCastingSlice.reducer;
