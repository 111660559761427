import React from 'react';
import {useLocation} from 'react-router-dom';
import {FundInfoCommitmentAvailable} from '../../../../../types/index';
import {ValueFormat} from '../../../../global';
import homeLayoutStyles from './FundInfoFunds.module.scss';
import pageLayoutStyles from './FundInfoFundsTemp.module.scss';

interface FundInfoFundsProps {
  commitment: FundInfoCommitmentAvailable;
  available: FundInfoCommitmentAvailable;
  currency: string;
}
const FundInfoFunds: React.FC<FundInfoFundsProps> = ({
  commitment,
  available,
  currency,
}) => {
  const {pathname} = useLocation();
  const styles = pathname === '/' ? homeLayoutStyles : pageLayoutStyles;
  return (
    <div className={styles.wrapper}>
      <div className={styles.commitment}>
        <div className={styles.label}>{commitment.label}</div>
        <div className={styles.value}>
          <span>{currency}</span>
          <ValueFormat value={commitment.value} format={commitment.format} />
        </div>
      </div>
      <div className={styles.available}>
        <div className={styles.label}>{available.label}</div>
        <div className={styles.value}>
          <span>{currency}</span>
          <ValueFormat value={available.value} format={available.format} />
        </div>
      </div>
    </div>
  );
};

export default FundInfoFunds;
