import React from 'react';
import classnames from 'classnames';
import {useAppDispatch, useAppSelector} from '../../../hooks/useReduxHooks';
import {clearGlobalInformation} from '../../../reducers/globalInformationSlice';
import {useMsal} from '@azure/msal-react';
import {useHistory} from 'react-router-dom';
import {Button, Icon, Modal} from '../';
import {
  GlobalInformationActionProps,
  GlobalInformationActionType,
} from '../../../types';
import styles from './ModalHandler.module.scss';
import {modalHandlerReduxActions} from '../../../utils';

type ModalHandlerProps = {
  children: React.ReactNode;
};

const ModalHandler = ({children}: ModalHandlerProps) => {
  const {data: globalData} = useAppSelector(state => state.globalInformation);
  const dispatch = useAppDispatch();

  const {instance} = useMsal();

  const history = useHistory();

  const handleAction = (prop?: GlobalInformationActionProps): void => {
    if (prop?.action === GlobalInformationActionType.Logout) {
      instance.logoutRedirect();
      return;
    }
    if (prop?.action === GlobalInformationActionType.Dismiss) {
      // clear global information/error
      dispatch(clearGlobalInformation());
      return;
    }
    if (prop?.action === GlobalInformationActionType.Redirect) {
      dispatch(clearGlobalInformation());
      // redirect action
      history.push(prop?.callback?.arguments);
      return;
    }
    if (
      prop?.action === GlobalInformationActionType.ReduxAction &&
      prop?.callback &&
      prop?.callback.fnKey
    ) {
      dispatch(
        modalHandlerReduxActions[prop.callback.fnKey](prop.callback.arguments)
      );
      dispatch(clearGlobalInformation());
      return;
    }
  };

  return (
    <div
      className={classnames(
        styles.wrapper,
        !!globalData.length ? styles.wrapper__locked : ''
      )}
    >
      {globalData.map((data, idx) => (
        <Modal
          key={idx}
          onClose={() =>
            data?.isDismissable && dispatch(clearGlobalInformation())
          }
          isOpen={true}
          isDismissable={data.isDismissable}
          className={styles.modalWrapper}
          style={{
            transform: `scale(${
              100 - (globalData.length - 1 - idx)
            }%) translateY(${-10 * idx}px)`,
          }}
          footerChildren={
            !!data.actions?.length ? (
              <div className={styles.actionButtons}>
                {data.actions.map((action, i) => (
                  <Button
                    key={i}
                    text={action.text}
                    styleType={action.style}
                    onClick={() => handleAction(action)}
                    className={classnames(
                      action.themeOverride
                        ? styles[action.themeOverride]
                        : styles.lightThemePrimaryButton,
                      action.customClassName && styles[action.customClassName]
                    )}
                  />
                ))}
              </div>
            ) : null
          }
        >
          <div className={styles.content}>
            {data.icon ? (
              <Icon
                className={classnames(
                  styles.icon,
                  data.icon === 'warning' && styles.icon__warning,
                  data.icon === 'info' && styles.icon__info,
                  data.icon === 'check' && styles.icon__success,
                  data.icon === 'add-chart' && styles.icon__addChart
                )}
                name={data.icon}
              />
            ) : null}
            <div className={styles.message}>{data.message}</div>
          </div>
        </Modal>
      ))}
      {children}
    </div>
  );
};

export default ModalHandler;
