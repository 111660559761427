import React, {ReactNode} from 'react';
import styles from './DropdownLinkList.module.scss';
import {Func} from '../../../types';

interface DropdownLinkListProps {
  options: ReactNode[];
  onClickDropdown?: Func<[], void>;
}
const DropdownLinkList: React.FC<DropdownLinkListProps> = ({
  options,
  onClickDropdown,
}) => {
  return (
    <div className={styles.dropdown} onClick={onClickDropdown}>
      <ul>
        {options.map((node, idx) => (node ? <li key={idx}>{node}</li> : null))}
      </ul>
    </div>
  );
};

export default DropdownLinkList;
