import React, {TdHTMLAttributes} from 'react';
import classnames from 'classnames';
import {IconButton} from '../../../index';
import {ButtonStyle, Func} from '../../../../../types';
import styles from './ReportingTablePaginationCell.module.scss';
import {useTranslation} from 'react-i18next';

type ReportingTablePaginationCellProps = {
  classNames?: string;
  periods: number;
  onPrevious: Func<[], void>;
  onNext: Func<[], void>;
  previousDisabled: boolean;
  nextDisabled: boolean;
} & TdHTMLAttributes<HTMLTableCellElement>;

const ReportingTablePaginationCell = ({
  classNames,
  periods,
  onPrevious,
  onNext,
  previousDisabled,
  nextDisabled,
  ...props
}: ReportingTablePaginationCellProps) => {
  const {t} = useTranslation();
  return (
    <td
      {...props}
      className={classnames(styles.cell, {
        [`${classNames}`]: !!classNames,
      })}
    >
      <div className={classnames(styles.cellInnerWrapper)}>
        <span>
          {periods} {t(periods === 1 ? 'Global.period' : 'Global.periods')}
        </span>
        <IconButton
          styleType={ButtonStyle.Primary}
          onClick={onPrevious}
          icon={'chevron-left'}
          disabled={previousDisabled}
        />
        <IconButton
          styleType={ButtonStyle.Primary}
          onClick={onNext}
          icon={'chevron-right'}
          disabled={nextDisabled}
        />
      </div>
    </td>
  );
};

export default ReportingTablePaginationCell;
