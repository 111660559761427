import React from 'react';
import {TimeLineData, StyleVariant} from '../../../types/index';
import {TimeLineWidget} from '../..';
import styles from './InvestmentOverviewTimelineDashboard.module.scss';

const InvestmentOverviewTimelineDashboard: React.FC<TimeLineData> = ({
  widgets,
}): JSX.Element => {
  return (
    <div className={styles.wrapper}>
      {widgets?.map((widget, idx) => (
        <TimeLineWidget
          key={idx}
          type={widget.type}
          headerData={widget.headerData}
          data={widget.data}
          isHoverStateDisabled
          variant={StyleVariant.Secondary}
        />
      ))}
    </div>
  );
};

export default InvestmentOverviewTimelineDashboard;
