import React from 'react';
import {
  HeaderMapping,
  FlexHAlign,
  WrittenOffInvestmentsRow,
} from '../../../../../types';
import {ColumnDef, createColumnHelper} from '@tanstack/react-table';
import Th from '../../cells/th/Th';
import Td from '../../cells/td/Td';
import NameCell from '../../cells/name-cell/NameCell';
import FundBarsCell from '../../cells/fund-bars-cell/FundBarsCell';
import routePaths from '../../../../../routePaths';
import {consolidatedCompanyColor} from '../../../../../constants/companies';
import {getTableColumnTestAttr} from '../../../../../utils/strings';

const genWrittenOffInvestmentsTableColumns = (
  headerMapping: HeaderMapping[]
): ColumnDef<WrittenOffInvestmentsRow, any>[] => {
  const columnHelper = createColumnHelper<WrittenOffInvestmentsRow>();

  return [
    columnHelper.accessor(row => row.companyName, {
      id: 'companyName',
      header: props => (
        <Th
          {...props}
          headerMapping={headerMapping}
          align={FlexHAlign.start}
          width={'max(100vw*0.18, 150px)'}
        />
      ),
      cell: props => (
        <Td
          {...props}
          align={FlexHAlign.start}
          width={'320px'}
          canHide={false}
          id={getTableColumnTestAttr(headerMapping, props.column.id)}
        >
          <NameCell
            name={props?.getValue<string>()}
            icon={props.row.original.fundColor ?? consolidatedCompanyColor}
            iconUrl={props.row.original.companyIcon ?? null}
            expanded={props.row.getIsExpanded()}
            // TODO: Linking to Investment Overview for V1, later on according to subscription
            linkTo={`${routePaths.PORTFOLIO_COMPANIES}/${props.row.original.id}/investment-overview`}
          />
        </Td>
      ),
    }),
    columnHelper.accessor('fundColor', {
      id: 'funds',
      header: props => (
        <Th {...props} headerMapping={headerMapping} align={FlexHAlign.start} />
      ),
      cell: props => (
        <Td
          {...props}
          align={FlexHAlign.start}
          canHide={false}
          id={getTableColumnTestAttr(headerMapping, props.column.id)}
        >
          <FundBarsCell
            funds={
              !props.row.original.investmentDetails
                ? [
                    {
                      fundColor: props.row.original.fundColor!,
                      fundName: props.row.original.fundName!,
                    },
                  ]
                : props.row.original.investmentDetails.map(item => ({
                    fundColor: item.fundColor!,
                    fundName: item.fundName!,
                  }))
            }
          />
        </Td>
      ),
    }),
    columnHelper.accessor(row => row.currency, {
      id: 'currency',
      header: props => (
        <Th {...props} headerMapping={headerMapping} align={FlexHAlign.start} />
      ),
      cell: props => (
        <Td
          {...props}
          align={FlexHAlign.start}
          id={getTableColumnTestAttr(headerMapping, props.column.id)}
        />
      ),
    }),
    columnHelper.accessor(row => row.firstInvestment, {
      id: 'firstInvestment',
      header: props => (
        <Th
          {...props}
          headerMapping={headerMapping}
          align={FlexHAlign.center}
        />
      ),
      cell: props => (
        <Td
          {...props}
          align={FlexHAlign.center}
          id={getTableColumnTestAttr(headerMapping, props.column.id)}
        />
      ),
    }),
    columnHelper.accessor(row => row.writtenOffDate?.value, {
      id: 'writtenOffDate',
      header: props => (
        <Th {...props} headerMapping={headerMapping} align={FlexHAlign.end} />
      ),
      cell: props => (
        <Td
          {...props}
          align={FlexHAlign.end}
          nestedAccessorFn={original => original.writtenOffDate}
          id={getTableColumnTestAttr(headerMapping, props.column.id)}
        />
      ),
    }),
    columnHelper.accessor(row => row.totalCost?.value, {
      id: 'totalCost',
      header: props => (
        <Th {...props} headerMapping={headerMapping} align={FlexHAlign.end} />
      ),
      cell: props => (
        <Td
          {...props}
          align={FlexHAlign.end}
          nestedAccessorFn={original => original.totalCost}
          id={getTableColumnTestAttr(headerMapping, props.column.id)}
        />
      ),
    }),
    columnHelper.accessor(row => row.realisedLoss?.value, {
      id: 'realisedLoss',
      header: props => (
        <Th {...props} headerMapping={headerMapping} align={FlexHAlign.end} />
      ),
      cell: props => (
        <Td
          {...props}
          align={FlexHAlign.end}
          nestedAccessorFn={original => original.realisedLoss}
          id={getTableColumnTestAttr(headerMapping, props.column.id)}
        />
      ),
    }),
    columnHelper.display({
      id: 'spacer',
      header: props => (
        <Th
          {...props}
          align={FlexHAlign.center}
          width={'30%'}
          headerMapping={headerMapping}
        />
      ),
      cell: props => (
        <Td
          {...props}
          align={FlexHAlign.center}
          canHide={false}
          emptyValue={''}
          id={getTableColumnTestAttr(headerMapping, props.column.id)}
        />
      ),
    }),
  ];
};

export default genWrittenOffInvestmentsTableColumns;
