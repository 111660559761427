import React, {useMemo} from 'react';
import {valueFormat} from '../../../../utils';
import styles from './BubbleChartTooltip.module.scss';
import classnames from 'classnames';

const BubbleChartTooltip = (props: any) => {
  const {
    payload,
    xAxisLabel,
    xAxisFilterLabel,
    xAxisFormat,
    yAxisLabel,
    yAxisFilterLabel,
    yAxisFormat,
    zAxisLabel,
    zAxisFilterLabel,
    zAxisFormat,
  } = props;

  const payloadHasLength = useMemo(() => !!payload?.length, [payload]);

  const xAxisValueFormatted = useMemo(
    () =>
      payloadHasLength
        ? valueFormat(
            payload.find((item: any) => item.dataKey === 'x')?.value ?? '',
            xAxisFormat
          ).value
        : '',
    [payload, payloadHasLength, xAxisFormat]
  );

  const yAxisValueFormatted = useMemo(
    () =>
      payloadHasLength
        ? valueFormat(
            payload.find((item: any) => item.dataKey === 'y')?.value ?? '',
            yAxisFormat
          ).value
        : '',
    [payload, payloadHasLength, yAxisFormat]
  );

  const zAxisValueFormatted = useMemo(
    () =>
      payloadHasLength
        ? valueFormat(
            payload.find((item: any) => item.dataKey === 'z')?.value ?? '',
            zAxisFormat
          ).value
        : '',
    [payload, payloadHasLength, zAxisFormat]
  );

  if (payloadHasLength) {
    return (
      <div className={classnames(styles.wrapper)}>
        <div className={styles.treemapTooltipTitleSection}>
          <p className={styles.name}>{payload[0].payload.title}</p>
        </div>

        <div className={styles.treemapTooltipInfoSection}>
          <p className={styles.name}>
            {`${yAxisLabel} (${yAxisFilterLabel})`}:
          </p>
          <p className={styles.value}>{yAxisValueFormatted}</p>
        </div>

        <div className={styles.treemapTooltipInfoSection}>
          <p className={styles.name}>
            {`${xAxisLabel} (${xAxisFilterLabel})`}:
          </p>
          <p className={styles.value}>{xAxisValueFormatted}</p>
        </div>

        <div className={styles.treemapTooltipInfoSection}>
          <p className={styles.name}>
            {`${zAxisLabel} (${zAxisFilterLabel})`}:
          </p>
          <p className={styles.value}>{zAxisValueFormatted}</p>
        </div>
      </div>
    );
  }

  return null;
};

export default BubbleChartTooltip;
