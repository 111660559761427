import {createAction, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {
  CompanyMonitoring,
  FormStatus,
  HeaderMapping,
  MetricConfigItem,
  MetricsChartDataItem,
  MetricsChartType,
  MetricsReportingGroup,
  SelectOption,
} from '../types/';
import {MonitoringMetricsChartConfigPayload} from '../api/getCompanyDetailsMonitoring';

export const saveCompanyDetailsMonitoringMetricsChartConfigAction =
  createAction(
    'company-details-monitoring/save-chart-config',
    (payload: MonitoringMetricsChartConfigPayload) => {
      return {
        payload,
      };
    }
  );

export const getCompanyDetailsMonitoringMetricsChartConfigAction = createAction(
  'company-details-monitoring/get-chart-config',
  (payload: {id: string}) => {
    return {
      payload,
    };
  }
);

export const deleteCompanyDetailsMonitoringMetricsChartConfigAction =
  createAction(
    'company-details-monitoring/delete-chart-config',
    (payload: {id: string}) => {
      return {
        payload,
      };
    }
  );

export const getCompanyDetailsMonitoringHistoricalDataAction = createAction(
  'company-details-monitoring/get-historical-template',
  (payload: {id: string}) => {
    return {
      payload,
    };
  }
);

export const exportCompanyDetailsMonitoringDataAction = createAction(
  'company-details-monitoring/export',
  (payload: {
    companyName: string;
    data: MetricsChartDataItem[];
    headerMapping: HeaderMapping[];
  }) => {
    return {
      payload,
    };
  }
);

interface CompanyDetailsMonitoringData {
  data: CompanyMonitoring | null;
  formStatus: FormStatus;
  error: string | null;
  formError: Record<string, string> | null;
  isRequestPending: boolean;
  isExportPending: boolean;
}

const initialState: CompanyDetailsMonitoringData = {
  data: null,
  formStatus: FormStatus.Ready,
  error: null,
  formError: null,
  isRequestPending: false,
  isExportPending: false,
};

const companyDetailsMonitoringSlice = createSlice({
  name: 'company-details-monitoring',
  initialState,
  reducers: {
    setCompanyMonitoringData(
      state: CompanyDetailsMonitoringData,
      action: PayloadAction<CompanyMonitoring>
    ) {
      state.data = action.payload;
    },

    clearCompanyMonitoringData(state: CompanyDetailsMonitoringData) {
      state.data = null;
    },

    setSelectedCompanyMonitoringMetricsConfigItem(
      state: CompanyDetailsMonitoringData,
      action: PayloadAction<MetricConfigItem>
    ) {
      if (state.data) {
        // update ordering
        const configItem = {...action.payload};

        if (configItem.isSelected) {
          const maxOrderingIndex = state.data.metricsConfig.data
            .flatMap(group => group.metricsGroups.flatMap(item => item.metrics))
            .reduce((x, y) => (x.orderId > y.orderId ? x : y)).orderId;

          configItem.orderId = maxOrderingIndex + 1;
        }

        if (!configItem.isSelected) {
          configItem.orderId = -1;
          configItem.chartType = MetricsChartType.LineChart;
          configItem.yAxisPosition = 0;
        }

        state.data.metricsConfig.data = state.data.metricsConfig.data.map(
          repGroup => ({
            ...repGroup,
            metricsGroups: repGroup.metricsGroups?.map(metricGroup => ({
              ...metricGroup,
              metrics: metricGroup.metrics.map(metric =>
                metric.id === configItem.id
                  ? configItem
                  : {
                      ...metric,
                      orderId:
                        configItem.orderId === -1 &&
                        metric.orderId > action.payload.orderId
                          ? metric.orderId - 1
                          : metric.orderId,
                    }
              ),
            })),
          })
        );
      }
    },
    updateSelectedMetricConfiguration(
      state: CompanyDetailsMonitoringData,
      action: PayloadAction<MetricConfigItem>
    ) {
      if (state.data) {
        state.data.metricsConfig.data = state.data.metricsConfig.data.map(
          repGroup => ({
            ...repGroup,
            metricsGroups: repGroup.metricsGroups?.map(metricGroup => ({
              ...metricGroup,
              metrics: metricGroup.metrics.map(metric =>
                metric.id === action.payload.id ? action.payload : metric
              ),
            })),
          })
        );
      }
    },
    updateSelectedMetricsConfiguration(
      state: CompanyDetailsMonitoringData,
      action: PayloadAction<MetricConfigItem[]>
    ) {
      if (state.data) {
        let dataSlice: MetricsReportingGroup[] = [
          ...state.data.metricsConfig.data,
        ];
        action.payload.forEach(item => {
          dataSlice = dataSlice.map(repGroup => ({
            ...repGroup,
            metricsGroups: repGroup.metricsGroups?.map(metricGroup => ({
              ...metricGroup,
              metrics: metricGroup.metrics.map(metric =>
                metric.id === item.id
                  ? item
                  : !!action.payload.find(item => item.id === metric.id)?.id
                  ? metric
                  : {
                      ...metric,
                      isSelected: false,
                      chartType: MetricsChartType.LineChart,
                      yAxisPosition: 0,
                      orderId: -1,
                    }
              ),
            })),
          }));
        });
        state.data.metricsConfig.data = dataSlice;
      }
    },
    resetCompanyDetailsMonitoringSavedChartConfig(
      state: CompanyDetailsMonitoringData
    ) {
      if (state.data) {
        state.data.metricsConfig.data = state.data.metricsConfig.data.map(
          repGroup => ({
            ...repGroup,
            metricsGroups: repGroup.metricsGroups?.map(metricGroup => ({
              ...metricGroup,
              metrics: metricGroup.metrics.map(metric => {
                return {
                  ...metric,
                  isSelected: false,
                  chartType: MetricsChartType.LineChart,
                  yAxisPosition: 0,
                  orderId: -1,
                };
              }),
            })),
          })
        );
      }
    },
    updateCompanyDetailsMonitoringSavedChartsList(
      state: CompanyDetailsMonitoringData,
      action: PayloadAction<SelectOption[]>
    ) {
      if (state.data) {
        state.data.savedCharts = action.payload;
      }
    },
    setCompanyDetailsMonitoringHistoricalData(
      state: CompanyDetailsMonitoringData,
      action: PayloadAction<
        Pick<CompanyMonitoring, 'metricsConfig' | 'metricsData' | 'savedCharts'>
      >
    ) {
      if (state.data) {
        state.data = {
          ...state.data,
          ...action.payload,
        };
      }
    },
    setCompanyDetailsMonitoringIsExportPending(
      state: CompanyDetailsMonitoringData,
      action: PayloadAction<boolean>
    ) {
      state.isExportPending = action.payload;
    },
    setCompanyDetailsMonitoringFormStatus(
      state: CompanyDetailsMonitoringData,
      action: PayloadAction<FormStatus>
    ) {
      state.formStatus = action.payload;
    },
    setCompanyDetailsMonitoringIsRequestPending(
      state: CompanyDetailsMonitoringData,
      action: PayloadAction<boolean>
    ) {
      state.isRequestPending = action.payload;
    },
    setCompanyDetailsMonitoringError(
      state: CompanyDetailsMonitoringData,
      action: PayloadAction<string | null>
    ) {
      state.error = action.payload;
    },
    setCompanyDetailsMonitoringFormError(
      state: CompanyDetailsMonitoringData,
      action: PayloadAction<Record<string, string> | null>
    ) {
      state.formError = action.payload;
    },
  },
});

export const {
  setCompanyMonitoringData,
  clearCompanyMonitoringData,
  setSelectedCompanyMonitoringMetricsConfigItem,
  updateSelectedMetricConfiguration,
  updateSelectedMetricsConfiguration,
  resetCompanyDetailsMonitoringSavedChartConfig,
  updateCompanyDetailsMonitoringSavedChartsList,
  setCompanyDetailsMonitoringHistoricalData,
  setCompanyDetailsMonitoringFormStatus,
  setCompanyDetailsMonitoringIsRequestPending,
  setCompanyDetailsMonitoringError,
  setCompanyDetailsMonitoringFormError,
  setCompanyDetailsMonitoringIsExportPending,
} = companyDetailsMonitoringSlice.actions;

export default companyDetailsMonitoringSlice.reducer;
