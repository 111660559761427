import {call, put, all, takeEvery, select} from 'redux-saga/effects';
import {PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../../store';
import {acquireAuthResult, msalInstance} from '../..';
import {errorHandlerAction} from '../../actions';
import {
  clearCompanyInvestmentOverviewData,
  filterCompanyInvestmentOverviewTransactionsDataAction,
  setCompanyInvestmentOverviewData,
  setCompanyInvestmentOverviewTransactionsFilteredData,
  setCompanyInvestmentOverviewTransactionsFilteringInProgress,
} from '../../reducers/companyDetailsInvestmentOverview';
import {
  getCompanyDetailsInvestmentOverview,
  getInvestmentOverviewTransactionsById,
} from '../../api/getCompanyDetailsInvestmentOverview';
import {CompanyInvestmentOverview, TransactionsData} from '../../types/index';

export const companyDetailsInvestmentOverviewSaga =
  function* companyDetailsInvestmentOverviewSaga({payload}: any): any {
    const id: string = payload?.match?.params?.id;
    if (id) {
      const isUserProfileUpdatePending = yield select(
        (state: RootState) => state.userProfile.isLoading
      );
      if (!isUserProfileUpdatePending) {
        yield put(clearCompanyInvestmentOverviewData());
      }
      try {
        const {accessToken} = yield call(acquireAuthResult, msalInstance);
        const response: CompanyInvestmentOverview = yield call(
          getCompanyDetailsInvestmentOverview,
          accessToken,
          id
        );

        yield put(setCompanyInvestmentOverviewData(response));
      } catch (err) {
        console.warn(err);
        yield put(errorHandlerAction({error: err}));
      }
    }
  };

export const updateCompanyDetailsInvestmentOverviewTransactionsSaga =
  function* updateCompanyDetailsInvestmentOverviewTransactionsSaga({
    payload,
  }: PayloadAction<{portfolioId: string; fundId: string}>) {
    if (payload.portfolioId) {
      yield put(
        setCompanyInvestmentOverviewTransactionsFilteringInProgress(true)
      );
      try {
        const {accessToken} = yield call(acquireAuthResult, msalInstance);
        const response: TransactionsData = yield call(
          getInvestmentOverviewTransactionsById,
          accessToken,
          payload.portfolioId,
          payload.fundId
        );
        yield put(
          setCompanyInvestmentOverviewTransactionsFilteredData(response)
        );
        yield put(
          setCompanyInvestmentOverviewTransactionsFilteringInProgress(false)
        );
      } catch (err) {
        console.warn(err);
        yield put(
          setCompanyInvestmentOverviewTransactionsFilteringInProgress(false)
        );
        yield put(errorHandlerAction({error: err}));
      }
    }
  };

export const companyDetailsInvestmentOverviewSagaWatcher =
  function* companyDetailsInvestmentOverviewSagaWatcher() {
    yield all([
      takeEvery(
        filterCompanyInvestmentOverviewTransactionsDataAction.type,
        updateCompanyDetailsInvestmentOverviewTransactionsSaga
      ),
    ]);
  };
