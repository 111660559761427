import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {GlobalInformationProps} from '../types';

interface GlobalInformation {
  data: GlobalInformationProps[];
}

const initialState: GlobalInformation = {
  data: [],
};

const globalInformationSlice = createSlice({
  name: 'global-information',
  initialState,
  reducers: {
    setGlobalInformation(
      state: GlobalInformation,
      action: PayloadAction<GlobalInformationProps>
    ) {
      state.data = [...state.data, {...action.payload}];
    },
    clearGlobalInformation(state: GlobalInformation) {
      const popped = state.data.pop();
      if (Array.isArray(popped)) {
        state.data = popped;
      }
    },
  },
});

export const {setGlobalInformation, clearGlobalInformation} =
  globalInformationSlice.actions;
export default globalInformationSlice.reducer;
