import {
  CapitalData,
  CapitalWidgetType,
  ExportDropdownOption,
  TableSettings,
} from '../../../types';

export const capitalWidgetContributionsSettings = (
  zipFileName: string
): TableSettings => {
  return {
    fileName: zipFileName,
    name: 'Capital Contributions',
    columnVisibility: {},
    headerMapping: [
      {
        columnId: 'name',
        label: 'Contribution',
      },
      {
        columnId: 'value',
        label: 'Amount',
      },
    ],
    columnOrder: ['name', 'value'],
    meta: {
      columns: {
        name: {
          styling: {
            align: 'right',
          },
        },
        value: {
          styling: {
            align: 'right',
          },
        },
      },
    },
  };
};

export const capitalWidgetDistributionsSettings = (
  zipFileName: string
): TableSettings => {
  return {
    fileName: zipFileName,
    name: 'Capital Distributions',
    columnVisibility: {},
    headerMapping: [
      {
        columnId: 'name',
        label: 'Distribution',
      },
      {
        columnId: 'value',
        label: 'Amount',
      },
    ],
    columnOrder: ['name', 'value'],
    meta: {
      columns: {
        name: {
          styling: {
            align: 'right',
          },
        },
        value: {
          styling: {
            align: 'right',
          },
        },
      },
    },
  };
};

export const exportCapitalOptions: ExportDropdownOption[] = [
  {
    label: 'Contributions',
    value: 'fund-overview-contributions',
  },
  {
    label: 'Distributions',
    value: 'fund-overview-distributions',
  },
];

export const entitiesCapitalExportOptions: ExportDropdownOption[] = [
  {
    label: 'Contributions',
    value: 'structure-entity-table-contributions',
  },
  {
    label: 'Distributions',
    value: 'structure-entity-table-distributions',
  },
];

export const genDataForExport = (
  data: CapitalData,
  option: ExportDropdownOption
) => {
  let selectedWidgetData: CapitalWidgetType | undefined = data?.widgets.find(
    widget => widget.label === option.label
  );

  return selectedWidgetData?.data.timeSeries?.data;
};

export const genTableExportPayload = (
  data: CapitalData | undefined,
  option: ExportDropdownOption,
  isContributions: boolean,
  zipFileName: string
) => {
  if (data === undefined) throw new Error('The capital data is undefined');

  const tableSettings = isContributions
    ? capitalWidgetContributionsSettings(zipFileName)
    : capitalWidgetDistributionsSettings(zipFileName);

  return [
    {
      data: genDataForExport(data, option),
      mappings: tableSettings.headerMapping,
      settings: tableSettings,
    },
  ];
};

export const genImagesExportPayload = (
  zipFileName: string,
  option: ExportDropdownOption
) => {
  return {
    id: `capital-chart-${option.label}`,
    fileName: zipFileName,
    opts: [
      {
        fileName: `${zipFileName}_TransparentBackground`,
        type: 'image/svg+xml',
        ext: 'svg',
        bgColor: 'transparent',
      },
      {
        fileName: `${zipFileName}_WithBackground`,
        type: 'image/svg+xml',
        ext: 'svg',
        bgColor: 'black',
      },
      {
        fileName: `${zipFileName}_TransparentBackground`,
        type: 'image/png',
        ext: 'png',
        bgColor: 'transparent',
      },
      {
        fileName: `${zipFileName}_WithBackground`,
        type: 'image/png',
        ext: 'png',
        bgColor: 'black',
      },
    ],
  };
};
