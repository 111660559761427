import React, {ChangeEvent, useEffect, useRef, useState} from 'react';
import styles from './MultiSelect.module.scss';
import {
  MultiSelectOption,
  MultiSelectProps,
  StyleVariant,
} from '../../../types';
import {useOnOutsideClick} from '../../../hooks';
import classnames from 'classnames';
import {DropdownList, Icon, MultiSelectDropdownOption, Tag} from '../index';
import {useTranslation} from 'react-i18next';
import {searchByValue} from '../../../utils';

const MultiSelect: React.FC<MultiSelectProps> = ({
  label,
  options = [],
  value,
  variant = StyleVariant.Primary,
  className,
  errorMessage,
  validate,
  controllable = true,
  checkboxVariant,
  optionChildren,
  tagVariant,
  withSearch,
  scrollIntoView,
  ...selectProps
}) => {
  const [inputState, setInputState] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [state, setState] = useState(value);
  const [filteredOptions, setFilteredOptions] =
    useState<(MultiSelectOption | MultiSelectOption[])[]>(options);
  const {t} = useTranslation();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const inputRef = useRef<HTMLInputElement>(null);

  const scrollDropdownIntoView = () => {
    if (scrollIntoView) {
      setTimeout(() => {
        dropdownRef?.current?.scrollIntoView({
          behavior:
            typeof scrollIntoView === 'boolean'
              ? 'smooth'
              : scrollIntoView.behavior,
          block:
            typeof scrollIntoView === 'boolean' ? 'end' : scrollIntoView.block,
        });
      }, 50);
    }
  };

  const handleClickInner = () => {
    if (selectProps.disabled) return;
    scrollDropdownIntoView();

    setIsOpen(true);
    setTimeout(() => {
      inputRef.current?.focus();
    }, 100);
  };

  const handleCaretClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (selectProps.disabled) return;
    scrollDropdownIntoView();

    setIsOpen(prev => !prev);
    setInputState('');
    setFilteredOptions(options);
  };

  const handleRemoveValue = (removedValue: string) => {
    setState(prev => [...prev.filter(val => val !== removedValue)]);
    if (selectProps.onChange) {
      selectProps.onChange({
        target: {
          value: [...state.filter(val => val !== removedValue)],
          name: selectProps.name || '',
          type: 'multi-select',
        },
      });
    }
  };

  const handleChangeCheckbox = (checkboxValue: string, checked: boolean) => {
    const result = checked
      ? [...state, checkboxValue]
      : [...state.filter(val => val !== checkboxValue)];
    setState(result);
    if (selectProps.onChange) {
      selectProps.onChange({
        target: {
          value: result,
          name: selectProps.name || '',
          type: 'multi-select',
        },
      });
    }
  };

  const getValueLabel = (value: string): string | number => {
    return (
      options?.flatMap(item => item).find(item => item.value === value)
        ?.label ?? ''
    );
  };

  const getValueColor = (value: string): string | undefined => {
    return options?.flatMap(item => item).find(item => item.value === value)
      ?.color;
  };

  const isValueMandatory = (value: string): boolean | undefined => {
    return options?.flatMap(item => item).find(item => item.value === value)
      ?.mandatory;
  };

  const handleChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setInputState(event.target.value);

    const searchResult = searchByValue<MultiSelectOption>(
      options,
      event.target.value,
      {
        searchTarget: 'label',
      }
    );

    setFilteredOptions(searchResult);
  };

  useOnOutsideClick(wrapperRef, () => {
    setIsOpen(false);
    setInputState('');
    setFilteredOptions(options);
  });

  useEffect(() => {
    if (controllable) {
      setState(value);
    }
  }, [value, controllable]);

  return (
    <div
      ref={wrapperRef}
      className={classnames(
        styles.wrapper,
        styles[`wrapper__${variant}`],
        className,
        isOpen && styles.active
      )}
    >
      {label && (
        <label className={styles.inputLabel}>
          {label}
          {selectProps.required && <span className={styles.asterisk}>*</span>}
        </label>
      )}
      <div
        className={classnames(
          styles.inner,
          isOpen && styles.active,
          selectProps.disabled && styles.disabled,
          errorMessage && styles.selectInvalid
        )}
        onClick={handleClickInner}
      >
        {withSearch && !state.length && !isOpen && (
          <span className={styles.placeholder}>
            {t('Global.ChooseOptions')}
          </span>
        )}
        {!withSearch && !state.length && (
          <span className={styles.placeholder}>
            {t('Global.ChooseOptions')}
          </span>
        )}
        {state.map((valueItem, idx) => (
          <Tag
            label={getValueLabel(valueItem).toString()}
            color={getValueColor(valueItem)}
            key={idx}
            variant={tagVariant}
            onRemove={
              selectProps.disabled || isValueMandatory(valueItem)
                ? undefined
                : event => {
                    event.stopPropagation();
                    handleRemoveValue(valueItem);
                  }
            }
          />
        ))}
        {withSearch && isOpen && (
          <input
            ref={inputRef}
            type="text"
            value={inputState}
            onChange={handleChangeSearch}
            className={classnames(styles.input, className && className)}
          />
        )}
        <Icon
          className={styles.caret}
          name={'chevron-down'}
          onClick={handleCaretClick}
        />
        <span className={styles.errorMessage}>{errorMessage}</span>
        <Icon
          className={classnames(styles.icon, styles['icon__danger'])}
          name={'exclamation-circle'}
        />
      </div>
      {isOpen && (
        <DropdownList ref={dropdownRef}>
          {filteredOptions.map((option, idx) =>
            Array.isArray(option) ? (
              <div className={styles.group} key={idx}>
                {option.map((option, idx) => (
                  <MultiSelectDropdownOption
                    key={idx}
                    handleChange={handleChangeCheckbox}
                    checkboxVariant={checkboxVariant}
                    checked={state.some(ss => ss === option.value)}
                    optionChildren={optionChildren}
                    {...option}
                  />
                ))}
              </div>
            ) : (
              <MultiSelectDropdownOption
                key={idx}
                handleChange={handleChangeCheckbox}
                checkboxVariant={checkboxVariant}
                checked={state.some(ss => ss === option.value)}
                optionChildren={optionChildren}
                {...option}
              />
            )
          )}
        </DropdownList>
      )}
    </div>
  );
};

export default MultiSelect;
