import {useMemo} from 'react';
import {Input, SelectInput} from '../../global';
import PrevNextButtons from '../../prevn-next-buttons/PrevNextButtons';
import styles from './CompanyForm.module.scss';
import {useFormState, useIsSubmitDisabled} from '../../../hooks';
import {portfolioCompanyFormSchema} from '../../../constants/formSchemas';
import {INDUSTRY, SECTOR} from '../../../constants/scenarioFormValues';
import classnames from 'classnames';
import {
  NearCastingScenarioCompany,
  NearCastingScenarioCompanyFormData,
} from '../../../types';
import {useTranslation} from 'react-i18next';

interface CompanyFormProps {
  onSubmit: (formState: NearCastingScenarioCompanyFormData) => void;
  onCancel: () => void;
  data?: NearCastingScenarioCompany;
  formClassName?: string;
}

const CompanyForm = ({
  onSubmit,
  onCancel,
  data,
  formClassName,
}: CompanyFormProps) => {
  const {t} = useTranslation();
  const scenarioMainInfoInitialState = useMemo(
    () => ({
      id: data?.id,
      name: data?.name || '',
      valuation: data?.valuation?.toString() || '',
      comment: data?.comment || '',
      industry: data?.industry || '',
      sector: data?.sector || '',
    }),
    [
      data?.id,
      data?.name,
      data?.valuation,
      data?.comment,
      data?.industry,
      data?.sector,
    ]
  );

  const {
    formState,
    isFormStateChanged,
    validate,
    errors,
    handleChange,
    setFormState,
  } = useFormState<NearCastingScenarioCompanyFormData>(
    scenarioMainInfoInitialState,
    portfolioCompanyFormSchema
  );

  const {isSubmitDisabled} = useIsSubmitDisabled(
    null,
    errors,
    null,
    !!data || isFormStateChanged
  );

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (!isSubmitDisabled) {
      // Validate all form state on submit
      const isValid = validate(formState);
      if (isValid) {
        onSubmit(formState);
        handleCloseForm();
      }
    }
  };

  const handleCloseForm = () => {
    setFormState({...scenarioMainInfoInitialState});
    onCancel();
  };

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement> &
      React.ChangeEvent<HTMLInputElement>
  ) => {
    const allowedChars = '.0123456789';
    const allowedControlChars = [
      'Backspace',
      'Delete',
      'Tab',
      'Enter',
      'Escape',
      'ArrowLeft',
      'ArrowRight',
    ];

    if (allowedChars.includes(e.key) || allowedControlChars.includes(e.key)) {
      setFormState({
        ...formState,
        valuation: e.target.value,
      });
    } else {
      e.preventDefault();
    }
  };

  return (
    <form className={classnames(styles.formWrapper, formClassName)}>
      <div className={styles.title}>
        {t(formState.id ? 'CompanyForm.Title.Edit' : 'CompanyForm.Title.Add')}
      </div>
      <div className={styles.formInputRowWrapper}>
        <div className={styles.formInputRow}>
          <div className={styles.formInputWrapper}>
            <Input
              name="name"
              value={formState.name}
              className={styles.formInput}
              onChange={handleChange}
              label={t('CompanyForm.InputLabel.Portfolio')}
              validate={validate}
              errorMessage={t(errors.name.message || '')}
            />
          </div>
          <div className={styles.formInputWrapper}>
            <Input
              name="valuation"
              value={formState.valuation}
              className={styles.formInput}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              label={t('CompanyForm.InputLabel.Predict')}
              validate={validate}
              errorMessage={t(errors.valuation.message || '')}
            />
          </div>
          <div className={styles.formInputWrapper}>
            <Input
              name="comment"
              value={formState.comment}
              className={styles.formInput}
              onChange={handleChange}
              label={t('Global.Comment')}
              validate={validate}
              errorMessage={t(errors.comment.message || '')}
            />
          </div>
          <div className={styles.formInputWrapper}>
            <div className={styles.formInput}>
              <div className={styles.formInputLabel}>
                {t('Global.Industry')}
              </div>
              <SelectInput
                className={styles.selectDropdown}
                placeholder=""
                value={formState.industry}
                handleSelect={value =>
                  setFormState({
                    ...formState,
                    industry: value,
                  })
                }
                controllable
                options={INDUSTRY.map(value => {
                  return {
                    id: value.label,
                    value: value.label,
                    label: value.key,
                  };
                })}
              />
            </div>
          </div>
          <div className={styles.formInputWrapper}>
            <div className={styles.formInput}>
              <div className={styles.formInputLabel}>{t('Global.Sector')}</div>
              <SelectInput
                className={styles.selectDropdown}
                placeholder=""
                value={formState.sector}
                handleSelect={value =>
                  setFormState({
                    ...formState,
                    sector: value,
                  })
                }
                controllable
                options={SECTOR.map(value => {
                  return {
                    id: value.label,
                    value: value.label,
                    label: value.key,
                  };
                })}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.buttonsWrapper}>
        <PrevNextButtons
          isSubmitDisabled={
            isSubmitDisabled || !formState.name || !formState.valuation
          }
          handlePrimary={handleSubmit}
          handleSecondary={handleCloseForm}
          primaryText={t(formState.id ? 'Global.Done' : 'Global.Add')}
          secondaryText={t('Global.Cancel')}
        />
      </div>
    </form>
  );
};

export default CompanyForm;
