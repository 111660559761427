import React from 'react';
import {ColumnDef, createColumnHelper} from '@tanstack/react-table';
import Th from '../cells/th/Th';
import Td from '../cells/td/Td';
import {
  FeeAnalysisTransactionTableItemRow,
  FlexHAlign,
  FooterMapping,
  HeaderMapping,
} from '../../../../types';
import {valueFormat} from '../../../../utils';
import styles from './FeeAnalysisSubTableColumnDefs.module.scss';

const genFeeAnalysisTransactionsTableColumnDefs = (
  headerMapping: HeaderMapping[],
  footerMapping: FooterMapping[]
): ColumnDef<FeeAnalysisTransactionTableItemRow, any>[] => {
  const columnHelper = createColumnHelper<FeeAnalysisTransactionTableItemRow>();

  return [
    columnHelper.accessor('category', {
      id: 'category',
      header: props => (
        <Th
          {...props}
          headerMapping={headerMapping}
          align={FlexHAlign.start}
          width={'max(100vw*0.21, 150px)'}
        />
      ),
      cell: props => (
        <Td
          {...props}
          className={styles.cell}
          align={FlexHAlign.start}
          canHide={false}
          skipExtend={true}
        />
      ),
      footer: props => (
        <Th
          {...props}
          footerMapping={footerMapping}
          align={FlexHAlign.start}
          width={'max(100vw*0.01, 30px)'}
          className={styles.footerLabel}
        />
      ),
    }),
    columnHelper.accessor(row => +row.grossAmount.value, {
      id: 'grossAmount',
      header: props => (
        <Th {...props} headerMapping={headerMapping} align={FlexHAlign.end} />
      ),
      cell: props => {
        const {value, format} = props.row.original.grossAmount;
        return (
          <Td
            {...props}
            className={styles.cell}
            align={FlexHAlign.end}
            canHide={false}
            skipExtend={true}
          >
            {valueFormat(value, format).value}
          </Td>
        );
      },
      footer: props => (
        <Th
          {...props}
          footerMapping={footerMapping}
          align={FlexHAlign.end}
          className={styles.footerLabel}
        />
      ),
    }),
    columnHelper.accessor(row => +(row.taxAmount?.value || 0), {
      id: 'taxAmount',
      header: props => (
        <Th {...props} headerMapping={headerMapping} align={FlexHAlign.end} />
      ),
      cell: props => {
        const {currency, value, format} = props.row.original.taxAmount || {};
        return (
          <Td
            {...props}
            className={styles.cell}
            align={FlexHAlign.end}
            canHide={false}
            skipExtend={true}
            emptyValue={'-'}
          >
            {props.row.original.taxAmount &&
              `${currency} ${valueFormat(value, format).value}`}
          </Td>
        );
      },
      footer: props => (
        <Th
          {...props}
          footerMapping={footerMapping}
          align={FlexHAlign.end}
          className={styles.footerLabel}
        />
      ),
    }),
    columnHelper.accessor(row => +row.netAmount.value, {
      id: 'netAmount',
      header: props => (
        <Th {...props} headerMapping={headerMapping} align={FlexHAlign.end} />
      ),
      cell: props => {
        const {value, format} = props.row.original.netAmount;
        return (
          <Td
            {...props}
            className={styles.cell}
            align={FlexHAlign.end}
            canHide={false}
            skipExtend={true}
          >
            {valueFormat(value, format).value}
          </Td>
        );
      },
      footer: props => (
        <Th
          {...props}
          footerMapping={footerMapping}
          align={FlexHAlign.end}
          className={styles.footerLabel}
        />
      ),
    }),
  ];
};

export default genFeeAnalysisTransactionsTableColumnDefs;
