import {useEffect, useRef, useState} from 'react';
import classnames from 'classnames';
import styles from './CommentCell.module.scss';
import {useTranslation} from 'react-i18next';

type CommentCellProps = {
  comment: string;
};

const CommentCell = ({comment}: CommentCellProps): JSX.Element | null => {
  const [isExpanded, setisExpanded] = useState(false);
  const [isTextWrapped, setTextWrapped] = useState(false);
  const {t} = useTranslation();
  const commentRef = useRef<HTMLDivElement>(null);

  const toggleExpanded = () => {
    setisExpanded(!isExpanded);
  };

  useEffect(() => {
    if (commentRef.current) {
      setTextWrapped(
        (commentRef?.current?.scrollWidth || 0) >
          (commentRef?.current?.clientWidth || 0)
      );
    }
  }, [commentRef, comment]);

  if (!comment) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <div
        className={classnames(styles.comment, {
          [styles['comment__collapsed']]: !isExpanded,
        })}
        ref={commentRef}
      >
        {comment}
      </div>
      {(isExpanded || isTextWrapped) && (
        <button
          type="button"
          onClick={toggleExpanded}
          className={styles.expandButton}
        >
          {t(isExpanded ? 'Global.Hide' : 'Global.ShowMore')}
        </button>
      )}
    </div>
  );
};

export default CommentCell;
