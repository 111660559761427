import {acquireAuthResult, msalInstance} from '../../index';
import {call, put, select, takeEvery} from 'redux-saga/effects';
import {replace} from 'connected-react-router';
import routePaths from '../../routePaths';
import {
  ClientAdminWorkflowConfiguration,
  ClientAdminWorkflowConfigurationOptions,
  CreatePCWorkflowConfigPayload,
  FormStatus,
  UpdateWorkflowPayload,
  UserRole,
} from '../../types';
import {
  getClientAdminWorkflowConfig,
  getClientAdminWorkflowConfigEditOptions,
  addClientAdminWorkflowConfig,
  updateClientAdminWorkflowConfig,
} from '../../api/getClientAdminWorkflowConfig';
import {errorHandlerAction} from '../../actions';
import {
  getClientAdminWorkflowEditOptionsAction,
  addAdminWorkflowConfigAction,
  updateAdminWorkflowConfigAction,
  setClientAdminEditWorkflowFormStatus,
  setClientAdminWorkflowConfigData,
  setClientAdminWorkflowConfigEditOptions,
  setClientAdminEditWorkflowError,
} from '../../reducers/clientAdminWorkflowConfigSlice';
import {PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../../store';
import i18n from '../../i18n';

export const clientAdminWorkflowConfigSaga =
  function* clientAdminWorkflowConfigSaga(): any {
    const accountInfo = msalInstance.getAllAccounts();

    if (accountInfo?.length > 0) {
      const userRole = accountInfo[0].idTokenClaims?.extension_ApplicationRole;
      if (userRole !== UserRole.ClientAdmin) {
        return yield put(replace(routePaths.HOME));
      }
      try {
        const {accessToken} = yield call(acquireAuthResult, msalInstance);
        const response: ClientAdminWorkflowConfiguration = yield call(
          getClientAdminWorkflowConfig,
          accessToken
        );
        yield put(setClientAdminWorkflowConfigData(response));
      } catch (err) {
        console.warn(err);
        yield put(errorHandlerAction({error: err}));
      }
    }
  };

export const getAdminWorkflowConfigEditOptionsSaga =
  function* getAdminWorkflowConfigEditOptionsSaga({
    payload,
  }: PayloadAction<string>): any {
    try {
      const error: string | null = yield select(
        (state: RootState) =>
          state.dataCollectionAdministration.workflowConfiguration.error
      );
      if (error) {
        yield put(setClientAdminEditWorkflowError(null));
      }
      yield put(setClientAdminWorkflowConfigEditOptions(null));
      yield put(setClientAdminEditWorkflowFormStatus(FormStatus.Pending));
      const {accessToken} = yield call(acquireAuthResult, msalInstance);
      const response: ClientAdminWorkflowConfigurationOptions = yield call(
        getClientAdminWorkflowConfigEditOptions,
        accessToken,
        payload
      );
      yield put(setClientAdminWorkflowConfigEditOptions(response));
      yield put(setClientAdminEditWorkflowFormStatus(FormStatus.Ready));
    } catch (err) {
      yield put(setClientAdminWorkflowConfigEditOptions(null));
      yield put(setClientAdminEditWorkflowFormStatus(FormStatus.Ready));
      yield put(
        setClientAdminEditWorkflowError(
          i18n.t('DataCollection.AdminWorkflowConfig.EditOptions.Error')
        )
      );
      console.warn(err);
    }
  };

export const addAdminWorkflowConfigSaga = function* addAdminWorkflowConfigSaga({
  payload,
}: PayloadAction<CreatePCWorkflowConfigPayload>): any {
  try {
    const error: string | null = yield select(
      (state: RootState) =>
        state.dataCollectionAdministration.workflowConfiguration.error
    );
    if (error) {
      yield put(setClientAdminEditWorkflowError(null));
    }
    yield put(setClientAdminEditWorkflowFormStatus(FormStatus.Pending));
    const {accessToken} = yield call(acquireAuthResult, msalInstance);
    yield call(addClientAdminWorkflowConfig, accessToken, payload);
    yield call(clientAdminWorkflowConfigSaga);
    yield put(setClientAdminEditWorkflowFormStatus(FormStatus.Success));
    yield put(setClientAdminWorkflowConfigEditOptions(null));
  } catch (err) {
    yield put(setClientAdminEditWorkflowFormStatus(FormStatus.Ready));
    yield put(
      setClientAdminEditWorkflowError(
        i18n.t('DataCollection.AddAdminWorkflowConfig.Error')
      )
    );
    console.warn(err);
  }
};
export const updateAdminWorkflowConfigSaga =
  function* updateAdminWorkflowConfigSaga({
    payload,
  }: PayloadAction<UpdateWorkflowPayload>): any {
    try {
      const error: string | null = yield select(
        (state: RootState) =>
          state.dataCollectionAdministration.workflowConfiguration.error
      );
      if (error) {
        yield put(setClientAdminEditWorkflowError(null));
      }
      yield put(setClientAdminEditWorkflowFormStatus(FormStatus.Pending));
      const {accessToken} = yield call(acquireAuthResult, msalInstance);
      yield call(
        updateClientAdminWorkflowConfig,
        accessToken,
        payload.workflowSettings,
        payload.workflowId
      );
      yield call(clientAdminWorkflowConfigSaga);
      yield put(setClientAdminEditWorkflowFormStatus(FormStatus.Success));
      yield put(setClientAdminWorkflowConfigEditOptions(null));
    } catch (err) {
      yield put(setClientAdminEditWorkflowFormStatus(FormStatus.Ready));
      yield put(
        setClientAdminEditWorkflowError(i18n.t('UpdateWorkflow.Error'))
      );
      console.warn(err);
    }
  };

export const clientAdminWorkflowConfigSagaWatcher =
  function* clientAdminWorkflowConfigSagaWatcher() {
    yield takeEvery(
      getClientAdminWorkflowEditOptionsAction.type,
      getAdminWorkflowConfigEditOptionsSaga
    );
    yield takeEvery(
      addAdminWorkflowConfigAction.type,
      addAdminWorkflowConfigSaga
    );
    yield takeEvery(
      updateAdminWorkflowConfigAction.type,
      updateAdminWorkflowConfigSaga
    );
  };
