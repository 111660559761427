import React, {useEffect, useMemo, useState} from 'react';
import styles from './FileEditForm.module.scss';
import {
  ButtonSize,
  ButtonStyle,
  DocumentTag,
  DocumentTransactionData,
  FileEditFormProps,
  FileEditSidePanelDialogOption,
  FormStatus,
  MultiSelectOption,
  MultiSelectValue,
  ReportingPeriodsData,
  SectionTag,
  StyleVariant,
  Subscription,
  TagItem,
  UserRole,
} from '../../../types';
import {
  Button,
  ButtonSpinner,
  Checkbox,
  ConfirmationModal,
  EntityDetailsList,
  Input,
  Modal,
  MultiSelect,
  NoPreviewAvailable,
  Tag,
  TagInput,
} from '../../';
import {useFormState, useIsSubmitDisabled} from '../../../hooks';
import {fileEditFormInitialState, fileEditFormSchema} from '../../../constants';
import {Trans, useTranslation} from 'react-i18next';
import SelectTransactions from './components/SelectTransactions';
import FilePreview from '../../global/file-preview/FilePreview';
import SelectReportingPeriods from './components/SelectReportingPeriods';
import {byteValueNumberFormatter} from '../../../utils';

const FileEditForm: React.FC<FileEditFormProps> = ({
  isOpen,
  onClose,
  onSubmit,
  formStatus,
  error,
  options,
  formError,
  resetErrors,
  entityTagsOptions,
  sectionTagsOptions,
  customTagsOptions,
  userRole,
  subscriptions,
  onSelectTransactions,
  onSelectReportingPeriods,
  handleOpenFilePreviewPopup,
  isFilePreviewDataLoading = false,
  releaseFilePreviewEnabled = false,
}) => {
  const [initialState, setInitialState] = useState(fileEditFormInitialState);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isSidePanelDialogOpen, setIsSidePanelDialogOpen] = useState(false);
  const [selectedReportingPeriods, setSelectedReportingPeriods] = useState<
    ReportingPeriodsData[]
  >([]);
  const [selectedTransactions, setSelectedTransactions] = useState<
    DocumentTransactionData[]
  >([]);
  const [sidePanelDialogOption, setSidePanelDialogOption] =
    useState<FileEditSidePanelDialogOption | null>(null);

  const modalZIndex = 101; // To be able to place modal over notification popup message

  const {t} = useTranslation();

  const {
    formState,
    setFormState,
    isFormStateChanged,
    errors,
    handleChange,
    validate,
  } = useFormState(initialState, fileEditFormSchema);

  const {isSubmitDisabled} = useIsSubmitDisabled(
    formError,
    errors,
    error,
    isFormStateChanged
  );

  const handleOnChange = (
    e:
      | React.ChangeEvent<
          HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
        >
      | MultiSelectValue
  ) => {
    if (error || formError) {
      resetErrors();
    }
    handleChange(e);

    if (e.target.name === 'isPrivate') {
      setIsConfirmationModalOpen(true);
    }
  };

  const onCloseFormModal = () => {
    if (isConfirmationModalOpen || isSidePanelDialogOpen) {
      return;
    }
    setInitialState(fileEditFormInitialState);
    onClose();
  };

  const onCloseConfirmationModal = () => {
    setFormState(prev => {
      return {
        ...prev,
        isPrivate: !prev.isPrivate,
      };
    });

    setIsConfirmationModalOpen(false);
  };

  const handleOnFilePreviewClick = () => {
    if (options?.id && handleOpenFilePreviewPopup)
      handleOpenFilePreviewPopup(options?.id);
  };

  const handleChangeSystemTags = (event: MultiSelectValue) => {
    validate({[event.target.name]: event.target.value});
    handleOnChange(event);
  };

  const handleOnDoneSelectTransactions = (
    transactions: string[],
    entityTags: string[],
    selectedTransactions: DocumentTransactionData[]
  ) => {
    setFormState(prev => ({
      ...prev,
      transactions,
      entityTags,
    }));

    setSelectedTransactions(selectedTransactions);
    if (!formState.transactions.length && transactions.length > 0) {
      updateSectionTags(true, 'transactions');
    }

    if (formState.transactions.length && transactions.length === 0) {
      updateSectionTags(false, 'transactions');
    }

    setIsSidePanelDialogOpen(false);
  };

  const handleOnDoneSelectReportingPeriods = (
    columns: string[],
    reportingPeriods: ReportingPeriodsData[]
  ) => {
    setFormState(prev => ({
      ...prev,
      columns,
    }));
    setSelectedReportingPeriods(reportingPeriods);
    if (!formState.columns.length && columns.length > 0) {
      updateSectionTags(true, 'reportingPeriods');
    }

    if (formState.columns.length && columns.length === 0) {
      updateSectionTags(false, 'reportingPeriods');
    }

    setIsSidePanelDialogOpen(false);
  };

  const handleSubmit = (isUpdate: boolean = false) => {
    onSubmit({...formState, id: options?.id ?? ''});
  };

  const handleSelectTransaction = () => {
    if (options && onSelectTransactions) {
      setSidePanelDialogOption(
        FileEditSidePanelDialogOption.SelectTransactions
      );
      setIsSidePanelDialogOpen(true);
      onSelectTransactions({
        entityTags: formState.entityTags,
      });
    }
  };

  const handleSelectReportingPeriods = () => {
    if (options && onSelectReportingPeriods) {
      setSidePanelDialogOption(
        FileEditSidePanelDialogOption.SelectReportingPeriods
      );
      setIsSidePanelDialogOpen(true);
      onSelectReportingPeriods({
        entityTags: formState.entityTags,
      });
    }
  };

  const handleChangeCustomTags = (value: TagItem[]) => {
    if (error || formError) {
      resetErrors();
    }

    setFormState(prev => ({
      ...prev,
      customTags: value,
    }));
  };

  const isPrivateCheckDisabled = !(
    userRole?.includes(UserRole.ClientAdmin) || options?.isOwner
  );

  const isCompanyAdmin = userRole?.includes(UserRole.CompanyAdmin);

  useEffect(() => {
    if (options && !initialState.fileName) {
      setSelectedReportingPeriods(options.reportingPeriods ?? []);
      setSelectedTransactions(options.transactions ?? []);
      setInitialState(prev => ({
        fileName: options.fileName,
        isPrivate: options.isPrivate,
        entityTags: options.entityTags.map(tag => tag.id),
        sectionTags: options.sectionTags.map(tag => tag.id),
        customTags: options.customTags,
        transactions:
          options.transactions?.map(transaction => transaction.id) || [],
        columns: options.reportingPeriods?.map(period => period.id) || [],
      }));
    }
  }, [options]);

  useEffect(() => {
    if (formStatus === FormStatus.Success) {
      setInitialState(fileEditFormInitialState);
    }
  }, [formStatus, setInitialState]);

  const userCanAccessDataCollectionSection = useMemo(
    () =>
      !!subscriptions?.includes(Subscription.PortfolioMonitoring) ||
      userRole?.includes(UserRole.CompanyAdmin),
    [subscriptions, userRole]
  );

  const transactionsEntityData = useMemo(() => {
    return (
      selectedTransactions.map(item => [
        {
          label: t('Fund'),
          value: item.fundName ?? '',
        },
        {
          label: t('Global.PortfolioCompany'),
          value: item.companyName ?? '',
        },
        {
          label: t('Global.InstrumentType'),
          value: item.instrumentType ?? '',
        },
        {
          label: t('Global.Share'),
          value: {
            value: item.shares?.value,
            format: item.shares?.format,
          },
        },
        {
          label: t('Global.Cost'),
          value: {
            value: item.cost?.value,
            format: item.cost?.format,
            currency: item.currency,
          },
        },
        {
          label: t('Global.Date'),
          value: {
            value: item.date?.value,
            format: item.date?.format,
          },
        },
      ]) || [[]]
    );
  }, [t, selectedTransactions]);

  const fileDetailsEntityData = useMemo(() => {
    return [
      [
        {label: t('Global.Owner'), value: options?.owner ?? ''},
        {
          label: t('Global.LastModified'),
          value: {
            value: options?.lastModified.value,
            format: options?.lastModified.format,
          },
        },
        {
          label: t('Global.Created'),
          value: {
            value: options?.created.value,
            format: options?.created.format,
          },
        },
        {
          label: t('Global.FileType'),
          value: options?.fileType.toUpperCase() ?? '',
        },
        {
          label: t('Documents.FileEditForm.FileDetails'),
          value: byteValueNumberFormatter.format(options?.fileSize ?? 0),
        },
      ],
    ];
  }, [byteValueNumberFormatter, t, options]);

  const dataCollectionDetailsEntityData = useMemo(() => {
    return (
      selectedReportingPeriods.map(item => [
        {
          label: t('Portfolio Company'),
          value: item.portfolioCompany ?? '',
        },
        {
          label: t('Global.ReportingGroup'),
          value: item.reportingGroup ?? '',
        },
        {
          label: t('Global.Period'),
          value: {
            value: item.period?.value,
            format: item.period?.format,
          },
        },
        {
          label: t('Global.Template'),
          value: item.template ?? '',
        },
        {
          label: t('Global.Report'),
          value: item.report ?? '',
        },
        {
          label: t('Global.Files'),
          value: item.files || '-',
        },
        {
          label: t('Global.Status'),
          value: item.status ?? '',
        },
      ]) || [[]]
    );
  }, [t, selectedReportingPeriods]);

  const mandatoryReportingPeriodsTags = [
    SectionTag.DataCollectionDetails,
    SectionTag.ReportingPeriod,
    SectionTag.DataCollectionReporting,
  ];

  const mandatoryTransactionTags = [
    SectionTag.PortfolioCompanyInvestmentOverview,
    SectionTag.FundPortfolioTransactions,
  ];

  const getMandatoryTagsIds = (
    tags: DocumentTag[],
    mandatoryTags: string[]
  ): string[] => {
    const mandatoryTagsIds: string[] = [];
    tags.forEach(tag => {
      if (mandatoryTags.includes(tag.label)) mandatoryTagsIds.push(tag.id);
    });

    return mandatoryTagsIds;
  };

  const sectionTags = useMemo(() => {
    return sectionTagsOptions.map(tag => {
      const mandatoryOptions =
        mandatoryReportingPeriodsTags.includes(tag.label as SectionTag) ||
        (mandatoryTransactionTags.includes(tag.label as SectionTag) &&
          formState.transactions.length)
          ? {mandatory: true, visible: false}
          : {};

      return {
        ...tag,
        ...mandatoryOptions,
        value: tag.id,
      };
    });
  }, [sectionTagsOptions, formState.transactions]);

  const updateSectionTags = (
    add: boolean,
    entity: 'transactions' | 'reportingPeriods'
  ) => {
    let tags: string[] = [];

    if (entity === 'reportingPeriods')
      tags = getMandatoryTagsIds(
        sectionTagsOptions,
        mandatoryReportingPeriodsTags
      );

    if (entity === 'transactions')
      tags = getMandatoryTagsIds(sectionTagsOptions, mandatoryTransactionTags);

    // Add mandatory sections tags to FormState
    if (add) {
      setFormState(prev => {
        const tagsToAdd: string[] = tags.filter(
          tag => !prev.sectionTags.includes(tag)
        );

        return {
          ...prev,
          sectionTags: [...prev.sectionTags, ...tagsToAdd],
        };
      });

      return;
    }

    // Remove mandatory sections tags from FormState
    if (entity === 'reportingPeriods') {
      setFormState(prev => {
        return {
          ...prev,
          sectionTags: prev.sectionTags.filter(tag => !tags.includes(tag)),
        };
      });
    }
  };

  return (
    <>
      <Modal
        title={options?.fileName || ''}
        isOpen={isOpen}
        onClose={onCloseFormModal}
        variant={StyleVariant.Secondary}
        className={styles.fileNameTitle}
        sidePanelDialog={
          <>
            {sidePanelDialogOption ===
              FileEditSidePanelDialogOption.SelectTransactions && (
              <SelectTransactions
                title={t('Documents.FileEditForm.SelectTransactions.Title')}
                transactions={options?.allTransactions || null}
                selectedTransactions={formState.transactions}
                selectedEntityTags={formState.entityTags}
                onCancel={() => setIsSidePanelDialogOpen(false)}
                onDone={handleOnDoneSelectTransactions}
              />
            )}
            {sidePanelDialogOption ===
              FileEditSidePanelDialogOption.SelectReportingPeriods && (
              <SelectReportingPeriods
                title={t('Documents.FileEditForm.ReportingPeriod')}
                reportingPeriods={options?.allReportingPeriods || null}
                selectedReportingPeriods={formState.columns}
                onCancel={() => setIsSidePanelDialogOpen(false)}
                onDone={handleOnDoneSelectReportingPeriods}
              />
            )}
          </>
        }
        isSidePanelDialogOpen={
          isSidePanelDialogOpen &&
          !options?.isTransactionsRequestPending &&
          !options?.isReportingPeriodsRequestPending
        }
        footerChildren={
          options ? (
            <div className={styles.buttons}>
              <Button
                styleType={ButtonStyle.Primary}
                text={t('Global.Save')}
                onClick={() => handleSubmit()}
                loading={formStatus === FormStatus.Pending}
                disabled={isSubmitDisabled}
              />
              <Button
                styleType={ButtonStyle.Secondary}
                text={t('Global.Cancel')}
                onClick={onCloseFormModal}
                disabled={formStatus === FormStatus.Pending}
              />
            </div>
          ) : null
        }
        zIndex={modalZIndex}
      >
        {error && <div className={styles.errorMessage}>{error}</div>}
        {options ? (
          <div className={styles.content}>
            {options.filePreview ? (
              <FilePreview
                className={styles.previewImage}
                filePreviewData={options.filePreview}
                handleOnFilePreviewClick={
                  releaseFilePreviewEnabled
                    ? handleOnFilePreviewClick
                    : undefined
                }
                isFilePreviewDataLoading={isFilePreviewDataLoading}
              />
            ) : (
              <NoPreviewAvailable className={styles.previewImage} />
            )}

            <div className={styles.group}>
              <Input
                name={'fileName'}
                label={t('Global.FileName')}
                errorMessage={t(
                  errors.fileName.message || formError?.fileName || ''
                )}
                validate={validate}
                value={formState.fileName}
                onChange={handleOnChange}
                variant={StyleVariant.Secondary}
                required={true}
              />
              {!isCompanyAdmin ? (
                <div className={styles.checkbox}>
                  <p className={styles.checkboxLabel}>{t('Global.Access')}</p>
                  <Checkbox
                    name={'isPrivate'}
                    onChange={handleOnChange}
                    label={t('Global.Private')}
                    checked={formState.isPrivate}
                    disabled={isPrivateCheckDisabled}
                  />
                </div>
              ) : null}
            </div>

            {!isCompanyAdmin ? (
              <>
                <p className={styles.groupTitle}>{t('Global.SystemTags')}</p>
                <div className={styles.group}>
                  <MultiSelect
                    required={true}
                    label={t('Documents.FileEditForm.MultiSelectLabel.Entity')}
                    name="entityTags"
                    value={formState.entityTags}
                    onChange={handleChangeSystemTags}
                    variant={StyleVariant.Primary}
                    errorMessage={t(errors.entityTags.message)}
                    validate={() => true}
                    withSearch={true}
                    options={entityTagsOptions.map(tagGroup =>
                      tagGroup.map(tag => ({...tag, value: tag.id}))
                    )}
                    tagVariant={StyleVariant.Secondary}
                    checkboxVariant={StyleVariant.Secondary}
                    optionChildren={({label, color}: MultiSelectOption) => (
                      <Tag
                        label={label}
                        color={color}
                        variant={StyleVariant.Secondary}
                      />
                    )}
                    disabled={
                      !!formState.columns.length ||
                      !!formState.transactions.length
                    }
                  />

                  <MultiSelect
                    label={t('Documents.FileEditForm.MultiSelectLabel.Section')}
                    name="sectionTags"
                    value={formState.sectionTags}
                    onChange={handleChangeSystemTags}
                    variant={StyleVariant.Primary}
                    errorMessage={t(errors.sectionTags.message)}
                    validate={() => true}
                    withSearch={true}
                    options={sectionTags}
                    tagVariant={StyleVariant.Secondary}
                    checkboxVariant={StyleVariant.Secondary}
                    optionChildren={({label, color}: MultiSelectOption) => (
                      <Tag
                        label={label}
                        color={color}
                        variant={StyleVariant.Secondary}
                      />
                    )}
                  />
                </div>
              </>
            ) : null}

            <p className={styles.groupTitle}>{t('Global.CustomTags')}</p>
            <div className={styles.group}>
              <TagInput
                value={formState.customTags}
                variant={StyleVariant.Secondary}
                onChange={handleChangeCustomTags}
                options={customTagsOptions}
                optionChildren={({label, color}) => (
                  <Tag
                    label={label}
                    color={color}
                    variant={StyleVariant.Secondary}
                  />
                )}
              />
            </div>

            {onSelectTransactions && !isCompanyAdmin ? (
              <>
                <EntityDetailsList
                  title={t('Global.Transaction')}
                  paginationTitle={t('transactions')}
                  entityData={transactionsEntityData}
                  disabled={!!formState.columns.length}
                />
                <div className={styles.group}>
                  <Button
                    styleType={ButtonStyle.Secondary}
                    size={ButtonSize.Small}
                    text={t('Documents.FileEditForm.Button.Transaction')}
                    className={styles.selectTransactionButton}
                    onClick={handleSelectTransaction}
                    loading={!!options.isTransactionsRequestPending}
                    disabled={
                      formState.entityTags.length === 0 ||
                      !!formState.columns.length
                    }
                  />
                </div>
              </>
            ) : null}

            {onSelectReportingPeriods && userCanAccessDataCollectionSection ? (
              <>
                <EntityDetailsList
                  title={t('Documents.FileEditForm.EntityDetailsList.Title')}
                  paginationTitle={t(
                    'Documents.FileEditForm.EntityDetailsList.PaginationTitle'
                  )}
                  entityData={dataCollectionDetailsEntityData}
                  disabled={!!formState.transactions.length}
                />
                <div className={styles.group}>
                  <Button
                    styleType={ButtonStyle.Secondary}
                    size={ButtonSize.Small}
                    text={t('Documents.FileEditForm.ReportingPeriod')}
                    className={styles.selectTransactionButton}
                    onClick={handleSelectReportingPeriods}
                    loading={!!options.isReportingPeriodsRequestPending}
                    disabled={!!formState.transactions.length}
                  />
                </div>
              </>
            ) : null}

            <EntityDetailsList
              title={t('Documents.FileEditForm.FileDetails')}
              entityData={fileDetailsEntityData}
            />
          </div>
        ) : (
          <div className={styles.spinnerWrapper}>
            <ButtonSpinner className={styles.spinner} />
          </div>
        )}
      </Modal>
      {isConfirmationModalOpen && (
        <ConfirmationModal
          confirmButtonText={t('Global.Confirm')}
          isOpen={isConfirmationModalOpen}
          iconBgColor={'orange-peel'}
          message={
            <Trans
              i18nKey={
                formState.isPrivate
                  ? `Documents.ConfirmationModal.PrivatiseFile`
                  : `Documents.ConfirmationModal.ShareFile`
              }
              values={{name: formState.fileName}}
            />
          }
          onClose={onCloseConfirmationModal}
          onConfirm={() => setIsConfirmationModalOpen(false)}
          isLoading={false}
          zIndex={modalZIndex}
        />
      )}
    </>
  );
};

export default FileEditForm;
