import axios from 'axios';
import config from '../config';
import {NearCastingScenario, UpdateNearCastingScenario} from '../types';

export const postScenariosNewData = async (token: string, payload: any) => {
  const {data} = await axios.post<NearCastingScenario>(
    `${config.APP_URI}/near_casting/scenarios`,
    payload,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );

  return data;
};

export const getScenarioData = async (token: string, id: string) => {
  const {data} = await axios.get<NearCastingScenario>(
    `${config.APP_URI}/near_casting/scenarios/${id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );
  return data;
};

export const updateScenarioData = async (
  token: string,
  id: string,
  payload: UpdateNearCastingScenario
) => {
  const {data} = await axios.put<NearCastingScenario>(
    `${config.APP_URI}/near_casting/scenarios/${id}`,
    payload,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );

  return data;
};
