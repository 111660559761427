import {put, call} from 'redux-saga/effects';
import {replace} from 'connected-react-router';
import {msalInstance, acquireAuthResult} from '../../index';
import routePaths from '../../routePaths';
import {END_SIDE_EFFECTS_RUNNING} from '../connectedRouterSagas';

export const authGuardSaga = function* authGuardSaga() {
  const accountInfo = msalInstance.getAllAccounts();
  if (accountInfo.length > 0) {
    return;
  }

  yield put(replace(routePaths.HOME));
  return END_SIDE_EFFECTS_RUNNING;
};

export const acquireTokenSilentSaga = function* acquireTokenSilentSaga() {
  const accountInfo = msalInstance.getAllAccounts();
  if (accountInfo.length > 0) {
    try {
      yield call(acquireAuthResult, msalInstance);
    } catch (err) {
      console.error(err);
    }
  }
};
