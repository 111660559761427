import React, {
  ClipboardEvent,
  SyntheticEvent,
  useEffect,
  useRef,
  useState,
  useMemo,
} from 'react';
import styles from './ReportingTableDataCell.module.scss';
import classnames from 'classnames';
import {useIMask} from 'react-imask';
import {
  DataCellError,
  ReportingTableCell,
  ReportingTableErrorMessages,
  ReportingTableOnCellSubmit,
  ReportingTableOnSetDataCellErrorMessage,
} from '../../../../../types';
import {Icon} from '../../../index';
import {
  parseClipboardData,
  ParsedClipboardDataTypes,
} from '../../../../../utils';
import {Func} from '../../../../../types';

type DataCellProps = {
  classNames?: string;
  cell: ReportingTableCell;
  readOnly?: boolean;
  onCellSubmit: ReportingTableOnCellSubmit;
  onSetDataCellErrorMessage: ReportingTableOnSetDataCellErrorMessage;
  onCopy?: Func<any, void>;
  onPaste?: Func<any, void>;
};
const ReportingTableDataCell = ({
  classNames,
  cell,
  readOnly = false,
  onCellSubmit,
  onSetDataCellErrorMessage,
  onPaste,
}: DataCellProps) => {
  const DataCellErrorEmpty = useMemo(
    () => ({
      cellId: cell.id,
      firstError: false,
      message: '',
    }),
    [cell.id]
  );
  const previousCellValueRef = useRef<number | null>(
    cell.data?.value === null ? null : Number(cell.data?.value)
  );
  const [validationMessage, setValidationMessage] =
    useState<DataCellError>(DataCellErrorEmpty);
  const [hasMandatoryError, setHasMandatoryError] = useState<boolean>(false);

  // @ts-ignore
  const {ref, setUnmaskedValue} = useIMask<HTMLInputElement>(
    {
      mask: Number, // enable number mask
      skipInvalid: false,
      overwrite: false,

      scale: 2,
      thousandsSeparator: ',',
      padFractionalZeros: true,
      normalizeZeros: true,
      radix: '.',
      mapToRadix: ['.'],

      min: -Infinity,
      max: Infinity,
    },
    {
      onAccept: (_, mask) => {
        if (
          !cell.isVisible ||
          !cell?.data ||
          cell.data?.value?.isFromClipboard
        ) {
          return;
        }

        if (
          (previousCellValueRef.current === null &&
            Number(mask.unmaskedValue) === 0 &&
            !!mask.unmaskedValue) ||
          (Number(previousCellValueRef.current) !==
            Number(mask.unmaskedValue) &&
            !!mask.unmaskedValue)
        ) {
          previousCellValueRef.current = Number(mask.unmaskedValue);
          onCellSubmit({
            ...cell,
            data: {
              ...cell.data,
              value: Number(mask.unmaskedValue),
            },
          } as ReportingTableCell);
        }
      },
    }
  );

  useEffect(() => {
    if (cell.data?.value?.isFromClipboard && ref.current) {
      cell.data?.value?.valueFromClipboard === null
        ? (ref.current.value = '')
        : setUnmaskedValue(String(cell.data?.value?.valueFromClipboard));
    }
    if (cell.data?.value?.isUpdated && ref.current) {
      cell.data?.value?.updateValue === null
        ? (ref.current.value = '')
        : setUnmaskedValue(String(cell.data?.value?.updateValue));
    }
  }, [ref, setUnmaskedValue, cell]);

  useEffect(() => {
    const valErrorMessage = cell?.data?.errorMessage;
    if (valErrorMessage && cell?.data?.errorMessage?.message) {
      setValidationMessage(valErrorMessage);
      if (valErrorMessage.firstError) {
        ref.current?.scrollIntoView({
          block: 'center',
          behavior: 'smooth',
        });
        ref.current?.focus();
      }

      if (valErrorMessage.message === ReportingTableErrorMessages.isMandatory) {
        setHasMandatoryError(true);
      }
    } else {
      setValidationMessage(DataCellErrorEmpty);
    }
  }, [DataCellErrorEmpty, cell?.data?.errorMessage, ref]);

  const onInputHandler = (event: SyntheticEvent) => {
    setValidationMessage(DataCellErrorEmpty);
    const nativeEvent: InputEvent = event.nativeEvent as InputEvent;
    const inputData = nativeEvent.data;

    // Reset cell to empty state
    if (inputData === '' || inputData === null) {
      previousCellValueRef.current = null;
      onCellSubmit({
        ...cell,
        data: {
          ...cell.data,
          value: null,
        },
      } as ReportingTableCell);
      return;
    }

    if (
      inputData !== '.' &&
      inputData !== '-' &&
      inputData !== '+' &&
      Number.isNaN(Number(inputData)) &&
      Number.isNaN(Number(inputData))
    ) {
      if (inputData === ',') {
        setValidationMessage({
          cellId: cell.id,
          firstError: true,
          message: ReportingTableErrorMessages.dotsOnlyForDecimals,
        });
      } else {
        setValidationMessage({
          cellId: cell.id,
          firstError: true,
          message: ReportingTableErrorMessages.numbersOnly,
        });
      }
    }
  };

  const onFocusHandler = (event: React.FocusEvent<HTMLInputElement>) => {
    event.target.setAttribute('data-single-cell-copy', 'true');
  };

  const onBlurHandler = (event: React.FocusEvent<HTMLInputElement>) => {
    // Remove single cell copy attribute on focus lost
    event.target.removeAttribute('data-single-cell-copy');

    const value: number | null =
      event.target.value !== ''
        ? Number(event.target.value.replaceAll(',', ''))
        : null;

    onSetDataCellErrorMessage({
      id: cell.id,
      value,
      error: undefined,
    });

    setValidationMessage(DataCellErrorEmpty);
    setHasMandatoryError(false);
    // Remove inputErrorMessage on focus lost
    ref.current?.classList.remove(styles.inputErrrorMessage);
  };

  const onCopyHandler = (event: ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault();
    event.stopPropagation();

    const inputElement = event.target as HTMLInputElement;
    const inputValue = inputElement.value;

    const modifiedValue = inputValue.replace(/,/g, '');
    event.clipboardData.setData('text', modifiedValue);
  };

  const onPasteHandler = (event: ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault();
    const pastedValue = event.clipboardData.getData('text');
    const modifiedValue = pastedValue.replace(/,/g, ''); // Remove all commas from the pasted value

    const clipboardData = parseClipboardData(modifiedValue);

    if (clipboardData.originType === ParsedClipboardDataTypes.TSV) {
      !!onPaste && onPaste(clipboardData, cell.id);
      return;
    }

    const inputElement = event.target as HTMLInputElement;
    inputElement.value = modifiedValue;
  };

  return (
    <td
      className={classnames(styles.dataCell, {
        [`${classNames}`]: !!classNames,
        [styles.notVisible]: !cell.isVisible,
      })}
    >
      <div className={styles.inputWrapper}>
        <span
          className={classnames(styles.errorMessage, {
            [styles.errorMessage__active]: validationMessage.firstError,
          })}
        >
          {validationMessage.message}
        </span>
        <Icon
          name={'exclamation-circle'}
          className={classnames(styles.errorIcon, {
            [styles.errorIcon__active]: validationMessage.firstError,
          })}
        />
        <input
          id={cell.id}
          type="text"
          ref={ref}
          className={classnames(styles.input, {
            [styles.readOnly]: readOnly,
            [styles.fakeColumn]: !cell?.data,
            [styles.inputErrrorMessage]: validationMessage.message,
            reportingTableMandatoryErrorMessage: hasMandatoryError,
          })}
          defaultValue={
            cell.data?.value?.isFromClipboard
              ? cell.data?.value?.valueFromClipboard
              : cell.data?.value
          }
          placeholder={'-'}
          onFocus={onFocusHandler}
          onBlur={onBlurHandler}
          onInput={onInputHandler}
          onCopy={onCopyHandler}
          onPaste={onPasteHandler}
          readOnly={readOnly}
          disabled={!cell?.data}
          data-fake-column={!cell?.data}
        />
      </div>
    </td>
  );
};

export default ReportingTableDataCell;
