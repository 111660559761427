import React, {useMemo, useState} from 'react';
import {
  CartesianGrid,
  Label,
  Legend,
  Line,
  LineChart,
  ReferenceArea,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import CustomYAxisTick from '../../../../global/custom-y-axis-tick/CustomYAxisTick';
import FundPerformanceMoicTooltip from '../../../../fund-overview/fund-performance-widget/components/fund-performance-moic-chart/components/FundPerformanceMoicTooltip';
import NavCustomLegend from '../../../../fund-overview/fund-performance-widget/components/nav-custom-legend/NavCustomLegend';
import {roiIrrLegendItems} from '../../consts';
import {SegmentedControlVariants} from '../../../../global/segmented-control/SegmentedControl';
import {PerformanceWidgetChartProps} from '../../PerformanceWidget';
import {
  useChartAnimated,
  useChartHover,
  useMultiAxesTicksCalculation,
} from '../../../../../hooks';
import {
  BundledExportProps,
  ButtonStyle,
  NavTimeSeries,
  TicksType,
} from '../../../../../types';
import {
  IconButton,
  LiveDataChartLabelContent,
  LiveDataChartShape,
} from '../../../../global';
import {
  getPerformanceRoiIrrImagesExportPayload,
  getPerformanceRoiIrrTableExportPayload,
} from './PerformanceRoiIrrChartSettings';
import styles from './PerformanceRoiIrrChart.module.scss';
import {genPortfolioLegendActions} from '../../../../../utils/performanceWidget';
import {
  calcDomainRange,
  calcXaxisTicks,
} from '../../../../../utils/benchmarking';
import NavXAXisTick from '../../../../fund-overview/fund-performance-widget/components/fund-performance-nav-chart/components/NavXAxisTick';

const PerformanceRoiIrrChart: React.FC<PerformanceWidgetChartProps> = ({
  timeSeries: reportedTimeSeries,
  nearCastingData,
  showExport = true,
  format,
  activeKpi,
  setActiveKpi,
  exportHandler,
  entityName,
  isConsolidatedData,
  id,
  signOffDate,
}) => {
  const [exportInProgress, setExportInProgress] = useState<boolean>(false);
  const {ref, isAnimated} = useChartAnimated();
  const {hovered, handleMouseMove, handleMouseLeave} = useChartHover();
  const isNearCastingEmpty =
    !nearCastingData?.timeSeries || !nearCastingData?.timeSeries?.data?.length;
  const timeSeries = useMemo(
    () => ({
      data: [
        ...(reportedTimeSeries?.data || []),
        ...(nearCastingData?.timeSeries?.data || []),
      ],
    }),
    [reportedTimeSeries, nearCastingData]
  );
  const chartData = useMemo(() => {
    return (
      timeSeries.data?.map(item => ({
        ...item,
        x: new Date((item as any).date as string),
      })) || []
    );
  }, [timeSeries]);
  const nearCastingStartingFrom = (
    reportedTimeSeries?.data?.slice(-1)[0] as any
  )?.date;

  const getXAxisTicks = useMemo(() => {
    return calcXaxisTicks(
      chartData as NavTimeSeries[],
      chartData?.length > 7 ? TicksType.Year : TicksType.Quarter,
      true
    ).map((item: string) => new Date(item));
  }, [chartData]);

  const {from, to} = useMemo(() => {
    return calcDomainRange(chartData);
  }, [chartData]);

  const {
    maxLeftYAxis,
    minLeftAxis,
    maxRightAxis,
    minRightAxis,
    ticksRightAxis,
    ticksLeftAxis,
  } = useMultiAxesTicksCalculation({
    data: chartData,
    leftAxisDataKeys: ['ROI'],
    rightAxisDataKeys: ['Net IRR'],
  });

  const handleBundledExport = async () => {
    setExportInProgress(true);

    const zipFileName = !!isConsolidatedData
      ? `${entityName}_NetROI`
      : `${entityName}_NetIRRROI`;

    await exportHandler({
      zipFileName,
      tableExportPayload: getPerformanceRoiIrrTableExportPayload(
        timeSeries.data || [],
        !!isConsolidatedData,
        zipFileName
      ),
      imagesExportPayload: getPerformanceRoiIrrImagesExportPayload(
        zipFileName || '',
        id
      ),
    } as unknown as BundledExportProps);

    setExportInProgress(false);
  };

  const getLineProps = (yAxisId: number, dataKey: string, color: string) => ({
    isAnimationActive: isAnimated,
    hide: !isAnimated,
    dot: false,
    yAxisId,
    dataKey,
    stroke: color,
    activeDot: {
      stroke: color,
      fill: color,
      strokeWidth: 5,
      strokeOpacity: 0.5,
      r: 6.5,
    },
  });

  return (
    <div ref={ref}>
      <ResponsiveContainer
        width="100%"
        height={560}
        id={`${id}-performance-roi-irr-chart`}
      >
        <LineChart
          onMouseLeave={handleMouseLeave}
          onMouseMove={handleMouseMove}
          data={chartData as any}
          margin={{top: 35, left: 80, right: 100, bottom: 72}}
        >
          <defs>
            <linearGradient
              id="nearCastingGradient"
              x1="24.4311"
              y1="6.58801e-06"
              x2="458.185"
              y2="407.236"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor={`#rgb(var(--colors-nearcasting-gradient2))`} />
              <stop
                offset="1"
                stopColor={`rgb(var(--colors-nearcasting-gradient1))`}
              />
            </linearGradient>
            <linearGradient
              id="signOffDataGradient"
              gradientTransform="rotate(0)"
            >
              <stop offset="0%" stopColor={`rgba(65, 65, 65, 0.8)`} />
              <stop offset="50%" stopColor={`rgba(65, 65, 65, 0.51)`} />
              <stop offset="100%" stopColor={`rgba(52, 51, 51, 0.2)`} />
            </linearGradient>
          </defs>
          <CartesianGrid
            vertical={false}
            strokeDasharray="2"
            stroke={`rgb(var(--colors-gray-6))`}
            x={0}
            width={1920}
          />
          {!isNearCastingEmpty && (
            <ReferenceArea
              yAxisId={0}
              x1={new Date(nearCastingStartingFrom).getTime()}
              ifOverflow="visible"
              fill="url(#nearCastingGradient)"
              fillOpacity={0.3}
            />
          )}
          <Tooltip
            content={
              <FundPerformanceMoicTooltip headerKey="quarter" format={format} />
            }
          />
          {isAnimated && (
            <>
              <Line
                {...getLineProps(0, 'ROI', 'rgb(var(--colors-orange-peel))')}
                type="stepAfter"
              />
              {!isNearCastingEmpty && (
                <Line
                  {...getLineProps(
                    0,
                    'NC ROI',
                    'rgb(var(--colors-orange-peel))'
                  )}
                  type="stepAfter"
                  strokeDasharray="6 4"
                />
              )}
            </>
          )}
          {!isConsolidatedData && isAnimated ? (
            <>
              <Line
                {...getLineProps(1, 'Net IRR', 'rgb(var(--colors-lan-violet))')}
                type="stepAfter"
              />
              {!isNearCastingEmpty && (
                <Line
                  {...getLineProps(
                    1,
                    'NC Net IRR',
                    'rgb(var(--colors-lan-violet))'
                  )}
                  type="stepAfter"
                  strokeDasharray="6 4"
                />
              )}
            </>
          ) : null}
          <XAxis
            dataKey="x"
            scale="time"
            type="number"
            domain={[from.getTime(), to.getTime()]}
            interval={0}
            axisLine={false}
            height={60}
            tickLine={false}
            ticks={getXAxisTicks as any}
            padding={{left: 50, right: 50}}
            tick={
              <NavXAXisTick
                showAxis={true}
                fill="gray-3"
                fontSize={12}
                yOffset={15}
                xOffset={chartData.length > 3 ? 0 : -10}
                xAxisFormat={chartData?.length > 7 ? 'yyyy' : `QQ 'YY`}
              />
            }
          />
          <YAxis
            axisLine={false}
            tickLine={false}
            width={1}
            yAxisId={0}
            domain={[minLeftAxis, maxLeftYAxis]}
            ticks={ticksLeftAxis}
            tick={
              <CustomYAxisTick
                showAxis={true}
                xOffset={-40}
                yOffset={4}
                fill="gray-3"
                fontSize={12}
                format={'auto'}
              />
            }
          />
          <YAxis
            axisLine={false}
            tickLine={false}
            yAxisId={1}
            width={1}
            domain={[minRightAxis, maxRightAxis]}
            ticks={ticksRightAxis}
            orientation="right"
            tick={
              <CustomYAxisTick
                showAxis={true}
                xOffset={60}
                yOffset={4}
                fill="gray-3"
                fontSize={12}
                format={format}
              />
            }
          />
          {signOffDate && (
            <ReferenceArea
              x1={new Date(signOffDate).getTime()}
              shape={props => (
                <LiveDataChartShape
                  {...props}
                  rightMargin={200}
                  fill="url(#signOffDataGradient)"
                />
              )}
            >
              {hovered && (
                <Label
                  value={'In-flight data'}
                  content={LiveDataChartLabelContent}
                />
              )}
            </ReferenceArea>
          )}
          <Legend
            iconSize={12}
            verticalAlign="top"
            align="right"
            wrapperStyle={{
              paddingBottom: '56px',
              left: '50px',
              paddingRight: '80px',
              width: '100%',
            }}
            iconType="circle"
            content={
              <NavCustomLegend
                id="performance-roi-irr-chart-legend"
                kpiToggle={{
                  actions: genPortfolioLegendActions(isConsolidatedData),
                  value: activeKpi,
                  onChange: setActiveKpi,
                }}
                items={
                  isConsolidatedData
                    ? [roiIrrLegendItems[0]]
                    : roiIrrLegendItems
                }
                buttonVariant={SegmentedControlVariants.Tertiary}
                className={styles.legendRow}
                showBenchmarking={false}
                exportButton={
                  showExport ? (
                    <IconButton
                      className="performance-chart-export"
                      onClick={handleBundledExport}
                      styleType={ButtonStyle.Primary}
                      icon="export"
                      loading={exportInProgress}
                      id="performance-roi-irr-chart-export-btn"
                    />
                  ) : undefined
                }
              />
            }
            formatter={value => (
              <span
                style={{
                  color: 'rgb(var(--colors-gray-4))',
                  fontSize: 12,
                }}
              >
                {value}
              </span>
            )}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default PerformanceRoiIrrChart;
