import React, {useEffect, useMemo, useState} from 'react';
import styles from './DateRangePicker.module.scss';
import {Button} from '../index';
import {
  ButtonStyle,
  DateRange,
  DateRangePresetTypes,
  Func,
} from '../../../types';
import DateRangePresets, {
  DateRangePreset,
} from './component/date-range-presets/DateRangePresets';
import {sinceStartRange} from '../../../utils/date-range-presets';
import ScrollableDateRangePicker from './component/scrollable-date-range-picker/ScrollableDateRangePicker';
import {isValid} from 'date-fns';
import {useTranslation} from 'react-i18next';

export type DateRangePickerProps = {
  isOpen: boolean;
  initialDateRange: DateRange;
  previouslySelectedDateRange: DateRange | null;
  dateFormat: string;
  onApply: Func<[DateRange], void>;
  onCancel: Func<[], void>;
  rangePresets?: DateRangePreset[];
};

const DateRangePicker = ({
  isOpen,
  initialDateRange,
  previouslySelectedDateRange,
  dateFormat,
  onApply,
  onCancel,
  rangePresets = [],
}: DateRangePickerProps): JSX.Element => {
  const {t} = useTranslation();
  const defaultSelected: DateRange = useMemo(
    () => sinceStartRange(initialDateRange) as DateRange,
    [initialDateRange]
  );
  const [range, setRange] = useState<DateRange | undefined>(defaultSelected);
  const [selectedPreset, setSelectedPreset] = useState<DateRangePresetTypes>(
    DateRangePresetTypes.sinceStart
  );
  const [appliedPreset, setAppliedPreset] = useState<DateRangePresetTypes>(
    DateRangePresetTypes.sinceStart
  );
  const [dateInputsValid, setDateInputsValid] = useState<{
    from: boolean;
    to: boolean;
  }>({
    from: true,
    to: true,
  });

  useEffect(() => {
    if (isOpen) {
      setRange(
        previouslySelectedDateRange !== null
          ? previouslySelectedDateRange
          : initialDateRange
      );
      setSelectedPreset(appliedPreset);
    }
  }, [isOpen]);

  const apply = () => {
    if (
      isValid(range?.from) &&
      isValid(range?.to) &&
      dateInputsValid.from &&
      dateInputsValid.to
    ) {
      setAppliedPreset(selectedPreset);
      onApply(range as DateRange);
    }
  };

  return (
    <div className={styles.wrapper}>
      <DateRangePresets
        setRange={setRange}
        rangePresets={rangePresets}
        initialDateRange={initialDateRange}
        previouslySelectedDateRange={previouslySelectedDateRange}
        selectedPreset={selectedPreset}
        setSelectedPreset={setSelectedPreset}
        dateRange={range}
      />
      <ScrollableDateRangePicker
        initialDateRange={initialDateRange}
        dateRange={range}
        dateFormat={dateFormat}
        setDateRange={setRange}
        setSelectedPreset={setSelectedPreset}
        dateInputValid={dateInputsValid}
        setDateInputsValid={setDateInputsValid}
        selectedPreset={selectedPreset}
      />
      <div className={styles.pickerActionsWrapper}>
        <Button
          text={t('Global.Apply')}
          styleType={ButtonStyle.Primary}
          onClick={apply}
          disabled={!dateInputsValid.from || !dateInputsValid.to}
          className={styles.lightThemePrimaryButton}
        />
        <Button
          text={t('Global.Cancel')}
          styleType={ButtonStyle.Secondary}
          onClick={onCancel}
          className={styles.lightThemeSecondaryButton}
        />
      </div>
    </div>
  );
};

export default DateRangePicker;
