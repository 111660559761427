import axios from 'axios';
import config from '../config';
import {FundListPerformanceResponse} from './getFundPerformanceData';

export const getScenarioFundPerformanceData = async (
  token: string,
  id: string
) => {
  const {data} = await axios.get<FundListPerformanceResponse>(
    `${config.APP_URI}/near_casting/scenarios/${id}/performance`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );
  return data;
};
