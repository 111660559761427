import {all, call, put, takeEvery} from 'redux-saga/effects';
import {acquireAuthResult, msalInstance} from '../..';
import {PayloadAction} from '@reduxjs/toolkit';
import {errorHandlerAction} from '../../actions';
import {
  FundNearCastingDetailed,
  FundScenarioState,
  NearCastingScenario,
} from '../../types';
import {
  deleteNearCastingScenario,
  duplicateNearCastingScenario,
  getNearCastingAllScenariosDataDetailed,
  updateNearCastingScenarioStatus,
} from '../../api/getFundNearCasting';
import {
  archiveNearCastingScenarioAction,
  clearFundNearCastingData,
  deleteNearCastingScenarioAction,
  duplicateNearCastingScenarioAction,
  getNearCastingAllScenariosDataAction,
  publishNearCastingScenarioAction,
  setFundNearCastingData,
  setLoading,
} from '../../reducers/fundDetailsNearCastingSlice';
import {replace} from 'connected-react-router';
import {
  setScenarioDuplicated,
  setScenarioEditMode,
  setScenarioMainInfoData,
} from '../../reducers/scenarioDetailsMainInfoSlice';
import i18n from '../../i18n';
import {callToastSuccess, DEFAULT_SUCCESSFUL_TOAST_OPTIONS} from '../../utils';

export const fundDetailsNearCastingSaga = function* fundDetailsNearCastingSaga({
  payload,
}: PayloadAction<{
  match: any;
  companyId: string;
  companyType: string;
  isFundCurrency: boolean;
  investmentGroupId: string;
}>) {
  yield put(setLoading());
  const id: string = payload?.match?.params?.id;
  if (id) {
    yield put(clearFundNearCastingData());
    try {
      const {accessToken} = yield call(acquireAuthResult, msalInstance);
      const response: FundNearCastingDetailed = yield call(
        getNearCastingAllScenariosDataDetailed,
        accessToken,
        id
      );
      yield put(setFundNearCastingData(response));
      yield put(setLoading());
    } catch (err) {
      console.warn(err);
      yield put(setLoading());
      yield put(errorHandlerAction({error: err}));
    }
  }
};

export const deleteNearCastingScenarioSaga =
  function* deleteNearCastingScenarioSaga({
    payload,
  }: PayloadAction<{
    id: string;
    scenarioName: string;
    fundId: string;
    navigateTo?: string;
  }>) {
    try {
      yield put(setLoading());
      const {id, scenarioName, fundId, navigateTo} = payload;
      const {accessToken} = yield call(acquireAuthResult, msalInstance);
      yield call(deleteNearCastingScenario, accessToken, id);
      if (navigateTo) {
        yield put(replace(navigateTo));
      }
      yield call(
        callToastSuccess,
        `"${scenarioName}" ${i18n.t(
          'DeleteNearCastingScenario.Message.Success'
        )}`,
        DEFAULT_SUCCESSFUL_TOAST_OPTIONS
      );
      const response: FundNearCastingDetailed = yield call(
        getNearCastingAllScenariosDataDetailed,
        accessToken,
        fundId
      );
      yield put(setFundNearCastingData(response));
      yield put(setLoading());
    } catch (err) {
      console.warn(err);
      yield put(setLoading());
      yield put(errorHandlerAction({error: err}));
    }
  };

export const publishNearCastingScenarioSaga =
  function* publishNearCastingScenarioSaga({
    payload,
  }: PayloadAction<{
    scenarioId: string;
    status: FundScenarioState;
    navigateTo?: string;
  }>) {
    try {
      yield put(setLoading());
      const {accessToken} = yield call(acquireAuthResult, msalInstance);
      const {scenarioId, status, navigateTo} = payload;

      const updatedScenarioResponse: NearCastingScenario = yield call(
        updateNearCastingScenarioStatus,
        accessToken,
        {status},
        scenarioId
      );
      if (navigateTo) {
        yield put(replace(navigateTo));
      }
      yield call(
        callToastSuccess,
        `"${updatedScenarioResponse.scenarioName}" ${i18n.t(
          'PublishNearCastingScenarioSaga.Message.Success'
        )}`,
        DEFAULT_SUCCESSFUL_TOAST_OPTIONS
      );
      const response: FundNearCastingDetailed = yield call(
        getNearCastingAllScenariosDataDetailed,
        accessToken,
        updatedScenarioResponse.fund.id
      );
      yield put(setFundNearCastingData(response));
      yield put(setLoading());
    } catch (err) {
      console.warn(err);
      yield put(setLoading());
      yield put(errorHandlerAction({error: err}));
    }
  };

export const archiveNearCastingScenarioSaga =
  function* archiveNearCastingScenarioSaga({
    payload,
  }: PayloadAction<{
    scenarioId: string;
    status: FundScenarioState;
    navigateTo?: string;
  }>) {
    try {
      yield put(setLoading());
      const {accessToken} = yield call(acquireAuthResult, msalInstance);
      const {scenarioId, status, navigateTo} = payload;

      const updatedScenarioResponse: NearCastingScenario = yield call(
        updateNearCastingScenarioStatus,
        accessToken,
        {status},
        scenarioId
      );
      if (navigateTo) {
        yield put(replace(navigateTo));
      }
      yield call(
        callToastSuccess,
        `"${updatedScenarioResponse.scenarioName}" ${i18n.t(
          'ArchiveNearCastingScenarioSaga.Message.Success'
        )}`,
        DEFAULT_SUCCESSFUL_TOAST_OPTIONS
      );
      const response: FundNearCastingDetailed = yield call(
        getNearCastingAllScenariosDataDetailed,
        accessToken,
        updatedScenarioResponse.fund.id
      );
      yield put(setFundNearCastingData(response));
      yield put(setLoading());
    } catch (err) {
      console.warn(err);
      yield put(setLoading());
      yield put(errorHandlerAction({error: err}));
    }
  };

export const duplicateNearCastingScenarioSaga =
  function* duplicateNearCastingScenarioSaga({
    payload,
  }: PayloadAction<{
    fundId: string;
    scenarioId: string;
  }>) {
    try {
      yield put(setLoading());
      const {accessToken} = yield call(acquireAuthResult, msalInstance);
      const {scenarioId, fundId} = payload;

      const duplicateScenarioResponse: NearCastingScenario = yield call(
        duplicateNearCastingScenario,
        accessToken,
        scenarioId
      );
      yield put(setScenarioMainInfoData(duplicateScenarioResponse));
      yield put(setScenarioEditMode());
      yield put(setScenarioDuplicated());
      yield put(
        replace(
          `/scenarios/funds/${fundId}/scenarios/${duplicateScenarioResponse.id}/main`
        )
      );
      yield put(setLoading());
    } catch (err) {
      console.warn(err);
      yield put(setLoading());
      yield put(errorHandlerAction({error: err}));
    }
  };

export const fundDetailsNearCastingSagaWatcher =
  function* fundDetailsNearCastingSagaWatcher() {
    yield all([
      takeEvery(
        getNearCastingAllScenariosDataAction.type,
        fundDetailsNearCastingSaga
      ),
      takeEvery(
        deleteNearCastingScenarioAction.type,
        deleteNearCastingScenarioSaga
      ),
      takeEvery(
        publishNearCastingScenarioAction.type,
        publishNearCastingScenarioSaga
      ),
      takeEvery(
        archiveNearCastingScenarioAction.type,
        archiveNearCastingScenarioSaga
      ),
      takeEvery(
        duplicateNearCastingScenarioAction.type,
        duplicateNearCastingScenarioSaga
      ),
    ]);
  };
