import {call, put, select, takeEvery} from 'redux-saga/effects';
import {push, replace} from 'connected-react-router';
import {PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../../store';
import {acquireAuthResult, msalInstance} from '../../index';
import routePaths from '../../routePaths';
import {
  ReportingGroupTemplateFormState,
  ClientAdminTemplateConfiguration,
  FormStatus,
  UserRole,
  TemplateConfigReportingGroup,
  TemplateConfigReportingGroupItem,
} from '../../types';
import {
  addNewTemplate,
  getClientAdminTemplateConfig,
} from '../../api/getClientAdminTemplateConfig';
import {errorHandlerAction} from '../../actions';
import {
  addNewTemplateConfigTemplateAction,
  setAddNewTemplateError,
  setAddNewTemplateFormError,
  setAddNewTemplateStatus,
  setClientAdminTemplateConfigData,
  setClientAdminTemplateConfigDataGroup,
} from '../../reducers/clientAdminTemplateConfigSlice';
import {responseErrorResolver} from '../../utils';
import i18n from '../../i18n';

export const clientAdminTemplateConfigSaga =
  function* clientAdminTemplateConfigSaga(): any {
    const accountInfo = msalInstance.getAllAccounts();

    if (accountInfo?.length > 0) {
      const userRole = accountInfo[0].idTokenClaims?.extension_ApplicationRole;
      if (userRole !== UserRole.ClientAdmin) {
        return yield put(replace(routePaths.HOME));
      }
      try {
        const {accessToken} = yield call(acquireAuthResult, msalInstance);
        const response: ClientAdminTemplateConfiguration = yield call(
          getClientAdminTemplateConfig,
          accessToken
        );
        yield put(setClientAdminTemplateConfigData(response));
      } catch (err) {
        console.warn(err);
        yield put(errorHandlerAction({error: err}));
      }
    }
  };

export const addNewTemplateConfigTemplateSaga =
  function* addNewTemplateConfigTemplateSaga({
    payload,
  }: PayloadAction<ReportingGroupTemplateFormState>): any {
    const error: string | null = yield select(
      (state: RootState) =>
        state.dataCollectionAdministration.templateConfiguration.error
    );
    if (error) {
      yield put(setAddNewTemplateError(null));
    }
    const formError: string | null = yield select(
      (state: RootState) =>
        state.dataCollectionAdministration.templateConfiguration.formError
    );
    if (formError) {
      yield put(setAddNewTemplateFormError(null));
    }
    try {
      const {accessToken} = yield call(acquireAuthResult, msalInstance);
      yield put(setAddNewTemplateStatus(FormStatus.Pending));
      const response: TemplateConfigReportingGroup<TemplateConfigReportingGroupItem> =
        yield call(
          addNewTemplate,
          accessToken,
          payload.reportingGroupId,
          payload.name,
          payload.templateParentId
        );
      yield put(setClientAdminTemplateConfigDataGroup(response));
      yield put(setAddNewTemplateStatus(FormStatus.Success));
      const newTemplate = response.data.find(
        template => template.template === payload.name
      );
      if (newTemplate) {
        yield put(
          push(
            `${routePaths.ADMIN_DATA_COLLECTION_TEMPLATE_CONFIGURATION}/${newTemplate.id}`
          )
        );
      }
    } catch (err) {
      console.warn(err);
      yield put(setAddNewTemplateStatus(FormStatus.Ready));
      const {error, formError} = responseErrorResolver(
        err,
        i18n.t('DataCollection.AddNewTemplateConfigTemplateSaga.Error')
      );
      yield put(setAddNewTemplateFormError(formError));
      yield put(setAddNewTemplateError(error));
    }
  };

export const clientAdminTemplateConfigSagaWatcher =
  function* clientAdminTemplateConfigSagaWatcher() {
    yield takeEvery(
      addNewTemplateConfigTemplateAction.type,
      addNewTemplateConfigTemplateSaga
    );
  };
