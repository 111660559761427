import React from 'react';
import {
  PortfolioExposureLabelsType,
  PortfolioExposureTableType,
} from '../../../../../types/index';
import styles from './Table.module.scss';
import {ValueFormat} from '../../../../global';

interface TableProps {
  data: PortfolioExposureTableType[];
  currency?: string;
  format?: string;
  labels: PortfolioExposureLabelsType;
}

const Table: React.FC<TableProps> = ({data, currency, labels, format}) => {
  return (
    <table className={styles.table}>
      <thead>
        <tr>
          <th>{labels.category}</th>
          <th>{labels.count}</th>
          <th>
            {labels.cost} {currency && <span>({currency})</span>}
          </th>
          <th>
            {labels.valuation} {currency && <span>({currency})</span>}
          </th>
        </tr>
      </thead>
      <tbody>
        {data.map(item => (
          <tr key={item.category}>
            <td>
              <div className={styles.firstCell}>
                <div
                  className={styles.tableMarker}
                  style={{backgroundColor: `rgb(var(--colors-${item.color}))`}}
                />
                <div>{item.category}</div>
              </div>
            </td>
            <td>{item.count}</td>
            <td>
              <ValueFormat value={item.cost} format={format} />
            </td>
            <td>
              <ValueFormat value={item.valuation} format={format} />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Table;
