import {useAppSelector} from './useReduxHooks';
import {userAdminSelector} from '../selectors';

const useAdminUser = () => {
  const state = useAppSelector(state => state);
  const data = userAdminSelector(state);
  return data;
};

export default useAdminUser;
