import {createAction, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {
  FundPortfolioTransactions,
  PortfolioTransactionData,
  PortfolioTransactions,
} from '../types';
import {updateTransactionUploadedFileCount} from '../utils';

export const filterTransactionsDataAction = createAction(
  'fund-details-portfolio-transactions/filter-transactions-data',
  (fundId: string, portfolioId?: string) => {
    return {
      payload: {
        fundId,
        portfolioId,
      },
    };
  }
);

interface FundDetailsPortfolioTransactionsData {
  data: FundPortfolioTransactions | null;
  transactionsFilteringInProgress: boolean;
  selectedTransactions: PortfolioTransactionData[] | [];
  selectedTransaction: PortfolioTransactionData | null;
}

const initialState: FundDetailsPortfolioTransactionsData = {
  data: null,
  transactionsFilteringInProgress: false,
  selectedTransactions: [],
  selectedTransaction: null,
};

const fundDetailsPortfolioTransactionsSlice = createSlice({
  name: 'fund-details-portfolio-transactions',
  initialState,
  reducers: {
    setFundPortfolioTransactionsData(
      state: FundDetailsPortfolioTransactionsData,
      action: PayloadAction<FundPortfolioTransactions>
    ) {
      state.data = action.payload;
    },
    clearFundPortfolioTransactionsData(
      state: FundDetailsPortfolioTransactionsData
    ) {
      state.data = null;
    },
    setFundPortfolioTransactionsFilteredData(
      state: FundDetailsPortfolioTransactionsData,
      action: PayloadAction<PortfolioTransactions>
    ) {
      if (state.data) {
        state.data.transactions = action.payload;
      }
    },
    setTransactionsFilteringInProgress(
      state: FundDetailsPortfolioTransactionsData,
      action: PayloadAction<boolean>
    ) {
      state.transactionsFilteringInProgress = action.payload;
    },
    setFundPortfolioSelectedTransactions(
      state: FundDetailsPortfolioTransactionsData,
      action: PayloadAction<PortfolioTransactionData[] | []>
    ) {
      state.selectedTransactions = action.payload;
    },
    setFundPortfolioSelectedTransaction(
      state: FundDetailsPortfolioTransactionsData,
      action: PayloadAction<PortfolioTransactionData | null>
    ) {
      state.selectedTransaction = action.payload;
    },
    setFundPortfolioSelectedTransactionsUploadedFilesCount(
      state: FundDetailsPortfolioTransactionsData,
      action: PayloadAction<string[] | []>
    ) {
      action.payload.forEach((transactionId: string) => {
        if (state.data?.transactions.data) {
          updateTransactionUploadedFileCount(
            state.data.transactions.data,
            transactionId,
            'add'
          );
        }
        if (state.data?.transactions.dataLocal) {
          updateTransactionUploadedFileCount(
            state.data.transactions.dataLocal,
            transactionId,
            'add'
          );
        }
      });
    },
    setFundPortfolioSelectedTransactionsDeletedFilesCount(
      state: FundDetailsPortfolioTransactionsData,
      action: PayloadAction<string[] | []>
    ) {
      action.payload.forEach((transactionId: string) => {
        if (state.data?.transactions.data) {
          updateTransactionUploadedFileCount(
            state.data.transactions.data,
            transactionId,
            'subtract'
          );
        }
        if (state.data?.transactions.dataLocal) {
          updateTransactionUploadedFileCount(
            state.data.transactions.dataLocal,
            transactionId,
            'subtract'
          );
        }
      });
    },
  },
});

export const {
  setFundPortfolioTransactionsData,
  clearFundPortfolioTransactionsData,
  setFundPortfolioTransactionsFilteredData,
  setTransactionsFilteringInProgress,
  setFundPortfolioSelectedTransactions,
  setFundPortfolioSelectedTransaction,
  setFundPortfolioSelectedTransactionsUploadedFilesCount,
  setFundPortfolioSelectedTransactionsDeletedFilesCount,
} = fundDetailsPortfolioTransactionsSlice.actions;

export default fundDetailsPortfolioTransactionsSlice.reducer;
