import {
  DocumentExplorer,
  DocumentExplorerDateFilterType,
  FileItem,
  FileUploadProcess,
  FileUploadStatus,
  SelectOption,
} from '../types';
import {
  allowedFileTypes,
  dotOnStartOrEndRegex,
  fileNameRegex,
} from '../constants';
import {addDays, addYears, startOfDay, startOfYear} from 'date-fns';

export const getDocumentExplorerFilterOptionsFromData = (
  data: DocumentExplorer | null
): {
  owner: SelectOption[];
  fileType: SelectOption[];
} => {
  const optionAll = {
    id: 'all',
    label: 'All',
    value: '',
  };

  const options: {
    owner: SelectOption[];
    fileType: SelectOption[];
  } = {
    owner: [],
    fileType: [],
  };
  if (data) {
    data.data.data.forEach(item => {
      if (!options.owner.some(ss => ss.value === item.owner)) {
        options.owner = [
          ...options.owner,
          {
            id: item.owner,
            value: item.owner,
            label: item.owner,
          },
        ];
      }
      if (!options.fileType.some(ss => ss.value === item.fileType)) {
        options.fileType = [
          ...options.fileType,
          {
            id: item.fileType,
            value: item.fileType,
            label: item.fileType,
          },
        ];
      }
    });

    const toSortedOptions = (array: SelectOption[]) => {
      return array.sort((a, b) => {
        if (!a.label) return 0;
        return (a.label as string).localeCompare(b.label as string);
      });
    };

    options.owner = [optionAll, ...toSortedOptions(options.owner)];

    options.fileType = [optionAll, ...toSortedOptions(options.fileType)];
  }
  return options;
};

export const getDocumentIconByType = (type: string) => {
  switch (type) {
    case 'application/pdf':
      return 'pdf';
    case 'text/csv':
    case 'application/vnd.ms-excel':
    case 'application/vnd.ms-excel.sheet.macroEnabled.12':
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return 'excel';
    case 'text/plain':
    case 'application/rtf':
    case 'application/msword':
    case 'application/vnd.ms-word.document.macroEnabled.12':
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return 'word';
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
    case 'application/vnd.ms-powerpoint.presentation.macroenabled.12':
    case 'application/vnd.ms-powerpoint':
      return 'powerpoint';
    case 'image/png':
    case 'image/jpeg':
    case 'image/jpg':
    case 'image/gif':
    case 'image/bmp':
    case 'image/svg+xml':
      return 'image';
    case 'audio/mpeg':
    case 'audio/wav':
    case 'audio/ogg':
      return 'audio';
    case 'video/mp4':
    case 'video/x-msvideo':
    case 'video/quicktime':
      return 'video';
    default:
      return 'document';
  }
};

export const checkFileTypeIsAllowed = (type: string) => {
  return allowedFileTypes.includes(type);
};

export const checkFileNameIsAllowed = (fileName: string) => {
  const reg1 = new RegExp(fileNameRegex);
  const reg2 = new RegExp(dotOnStartOrEndRegex);
  return reg1.test(fileName) && reg2.test(fileName);
};

export const filterItemByDate = (
  item: FileItem,
  filter: DocumentExplorerDateFilterType
): boolean => {
  const createdDate = new Date(item.lastModified.value);
  switch (filter) {
    case DocumentExplorerDateFilterType.Today: {
      const filterDate = startOfDay(new Date());
      return createdDate > filterDate;
    }
    case DocumentExplorerDateFilterType.Last7Days: {
      const filterDate = addDays(startOfDay(new Date()), -7);
      return createdDate > filterDate;
    }
    case DocumentExplorerDateFilterType.Last30Days: {
      const filterDate = addDays(startOfDay(new Date()), -30);
      return createdDate > filterDate;
    }
    case DocumentExplorerDateFilterType.ThisYear: {
      const filterDate = startOfYear(new Date());
      return createdDate > filterDate;
    }
    case DocumentExplorerDateFilterType.LastYear: {
      const filterDate = addYears(startOfYear(new Date()), -1);
      return createdDate > filterDate && createdDate < startOfYear(new Date());
    }
    default:
      return true;
  }
};

export const getUploadIsFinished = (uploads: FileUploadProcess[]): boolean => {
  return !uploads.find(
    file =>
      file.status === FileUploadStatus.Ready ||
      file.status === FileUploadStatus.Pending
  );
};
