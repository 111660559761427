import React from 'react';
import {brightnessByColor, valueFormat} from '../../../../utils';
import styles from './TreemapChartWidgetNode.module.scss';

const TreemapChartWidgetNode = (props: any) => {
  const {
    x,
    y,
    width,
    height,
    title,
    colorLabel,
    colorValue,
    tileScaleLabel,
    tileScaleValue,
    color = '',
    onClickNode,
    additionalData,
    onHover,
    hoveredId,
    canBeHovered,
  } = props;
  const textColor =
    brightnessByColor(color) > 150
      ? 'rgb(var(--colors-black))'
      : 'rgb(var(--colors-white))';

  const colorValueFormatted = valueFormat(colorValue, 'auto');
  const tileScaleValueFormatted = valueFormat(tileScaleValue, 'auto');

  const isHovered =
    hoveredId === `treemap-node-${additionalData?.id}` && canBeHovered;

  const onMouseEnter = () => {
    onHover(`treemap-node-${additionalData?.id}`);
  };

  const onMouseLeave = () => {
    onHover(``);
  };

  return (
    <g id={`treemap-node-${additionalData?.id}`}>
      <filter id="treemapNodeShadow" colorInterpolationFilters="sRGB">
        <feDropShadow dx="0" dy="0" stdDeviation="5" floodOpacity="0.3" />
      </filter>
      <rect
        fill={color}
        x={isHovered ? x - 5 : x}
        y={isHovered ? y - 5 : y}
        width={isHovered ? width + 10 : width}
        height={isHovered ? height + 10 : height}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={() => onClickNode({id: additionalData?.id})}
        style={{
          transition: '.2s ease',
          cursor: 'pointer',
          stroke: isHovered ? color : '',
          strokeOpacity: isHovered ? 0.5 : '',
          filter: isHovered ? 'url(#treemapNodeShadow)' : '',
        }}
      />
      {width > 20 && (
        <foreignObject
          x={x + 12}
          y={y + 22}
          width={width - 24}
          height={height - 44}
          style={{pointerEvents: 'none'}}
        >
          <div style={{color: textColor}} className={styles.title}>
            {title}
          </div>
          <div style={{color: textColor}} className={styles.value}>
            {tileScaleLabel}: {tileScaleValueFormatted.value}
            {tileScaleValueFormatted.suffix}
          </div>
          <div style={{color: textColor}} className={styles.value}>
            {colorLabel}: {colorValueFormatted.value}
            {colorValueFormatted.suffix}
          </div>
        </foreignObject>
      )}
    </g>
  );
};

export default TreemapChartWidgetNode;
