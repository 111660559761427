import {createAction, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {
  CreateNearCastingScenario,
  NearCastingScenario,
  UpdateNearCastingScenario,
} from '../types';

export const getScenarioDetailsMainInfoDataAction = createAction(
  'scenario-details-main/get-scenario-main-info-data',
  (
    companyId: string,
    companyType: string,
    isFundCurrency: boolean,
    investmentGroupId: string
  ) => {
    return {
      payload: {
        companyId,
        companyType,
        isFundCurrency,
        investmentGroupId,
      },
    };
  }
);

export const createNewScenarioAction = createAction(
  'scenario-details-main/post-new-scenario',
  (payload: CreateNearCastingScenario) => ({payload})
);

export const updateScenarioAction = createAction(
  'scenario-details-main/update-scenario',
  (payload: {id: string; updatePayload: UpdateNearCastingScenario}) => ({
    payload,
  })
);

interface ScenarioDetailsMainInfoData {
  data: NearCastingScenario | null;
  isLoading: boolean;
  editMode: boolean;
  scenarioDuplicated: boolean;
}

const initialState: ScenarioDetailsMainInfoData = {
  data: null,
  isLoading: false,
  editMode: false,
  scenarioDuplicated: false,
};

const scenarioDetailsMainInfoSlice = createSlice({
  name: 'scenario-details-main-info',
  initialState,
  reducers: {
    setScenarioMainInfoData(
      state: ScenarioDetailsMainInfoData,
      action: PayloadAction<NearCastingScenario>
    ) {
      state.data = action.payload;
    },
    clearScenarioDetailsMainInfoData(state: ScenarioDetailsMainInfoData) {
      state.data = null;
      state.editMode = false;
    },
    setLoading(state: ScenarioDetailsMainInfoData) {
      state.isLoading = !state.isLoading;
    },
    setScenarioEditMode(state: ScenarioDetailsMainInfoData) {
      state.editMode = true;
    },
    setScenarioDuplicated(state: ScenarioDetailsMainInfoData) {
      state.scenarioDuplicated = true;
    },
    clearScenarioDuplicated(state: ScenarioDetailsMainInfoData) {
      state.scenarioDuplicated = false;
    },
  },
});

export const {
  setScenarioMainInfoData,
  setLoading,
  clearScenarioDetailsMainInfoData,
  setScenarioEditMode,
  setScenarioDuplicated,
  clearScenarioDuplicated,
} = scenarioDetailsMainInfoSlice.actions;

export default scenarioDetailsMainInfoSlice.reducer;
