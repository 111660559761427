import React from 'react';
import {ExternalLinkProps} from '../../../types/index';
import {Icon} from '../';
import {httpRegex} from '../../../constants';
import styles from './ExternalLink.module.scss';

const ExternalLink: React.FC<ExternalLinkProps> = ({links}): JSX.Element => {
  const prependHTTPS = (val: string): string => {
    let url = val;
    if (!httpRegex.test(url)) {
      url = 'https://' + url;
    }
    return url;
  };

  return (
    <div className={styles.wrapper}>
      {links?.map((item, i) => (
        <a
          key={i}
          className={styles.externalLink}
          href={prependHTTPS(item.link)}
          target="_blank"
          rel="noopener noreferrer"
        >
          {item.icon ? (
            <Icon
              className={styles.icon}
              name={item.icon}
              tooltip={
                item.icon
                  ? item.icon[0].toUpperCase() + item.icon.slice(1)
                  : undefined
              }
            />
          ) : (
            item.link
          )}
        </a>
      ))}
    </div>
  );
};

export default ExternalLink;
