import React, {useState} from 'react';
import classnames from 'classnames';
import {
  ComponentWidgetType,
  ExportDropdownOption,
  Func,
  OverallFundExposureData,
} from '../../../types';
import {
  AsAtController,
  FundExposureWidgetSkeleton,
  SectionHeader,
  SelectDropdown,
} from '../../';
import {FundExposureWidget} from '../../fund-widgets';
import styles from './FundExposuresDashboard.module.scss';
import ExportDropdown from '../../global/export-dropdown/ExportDropdown';
import {proxy} from 'comlink';
import {
  generateDataForExport,
  imagesExportPayload,
  pickCorrectSettings,
} from '../../fund-widgets/fund-exposure-widget/FundExposureWidgetSettings';
import {
  capitalizeStringSentence,
  downloadZippedExport,
  htmlToImage,
} from '../../../utils';
import {exportsWorker} from '../../../utils/export';
import {fundExposureSortOptions} from '../../../constants';
import {format} from 'date-fns';
import {useTranslation} from 'react-i18next';

interface FundExposuresDashboardProps extends OverallFundExposureData {
  id: string;
  fundId: string;
  fundName: string;
  className?: string;
  withSectionHeader?: boolean;
  handleUpdateData?: Func<[string, string, string, string], void>;
  exportOptions: ExportDropdownOption[];
}

const FundExposureDashboardTypes = {
  Overview: 'OverallFundExposureDataSection',
  Investors: 'StructureInvestorsDataSection',
};

const FundExposuresDashboard: React.FC<FundExposuresDashboardProps> = ({
  id,
  title,
  asAt,
  firstDate,
  lastDate,
  widgets,
  className = '',
  withSectionHeader = true,
  handleUpdateData,
  fundId,
  fundName,
  isLoading,
  exportOptions,
  defaultSorting,
  type,
}): JSX.Element => {
  const {t} = useTranslation();
  const [currentDate, setCurrentDate] = useState(
    asAt?.value ? format(new Date(asAt.value), 'dd-MM-yyyy') : ''
  );
  const [sortState, setSortState] = useState(
    defaultSorting || fundExposureSortOptions[0][0].value
  );
  const handleChangeAsAt = (date: string) => {
    setCurrentDate(date);
    if (handleUpdateData) {
      const [sortValue, sortDirection] = sortState.split('-');
      handleUpdateData(fundId, currentDate, sortValue, sortDirection);
    }
  };

  const handleChangeSort = (value: string) => {
    setSortState(value as string);
    if (handleUpdateData) {
      const [sortValue, sortDirection] = value.split('-');
      handleUpdateData(fundId, currentDate, sortValue, sortDirection);
    }
  };

  const exportHandler = async (option: ExportDropdownOption) => {
    const zipFileName = `${fundName
      .split(' ')
      .join('')}_${capitalizeStringSentence(option.label)}`;

    const exportSettings = pickCorrectSettings(option, zipFileName);

    const tableExportPayload = [
      {
        data: generateDataForExport(widgets, option),
        mappings: exportSettings?.headerMapping,
        settings: exportSettings,
      },
    ];

    const exportedData = await exportsWorker.exportZippedChartData(
      tableExportPayload,
      imagesExportPayload(option, zipFileName),
      proxy(htmlToImage)
    );

    downloadZippedExport({
      name: zipFileName,
      blob: exportedData,
    });
  };

  return (
    <div id={id} className={classnames(styles.wrapper, className)}>
      {withSectionHeader && (
        <SectionHeader
          label={title}
          labelType={'large'}
          onClick={() => null}
          withActionButton={false}
          className={styles.exposuresSectionHeader}
          childrenRight={[
            <SelectDropdown
              id={`${id}-select-dropdown-fund-exposure-sorting-options`}
              label={t('Global.SortBy')}
              options={fundExposureSortOptions}
              onChange={value => handleChangeSort(value as string)}
              value={sortState}
            />,
            ...(lastDate && firstDate
              ? [
                  <AsAtController
                    label={t('Global.asAt')}
                    startDate={firstDate?.value}
                    endDate={lastDate.value}
                    value={asAt?.value || ''}
                    onChange={handleChangeAsAt}
                  />,
                ]
              : []),
            <ExportDropdown
              exportOptions={exportOptions}
              exportHandler={exportHandler}
            />,
          ]}
        />
      )}
      <div className={styles.exposuresWidgets}>
        {!isLoading ? (
          widgets?.map((widget: ComponentWidgetType, idx: number) => {
            return <FundExposureWidget {...widget} key={idx} />;
          })
        ) : (
          <FundExposureWidgetSkeleton
            sumOfSkeletons={
              type === FundExposureDashboardTypes.Overview
                ? 3
                : type === FundExposureDashboardTypes.Investors
                ? 2
                : 1
            }
          />
        )}
      </div>
    </div>
  );
};

export default FundExposuresDashboard;
