import React from 'react';
import styles from './DocumentExplorerAskAIPlaceholder.module.scss';
import {Icon} from '../../global';
import {useTranslation} from 'react-i18next';

const DocumentExplorerAskAIPlaceholder = () => {
  const {t} = useTranslation();
  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>
        <p>{t('Documents.DocumentExplorerAskAIPlaceholder.Title')}</p>
        <div className={styles.beta}>
          <Icon className={styles.logo} name="logo-light" />
          <span>{t('Global.BETA')}</span>
        </div>
      </div>
      <p className={styles.description}>
        {t(`Documents.DocumentExplorerAskAIPlaceholder.Description`)}
      </p>
    </div>
  );
};

export default DocumentExplorerAskAIPlaceholder;
