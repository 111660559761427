import React, {Fragment, useEffect, useMemo, useRef} from 'react';
import styles from './CalendarPicker.module.scss';
import {
  addMonths,
  differenceInMonths,
  format,
  startOfDay,
  startOfMonth,
  startOfToday,
} from 'date-fns';
import classnames from 'classnames';
import {CalendarPickerProps, DateRangePresetTypes} from '../../../types';
import {getDaysOfWeek} from '../../../utils';

const CalendarPicker: React.FC<CalendarPickerProps> = ({
  range,
  setRange,
  availableRange,
  selectedPreset,
  isRangePicker = true,
}) => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const calendar = useMemo(() => {
    const {weekStartsOn = 0} = getDaysOfWeek();
    const start = startOfMonth(
      availableRange?.from || addMonths(new Date(), -3)
    );
    const end = addMonths(availableRange?.to || new Date(), 1);
    const monthsCount = differenceInMonths(end, start);
    const getMonthDays = (startMonthDate: Date): Array<null | Date> => {
      let days: any[] = [];
      let year = startMonthDate.getFullYear();
      let month = startMonthDate.getMonth();
      // Get the first day of the month
      let dayOne = new Date(year, month, 1).getDay() - weekStartsOn;
      if (dayOne < 0) {
        dayOne = 6;
      }

      // Get the last date of the month
      let lastDate = new Date(year, month + 1, 0).getDate();

      // Get the day of the last date of the month
      let dayEnd = new Date(year, month, lastDate).getDay() - weekStartsOn;
      if (dayEnd < 0) {
        dayEnd = 6;
      }

      // Loop to add the last dates of the previous month
      for (let i = dayOne; i > 0; i--) {
        days = [...days, null];
      }

      // Loop to add the dates of the current month
      for (let i = 1; i <= lastDate; i++) {
        days = [...days, new Date(year, month, i)];
      }

      // Loop to add the first dates of the next month
      for (let i = dayEnd; i < 6; i++) {
        days = [...days, null];
      }

      return days;
    };
    return Array.from(Array(monthsCount)).map((_, idx) => ({
      name: format(addMonths(start, idx), 'MMM yy'),
      days: getMonthDays(addMonths(start, idx)),
    }));
  }, [availableRange]);

  const isStartDay = (day: Date | null): boolean => {
    if (!day || !range?.from) return false;
    return day.getTime() === startOfDay(range.from).getTime();
  };

  const isEndDay = (day: Date | null): boolean => {
    if (!day || !range?.to) return false;
    return day.getTime() === startOfDay(range.to).getTime();
  };

  const isToday = (day: Date | null): boolean => {
    const today = startOfToday();
    return day?.getTime() === today.getTime();
  };

  const isInRange = (day: Date | null) => {
    if (!day || !range?.from || !range?.to) return false;
    return (
      day.getTime() >= startOfDay(range.from)?.getTime() &&
      day.getTime() <= startOfDay(range.to)?.getTime()
    );
  };

  const handleStartDateClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (
      !(event.target instanceof HTMLDivElement) ||
      !event.target.dataset.day
    ) {
      return;
    }
    setRange({
      from: new Date(event.target.dataset.day),
    });
  };

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (
      !(event.target instanceof HTMLDivElement) ||
      !event.target.dataset.day
    ) {
      return;
    }

    const day = new Date(event.target.dataset.day);
    if (!range?.from || day.getTime() < range.from?.getTime()) {
      return setRange({from: day, to: range?.to || range?.from});
    }
    if (day.getTime() > range.from?.getTime()) {
      return setRange({from: range.from, to: day});
    }
  };

  useEffect(() => {
    if (
      !scrollRef.current ||
      !range?.from ||
      selectedPreset === DateRangePresetTypes.custom
    )
      return;
    const day = scrollRef.current.querySelector(
      `[data-day='${startOfDay(range.from)}']`
    );
    if (!(day instanceof HTMLElement)) return;
    if (
      day.offsetTop < scrollRef.current.scrollTop ||
      day.offsetTop >
        scrollRef.current.scrollTop + scrollRef.current.clientHeight
    ) {
      scrollRef.current.scrollTo({
        top: day?.offsetTop - scrollRef.current.clientHeight / 3,
        behavior: 'smooth',
      });
    }
  }, [range?.from, selectedPreset]);

  return (
    <div className={styles.wrapper}>
      <div
        className={styles.inner}
        onDoubleClick={isRangePicker ? handleStartDateClick : undefined} // only date range picker has double click handler
        onClick={isRangePicker ? handleClick : handleStartDateClick}
        ref={scrollRef}
      >
        {calendar.map((month, idx) => (
          <Fragment key={idx}>
            <div className={styles.monthDivider}>
              <div className={styles.monthName}>{month.name}</div>
            </div>
            {month.days.map((day, idx) => (
              <div
                key={idx}
                className={classnames(
                  styles.day,
                  !day && styles.empty,
                  isStartDay(day) && styles.start,
                  isEndDay(day) && styles.end,
                  isInRange(day) && styles.inRange,
                  isToday(day) && styles.today
                )}
                data-day={day}
              >
                {day?.getDate()}
              </div>
            ))}
          </Fragment>
        ))}
      </div>
    </div>
  );
};

export default CalendarPicker;
