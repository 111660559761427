import React from 'react';
import {Icon} from '../index';
import classnames from 'classnames';
import styles from './LoaderSpinner.module.scss';

type LoadingSpinnerProps = {
  className?: string;
};

const LoadingSpinner = ({className}: LoadingSpinnerProps) => {
  return <Icon className={classnames(styles.icon, className)} name="loader" />;
};

export default LoadingSpinner;
