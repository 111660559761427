import React from 'react';

interface LiveDataChartShapeProps {
  x: number;
  y: number;
  width: number;
  height: number;
  topMargin?: number;
  rightMargin?: number;
  bottomMargin?: number;
  fill: string;
}
const LiveDataChartShape: React.FC<LiveDataChartShapeProps> = ({
  x = 0,
  y = 0,
  height = 0,
  width = 0,
  topMargin = 0,
  rightMargin = 0,
  bottomMargin = 0,
  fill,
}) => {
  return (
    <g>
      <line
        x1={x}
        y1={y - topMargin}
        x2={x}
        y2={y - topMargin + height + bottomMargin}
        stroke="rgb(var(--colors-gray-4))"
        strokeDasharray="4 2"
      />
      <rect
        x={x}
        y={y - topMargin}
        width={width + rightMargin}
        height={height + bottomMargin}
        fill={fill}
      />
    </g>
  );
};

export default LiveDataChartShape;
