import {
  HeaderMapping,
  ImagesExportPayload,
  SankeyData,
  TableExportPayload,
  TableSettings,
} from '../../../types';

const headerMappingFeeTypes = [
  {
    columnId: 'name',
    label: 'Fee',
  },
  {
    columnId: 'value',
    label: 'Value',
  },
];

const headerMappingSupplierFees = [
  {
    columnId: 'name',
    label: 'Supplier',
  },
  {
    columnId: 'value',
    label: 'Value',
  },
];

const columnOrder = ['name', 'value'];

const meta = {
  columns: {
    name: {
      styling: {
        align: 'right',
      },
    },
    value: {
      styling: {
        align: 'right',
      },
    },
  },
};

export const sankeyChartSettingsFeeTypes = (
  zipFileName: string
): TableSettings => {
  return {
    fileName: zipFileName,
    name: 'Fee Analysis Breakdown Fee Type',
    columnVisibility: {},
    headerMapping: headerMappingFeeTypes,
    columnOrder,
    meta,
  };
};

export const sankeyChartSettingsSupplierFees = (
  zipFileName: string
): TableSettings => {
  return {
    fileName: zipFileName,
    name: 'Fee Analysis Breakdown Supplier Fees',
    columnVisibility: {},
    headerMapping: headerMappingSupplierFees,
    columnOrder,
    meta,
  };
};

export const genSankeyTableExportPayload = (
  data: any,
  isFeeTypeActive: boolean,
  zipFileName: string
): TableExportPayload[] => {
  const tableSettings = isFeeTypeActive
    ? sankeyChartSettingsFeeTypes(zipFileName)
    : sankeyChartSettingsSupplierFees(zipFileName);

  return [
    {
      data: genSankeyDataForExport(data),
      mappings: tableSettings.headerMapping as HeaderMapping[],
      settings: tableSettings,
    },
  ];
};

export const genSankeyImageExportPayload = (
  zipFileName: string
): ImagesExportPayload => {
  return {
    id: 'sankey-chart',
    fileName: zipFileName,
    opts: [
      {
        fileName: `${zipFileName}_TransparentBackground`,
        type: 'image/svg+xml',
        ext: 'svg',
        bgColor: 'transparent',
        elsToFilter: ['recharts-tooltip-wrapper'],
      },
      {
        fileName: `${zipFileName}_WithBackground`,
        type: 'image/svg+xml',
        ext: 'svg',
        bgColor: '#202020',
        elsToFilter: ['recharts-tooltip-wrapper'],
      },
      {
        fileName: `${zipFileName}_TransparentBackground`,
        type: 'image/png',
        ext: 'png',
        bgColor: 'transparent',
        elsToFilter: ['recharts-tooltip-wrapper'],
      },
      {
        fileName: `${zipFileName}_WithBackground`,
        type: 'image/png',
        ext: 'png',
        bgColor: '#202020',
        elsToFilter: ['recharts-tooltip-wrapper'],
      },
    ],
  };
};

export const genSankeyDataForExport = (data: SankeyData) => {
  if (!data) return [];

  // Total sum of all items
  const total = {
    name: data.nodes[0]?.name,
    value: data.totalFees,
  };

  // Prepare data for export
  const dataForExport = data.links.map(item => {
    return {name: data.nodes[item.target]?.name, value: item.value};
  });

  // Add total as last item
  dataForExport.push(total);

  return dataForExport;
};
