import React from 'react';
import {
  FlexHAlign,
  Func,
  HeaderMapping,
  StyleVariant,
  TemplateConfigDetailsCustomGroupItemRow,
  UpdateTemplateConfigDetailsCustomMetricsPayload,
} from '../../../../types';
import {ColumnDef, createColumnHelper} from '@tanstack/react-table';
import Th from '../cells/th/Th';
import Td from '../cells/td/Td';
import {ActivityStatus, IconButton} from '../../index';
import styles from './TemplateConfigDetailsCutomGroupTable.module.scss';

const genTemplateConfigDetailsCustomGroupTableColumnDefs = (
  headerMapping: HeaderMapping[],
  isRowEditable: boolean,
  openEditMetricsModalHandler: Func<
    [UpdateTemplateConfigDetailsCustomMetricsPayload],
    void
  >
): ColumnDef<TemplateConfigDetailsCustomGroupItemRow, any>[] => {
  const columnHelper =
    createColumnHelper<TemplateConfigDetailsCustomGroupItemRow>();

  return [
    columnHelper.accessor('reference', {
      id: 'reference',
      enableSorting: false,
      header: props => (
        <Th
          {...props}
          enableSorting={false}
          headerMapping={headerMapping}
          align={FlexHAlign.start}
        />
      ),
      cell: props => (
        <Td {...props} align={FlexHAlign.start} className={styles.cell} />
      ),
    }),
    columnHelper.accessor('reportingName', {
      id: 'reportingName',
      enableSorting: false,
      header: props => (
        <Th
          width={'max(100vw*0.09, 150px)'}
          {...props}
          enableSorting={false}
          headerMapping={headerMapping}
          align={FlexHAlign.start}
        />
      ),
      cell: props => (
        <Td
          width={'160px'}
          {...props}
          align={FlexHAlign.start}
          className={styles.cell}
        />
      ),
    }),
    columnHelper.accessor('description', {
      id: 'description',
      enableSorting: false,
      header: props => (
        <Th
          width={'max(100vw*0.18, 150px)'}
          {...props}
          enableSorting={false}
          headerMapping={headerMapping}
          align={FlexHAlign.start}
        />
      ),
      cell: props => (
        <Td
          width={'320px'}
          {...props}
          align={FlexHAlign.start}
          className={styles.cell}
        />
      ),
    }),
    columnHelper.accessor('unitType', {
      id: 'unitType',
      enableSorting: false,
      header: props => (
        <Th
          {...props}
          enableSorting={false}
          headerMapping={headerMapping}
          align={FlexHAlign.center}
        />
      ),
      cell: props => (
        <Td {...props} align={FlexHAlign.center} className={styles.cell} />
      ),
    }),
    columnHelper.accessor('unitLabel', {
      id: 'unitLabel',
      enableSorting: false,
      header: props => (
        <Th
          {...props}
          enableSorting={false}
          headerMapping={headerMapping}
          align={FlexHAlign.start}
        />
      ),
      cell: props => (
        <Td {...props} align={FlexHAlign.start} className={styles.cell} />
      ),
    }),
    columnHelper.accessor('mandatory', {
      id: 'mandatory',
      enableSorting: false,
      header: props => (
        <Th
          {...props}
          enableSorting={false}
          headerMapping={headerMapping}
          align={FlexHAlign.center}
        />
      ),
      cell: props => (
        <Td {...props} align={FlexHAlign.center} className={styles.cell}>
          <ActivityStatus
            variant={StyleVariant.Secondary}
            activity={props.row.original.mandatory}
          />
        </Td>
      ),
    }),
    columnHelper.accessor('reportingType', {
      id: 'reportingType',
      enableSorting: false,
      header: props => (
        <Th
          {...props}
          enableSorting={false}
          headerMapping={headerMapping}
          align={FlexHAlign.start}
        />
      ),
      cell: props => (
        <Td {...props} align={FlexHAlign.start} className={styles.cell} />
      ),
    }),
    columnHelper.display({
      id: 'edit',
      enableSorting: false,
      header: props => {
        return isRowEditable ? (
          <Th
            {...props}
            enableSorting={false}
            align={FlexHAlign.center}
            width={'40px'}
            headerMapping={headerMapping}
          />
        ) : null;
      },
      cell: props => {
        return isRowEditable ? (
          <Td {...props} align={FlexHAlign.end}>
            <IconButton
              className={styles.edit}
              onClick={() =>
                openEditMetricsModalHandler({
                  ...props.row.original,
                  name: props.row.original.reference,
                  groupId: '',
                  unitLabel: props.row.original.unitLabel || '',
                  question: '',
                  answerOptions: [],
                  standard: [],
                })
              }
              icon={'pencil'}
            />
          </Td>
        ) : null;
      },
    }),
  ];
};

export default genTemplateConfigDetailsCustomGroupTableColumnDefs;
