import React from 'react';
import styles from './AppLayout.module.scss';
import {useAppSelector} from '../../hooks/useReduxHooks';
import {ToastContainer} from 'react-toastify';

type Props = {
  children: React.ReactNode;
};

const AppLayout = ({children}: Props): JSX.Element => {
  const {isLoading} = useAppSelector(state => state.userProfile);
  return (
    <div
      className={styles.wrapper}
      // to prevent user interaction while userProfileService update is pending
      style={{pointerEvents: isLoading ? 'none' : 'unset'}}
    >
      <ToastContainer />
      {children}
    </div>
  );
};

export default AppLayout;
