import {useEffect, useState} from 'react';

/**
 * returns boolean value if variable is within breakpoint range
 *
 * USAGE:
 * const {isLarge} = useBreakpoint();
 * const {isSmall} = useBreakpoint();
 * const {isMedium, isXLarge} = useBreakpoint();
 * const {isCustomBreakpoint} = useBreakpoint(500, 1000);
 */

export type Breakpoint =
  | 'XSMALL'
  | 'SMALL'
  | 'MEDIUM'
  | 'LARGE'
  | 'XLARGE'
  | 'XXLARGE'
  | 'CUSTOM'
  | 'NONE';

export type Breakpoints = {[key in Breakpoint]: Breakpoint};

export const BREAKPOINTS: Breakpoints = {
  XSMALL: 'XSMALL',
  SMALL: 'SMALL',
  MEDIUM: 'MEDIUM',
  LARGE: 'LARGE',
  XLARGE: 'XLARGE',
  XXLARGE: 'XXLARGE',
  CUSTOM: 'CUSTOM',
  NONE: 'NONE',
};

const getBreakpoint = (
  width: number,
  min?: number,
  max?: number
): Breakpoint => {
  if (width >= min! && width <= max!) return BREAKPOINTS.CUSTOM;
  if (width < 480) return BREAKPOINTS.XSMALL;
  if (width >= 480 && width < 640) return BREAKPOINTS.SMALL;
  if (width >= 640 && width < 1024) return BREAKPOINTS.MEDIUM;
  if (width >= 1024 && width < 1440) return BREAKPOINTS.LARGE;
  if (width >= 1440 && width < 1920) return BREAKPOINTS.XLARGE;
  if (width >= 1920) return BREAKPOINTS.XXLARGE;

  return BREAKPOINTS.NONE;
};

interface BreakpointValue {
  isXSmall: boolean;
  isSmall: boolean;
  isMedium: boolean;
  isLarge: boolean;
  isXLarge: boolean;
  isXXlarge: boolean;
  isCustomBreakpoint: boolean;
}

const useBreakpoint = (min?: number, max?: number): BreakpointValue => {
  const [breakpoint, setBreakpoint] = useState(() =>
    getBreakpoint(window.innerWidth, min, max)
  );

  useEffect(() => {
    const handleResize = (): void => {
      setBreakpoint(() => getBreakpoint(window.innerWidth, min, max));
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [min, max]);

  return {
    isXSmall: breakpoint === BREAKPOINTS.XSMALL,
    isSmall: breakpoint === BREAKPOINTS.SMALL,
    isMedium: breakpoint === BREAKPOINTS.MEDIUM,
    isLarge: breakpoint === BREAKPOINTS.LARGE,
    isXLarge: breakpoint === BREAKPOINTS.XLARGE,
    isXXlarge: breakpoint === BREAKPOINTS.XXLARGE,
    isCustomBreakpoint: breakpoint === BREAKPOINTS.CUSTOM,
  };
};

export default useBreakpoint;
