import React from 'react';
import {ActivityTimelineProps} from '../../../types';
import {getActivityColor} from '../../../utils';
import styles from './ActivityTimeline.module.scss';

const ActivityTimeline: React.FC<ActivityTimelineProps> = ({activity}) => {
  return (
    <div className={styles.wrapper}>
      {activity?.map((item, idx) => (
        <div
          key={idx}
          style={{backgroundColor: getActivityColor(item)}}
          className={styles.item}
        />
      ))}
    </div>
  );
};

export default ActivityTimeline;
