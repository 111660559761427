import React, {useEffect, useState} from 'react';
import {z} from 'zod';
import useSchemaValidation from './useSchemaValidation';
import {
  Func,
  MultiSelectValue,
  SchemaValidationErrors,
  ValidationPayload,
} from '../types';

function useFormState<T>(
  initialState: T,
  formSchema: z.ZodObject<any>,
  mandatoryFields: Array<keyof T> = []
): {
  formState: T;
  setFormState: React.Dispatch<React.SetStateAction<T>>;
  errors: SchemaValidationErrors;
  validate: Func<[ValidationPayload], boolean>;
  handleChange: Func<
    [
      | React.ChangeEvent<
          HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
        >
      | MultiSelectValue
    ],
    void
  >;
  isFormStateChanged: boolean;
  setCurrentSchema: React.Dispatch<React.SetStateAction<z.ZodObject<any>>>;
  resetSchemaValidationErrors: Func<[], void>;
  hasEmptyMandatoryFields: boolean;
} {
  const [currentSchema, setCurrentSchema] = useState(formSchema);
  const [formState, setFormState] = useState<T>(initialState);
  const [errors, validate, resetSchemaValidationErrors] =
    useSchemaValidation(currentSchema);
  const [isFormStateChanged, setIsFormStateChanged] = useState<boolean>(false);
  const [hasEmptyMandatoryFields, setHasEmptyMandatoryFields] =
    useState<boolean>(false);

  const checkIfMandatoryFieldsFilled = () => {
    const checkForEmptyMandatoryFields = mandatoryFields.some(field => {
      const value = formState[field];
      return !value;
    });
    setHasEmptyMandatoryFields(checkForEmptyMandatoryFields);
  };

  useEffect(() => {
    if (!mandatoryFields.length) return;
    checkIfMandatoryFieldsFilled();
  }, [formState, mandatoryFields]);

  const handleChange = (
    event:
      | React.ChangeEvent<
          HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
        >
      | MultiSelectValue
  ) => {
    setFormState(prev => ({
      ...prev,
      [event.target.name]:
        event.target.type === 'checkbox'
          ? (event.target as HTMLInputElement).checked
          : event.target.value,
    }));
  };

  const isEqual = (obj1: any, obj2: any) => {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  };

  useEffect(() => {
    if (!isEqual(initialState, formState)) {
      setFormState(initialState);
    }
  }, [initialState]);

  useEffect(() => {
    isEqual(initialState, formState)
      ? setIsFormStateChanged(false)
      : setIsFormStateChanged(true);
  }, [formState]);

  return {
    formState,
    errors,
    validate,
    handleChange,
    setFormState,
    isFormStateChanged,
    setCurrentSchema,
    resetSchemaValidationErrors,
    hasEmptyMandatoryFields,
  };
}

export default useFormState;
