import React from 'react';
import styles from './EntityLogo.module.scss';
import classnames from 'classnames';
import {Size} from '../../../types';

export type EntityLogoProps = {
  name: string;
  color: string;
  url: string | null;
  size: Size;
  className?: string;
};

const EntityLogo: React.FC<EntityLogoProps> = ({
  name,
  color,
  url,
  size = Size.Medium,
  className,
}) => {
  return (
    <div
      className={classnames(styles.wrapper, styles[size], className)}
      style={{background: url ? 'transparent' : `rgb(var(--colors-${color}))`}}
    >
      {url ? (
        <img
          alt={`${name}'s icon`}
          src={url}
          className={styles.iconImage}
          style={{
            position: 'absolute',
            height: '100%',
            width: '100%',
            inset: '0px',
            objectFit: 'contain',
            color: 'transparent',
          }}
        />
      ) : (
        <span>{name[0].toUpperCase()}</span>
      )}
    </div>
  );
};

export default EntityLogo;
