import React, {useState} from 'react';
import {SectionHeader} from '../../dashboard';
import {InfoHeader} from '../../fund-overview';
import {AcquisitionInformation, Func} from '../../../types';
import styles from './AcquisitionInformationDashboard.module.scss';
import {IconButton} from '../../global';
import {useTranslation} from 'react-i18next';

interface AcquisitionInformationDashboardProps extends AcquisitionInformation {
  onEditHandler: Func<[], void>;
  onExportHandler: Func<[string], void>;
}

const AcquisitionInformationDashboard: React.FC<
  AcquisitionInformationDashboardProps
> = ({title, headerData, onEditHandler, onExportHandler}) => {
  const [isExporting, setIsExporting] = useState(false);
  const {t} = useTranslation();

  const handleExport = async () => {
    try {
      const exportHandlerButtonInfo = 'acquisitionInformation';
      setIsExporting(true);
      await onExportHandler(exportHandlerButtonInfo);
      setIsExporting(false);
    } catch (error) {
      setIsExporting(false);
      console.error(error);
    }
  };

  return (
    <div className={styles.wrapper}>
      <SectionHeader
        label={t('Acquisition information')}
        labelType="large"
        onClick={() => null}
        className={styles.sectionHeader}
        withActionButton={false}
        childrenRight={[
          <IconButton onClick={onEditHandler} icon="pencil" />,
          <IconButton
            onClick={handleExport}
            icon="export"
            loading={isExporting}
          />,
        ]}
      />
      <InfoHeader
        data={headerData}
        className={styles.infoHeader}
        gridColumnWidth={180}
      />
    </div>
  );
};

export default AcquisitionInformationDashboard;
