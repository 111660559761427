import React, {useMemo} from 'react';
import styles from './TemplateConfigDetailsCustomMetricsForm.module.scss';
import {
  ButtonStyle,
  MetricType,
  SelectOption,
  StyleVariant,
  TemplateConfigDetailsCustomMetricsFormComponentProps,
  TemplateConfigDetailsCustomMetricsFormState,
} from '../../../types';
import {
  Button,
  Input,
  Modal,
  SelectInput,
  TextArea,
  Toggle,
} from '../../global';
import {useFormState, useIsSubmitDisabled} from '../../../hooks';
import {templateConfigDetailsCustomMetricsFormSchema} from '../../../constants';
import {useTranslation} from 'react-i18next';
import {Position} from '../../../context/TooltipContext';

const TemplateConfigDetailsCustomMetricsForm: React.FC<
  TemplateConfigDetailsCustomMetricsFormComponentProps
> = ({
  error,
  data,
  onClose,
  onSubmit,
  onDelete,
  options,
  isOpen,
  inProgress,
  isDeleteInProgress,
  isWorkflowForTemplateActive,
  formError,
  resetErrors,
}) => {
  const {t} = useTranslation();
  const {
    formState,
    isFormStateChanged,
    validate,
    errors,
    handleChange,
    setFormState,
  } = useFormState<TemplateConfigDetailsCustomMetricsFormState>(
    data,
    templateConfigDetailsCustomMetricsFormSchema
  );

  const {isSubmitDisabled} = useIsSubmitDisabled(
    formError,
    errors,
    error,
    isFormStateChanged
  );

  const handleSubmit = () => {
    onSubmit(formState);
  };

  const selectedOption = useMemo(() => {
    return options.find(option => formState.parentId === option.id);
  }, [formState.name]);

  const isUnitLabelDisabled = useMemo(() => {
    if (selectedOption) {
      const unitType = selectedOption.unitType.options?.find(
        option => option.value === formState.unitType
      );
      return !!unitType?.unitLabel?.isDisabled;
    }
    return false;
  }, [selectedOption, formState.unitType]);

  const templateOptions = useMemo<SelectOption[]>(() => {
    return options.map(option => ({
      id: option.id || option.name,
      value: option.id || '',
      label: option.name,
    }));
  }, [options]);

  const handleChangeTemplate = (id: string) => {
    resetErrors();
    const template = options.find(item =>
      id === '' ? item.id === null : item.id === id
    );
    if (template) {
      setFormState({
        ...template,
        parentId: template.id,
        id: '',
        reportingType: template.reportingType.value,
        unitType: template.unitType.value,
        groupId: data.groupId,
        question: '',
        answerOptions: [],
        standard: [],
      });
    }
  };

  const handleChangeUnitType = (value: string) => {
    if (!selectedOption) return;
    const unitType = selectedOption.unitType.options.find(
      option => option.value === value
    );
    setFormState(prev => ({
      ...prev,
      unitType: value,
      unitLabel: unitType?.unitLabel.value || '',
    }));
  };

  const handleOnChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    if (error || formError) {
      resetErrors();
    }
    handleChange(e);
  };

  const isSystemMetrics = formState.type === MetricType.SystemBased;
  const isEditingMetricDisabled = !!data?.id && isWorkflowForTemplateActive;

  return (
    <Modal
      title={t(
        data.id
          ? 'TemplateConfigDetailsCustomMetricsForm.Modal.Title.Edit'
          : 'TemplateConfigDetailsCustomMetricsForm.Modal.Title.Add'
      )}
      isOpen={isOpen}
      onClose={onClose}
      variant={StyleVariant.Secondary}
      footerChildren={
        <div className={styles.buttons}>
          <Button
            disabled={isSubmitDisabled || isDeleteInProgress}
            text={t('Global.Save')}
            loading={inProgress}
            onClick={handleSubmit}
          />
          <Button
            styleType={ButtonStyle.Secondary}
            text={t('Global.Cancel')}
            onClick={onClose}
            disabled={inProgress || isDeleteInProgress}
          />
          {!!data.id && (
            <Button
              className={styles.deleteButton}
              styleType={ButtonStyle.Warning}
              text={t('Global.Delete')}
              onClick={() => onDelete(data.id, data.groupId || '')}
              loading={isDeleteInProgress}
              disabled={inProgress}
            />
          )}
        </div>
      }
    >
      <div className={styles.wrapper}>
        {error ? <div className={styles.errorMessage}>{error}</div> : null}
        <div className={styles.labelIconContainer}>
          <SelectInput
            disabled={!!formState.id}
            variant={StyleVariant.Secondary}
            options={templateOptions}
            onChange={event => handleChangeTemplate(event.target.value)}
            value={formState.parentId || ''}
            errorMessage={formError?.name}
            withSeparator={true}
            separatorIndex={0}
          />
        </div>
        <div>
          <Input
            variant={StyleVariant.Secondary}
            name={'reportingName'}
            label={t(
              'Admin.DataCollection.TemplateConfigDetailsCustomMetricsForm.InputLabel.Reporting'
            )}
            labelIconProps={{
              labelIcon: 'info',
              labelIconClassName: styles.labelIconContainer,
              labelIconTooltipContent: t(
                'DataCollection.TemplateConfigurationTooltips.MetricsReportingName'
              ),
              labelIconTooltipContentPosition: Position.Left,
              labelIconTooltipClasses: styles.tooltipContainer,
              labelIconTooltipArrow: true,
            }}
            errorMessage={
              t(errors.reportingName.message) || formError?.reportingName || ''
            }
            validate={validate}
            value={formState.reportingName}
            onChange={handleOnChange}
          />
        </div>
        <div>
          <TextArea
            variant={StyleVariant.Secondary}
            name={'description'}
            label={t('Global.Description')}
            errorMessage={
              t(errors.description.message) || formError?.description || ''
            }
            labelIconProps={{
              labelIcon: 'info',
              labelIconClassName: styles.labelIconContainer,
              labelIconTooltipContent: t(
                'DataCollection.TemplateConfigurationTooltips.MetricsDescription'
              ),
              labelIconTooltipContentPosition: Position.Left,
              labelIconTooltipClasses: styles.tooltipContainer,
              labelIconTooltipArrow: true,
            }}
            validate={validate}
            value={formState.description}
            onChange={handleOnChange}
          />
        </div>
        <div>
          <SelectInput
            variant={StyleVariant.Secondary}
            label={t('Global.UnitType')}
            name={'unitType'}
            value={formState.unitType}
            options={selectedOption?.unitType.options || []}
            onChange={event => handleChangeUnitType(event.target.value)}
            disabled={isSystemMetrics || isEditingMetricDisabled}
            errorMessage={formError?.unitType}
          />
        </div>
        <div>
          <Input
            variant={StyleVariant.Secondary}
            name={'unitLabel'}
            label={t('Global.UnitLabel')}
            errorMessage={
              t(errors.unitLabel.message) || formError?.unitLabel || ''
            }
            validate={validate}
            value={formState.unitLabel}
            onChange={handleOnChange}
            disabled={
              isSystemMetrics || isUnitLabelDisabled || isEditingMetricDisabled
            }
          />
        </div>
        <div>
          <SelectInput
            variant={StyleVariant.Secondary}
            label={t('Global.ReportingType')}
            name={'reportingType'}
            value={formState.reportingType}
            options={selectedOption?.reportingType.options || []}
            onChange={handleOnChange}
            errorMessage={t(formError?.reportingType ?? '')}
          />
        </div>
        <Toggle
          checked={formState.mandatory}
          label={t('Global.Mandatory')}
          onChange={handleChange}
          name={'mandatory'}
        />
      </div>
    </Modal>
  );
};

export default TemplateConfigDetailsCustomMetricsForm;
