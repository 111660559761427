import React from 'react';
import {DateRange, Func} from '../../../types';
import styles from './DatePickerDropdown.module.scss';
import {Calendar} from '../../global';
import DropdownWrapper from '../dropdown-wrapper/DropdownWrapper';
import {formatWithLocale} from '../../../utils';

type DatePickerDropdownProps = {
  value?: Date;
  setValue: Func<[Date | undefined], void>;
  availableRange?: DateRange;
  className?: string;
  label?: string;
  disabled?: boolean;
  dateFormat?: string;
};

const DatePickerDropdown = ({
  value,
  setValue,
  availableRange,
  className,
  label,
  disabled,
  dateFormat,
}: DatePickerDropdownProps): JSX.Element => {
  const dateSelectHandler = ({from}: DateRange) => {
    setValue(from);
  };

  return (
    <DropdownWrapper
      className={className}
      label={
        label ||
        (!!value && !!dateFormat
          ? formatWithLocale(value as Date, dateFormat)
          : '')
      }
      disabled={disabled}
    >
      {({handleCloseDropdown, isOpen}) => (
        <>
          {isOpen && (
            <div className={styles.wrapper}>
              <Calendar
                range={{from: value, to: value}}
                setRange={range => {
                  handleCloseDropdown();
                  dateSelectHandler(range);
                }}
                availableRange={availableRange}
                isRangePicker={false}
              />
            </div>
          )}
        </>
      )}
    </DropdownWrapper>
  );
};

export default DatePickerDropdown;
