import routePaths from './routePaths';
import React from 'react';
import App from './application/App';
import NotFoundView from './views/not-found/NotFoundView';
import * as authSagas from './sagas/auth/authSagas';
import {getClientDashboardSaga} from './sagas/dashboard/getClientDashboardSaga';
import {fundDetailsOverviewSaga} from './sagas/fund-details/fundDetailsOverviewSaga';
import {fundDetailsStructureSaga} from './sagas/fund-details/fundDetailsStructureSaga';
import {fundDetailsPortfolioSaga} from './sagas/fund-details/fundDetailsPortfolioSaga';
import {getAllFundsSaga} from './sagas/funds/getAllFundsSaga';
import {getAllCompaniesSaga} from './sagas/companies/getAllCompaniesSaga';
import {companyDetailsCompanySaga} from './sagas/company-details/companyDetailsCompanySaga';
import {companyDetailsInvestmentOverviewSaga} from './sagas/company-details/companyDetailsInvestmentOverviewSaga';
import {companyDetailsMonitoringSaga} from './sagas/company-details/companyDetailsMonitoringSaga';
import {fundDetailsNearCastingSaga} from './sagas/fund-details/fundDetailsNearCastingSaga';
import {pageAnalyticsSaga} from './sagas/analytics/pageAnalyticsSaga';
import {fundDetailsOperationalSaga} from './sagas/fund-details/fundDetailsOperationalSaga';
import {fundDetailsPortfolioTransactionsSaga} from './sagas/fund-details/fundDetailsPortfolioTransactionsSaga';
import {getClientAdminUsersSaga} from './sagas/clientAdmin/clientAdminUsersSaga';
import {userProfileServiceSaga} from './sagas/user-profile-service/userProfileServiceSaga';
import {clientAdminTemplateConfigSaga} from './sagas/clientAdmin/clientAdminTemplateConfigSaga';
import {clientAdminWorkflowConfigSaga} from './sagas/clientAdmin/clientAdminWorkflowConfigSaga';
import {clientAdminTemplateConfigDetailsSaga} from './sagas/clientAdmin/clientAdminTemplateConfigDetailsSaga';
import {dataCollectionSaga} from './sagas/data-collection/DataCollectionSaga';
import {dataCollectionDetailsSaga} from './sagas/data-collection-details/DataCollectionDetailsSaga';
import {companyAdminReportingSaga} from './sagas/company-admin/CompanyAdminReportingSaga';
import {clientAdminPortfolioCompanyUsersSaga} from './sagas/clientAdmin/clientAdminPortfolioCompanyUsersSaga';
import {documentExplorerSaga} from './sagas/documents/documentExplorerSaga';
import {scenarioDetailsReportSaga} from './sagas/scenario-details/scenarioDetailsReportSaga';
import {
  editScenarioSaga,
  scenarioDetailsMainSaga,
} from './sagas/scenario-details/scenarioDetailsMainSaga';
import {scenarioDetailsValuationSaga} from './sagas/scenario-details/scenarioDetailsValuationSaga';
import {scenarioDetailsTransactionsSaga} from './sagas/scenario-details/scenarioDetailsTransactionsSaga';
import {scenarioOnePageReportMainSaga} from './sagas/scenario-one-page-report/scenarioOnePageReportMainSaga';
import {scenarioOnePageReportOverviewSaga} from './sagas/scenario-one-page-report/scenarioOnePageReportOverviewSaga';
import {scenarioOnePageReportPortfolioSaga} from './sagas/scenario-one-page-report/scenarioOnePageReportPortfolioSaga';
import {scenarioOnePageReportPortfolioPerformanceSaga} from './sagas/scenario-one-page-report/scenarioOnePageReportPortfolioPerformanceSaga';

const Home = React.lazy(() => import('./views/home/Home'));
const Funds = React.lazy(() => import('./views/funds/Funds'));
const FundDetails = React.lazy(
  () => import('./views/fund-details/FundDetails')
);
const FundOverview = React.lazy(
  () => import('./views/fund-overview/FundOverview')
);
const FundOperational = React.lazy(
  () => import('./views/fund-operational/FundOperational')
);
const FundPortfolio = React.lazy(
  () => import('./views/fund-portfolio/FundPortfolio')
);
const FundPortfolioTransactions = React.lazy(
  () => import('./views/fund-portfolio-transactions/FundPortfolioTransactions')
);
const FundNearCasting = React.lazy(
  () => import('./views/fund-near-casting/FundNearCasting')
);
const FundStructure = React.lazy(
  () => import('./views/fund-structure/FundStructure')
);
const CompanyDetails = React.lazy(
  () => import('./views/company-details/CompanyDetails')
);
const ScenarioDetails = React.lazy(
  () => import('./views/scenario-details/ScenarioDetails')
);
const ScenarioDetailsMain = React.lazy(
  () => import('./views/scenario-details-main/ScenarioDetailsMain')
);
const ScenarioDetailsValuation = React.lazy(
  () => import('./views/scenario-details-valuation/ScenarioDetailsValuation')
);
const ScenarioDetailsTransactions = React.lazy(
  () =>
    import('./views/scenario-details-transactions/ScenarioDetailsTransactions')
);
const ScenarioOnePageReport = React.lazy(
  () => import('./views/scenario-one-page-report/ScenarioOnePageReport')
);
const Companies = React.lazy(() => import('./views/companies/Companies'));
const CompanyInvestmentOverview = React.lazy(
  () => import('./views/company-investment-overview/CompanyInvestmentOverview')
);
const Company = React.lazy(() => import('./views/company/Company'));
const CompanyMonitoring = React.lazy(
  () => import('./views/company-monitoring/CompanyMonitoring')
);
const ClientAdminUsersAdmin = React.lazy(
  () => import('./views/client-admin-users-admin/ClientAdminUsersAdmin')
);
const ClientAdminUsers = React.lazy(
  () => import('./views/client-admin-users/ClientAdminUsers')
);
const ClientAdminDataCollectionAdmin = React.lazy(
  () =>
    import(
      './views/client-admin-data-collection-admin/ClientAdminDataCollectionAdmin'
    )
);
const ClientAdminTemplateConfig = React.lazy(
  () => import('./views/client-admin-template-config/ClientAdminTemplateConfig')
);
const ClientAdminWorkflowConfig = React.lazy(
  () => import('./views/client-admin-workflow-config/ClientAdminWorkflowConfig')
);
const ClientAdminTemplateConfigDetails = React.lazy(
  () =>
    import(
      './views/client-admin-template-config-details/ClientAdminTemplateConfigDetails'
    )
);
const ClientAdminPortfolioCompanyUsers = React.lazy(
  () =>
    import(
      './views/client-admin-portfolio-company-users/ClientAdminPortfolioCompanyUsers'
    )
);
const DataCollection = React.lazy(
  () => import('./views/data-collection/DataCollection')
);
const DataCollectionDetails = React.lazy(
  () => import('./views/data-collection-details/DataCollectionDetails')
);
const Reporting = React.lazy(() => import('./views/reporting/Reporting'));

const Documents = React.lazy(() => import('./views/documents/Documents'));
const DocumentExplorer = React.lazy(
  () => import('./views/documents-document-explorer/DocumentExplorer')
);
/**
 * Location object definition (react-router, history)
 *
 * @typedef {Object} Location
 * @property {string} key - key is available for all by HashHistory
 * @property {string} pathname - path part of route
 * @property {string} search - search query part of route
 * @property {string} hash - hash (#) part of route
 * @property {Object} state - user defined state for the route
 */

/**
 * ConnectedRoute object definition (react-router-config, connected-react-router, redux-saga)
 *
 * @typedef {Object} ConnectedRoute
 * @property {string} path - any valid URL path that path-to-regexp understands.
 * @property {?Object} component - React component for application screen
 * @property {?function} render - render prop function
 * @property {?Location} location - for matching against different location than one in history
 * @property {?boolean} exact - when true, will only match if the path matches the location.pathname exactly
 * @property {?boolean} string - when true, a path that has a trailing slash will only match a location.pathname
 *                      with a trailing slash
 * @property {?boolean} sensitive - when true, will match if the path is case sensitive.
 * @property {?Array<ConnectedRoute>} routes - sub-routes
 * @property {?Array<Array<[saga, Object]>>} locationChangeSideEffects - Redux sagas (side-effects) to be run
 *                                            with payload object
 */

/**
 * Static route configuration
 *
 * @type {Array<ConnectedRoute>}
 */
const routes = [
  {
    component: App,
    locationChangeSideEffects: [[userProfileServiceSaga], [pageAnalyticsSaga]],
    routes: [
      {
        path: routePaths.HOME,
        exact: true,
        component: Home,
        page: 'Dashboard',
        locationChangeSideEffects: [[getClientDashboardSaga]],
      },
      {
        path: routePaths.FUNDS,
        exact: true,
        component: Funds,
        page: 'Funds',
        locationChangeSideEffects: [
          [authSagas.authGuardSaga],
          [getAllFundsSaga],
        ],
      },
      {
        path: routePaths.FUND_DETAILS,
        component: FundDetails,
        locationChangeSideEffects: [[authSagas.authGuardSaga]],
        routes: [
          {
            path: routePaths.FUND_OVERVIEW,
            exact: true,
            component: FundOverview,
            page: 'Fund Overview',
            locationChangeSideEffects: [
              [authSagas.authGuardSaga],
              [fundDetailsOverviewSaga],
            ],
          },
          {
            path: routePaths.FUND_OPERATIONAL,
            exact: true,
            component: FundOperational,
            page: 'Fund Operational',
            locationChangeSideEffects: [
              [authSagas.authGuardSaga],
              [fundDetailsOperationalSaga],
            ],
          },
          {
            path: routePaths.FUND_STRUCTURE,
            exact: true,
            component: FundStructure,
            page: 'Fund Structure',
            locationChangeSideEffects: [
              [authSagas.authGuardSaga],
              [fundDetailsStructureSaga],
            ],
          },
          {
            path: routePaths.FUND_PORTFOLIO,
            exact: true,
            component: FundPortfolio,
            page: 'Fund Portfolio',
            locationChangeSideEffects: [
              [authSagas.authGuardSaga],
              [fundDetailsPortfolioSaga],
            ],
          },
          {
            path: routePaths.FUND_PORTFOLIO_TRANSACTIONS,
            exact: true,
            component: FundPortfolioTransactions,
            page: 'Fund Portfolio Transactions',
            locationChangeSideEffects: [
              [authSagas.authGuardSaga],
              [fundDetailsPortfolioTransactionsSaga],
            ],
          },
          {
            path: routePaths.FUND_NEAR_CASTING,
            exact: true,
            component: FundNearCasting,
            page: 'Fund Near Casting',
            locationChangeSideEffects: [
              [authSagas.authGuardSaga],
              [fundDetailsNearCastingSaga],
            ],
          },
          {
            path: '*',
            component: NotFoundView,
            page: 'Unavailable Page',
          },
        ],
      },
      {
        path: routePaths.SCENARIO_REPORT,
        exact: true,
        component: ScenarioOnePageReport,
        page: 'Scenario one-page report',
        locationChangeSideEffects: [
          [authSagas.authGuardSaga],
          [scenarioOnePageReportMainSaga],
          [scenarioOnePageReportOverviewSaga],
          [scenarioOnePageReportPortfolioSaga],
          [scenarioOnePageReportPortfolioPerformanceSaga],
        ],
      },
      {
        path: routePaths.SCENARIO,
        component: ScenarioDetails,
        locationChangeSideEffects: [[authSagas.authGuardSaga]],
        routes: [
          {
            path: routePaths.SCENARIO_MAIN,
            exact: true,
            component: ScenarioDetailsMain,
            page: 'Scenario Main Info',
            locationChangeSideEffects: [
              [authSagas.authGuardSaga],
              [scenarioDetailsReportSaga],
              [scenarioDetailsMainSaga],
            ],
          },
          {
            path: routePaths.SCENARIO_MAIN_WITH_ID,
            exact: true,
            component: ScenarioDetailsMain,
            page: 'Scenario Details Main Info',
            locationChangeSideEffects: [
              [authSagas.authGuardSaga],
              [scenarioDetailsReportSaga],
              [editScenarioSaga],
            ],
          },
          {
            path: routePaths.SCENARIO_VALUATION,
            exact: true,
            component: ScenarioDetailsValuation,
            page: 'Scenario Details Valuation',
            locationChangeSideEffects: [
              [authSagas.authGuardSaga],
              [scenarioDetailsReportSaga],
              [scenarioDetailsValuationSaga],
            ],
          },
          {
            path: routePaths.SCENARIO_TRANSACTION,
            exact: true,
            component: ScenarioDetailsTransactions,
            page: 'Scenario Details Other transactions',
            locationChangeSideEffects: [
              [authSagas.authGuardSaga],
              [scenarioDetailsReportSaga],
              [scenarioDetailsTransactionsSaga],
            ],
          },
          {
            path: '*',
            component: NotFoundView,
            page: 'Unavailable Page',
          },
        ],
      },
      {
        path: routePaths.PORTFOLIO_COMPANIES,
        exact: true,
        component: Companies,
        page: 'Portfolio Companies',
        locationChangeSideEffects: [
          [authSagas.authGuardSaga],
          [getAllCompaniesSaga],
        ],
      },
      {
        path: routePaths.PORTFOLIO_COMPANY_DETAILS,
        component: CompanyDetails,
        locationChangeSideEffects: [[authSagas.authGuardSaga]],
        routes: [
          {
            path: routePaths.PORTFOLIO_COMPANY_INVESTMENT_OVERVIEW,
            exact: true,
            component: CompanyInvestmentOverview,
            page: 'Portfolio Company Investment Overview',
            locationChangeSideEffects: [
              [authSagas.authGuardSaga],
              [companyDetailsInvestmentOverviewSaga],
            ],
          },
          {
            path: routePaths.PORTFOLIO_COMPANY_COMPANY,
            exact: true,
            component: Company,
            page: 'Portfolio Company',
            locationChangeSideEffects: [
              [authSagas.authGuardSaga],
              [companyDetailsCompanySaga],
            ],
          },
          {
            path: routePaths.PORTFOLIO_COMPANY_MONITORING,
            exact: true,
            component: CompanyMonitoring,
            page: 'Portfolio Monitoring',
            locationChangeSideEffects: [
              [authSagas.authGuardSaga],
              [companyDetailsMonitoringSaga],
            ],
          },
          {
            path: '*',
            component: NotFoundView,
            page: 'Unavailable Page',
          },
        ],
      },
      {
        path: routePaths.ADMIN_USER,
        component: ClientAdminUsersAdmin,
        page: 'User Administration',
        locationChangeSideEffects: [[authSagas.authGuardSaga]],
        routes: [
          {
            path: routePaths.ADMIN_USER_USERS,
            exact: true,
            component: ClientAdminUsers,
            page: 'Admin Users',
            locationChangeSideEffects: [
              [authSagas.authGuardSaga],
              [getClientAdminUsersSaga],
            ],
          },
        ],
      },
      {
        path: routePaths.ADMIN_DATA_COLLECTION,
        component: ClientAdminDataCollectionAdmin,
        page: 'Data Collection Administration',
        locationChangeSideEffects: [[authSagas.authGuardSaga]],
        routes: [
          {
            path: routePaths.ADMIN_DATA_COLLECTION_TEMPLATE_CONFIGURATION,
            exact: true,
            component: ClientAdminTemplateConfig,
            page: 'Admin Template Configuration',
            locationChangeSideEffects: [
              [authSagas.authGuardSaga],
              [clientAdminTemplateConfigSaga],
            ],
          },
          {
            path: routePaths.ADMIN_DATA_COLLECTION_WORKFLOW_CONFIGURATION,
            exact: true,
            component: ClientAdminWorkflowConfig,
            page: 'Admin Workflow Configuration',
            locationChangeSideEffects: [
              [authSagas.authGuardSaga],
              [clientAdminWorkflowConfigSaga],
            ],
          },
          {
            path: routePaths.ADMIN_DATA_COLLECTION_TEMPLATE_CONFIGURATION_DETAILS,
            exact: true,
            component: ClientAdminTemplateConfigDetails,
            page: 'Admin Template Configuration Details',
            locationChangeSideEffects: [
              [authSagas.authGuardSaga],
              [clientAdminTemplateConfigDetailsSaga],
            ],
          },
          {
            path: routePaths.ADMIN_DATA_COLLECTION_PORTFOLIO_COMPANY_USERS,
            exact: true,
            component: ClientAdminPortfolioCompanyUsers,
            page: 'Admin Portfolio Company Users',
            locationChangeSideEffects: [
              [authSagas.authGuardSaga],
              [clientAdminPortfolioCompanyUsersSaga],
            ],
          },
        ],
      },
      {
        path: routePaths.DATA_COLLECTION,
        exact: true,
        page: 'Data Collection',
        component: DataCollection,
        locationChangeSideEffects: [
          [authSagas.authGuardSaga],
          [dataCollectionSaga],
        ],
      },
      {
        path: routePaths.DATA_COLLECTION_DETAILS,
        exact: true,
        page: 'Data Collection Details',
        component: DataCollectionDetails,
        locationChangeSideEffects: [
          [authSagas.authGuardSaga],
          [dataCollectionDetailsSaga],
        ],
      },
      {
        path: routePaths.DOCUMENTS,
        component: Documents,
        page: 'Documents',
        locationChangeSideEffects: [[authSagas.authGuardSaga]],
        routes: [
          {
            path: routePaths.DOCUMENTS_DOCUMENT_EXPLORER,
            exact: true,
            component: DocumentExplorer,
            page: 'Document Explorer',
            locationChangeSideEffects: [
              [authSagas.authGuardSaga],
              [documentExplorerSaga],
            ],
          },
          {
            path: routePaths.DOCUMENTS_TRANSACTION_PIVOT,
            exact: true,
            component: null,
            page: 'Transaction Pivot',
            locationChangeSideEffects: [[authSagas.authGuardSaga]],
          },
        ],
      },
      {
        path: routePaths.REPORTING,
        exact: true,
        component: Reporting,
        page: 'Company Admin Reporting',
        locationChangeSideEffects: [
          [authSagas.authGuardSaga],
          [companyAdminReportingSaga],
        ],
      },
      {
        path: routePaths.NOT_FOUND,
        exact: true,
        component: NotFoundView,
        locationChangeSideEffects: [],
      },
      {
        path: '*',
        component: NotFoundView,
        page: 'Unavailable Page',
      },
    ],
  },
];

export default routes;
