import React, {ReactNode} from 'react';
import {Func, MultiSelectOption, StyleVariant} from '../../../types';
import styles from './MultiSelectDropdownOptions.module.scss';
import classnames from 'classnames';
import {Checkbox} from '../index';

interface MultiSelectDropdownOptionProps extends MultiSelectOption {
  checked: boolean;
  checkboxVariant?: StyleVariant;
  disabled?: boolean;
  handleChange: Func<[string, boolean], void>;
  optionChildren?: ReactNode | Func<[MultiSelectOption], ReactNode>;
}
const MultiSelectDropdownOption: React.FC<MultiSelectDropdownOptionProps> = ({
  handleChange,
  optionChildren,
  checkboxVariant,
  disabled,
  ...option
}) => {
  const {visible, checked, label, value} = option;
  return (
    <div
      className={classnames(
        styles.option,
        visible === false && styles.notVisible,
        disabled && styles.disabled
      )}
    >
      <Checkbox
        className={styles.checkbox}
        disabled={disabled}
        label={`${label}`}
        checked={checked}
        variant={checkboxVariant}
        onChange={event => handleChange(value as string, event.target.checked)}
      >
        {optionChildren
          ? typeof optionChildren === 'function'
            ? optionChildren(option)
            : optionChildren
          : null}
      </Checkbox>
    </div>
  );
};

export default MultiSelectDropdownOption;
