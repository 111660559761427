import {createSelector} from '@reduxjs/toolkit';
import {RootState} from '../store';

const companyDetails = (state: RootState) => state.companyDetails;

const companyDetailsSelector = createSelector(
  [companyDetails],
  companyDetails => {
    const data =
      companyDetails.company?.data ||
      companyDetails.companyInvestmentOverview?.data ||
      companyDetails.companyMonitoring?.data;

    const {companyName, companyIcon, asAt, id, fundColor, status} = data || {};

    return {
      fundColor,
      companyName,
      companyIcon,
      asAt,
      id,
      status,
    };
  }
);

export default companyDetailsSelector;
