import React from 'react';
import styles from './CommonTags.module.scss';
import {CommonTagsProps, StyleVariant} from '../../../../../types';
import {Tag} from '../../../..';

const CommonTags: React.FC<CommonTagsProps> = ({title, tags}) => {
  return (
    <div className={styles.wrapper}>
      <p className={styles.title}>{title}</p>
      <div className={styles.list}>
        {tags.map((tag, idx) => (
          <Tag key={idx} {...tag} variant={StyleVariant.Secondary} />
        ))}
      </div>
    </div>
  );
};

export default CommonTags;
