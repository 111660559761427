import React from 'react';
import {NavLink, useLocation} from 'react-router-dom';
import {BaseCompanyData, Size} from '../../../../../types/index';
import {EntityLogo, Icon, ValueFormat} from '../../../../global';
import {valueFormat} from '../../../../../utils/value-format';
import routePaths from '../../../../../routePaths';
import homeLayoutStyles from './BaseInfo.module.scss';
import pageLayoutStyles from './BaseInfoTemp.module.scss';
import {getInstrumentIconTooltip} from '../../../../../utils/getInstrumentIconTooltip';
import {useTranslation} from 'react-i18next';

interface BaseInfoProps {
  data: BaseCompanyData;
  rightTopComponent?: JSX.Element | null;
  instrumentTypes?: Array<string> | null;
}

const BaseInfo: React.FC<BaseInfoProps> = ({
  data: {
    id,
    fundColor,
    fundName,
    companyName,
    companyIcon,
    status,
    holdingPeriod,
    isDataCurrent = true,
    asAt,
  },
  rightTopComponent,
  instrumentTypes,
}) => {
  const {t} = useTranslation();
  const {pathname} = useLocation();
  const styles = pathname === '/' ? homeLayoutStyles : pageLayoutStyles;

  return (
    <div className={styles.top}>
      <div className={styles.topRow}>
        <div className={styles.topLeft}>
          <EntityLogo
            size={Size.Small}
            name={companyName}
            url={companyIcon ?? null}
            color={fundColor!}
            className={styles.logo}
          />
          <NavLink
            // TODO: for v1 redirect to investment-overview
            to={`${routePaths.PORTFOLIO_COMPANIES}/${id}/investment-overview`}
            data-test="company-widget-title"
          >
            <div className={styles.companyName}>{companyName}</div>
          </NavLink>
          <div className={styles.fundInfo} data-test="company-widget-fund-name">
            {fundName}
          </div>
          <div className={styles.fundInfo}>
            {holdingPeriod?.label}:{' '}
            <span className={styles.smallText}>
              <ValueFormat
                value={holdingPeriod?.value}
                format={holdingPeriod?.format}
              />
            </span>
          </div>
        </div>
        {rightTopComponent && (
          <div className={styles.topRight}>{rightTopComponent}</div>
        )}
      </div>
      <div className={styles.topRow}>
        <div className={styles.topLeft}>
          {!!instrumentTypes && (
            <div className={styles.specIcons}>
              {instrumentTypes?.map((item, idx) => (
                <Icon
                  tooltip={getInstrumentIconTooltip(item)}
                  className={styles.specIconsItem}
                  key={idx}
                  name={item}
                />
              ))}
            </div>
          )}
        </div>
      </div>
      {!isDataCurrent && (
        <div className={styles.asAtWrapper}>
          <div className={styles.asAt}>
            <Icon className={styles.asAtIcon} name={'warning'} />
            <div>
              {t('Global.DataBelow')}{' '}
              {valueFormat(asAt.value, asAt.format).value}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BaseInfo;
