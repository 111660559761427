import {SankeyData} from '../types';

const hex2rgba = (hex: string, alpha = 1) => {
  const [r, g, b] = hex.match(/\w\w/g)!.map(x => parseInt(x, 16));
  return `rgba(${r},${g},${b},${alpha})`;
};

// Just as a temp solution
const getOpacityStep = (num: number) => {
  if (num < 10) return 0.1;
  if (num < 20) return 0.05;
  if (num < 30) return 0.03;
  if (num < 40) return 0.02;
  return 0.01;
};

export const updateSankeyData = (data: SankeyData): SankeyData => {
  let opacity = 1;
  const opacityStep = getOpacityStep(data.links.length);

  const updatedData = {
    ...data,
    links: [
      ...data.links
        .slice()
        .sort((a, b) => b.value - a.value)
        .map(item => {
          opacity = opacity >= 0.2 ? opacity - opacityStep : 0.15;
          return {
            ...item,
            colorVariant: hex2rgba(
              data.color || '#FF14D9',
              +opacity.toFixed(2)
            ),
          };
        }),
    ],
  };
  return updatedData;
};
