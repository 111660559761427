import React from 'react';
import classnames from 'classnames';
import {Link} from 'react-router-dom';
import styles from './NavLogo.module.scss';
import LanternPrimaryLogoWhite from '../../global/logos/LanternPrimaryLogoWhite';

interface NavLogoProps {
  className?: string;
  logoGradientId: string;
  linkTo: string;
}

const NavLogo: React.FC<NavLogoProps> = ({
  className,
  logoGradientId,
  linkTo,
}): JSX.Element => {
  return (
    <div className={classnames(styles.wrapper, className)}>
      <Link to={linkTo} className={styles.link}>
        <LanternPrimaryLogoWhite
          gradientId={logoGradientId}
          className={styles.logo}
        />
      </Link>
    </div>
  );
};

export default NavLogo;
