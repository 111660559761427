import React, {useMemo} from 'react';
import classnames from 'classnames';
import styles from './ReportingTableTextDataCell.module.scss';
import {
  QuestionnaireRowOptions,
  ReportingTableCell,
  SelectOption,
} from '../../../../../types';
import {valueFormat} from '../../../../../utils';

type ReportingTableTextDataCellProps = {
  cell: ReportingTableCell;
  questionnaireOptions?: QuestionnaireRowOptions;
  classNames?: string;
};

const ReportingTableTextDataCell = ({
  cell,
  questionnaireOptions,
  classNames,
}: ReportingTableTextDataCellProps) => {
  const multiSelectValue = useMemo<string>((): string => {
    return questionnaireOptions?.answers
      ? questionnaireOptions.answers
          .filter((answer: SelectOption) =>
            Array.isArray(cell.data?.value)
              ? cell.data?.value.includes(answer.value)
              : cell.data?.value === answer.value
          )
          .reduce(
            (
              acc: string,
              curr: SelectOption,
              idx: number,
              src: Array<SelectOption>
            ): string => {
              // Handle semicolon addition for the first option
              if (!acc) return `${curr.label}${src.length > 1 ? ';' : ''}`;

              // Handle semicolon addition for the last option
              if (idx === src.length - 1) return `${acc} ${curr.label}`;

              // Handle semicolon addition for the options which are between the first and the last one
              return `${acc} ${curr.label};`;
            },
            ''
          )
      : '';
  }, [cell, questionnaireOptions]);

  const singleSelectValue = useMemo<string>(
    (): string =>
      questionnaireOptions?.answers
        ? questionnaireOptions.answers.find(
            (answer: SelectOption): boolean => answer.value === cell.data?.value
          )?.label ?? ''
        : '',
    [cell, questionnaireOptions]
  );

  const numberValue = useMemo<string>(() => {
    return cell?.data?.value
      ? `${valueFormat(cell.data.value, 'en-GB').value}`
      : '';
  }, [cell]);

  const percentageValue = useMemo<string>(() => {
    return cell?.data?.value ? `${cell.data.value}%` : '';
  }, [cell]);

  return (
    <td
      className={classnames(styles.cell, {
        [`${classNames}`]: !!classNames,
        [styles.notVisible]: !cell.isVisible,
      })}
    >
      <div
        className={classnames(styles.innerWrapper, {
          [styles.fakeColumnCell]: !cell.data,
        })}
      >
        {cell.data?.type === 'MultiSelect' ? (
          <p className={styles.cellText}>{multiSelectValue}</p>
        ) : cell.data?.type === 'SingleSelect' ? (
          <p className={styles.cellText}>{singleSelectValue}</p>
        ) : cell.data?.type === 'Number' ? (
          <p className={styles.cellText}>{numberValue}</p>
        ) : cell.data?.type === 'Percentage' ? (
          <p className={styles.cellText}>{percentageValue}</p>
        ) : (
          <p className={styles.cellText}>{cell.data?.value}</p>
        )}
      </div>
    </td>
  );
};

export default ReportingTableTextDataCell;
