import React, {useState, useMemo, useEffect} from 'react';
import styles from './EntityDetailsList.module.scss';
import ValueFormat from '../value-format/ValueFormat';
import {Icon} from '../..';
import {EntityDetailsListProps} from '../../../types';
import classNames from 'classnames';

const EntityDetailsList: React.FC<EntityDetailsListProps> = ({
  title,
  paginationTitle,
  entityData,
  disabled,
}) => {
  const [selectedEntityIndex, setSelectedEntityIndex] = useState(0);

  const selectedEntity = useMemo(
    () => entityData[selectedEntityIndex],
    [entityData, selectedEntityIndex]
  );

  const handleLeftArrowClick = () => {
    setSelectedEntityIndex(prevIndex => (prevIndex > 0 ? prevIndex - 1 : 0));
  };

  const handleRightArrowClick = () => {
    setSelectedEntityIndex(prevIndex =>
      prevIndex < entityData.length - 1 ? prevIndex + 1 : entityData.length - 1
    );
  };

  useEffect(() => {
    setSelectedEntityIndex(0);
  }, [entityData]);

  return (
    <>
      <div className={styles.header}>
        <p
          className={classNames(styles.groupTitle, disabled && styles.disabled)}
        >
          {title}
        </p>
        {entityData.length > 1 && (
          <div className={styles.quantityGroup}>
            <p className={styles.quantityTitle}>
              {`${entityData.length} ${paginationTitle}`}
            </p>
            <div className={styles.icons}>
              <Icon
                onClick={handleLeftArrowClick}
                name={'chevron-left'}
                className={selectedEntityIndex === 0 ? styles.disabled : ''}
              />
              <Icon
                onClick={handleRightArrowClick}
                name={'chevron-right'}
                className={
                  selectedEntityIndex === entityData.length - 1
                    ? styles.disabled
                    : ''
                }
              />
            </div>
          </div>
        )}
      </div>
      {selectedEntity && (
        <div className={styles.entityDetailsGroup}>
          {selectedEntity.map(detail => (
            <div className={styles.entityDetails} key={detail.label}>
              <p className={styles.entityDetailsLabel}>{detail.label}</p>
              {typeof detail.value === 'object' ? (
                <ValueFormat
                  value={detail.value.value}
                  format={detail.value.format}
                  currency={detail.value.currency}
                />
              ) : (
                <p className={styles.entityDetailsValue}>{detail.value}</p>
              )}
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default EntityDetailsList;
