import React from 'react';
import {Trans} from 'react-i18next';

const CustomTagsTooltipContent: React.FC<{className: string}> = ({
  className,
}) => {
  return (
    <div className={className}>
      <Trans
        i18nKey={'UploadFileForm.SystemTagsTooltipContent.Custom'}
        components={[<b>Custom tags</b>]}
      />
    </div>
  );
};

export default CustomTagsTooltipContent;
