import React, {CSSProperties, useRef} from 'react';
import styles from './TextTruncation.module.scss';
import classnames from 'classnames';
import {Position} from '../../../context/TooltipContext';
import {useTooltip} from '../../../hooks';

interface TextTruncationProps {
  text: string;
  className?: string;
  style?: CSSProperties;
  tooltipClassname?: string;
}

const TextTruncation: React.FC<TextTruncationProps> = ({
  text,
  className,
  style,
  tooltipClassname,
}) => {
  const {showTooltip, hideTooltip} = useTooltip();
  const textRef = useRef<HTMLDivElement>(null);

  const handleShowTooltip = () => {
    const textElement = textRef.current as HTMLElement;

    if (!textRef.current) return;
    if (textElement?.scrollWidth > textElement?.offsetWidth) {
      showTooltip({
        content: text,
        target: textRef.current,
        position: Position.Top,
        className: classnames(styles.tooltip, tooltipClassname),
      });
    }
  };

  return (
    <div style={style} className={classnames(styles.wrapper, className)}>
      <p
        className={styles.truncated}
        ref={textRef}
        onMouseEnter={handleShowTooltip}
        onMouseLeave={hideTooltip}
      >
        {text}
      </p>
    </div>
  );
};

export default TextTruncation;
