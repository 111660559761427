export const genPortfolioLegendActions = (
  isConsolidated: boolean | undefined
) => {
  return [
    {
      id: 'current-cost',
      label: 'Global.CurrentCost',
      value: 'total-cost',
      color: `rgb(var(--colors-gray-4))`,
    },
    {
      id: 'total-value',
      label: 'Global.TotalValue',
      value: 'total-value',
      color: `rgb(var(--colors-gray-4))`,
    },
    {
      id: 'roi-irr',
      label: isConsolidated ? 'Global.ROI' : 'Global.ROI/IRR',
      value: 'roi-irr',
      color: `rgb(var(--colors-gray-4))`,
    },
  ];
};
