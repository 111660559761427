import {ButtonStyle, StyleVariant} from '../../../../../types';
import styles from './ReportingTableColumnSubmissionConfirmationDialog.module.scss';
import {Button, Icon, Modal} from '../../../index';
import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import classnames from 'classnames';

type ReportingTableColumnSubmissionConfirmationDialogProps = {
  isOpen: boolean;
  onClose: Function;
  onCancel: Function;
  onSubmit: Function;
  message: string;
  isLoading: boolean;
  actionType: 'submit' | 'update' | 'cancel';
};

const ReportingTableColumnSubmissionConfirmationDialog = ({
  isOpen,
  onClose,
  onCancel,
  onSubmit,
  message,
  isLoading,
  actionType,
}: ReportingTableColumnSubmissionConfirmationDialogProps) => {
  const {t} = useTranslation();

  const iconName = useMemo(() => {
    if (actionType === 'submit') return 'tasks';
    if (actionType === 'update') return 'update-document';
    if (actionType === 'cancel') return 'warning';
    return '';
  }, [actionType]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => onClose()}
      variant={StyleVariant.Primary}
      footerChildren={
        <div className={styles.footerWrapper}>
          <Button
            styleType={ButtonStyle.Primary}
            text={t('Global.Submit')}
            loading={isLoading}
            onClick={() => onSubmit()}
            className={styles.submitButton}
          />
          <Button
            styleType={ButtonStyle.Secondary}
            text={t('Global.Cancel')}
            onClick={() => {
              onCancel();
            }}
            className={styles.cancelButton}
          />
        </div>
      }
    >
      <div className={styles.childrenWrapper}>
        <div
          className={classnames(
            styles.iconWrapper,
            styles[`iconWrapper__${actionType}`]
          )}
        >
          <Icon className={styles.icon} name={iconName} />
        </div>
        <div
          className={styles.text}
          dangerouslySetInnerHTML={{__html: message}}
        />
      </div>
    </Modal>
  );
};

export default ReportingTableColumnSubmissionConfirmationDialog;
