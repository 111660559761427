import React, {useMemo, useRef, useState} from 'react';
import {Button, CustomizeOrderPanel, Modal} from '../../global';
import {
  ButtonStyle,
  StyleVariant,
  TemplateConfigDetailsCustomGroup,
  TemplateConfigDetailsCustomGroupItem,
  TemplateGroupsOrderOption,
  TemplateGroupsReorderingPanelProps,
} from '../../../types';
import styles from './TemplateGroupsReorderingPanel.module.scss';
import {useTranslation} from 'react-i18next';

const TemplateGroupsReorderingPanel: React.FC<
  TemplateGroupsReorderingPanelProps
> = ({id, data, onClose, onSave, isOpen, onChange, inProgress, error}) => {
  const {t} = useTranslation();
  const options: TemplateGroupsOrderOption[] = useMemo(
    () =>
      data.map(item => ({
        ...item,
        label: item.name,
        id: item.id,
        groupLabel: 'Metric',
        subItems: item.data.map(item => ({
          ...item,
          label: item.reportingName,
          id: item.id,
        })),
      })),
    [data]
  );
  const originState =
    useRef<
      Array<
        TemplateConfigDetailsCustomGroup<TemplateConfigDetailsCustomGroupItem>
      >
    >(data);

  const [isChanged, setIsChanged] = useState(false);

  const handleChange = (state: TemplateGroupsOrderOption[]) => {
    if (!isChanged) {
      setIsChanged(true);
    }
    onChange(
      state.map(({label, subItems, groupLabel, ...rest}) => ({
        ...rest,
        data: subItems.map(({label, ...restSubItem}) => ({...restSubItem})),
      }))
    );
  };

  const handleCancel = () => {
    if (inProgress) return;
    onChange(originState.current);
    onClose();
  };

  return (
    <Modal
      title={t('Global.CustomizeOrder')}
      isOpen={isOpen}
      onClose={handleCancel}
      variant={StyleVariant.Secondary}
      footerChildren={
        <div className={styles.buttons}>
          <Button
            text={t('Global.Save')}
            loading={inProgress}
            onClick={onSave}
            disabled={!isChanged}
          />
          <Button
            styleType={ButtonStyle.Secondary}
            text={t('Global.Cancel')}
            onClick={handleCancel}
            disabled={inProgress}
          />
        </div>
      }
    >
      {error && <div className={styles.error}>{error}</div>}
      <CustomizeOrderPanel
        id={`${id}-customize`}
        className={styles.panel}
        withoutActionButtons={true}
        label={''}
        countLabel={t(options.length > 1 ? 'Global.Groups' : 'Global.Group', {
          count: options.length,
        })}
        options={options}
        onChange={state => handleChange(state as TemplateGroupsOrderOption[])}
        onSave={onSave}
        onCancel={handleCancel}
      />
    </Modal>
  );
};

export default TemplateGroupsReorderingPanel;
