import React from 'react';
import {Icon} from '../../../';
import styles from './InvestmentTypeCell.module.scss';
import {getInstrumentIconTooltip} from '../../../../../utils/getInstrumentIconTooltip';

type InvestmentsTypeCellProps = {
  icons?: string[];
};

const InvestmentTypeCell = ({icons}: InvestmentsTypeCellProps): JSX.Element => (
  <div className={styles.wrapper}>
    {icons?.map((iconName, i) => (
      <Icon
        tooltip={getInstrumentIconTooltip(iconName)}
        key={i}
        name={iconName}
        className={styles.icon}
      />
    ))}
  </div>
);

export default InvestmentTypeCell;
