import routePaths from '../routePaths';
import {BreadCrumbRoutes, BreadCrumbsNearCastingInfo} from '../types/index';

export const getBreadcrumbs = (
  pathname: string,
  nearCastingInfo: BreadCrumbsNearCastingInfo
): BreadCrumbRoutes[] => {
  if (pathname === '/') {
    return [];
  }
  if (pathname.includes('/scenarios/funds/') && !!nearCastingInfo) {
    const scenarioBreadCrumbs = [
      {
        path: routePaths.HOME,
        name: 'NavigationMenu.Home',
        isActive: false,
      },
      {
        path: routePaths.FUNDS,
        name: 'NavigationMenu.Funds',
        isActive: false,
      },
      {
        path: routePaths.FUND_OVERVIEW.replace(':id', nearCastingInfo.fundId),
        name: nearCastingInfo.fundName,
        isActive: false,
      },
      {
        path: routePaths.FUND_NEAR_CASTING.replace(
          ':id',
          nearCastingInfo.fundId
        ),
        name: 'Predict',
        isActive: false,
      },
    ];

    if (pathname.includes('/report')) {
      return [
        ...scenarioBreadCrumbs,
        {
          path: routePaths.SCENARIO_REPORT,
          name: 'Global.Report',
          isActive: true,
        },
      ];
    }
    if (pathname.includes('/create')) {
      return [
        ...scenarioBreadCrumbs,
        {
          path: routePaths.SCENARIO_MAIN,
          name: 'New Scenario',
          isActive: true,
        },
      ];
    }
    if (pathname.includes('/valuation') || pathname.includes('/transactions')) {
      const path = pathname.includes('/valuation')
        ? routePaths.SCENARIO_VALUATION
        : routePaths.SCENARIO_TRANSACTION;
      return [
        ...scenarioBreadCrumbs,
        {
          path,
          name: nearCastingInfo.scenarioName,
          isActive: true,
        },
      ];
    }
    if (pathname.includes('/main')) {
      return [
        ...scenarioBreadCrumbs,
        {
          path: routePaths.SCENARIO_MAIN,
          name: nearCastingInfo.scenarioName,
          isActive: true,
        },
      ];
    }
    return [];
  }
  if (pathname.includes('/funds/')) {
    return [
      {
        path: routePaths.HOME,
        name: 'NavigationMenu.Home',
        isActive: false,
      },
      {
        path: routePaths.FUNDS,
        name: 'NavigationMenu.Funds',
        isActive: false,
      },
    ];
  }
  if (pathname.includes('/funds')) {
    return [
      {
        path: routePaths.HOME,
        name: 'NavigationMenu.Home',
        isActive: false,
      },
      {
        path: routePaths.FUNDS,
        name: 'NavigationMenu.Funds',
        isActive: true,
      },
    ];
  }
  if (pathname.includes('/portfolio/')) {
    return [
      {
        path: routePaths.HOME,
        name: 'NavigationMenu.Home',
        isActive: false,
      },
      {
        path: routePaths.PORTFOLIO_COMPANIES,
        name: 'Global.Portfolio',
        isActive: false,
      },
    ];
  }
  if (
    pathname.includes('/portfolio') &&
    !pathname.includes('/data-collection/')
  ) {
    return [
      {
        path: routePaths.HOME,
        name: 'NavigationMenu.Home',
        isActive: false,
      },
      {
        path: routePaths.PORTFOLIO_COMPANIES,
        name: 'Global.Portfolio',
        isActive: true,
      },
    ];
  }
  if (pathname.includes('/admin/user')) {
    return [
      {
        path: routePaths.HOME,
        name: 'NavigationMenu.Home',
        isActive: false,
      },
      {
        path: routePaths.ADMIN_USER_USERS,
        name: 'Global.UserAdministration',
        isActive: true,
      },
    ];
  }
  if (pathname.includes('/admin/data-collection')) {
    return [
      {
        path: routePaths.HOME,
        name: 'NavigationMenu.Home',
        isActive: false,
      },
      {
        path: routePaths.ADMIN_DATA_COLLECTION_TEMPLATE_CONFIGURATION,
        name: 'DataCollectionAdministration.Label',
        isActive: true,
      },
    ];
  }
  if (pathname.includes('/data-collection')) {
    return [
      {
        path: routePaths.HOME,
        name: 'NavigationMenu.Home',
        isActive: false,
      },
      {
        path: routePaths.DATA_COLLECTION,
        name: 'Breadcrumbs.DataCollection',
        isActive: true,
      },
    ];
  }
  if (pathname.includes('/documents/')) {
    return [
      {
        path: routePaths.HOME,
        name: 'NavigationMenu.Home',
        isActive: false,
      },
      {
        path: routePaths.DOCUMENTS_DOCUMENT_EXPLORER,
        name: 'Global.Documents',
        isActive: true,
      },
    ];
  }
  return [];
};
