import React, {RefObject, useEffect} from 'react';
import {IMask, useIMask} from 'react-imask';
import styles from './MaskedDateInput.module.scss';
import {
  Func,
  MaskedDateInputMask,
  MaskedDateInputPattern,
} from '../../../../types';
import classnames from 'classnames';
import {
  formatWithLocale,
  getLocalisedDateMaskPattern,
  parseWithLocale,
} from '../../../../utils';
import {useTranslation} from 'react-i18next';

type MaskedDateInputProps = {
  type?: string;
  label: string;
  initialValue?: {
    from: string;
    to: string;
  };
  pattern: {
    mask: MaskedDateInputMask;
    date: MaskedDateInputPattern;
  };
  onAccept: Func<[string, RefObject<HTMLInputElement>], void>; // onAccept: Func<[string, InputMask<any>?, (InputEvent | undefined)?], void>;
  invalid?: boolean;
};

const MaskedDateInput = ({
  label,
  initialValue,
  pattern,
  onAccept,
  invalid = false,
}: MaskedDateInputProps) => {
  const {t} = useTranslation();
  // IMask options
  const opts = {
    mask: Date,
    pattern: getLocalisedDateMaskPattern(pattern.mask),
    maxLength: 10,
    autofix: false,
    lazy: false,
    format: function (date: any) {
      return date instanceof Date ? formatWithLocale(date, pattern.date) : '';
    },
    parse: function (dateStr: string) {
      return parseWithLocale(dateStr, pattern.date);
    },
    blocks: {
      dd: {
        mask: IMask.MaskedRange,
        from: 1,
        to: 31,
        placeholderChar: 'D',
        maxLength: 2,
      },
      MM: {
        mask: IMask.MaskedRange,
        from: 1,
        to: 12,
        placeholderChar: 'M',
        maxLength: 2,
      },
      yyyy: {
        mask: IMask.MaskedRange,
        from: 1970,
        to: 2030,
        placeholderChar: 'Y',
        maxLength: 4,
      },
    },
  };

  // @ts-ignore
  const {ref, setValue} = useIMask<HTMLInputElement>(opts, {
    onAccept: (value: string) => {
      onAccept(value, ref);
    },
  });

  const isFromLabel = t('Global.From');

  useEffect(() => {
    setValue(
      label === isFromLabel
        ? initialValue?.from
          ? initialValue.from
          : ''
        : initialValue?.to
        ? initialValue?.to
        : ''
    );
  }, [initialValue]);

  return (
    <div className={styles.dateInputField}>
      <label className={styles.label}>{label}</label>
      <input
        className={classnames(styles.dateInput, {
          [styles.dateInput__invalid]: invalid,
        })}
        type="text"
        defaultValue={
          label === isFromLabel ? initialValue?.from : initialValue?.to
        }
        ref={ref}
      />
    </div>
  );
};

export default MaskedDateInput;
