import React from 'react';
import styles from './WorkflowActivityStatus.module.scss';
import {ActivityStatus} from '../index';
import {ActivityStatusType, WorkflowStatus} from '../../../types';

export interface WorkflowActivityStatusProps {
  status: WorkflowStatus;
  label: string;
}

const WorkflowActivityStatus: React.FC<WorkflowActivityStatusProps> = ({
  status,
  label,
}) => {
  return (
    <div className={styles.wrapper}>
      <ActivityStatus activity={status} type={ActivityStatusType.Workflow} />
      <span>{label}</span>
    </div>
  );
};

export default WorkflowActivityStatus;
