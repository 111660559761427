import React, {useMemo, useState} from 'react';
import {Pie, PieChart, ResponsiveContainer, Tooltip} from 'recharts';
import {
  PortfolioExposureLabelsType,
  PortfolioExposureTableType,
} from '../../../../../types/index';
import {Icon} from '../../../../global';
import styles from './Chart.module.scss';
import ChartTooltip from '../chart-tooltip/ChartTooltip';
import classnames from 'classnames';

interface ChartProps {
  data: PortfolioExposureTableType[];
  labels: PortfolioExposureLabelsType;
  currency?: string;
  isAnimated: boolean;
}

interface ChartDataType {
  label: string;
  timeSeries: {label: string; value: number; fill: string; icon?: string}[];
}

const Chart: React.FC<ChartProps> = ({data, labels, isAnimated}) => {
  const [areLabelsShown, setAreLabelsShown] = useState(false);
  const [activeCategory, setActiveCategory] = useState<string>('');

  const chartData = useMemo(() => {
    return data.reduce(
      (arr: ChartDataType[], cur) => {
        return [
          {
            label: labels.count,
            timeSeries: [
              ...arr[0].timeSeries,
              {
                label: cur.category,
                value: cur.count,
                fill: `rgb(var(--colors-${cur.color}))`,
                icon: cur.icon,
              },
            ],
          },
          {
            label: labels.cost,
            timeSeries: [
              ...arr[1].timeSeries,
              {
                label: cur.category,
                value: cur.cost,
                fill: `rgb(var(--colors-${cur.color}))`,
                icon: cur.icon,
              },
            ],
          },
          {
            label: labels.valuation,
            timeSeries: [
              ...arr[2].timeSeries,
              {
                label: cur.category,
                value: cur.valuation,
                fill: `rgb(var(--colors-${cur.color}))`,
                icon: cur.icon,
              },
            ],
          },
        ];
      },
      [
        {
          label: labels.count,
          timeSeries: [],
        },
        {
          label: labels.cost,
          timeSeries: [],
        },
        {
          label: labels.valuation,
          timeSeries: [],
        },
      ]
    );
  }, [data, labels]);

  const legendArray: {label: string; fill: string; icon: string}[] = useMemo(
    () =>
      data.reduce(
        (arr: {label: string; fill: string; icon: string}[], current) => {
          if (!arr.some(ss => ss.label === current.category)) {
            arr.push({
              label: current.category,
              fill: current.color,
              icon: current.icon || '',
            });
          }
          return arr;
        },
        []
      ),
    [data]
  );

  return (
    <div className={styles.wrapper}>
      <div className={styles.chart}>
        <ResponsiveContainer width="100%" height="100%">
          <PieChart>
            {chartData.map((data, index) => (
              <Pie
                isAnimationActive={isAnimated}
                hide={!isAnimated}
                startAngle={90}
                endAngle={-270}
                key={index}
                data={data.timeSeries}
                dataKey="value"
                outerRadius={`${100 - index * 18}%`}
                innerRadius={`${92 - index * 18}%`}
                legendType="circle"
                stroke="none"
                opacity={1 - index * 0.25}
                style={{outline: 'none'}}
                onAnimationEnd={() => setAreLabelsShown(true)}
                onMouseEnter={data => {
                  setAreLabelsShown(true);
                  setActiveCategory(data.label || '');
                }}
                onMouseLeave={() => setActiveCategory('')}
              />
            ))}
            <Tooltip
              isAnimationActive
              content={() => (
                <ChartTooltip
                  data={data}
                  labels={labels}
                  activeCategory={activeCategory}
                />
              )}
            />
            <g
              opacity={areLabelsShown ? 1 : 0}
              style={{transition: `opacity .2s`}}
            >
              <g
                fill={'rgb(var(--colors-white))'}
                stroke={'rgb(var(--colors-white))'}
              >
                <text fontSize={12} fontWeight={400} x={0} y={`28%`}>
                  {labels.count}
                </text>
                <line
                  x1={0}
                  y1={`30%`}
                  x2={`50%`}
                  y2={`30%`}
                  style={{transform: 'translate(-60px, 0)'}}
                />
                <circle
                  cx={`50%`}
                  cy={`30%`}
                  r={2}
                  style={{transform: 'translate(-60px, 0)'}}
                />
              </g>
              <g
                fill={'rgb(var(--colors-white))'}
                stroke={'rgb(var(--colors-white))'}
                opacity={0.75}
              >
                <text fontSize={12} fontWeight={400} x={0} y={`48%`}>
                  {labels.cost}
                </text>
                <line
                  x1={0}
                  y1={`50%`}
                  x2={`50%`}
                  y2={`50%`}
                  style={{transform: 'translate(-55px, 0)'}}
                />
                <circle
                  cx="50%"
                  cy={`50%`}
                  r={2}
                  style={{transform: 'translate(-55px, 0)'}}
                />
              </g>
              <g
                fill={'rgb(var(--colors-white))'}
                stroke={'rgb(var(--colors-white))'}
                opacity={0.5}
              >
                <text fontSize={12} fontWeight={400} x={0} y={`68%`}>
                  {labels.valuation}
                </text>
                <line
                  x1={0}
                  y1={`70%`}
                  x2={'50%'}
                  y2={`70%`}
                  style={{transform: 'translate(-30px, 0)'}}
                />
                <circle
                  cx="50%"
                  cy={`70%`}
                  r={2}
                  style={{transform: 'translate(-30px, 0)'}}
                />
              </g>
            </g>
          </PieChart>
        </ResponsiveContainer>
      </div>
      <ul className={styles.legend}>
        {legendArray.map((entry, index) => (
          <li
            className={classnames(
              styles.legendItem,
              activeCategory === entry.label && styles.active,
              activeCategory &&
                activeCategory !== entry.label &&
                styles.inActive
            )}
            key={`item-${index}`}
          >
            <div
              className={styles.legendMarker}
              style={{backgroundColor: `rgb(var(--colors-${entry.fill}))`}}
            />
            {entry.label}
            {entry.icon && <Icon className={styles.icon} name={entry.icon} />}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Chart;
