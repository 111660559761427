import React from 'react';
import styles from './FileUploadProgressPopup.module.scss';
import {ButtonSize, FileUploadStatus} from '../../../types';
import {Trans} from 'react-i18next';
import {Icon, IconButton, UploadStatus} from '../index';
import classnames from 'classnames';
import {getDocumentIconByType, getUploadIsFinished} from '../../../utils';
import {useAppDispatch, useAppSelector} from '../../../hooks/useReduxHooks';
import {
  setFileUploadData,
  setFileUploadShowNotification,
  setFileUploadIsNotificationMinimized,
  fileAlreadyExistsAction,
  setFileAlreadyExists,
} from '../../../reducers/fileUploadSlice';
import {documentExplorerCancelUploadFileAction} from '../../../reducers/documentExplorerSlice';
import FileAlreadyExistsModal from '../modals/file-already-exists-modal/FileAlreadyExistsModal';
import {t} from 'i18next';

const FileUploadProgressPopup: React.FC = () => {
  const {data, showNotification, isNotificationMinimized, fileAlreadyExists} =
    useAppSelector(state => state.fileUpload);

  const dispatch = useAppDispatch();

  const handleClose = () => {
    const uploadsAreFinished = getUploadIsFinished(data ?? []);

    if (uploadsAreFinished) dispatch(setFileUploadData(null));
    dispatch(setFileUploadShowNotification(false));
  };

  const handleCancelFileUpload = () => {
    dispatch(documentExplorerCancelUploadFileAction());
  };

  const handleToggleMinimize = () => {
    dispatch(setFileUploadIsNotificationMinimized(!isNotificationMinimized));
  };

  const handleFileAlreadyExistConfirm = (option: string) => {
    dispatch(fileAlreadyExistsAction(option));
    dispatch(setFileAlreadyExists(null));
  };

  const handleFileAlreadyExistClose = () => {
    dispatch(fileAlreadyExistsAction(null));
    dispatch(setFileAlreadyExists(null));
  };

  return (
    <div
      className={classnames(styles.wrapper, showNotification && styles.active)}
    >
      {showNotification && (
        <div className={styles.top}>
          <div className={styles.title}>
            {t('FileUploadProgressPopup.Title')}
          </div>
          <div className={styles.buttons}>
            <IconButton
              buttonSize={ButtonSize.Small}
              onClick={handleToggleMinimize}
              icon={isNotificationMinimized ? 'chevron-up' : 'chevron-down'}
            />
            <IconButton
              buttonSize={ButtonSize.Small}
              onClick={handleClose}
              icon={'cross'}
            />
          </div>
        </div>
      )}
      <div
        className={classnames(
          styles.content,
          isNotificationMinimized && styles.isMinimized
        )}
      >
        <div className={styles.cancelButtonWrapper}>
          <button
            onClick={handleCancelFileUpload}
            className={styles.cancelButton}
          >
            <Trans i18nKey={'Global.Cancel'} />
          </button>
        </div>

        <div className={styles.filesWrapper}>
          {data?.map((file, idx) => (
            <div
              key={idx}
              className={classnames(
                styles.fileItem,
                (file.status === FileUploadStatus.Pending ||
                  file.status === FileUploadStatus.Success) &&
                  styles.active
              )}
            >
              <Icon
                name={getDocumentIconByType(file.fileType)}
                className={styles.icon}
              />
              <span title={file.fileName} className={styles.fileName}>
                {file.fileName}
              </span>
              <UploadStatus status={file.status} />
            </div>
          ))}
        </div>
      </div>
      <FileAlreadyExistsModal
        title={t('FileUploadProgressPopup.Title')}
        fileName={fileAlreadyExists ?? ''}
        isOpen={!!fileAlreadyExists}
        onConfirm={handleFileAlreadyExistConfirm}
        onClose={handleFileAlreadyExistClose}
      />
    </div>
  );
};

export default FileUploadProgressPopup;
