import {useAppSelector} from './useReduxHooks';
import {AsAt} from '../types';
import {useMemo} from 'react';

const useCurrentAsAt = () => {
  const fundDetails = useAppSelector(state => state.fundDetails);
  const page = useAppSelector(state => state.page);

  const currentAsAt: AsAt | undefined = useMemo(() => {
    if (page.name === 'Fund Overview')
      return fundDetails.fundOverview.data?.asAt;
    if (page.name === 'Fund Operational')
      return fundDetails.fundOperational.data?.asAt;
    if (page.name === 'Fund Structure')
      return fundDetails.fundStructure.data?.asAt;
    if (page.name === 'Fund Portfolio')
      return fundDetails.fundPortfolio.data?.asAt;
    if (page.name === 'Fund Portfolio Transactions')
      return fundDetails.fundPortfolioTransactions.data?.asAt;
    if (page.name === 'Fund Near Casting')
      return fundDetails.fundNearCasting.data?.asAt;
  }, [page, fundDetails]);

  return currentAsAt;
};

export default useCurrentAsAt;
