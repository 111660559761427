import axios from 'axios';
import config from '../config';
import {CompanyInvestmentOverview, TransactionsData} from '../types/index';

export const getCompanyDetailsInvestmentOverview = async (
  token: string,
  id: string
) => {
  const {data} = await axios.get<CompanyInvestmentOverview>(
    `${config.APP_URI}/portfolio-company-investment-overviews/${id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );
  return data;
};

export const getInvestmentOverviewTransactionsById = async (
  token: string,
  portfolioCompanyId: string,
  fundId?: string
) => {
  const {data} = await axios.get<TransactionsData>(
    `${
      config.APP_URI
    }/portfolio-company-investment-overviews/transactions/${portfolioCompanyId}${
      fundId ? `?fundId=${fundId}` : ''
    }`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );
  return data;
};
