import React, {Fragment, useEffect, useState} from 'react';
import FundAreaChart from './components/fund-area-chart/FundAreaChart';
import FundBarChart from './components/fund-bar-chart/FundBarChart';
import FundOnboarding from './components/fund-onboarding/FundOnboarding';
import FundWidgetHeader from './components/fund-widget-header/FundWidgetHeader';
import {FundSummaryWidgetProps, KpiTabs} from '../../../types/index';
import styles from './FundSummaryWidget.module.scss';

const FundSummaryWidget: React.FC<FundSummaryWidgetProps> = props => {
  const {
    nav,
    netIrr,
    dpiRvpiTvpi,
    activeTab: activeTabGroup,
    clickHandler,
    fundOnboardingLabel,
    signOffDate,
  } = props;

  const [activeTab, setActiveTab] = useState(KpiTabs.Nav);
  const isFundOnboarding = !nav && !netIrr && !dpiRvpiTvpi;

  const handleSetActiveTab = (id: KpiTabs) => {
    setActiveTab(id);
    if (clickHandler) {
      clickHandler(id);
    }
  };

  useEffect(() => {
    if (activeTabGroup) {
      setActiveTab(activeTabGroup);
    }
  }, [activeTabGroup]);

  return (
    <div className={styles.wrapper} data-test="fund-summary-widget">
      <FundWidgetHeader
        {...props}
        clickHandler={handleSetActiveTab}
        activeTab={activeTab}
        isFundOnboarding={isFundOnboarding}
      />
      {isFundOnboarding ? (
        <FundOnboarding label={fundOnboardingLabel} />
      ) : (
        <Fragment>
          {activeTab === KpiTabs.Nav && (
            <FundAreaChart
              {...nav}
              signOffDate={signOffDate}
              isNearCastingEmpty
            />
          )}
          {activeTab === KpiTabs.NetIrr && (
            <FundAreaChart
              {...netIrr}
              signOffDate={signOffDate}
              isNearCastingEmpty
            />
          )}
          {activeTab === KpiTabs.DpiRvpi && (
            <FundBarChart signOffDate={signOffDate} {...dpiRvpiTvpi} />
          )}
        </Fragment>
      )}
    </div>
  );
};

export default FundSummaryWidget;
