import React from 'react';
import classnames from 'classnames';
import styles from './ReportingTableHistoricalReportDateCell.module.scss';
import {ReportingTableCell} from '../../../../../types/reportingTable';
import {valueFormat} from '../../../../../utils/value-format';

type ReportingTableHistoricalReportDateCellProps = {
  cell: ReportingTableCell;
  classNames: string;
};

const ReportingTableHistoricalReportDateCell = ({
  cell,
  classNames,
}: ReportingTableHistoricalReportDateCellProps) => {
  return (
    <td
      className={classnames(styles.cell, {
        [`${classNames}`]: !!classNames,
        [styles.notVisible]: !cell.isVisible,
      })}
    >
      <div className={styles.cellInnerWrapper}>
        {cell?.data?.value && (
          <span>{valueFormat(cell.data.value, cell.data.format).value}</span>
        )}
      </div>
    </td>
  );
};

export default ReportingTableHistoricalReportDateCell;
