import React from 'react';
import classnames from 'classnames';
import styles from './ReportingTableStatusUpdateDateCell.module.scss';
import {ReportingTableCell} from '../../../../../types/reportingTable';
import {valueFormat} from '../../../../../utils/value-format';
import {useTranslation} from 'react-i18next';
import {Icon} from '../../..';

type ReportingTableStatusUpdateDateCellProps = {
  cell: ReportingTableCell;
  textAlign?: 'left' | 'center' | 'right';
};

const ReportingTableStatusUpdateDateCell = ({
  cell,
  textAlign = 'right',
}: ReportingTableStatusUpdateDateCellProps) => {
  const {t} = useTranslation();
  return (
    <td
      headers="blank"
      style={{textAlign}}
      className={classnames(styles.cell, {
        [styles.notVisible]: !cell.isVisible,
      })}
    >
      <div
        className={classnames(styles.cellInnerWrapper, {
          [styles.fakeColumn]: !cell?.data,
        })}
      >
        {cell?.data?.value && (
          <div className={styles.submissionDateWrapper}>
            <div className={styles.dateLabel}>
              <Icon className={styles.icon} name={'exclamation-circle'} />
              {t('Last updated')}
            </div>
            <div>{valueFormat(cell?.data?.value, cell.data.format).value}</div>
          </div>
        )}
      </div>
    </td>
  );
};

export default ReportingTableStatusUpdateDateCell;
