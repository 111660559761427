import React, {useMemo} from 'react';
import classnames from 'classnames';
import styles from './ReportingTableStatusSubmissionDateCell.module.scss';
import {
  ReportingTableCell,
  ReportingTableColumnStatusLabel,
} from '../../../../../types/reportingTable';
import {valueFormat} from '../../../../../utils/value-format';
import {ActivityStatus} from '../../../index';
import {ActivityStatuses, StyleVariant} from '../../../../../types';

type ReportingTableStatusDateCellProps = {
  cell: ReportingTableCell;
  readOnly: boolean;
  textAlign?: 'left' | 'center' | 'right';
};

const ReportingTableStatusSubmissionDateCell = ({
  cell,
  readOnly,
  textAlign = 'right',
}: ReportingTableStatusDateCellProps) => {
  const submissionData = useMemo(() => {
    const statusValue = cell?.data?.value;

    if (!statusValue)
      return {
        status: '',
        date: '',
      };

    const splitValues = statusValue.split(' ');
    if (splitValues.length === 1) {
      return {
        status: '',
        date: splitValues[0],
      };
    }

    return {
      status:
        (splitValues[0].includes(ReportingTableColumnStatusLabel.Draft) ||
          splitValues[0].includes(ReportingTableColumnStatusLabel.Pending)) &&
        !readOnly
          ? ReportingTableColumnStatusLabel.Due
          : splitValues[0],
      date: splitValues[1],
    };
  }, [cell]);

  return (
    <td
      headers="blank"
      style={{textAlign}}
      className={classnames(styles.cell, {
        [styles.notVisible]: !cell.isVisible,
      })}
    >
      <div
        className={classnames(styles.cellInnerWrapper, {
          [styles.fakeColumn]: !cell?.data,
        })}
      >
        {cell?.data?.value &&
          (submissionData.date ? (
            <span
              className={classnames(styles.submissionDateWrapper, {
                [styles.submissionDateOverdue]:
                  submissionData.status ===
                  ReportingTableColumnStatusLabel.Overdue,
              })}
            >
              {submissionData.status ===
                ReportingTableColumnStatusLabel.Overdue && (
                <ActivityStatus
                  activity={ActivityStatuses.Overdue}
                  variant={StyleVariant.Primary}
                  classNames={styles.activityStatusOverride}
                />
              )}
              {submissionData.status ? (
                <>
                  {submissionData.status} <br />
                </>
              ) : null}

              <span className={styles.submissionDate}>
                {valueFormat(submissionData.date, cell.data.format).value}
              </span>
            </span>
          ) : (
            <span>
              {valueFormat(submissionData.status, cell.data.format).value}
            </span>
          ))}
      </div>
    </td>
  );
};

export default ReportingTableStatusSubmissionDateCell;
