import {useAppSelector} from './useReduxHooks';
import {companyDetailsSelector} from '../selectors';

const useCompanyDetails = () => {
  const state = useAppSelector(state => state);
  const data = companyDetailsSelector(state);
  return data;
};

export default useCompanyDetails;
