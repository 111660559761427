import axios, {AxiosError} from 'axios';
import {FormResponseError} from '../types';

export const responseErrorResolver = (
  err: any,
  defaultMessage: string
): {error: string | null; formError: Record<string, string> | null} => {
  if (axios.isAxiosError(err) && err.response) {
    const error = err as AxiosError<FormResponseError>;
    if (error.response?.status === 422) {
      const formError: Record<string, string> = (
        error.response.data as FormResponseError
      ).violations.reduce((acc, item) => {
        return {
          ...acc,
          [item.propertyPath]: item.message,
        };
      }, {});
      return {formError, error: null};
    }
    return {
      error: error.response?.data.detail || defaultMessage,
      formError: null,
    };
  }

  return {
    error: 'Unknown error',
    formError: null,
  };
};
