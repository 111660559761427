import React, {useEffect, useState} from 'react';
import styles from './AcquisitionInformationForm.module.scss';
import {
  ButtonStyle,
  FormStatus,
  StyleVariant,
  AcquisitionInformationFormProps,
  AcquisitionInformationFormState,
  TagItem,
} from '../../../types';
import {Button, ButtonSpinner, Modal, SelectInput} from '../../global';
import {useFormState, useIsSubmitDisabled} from '../../../hooks';
import {acquisitionInformationFormSchema} from '../../../constants';
import classnames from 'classnames';
import TagInput from '../../global/tag-input/TagInput';
import MaskedNumberInput from '../../global/masked-inputs/masked-number-input/MaskedNumberInput';
import {useTranslation} from 'react-i18next';

const AcquisitionInformationForm: React.FC<AcquisitionInformationFormProps> = ({
  error,
  onClose,
  onSubmit,
  options,
  isOpen,
  initialFormState,
  title,
  formStatus,
  formError,
  resetErrors,
}) => {
  const {t} = useTranslation();
  const {
    formState,
    isFormStateChanged,
    setFormState,
    errors,
    validate,
    handleChange,
  } = useFormState<AcquisitionInformationFormState>(
    initialFormState,
    acquisitionInformationFormSchema
  );
  const {isSubmitDisabled} = useIsSubmitDisabled(
    formError,
    errors,
    error,
    isFormStateChanged
  );

  const [tagState, setTagState] = useState<TagItem[]>(
    initialFormState.coInvestors.map(investor => {
      return {id: null, label: investor, mandatory: false};
    })
  );

  const handleOnChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    if (error || formError) {
      resetErrors();
    }
    handleChange(e);
  };

  const handleSubmit = () => {
    // Prepare data to avoid BE error for now if we send empty string
    const prepareData = {
      ...formState,
      enterpriseValue: formState.enterpriseValue || 0,
      acquisitionMultiple: formState.acquisitionMultiple || 0,
      fullyDilutedOwnership: formState.fullyDilutedOwnership || 0,
    };

    onSubmit(prepareData);
  };

  const handleTagChange = (e: TagItem[]) => {
    if (error || formError) {
      resetErrors();
    }

    setFormState(prev => {
      return {
        ...prev,
        coInvestors: e.length ? e.map(tag => tag.label) : [],
      };
    });

    setTagState(e);
  };

  useEffect(() => {
    setTagState(
      initialFormState.coInvestors.map(investor => {
        return {id: null, label: investor, mandatory: false};
      })
    );
  }, [initialFormState]);

  return (
    <Modal
      title={title}
      isOpen={isOpen}
      onClose={onClose}
      variant={StyleVariant.Secondary}
      className={classnames(styles.wrapper, !options && styles.loading)}
      footerChildren={
        options && (
          <div className={styles.buttons}>
            <Button
              text={t('Global.Save')}
              onClick={handleSubmit}
              loading={formStatus === FormStatus.Pending}
              disabled={isSubmitDisabled}
            />
            <Button
              styleType={ButtonStyle.Secondary}
              text={t('Global.Cancel')}
              onClick={onClose}
              disabled={formStatus === FormStatus.Pending}
            />
          </div>
        )
      }
    >
      {error ? <div className={styles.errorMessage}>{error}</div> : null}
      {options ? (
        <div className={styles.content}>
          <MaskedNumberInput
            variant={StyleVariant.Secondary}
            name={'enterpriseValue'}
            label={t(
              'Portfolio.Company.AcquisitionInformationForm.InputLabel.Enterprise'
            )}
            placeholder={t(
              'Portfolio.Company.AcquisitionInformationForm.InputPlaceholder.Enterprise'
            )}
            errorMessage={t(
              errors.enterpriseValue.message || formError?.enterpriseValue || ''
            )}
            validate={validate}
            initialValue={initialFormState.enterpriseValue.toString()}
            onChange={handleOnChange}
            maskType={'number'}
          />
          <MaskedNumberInput
            variant={StyleVariant.Secondary}
            name={'acquisitionMultiple'}
            label={t(
              'Portfolio.Company.AcquisitionInformationForm.MaskedNumberInputLabel.Acquisition'
            )}
            placeholder={t(
              'Portfolio.Company.AcquisitionInformationForm.MaskedNumberInputPlaceholder.Acquisition'
            )}
            errorMessage={t(
              errors.acquisitionMultiple.message ||
                formError?.acquisitionMultiple ||
                ''
            )}
            validate={validate}
            initialValue={initialFormState.acquisitionMultiple.toString()}
            onChange={handleOnChange}
            maskType={'number'}
          />
          <SelectInput
            label={t(
              'Portfolio.Company.AcquisitionInformationForm.SelectInputLabel.Multiple'
            )}
            name={'multipleOf'}
            variant={StyleVariant.Secondary}
            value={formState.multipleOf}
            onChange={handleOnChange}
            options={options?.multipleOf}
            placeholder={t(
              'Portfolio.Company.AcquisitionInformationForm.SelectInputPlaceholder.Multiple'
            )}
          />
          <MaskedNumberInput
            variant={StyleVariant.Secondary}
            name={'fullyDilutedOwnership'}
            label={t(
              'Portfolio.Company.AcquisitionInformationForm.MaskedNumberInputLabel.Ownership'
            )}
            errorMessage={t(
              errors.fullyDilutedOwnership.message ||
                formError?.fullyDilutedOwnership ||
                ''
            )}
            validate={validate}
            initialValue={initialFormState.fullyDilutedOwnership.toString()}
            onChange={handleOnChange}
            maskType={'percent'}
            suffix={'%'}
          />
          <SelectInput
            label={t(
              'Portfolio.Company.AcquisitionInformationForm.SelectInputLabel.Investment'
            )}
            name={'investmentRole'}
            variant={StyleVariant.Secondary}
            value={formState.investmentRole}
            onChange={handleOnChange}
            options={options?.investmentRole}
            placeholder={t(
              'Portfolio.Company.AcquisitionInformationForm.SelectInputPlaceholder.Investment'
            )}
          />
          <TagInput
            label={t(
              'Portfolio.Company.AcquisitionInformationForm.TagInputLabel.CoInvestors'
            )}
            value={tagState}
            onChange={handleTagChange}
            placeholder={t(
              'Portfolio.Company.AcquisitionInformationForm.TagInputPlaceholder.CoInvestors'
            )}
          />
        </div>
      ) : (
        <div className={styles.spinnerWrapper}>
          <ButtonSpinner className={styles.spinner} />
        </div>
      )}
    </Modal>
  );
};

export default AcquisitionInformationForm;
