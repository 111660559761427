import {useEffect, useState} from 'react';

interface ScrollPosition {
  x: number;
  y: number;
}

const getScrollPosition = (): ScrollPosition => {
  return {x: window.scrollX, y: window.scrollY};
};

const useScrollPosition = (): ScrollPosition => {
  const [position, setScrollPosition] = useState<ScrollPosition>(
    getScrollPosition()
  );

  useEffect(() => {
    let requestRunning: number | null = null;
    const handleScroll = () => {
      if (requestRunning === null) {
        requestRunning = window.requestAnimationFrame(() => {
          setScrollPosition(getScrollPosition());
          requestRunning = null;
        });
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return position;
};

export default useScrollPosition;
