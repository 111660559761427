import {LogoProps} from '../../../types';
import React from 'react';

const LanternSecondaryLogoWhite: React.FC<LogoProps> = ({
  gradientId,
  ...props
}) => {
  return (
    <svg
      id="Layer_2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 108.01 102.09"
      {...props}
    >
      <defs>
        <linearGradient
          id={gradientId}
          x1="105.34"
          y1="26.78"
          x2="-77.4"
          y2="185.46"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".19" stopColor="#fff" />
          <stop offset=".48" stopColor="#fff" stopOpacity="0" />
        </linearGradient>
      </defs>
      <g id="Layer_1-2">
        <path
          fill={`url(#${gradientId})`}
          d="m106.48,99.34c.86-1.05,1.35-2.26,1.46-3.62,0-.06.01-.11.02-.17.01-.16.05-.31.05-.48,0-.12-.03-.23-.03-.34,0-.32-.04-.64-.09-.96-.01-.07-.03-.14-.04-.21-.13-.66-.33-1.3-.66-1.94L60.31,3.79s-.05-.06-.07-.1c-.63-1.24-1.59-2.21-2.9-2.9C56.27.22,55.15-.02,54,0c-1.15-.02-2.27.22-3.35.79-1.3.68-2.26,1.65-2.9,2.9-.02.04-.05.06-.07.1L.82,91.62c-.91,1.72-1.06,3.54-.47,5.44.59,1.9,1.75,3.31,3.47,4.22,1.72.91,3.51,1.05,5.38.44,1.86-.61,3.25-1.78,4.15-3.5L54,22.02l35.16,65.9h-35.16c-1.95,0-3.62.7-5.03,2.12-1.41,1.41-2.11,3.09-2.11,5.03s.7,3.6,2.11,4.96c1.41,1.36,3.09,2.04,5.03,2.04h46.86c.06,0,.12-.01.18-.02.38,0,.75-.04,1.12-.1.07-.01.15-.03.22-.04.32-.07.64-.16.95-.28.17-.06.34-.13.5-.21.12-.05.24-.09.36-.15.12-.06.22-.15.34-.22.16-.1.32-.2.47-.31.25-.18.49-.37.71-.57.06-.06.13-.1.19-.16.07-.07.12-.15.2-.23.14-.15.27-.3.39-.46Z"
        />
        <path
          fill="#fff"
          d="m45.72,7.49s2.02-3.77,2.04-3.8c.63-1.24,1.59-2.21,2.9-2.9C51.74.22,52.86-.02,54,0c1.15-.02,2.27.22,3.35.79,1.3.68,2.26,1.65,2.9,2.9.02.04.05.06.07.1l46.87,87.84c.33.63.53,1.28.66,1.94.02.07.03.14.04.21.05.32.08.63.09.96,0,.12.03.22.03.34,0,.17-.04.32-.05.48,0,.06,0,.11-.02.17-.12,1.36-.61,2.57-1.46,3.62-.12.16-.26.31-.39.46-.07.07-.12.16-.2.23-.06.06-.13.1-.19.16-.22.2-.46.39-.71.57-.15.11-.31.21-.47.31-.12.07-.21.16-.34.22-.12.06-.24.09-.36.15-.17.08-.33.15-.5.21-.31.12-.63.21-.95.28-.07.01-.15.03-.22.04-.37.07-.74.1-1.12.1-.06,0-.12.02-.18.02h-46.86c-1.95,0-3.62-.68-5.03-2.04-1.41-1.36-2.11-3.02-2.11-4.96s.7-3.62,2.11-5.03c1.41-1.41,3.09-2.12,5.03-2.12h35.16L54,22.02l-8.28-14.53Z"
        />
      </g>
    </svg>
  );
};

export default LanternSecondaryLogoWhite;
