import React, {useMemo} from 'react';
import {
  ButtonStyle,
  SelectOption,
  StyleVariant,
  TemplateConfigDetailsCustomGroupFormComponentProps,
  TemplateConfigDetailsCustomGroupFormState,
} from '../../../types';
import {Button, Input, Modal, SelectInput} from '../../global';
import styles from './TemplateConfigDetailsCustomGroupForm.module.scss';
import {useFormState, useIsSubmitDisabled} from '../../../hooks';
import {
  customGroupOption,
  templateConfigDetailsCustomGroupFormSchema,
} from '../../../constants';
import {useTranslation} from 'react-i18next';
import {Position} from '../../../context/TooltipContext';

const TemplateConfigDetailsCustomGroupForm: React.FC<
  TemplateConfigDetailsCustomGroupFormComponentProps
> = ({
  isOpen,
  onSubmit,
  onDelete,
  options,
  onClose,
  data,
  error,
  inProgress,
  isDeleteInProgress,
  isSelectedGroupPredefined,
  isCreateGroup,
  formError,
  resetErrors,
}) => {
  const {t} = useTranslation();
  const {
    formState,
    isFormStateChanged,
    validate,
    errors,
    handleChange,
    setFormState,
  } = useFormState<TemplateConfigDetailsCustomGroupFormState>(
    data,
    templateConfigDetailsCustomGroupFormSchema
  );

  const selectOptions: SelectOption[] = useMemo(
    () => [
      customGroupOption,
      ...options.map(({id, name}) => ({id: id, label: name, value: id})),
    ],
    [options]
  );

  const {isSubmitDisabled} = useIsSubmitDisabled(
    formError,
    errors,
    error,
    isFormStateChanged
  );

  const handleSubmit = () => {
    onSubmit(formState);
  };

  const handleChangePredefinedGroup = (id: string) => {
    resetErrors();
    const groupName = options.find(item => item.id === id)?.name || '';
    setFormState(prev => ({...prev, name: groupName, parentId: id}));
  };

  const handleOnChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    if (error || formError) {
      resetErrors();
    }
    handleChange(e);
  };

  const isNameInputDisabled = isCreateGroup
    ? !!formState.parentId
    : isSelectedGroupPredefined;

  return (
    <Modal
      title={t(
        !isCreateGroup
          ? 'Admin.DataCollection.TemplateConfigDetailsCustomGroupForm.Title.EditGroup'
          : 'Admin.DataCollection.TemplateConfigDetailsCustomGroupForm.Title.ChooseGroup'
      )}
      isOpen={isOpen}
      onClose={onClose}
      variant={StyleVariant.Secondary}
      footerChildren={
        <div className={styles.buttons}>
          <Button
            disabled={
              formState.name.trim().length <= 0 ||
              formState.name.trim().length > 256 ||
              isDeleteInProgress ||
              isSubmitDisabled
            }
            text={t(!isCreateGroup ? 'Global.Save' : 'Global.AddNewGroup')}
            onClick={handleSubmit}
            loading={inProgress}
          />
          <Button
            styleType={ButtonStyle.Secondary}
            text={t('Global.Cancel')}
            onClick={onClose}
            disabled={inProgress || isDeleteInProgress}
          />
          {!isCreateGroup && (
            <Button
              className={styles.deleteButton}
              styleType={ButtonStyle.Warning}
              text={t('Global.Delete')}
              onClick={() => onDelete(data.parentId)}
              loading={isDeleteInProgress}
              disabled={inProgress}
            />
          )}
        </div>
      }
    >
      <div className={styles.wrapper}>
        {error ? <div className={styles.errorMessage}>{error}</div> : null}
        {isCreateGroup && (
          <SelectInput
            variant={StyleVariant.Secondary}
            options={selectOptions}
            errorMessage={isNameInputDisabled ? formError?.name : ''}
            onChange={event => handleChangePredefinedGroup(event.target.value)}
            value={formState.parentId}
            label={t('Global.Group')}
            labelIconProps={{
              labelIcon: 'info',
              labelIconClassName: styles.labelIconContainer,
              labelIconTooltipContent: t(
                'DataCollection.TemplateConfigurationTooltips.Group'
              ),
              labelIconTooltipContentPosition: Position.Left,
              labelIconTooltipClasses: styles.tooltipContainer,
              labelIconTooltipArrow: true,
            }}
          />
        )}
        <div>
          <Input
            label={t(
              'Admin.DataCollection.TemplateConfigDetailsCustomGroupForm.InputLabel.Group'
            )}
            errorMessage={
              isNameInputDisabled
                ? ''
                : t(errors.name.message) || formError?.name || ''
            }
            validate={validate}
            value={formState.name}
            onChange={handleOnChange}
            variant={StyleVariant.Secondary}
            name={'name'}
            disabled={isNameInputDisabled}
          />
        </div>
      </div>
    </Modal>
  );
};

export default TemplateConfigDetailsCustomGroupForm;
