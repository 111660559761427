import styles from './DropdownList.module.scss';
import React from 'react';

interface DropdownListProps {
  children: JSX.Element | Array<JSX.Element>;
}

const DropdownList = React.forwardRef<HTMLDivElement, DropdownListProps>(
  ({children}, ref) => {
    return (
      <div className={styles.dropdown} ref={ref}>
        <div className={styles.dropdownInner}>{children}</div>
      </div>
    );
  }
);

export default DropdownList;
