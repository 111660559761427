import React, {useState} from 'react';
import {GenericTable} from '../../global/table';
import {
  GenericExpandedRow,
  NearCastingCapitalTransaction,
  SelectOption,
} from '../../../types';
import {useAppDispatch} from '../../../hooks/useReduxHooks';
import PrevNextButtons from '../../prevn-next-buttons/PrevNextButtons';
import capitalColumns from '../../global/table/scenarios/transactions/CapitalColumns';
import {valueFormat} from '../../../utils';
import styles from './TransactionsCapitalForm.module.scss';
import {
  deleteTransactionInCapitalTransactionAction,
  importFundCapitalTransactionsAction,
} from '../../../reducers/scenarioDetailsTransactionsSlice';
import CapitalForm, {CapitalFormInitialData} from './CapitalForm';
import ImportScenarioTransactionsModal from '../../fund-near-casting/import-scenario-transactions-modal/ImportScenarioTransactionsModal';
import {useTranslation} from 'react-i18next';

const columns = [
  {
    label: 'Transaction type',
    columnId: 'type',
  },
  {
    label: 'Currency',
    columnId: 'currency',
  },
  {
    label: 'Date',
    columnId: 'date',
  },
  {
    label: 'Amount',
    columnId: 'value',
  },
  {
    label: 'Comment',
    columnId: 'comment',
  },
];

const transactionTypeOptions: SelectOption[] = [
  {
    id: 'contribution',
    value: 'contribution',
    label: 'Called',
  },
  {
    id: 'distribution',
    value: 'distribution',
    label: 'Distribution',
  },
];

const getOptionLabel = (options: any, value: string, getValue?: boolean) => {
  if (!value) {
    return '';
  }

  const option = options.find((elem: {value: string}) => {
    return elem.value === value;
  });

  return getValue ? option.value : option.label;
};

const TransactionsCapitalForm = ({
  tableData,
  handleAdd,
  handleEdit,
  minDate,
  maxDate,
  scenarioId,
  fundCurrency,
}: any) => {
  const {t} = useTranslation();
  const [isAdding, setIsAdding] = useState(false);
  const [editedTransaction, setEditedTransaction] =
    useState<CapitalFormInitialData | null>(null);
  const [isShowTransactionForm, setIsShowTransactionForm] = useState(false);
  const [showFileUploadPopup, setShowFileUploadPopup] = useState(false);
  const [fileList, setFileList] = useState<FileList | undefined>(undefined);

  const dispatch = useAppDispatch();

  const handleCloseAddForm = () => {
    setIsAdding(false);
  };

  const handleCloseEditForm = () => {
    setIsShowTransactionForm(false);
    setEditedTransaction(null);
  };

  const onCancelImportClick = () => {
    setShowFileUploadPopup(false);
    setFileList(undefined);
  };

  const handleEditTransaction = (value: any) => {
    handleCloseAddForm();
    const transactionId = value.id;
    const transaction = tableData.find(
      (transaction: any) => transaction.id === transactionId
    );

    const typeOption = transaction.type || transaction?.fundKpi?.kpiName || '';
    transaction.type = getOptionLabel(transactionTypeOptions, typeOption, true);

    setEditedTransaction(transaction);
    setIsShowTransactionForm(true);
  };

  const handleEditTransactionSubmit = (data: any) => {
    /* @ts-ignore */
    handleEdit(data, editedTransaction.id);
  };

  const handleDeleteTransaction = (transactionId?: string) => {
    if (!transactionId) {
      return;
    }

    dispatch(
      deleteTransactionInCapitalTransactionAction({
        id: transactionId,
        scenarioId: scenarioId,
      })
    );
  };

  const rowData = tableData.map((elem: NearCastingCapitalTransaction) => {
    const typeOption = elem.type || elem?.fundKpi?.kpiName || '';

    return {
      id: elem.id,
      type: getOptionLabel(transactionTypeOptions, typeOption),
      // type: elem.type || elem?.fundKpi?.kpiName,
      currency: elem.currency || 'USD',
      date: valueFormat(elem.date, 'dd-MM-yyyy').value.toString(),
      value: elem.value,
      comment: elem.comment,
      isCasting: elem.isCasting,
    };
  });

  const onUploadFile = () => {
    dispatch(
      importFundCapitalTransactionsAction(scenarioId, {
        file: fileList?.item(0)!,
      })
    );
    setShowFileUploadPopup(false);
  };

  return (
    <div className={styles.transactionWrapper}>
      <div className={styles.formHeaderTitle}>Fund capital transactions</div>
      <div className={styles.transactionFormWrapper}>
        <div className={styles.formHeaderWrapper}>
          <div className={styles.transactionCount}>
            {t('Global.Transactions')}
            <span className={styles.rowsCount}>{rowData.length}</span>
          </div>
          <div className={styles.buttonsWrapper}>
            <PrevNextButtons
              handlePrimary={() => {
                setIsAdding(true);
                handleCloseEditForm();
              }}
              handleSecondary={() => {
                setShowFileUploadPopup(true);
              }}
              primaryText={t('Global.AddNew')}
              secondaryText={t('Global.Import')}
            />
          </div>
        </div>
        {isShowTransactionForm && !!editedTransaction && (
          <CapitalForm
            transactionsTypeOptions={transactionTypeOptions}
            initialData={editedTransaction}
            title={t(
              'ScenarioDetailsTransactions.TransactionsCapitalForm.Title'
            )}
            submitText={t('Global.Done')}
            minDate={minDate}
            maxDate={maxDate}
            handleSubmit={handleEditTransactionSubmit}
            handleCloseForm={handleCloseEditForm}
          />
        )}
        {isAdding && (
          <CapitalForm
            transactionsTypeOptions={transactionTypeOptions}
            submitText={t('Global.AddTransaction')}
            title={t('ScenarioDetailsTransactions.CapitalForm.Title.Adding')}
            minDate={minDate}
            maxDate={maxDate}
            handleSubmit={handleAdd}
            handleCloseForm={handleCloseAddForm}
            initialData={{
              type: 'contribution',
            }}
          />
        )}
        <GenericTable
          data={rowData}
          className={styles.table}
          columns={
            capitalColumns(
              columns,
              handleEditTransaction,
              handleDeleteTransaction,
              fundCurrency
            ) as any
          }
          meta={{
            getRowStyles: (row: any): any => {
              return row.original.isCasting
                ? {
                    background: 'var(--nearcasting-gradient)',
                  }
                : {};
            },
          }}
          getRowCanExpand={() => true}
          expandedView={function ({
            row,
          }: GenericExpandedRow<object>): React.ReactElement<
            any,
            string | React.JSXElementConstructor<any>
          > {
            throw new Error('Function not implemented.');
          }}
        />
        <ImportScenarioTransactionsModal
          isOpen={showFileUploadPopup}
          onClose={onCancelImportClick}
          onDropHandler={(fileList: FileList) => setFileList(fileList)}
          templateId="fund_capital_transactions_import"
          onUploadFileClick={onUploadFile}
          handleDeleteFile={() => setFileList(undefined)}
        />
      </div>
    </div>
  );
};

export default TransactionsCapitalForm;
