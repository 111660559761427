import React, {Fragment} from 'react';
import styles from './TransactionsInfo.module.scss';
import {TransactionInfoProps} from '../../../../../types';
import ValueFormat from '../../../value-format/ValueFormat';
import {useTranslation} from 'react-i18next';
import classnames from 'classnames';

const TransactionsInfo: React.FC<TransactionInfoProps> = ({
  transactionsInfo,
}) => {
  const {t} = useTranslation();
  return (
    <div className={styles.wrapper}>
      <span className={styles.transactionsHeader}>
        {transactionsInfo[0].companyName
          ? t('Global.PortfolioCompany')
          : t('Global.Fund')}
      </span>
      <span
        className={classnames(styles.rightPosition, styles.transactionsHeader)}
      >
        {t('Global.Shares')}
      </span>
      <span className={styles.transactionsHeader}>{t('Global.Currency')}</span>
      <span
        className={classnames(styles.rightPosition, styles.transactionsHeader)}
      >
        {t('Global.Cost')}
      </span>
      <span className={styles.transactionsHeader}>
        {t('Documents.TransactionsInfo.TransactionDate')}
      </span>

      {transactionsInfo.map(item => (
        <Fragment key={item.id}>
          <span className={styles.transactionsRow}>
            {item.companyName ?? ''} {item.fundName ?? ''}
          </span>
          <span
            className={classnames(styles.rightPosition, styles.transactionsRow)}
          >
            <ValueFormat
              value={item.shares?.value}
              format={item.shares?.format}
            />
          </span>
          <span className={styles.transactionsRow}>{item.currency}</span>
          <span
            className={classnames(styles.rightPosition, styles.transactionsRow)}
          >
            <ValueFormat value={item.cost?.value} format={item.cost?.format} />
          </span>
          <span className={styles.transactionsRow}>
            <ValueFormat value={item.date?.value} format={item.date?.format} />
          </span>
        </Fragment>
      ))}
    </div>
  );
};

export default TransactionsInfo;
