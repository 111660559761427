import React from 'react';
import classnames from 'classnames';
import {proxy} from 'comlink';
import {
  capitalizeStringSentence,
  downloadZippedExport,
  htmlToImage,
} from '../../../utils';
import {PortfolioExposureWidget} from '../';
import {
  ExportDropdownOption,
  PortfolioExposureDataProps,
} from '../../../types/index';
import {SectionHeader} from '../../';
import ExportDropdown from '../../global/export-dropdown/ExportDropdown';
import {
  exsposureWidgetImagesExportPayload,
  exsposureWidgetTableExportPayload,
  investmentFocusExportOptions,
} from '../portfolio-exposure-widget/PortfolioExposureWidgetExportSettings';
import styles from './PorfolioExposureDashboard.module.scss';
import {exportsWorker} from '../../../utils/export';
import {useTranslation} from 'react-i18next';
interface PortfolioExposureDashboardProps extends PortfolioExposureDataProps {
  className?: string;
  withSectionHeader?: boolean;
}

const PortfolioExposureDashboard: React.FC<PortfolioExposureDashboardProps> = ({
  label,
  widgets,
  fundName,
  className = '',
  withSectionHeader = true,
}): JSX.Element => {
  const {t} = useTranslation();
  const investmentFocusExportHandler = async (option: ExportDropdownOption) => {
    const zipFileName = `${fundName.split(' ').join('')}_${t(
      'PortfolioExposureDashboard.Export.InvestmentFocus'
    )}_${capitalizeStringSentence(option.value, '-')}`;

    const exportedData = await exportsWorker.exportZippedChartData(
      exsposureWidgetTableExportPayload(widgets, option, zipFileName),
      exsposureWidgetImagesExportPayload(option.value, zipFileName),
      proxy(htmlToImage)
    );

    downloadZippedExport({
      name: zipFileName,
      blob: exportedData,
    });
  };

  return (
    <div className={classnames(styles.wrapper, className)}>
      {withSectionHeader && (
        <SectionHeader
          label={label}
          labelType={'large'}
          onClick={() => null}
          withActionButton={false}
          className={styles.exposuresSectionHeader}
          childrenRight={[
            <ExportDropdown
              exportOptions={investmentFocusExportOptions}
              exportHandler={investmentFocusExportHandler}
            />,
          ]}
        />
      )}
      <div className={styles.widgets}>
        {widgets.map((item, i) => (
          <div className={styles.widgetsItem} key={i}>
            <PortfolioExposureWidget {...item} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default PortfolioExposureDashboard;
