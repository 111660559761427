import {put, takeEvery} from 'redux-saga/effects';
import {errorHandlerAction} from '../../actions';
import {generalResponseErrorResolver} from '../../utils';
import {setGlobalInformation} from '../../reducers/globalInformationSlice';

export const errorHandlerSaga = function* errorHandlerSaga({payload}: any) {
  const responseError = generalResponseErrorResolver(payload.error);
  if (responseError) {
    yield put(setGlobalInformation(responseError));
  }
};

export const errorHandlerSagaWatcher = function* errorHandlerSagaWatcher() {
  yield takeEvery(errorHandlerAction.type, errorHandlerSaga);
};
