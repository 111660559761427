import React, {useCallback, useState} from 'react';
import {GenericTable} from '../../global';
import ExpandedRowHeader from '../ExpandedRowHeader/ExpandedRowHeader';
import ScenarioValuationExpandedTableColumns from '../../global/table/scenarios/valuation/ScenarioValuationExpandedTableColumns';
import {
  GenericExpandedRow,
  NearCastingScenarioCompany,
  NearCastingScenarioCompanyTransaction,
} from '../../../types';
import {TransactionForm} from '../../transaction-form';
import {
  FormDataProps,
  getInstrumentLabel,
} from '../../transaction-form/transaction-form/TransactionForm';
import {useScenarioDetails} from '../../../hooks';
import {valueFormat} from '../../../utils';
import styles from './ValuationTableExpandedRow.module.scss';
import classnames from 'classnames';
import {v4 as uuid} from 'uuid';
import format from 'date-fns/format';
import {
  deletePortfolioCompanyNearCastingTransactionAction,
  importPortfolioCompanyTransactions,
  savePortfolioCompanyNearCastingTransactionAction,
  updatePortfolioCompanyNearCastingTransactionAction,
} from '../../../reducers/scenarioDetailsValuationSlice';
import {useAppDispatch, useAppSelector} from '../../../hooks/useReduxHooks';
import ImportScenarioTransactionsModal from '../../fund-near-casting/import-scenario-transactions-modal/ImportScenarioTransactionsModal';
import {useTranslation} from 'react-i18next';

const transactionColumns = [
  {
    label: 'Instrument type',
    columnId: 'instrument',
  },
  {
    label: 'Comment',
    columnId: 'comment',
  },
  {
    label: 'Currency',
    columnId: 'currency',
  },
  {
    label: 'Shares',
    columnId: 'shares',
  },
  {
    label: 'Date',
    columnId: 'date',
  },
  {
    label: 'Cost',
    columnId: 'invested',
  },
  {
    label: 'Realized gain/loss',
    columnId: 'realisedGains',
  },
  {
    label: 'Investment income',
    columnId: 'investmentIncome',
  },
];

const getTransactionsData = (
  company: NearCastingScenarioCompany
): NearCastingScenarioCompanyTransaction[] => {
  const reportedTransactions =
    company.portfolioCompanyTransactions?.map((data: any) => ({
      id: data.id,
      instrument: data.instrumentLabel ?? data.instrument,
      comment: data.comment || '',
      currency: company?.currency,
      shares: data.shares,
      date: valueFormat(data.date, 'dd-MM-yyyy').value.toString(),
      invested: data.invested,
      realised: data.realisedGains,
      income: data.investmentIncome,
    })) || [];

  const ncTransactions =
    company.transactions?.map((data: any) => ({
      id: data.id,
      instrument: data.instrumentLabel ?? data.instrument,
      comment: data.comment || '',
      currency: company?.currency,
      shares: data.shares,
      date: valueFormat(data.date, 'dd-MM-yyyy').value.toString(),
      invested: data.invested,
      realisedGains: data.realisedGains,
      investmentIncome: data.investmentIncome,
      isNcTransaction: true,
    })) || [];

  return [...ncTransactions, ...reportedTransactions];
};

interface Props {
  company: any;
  fundCurrency: string;
}

const ValuationTableExpandedRow = ({company, fundCurrency}: Props) => {
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const formStatus = useAppSelector(
    state => state?.scenarioDetails?.valuation?.transactionFormStatus
  );

  const transactionData = getTransactionsData(company);
  const [editedTransaction, setEditedTransaction] = useState({});
  const isEmpty = transactionData.length === 0;
  const [isShowTransactionForm, setIsShowTransactionForm] = useState(false);
  const [transactionIdToRemove, setTransactionIdToRemove] = useState('');
  const [showFileUploadPopup, setShowFileUploadPopup] = useState(false);
  const [fileList, setFileList] = useState<FileList | undefined>(undefined);

  const onAddClick = useCallback(() => {
    setEditedTransaction({});
    setIsShowTransactionForm(true);
  }, []);
  const onImportClick = useCallback(() => {
    setShowFileUploadPopup(true);
  }, []);
  const onCancelImportClick = () => {
    setShowFileUploadPopup(false);
    setFileList(undefined);
  };
  const onCancelClick = useCallback(() => {
    setIsShowTransactionForm(false);
  }, []);

  const submitAddTransaction = useCallback(
    (value: any) => {
      const transaction = {
        currency: company.currency,
        transactionType: value.transactionType,
        comment: value.comment,
        shares: value.shares?.toString() || '0',
        invested: parseFloat(value.invested),
        instrument: value.instrument,
        instrumentLabel: getInstrumentLabel(value.instrument),
        investmentIncome: parseFloat(value.investmentIncome),
        realisedGains: parseFloat(value.realisedGains),
        date: format(value.date, 'dd-MM-yyyy'),
        transactionId: value.transactionId ?? uuid(),
        ncCompany: `/api/near_casting/companies/${company.id}`,
      };

      dispatch(
        savePortfolioCompanyNearCastingTransactionAction({
          transaction,
        })
      );
    },
    [company.currency, company.id, dispatch]
  );

  const submitEditTransaction = useCallback(
    (value: any) => {
      const transaction = {
        transactionType: value.transactionType,
        currency: company.currency,
        comment: value.comment,
        shares: value.shares?.toString() || '0',
        invested: parseFloat(value.invested),
        instrument: value.instrument,
        instrumentLabel: getInstrumentLabel(value.instrument),
        investmentIncome: parseFloat(value.investmentIncome),
        realisedGains: parseFloat(value.realisedGains),
        date: format(new Date(value.date), 'dd-MM-yyyy'),
        id: value.id,
        ncCompany: `/api/near_casting/companies/${company.id}`,
      };

      dispatch(
        updatePortfolioCompanyNearCastingTransactionAction({
          transaction,
        })
      );
    },
    [company.currency, company.id, dispatch]
  );

  const handleDeleteTransaction = useCallback(
    (transactionId?: string) => {
      if (!transactionId) {
        return;
      }
      setIsShowTransactionForm(false);
      setTransactionIdToRemove(transactionId);
      dispatch(
        deletePortfolioCompanyNearCastingTransactionAction({
          id: transactionId,
          companyId: company.id,
        })
      );
    },
    [company.id, dispatch]
  );

  const handleEditTransaction = (value: any) => {
    const transactionId = value.id;
    const transaction = company.transactions.find(
      (transaction: any) => transaction.id === transactionId
    );

    setEditedTransaction(transaction);
    setIsShowTransactionForm(true);
  };

  const onUploadFile = () => {
    dispatch(
      importPortfolioCompanyTransactions(company.id, {file: fileList?.item(0)!})
    );
    setShowFileUploadPopup(false);
  };

  const onFormSubmit = useCallback(
    (formState: FormDataProps) => {
      if (formState.id) {
        submitEditTransaction(formState);
      } else {
        submitAddTransaction(formState);
      }
      setIsShowTransactionForm(false);
    },
    [submitEditTransaction, submitAddTransaction]
  );
  const {startDate, endDate} = useScenarioDetails();

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <div
          className={classnames(styles.header, {
            [styles.expandedHeader]: isShowTransactionForm,
          })}
        >
          <ExpandedRowHeader
            transactionsCount={transactionData?.length || 0}
            handleAddNew={onAddClick}
            handleImport={onImportClick}
            disabled={isShowTransactionForm}
          />
        </div>
        {isShowTransactionForm && (
          <div className={styles.addTransactionForm}>
            <TransactionForm
              data={editedTransaction}
              onSubmit={onFormSubmit}
              onCancel={onCancelClick}
              minDate={new Date(startDate as string)}
              maxDate={new Date(endDate as string)}
              formStatus={formStatus}
            />
          </div>
        )}
        {isEmpty ? (
          <div className={styles.emptyState}>
            {t('ScenarioDetailsValuation.ValuationTableExpandedRow.EmptyState')}
          </div>
        ) : (
          <GenericTable<NearCastingScenarioCompanyTransaction>
            data={transactionData}
            columns={ScenarioValuationExpandedTableColumns(
              transactionColumns,
              handleEditTransaction,
              handleDeleteTransaction,
              transactionIdToRemove,
              fundCurrency
            )}
            getRowCanExpand={() => false}
            className={styles.table}
            expandedView={function ({
              row,
            }: GenericExpandedRow<any>): React.ReactElement<
              any,
              string | React.JSXElementConstructor<any>
            > {
              throw new Error('Function not implemented.');
            }}
            meta={{
              getRowStyles: (row: any): any => {
                return row.original.isNcTransaction
                  ? {
                      background: 'var(--nearcasting-gradient)',
                    }
                  : {};
              },
            }}
          />
        )}
        <ImportScenarioTransactionsModal
          isOpen={showFileUploadPopup}
          onClose={onCancelImportClick}
          onDropHandler={(fileList: FileList) => setFileList(fileList)}
          templateId="company_transactions_import"
          onUploadFileClick={onUploadFile}
          handleDeleteFile={() => setFileList(undefined)}
        />
      </div>
    </div>
  );
};

export default ValuationTableExpandedRow;
