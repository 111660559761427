import {all, call, delay, put, select, takeLatest} from 'redux-saga/effects';
import {acquireAuthResult, msalInstance} from '../..';
import {
  clearCompanyDetailsCompanyData,
  clearCompanyDetailsDealTeamOptions,
  getCompanyAcquisitionInformationEditOptionsAction,
  getCompanyGeneralInfoEditOptionsAction,
  getFullyDilutedOwnershipEditOptionsAction,
  saveCompanyAcquisitionInformationEditAction,
  saveCompanyGeneralInfoEditAction,
  searchCompanyDetailsDealTeamMembersAction,
  setCompanyAcquisitionInfo,
  setCompanyAcquisitionInfoOptions,
  setCompanyDetailsCompanyData,
  setCompanyDetailsCompanyDealTeamData,
  setCompanyDetailsCompanyError,
  setCompanyDetailsCompanyFormError,
  setCompanyDetailsCompanyFormStatus,
  setCompanyDetailsCompanyOwnershipData,
  setCompanyDetailsDealTeamSearchOptions,
  setCompanyGeneralInfo,
  setCompanyGeneralInfoOptions,
  setCompanyLatestNewsItem,
  setCompanyResolvedAndFavoriteOfNewsItem,
  setFullyDilutedOwnershipEditOptions,
  setPortfolioCompanyGeneralInfoId,
  setPortfolioCompanyIcon,
  updateCompanyDetailsCompanyDealTeamMembersAction,
  updateCompanyLatestNewsAction,
  updateFullyDilutedOwnershipDataAction,
} from '../../reducers/companyDetailsCompanySlice';
import {
  AcquisitionInformation,
  AcquisitionInformationFormState,
  Company,
  CompanyAcquisitionInfoFormData,
  CompanyGeneralInfoFormData,
  CompanyGeneralInfoFormState,
  FormStatus,
  InfoHeaderType,
  NewsItemRow,
  OwnershipFormData,
  UpdateNewsPayload,
  WidgetData,
} from '../../types';
import {errorHandlerAction} from '../../actions';
import {
  getAcquisitionInformationOptions,
  getCompanyDetailsCompanyOverview,
  getCompanyGeneralInfoOptions,
  getFullyDilutedOwnershipFormOptions,
  saveAcquisitionInformation,
  saveCompanyGeneralInfo,
  searchDealTeamMembers,
  updateCompanyLatestNews,
  updateDealTeamMembers,
  updateFullyDilutedOwnershipData,
} from '../../api/getCompanyDetailsCompanyOverview';
import {PayloadAction} from '@reduxjs/toolkit';
import {DealTeamMember} from '../../types/dealTeamForm';
import {RootState} from '../../store';
import {
  callToastError,
  DEFAULT_ERROR_TOAST_OPTIONS,
  responseErrorResolver,
  usersResponseErrorResolver,
} from '../../utils';
import {setGlobalInformation} from '../../reducers/globalInformationSlice';
import i18n from '../../i18n';

export const companyDetailsCompanySaga = function* companyDetailsCompanySaga({
  payload,
}: any): any {
  const id: string = payload?.match?.params?.id;
  if (id) {
    const isUserProfileUpdatePending = yield select(
      (state: RootState) => state.userProfile.isLoading
    );
    if (!isUserProfileUpdatePending) {
      yield put(clearCompanyDetailsCompanyData());
    }
    try {
      const {accessToken} = yield call(acquireAuthResult, msalInstance);
      const response: Company = yield call(
        getCompanyDetailsCompanyOverview,
        accessToken,
        id
      );
      yield put(setCompanyDetailsCompanyData(response));
    } catch (err) {
      console.warn(err);
      yield put(errorHandlerAction({error: err}));
    }
  }
};

export const companyDetailsSearchDealTeamMembersSaga =
  function* companyDetailsSearchDealTeamMembersSaga({
    payload,
  }: PayloadAction<{search: string; id: string}>) {
    yield delay(500);
    const search = payload.search;
    if (search?.length > 1) {
      try {
        const {accessToken} = yield call(acquireAuthResult, msalInstance);
        const response: string[] = yield call(
          searchDealTeamMembers,
          accessToken,
          payload.id,
          search
        );
        yield put(setCompanyDetailsDealTeamSearchOptions(response));
      } catch (err) {
        console.warn(err);
        yield put(clearCompanyDetailsDealTeamOptions());
        yield put(errorHandlerAction({error: err}));
      }
    }
  };

export const updateCompanyDetailsCompanyDealTeamMembersSaga =
  function* updateCompanyDetailsCompanyDealTeamMembersSaga({
    payload,
  }: PayloadAction<{id: string; payload: DealTeamMember[]}>) {
    yield call(companyDetailsFormErrorHelperSaga);
    try {
      const {accessToken} = yield call(acquireAuthResult, msalInstance);
      yield put(setCompanyDetailsCompanyFormStatus(FormStatus.Pending));
      const response: DealTeamMember[] = yield call(
        updateDealTeamMembers,
        accessToken,
        payload.id,
        payload.payload
      );
      yield put(setCompanyDetailsCompanyFormStatus(FormStatus.Success));
      yield put(setCompanyDetailsCompanyDealTeamData(response));
    } catch (err) {
      console.warn(err);
      yield put(setCompanyDetailsCompanyFormStatus(FormStatus.Ready));
      const {error, formError} = responseErrorResolver(
        err,
        i18n.t('UpdateCompanyDetailsCompanyDealTeamMembers.Error')
      );
      yield put(setCompanyDetailsCompanyError(error));
      yield put(setCompanyDetailsCompanyFormError(formError));
    }
  };

export const getCompanyGeneralInfoEditOptionsSaga =
  function* getCompanyGeneralInfoEditOptionsSaga({
    payload,
  }: PayloadAction<{id: string}>) {
    yield call(companyDetailsFormErrorHelperSaga);
    yield put(setCompanyGeneralInfoOptions(null));
    try {
      const {accessToken} = yield call(acquireAuthResult, msalInstance);
      const response: CompanyGeneralInfoFormData = yield call(
        getCompanyGeneralInfoOptions,
        accessToken,
        payload.id
      );
      yield put(setCompanyGeneralInfoOptions(response));
    } catch (err) {
      console.warn(err);
      const {error, formError} = responseErrorResolver(
        err,
        i18n.t('GetCompanyGeneralInfoEditOptions.Error')
      );
      yield put(setCompanyDetailsCompanyError(error));
      yield put(setCompanyDetailsCompanyFormError(formError));
    }
  };

export const saveCompanyGeneralInfoEditSaga =
  function* saveCompanyGeneralInfoEditSaga({
    payload,
  }: PayloadAction<{
    formData: CompanyGeneralInfoFormState;
    portfolioCompanyId?: string;
    portfolioCompanyGeneralInfoId?: string;
  }>) {
    yield call(companyDetailsFormErrorHelperSaga);
    try {
      const {accessToken} = yield call(acquireAuthResult, msalInstance);
      yield put(setCompanyDetailsCompanyFormStatus(FormStatus.Pending));
      const response: {
        generalInformation: InfoHeaderType[];
        portfolioCompanyGeneralInfoId: string;
        companyIcon: string | null;
      } = yield call(saveCompanyGeneralInfo, accessToken, payload);
      yield put(setCompanyDetailsCompanyFormStatus(FormStatus.Success));
      yield put(setCompanyGeneralInfo(response.generalInformation));
      yield put(
        setPortfolioCompanyGeneralInfoId(response.portfolioCompanyGeneralInfoId)
      );
      yield put(setPortfolioCompanyIcon(response.companyIcon));
      yield put(setCompanyGeneralInfoOptions(null));
    } catch (err) {
      console.warn(err);
      yield put(setCompanyDetailsCompanyFormStatus(FormStatus.Ready));
      const {error, formError} = responseErrorResolver(
        err,
        i18n.t('SaveCompanyGeneralInfoEdit.Error')
      );
      yield put(setCompanyDetailsCompanyError(error));
      yield put(setCompanyDetailsCompanyFormError(formError));
    }
  };

export const getCompanyAcquisitionInformationEditOptionsSaga =
  function* getCompanyAcquisitionInformationEditOptionsSaga({
    payload,
  }: PayloadAction<{id: string}>) {
    yield call(companyDetailsFormErrorHelperSaga);
    try {
      const {accessToken} = yield call(acquireAuthResult, msalInstance);
      const response: CompanyAcquisitionInfoFormData = yield call(
        getAcquisitionInformationOptions,
        accessToken,
        payload.id
      );
      yield put(setCompanyAcquisitionInfoOptions(response));
    } catch (err) {
      console.warn(err);
      const {error, formError} = responseErrorResolver(
        err,
        i18n.t('GetCompanyAcquisitionInformationEditOptions.Error')
      );
      yield put(setCompanyDetailsCompanyError(error));
      yield put(setCompanyDetailsCompanyFormError(formError));
    }
  };

export const saveCompanyAcquisitionInformationEditSaga =
  function* saveCompanyAcquisitionInformationEditSaga({
    payload,
  }: PayloadAction<{
    formData: AcquisitionInformationFormState;
    portfolioCompanyId?: string;
    portfolioCompanyGeneralInfoId?: string;
  }>) {
    yield call(companyDetailsFormErrorHelperSaga);
    try {
      const {accessToken} = yield call(acquireAuthResult, msalInstance);
      yield put(setCompanyDetailsCompanyFormStatus(FormStatus.Pending));
      const response: {
        acquisitionInformation: AcquisitionInformation;
        portfolioCompanyGeneralInfoId: string;
      } = yield call(saveAcquisitionInformation, accessToken, payload);
      yield put(setCompanyDetailsCompanyFormStatus(FormStatus.Success));
      yield put(setCompanyAcquisitionInfo(response.acquisitionInformation));
      yield put(
        setPortfolioCompanyGeneralInfoId(response.portfolioCompanyGeneralInfoId)
      );
      yield put(setCompanyAcquisitionInfoOptions(null));
    } catch (err) {
      console.warn(err);
      yield put(setCompanyDetailsCompanyFormStatus(FormStatus.Ready));
      const {error, formError} = responseErrorResolver(
        err,
        i18n.t('SaveCompanyAcquisitionInformationEdit.Error')
      );
      yield put(setCompanyDetailsCompanyError(error));
      yield put(setCompanyDetailsCompanyFormError(formError));
    }
  };

export const getFullyDilutedOwnershipEditOptionsSaga =
  function* getFullyDilutedOwnershipEditOptionsSaga({
    payload,
  }: PayloadAction<{id: string}>) {
    yield call(companyDetailsFormErrorHelperSaga);
    try {
      yield put(setCompanyDetailsCompanyFormStatus(FormStatus.Pending));
      const {accessToken} = yield call(acquireAuthResult, msalInstance);
      const response: OwnershipFormData[] = yield call(
        getFullyDilutedOwnershipFormOptions,
        accessToken,
        payload.id
      );
      yield put(setCompanyDetailsCompanyFormStatus(FormStatus.Ready));
      yield put(setFullyDilutedOwnershipEditOptions(response));
    } catch (err) {
      console.warn(err);
      const {error} = responseErrorResolver(
        err,
        i18n.t('GetFullyDilutedOwnershipEditOptions.Error')
      );
      // [LP-3303] Here the naming is confusing, but it will be fixed in future ticket. We set FormStatus.Success (will be FormStatus.Resolved in the future) in order to close a form upon a backend error, and then we dispatch a toaster to show the error message - Aleksa Jukic
      yield put(setCompanyDetailsCompanyFormStatus(FormStatus.Success));
      yield call(callToastError, error, DEFAULT_ERROR_TOAST_OPTIONS);
    }
  };

export const updateFullyDilutedOwnershipDataSaga =
  function* updateFullyDilutedOwnershipDataSaga({
    payload,
  }: PayloadAction<{id: string; payload: OwnershipFormData[]}>) {
    yield call(companyDetailsFormErrorHelperSaga);
    try {
      const {accessToken} = yield call(acquireAuthResult, msalInstance);
      yield put(setCompanyDetailsCompanyFormStatus(FormStatus.Pending));
      const response: WidgetData = yield call(
        updateFullyDilutedOwnershipData,
        accessToken,
        payload.id,
        payload.payload
      );
      yield put(setCompanyDetailsCompanyOwnershipData(response));
      yield put(setCompanyDetailsCompanyFormStatus(FormStatus.Success));
      yield put(setFullyDilutedOwnershipEditOptions([]));
    } catch (err) {
      console.warn(err);
      yield put(setCompanyDetailsCompanyFormStatus(FormStatus.Ready));
      const {error, formError} = responseErrorResolver(
        err,
        i18n.t('UpdateFullyDilutedOwnershipData.Error')
      );
      yield put(setCompanyDetailsCompanyError(error));
      yield put(setCompanyDetailsCompanyFormError(formError));
    }
  };

export const updateCompanyLatestNewsSaga =
  function* updateCompanyLatestNewsSaga({
    payload,
  }: PayloadAction<UpdateNewsPayload>) {
    const {id, ...restPayload} = payload;
    if (id) {
      const originalState: Company = yield select(
        (state: RootState) => state.companyDetails.company.data
      );
      try {
        yield put(setCompanyResolvedAndFavoriteOfNewsItem(payload));
        const {accessToken} = yield call(acquireAuthResult, msalInstance);
        const response: NewsItemRow = yield call(
          updateCompanyLatestNews,
          accessToken,
          id,
          restPayload
        );
        yield put(setCompanyLatestNewsItem(response));
      } catch (err) {
        console.warn(err);
        const responseError = usersResponseErrorResolver(err);
        if (responseError) {
          yield put(setGlobalInformation(responseError));
        }
        const originalNewsItem = originalState.news.data.find(
          item => item.id === payload.id
        );
        if (originalNewsItem) {
          yield put(setCompanyLatestNewsItem(originalNewsItem));
        }
      }
    }
  };

const companyDetailsFormErrorHelperSaga =
  function* companyDetailsFormErrorHelperSaga() {
    const error: string | null = yield select(
      (state: RootState) => state.companyDetails.company.error
    );
    const formError: string | null = yield select(
      (state: RootState) => state.companyDetails.company.formError
    );
    if (error) {
      yield put(setCompanyDetailsCompanyError(null));
    }
    if (formError) {
      yield put(setCompanyDetailsCompanyFormError(null));
    }
  };

export const companyDetailsOverviewCompanySagaWatcher =
  function* companyDetailsOverviewCompanySagaWatcher() {
    yield all([
      takeLatest(
        searchCompanyDetailsDealTeamMembersAction.type,
        companyDetailsSearchDealTeamMembersSaga
      ),
      takeLatest(
        updateCompanyDetailsCompanyDealTeamMembersAction.type,
        updateCompanyDetailsCompanyDealTeamMembersSaga
      ),
      takeLatest(
        getCompanyGeneralInfoEditOptionsAction.type,
        getCompanyGeneralInfoEditOptionsSaga
      ),
      takeLatest(
        saveCompanyGeneralInfoEditAction.type,
        saveCompanyGeneralInfoEditSaga
      ),
      takeLatest(
        getCompanyAcquisitionInformationEditOptionsAction.type,
        getCompanyAcquisitionInformationEditOptionsSaga
      ),
      takeLatest(
        saveCompanyAcquisitionInformationEditAction.type,
        saveCompanyAcquisitionInformationEditSaga
      ),
      takeLatest(
        getFullyDilutedOwnershipEditOptionsAction.type,
        getFullyDilutedOwnershipEditOptionsSaga
      ),
      takeLatest(
        updateFullyDilutedOwnershipDataAction.type,
        updateFullyDilutedOwnershipDataSaga
      ),
      takeLatest(
        updateCompanyLatestNewsAction.type,
        updateCompanyLatestNewsSaga
      ),
    ]);
  };
