import axios from 'axios';
import config from '../config';
import {UploadOneFilePayload} from '../types';

export const uploadFile = async (
  token: string,
  payload: UploadOneFilePayload,
  signal: AbortSignal,
  option?: string
) => {
  const formData = new FormData();
  formData.append('file', payload.file);
  formData.append('customTags', JSON.stringify(payload.customTags));
  formData.append('entityTags', JSON.stringify(payload.entityTags));
  formData.append('sectionTags', JSON.stringify(payload.sectionTags));
  formData.append('transactions', JSON.stringify(payload.transactions));
  formData.append('columns', JSON.stringify(payload.columns));
  formData.append('isPrivate', JSON.stringify(payload.isPrivate));
  const {data} = await axios.post(
    `${config.APP_URI}/document/upload${option ? `?option=${option}` : ''}`,
    formData,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
      signal,
    }
  );
  return data;
};

export const deleteFile = async (token: string, id: string) => {
  const {data} = await axios.delete(`${config.APP_URI}/document/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
  });
  return data;
};

export const downloadFile = async (token: string, id: string) => {
  const response = await axios.get(
    `${config.APP_URI}/document/${id}/download`,
    {
      responseType: 'blob',
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );
  const href = window.URL.createObjectURL(response.data);

  const anchorElement = document.createElement('a');

  anchorElement.href = href;

  // 1) Get the value of content-disposition header
  const contentDisposition = response.headers['content-disposition'];

  // 2) set the fileName variable to the default value
  let fileName = 'file';

  // 3) if the header is set, extract the filename
  if (contentDisposition) {
    const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
    if (fileNameMatch?.length === 2) {
      fileName = fileNameMatch[1];
    }
  }

  anchorElement.download = fileName;

  document.body.appendChild(anchorElement);
  anchorElement.click();

  document.body.removeChild(anchorElement);
  window.URL.revokeObjectURL(href);
  return response.data;
};
