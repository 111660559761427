import React from 'react';
import {LabelStyles} from '../../../types/index';

export interface CustomBarProps {
  DPI?: number;
  RVPI?: number;
  TVPI?: number;
  noData?: boolean;
  background?: {
    x?: number;
    y?: number;
    width?: number;
    height?: number;
  };
  heigth?: number;
  index?: number;
  quarter?: string;
  value?: Array<number>;
  x?: number;
  y?: number;
  labelStyles?: LabelStyles;
}

const NoDataChartLabel: React.FC<CustomBarProps> = ({
  x,
  y,
  noData,
  labelStyles,
  quarter,
}): JSX.Element => {
  if (!noData) {
    return <div></div>;
  }

  return (
    <g transform={`translate(${x},${y})`}>
      <rect
        x={labelStyles?.rectX}
        y={labelStyles?.rectY}
        width={32}
        height={32}
        rx={16}
        fill="rgb(var(--colors-gray-7))"
      ></rect>
      <svg
        x={(labelStyles?.rectX || 0) + 9}
        y={(labelStyles?.rectY || 0) + 8}
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
      >
        <path
          d="M6.2504 10.25C6.2504 10.4489 6.32941 10.6397 6.47007 10.7803C6.61072 10.921 6.80148 11 7.0004 11C7.19931 11 7.39007 10.921 7.53073 10.7803C7.67138 10.6397 7.7504 10.4489 7.7504 10.25C7.7504 10.0511 7.67138 9.86032 7.53073 9.71967C7.39007 9.57902 7.19931 9.5 7.0004 9.5C6.80148 9.5 6.61072 9.57902 6.47007 9.71967C6.32941 9.86032 6.2504 10.0511 6.2504 10.25ZM6.5004 5.5V8.375C6.5004 8.44375 6.55665 8.5 6.6254 8.5H7.3754C7.44415 8.5 7.5004 8.44375 7.5004 8.375V5.5C7.5004 5.43125 7.44415 5.375 7.3754 5.375H6.6254C6.55665 5.375 6.5004 5.43125 6.5004 5.5ZM13.9332 12.375L7.43321 1.125C7.33633 0.957813 7.16915 0.875 7.0004 0.875C6.83165 0.875 6.6629 0.957813 6.56758 1.125L0.0675834 12.375C-0.124604 12.7094 0.116021 13.125 0.500396 13.125H13.5004C13.8848 13.125 14.1254 12.7094 13.9332 12.375ZM1.69102 11.9391L7.0004 2.74844L12.3098 11.9391H1.69102Z"
          fill="rgb(var(--colors-gray-5))"
        />
      </svg>
    </g>
  );
};

export default NoDataChartLabel;
