import React from 'react';
import classNames from 'classnames';
import {Icon} from '../../../index';
import styles from './ExpanderCell.module.scss';

type ExpanderCellProps = {
  expanded: boolean;
  className?: string;
};

const ExpanderCell = ({
  expanded,
  className,
}: ExpanderCellProps): JSX.Element => {
  return (
    <div
      className={classNames(styles.chevron, className, {
        [styles.chevron__up]: expanded,
      })}
    >
      <Icon name={'chevron-down'} />
    </div>
  );
};

export default ExpanderCell;
