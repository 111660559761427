import React from 'react';
import styles from './WorkflowActivity.module.scss';
import {WorkflowActivityProps} from '../../../types';
import classnames from 'classnames';
import {ValueFormat} from '../index';
import {useTranslation} from 'react-i18next';
import WorkflowActivityStatus from '../workflow-activity-status/WorkflowActivityStatus';

const WorkflowActivity: React.FC<WorkflowActivityProps> = ({
  status,
  date,
  timeline,
  assigneeName,
  assigneeEmail,
  workflowType,
}) => {
  const {t} = useTranslation();

  return (
    <div className={styles.wrapper}>
      <div className={styles.info}>
        <div className={styles.infoTitle}>{t('Global.Workflow')}</div>
        <div className={styles.infoValue}>{workflowType}</div>
        <div className={classnames(styles.infoTitle, styles.separator)}>
          <WorkflowActivityStatus status={status.id} label={status.label} />
        </div>
        <div className={styles.infoTitle}>{t('Global.Assignee')}</div>
        <div className={styles.infoValue}>{assigneeName || '-'}</div>
        <div className={styles.infoSubValue}>{assigneeEmail}</div>
      </div>
      <div className={styles.timeline}>
        {timeline.map((item, idx) => (
          <div
            key={idx}
            className={classnames(
              styles.timelineItem,
              item.isCurrent && styles.current
            )}
          >
            <div className={styles.timelineDate}>
              <ValueFormat
                value={item.date?.value}
                format={item.date?.format}
              />
            </div>
            <div className={styles.timelineStatus}>
              <div>{item.status}</div>
              <div className={styles.timelineStatusLabel}>
                <ValueFormat
                  value={item.statusLabel?.value}
                  format={item.statusLabel?.format}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WorkflowActivity;
