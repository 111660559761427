import React from 'react';
import {useTranslation} from 'react-i18next';
import {StyleVariant, TagItem} from '../../../types';
import {Tag} from '../../global';
import styles from './DocumentExplorerSelectedTags.module.scss';

interface DocumentExplorerSelectedTagsProps {
  tags: TagItem[];
}
const DocumentExplorerSelectedTags: React.FC<
  DocumentExplorerSelectedTagsProps
> = ({tags}) => {
  const {t} = useTranslation();
  if (!tags.length) return null;
  return (
    <div className={styles.wrapper}>
      <div className={styles.label}>
        {t('Documents.DocumentExplorerSelectedTags.Label')}
      </div>
      <div className={styles.list}>
        {tags.map((tag, idx) => (
          <Tag key={idx} {...tag} variant={StyleVariant.Secondary} />
        ))}
      </div>
    </div>
  );
};

export default DocumentExplorerSelectedTags;
