import {put, all, takeLatest} from 'redux-saga/effects';
import {clearClientAdminDataCollectionDataAction} from '../../actions';
import {clearClientAdminTemplateConfigData} from '../../reducers/clientAdminTemplateConfigSlice';
import {clearClientAdminTemplateConfigDetailsData} from '../../reducers/clientAdminTemplateConfigDetailsSlice';
import {clearClientAdminWorkflowConfigData} from '../../reducers/clientAdminWorkflowConfigSlice';
import {clearClientAdminPortfolioCompanyUsersData} from '../../reducers/clientAdminPortfolioCompanyUsersSlice';

export const clearClientAdminDataCollectionDataSaga =
  function* clearClientAdminDataCollectionDataSaga() {
    yield all([
      put(clearClientAdminTemplateConfigData()),
      put(clearClientAdminTemplateConfigDetailsData()),
      put(clearClientAdminWorkflowConfigData()),
      put(clearClientAdminPortfolioCompanyUsersData()),
    ]);
  };

export const clientAdminDataCollectionSagaWatcher =
  function* clientAdminDataCollectionSagaWatcher() {
    yield all([
      takeLatest(
        clearClientAdminDataCollectionDataAction.type,
        clearClientAdminDataCollectionDataSaga
      ),
    ]);
  };
