import {FlexHAlign, HeaderMapping, CompanyUserRow} from '../../../../types';
import {ColumnDef, createColumnHelper} from '@tanstack/react-table';
import Th from '../cells/th/Th';
import Td from '../cells/td/Td';
import React from 'react';
import ExpanderCell from '../cells/expander-cell/ExpanderCell';
import StatusCell from '../cells/status-cell/StatusCell';
import NameCell from '../cells/name-cell/NameCell';
import styles from './PCUsersColumnDefs.module.scss';

const genPCUsersTableColumnDefs = (
  headerMapping: HeaderMapping[]
): ColumnDef<CompanyUserRow, any>[] => {
  const columnHelper = createColumnHelper<CompanyUserRow>();

  return [
    columnHelper.accessor('portfolioCompany', {
      id: 'portfolioCompany',
      header: props => (
        <Th {...props} headerMapping={headerMapping} align={FlexHAlign.start} />
      ),
      cell: props => {
        const isExpanded = props.row.getIsExpanded();
        const portfolioCompany = props.getValue<string>();
        const fullName = `${props.row.original.firstName || ''} ${
          props.row.original.lastName || ''
        }`;

        return (
          <Td {...props} align={FlexHAlign.start} canHide={false}>
            <NameCell
              name={!isExpanded ? portfolioCompany : fullName}
              expanded={isExpanded}
              className={styles.cell}
              emptyValue={'-'}
            />
          </Td>
        );
      },
    }),
    columnHelper.accessor('firstName', {
      id: 'firstName',
      header: props => (
        <Th {...props} headerMapping={headerMapping} align={FlexHAlign.start} />
      ),
      cell: props => (
        <Td
          className={styles.cell}
          {...props}
          align={FlexHAlign.start}
          emptyValue={'-'}
        />
      ),
    }),
    columnHelper.accessor('lastName', {
      id: 'lastName',
      header: props => (
        <Th {...props} headerMapping={headerMapping} align={FlexHAlign.start} />
      ),
      cell: props => (
        <Td
          className={styles.cell}
          {...props}
          align={FlexHAlign.start}
          emptyValue={'-'}
        />
      ),
    }),
    columnHelper.accessor('email', {
      id: 'email',
      header: props => (
        <Th {...props} headerMapping={headerMapping} align={FlexHAlign.start} />
      ),
      cell: props => (
        <Td className={styles.cell} {...props} align={FlexHAlign.start} />
      ),
    }),
    columnHelper.accessor(row => row.persona?.label, {
      id: 'persona',
      header: props => (
        <Th {...props} headerMapping={headerMapping} align={FlexHAlign.start} />
      ),
      cell: props => (
        <Td
          className={styles.cell}
          {...props}
          align={FlexHAlign.start}
          nestedAccessorFn={original => ({value: original.persona?.label})}
          emptyValue={'-'}
        />
      ),
    }),
    columnHelper.accessor('job', {
      id: 'job',
      header: props => (
        <Th {...props} headerMapping={headerMapping} align={FlexHAlign.start} />
      ),
      cell: props => (
        <Td
          className={styles.cell}
          {...props}
          align={FlexHAlign.start}
          emptyValue={'-'}
        />
      ),
    }),
    columnHelper.accessor('status', {
      id: 'status',
      header: props => (
        <Th {...props} headerMapping={headerMapping} align={FlexHAlign.start} />
      ),
      cell: props => {
        return (
          <Td {...props} align={FlexHAlign.start}>
            <StatusCell className={styles.cell} status={props.getValue()} />
          </Td>
        );
      },
    }),
    columnHelper.accessor(row => row.lastLogin?.value, {
      id: 'lastLogin',
      header: props => (
        <Th {...props} headerMapping={headerMapping} align={FlexHAlign.start} />
      ),
      cell: props => {
        return (
          <Td
            className={styles.cell}
            {...props}
            align={FlexHAlign.start}
            nestedAccessorFn={original => original.lastLogin}
            emptyValue={'-'}
          />
        );
      },
    }),
    columnHelper.display({
      id: 'expander',
      header: props => (
        <Th
          {...props}
          align={FlexHAlign.center}
          width={'40px'}
          headerMapping={headerMapping}
        />
      ),
      cell: props => {
        const row = props.row;
        const isExpanded = row.getIsExpanded();

        return row.getCanExpand() ? (
          <Td {...props} align={FlexHAlign.center} canHide={false}>
            <ExpanderCell expanded={isExpanded} />
          </Td>
        ) : null;
      },
    }),
  ];
};

export default genPCUsersTableColumnDefs;
