import React, {useEffect, useMemo, useRef} from 'react';
import classnames from 'classnames';
import {formatWithLocale} from '../../../../../utils';
import EventPoint, {LabelPosition} from '../event-point/EventPoint';
import {Func, TimelineChartData, StyleVariant} from '../../../../../types';
import styles from './YearLine.module.scss';
import {useTranslation} from 'react-i18next';

export interface EventData extends TimelineChartData {
  labelPosition: LabelPosition;
}

interface YearLineProps {
  time: number;
  label: string;
  scaled: boolean;
  events: EventData[];
  isExpanded: boolean;
  today: Date;
  setFirstEventRef: Func<[HTMLDivElement], void>;
  setTodayPointRef: Func<[HTMLDivElement], void>;
  onHoverEvent: Func<[string | number, HTMLDivElement | null], void>;
  variant: StyleVariant;
}

const YearLine: React.FC<YearLineProps> = ({
  time,
  label,
  scaled,
  events,
  isExpanded,
  today,
  setFirstEventRef,
  setTodayPointRef,
  onHoverEvent,
  variant,
}) => {
  const {t} = useTranslation();
  const todayRef = useRef<HTMLDivElement>(null);
  const {from, to, isToday} = useMemo(() => {
    const from = new Date(`${time}`).getTime();
    const to = new Date(`${time + 1}`).getTime();
    const isToday = today.getTime() > from && today.getTime() < to;
    return {
      today,
      from,
      to,
      isToday,
    };
  }, [time, today]);

  useEffect(() => {
    if (isToday && todayRef.current) {
      setTodayPointRef(todayRef.current);
    }
  }, [isToday, today, setTodayPointRef, todayRef]);

  return (
    <div className={classnames(styles.wrapper, scaled && styles.scaled)}>
      <span className={styles.label}>{label}</span>
      {scaled && (
        <>
          {Array.from('1'.repeat(3)).map((_, i) => (
            <div
              key={i}
              className={styles.quarterPoint}
              style={{left: 25 * (i + 1) + '%'}}
            />
          ))}
        </>
      )}
      {isToday && (
        <div
          ref={todayRef}
          className={classnames(
            styles.todayPoint,
            isExpanded && styles.expanded,
            styles[variant]
          )}
          style={{
            right: ((to - today.getTime()) / (to - from)) * 100 + '%',
          }}
          data-test={'today-point'}
        >
          <span>
            {t('Global.Today')}, <br />{' '}
            {formatWithLocale(new Date(today), 'dd-MM-yyyy')}
          </span>
        </div>
      )}
      {events.map((item, idx) => (
        <EventPoint
          onHoverEvent={onHoverEvent}
          setFirstEventRef={setFirstEventRef}
          isFirstEvent={idx === 0}
          isExpanded={isExpanded}
          key={idx}
          {...item}
          currentYear={{to, from}}
        />
      ))}
    </div>
  );
};

export default YearLine;
