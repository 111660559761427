import {
  ButtonStyle,
  DataVisualisaitonChartType,
  GlobalInformationActionType,
  GlobalInformationProps,
  ModalHandlerReduxActions,
} from '../types';
import i18n from '../i18n';
import routePaths from '../routePaths';

export const consolidatedCompanyColor = 'lan-green';

export const saveChartConfirmModal = (
  chartType: DataVisualisaitonChartType
): GlobalInformationProps => ({
  type: 'info',
  message: i18n.t(
    'PortfolioCompanies.SectionHeader.SyncChartConfirmationModal.Content'
  ),
  icon: 'add-chart',
  isDismissable: true,
  actions: [
    {
      text: i18n.t('Global.Yes'),
      style: ButtonStyle.Secondary,
      action: GlobalInformationActionType.ReduxAction,
      callback: {
        fnKey: ModalHandlerReduxActions.PortfolioCompaniesSaveFilterConfig,
        arguments: chartType,
      },
      themeOverride: 'lightThemePrimaryButton',
    },
    {
      text: i18n.t('Global.Cancel'),
      style: ButtonStyle.Primary,
      action: GlobalInformationActionType.Dismiss,
      themeOverride: 'lightThemeSecondaryButton',
    },
  ],
});

export const saveChartCompleteModal = (
  chartType: DataVisualisaitonChartType
): GlobalInformationProps => {
  const content = saveChartConfirmModal(chartType);

  return {
    ...content,
    message: i18n.t(
      'PortfolioCompanies.SectionHeader.SyncChartCompleteModal.Content'
    ),
    actions: [
      {
        ...(content.actions![0] || {}),
        text: i18n.t(
          'PortfolioCompanies.SectionHeader.SyncChartCompleteModal.ViewAction'
        ),
        action: GlobalInformationActionType.Redirect,
        callback: {
          fnKey: undefined,
          arguments: routePaths.HOME,
        },
      },
      {
        ...(content.actions![1] || {}),
      },
    ],
  };
};
