import React, {useRef} from 'react';
import classnames from 'classnames';
import styles from './TitleCell.module.scss';
import {useTooltip} from '../../../../../hooks';
import {Position} from '../../../../../context/TooltipContext';

type TitleCellProps = {
  title: string;
  externalResource: string;
  summary: string;
};
const TitleCell: React.FC<TitleCellProps> = ({
  title,
  externalResource,
  summary,
}) => {
  const {showTooltip, hideTooltip} = useTooltip();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const link =
    externalResource.startsWith('https://') ||
    externalResource.startsWith('http://')
      ? externalResource
      : `https://${externalResource}`;
  const handleShowTooltip = () => {
    if (!wrapperRef.current) return;
    showTooltip({
      content: summary,
      target: wrapperRef.current,
      position: Position.Top,
      className: classnames(styles.tooltip, styles.wrapperTooltip),
      hasArrow: false,
    });
  };

  return (
    <div
      className={styles.wrapper}
      ref={wrapperRef}
      onMouseEnter={handleShowTooltip}
      onMouseLeave={hideTooltip}
    >
      <a
        href={link}
        target={'_blank'}
        className={styles.link}
        rel="noreferrer"
        onClick={event => event.stopPropagation()}
      >
        {title}
      </a>
    </div>
  );
};

export default TitleCell;
