import React from 'react';
import FundExposureChartSkeleton from '../fund-exposure-chart/FundExposureChartSkeleton';
import styles from './FundExposureWidget.module.scss';

interface FundExposureWidgetSkeletonProps {
  sumOfSkeletons?: number;
}

const FundExposureWidgetSkeleton: React.FC<FundExposureWidgetSkeletonProps> = ({
  sumOfSkeletons = 2,
}) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.charts}>
        {Array.from(Array(sumOfSkeletons)).map((_, index) => (
          <div className={styles.chartsItems} key={index}>
            <FundExposureChartSkeleton />
          </div>
        ))}
      </div>
    </div>
  );
};

export default FundExposureWidgetSkeleton;
