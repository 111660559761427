import React, {useMemo, useState} from 'react';
import {
  ButtonStyle,
  Func,
  InvestedFundTransactionData,
  InvestmentsTransactionsRow,
  SectionTag,
  TransactionsData,
} from '../../../types';
import {SectionHeader} from '../../dashboard';
import TransactionsTable from '../../global/table/transactions-table/TransactionsTable';
import genFundTransactionsTableColumnDefs from '../../global/table/transactions-table/funds/FundTransactionsColumnDefs';
import styles from './TransactionsTableDashboard.module.scss';
import {IconButton, SelectDropdown, Toggle} from '../../global';
import {useAppDispatch, useAppSelector} from '../../../hooks/useReduxHooks';
import {extractUploadDocumentsParamsFromUrl} from '../../../utils';
import {
  getUploadDocumentsDataAction,
  setIsUploadDocumentsModalOpen,
} from '../../../reducers/uploadDocumentsSlice';
import {useNavInfo} from '../../../hooks';
import {
  setCompanyInvestmentOverviewSelectedTransaction,
  setCompanyInvestmentOverviewSelectedTransactions,
} from '../../../reducers/companyDetailsInvestmentOverview';
import {companyInvestmentOverviewTransactionsDefaultSorting} from '../../../constants/companyInvestmentOverview';
import {useTranslation} from 'react-i18next';
import {Row} from '@tanstack/react-table';

const ALL_FUNDS = 'all-funds';

interface TransactionsTableDashboardProps {
  id: string;
  transactionsDashboardData: TransactionsData;
  exportHandler: Func<[void], void>;
  exportInProgress?: boolean;
  filterTransactionsData: Func<[string?], void>;
  transactionsFilteringInProgress: boolean;
  isTransactionCurrency: boolean;
  setIsTransactionCurrency: Func<[boolean], void>;
  signOffDate?: string | null;
}
const TransactionsTableDashboard: React.FC<TransactionsTableDashboardProps> = ({
  id,
  transactionsDashboardData,
  exportHandler,
  exportInProgress,
  filterTransactionsData,
  transactionsFilteringInProgress,
  isTransactionCurrency,
  setIsTransactionCurrency,
  signOffDate,
}) => {
  const {
    label,
    data,
    dataLocal,
    fundsInvested,
    headerMapping,
    footerMapping,
    hasDataLocal,
    footerMappingDataLocal,
  } = transactionsDashboardData;
  const {t} = useTranslation();
  const [filterValue, setFilterValue] = useState(ALL_FUNDS);
  const [allCheckboxState, setAllCheckboxState] = useState<{
    checked: boolean;
    isIndeterminate: boolean;
  }>({checked: false, isIndeterminate: false});

  const {selectedTransactions} = useAppSelector(
    state => state.companyDetails.companyInvestmentOverview
  );

  const {releaseDocumentRepositoryEnabled} = useAppSelector(
    state => state.featureFlags.data
  );

  const companyInvestmentOverviewTransactionsTableColumnVisibility = useMemo(
    () => ({
      isSelected: releaseDocumentRepositoryEnabled,
      file: releaseDocumentRepositoryEnabled,
      upload: releaseDocumentRepositoryEnabled,
    }),
    [releaseDocumentRepositoryEnabled]
  );

  const dispatch = useAppDispatch();

  const navInfoData = useNavInfo();

  const handleTransactionSelection = (
    checked: boolean,
    transaction?: InvestedFundTransactionData
  ) => {
    let payload = [];
    if (checked) {
      payload = transaction
        ? [...selectedTransactions, transaction]
        : [...data];
      dispatch(setCompanyInvestmentOverviewSelectedTransactions(payload));
    } else {
      payload = transaction
        ? selectedTransactions.filter(item => item.id !== transaction.id)
        : [];
      dispatch(setCompanyInvestmentOverviewSelectedTransactions(payload));
    }

    const selectedCount = payload.length;
    const totalTransactions = data.length;

    if (selectedCount === totalTransactions) {
      setAllCheckboxState({checked: true, isIndeterminate: false});
    } else if (selectedCount === 0) {
      setAllCheckboxState({checked: false, isIndeterminate: false});
    } else {
      setAllCheckboxState({checked: true, isIndeterminate: true});
    }
  };

  const handleOpenUploadDocumentsModal = (
    transaction: InvestedFundTransactionData
  ) => {
    const uploadDocumentParams = extractUploadDocumentsParamsFromUrl(
      navInfoData.sectionTag
    );
    dispatch(setCompanyInvestmentOverviewSelectedTransaction(transaction));
    dispatch(setIsUploadDocumentsModalOpen(true));
    dispatch(
      getUploadDocumentsDataAction({
        ...uploadDocumentParams,
        sections: [
          SectionTag.FundPortfolioTransactions,
          SectionTag.PortfolioCompanyInvestmentOverview,
        ],
        transactions: [transaction.id],
        columnIds: null,
      })
    );
  };

  const handleChangeFilter = (value: string) => {
    setIsTransactionCurrency(false);
    setFilterValue(value);
    if (value === ALL_FUNDS) {
      filterTransactionsData();
      return;
    }
    filterTransactionsData(value);
  };

  const handleTransactionCurrencyToggle = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(setCompanyInvestmentOverviewSelectedTransactions([]));
    setAllCheckboxState({checked: false, isIndeterminate: false});
    setIsTransactionCurrency(event.target.checked);
  };

  const fundsOptions = useMemo(() => {
    const options = fundsInvested.map(option => {
      return {id: option.id, label: option.name, value: option.id};
    });

    return [
      {
        id: 'show-all',
        label: t('TransactionsTableDashboard.SelectDropdown.OptionLabel'),
        value: ALL_FUNDS,
      },
      ...options,
    ];
  }, [fundsInvested]);

  const getRowClassNames = (row: Row<InvestmentsTransactionsRow>): string => {
    if (!signOffDate || !row.original.date) return '';
    if (
      new Date(signOffDate).getTime() >=
      new Date(row.original.date.value).getTime()
    )
      return '';
    return styles.liveDataTableRow;
  };

  return (
    <div id={id} className={styles.wrapper}>
      <SectionHeader
        label={label}
        labelType={'large'}
        onClick={() => null}
        withActionButton={false}
        className={styles.sectionHeader}
        childrenRight={[
          <div className={styles.transactionsSectionRight}>
            {fundsInvested.length > 1 && (
              <SelectDropdown
                id={`${id}-select-dropdown-funds-options`}
                onChange={value => handleChangeFilter(value as string)}
                options={fundsOptions}
                value={filterValue}
                disabled={transactionsFilteringInProgress}
                loading={transactionsFilteringInProgress}
              />
            )}
            <Toggle
              checked={isTransactionCurrency}
              label={t('Toggle.ShowInTransactionCurrency')}
              onChange={handleTransactionCurrencyToggle}
              disabled={!hasDataLocal || transactionsFilteringInProgress}
            />
            <IconButton
              styleType={ButtonStyle.Primary}
              icon="export"
              onClick={exportHandler}
              loading={exportInProgress}
            />
          </div>,
        ]}
      />
      <TransactionsTable<InvestmentsTransactionsRow>
        data={isTransactionCurrency ? dataLocal : data}
        columns={genFundTransactionsTableColumnDefs(
          headerMapping,
          isTransactionCurrency ? footerMappingDataLocal : footerMapping,
          selectedTransactions,
          allCheckboxState,
          handleTransactionSelection,
          handleOpenUploadDocumentsModal
        )}
        meta={{
          getRowClassNames,
        }}
        expandedView={() => <></>}
        getRowCanExpand={() => true}
        scrollableFromWidth={'890px'}
        defaultSorting={companyInvestmentOverviewTransactionsDefaultSorting}
        columnVisibility={
          companyInvestmentOverviewTransactionsTableColumnVisibility
        }
      />
    </div>
  );
};

export default TransactionsTableDashboard;
