import {put, all, takeLatest} from 'redux-saga/effects';
import {clearClientAdminUserDataAction} from '../../actions';
import {clearClientAdminUsersData} from '../../reducers/clientAdminUsersSlice';

export const clearClientAdminUserDataSaga =
  function* clearClientAdminUserDataSaga() {
    yield all([put(clearClientAdminUsersData())]);
  };

export const clientAdminUserSagaWatcher =
  function* clientAdminUserSagaWatcher() {
    yield all([
      takeLatest(
        clearClientAdminUserDataAction.type,
        clearClientAdminUserDataSaga
      ),
    ]);
  };
