import React from 'react';
import {HeaderMapping, FlexHAlign, ButtonSize} from '../../../../../types';
import {ColumnDef, createColumnHelper} from '@tanstack/react-table';
import Th from '../../cells/th/Th';
import Td from '../../cells/td/Td';
import IconButton from '../../../icon-button/IconButton';
import styles from './index.module.scss';

const otherColumns = (
  headerMapping: HeaderMapping[],
  handleEdit: (transaction: any) => void,
  handleDelete: (id?: string) => void,
  fundCurrency: string
): ColumnDef<any, any>[] => {
  const columnHelper = createColumnHelper<any>();

  return [
    columnHelper.accessor('description', {
      id: 'description',
      header: props => (
        <Th {...props} headerMapping={headerMapping} align={FlexHAlign.start} />
      ),
      cell: props => <Td {...props} align={FlexHAlign.start} />,
    }),
    columnHelper.accessor('currency', {
      id: 'currency',
      header: props => (
        <Th {...props} headerMapping={headerMapping} align={FlexHAlign.start} />
      ),
      cell: props => (
        <Td {...props} align={FlexHAlign.start}>
          {fundCurrency}
        </Td>
      ),
    }),
    columnHelper.accessor('date', {
      id: 'date',
      header: props => (
        <Th {...props} headerMapping={headerMapping} align={FlexHAlign.start} />
      ),
      cell: props => <Td {...props} align={FlexHAlign.start} />,
    }),
    columnHelper.accessor('value', {
      id: 'value',
      header: props => (
        <Th {...props} headerMapping={headerMapping} align={FlexHAlign.start} />
      ),
      cell: props => <Td {...props} align={FlexHAlign.start} />,
    }),
    columnHelper.accessor('comment', {
      id: 'comment',
      header: props => (
        <Th {...props} headerMapping={headerMapping} align={FlexHAlign.start} />
      ),
      cell: props => <Td {...props} align={FlexHAlign.start} />,
    }),
    columnHelper.display({
      id: 'edit',
      header: props => (
        <Th
          {...props}
          align={FlexHAlign.center}
          width={'40px'}
          headerMapping={headerMapping}
        />
      ),
      cell: props => (
        <Td {...props} align={FlexHAlign.end}>
          {props.row.original.isCasting ? (
            <>
              <IconButton
                buttonSize={ButtonSize.Small}
                onClick={() => handleEdit(props.row.original)}
                icon="pencil"
                className={styles.actionButton}
              />
              <IconButton
                buttonSize={ButtonSize.Small}
                onClick={() => handleDelete(props.row.original.id)}
                icon="cross"
                className={styles.actionButton}
              />
            </>
          ) : null}
        </Td>
      ),
    }),
  ];
};

export default otherColumns;
