import React, {ReactElement, useState} from 'react';
import {SectionHeader} from '../../dashboard';
import {IconButton, Tabs} from '../../global';
import {portfolioInvestmentsTabs} from '../../../constants/tabs';
import styles from './PortfolioInvestmentsDashboard.module.scss';
import {Func, ButtonStyle, StyleVariant, Tab} from '../../../types';

type PortfolioInvestmentsDashboardProps = {
  label: string;
  investments: {
    active: ReactElement;
    exited: ReactElement;
    writtenOff: ReactElement;
  };
  isFundCurrency: boolean;
  setIsFundCurrency: Function;
  exportHandler?: Func<[void], void>;
  exportInProgress?: boolean;
};

const PortfolioInvestmentsDashboard = ({
  label,
  investments,
  isFundCurrency,
  setIsFundCurrency,
  exportHandler = () => null,
  exportInProgress,
}: PortfolioInvestmentsDashboardProps): JSX.Element => {
  const [activeTab, setActiveTab] = useState<Tab>(portfolioInvestmentsTabs[0]);

  return (
    <div>
      <SectionHeader
        label={label}
        labelType={'large'}
        onClick={(): void => {}}
        withActionButton={false}
        className={styles.investmentsSectionHeader}
      />
      <div className={styles.subsection}>
        <Tabs
          tabs={portfolioInvestmentsTabs}
          activeTab={activeTab}
          clickHandler={tab => setActiveTab(tab)}
          tabVariant={StyleVariant.Secondary}
        />

        <div className={styles.buttonsWrapper}>
          <IconButton
            onClick={exportHandler}
            styleType={ButtonStyle.Primary}
            icon="export"
            loading={exportInProgress}
          />
        </div>
      </div>
      {/*  ActiveInvestmentsTable */}
      {activeTab.label === 'Global.Active' && investments.active}
      {/* ExitedInvestmentsTable */}
      {activeTab.label === 'Global.Exited' && investments.exited}
      {/* WrittenOffInvestmentsTable */}
      {activeTab.label === 'Global.WrittenOff' && investments.writtenOff}
    </div>
  );
};

export default PortfolioInvestmentsDashboard;
