import axios from 'axios';
import config from '../config';
import {FundPerformanceWidgetProps, InfoHeaderType} from '../types/index';

export interface FundListPerformanceResponse {
  type: 'FundListPerformanceResponse';
  data: FundPerformanceWidgetProps;
  headerData: InfoHeaderType[];
}

export const getFundPerformanceData = async (token: string, id: string) => {
  const {data} = await axios.get<FundListPerformanceResponse>(
    `${config.APP_URI}/fund-lists/performance/${id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );
  return data;
};
