import {createAction, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {
  ActiveCompanyData,
  ExitedCompanyData,
  FundPortfolio,
  PortfolioPerformanceWidgetProps,
} from '../types/index';

export const getInvestmentPerformanceDataAction = createAction(
  'fund-details-portfolio/get-investment-performance-data',
  (
    companyId: string,
    companyType: string,
    isFundCurrency: boolean,
    investmentGroupId: string,
    currency: string
  ) => {
    return {
      payload: {
        companyId,
        companyType,
        isFundCurrency,
        investmentGroupId,
        currency,
      },
    };
  }
);

interface FundDetailsPortfolioData {
  data: FundPortfolio | null;
}

const initialState: FundDetailsPortfolioData = {
  data: null,
};

const fundDetailsPortfolioSlice = createSlice({
  name: 'fund-details-portfolio',
  initialState,
  reducers: {
    setFundPortfolioData(
      state: FundDetailsPortfolioData,
      action: PayloadAction<FundPortfolio>
    ) {
      state.data = action.payload;
    },
    clearFundPortfolioData(state: FundDetailsPortfolioData) {
      state.data = null;
    },
    setInvestmentPerformanceData(
      state: FundDetailsPortfolioData,
      action: PayloadAction<{
        performanceData: PortfolioPerformanceWidgetProps;
        investmentGroupId: string;
        companyType: 'active' | 'exited';
        isFundCurrency: boolean;
        currency: string;
      }>
    ) {
      const {
        performanceData,
        investmentGroupId,
        companyType = 'active',
        isFundCurrency,
        currency,
      } = action.payload;

      if (state.data) {
        const data = isFundCurrency ? 'data' : 'dataLocal';
        const updatedInvestmentData = state.data.investmentData[companyType][
          data
        ].map(item =>
          item.investmentGroupId === investmentGroupId &&
          item.currency === currency
            ? {
                ...item,
                performanceData,
              }
            : item
        ) as ActiveCompanyData[] | ExitedCompanyData[];
        state.data.investmentData[companyType][data] = updatedInvestmentData;
      }
    },
  },
});

export const {
  setFundPortfolioData,
  clearFundPortfolioData,
  setInvestmentPerformanceData,
} = fundDetailsPortfolioSlice.actions;

export default fundDetailsPortfolioSlice.reducer;
