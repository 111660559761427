import React, {useState} from 'react';
import {Button, Modal} from '../..';
import styles from './FileAlreadyExistsModal.module.scss';
import {
  ButtonStyle,
  FileAlreadyExistsModalProps,
  ExportDropdownOption,
  FileAlreadyExist,
} from '../../../../types';
import RadioInput from '../../radio-input/RadioInput';
import {useTranslation} from 'react-i18next';

const FileAlreadyExistsModal: React.FC<FileAlreadyExistsModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
  title,
  isLoading = false,
  fileName,
}) => {
  const {t} = useTranslation();

  const options: ExportDropdownOption[] = [
    {
      label: t('FileAlreadyExistsModal.RadioInput.Replace'),
      value: FileAlreadyExist.ReplaceFile,
    },
    {
      label: t('FileAlreadyExistsModal.RadioInput.Keep'),
      value: FileAlreadyExist.KeepBoth,
    },
  ];

  const [selectedOption, setSelectedOption] = useState(options[1]);
  const handleChange = (value: ExportDropdownOption) => {
    setSelectedOption(value);
  };

  return (
    <Modal
      title={title}
      onClose={onClose}
      isOpen={isOpen}
      className={styles.modalWrapper}
      zIndex={110}
    >
      <div className={styles.wrapper}>
        <div className={styles.message}>
          <span className={styles.fileName}>'{fileName}'</span>
          {t('FileAlreadyExistsModal.Title')}
        </div>
        <div className={styles.radioButtons}>
          {options.map(option => (
            <RadioInput
              option={option}
              checked={selectedOption.value === option.value}
              handleChange={handleChange}
              key={option.value}
            />
          ))}
        </div>
        <div className={styles.footerButtons}>
          <Button
            styleType={ButtonStyle.Primary}
            text={t('Global.Upload')}
            onClick={() => onConfirm(selectedOption.value)}
            className={styles.lightThemePrimaryButton}
            loading={isLoading}
          />
          <Button
            onClick={onClose}
            text={t('Global.Cancel')}
            styleType={ButtonStyle.Secondary}
            className={styles.lightThemeSecondaryButton}
            disabled={isLoading}
          />
        </div>
      </div>
    </Modal>
  );
};

export default FileAlreadyExistsModal;
