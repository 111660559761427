import React from 'react';
import classnames from 'classnames';
import styles from './ReportingTableGroupSubHeaderCell.module.scss';
import {ReportingTableCell} from '../../../../../types/reportingTable';

type ReportingTableSubHeaderCellProps = {
  cell: ReportingTableCell;
  classNames?: string;
  verticalAlign?: 'top' | 'middle' | 'bottom';
  textAlign?: 'left' | 'center' | 'right';
};

const ReportingTableSubHeaderCell = ({
  cell,
  classNames,
  verticalAlign = 'middle',
  textAlign = 'center',
}: ReportingTableSubHeaderCellProps) => {
  return (
    <th
      id="blank"
      headers="blank"
      style={{textAlign, verticalAlign}}
      className={classnames(styles.cell, {
        [`${classNames}`]: !!classNames,
        [styles.notVisible]: !cell.isVisible,
      })}
    >
      <span className={styles.tableHeader}>{cell.data?.value}</span>
    </th>
  );
};

export default ReportingTableSubHeaderCell;
