import React from 'react';
import {Row} from '@tanstack/react-table';
import {FundsRow, StyleVariant} from '../../../../types/index';
import {Loader} from '../../index';
import FundPerformanceWidget from '../../../fund-overview/fund-performance-widget/FundPerformanceWidget';
import InfoHeader from '../../info-header/InfoHeader';
import styles from './FundsTableExpandedRow.module.scss';

export type FundsTableExpandedRowProps<TData> = {
  row: Row<TData>;
};

const FundsTableExpandedRow = ({
  row,
}: FundsTableExpandedRowProps<FundsRow>): JSX.Element => {
  return (
    <div className={styles.wrapper}>
      {row.original.fundPerformanceData ? (
        <div className={styles.content}>
          <InfoHeader
            styleType={StyleVariant.Secondary}
            data={row.original.fundPerformanceData.headerData || []}
          />
          <FundPerformanceWidget
            {...row.original.fundPerformanceData}
            signOffDate={row.original.signOffDate}
            styleType={StyleVariant.Secondary}
            id="fund-performance-row"
          />
        </div>
      ) : (
        <Loader loaderType={'funds'} />
      )}
    </div>
  );
};

export default FundsTableExpandedRow;
