import React from 'react';
import {Func} from '../../../../../types';
import styles from './ScrollContainer.module.scss';

interface ScrollContainerProps {
  children: JSX.Element;
  scrollableFromWidth?: string;
  onScroll: Func<[React.UIEvent], void>;
}

const ScrollContainer = React.forwardRef<HTMLDivElement, ScrollContainerProps>(
  ({children, onScroll, scrollableFromWidth}, ref) => {
    if (!scrollableFromWidth) {
      return children;
    }

    return (
      <div className={styles.scrollContainer} ref={ref} onScroll={onScroll}>
        <div
          className={styles.inner}
          style={{
            width: scrollableFromWidth
              ? `max(100%, ${scrollableFromWidth})`
              : '100%',
          }}
        >
          {children}
        </div>
      </div>
    );
  }
);

export default ScrollContainer;
