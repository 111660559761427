import React from 'react';
import FundPerformanceWidget from '../fund-performance-widget/FundPerformanceWidget';
import {
  FundPerformanceData,
  Func,
  FundPerformanceKpiGroups,
} from '../../../types/index';
import {SectionHeader} from '../../';
import styles from './FundPerformanceDashboard.module.scss';

interface FundPerformaceDataProps extends FundPerformanceData {
  getBenchmarkData?: Func<[string, string, FundPerformanceKpiGroups], void>;
  clearBenchmarkData?: Func<[FundPerformanceKpiGroups], void>;
}

const FundPerformanceDashboard: React.FC<FundPerformaceDataProps> = ({
  title,
  type,
  widgets,
  getBenchmarkData,
  clearBenchmarkData,
  signOffDate,
}) => {
  return (
    <div className={styles.wrapper}>
      <SectionHeader
        label={title}
        labelType={'large'}
        onClick={() => null}
        withActionButton={false}
        className={styles.performanceSectionHeader}
      />
      <div className={styles.widgets}>
        {widgets.map((widget, index) => (
          <div key={index + widget.type} className={styles.widgetItem}>
            <FundPerformanceWidget
              {...widget}
              signOffDate={signOffDate}
              getBenchmarkData={getBenchmarkData}
              clearBenchmarkData={clearBenchmarkData}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default FundPerformanceDashboard;
