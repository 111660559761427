import {
  InvestedFundTransactionData,
  PortfolioTransactionData,
  SectionTag,
  UploadDocumentsPageParams,
} from '../types';

export const extractUploadDocumentsParamsFromUrl = (
  sectionTag: string | null
): UploadDocumentsPageParams => {
  const url = new URL(window.location.href);

  // Get path segments
  const pathSegments = url.pathname.split('/').filter(segment => !!segment);
  // Get query parameters
  const searchParams = new URLSearchParams(url.search);

  const emptyPageParams = {
    fund: null,
    portfolioCompany: null,
    sections: null,
  };

  const getFundsPageParams = () => ({
    fund: pathSegments[1],
    portfolioCompany: null,
    sections: [sectionTag ?? ''],
  });

  const getPortfoliosPageParams = () => ({
    fund: null,
    portfolioCompany: pathSegments[1],
    sections: [sectionTag ?? ''],
  });

  const getDataCollectionPageParams = () => {
    // Get tags for data collection details page
    if (!!pathSegments[1]) {
      return {
        fund: null,
        portfolioCompany: null,
        sections: [
          SectionTag.DataCollectionDetails,
          SectionTag.DataCollectionReporting,
          SectionTag.ReportingPeriod,
        ],
      };
    }

    const defaultTag = SectionTag.DataCollectionActuals;

    const currentTab = searchParams.has('tab')
      ? (searchParams.get('tab') as string)
      : null;

    const segmentFromQuery =
      currentTab === 'esg' ? SectionTag.DataCollectionESG : defaultTag;

    return {
      fund: null,
      portfolioCompany: null,
      sections: [segmentFromQuery],
    };
  };

  const getReportingPageParams = () => {
    return {
      fund: null,
      portfolioCompany: null,
      sections: [
        SectionTag.DataCollectionDetails,
        SectionTag.DataCollectionReporting,
        SectionTag.ReportingPeriod,
      ],
    };
  };

  if (pathSegments.length) {
    switch (pathSegments[0]) {
      case 'portfolio':
        return getPortfoliosPageParams();
      case 'funds':
        return getFundsPageParams();
      case 'data-collection':
        return getDataCollectionPageParams();
      case 'reporting':
        return getReportingPageParams();
      default:
        return emptyPageParams;
    }
  }

  return emptyPageParams;
};

export const updateTransactionUploadedFileCount = (
  transactions: Array<InvestedFundTransactionData | PortfolioTransactionData>,
  transactionId: string,
  operation: 'add' | 'subtract'
) => {
  const transaction = transactions.find(
    transaction => transaction.id === transactionId
  );
  if (operation === 'add' && typeof transaction?.file === 'number') {
    transaction.file += 1;
  }
  if (
    operation === 'subtract' &&
    typeof transaction?.file === 'number' &&
    transaction.file > 0
  ) {
    transaction.file -= 1;
  }
};
