import {
  FeeAnalysisTableData,
  FeeAnalysisTableItem,
  HeaderMapping,
  ImagesExportPayload,
  TableExportPayload,
  TableSettings,
  TreemapData,
} from '../../../types';

const headerMappingFeeTypes = [
  {
    columnId: 'category',
    label: 'Fee Type',
  },
  {
    columnId: 'percent',
    label: 'Percent',
  },
  {
    columnId: 'grossAmount',
    label: 'Gross Amount',
  },
  {
    columnId: 'taxAmount',
    label: 'Tax Amount',
  },
  {
    columnId: 'netAmount',
    label: 'Net Amount',
  },
  {
    columnId: 'supplier',
    label: 'Supplier',
  },
];

const headerMappingSupplierFees = [
  {
    columnId: 'category',
    label: 'Supplier',
  },
  {
    columnId: 'percent',
    label: 'Percent',
  },
  {
    columnId: 'grossAmount',
    label: 'Gross Amount',
  },
  {
    columnId: 'taxAmount',
    label: 'Tax Amount',
  },
  {
    columnId: 'netAmount',
    label: 'Net Amount',
  },
  {
    columnId: 'feeType',
    label: 'Fee Type',
  },
];
const feeTypesColumnOrder = [
  'category',
  'percent',
  'grossAmount',
  'taxAmount',
  'netAmount',
  'supplier',
];
const suppliersColumnOrder = [
  'category',
  'percent',
  'grossAmount',
  'taxAmount',
  'netAmount',
  'feeType',
];

const feeTypesMeta = {
  columns: {
    category: {
      styling: {
        align: 'left',
      },
    },
    percent: {
      styling: {
        align: 'right',
      },
    },
    grossAmount: {
      styling: {
        align: 'right',
      },
    },
    taxAmount: {
      styling: {
        align: 'right',
      },
    },
    netAmount: {
      styling: {
        align: 'right',
      },
    },
    supplier: {
      styling: {
        align: 'right',
      },
    },
  },
};

const suppliersMeta = {
  columns: {
    category: {
      styling: {
        align: 'left',
      },
    },
    percent: {
      styling: {
        align: 'right',
      },
    },
    grossAmount: {
      styling: {
        align: 'right',
      },
    },
    taxAmount: {
      styling: {
        align: 'right',
      },
    },
    netAmount: {
      styling: {
        align: 'right',
      },
    },
    feeType: {
      styling: {
        align: 'right',
      },
    },
  },
};

export const treemapChartSettingsFeeTypes = (
  zipFileName: string
): TableSettings => {
  return {
    fileName: zipFileName,
    name: 'Fee Analysis Fee Type',
    columnVisibility: {},
    headerMapping: headerMappingFeeTypes,
    columnOrder: feeTypesColumnOrder,
    meta: feeTypesMeta,
  };
};

export const treemapChartSettingsSupplierFees = (
  zipFileName: string
): TableSettings => {
  return {
    fileName: zipFileName,
    name: 'Fee Analysis Supplier Fees',
    columnVisibility: {},
    headerMapping: headerMappingSupplierFees,
    columnOrder: suppliersColumnOrder,
    meta: suppliersMeta,
  };
};

const generateFeeAnalysisSupplierExportTableData = (
  data: FeeAnalysisTableData<FeeAnalysisTableItem>
) => {
  return data.data.reduce((acc: any[], cur) => {
    const mainRow = {
      category: cur.category,
      percent: cur.percent.value,
      grossAmount: cur.grossAmount.value,
      taxAmount: cur.taxAmount?.value || '',
      netAmount: cur.netAmount.value,
      feeType: '',
      supplier: '',
    };
    const subRows = cur.transactionData.data.map(item => ({
      category: '',
      percent: '',
      grossAmount: item.grossAmount.value,
      taxAmount: item.taxAmount?.value || '',
      netAmount: item.netAmount.value,
      feeType: item.category,
      supplier: item.category,
    }));
    return [...acc, mainRow, ...subRows];
  }, []);
};

export const getTreemapTableExportPayload = (
  tableData: FeeAnalysisTableData<FeeAnalysisTableItem>,
  isFeeTypeActive: boolean,
  zipFileName: string
): TableExportPayload[] => {
  const tableSettings = isFeeTypeActive
    ? treemapChartSettingsFeeTypes(zipFileName)
    : treemapChartSettingsSupplierFees(zipFileName);
  const data = generateFeeAnalysisSupplierExportTableData(tableData);

  return [
    {
      data,
      mappings: tableSettings.headerMapping as HeaderMapping[],
      settings: tableSettings,
    },
  ];
};

export const getTreemapImageExportPayload = (
  zipFileName: string
): ImagesExportPayload => {
  return {
    id: 'treemap-chart',
    fileName: zipFileName,
    opts: [
      {
        fileName: `${zipFileName}_TransparentBackground`,
        type: 'image/svg+xml',
        ext: 'svg',
        bgColor: 'transparent',
        elsToFilter: ['treemap-tooltip'],
      },
      {
        fileName: `${zipFileName}_WithBackground`,
        type: 'image/svg+xml',
        ext: 'svg',
        bgColor: 'black',
        elsToFilter: ['treemap-tooltip'],
      },
      {
        fileName: `${zipFileName}_TransparentBackground`,
        type: 'image/png',
        ext: 'png',
        bgColor: 'transparent',
        elsToFilter: ['treemap-tooltip'],
      },
      {
        fileName: `${zipFileName}_WithBackground`,
        type: 'image/png',
        ext: 'png',
        bgColor: 'black',
        elsToFilter: ['treemap-tooltip'],
      },
    ],
  };
};

export const generateTreemapDataForExport = (data: TreemapData[]) => {
  return data[0].children
    ? data[0].children?.map(item => {
        return {name: item.name, value: item.value};
      })
    : [];
};
