import {createAction, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {
  NearCastingCapitalTransactionPayload,
  FormStatus,
  NearCastingCapitalTransaction,
  NearCastingFeeTransaction,
  NearCastingOtherTransaction,
  NearCastingScenario,
  UploadSingleTransactionsFile,
  NearCastingFeeOrOtherTransactionPayload,
} from '../types';

export const getScenarioDetailsTransactionsAction = createAction(
  'scenario-details-transactions/get-scenario-transactions-capital-data',
  (companyId: string) => {
    return {
      payload: {
        companyId,
      },
    };
  }
);

export const postScenarioDetailsCapitalTransactionAction = createAction(
  'scenario-details-transactions/post-capital-transaction',
  (payload: NearCastingCapitalTransactionPayload) => ({payload})
);

export const editScenarioDetailsCapitalTransactionAction = createAction(
  'scenario-details-transactions/edit-capital-transaction',
  (payload: {
    transactionId: string;
    scenarioId: string;
    data: NearCastingCapitalTransactionPayload;
  }) => {
    return {
      payload,
    };
  }
);

export const editScenarioDetailsOtherAndFeeTransactionAction = createAction(
  'scenario-details-transactions/edit-other-and-fee-transaction',
  (payload: {
    transactionId: string;
    scenarioId: string;
    data: NearCastingFeeOrOtherTransactionPayload;
  }) => {
    return {
      payload,
    };
  }
);

export const postScenarioDetailsFeeTransactionAction = createAction(
  'scenario-details-transactions/post-fee-transaction',
  (payload: NearCastingFeeOrOtherTransactionPayload) => ({payload})
);

export const postScenarioDetailsOtherTransactionAction = createAction(
  'scenario-details-transactions/post-other-transaction',
  (payload: NearCastingFeeOrOtherTransactionPayload) => ({payload})
);

export const updateTransactionsDataOnSaveAction = createAction(
  'scenario-details-transactions/update-nc-data',
  (id: string) => ({payload: {id}})
);

export const saveAndCalculateTransactionAction = createAction(
  'scenario-details-transactions/save-and-calculate',
  (id: string) => {
    return {
      payload: {
        id,
      },
    };
  }
);

export const deleteTransactionInCapitalTransactionAction = createAction(
  'scenario-details-transactions/delete-capital-transaction',
  (payload: {id: string; scenarioId: string}) => {
    return {
      payload,
    };
  }
);

export const deleteTransactionInFeeAndOtherTransactionAction = createAction(
  'scenario-details-transactions/delete-other-and-fee-transaction',
  (payload: {id: string; scenarioId: string}) => {
    return {
      payload,
    };
  }
);

export const importFundCapitalTransactionsAction = createAction(
  'scenario-details-transactions/import-fund-capital-transactions',
  (scenarioId: string, file: UploadSingleTransactionsFile) => {
    return {
      payload: {
        scenarioId,
        file,
      },
    };
  }
);

export const importFundFeeTransactionsAction = createAction(
  'scenario-details-transactions/import-fund-fee-transactions',
  (scenarioId: string, file: UploadSingleTransactionsFile) => {
    return {
      payload: {
        scenarioId,
        file,
      },
    };
  }
);

export const importOtherTransactionsAction = createAction(
  'scenario-details-transactions/import-other-transactions',
  (scenarioId: string, file: UploadSingleTransactionsFile) => {
    return {
      payload: {
        scenarioId,
        file,
      },
    };
  }
);

interface ScenarioDetailsTransactionsData {
  data: NearCastingScenario | null;
  isLoading: boolean;
  transactionFormStatus: FormStatus;
}

const initialState: ScenarioDetailsTransactionsData = {
  data: null,
  isLoading: false,
  transactionFormStatus: FormStatus.Ready,
};

const scenarioDetailsTransactionsCapitalSlice = createSlice({
  name: 'scenario-details-transactions',
  initialState,
  reducers: {
    setScenarioTransactionsCapitalData(
      state: ScenarioDetailsTransactionsData,
      action: PayloadAction<NearCastingScenario>
    ) {
      state.data = action.payload;
    },
    clearScenarioDetailsTransactionsCapitalData(
      state: ScenarioDetailsTransactionsData
    ) {
      state.data = null;
    },
    setUpdatedScenarioDetailsTransactionsData(
      state: ScenarioDetailsTransactionsData,
      action: PayloadAction<NearCastingScenario>
    ) {
      state.data = action.payload;
    },
    setLoading(state: ScenarioDetailsTransactionsData) {
      state.isLoading = !state.isLoading;
    },
    setNewCapitalTransaction(
      state: ScenarioDetailsTransactionsData,
      action: PayloadAction<NearCastingCapitalTransaction>
    ) {
      const updatedState = {
        ...state.data,
        nearCastingData: {
          ...state.data?.nearCastingData,
          fundCapitalTransactions: [
            {...action.payload, isCasting: true},
            ...(state.data?.nearCastingData?.fundCapitalTransactions || []),
          ],
        },
      };
      state.data = updatedState as NearCastingScenario;
    },
    setNewFeeTransaction(
      state: ScenarioDetailsTransactionsData,
      action: PayloadAction<NearCastingFeeTransaction>
    ) {
      const updatedState = {
        ...state.data,
        nearCastingData: {
          ...state.data?.nearCastingData,
          feeTransactions: [
            {...action.payload, isCasting: true},
            ...(state.data?.nearCastingData?.feeTransactions || []),
          ],
        },
      };
      state.data = updatedState as NearCastingScenario;
    },
    setNewOtherTransaction(
      state: ScenarioDetailsTransactionsData,
      action: PayloadAction<NearCastingOtherTransaction>
    ) {
      const updatedState = {
        ...state.data,
        nearCastingData: {
          ...state.data?.nearCastingData,
          otherTransactions: [
            {...action.payload, isCasting: true},
            ...(state.data?.nearCastingData?.otherTransactions || []),
          ],
        },
      };
      state.data = updatedState as NearCastingScenario;
    },
  },
});

export const {
  setScenarioTransactionsCapitalData,
  setUpdatedScenarioDetailsTransactionsData,
  clearScenarioDetailsTransactionsCapitalData,
  setNewCapitalTransaction,
  setNewOtherTransaction,
  setNewFeeTransaction,
  setLoading,
} = scenarioDetailsTransactionsCapitalSlice.actions;

export default scenarioDetailsTransactionsCapitalSlice.reducer;
