import React, {useState} from 'react';
import {GenericTable} from '../../global/table';
import {GenericExpandedRow} from '../../../types';
import {useAppDispatch} from '../../../hooks/useReduxHooks';
import {editTableRowData} from '../../../reducers/scenarioDetailsValuationSlice';
import PrevNextButtons from '../../prevn-next-buttons/PrevNextButtons';
import otherColumns from '../../global/table/scenarios/transactions/OtherColumns';
import styles from './TransactionsOtherForm.module.scss';
import {
  deleteTransactionInFeeAndOtherTransactionAction,
  importOtherTransactionsAction,
} from '../../../reducers/scenarioDetailsTransactionsSlice';
import OtherForm from './OtherForm';
import {valueFormat} from '../../../utils';
import ImportScenarioTransactionsModal from '../../fund-near-casting/import-scenario-transactions-modal/ImportScenarioTransactionsModal';
import {useTranslation} from 'react-i18next';

interface RowDataProps {
  vendor: string;
  currency?: string;
  localCurrency?: string;
  date: string;
  amount: number;
  comment: string;
  scenarioId: string;
  id: string;
  isCasting?: boolean;
}

const columns = [
  {
    label: 'Description',
    columnId: 'description',
  },
  {
    label: 'Currency',
    columnId: 'currency',
  },
  {
    label: 'Date',
    columnId: 'date',
  },
  {
    label: 'Amount',
    columnId: 'value',
  },
  {
    label: 'Comment',
    columnId: 'comment',
  },
];

const TransactionsOtherForm = ({
  tableData,
  handleAdd,
  handleEdit,
  minDate,
  maxDate,
  scenarioId,
  fundCurrency,
}: any) => {
  const {t} = useTranslation();
  const [isAdding, setIsAdding] = useState(false);
  const [editedTransaction, setEditedTransaction] = useState({});
  const [isShowTransactionForm, setIsShowTransactionForm] = useState(false);
  const [showFileUploadPopup, setShowFileUploadPopup] = useState(false);
  const [fileList, setFileList] = useState<FileList | undefined>(undefined);

  const dispatch = useAppDispatch();

  const handleCloseAddForm = () => {
    setIsAdding(false);
  };

  const handleFeeTableChanges = (
    field: string,
    tableIndex: number,
    value: string
  ) => {
    dispatch(editTableRowData({field, tableIndex, value}));
  };

  const handleDeleteTransaction = (transactionId?: string) => {
    if (!transactionId) {
      return;
    }

    dispatch(
      deleteTransactionInFeeAndOtherTransactionAction({
        id: transactionId,
        scenarioId: scenarioId,
      })
    );
  };

  const handleCloseEditForm = () => {
    setIsShowTransactionForm(false);
    setEditedTransaction([]);
  };

  const onCancelImportClick = () => {
    setShowFileUploadPopup(false);
    setFileList(undefined);
  };

  const handleEditTransaction = (value: any) => {
    handleCloseAddForm();
    const transactionId = value.id;
    const transaction = tableData.find(
      (transaction: any) => transaction.id === transactionId
    );

    setEditedTransaction(transaction);
    setIsShowTransactionForm(true);
  };

  const handleEditTransactionSubmit = (data: any) => {
    /* @ts-ignore */
    handleEdit(data, editedTransaction.id);
  };

  const onUploadFile = () => {
    dispatch(
      importOtherTransactionsAction(scenarioId, {
        file: fileList?.item(0)!,
      })
    );
    setShowFileUploadPopup(false);
  };

  const rowData = tableData.map((elem: RowDataProps) => {
    return {
      id: elem.id,
      description: elem.vendor,
      currency: elem.localCurrency || 'USD',
      date: valueFormat(elem.date, 'dd-MM-yyyy').value.toString(),
      value: elem.amount,
      comment: elem.comment,
      isCasting: elem.isCasting,
    };
  });

  return (
    <div className={styles.transactionWrapper}>
      <div className={styles.formHeaderTitle}>
        {t('TransactionOtherForm.Title')}
      </div>
      <div className={styles.transactionFormWrapper}>
        <div className={styles.formHeaderWrapper}>
          <div className={styles.transactionCount}>
            {t('Global.Transactions')}
            <span className={styles.rowsCount}>{rowData.length}</span>
          </div>
          <div className={styles.buttonsWrapper}>
            <PrevNextButtons
              handlePrimary={() => {
                setIsAdding(true);
                handleCloseEditForm();
              }}
              handleSecondary={() => {
                setShowFileUploadPopup(true);
              }}
              primaryText={t('Global.AddNew')}
              secondaryText={t('Global.Import')}
            />
          </div>
        </div>
        {isShowTransactionForm && (
          <OtherForm
            initialData={editedTransaction}
            title={t(
              'ScenarioDetailsTransactions.TransactionsOtherForm.OtherForm.Title.Edit'
            )}
            minDate={minDate}
            submitText={t('Global.Done')}
            maxDate={maxDate}
            handleSubmit={handleEditTransactionSubmit}
            handleCloseForm={handleCloseEditForm}
          />
        )}
        {isAdding && (
          <OtherForm
            initialData={editedTransaction}
            title={t(
              'ScenarioDetailsTransactions.TransactionsOtherForm.OtherForm.Title.Add'
            )}
            minDate={minDate}
            maxDate={maxDate}
            submitText={t('Global.AddTransaction')}
            handleSubmit={handleAdd}
            handleCloseForm={handleCloseAddForm}
          />
        )}
        <GenericTable
          data={rowData}
          columns={
            otherColumns(
              columns,
              handleEditTransaction,
              handleDeleteTransaction,
              fundCurrency
            ) as any
          }
          className={styles.table}
          meta={{
            updateData: (rowIndex, columnId, value) => {
              handleFeeTableChanges(columnId, rowIndex, value as string);
            },
            getRowStyles: (row: any): any => {
              return row.original.isCasting
                ? {
                    background: 'var(--nearcasting-gradient)',
                  }
                : {};
            },
          }}
          getRowCanExpand={() => true}
          expandedView={function ({
            row,
          }: GenericExpandedRow<object>): React.ReactElement<
            any,
            string | React.JSXElementConstructor<any>
          > {
            throw new Error('Function not implemented.');
          }}
        />
      </div>
      <ImportScenarioTransactionsModal
        isOpen={showFileUploadPopup}
        onClose={onCancelImportClick}
        onDropHandler={(fileList: FileList) => setFileList(fileList)}
        templateId="other_transactions_import"
        onUploadFileClick={onUploadFile}
        handleDeleteFile={() => setFileList(undefined)}
      />
    </div>
  );
};

export default TransactionsOtherForm;
