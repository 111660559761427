import axios from 'axios';
import config from '../config';
import {FundNearCastingDetailed, NearCastingScenario} from '../types/index';

export const getNearCastingAllScenariosDataDetailed = async (
  token: string,
  id: string
) => {
  const {data} = await axios.get<FundNearCastingDetailed>(
    `${config.APP_URI}/near_casting/fund_scenarios?fund=${id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );
  return data;
};

export const updateNearCastingScenarioStatus = async (
  token: string,
  payload: {status: string},
  id: string
) => {
  // we can update only status here because the PUT /scenarios/{id} endpoint
  // will overwrite only the submitted field and leave all the other fields the same
  const {data} = await axios.put<NearCastingScenario>(
    `${config.APP_URI}/near_casting/scenarios/${id}`,
    payload,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );

  return data;
};

export const duplicateNearCastingScenario = async (
  token: string,
  id: string
) => {
  const {data} = await axios.get<NearCastingScenario>(
    `${config.APP_URI}/near_casting/scenario/${id}/duplicate`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );

  return data;
};

export const deleteNearCastingScenario = async (token: string, id: string) => {
  const {data} = await axios.delete(
    `${config.APP_URI}/near_casting/scenarios/${id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );

  return data;
};
