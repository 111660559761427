import React, {Fragment, useEffect, useRef, useState} from 'react';
import styles from './GenericTable.module.scss';
import {
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  PaginationState,
  Row,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import classnames from 'classnames';
import {GenericTableProps, PaginationType} from '../../../types';
import ScrollButtons from './components/scroll-buttons/ScrollButtons';
import {useTableScroll} from '../../../hooks';
import ScrollContainer from './components/scroll-container/ScrollContainer';
import Pagination from '../pagination/Pagination';

const GenericTable = <TData extends object>({
  data,
  columns,
  expandedView,
  expandedHandler,
  getRowCanExpand,
  columnVisibility = {},
  columnOrder = [],
  scrollableFromWidth,
  isCollapseRowsActivated,
  className,
  onlyOneRowCanBeExpanded,
  defaultSorting = [],
  meta,
  paginated = false,
  pageSize = 50,
  paginationType = PaginationType.Numbers,
}: GenericTableProps<TData>): JSX.Element => {
  const [sorting, setSorting] = useState<SortingState>(defaultSorting);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: pageSize,
  });

  const scrollRef = useRef<HTMLDivElement>(null);

  const {handleScrollContainer, handleScrollBy, scrollPosition, isScrollable} =
    useTableScroll({scrollContainerRef: scrollRef});

  const table = useReactTable({
    data,
    columns,
    getRowCanExpand,
    state: {
      sorting,
      columnVisibility,
      columnOrder,
      ...(paginated ? {pagination} : {}),
    },
    onSortingChange: setSorting,
    ...(paginated ? {onPaginationChange: setPagination} : {}),
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    ...(paginated ? {getPaginationRowModel: getPaginationRowModel()} : {}),
    ...(meta ? {meta} : {}),
  });

  const handleExpandRow = (row: Row<TData>) => {
    if (onlyOneRowCanBeExpanded && !row.getIsExpanded()) {
      table.toggleAllRowsExpanded(false);
    }
    if (expandedHandler) expandedHandler(row);
  };

  useEffect(() => {
    if (isCollapseRowsActivated && table.getIsSomeRowsExpanded()) {
      table.toggleAllRowsExpanded(false);
    }
  }, [isCollapseRowsActivated]);

  return (
    <div
      className={classnames(
        styles.wrapper,
        !scrollableFromWidth && styles.wrapper__withoutScroll,
        className
      )}
    >
      {isScrollable && (
        <ScrollButtons
          handleScrollBy={handleScrollBy}
          scrollPosition={scrollPosition}
          containerRef={scrollRef}
        />
      )}
      <ScrollContainer
        ref={scrollRef}
        onScroll={handleScrollContainer}
        scrollableFromWidth={scrollableFromWidth}
      >
        <table className={styles.table}>
          <thead>
            {table.getHeaderGroups().map(headerGroup => (
              <tr className={styles.row} key={headerGroup.id}>
                {headerGroup.headers.map(header => {
                  return (
                    <Fragment key={header.id}>
                      {/* This is a header row */}
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                    </Fragment>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map(row => {
              return (
                <Fragment key={row.id}>
                  <tr
                    className={classnames(styles.tbodyRow, {
                      [styles.row__hover]: row.getIsExpanded(),
                    })}
                    onClick={() => handleExpandRow(row)}
                    style={table.options?.meta?.getRowStyles?.(row)}
                  >
                    {/* This is a normal row */}
                    {row.getVisibleCells().map(cell => {
                      return (
                        <Fragment key={cell.id}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </Fragment>
                      );
                    })}
                  </tr>
                  {row.getIsExpanded() && (
                    <tr className={styles.tbodyRowExpanded}>
                      {/* This is an expanded row */}
                      <td colSpan={row.getVisibleCells().length}>
                        {expandedView({row})}
                      </td>
                    </tr>
                  )}
                </Fragment>
              );
            })}
          </tbody>
        </table>
      </ScrollContainer>
      {paginated && table.getPageCount() > 1 ? (
        <Pagination
          pages={table.getPageCount()}
          page={pagination.pageIndex + 1}
          onChange={pageNumber => table.setPageIndex(pageNumber - 1)}
          type={paginationType}
          className={styles.pagination}
        />
      ) : null}
    </div>
  );
};

export default GenericTable;
