import React, {useEffect, useMemo, useState} from 'react';
import {
  ButtonStyle,
  ForecastGroupsAndMetricsFormGroup,
  ForecastGroupsAndMetricsFormProps,
  StyleVariant,
} from '../../../types';
import {Button, ButtonSpinner, Checkbox, Modal} from '../../global';
import styles from './ForecastGroupsAndMetricsForm.module.scss';
import {useTranslation} from 'react-i18next';

const ForecastGroupsAndMetricsForm: React.FC<
  ForecastGroupsAndMetricsFormProps
> = ({isOpen, onClose, onSubmit, subTitle, data, inProgress, error}) => {
  const {t} = useTranslation();
  const [formState, setFormState] = useState<
    ForecastGroupsAndMetricsFormGroup[] | null | undefined
  >(data);

  const selectAllCheckboxState = useMemo<{
    checked: boolean;
    isIndeterminate: boolean;
  }>(() => {
    if (!formState)
      return {
        checked: false,
        isIndeterminate: false,
      };
    let checkedLength = 0;
    let totalLength = 0;
    formState.forEach(group => {
      group.metrics.forEach(metric => {
        totalLength += 1;
        if (metric.value) checkedLength += 1;
      });
    });
    return {
      checked: checkedLength === totalLength,
      isIndeterminate: checkedLength !== totalLength && checkedLength > 0,
    };
  }, [formState]);

  const handleSubmit = () => {
    if (!formState) return;
    onSubmit(formState);
  };

  const handleChange = (
    groupIdx: number,
    metricIdx: number,
    value: boolean
  ) => {
    setFormState(prev => {
      if (!prev) return null;
      return prev.map((item, index) => {
        if (index === groupIdx) {
          const updatedItem = {
            ...item,
            metrics: item.metrics.map((metric, i) =>
              i === metricIdx ? {...metric, value} : metric
            ),
          };
          return updatedItem;
        }
        return item;
      });
    });
  };

  const handleChangeSelectAll = (value: boolean) => {
    setFormState(prev => {
      if (!prev) return null;
      return prev.map(group => ({
        ...group,
        metrics: group.metrics.map(metric => ({...metric, value})),
      }));
    });
  };

  useEffect(() => {
    if (data) {
      setFormState(data);
    }
  }, [data]);

  return (
    <Modal
      title={t('DataCollection.ForecastGroupsAndMetricsForm.ModalTitle')}
      subTitle={subTitle}
      isOpen={isOpen}
      onClose={onClose}
      variant={StyleVariant.Secondary}
      footerChildren={
        data ? (
          <div className={styles.buttons}>
            <Button
              styleType={ButtonStyle.Primary}
              text={t('Global.Save')}
              onClick={handleSubmit}
              loading={inProgress}
            />
            <Button
              styleType={ButtonStyle.Secondary}
              text={t('Global.Cancel')}
              onClick={onClose}
              disabled={inProgress}
            />
          </div>
        ) : null
      }
    >
      {formState ? (
        <div className={styles.wrapper}>
          {error ? <div className={styles.errorMessage}>{error}</div> : null}
          <Checkbox
            label={t(
              'DataCollection.ForecastGroupsAndMetricsForm.Checkbox.SelectDeselect'
            )}
            checked={selectAllCheckboxState.checked}
            isIndeterminate={selectAllCheckboxState.isIndeterminate}
            onChange={event => handleChangeSelectAll(event.target.checked)}
          />
          {formState.map((group, groupIdx) => (
            <div className={styles.group} key={groupIdx}>
              <div className={styles.groupTitle}>
                {group.name}{' '}
                <span className={styles.groupSubTitle}>
                  {t('Global.group')}
                </span>
              </div>
              <div className={styles.groupMetrics}>
                {group.metrics.map((metric, metricIdx) => (
                  <Checkbox
                    label={metric.label}
                    checked={metric.value}
                    key={metricIdx}
                    name={metric.id}
                    onChange={event =>
                      handleChange(groupIdx, metricIdx, event.target.checked)
                    }
                  />
                ))}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className={styles.spinnerWrapper}>
          <ButtonSpinner className={styles.spinner} />
        </div>
      )}
    </Modal>
  );
};

export default ForecastGroupsAndMetricsForm;
