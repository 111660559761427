import React from 'react';
import styles from './DocumentExplorerAISearchSummary.module.scss';
import {Icon, LoadingSpinner} from '../../global';
import {useTranslation} from 'react-i18next';
import classnames from 'classnames';

type DocumentExplorerAISearchSummaryProps = {
  question: string;
  summary: string;
  isWaiting?: boolean;
};

const DocumentExplorerAISearchSummary = ({
  question,
  summary,
  isWaiting = false,
}: DocumentExplorerAISearchSummaryProps) => {
  const {t} = useTranslation();
  return (
    <div className={styles.wrapper}>
      {/* User question bubble */}
      <p className={styles.chatBubbleTitle}>
        {t('Documents.DocumentExplorerAISearchSummary.ChatBubbleTitle')}
      </p>
      <div className={styles.chatRow}>
        <Icon className={styles.chatRowIcon} name={'user'} />
        <div className={styles.chatBubble}>{question}</div>
      </div>
      {/* Lantern Ai summary bubble */}
      <p className={styles.chatBubbleTitle}>{t('LanternAIBeta')}</p>
      <div className={styles.chatRow}>
        <Icon className={styles.chatRowIcon} name={'logo-light'} />
        {isWaiting ? (
          // Show chat bubble with a spinner while we are waiting the summary
          <div
            className={classnames(
              styles.chatBubble,
              styles.chatBubble__isWaiting
            )}
          >
            <LoadingSpinner />
          </div>
        ) : !isWaiting && !summary ? (
          // Show chat bubble with a not found info when can't find any info in files
          <div
            className={classnames(
              styles.chatBubble,
              styles.chatBubble__isNotFound
            )}
          >
            <div className={styles.notFoundContent}>
              <Icon name="no-document" />
              <div className={styles.notFoundTitle}>
                {t(`Documents.DocumentExplorerAISearchSummary.Title.NotFound`)}
              </div>
              <div className={styles.notFoundSubTitle}>
                {t(
                  `Documents.DocumentExplorerAISearchSummary.Description.NotFound`
                )}
              </div>
            </div>
          </div>
        ) : (
          // Show chat bubble with the search summary when we receive it from backend
          <div className={styles.chatBubble}>{summary}</div>
        )}
      </div>
    </div>
  );
};

export default DocumentExplorerAISearchSummary;
