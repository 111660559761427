import {all, call, put, select, takeEvery} from 'redux-saga/effects';
import {RootState} from '../../store';
import {PayloadAction} from '@reduxjs/toolkit';
import {acquireAuthResult, msalInstance} from '../..';
import {errorHandlerAction} from '../../actions';
import {FundPortfolioTransactions, PortfolioTransactions} from '../../types';
import {
  getFundDetailsPortfolioTransactions,
  getFundPortfolioTransactionsById,
} from '../../api/getFundDetailsPortfolioTransactions';
import {
  clearFundPortfolioTransactionsData,
  filterTransactionsDataAction,
  setFundPortfolioTransactionsData,
  setFundPortfolioTransactionsFilteredData,
  setTransactionsFilteringInProgress,
} from '../../reducers/fundDetailsPortfolioTransactionsSlice';

export const fundDetailsPortfolioTransactionsSaga =
  function* fundDetailsPortfolioTransactionsSaga({payload}: any): any {
    const id: string = payload?.match?.params?.id;
    if (id) {
      const isUserProfileUpdatePending = yield select(
        (state: RootState) => state.userProfile.isLoading
      );
      if (!isUserProfileUpdatePending) {
        yield put(clearFundPortfolioTransactionsData());
      }
      try {
        const {accessToken} = yield call(acquireAuthResult, msalInstance);
        const response: FundPortfolioTransactions = yield call(
          getFundDetailsPortfolioTransactions,
          accessToken,
          id
        );

        yield put(setFundPortfolioTransactionsData(response));
      } catch (err) {
        console.warn(err);
        yield put(errorHandlerAction({error: err}));
      }
    }
  };

export const fundDetailsPortfolioTransactionsFilterSaga =
  function* fundDetailsPortfolioTransactionsFilterSaga({
    payload,
  }: PayloadAction<{fundId: string; portfolioId: string}>) {
    const fundId: string = payload.fundId;
    const portfolioId: string = payload?.portfolioId;
    if (fundId) {
      yield put(setTransactionsFilteringInProgress(true));
      try {
        const {accessToken} = yield call(acquireAuthResult, msalInstance);
        const response: PortfolioTransactions = yield call(
          getFundPortfolioTransactionsById,
          accessToken,
          fundId,
          portfolioId
        );
        yield put(setFundPortfolioTransactionsFilteredData(response));
        yield put(setTransactionsFilteringInProgress(false));
      } catch (err) {
        console.warn(err);
        yield put(setTransactionsFilteringInProgress(false));
        yield put(errorHandlerAction({error: err}));
      }
    }
  };

export const fundDetailsPortfolioTransactionsSagaWatcher =
  function* fundDetailsPortfolioTransactionsSagaWatcher() {
    yield all([
      takeEvery(
        filterTransactionsDataAction.type,
        fundDetailsPortfolioTransactionsFilterSaga
      ),
    ]);
  };
