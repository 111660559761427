import {createAction, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {ClientAdminUsers, FormStatus, UserData, UserRole} from '../types';

export const updateUserDataAction = createAction(
  'client-admin/users/update-user-data',
  (payload: UserData) => {
    return {
      payload,
    };
  }
);

export const removeUserDataAction = createAction(
  'client-admin/users/remove-user-data',
  (id: string) => {
    return {
      payload: {
        id,
      },
    };
  }
);

export const inviteUserAction = createAction(
  'client-admin/users/invite-user',
  (email: string, role: UserRole) => {
    return {
      payload: {
        email,
        role,
      },
    };
  }
);

export const resendInviteUserAction = createAction(
  'client-admin/users/resend-invite-user',
  (id: string) => {
    return {
      payload: {
        id,
      },
    };
  }
);

export const reactivateUserAction = createAction(
  'client-admin/users/reactivate-user',
  (id: string) => {
    return {
      payload: {
        id,
      },
    };
  }
);

interface ClientAdminUsersSlice {
  data: ClientAdminUsers | null;
  formStatus: FormStatus;
  isRequestPending: boolean;
  isReinvitePending: boolean;
}

const initialState: ClientAdminUsersSlice = {
  data: null,
  formStatus: FormStatus.Ready,
  isRequestPending: false,
  isReinvitePending: false,
};

const clientAdminUsersSlice = createSlice({
  name: 'client-admin/users',
  initialState,
  reducers: {
    setAdminData(
      state: ClientAdminUsersSlice,
      action: PayloadAction<ClientAdminUsers>
    ) {
      state.data = action.payload;
    },
    clearClientAdminUsersData(state: ClientAdminUsersSlice) {
      state.data = null;
    },
    updateUserData(
      state: ClientAdminUsersSlice,
      action: PayloadAction<UserData>
    ) {
      if (state.data) {
        const users = state.data.users.data;

        state.data.users.data = users.map((item: UserData) => {
          if (item.id !== action.payload.id) {
            return item;
          }

          return {
            ...item,
            ...action.payload,
          };
        });
      }
    },
    setClientAdminUsersFormStatus(
      state: ClientAdminUsersSlice,
      action: PayloadAction<FormStatus>
    ) {
      state.formStatus = action.payload;
    },
    setClientAdminUsersIsRequestPending(
      state: ClientAdminUsersSlice,
      action: PayloadAction<boolean>
    ) {
      state.isRequestPending = action.payload;
    },
    setClientAdminUsersIsReinvitePending(
      state: ClientAdminUsersSlice,
      action: PayloadAction<boolean>
    ) {
      state.isReinvitePending = action.payload;
    },
  },
});

export default clientAdminUsersSlice.reducer;

export const {
  setAdminData,
  clearClientAdminUsersData,
  updateUserData,
  setClientAdminUsersFormStatus,
  setClientAdminUsersIsRequestPending,
  setClientAdminUsersIsReinvitePending,
} = clientAdminUsersSlice.actions;
