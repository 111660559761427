import React from 'react';
import {valueFormat} from '../../../utils/value-format';
import styles from './TreemapTooltip.module.scss';
import classnames from 'classnames';
import {useTranslation} from 'react-i18next';

const TreemapTooltip = (props: any) => {
  const {t} = useTranslation();
  const {payload} = props;

  if (payload?.length) {
    return (
      <div className={classnames(styles.wrapper, 'treemap-tooltip')}>
        <div className={styles.treemapTooltipInfoHolder}>
          <p className={styles.name}>{payload[0].payload.name}:</p>
          <p className={styles.value}>{payload[0].payload.percentage}%</p>
        </div>

        <div className={styles.treemapTooltipInfoHolder}>
          <p className={styles.currency}>{payload[0].payload?.currency}</p>
          <p className={styles.value}>
            {
              valueFormat(
                payload[0].payload.value,
                payload[0].payload.tooltipFormat
              ).value
            }
          </p>
        </div>
        {'--'}
        <p>{t('TreemapChart.TooltipMessage')}</p>
      </div>
    );
  }

  return null;
};

export default TreemapTooltip;
