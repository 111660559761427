import React from 'react';
import classnames from 'classnames';
import {SkeletonLoader} from '../../../../global';
import styles from './ListItem.module.scss';

const ListItemSkeleton: React.FC = () => {
  return (
    <div className={classnames(styles.listItem)}>
      <div className={styles.listItemName}>
        <SkeletonLoader width={'70%'} />
      </div>
    </div>
  );
};

export default ListItemSkeleton;
