import React from 'react';
import {
  FeeAnalysisTableItemRow,
  FlexHAlign,
  HeaderMapping,
} from '../../../../types';
import {ColumnDef, createColumnHelper} from '@tanstack/react-table';
import Th from '../cells/th/Th';
import Td from '../cells/td/Td';
import NameCell from '../cells/name-cell/NameCell';
import ExpanderCell from '../cells/expander-cell/ExpanderCell';
import {valueFormat} from '../../../../utils';
import styles from './FeeAnalysisColumnDefs.module.scss';

export const genFeeAnalysisTableColumns = (
  headerMapping: HeaderMapping[]
): ColumnDef<FeeAnalysisTableItemRow, any>[] => {
  const columnHelper = createColumnHelper<FeeAnalysisTableItemRow>();

  return [
    columnHelper.accessor(row => row.category, {
      id: 'category',
      header: props => (
        <Th
          {...props}
          headerMapping={headerMapping}
          align={FlexHAlign.start}
          width={'max(100vw*0.18, 150px)'}
        />
      ),
      cell: props => (
        <Td
          {...props}
          className={styles.cell}
          align={FlexHAlign.start}
          width={'320px'}
          canHide={false}
        >
          <NameCell
            name={props?.getValue<string>()}
            expanded={props.row.getIsExpanded()}
            className={styles.nameCell}
          />
        </Td>
      ),
    }),
    columnHelper.accessor(row => row.percent.value, {
      id: 'percent',
      header: props => (
        <Th {...props} headerMapping={headerMapping} align={FlexHAlign.end} />
      ),
      cell: props => {
        const {value, format} = props.row.original.percent;
        const formatted = valueFormat(value, format);
        return (
          <Td {...props} className={styles.cell} align={FlexHAlign.end}>
            {`${formatted.value}${formatted.suffix}`}
          </Td>
        );
      },
    }),
    columnHelper.accessor(row => +row.grossAmount.value, {
      id: 'grossAmount',

      header: props => (
        <Th {...props} headerMapping={headerMapping} align={FlexHAlign.end} />
      ),
      cell: props => {
        const {currency, value, format} = props.row.original.grossAmount;
        return (
          <Td {...props} className={styles.cell} align={FlexHAlign.end}>
            {`${currency} ${valueFormat(value, format).value}`}
          </Td>
        );
      },
    }),
    columnHelper.accessor(row => +(row.taxAmount?.value || ''), {
      id: 'taxAmount',
      header: props => (
        <Th {...props} headerMapping={headerMapping} align={FlexHAlign.end} />
      ),
      cell: props => {
        const {currency, value, format} = props.row.original.taxAmount || {};
        return (
          <Td
            {...props}
            className={styles.cell}
            align={FlexHAlign.end}
            emptyValue={'-'}
          >
            {props.row.original.taxAmount &&
              `${currency} ${valueFormat(value, format).value}`}
          </Td>
        );
      },
    }),
    columnHelper.accessor(row => +row.netAmount.value, {
      id: 'netAmount',
      header: props => (
        <Th {...props} headerMapping={headerMapping} align={FlexHAlign.end} />
      ),
      cell: props => {
        const {currency, value, format} = props.row.original.netAmount;
        return (
          <Td {...props} className={styles.cell} align={FlexHAlign.end}>
            {`${currency} ${valueFormat(value, format).value}`}
          </Td>
        );
      },
    }),
    columnHelper.display({
      id: 'expander',
      header: props => (
        <Th
          {...props}
          align={FlexHAlign.center}
          width={'40px'}
          headerMapping={headerMapping}
        />
      ),
      cell: props => {
        const row = props.row;
        const isExpanded = props.row.getIsExpanded();

        return row.getCanExpand() ? (
          <Td {...props} align={FlexHAlign.center} canHide={false}>
            <ExpanderCell expanded={isExpanded} />
          </Td>
        ) : null;
      },
    }),
  ];
};
