import React, {useEffect, useRef, useState} from 'react';
import classnames from 'classnames';
import {Icon} from '../index';
import useOnOutsideClick from '../../../hooks/useOnOutsideClick';
import {DropdownWrapperAlignmentType, StyleVariant, Func} from '../../../types';
import styles from './DropdownWrapper.module.scss';

interface DropdownWrapperProps {
  id?: string;
  variant?: StyleVariant;
  label?: string | JSX.Element;
  subLabel?: string;
  children:
    | JSX.Element
    | Func<
        [{handleCloseDropdown: Func<[], void>; isOpen: boolean}],
        JSX.Element
      >;
  onClickCancel?: Func<[], void>;
  alignment?: DropdownWrapperAlignmentType;
  disabled?: boolean;
  loading?: boolean;
  customButton?: Func<
    [{handleToggleOpen: (e: any) => void; isOpen: boolean}],
    JSX.Element
  >;
  isOpen?: boolean;
  className?: string;
}

const DropdownWrapper: React.FC<DropdownWrapperProps> = ({
  label,
  subLabel,
  children,
  customButton,
  onClickCancel,
  variant = StyleVariant.Primary,
  alignment = DropdownWrapperAlignmentType.Right,
  disabled = false,
  loading = false,
  isOpen: isManualOpen,
  className = '',
  id,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const elemRef = useRef<HTMLDivElement>(null);

  const handleCloseDropdown = () => {
    setIsOpen(false);
  };

  const handleToggleOpen = (e: any): void => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  const handleClickCancel = () => {
    handleCloseDropdown();
    if (onClickCancel) {
      onClickCancel();
    }
  };

  useOnOutsideClick(elemRef, handleClickCancel);

  useEffect(() => {
    if (isManualOpen !== undefined) {
      setIsOpen(isManualOpen);
    }
  }, [isManualOpen]);

  return (
    <div
      className={classnames(styles.wrapper, isOpen && styles.active, className)}
      ref={elemRef}
      data-test={id}
    >
      <div className={classnames(styles.inner, styles[`inner__${alignment}`])}>
        {subLabel && (
          <span className={styles.subLabel} data-test="dropdown-wrapper-label">
            {subLabel}
          </span>
        )}
        {customButton ? (
          customButton({handleToggleOpen, isOpen})
        ) : (
          <button
            onClick={handleToggleOpen}
            className={classnames(
              styles.value,
              isOpen && styles.active,
              styles[`value__${variant}`],
              loading && styles.loading
            )}
            disabled={disabled}
            data-test="dropdown-wrapper-button"
            aria-haspopup="true"
            aria-expanded={isOpen}
          >
            {label && <span className={styles.label}>{label}</span>}
            <span className={styles.caret}>
              <Icon className={styles.dropIcon} name={'chevron-down'} />
            </span>
            {loading ? (
              <span className={styles.loader}>
                <Icon className={styles.loaderIcon} name="ellipse" />
              </span>
            ) : null}
          </button>
        )}
      </div>
      <div
        data-dropdown-wrapper-dropdown
        className={classnames(
          styles.dropdown,
          isOpen && styles.active,
          styles[`dropdown__${alignment}`]
        )}
      >
        {typeof children === 'function'
          ? children({handleCloseDropdown, isOpen})
          : children}
      </div>
    </div>
  );
};

export default DropdownWrapper;
