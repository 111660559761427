import React from 'react';
import {Trans} from 'react-i18next';
import styles from './PortfolioMonitoringOverlay.module.scss';
import portfolioMonitoring from '../../../../assets/images/portfolio-monitoring.svg';

const PortfolioMonitoringOverlay = (): JSX.Element => {
  const portfolioMonitoringText = () => {
    return <div className={styles.heading}>Portfolio Monitoring</div>;
  };

  const emailLink = () => {
    return (
      <a
        href="mailto:hello@lantern.ai?subject=Portfolio Monitoring demo request"
        target="_blank"
        rel="noreferrer"
        className={styles.email}
      >
        hello@lantern.ai
      </a>
    );
  };

  return (
    <div className={styles.wrapper}>
      <img src={portfolioMonitoring} alt="Portfolio Monitoring" />
      <div className={styles.information}>
        <div className={styles.message}>
          <Trans
            i18nKey="Portfolio.MonitoringSubscriptionOverlay"
            components={{
              heading: portfolioMonitoringText(),
              email: emailLink(),
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default PortfolioMonitoringOverlay;
