import React from 'react';
import {ValueFormat} from '../../../';
import {InfoHeaderType} from '../../../../../types';
import DeltaIndicator from '../../../../dashboard/fund-summary-widget/components/fund-widget-header/components/delta-indicator/DeltaIndicator';
import styles from './CommonCell.module.scss';
import {formatToHyphenatedString} from '../../../../../utils/strings';

const CommonCell: React.FC<InfoHeaderType> = ({
  currency,
  vintage,
  value,
  format,
  deltaIndicator,
  id,
  label,
}): JSX.Element => {
  return (
    <div
      className={styles.wrapper}
      data-test={`${id}-${formatToHyphenatedString(label ?? '')}-value`}
    >
      <div className={styles.value}>
        {currency && <span className={styles.currency}>{currency}</span>}
        {vintage && <span className={styles.currency}>{vintage}</span>}
        <ValueFormat value={value as string} format={format} />
        {deltaIndicator && (
          <div className={styles.delta}>
            <DeltaIndicator isUp={deltaIndicator.isUp} />
          </div>
        )}
      </div>
    </div>
  );
};

export default CommonCell;
