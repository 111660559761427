import React from 'react';
import EntityPreviewService from './components/entity-preview-service/EntityPreviewService';
import EntityPreviewCapital from './components/entity-preview-capital/EntityPreviewCapital';
import {EntityPreviewType, StyleVariant} from '../../../types/index';
import {InfoHeader} from '../../fund-overview';
import styles from './EntityPreview.module.scss';

const EntityPreview: React.FC<EntityPreviewType> = ({
  name,
  headerData,
  capitalData,
  serviceProviders,
  fundEntityId,
}) => {
  return (
    <div className={styles.wrapper}>
      <InfoHeader
        styleType={StyleVariant.Secondary}
        className={styles.header}
        data={headerData}
        gridColumnWidth={250}
      />
      <EntityPreviewService {...serviceProviders} />
      <EntityPreviewCapital
        fundName={name}
        data={capitalData}
        fundEntityId={fundEntityId}
      />
    </div>
  );
};

export default EntityPreview;
