import axios from 'axios';
import config from '../config';
import {
  NearCastingCapitalTransactionPayload,
  NearCastingFeeOrOtherTransactionPayload,
  NearCastingCapitalTransaction,
  NearCastingScenario,
} from '../types';

export const addNewCapitalTransaction = async (
  token: string,
  payload: NearCastingCapitalTransactionPayload
) => {
  const {data} = await axios.post<NearCastingCapitalTransaction>(
    `${config.APP_URI}/near_casting/fund_kpi_time_series`,
    payload,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );

  return data;
};

export const addNewFeeOrOtherTransaction = async (
  token: string,
  payload: NearCastingFeeOrOtherTransactionPayload
) => {
  const {data} = await axios.post(
    `${config.APP_URI}/near_casting/fund_entity_transactions`,
    payload,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );

  return data;
};

export const getTransactionsCalc = async (token: string, id: string) => {
  const {data} = await axios.get<any>(
    `${config.APP_URI}/near_casting/calc_scenario/${id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );
  return data;
};

export const deleteOtherTransactionsCapitalTransaction = async (
  token: string,
  id: string
) => {
  const {data} = await axios.delete(
    `${config.APP_URI}/near_casting/fund_kpi_time_series/${id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );

  return data;
};

export const deleteOtherTransactionsFeeAndOtherTransaction = async (
  token: string,
  id: string
) => {
  const {data} = await axios.delete(
    `${config.APP_URI}/near_casting/fund_entity_transactions/${id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );

  return data;
};

export const updateOtherTransactionsCapitalTransaction = async (
  token: string,
  payload: NearCastingCapitalTransactionPayload,
  id: string
) => {
  const {data} = await axios.put(
    `${config.APP_URI}/near_casting/fund_kpi_time_series/${id}`,
    payload,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );

  return data;
};

export const updateOtherTransactionsOtherAndFeeTransaction = async (
  token: string,
  payload: NearCastingFeeOrOtherTransactionPayload,
  id: string
) => {
  const {data} = await axios.put(
    `${config.APP_URI}/near_casting/fund_entity_transactions/${id}`,
    payload,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );

  return data;
};

export const importFundEntityTransactions = async (
  token: string,
  scenarioId: string,
  file: File
) => {
  const formData = new FormData();
  formData.append('file', file);
  const {data} = await axios.post<NearCastingScenario>(
    `${config.APP_URI}/near_casting/scenarios/${scenarioId}/import_fund_capital_transactions`,
    formData,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );
  return data;
};

export const importFundFeeTransactions = async (
  token: string,
  scenarioId: string,
  file: File
) => {
  const formData = new FormData();
  formData.append('file', file);
  const {data} = await axios.post<NearCastingScenario>(
    `${config.APP_URI}/near_casting/scenarios/${scenarioId}/import_fee_transactions`,
    formData,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );
  return data;
};

export const importOtherTransactions = async (
  token: string,
  scenarioId: string,
  file: File
) => {
  const formData = new FormData();
  formData.append('file', file);
  const {data} = await axios.post<NearCastingScenario>(
    `${config.APP_URI}/near_casting/scenarios/${scenarioId}/import_other_transactions`,
    formData,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );
  return data;
};
