import React from 'react';
import {
  ButtonStyle,
  FormStatus,
  StyleVariant,
  CompanyGeneralInfoFormComponentProps,
  CompanyGeneralInfoFormState,
} from '../../../types';
import {useFormState, useIsSubmitDisabled} from '../../../hooks';
import {
  Button,
  ButtonSpinner,
  Icon,
  Input,
  Modal,
  SelectInput,
  TextArea,
} from '../../global';
import styles from './CompanyGeneralInfoForm.module.scss';
import classnames from 'classnames';
import {companyGeneralInfoFormSchema} from '../../../constants';
import {genDayOptions, genMonthOptions} from '../../../utils';
import {useTranslation} from 'react-i18next';

const CompanyGeneralInfoForm: React.FC<
  CompanyGeneralInfoFormComponentProps
> = ({
  options,
  onClose,
  onSubmit,
  isOpen,
  title,
  formStatus,
  initialFormState,
  error,
  formError,
  resetErrors,
}) => {
  const {t} = useTranslation();
  const {
    errors,
    handleChange,
    isFormStateChanged,
    validate,
    formState,
    setFormState,
  } = useFormState<CompanyGeneralInfoFormState>(
    initialFormState,
    companyGeneralInfoFormSchema
  );

  const {isSubmitDisabled} = useIsSubmitDisabled(
    formError,
    errors,
    error,
    isFormStateChanged
  );

  const handleOnChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    if (error || formError) {
      resetErrors();
    }
    handleChange(e);
  };

  const handleSubmit = () => {
    if (validate(formState)) {
      onSubmit(formState);
    }
  };

  const handleMonthChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (error || formError) {
      resetErrors();
    }

    const days = genDayOptions(e.target.value);

    setFormState(prev => ({
      ...prev,
      fiscalYearEndMonth: e.target.value,
      fiscalYearEndDay: days[days.length - 1].value.toString(),
    }));
  };
  const handleHeadquartersChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    if (error || formError) {
      resetErrors();
    }

    setFormState(prev => ({
      ...prev,
      headquartersCountry: e.target.value,
      state: '',
    }));
  };

  return (
    <Modal
      title={title}
      isOpen={isOpen}
      onClose={onClose}
      variant={StyleVariant.Secondary}
      className={classnames(styles.wrapper, !options && styles.loading)}
      footerChildren={
        options && (
          <div className={styles.buttons}>
            <Button
              text={t('Global.Save')}
              onClick={handleSubmit}
              loading={formStatus === FormStatus.Pending}
              disabled={isSubmitDisabled}
            />
            <Button
              styleType={ButtonStyle.Secondary}
              text={t('Global.Cancel')}
              onClick={onClose}
              disabled={formStatus === FormStatus.Pending}
            />
          </div>
        )
      }
    >
      {error ? <div className={styles.errorMessage}>{error}</div> : null}
      {options ? (
        <div className={styles.content}>
          <div className={styles.group}>
            <Input
              variant={StyleVariant.Secondary}
              name={'companyName'}
              label={t('Global.CompanyName')}
              placeholder={t(
                'Portfolio.CompanyGeneralInfoForm.InputPlaceholder.EnterCompany'
              )}
              errorMessage={t(
                errors.companyName.message || formError?.companyName || ''
              )}
              validate={validate}
              value={formState.companyName}
              onChange={handleOnChange}
            />
            <SelectInput
              label={t(
                'Portfolio.CompanyGeneralInfoForm.SelectInputLabel.Region'
              )}
              name={'geographicRegion'}
              placeholder={t(
                'Portfolio.CompanyGeneralInfoForm.SelectInputPlaceholder.Region'
              )}
              variant={StyleVariant.Secondary}
              options={options.geographicRegion}
              onChange={handleOnChange}
              value={formState.geographicRegion}
              errorMessage={t(formError?.geographicRegion || '')}
            />
            <SelectInput
              label={t('Global.Sector')}
              name={'sector'}
              placeholder={t(
                'Portfolio.CompanyGeneralInfoForm.SelectInputPlaceholder.Sector'
              )}
              variant={StyleVariant.Secondary}
              options={options.sector}
              onChange={handleOnChange}
              value={formState.sector}
              errorMessage={t(formError?.sector || '')}
            />
            {formState.sector ? (
              <SelectInput
                label={t('Global.Industry')}
                name={'industry'}
                placeholder={t(
                  'Portfolio.CompanyGeneralInfoForm.SelectInputPlaceholder.Industry'
                )}
                variant={StyleVariant.Secondary}
                options={
                  options.sector.filter(
                    sector => sector.value === formState.sector
                  )[0]?.industry
                }
                onChange={handleOnChange}
                value={formState.industry}
                errorMessage={t(formError?.industry || '')}
              />
            ) : null}
          </div>

          <div className={styles.group}>
            <SelectInput
              label={t(
                'Portfolio.CompanyGeneralInfoForm.SelectInputLabel.Headquarters'
              )}
              name={'headquartersCountry'}
              placeholder={t(
                'Portfolio.CompanyGeneralInfoForm.SelectInputPlaceholder.Country'
              )}
              variant={StyleVariant.Secondary}
              options={options.headquartersCountry}
              onChange={handleHeadquartersChange}
              value={formState.headquartersCountry}
              errorMessage={t(formError?.headquartersCountry || '')}
            />
            {formState.headquartersCountry === 'United States' ? (
              <SelectInput
                label={t('Global.State')}
                name={'state'}
                placeholder={t(
                  'Portfolio.CompanyGeneralInfoForm.SelectInputPlaceholder.State'
                )}
                variant={StyleVariant.Secondary}
                options={
                  options.headquartersCountry.filter(
                    headquartersCountry =>
                      headquartersCountry.value ===
                      formState.headquartersCountry
                  )[0]?.state
                }
                onChange={handleOnChange}
                value={formState.state}
                errorMessage={t(formError?.state || '')}
              />
            ) : null}
          </div>

          <div className={styles.group}>
            <SelectInput
              label={t(
                'Portfolio.CompanyGeneralInfoForm.SelectInputLabel.Stage'
              )}
              name={'stage'}
              placeholder={t(
                'Portfolio.CompanyGeneralInfoForm.SelectInputPlaceholder.Stage'
              )}
              variant={StyleVariant.Secondary}
              options={options.stage}
              onChange={handleOnChange}
              value={formState.stage}
              errorMessage={t(formError?.stage || '')}
            />
            <Input
              variant={StyleVariant.Secondary}
              label={t(
                'Portfolio.CompanyGeneralInfoForm.InputLabel.CompanyRegNum'
              )}
              name={'companyRegistrationNumber'}
              placeholder={t(
                'Portfolio.CompanyGeneralInfoForm.InputPlaceholder.CompanyRegNum'
              )}
              errorMessage={t(
                errors.companyRegistrationNumber.message ||
                  formError?.companyRegistrationNumber ||
                  ''
              )}
              validate={validate}
              value={formState.companyRegistrationNumber}
              onChange={handleOnChange}
            />
            <SelectInput
              label={t('Global.ReportingCurrency')}
              name={'reportingCurrency'}
              placeholder={'-'}
              variant={StyleVariant.Secondary}
              options={options.reportingCurrency}
              onChange={handleOnChange}
              value={formState.reportingCurrency}
              disabled={true}
              errorMessage={t(formError?.reportingCurrency || '')}
            />
            <div>
              <p className={styles.groupTitle}>{t('Global.FiscalYearEnd')}</p>
              <div className={styles.dateInputs}>
                <SelectInput
                  label={t('Global.Month')}
                  name={'fiscalYearEndMonth'}
                  variant={StyleVariant.Secondary}
                  options={genMonthOptions()}
                  onChange={handleMonthChange}
                  value={formState.fiscalYearEndMonth}
                  errorMessage={t(formError?.fiscalYearEndMonth || '')}
                />
                <SelectInput
                  label={'Global.Day'}
                  name={'fiscalYearEndDay'}
                  variant={StyleVariant.Secondary}
                  placeholder={'Choose day'}
                  options={genDayOptions(formState.fiscalYearEndMonth)}
                  onChange={handleOnChange}
                  value={formState.fiscalYearEndDay}
                  errorMessage={t(formError?.fiscalYearEndDay || '')}
                />
              </div>
            </div>
            <Input
              variant={StyleVariant.Secondary}
              label={t('Global.Website')}
              name={'website'}
              placeholder={t('Global.EnterURL')}
              errorMessage={t(
                errors.website.message || formError?.website || ''
              )}
              validate={validate}
              value={formState.website}
              onChange={handleOnChange}
            />
          </div>

          <p className={styles.groupTitle}>{t('Global.SocialMedia')}</p>
          <div className={styles.group}>
            <div className={styles.socialMedia}>
              <Icon
                name={'facebook'}
                className={styles.socialMediaIcon}
                tooltip={t('Global.Facebook')}
              />
              <Input
                variant={StyleVariant.Secondary}
                label={''}
                name={'facebook'}
                placeholder={t('Global.EnterURL')}
                errorMessage={t(
                  errors.facebook.message || formError?.facebook || ''
                )}
                validate={validate}
                value={formState.facebook}
                onChange={handleOnChange}
              />
            </div>
            <div className={styles.socialMedia}>
              <Icon
                name={'youtube'}
                className={styles.socialMediaIcon}
                tooltip={t('Global.Youtube')}
              />
              <Input
                variant={StyleVariant.Secondary}
                label={''}
                name={'youtube'}
                placeholder={t('Global.EnterURL')}
                errorMessage={t(
                  errors.youtube.message || formError?.youtube || ''
                )}
                validate={validate}
                value={formState.youtube}
                onChange={handleOnChange}
              />
            </div>
            <div className={styles.socialMedia}>
              <Icon
                name={'twitter'}
                className={styles.socialMediaIcon}
                tooltip={t('Global.Twitter')}
              />
              <Input
                variant={StyleVariant.Secondary}
                label={''}
                name={'twitter'}
                placeholder={t('Global.EnterURL')}
                errorMessage={t(
                  errors.twitter.message || formError?.twitter || ''
                )}
                validate={validate}
                value={formState.twitter}
                onChange={handleOnChange}
              />
            </div>
            <div className={styles.socialMedia}>
              <Icon
                name={'linkedin'}
                className={styles.socialMediaIcon}
                tooltip={t('Global.LinkedIn')}
              />
              <Input
                variant={StyleVariant.Secondary}
                label={''}
                name={'linkedIn'}
                placeholder={t('Global.EnterURL')}
                errorMessage={t(
                  errors.linkedIn.message || formError?.linkedIn || ''
                )}
                validate={validate}
                value={formState.linkedIn}
                onChange={handleOnChange}
              />
            </div>
            <div className={styles.socialMedia}>
              <Icon
                name={'instagram'}
                className={styles.socialMediaIcon}
                tooltip={t('Global.Instagram')}
              />
              <Input
                variant={StyleVariant.Secondary}
                label={''}
                name={'instagram'}
                placeholder={t('Global.EnterURL')}
                errorMessage={t(
                  errors.instagram.message || formError?.instagram || ''
                )}
                validate={validate}
                value={formState.instagram}
                onChange={handleOnChange}
              />
            </div>
          </div>

          <div className={styles.group}>
            <TextArea
              variant={StyleVariant.Secondary}
              name={'description'}
              label={t('Global.Description')}
              placeholder={t(
                'Portfolio.CompanyGeneralInfoForm.InputPlaceholder.Description'
              )}
              errorMessage={t(
                errors.description.message || formError?.description || ''
              )}
              validate={validate}
              value={formState.description}
              onChange={handleOnChange}
            />
          </div>
        </div>
      ) : (
        <div className={styles.spinnerWrapper}>
          <ButtonSpinner className={styles.spinner} />
        </div>
      )}
    </Modal>
  );
};

export default CompanyGeneralInfoForm;
