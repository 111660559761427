import React, {useEffect, useMemo, useState} from 'react';
import styles from './CustomTopicsForm.module.scss';
import {
  ButtonStyle,
  CustomTopicsFormProps,
  CustomTopicsFormState,
  MetricType,
  MetricUnitType,
  SelectOption,
  StyleVariant,
  TagItem,
  TopicAnswer,
} from '../../../types';
import {
  Button,
  ButtonSpinner,
  IconButton,
  Input,
  Modal,
  SelectInput,
  TagInput,
  TextArea,
  Toggle,
} from '../../global';
import {useFormState} from '../../../hooks';
import {customTopicsFormSchema} from '../../../constants';
import classnames from 'classnames';
import {useTranslation} from 'react-i18next';

const CustomTopicsForm: React.FC<CustomTopicsFormProps> = ({
  error,
  onClose,
  onSubmit,
  options,
  isOpen,
  initialFormState,
  inProgress,
  isDeleteInProgress,
  isWorkflowForTemplateActive,
  isAnswersOptionsDeletable,
  formError,
  resetErrors,
  onDelete,
}) => {
  const {t} = useTranslation();
  const {
    formState,
    isFormStateChanged,
    setFormState,
    errors,
    validate,
    handleChange,
  } = useFormState<CustomTopicsFormState>(
    initialFormState,
    customTopicsFormSchema
  );

  const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(true);
  const [newAnswerId, setNewAnswerId] = useState<number>(0);

  const tagState = useMemo(() => {
    return formState.standard?.map(standard => {
      return {id: null, label: standard, mandatory: false};
    });
  }, [formState.standard]);

  const selectedTopic = useMemo(() => {
    if (formState.type === MetricType.Custom) {
      return options.find(option => option.type === MetricType.Custom);
    }
    return options.find(option => formState.parentId === option.id);
  }, [formState, options]);

  const handleOnChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    if (error || formError) {
      resetErrors();
    }
    handleChange(e);
  };

  const handleSubmit = () => {
    const preparedData = {
      ...formState,
      parentId: formState.parentId === 'custom' ? null : formState.parentId,
      answerOptions: formState.answerOptions?.map(answer => {
        if (typeof answer.value !== 'number') {
          return answer;
        }

        return {value: null, label: answer.label};
      }),
    };

    onSubmit(preparedData);
  };

  const handleTagChange = (e: TagItem[]) => {
    if (error || formError) {
      resetErrors();
    }

    setFormState(prev => {
      return {
        ...prev,
        standard: e.length ? e.map(tag => tag.label) : [],
      };
    });
  };

  const handleAnswerOnChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    id: string | number | null
  ) => {
    setFormState(prev => {
      return {
        ...prev,
        answerOptions: prev.answerOptions.map(answer => {
          if (answer.value === id) {
            return {...answer, label: e.target.value};
          }
          return answer;
        }),
      };
    });
  };
  const handleDeleteAnswer = (id: string | number | null) => {
    setFormState(prev => {
      return {
        ...prev,
        answerOptions: prev.answerOptions.filter(answer => answer.value !== id),
      };
    });
  };

  const handleAddAnswer = () => {
    setFormState(prev => {
      return {
        ...prev,
        answerOptions: [...prev.answerOptions, {value: newAnswerId, label: ''}],
      };
    });

    setNewAnswerId(prev => prev + 1);
  };

  const topicOptions = useMemo<SelectOption[]>(() => {
    return options.map(option => ({
      id: option.id || option.name,
      value: option.id || '',
      label: option.name,
    }));
  }, [options]);

  const handleTopicOnChange = (id: string) => {
    resetErrors();
    const template = options.find(item => item.id === id);
    if (template) {
      const newFormState = {
        ...template,
        parentId: template.id,
        id: '',
        reportingType: template.reportingType.value,
        unitType: template.unitType.value,
        groupId: initialFormState.groupId,
        question: template.question || '',
        answerOptions: template.answerOptions || [],
        standard: template.standard || [],
      };

      setFormState(newFormState);
      validate({
        unitLabel: template.unitLabel || '',
        answerOptions: template.answerOptions || [],
        reportingType: template.reportingType.value,
        question: template.question || '',
        reportingName: template.name,
        description: template.description,
      });
    }
  };

  const handleChangeUnitType = (value: string) => {
    if (!selectedTopic) return;
    const unitType = selectedTopic.unitType.options.find(
      option => option.value === value
    );

    let answerOptions: TopicAnswer[] = [];

    if (
      value === MetricUnitType.MultiSelect ||
      value === MetricUnitType.SingleSelect
    ) {
      answerOptions = [
        {value: 0, label: ''},
        {value: 1, label: ''},
      ];
      setNewAnswerId(2);
    }

    setFormState(prev => {
      validate({
        unitLabel: unitType?.unitLabel.value || prev.unitLabel,
        answerOptions: answerOptions,
        reportingType: prev.reportingType,
        question: prev.question,
        reportingName: prev.reportingName,
        description: prev.description,
      });

      return {
        ...prev,
        unitType: value,
        unitLabel: unitType?.unitLabel.value || prev.unitLabel,
        answerOptions: answerOptions,
      };
    });
  };

  const isUnitLabelDisabled = useMemo(() => {
    if (selectedTopic) {
      const unitType = selectedTopic.unitType.options?.find(
        option => option.value === formState.unitType
      );
      return !!unitType?.unitLabel?.isDisabled;
    }
    return false;
  }, [selectedTopic, formState.unitType]);

  // Manually check only relevant field errors based on selected unit type
  useEffect(() => {
    let includedFields;
    switch (formState.unitType) {
      case MetricUnitType.MultiSelect:
      case MetricUnitType.SingleSelect:
        includedFields = [
          'reportingName',
          'description',
          'question',
          'answerOptions',
        ];
        break;
      case MetricUnitType.Number:
      case MetricUnitType.Percentage:
      case MetricUnitType.Currency:
        includedFields = [
          'reportingName',
          'description',
          'question',
          'unitLabel',
          'reportingType',
        ];
        break;
      default:
        includedFields = ['reportingName', 'description', 'question'];
    }

    const someFieldHasAnError = includedFields.some(
      field => errors[field].message
    );

    const someFieldHasANestedError = includedFields.some(
      field => Object.keys(errors[field].nestedFields).length
    );

    setIsSubmitDisabled(
      !!error ||
        !!formError ||
        !isFormStateChanged ||
        someFieldHasAnError ||
        someFieldHasANestedError
    );
  }, [formError, errors, error, isFormStateChanged, formState]);

  useEffect(() => {
    validate({answerOptions: formState.answerOptions});
  }, [formState.answerOptions]);

  const isSystemMetrics = formState.type === MetricType.SystemBased;
  const isEditingMetricDisabled =
    formState?.parentId !== 'custom' && isWorkflowForTemplateActive;

  return (
    <Modal
      title={
        formState.id
          ? t('DataCollection.CustomTopicsForm.ModalTitle.EditTopic')
          : t('DataCollection.CustomTopicsForm.ModalTitle.Topic')
      }
      isOpen={isOpen}
      onClose={onClose}
      variant={StyleVariant.Secondary}
      className={classnames(styles.wrapper, !options && styles.loading)}
      footerChildren={
        options && (
          <div className={styles.buttons}>
            <Button
              text={t('Global.Save')}
              onClick={handleSubmit}
              loading={inProgress}
              disabled={isSubmitDisabled}
            />
            <Button
              styleType={ButtonStyle.Secondary}
              text={t('Global.Cancel')}
              onClick={onClose}
              disabled={inProgress || isDeleteInProgress}
            />
            {!!initialFormState.id && (
              <Button
                className={styles.deleteButton}
                styleType={ButtonStyle.Warning}
                text={t('Global.Delete')}
                onClick={() =>
                  onDelete(initialFormState.id, initialFormState.groupId || '')
                }
                loading={isDeleteInProgress}
                disabled={inProgress}
              />
            )}
          </div>
        )
      }
    >
      {error ? <div className={styles.errorMessage}>{error}</div> : null}
      {options ? (
        <div className={styles.content}>
          <SelectInput
            label={t('Global.Topic')}
            name={'parentId'}
            variant={StyleVariant.Secondary}
            options={topicOptions}
            placeholder={t(
              'DataCollection.CustomTopicsForm.SelectInputPlaceholder.Topic'
            )}
            value={
              formState.parentId === null && formState.id
                ? topicOptions[0].value
                : formState.parentId || ''
            }
            onChange={e => handleTopicOnChange(e.target.value)}
            disabled={!!formState.id}
            errorMessage={t(formError?.name || '')}
            withSeparator={true}
            separatorIndex={0}
          />
          {formState.parentId || formState.id ? (
            <SelectInput
              label={t('Global.UnitType')}
              name={'unitType'}
              variant={StyleVariant.Secondary}
              options={selectedTopic?.unitType.options || []}
              placeholder={t('Global.ChooseType')}
              value={formState.unitType}
              onChange={e => handleChangeUnitType(e.target.value)}
              disabled={isSystemMetrics || isEditingMetricDisabled}
            />
          ) : null}
          {formState.unitType ? (
            <>
              <Input
                label={t('DataCollection.CustomTopicsForm.InputLabel.Topic')}
                name={'reportingName'}
                errorMessage={t(
                  errors.reportingName.message || formError?.reportingName || ''
                )}
                validate={validate}
                value={formState.reportingName}
                onChange={handleOnChange}
                variant={StyleVariant.Secondary}
              />
              <TextArea
                name={'description'}
                label={t('Description')}
                placeholder={t('Enter description')}
                errorMessage={t(
                  errors.description.message || formError?.description || ''
                )}
                validate={validate}
                value={formState.description}
                onChange={handleOnChange}
                variant={StyleVariant.Secondary}
              />
              <TextArea
                name={'question'}
                label={t(
                  'DataCollection.CustomTopicsForm.TextAreaLabel.Question'
                )}
                placeholder={t(
                  'DataCollection.CustomTopicsForm.TextAreaPlaceholder.Question'
                )}
                errorMessage={t(
                  errors.question.message || formError?.question || ''
                )}
                validate={validate}
                value={formState.question}
                onChange={handleOnChange}
                variant={StyleVariant.Secondary}
                disabled={isSystemMetrics}
              />

              {formState.unitType === MetricUnitType.MultiSelect ||
              formState.unitType === MetricUnitType.SingleSelect ? (
                <>
                  {formState.answerOptions.map((answer, idx) => (
                    <div key={answer.value} className={styles.answerWrapper}>
                      <Input
                        label={
                          idx === 0
                            ? t(
                                'DataCollection.CustomTopicsForm.InputLabel.Answer'
                              )
                            : ''
                        }
                        name={'answerOptions'}
                        errorMessage={
                          (errors?.answerOptions?.nestedFields &&
                            errors?.answerOptions?.nestedFields[`${idx}`] &&
                            t(
                              errors?.answerOptions?.nestedFields[`${idx}`][0]
                            )) ||
                          ''
                        }
                        placeholder={`${t('Global.Answer')} ${idx + 1}`}
                        validate={() => true}
                        value={answer.label}
                        onChange={e => handleAnswerOnChange(e, answer.value)}
                        variant={StyleVariant.Secondary}
                        disabled={
                          isSystemMetrics &&
                          selectedTopic?.answerOptions?.some(
                            answerOption => answerOption.value === answer.value
                          )
                        }
                      />
                      <IconButton
                        onClick={() => handleDeleteAnswer(answer.value)}
                        styleType={ButtonStyle.Warning}
                        icon="trash-can"
                        loading={false}
                        title="Trash"
                        className={styles.deleteAnswerButton}
                        disabled={
                          formState.answerOptions.length < 3 ||
                          ((!isAnswersOptionsDeletable || isSystemMetrics) &&
                            selectedTopic?.answerOptions?.some(
                              answerOption =>
                                answerOption.value === answer.value
                            ))
                        }
                      />
                    </div>
                  ))}
                  <Button
                    text={t(
                      'DataCollection.CustomTopicsForm.ButtonLabel.Answer'
                    )}
                    onClick={handleAddAnswer}
                    icon={'plus'}
                    styleType={ButtonStyle.Secondary}
                  />
                </>
              ) : null}

              {formState.unitType === MetricUnitType.Number ||
              formState.unitType === MetricUnitType.Percentage ||
              formState.unitType === MetricUnitType.Currency ? (
                <>
                  <Input
                    label={t('Global.UnitLabel')}
                    name={'unitLabel'}
                    errorMessage={t(
                      errors.unitLabel.message || formError?.unitLabel || ''
                    )}
                    validate={validate}
                    value={formState.unitLabel}
                    onChange={handleOnChange}
                    variant={StyleVariant.Secondary}
                    disabled={
                      isSystemMetrics ||
                      isUnitLabelDisabled ||
                      isEditingMetricDisabled
                    }
                  />
                  <SelectInput
                    label={t('Global.ReportingType')}
                    name={'reportingType'}
                    variant={StyleVariant.Secondary}
                    options={selectedTopic?.reportingType.options || []}
                    placeholder={t('Global.ChooseType')}
                    value={formState.reportingType}
                    validate={validate}
                    onChange={handleOnChange}
                    disabled={isSystemMetrics}
                  />
                </>
              ) : null}

              <Toggle
                checked={formState.mandatory}
                label={t('Global.Mandatory')}
                onChange={handleChange}
                name={'mandatory'}
              />
              <TagInput
                label={t(
                  'DataCollection.CustomTopicsForm.TagInputLabel.Standard'
                )}
                value={tagState}
                onChange={handleTagChange}
                disabled={isSystemMetrics}
              />
            </>
          ) : null}
        </div>
      ) : (
        <div className={styles.spinnerWrapper}>
          <ButtonSpinner className={styles.spinner} />
        </div>
      )}
    </Modal>
  );
};

export default CustomTopicsForm;
