import React, {useEffect, useMemo, useState} from 'react';
import CustomYAxisTick from '../../../../global/custom-y-axis-tick/CustomYAxisTick';
import NavCustomLegend from '../../../../fund-overview/fund-performance-widget/components/nav-custom-legend/NavCustomLegend';
import {totalValueLegendItems, totalValueTooltipOrder} from '../../consts';
import {SegmentedControlVariants} from '../../../../global/segmented-control/SegmentedControl';

import {
  Bar,
  CartesianGrid,
  Cell,
  ComposedChart,
  Label,
  // LabelList,
  Legend,
  Line,
  ReferenceArea,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import {PerformanceWidgetChartProps} from '../../PerformanceWidget';

import styles from './PerformanceTotalValueChart.module.scss';
import FundPerformanceMoicTooltip from '../../../../fund-overview/fund-performance-widget/components/fund-performance-moic-chart/components/FundPerformanceMoicTooltip';
// import {valueFormat} from '../../../../../utils/value-format';
import {useChartAnimated, useChartHover} from '../../../../../hooks';
import {
  BundledExportProps,
  ButtonStyle,
  NavTimeSeries,
  TicksType,
} from '../../../../../types';
import {
  IconButton,
  LiveDataChartLabelContent,
  LiveDataChartShape,
} from '../../../../global';
import {
  getPerformanceTotalValueImagesExportPayload,
  performanceTotalValueChartSettings,
} from './PerformanceTotalValueChartSettings';
import {genPortfolioLegendActions} from '../../../../../utils/performanceWidget';
import {
  calcDomainRange,
  calcXaxisPadding,
  calcXaxisTicks,
} from '../../../../../utils/benchmarking';
import NavXAXisTick from '../../../../fund-overview/fund-performance-widget/components/fund-performance-nav-chart/components/NavXAxisTick';
import {getHalfBarWidthInTime} from '../../../../../utils';

const CHART_X_MARGINS = 70;

const PerformanceTotalValueChart: React.FC<PerformanceWidgetChartProps> = ({
  timeSeries: reportedTimeSeries,
  nearCastingData,
  showExport = true,
  activeKpi,
  setActiveKpi,
  exportHandler,
  yAxisFormat,
  currency,
  entityName,
  isConsolidatedData,
  id,
  legendItems,
  customTooltipLabels,
  signOffDate,
}) => {
  const [selectedBar, setSelectedBar] = useState(null);
  const {ref, isAnimated} = useChartAnimated();
  const {hovered, handleMouseMove, handleMouseLeave} = useChartHover();
  const [exportInProgress, setExportInProgress] = useState<boolean>(false);
  const isNearCastingEmpty =
    !nearCastingData?.timeSeries || !nearCastingData?.timeSeries?.data?.length;
  const timeSeries = useMemo(
    () => ({
      data: [
        ...(reportedTimeSeries?.data || []),
        ...(nearCastingData?.timeSeries?.data || []),
      ],
    }),
    [reportedTimeSeries, nearCastingData]
  );
  const [chartWidth, setChartWidth] = useState(0);
  const chartData = useMemo(() => {
    return (
      timeSeries.data?.map(item => ({
        ...item,
        x: new Date((item as any).date as string),
      })) || []
    );
  }, [timeSeries]);
  const nearCastingStartingFrom = (
    reportedTimeSeries?.data?.slice(-1)[0] as any
  )?.date;

  const getXAxisTicks = useMemo(() => {
    return calcXaxisTicks(
      chartData as NavTimeSeries[],
      chartData?.length > 7 ? TicksType.Year : TicksType.Quarter,
      true
    ).map((item: string) => new Date(item));
  }, [chartData]);

  const {from, to} = useMemo(() => {
    return calcDomainRange(chartData);
  }, [chartData]);
  const halfBarWidthInTime = useMemo(
    () =>
      getHalfBarWidthInTime(
        to,
        from,
        chartData?.length - 1,
        chartWidth - CHART_X_MARGINS
      ),
    [to, from, chartData, chartWidth]
  );
  const onMouseEnterBar = (e: any, index: any) => {
    setSelectedBar(index);
  };

  const onMouseLeaveBar = () => {
    setSelectedBar(null);
  };

  const handleBundledExport = async () => {
    setExportInProgress(true);

    const zipFileName = `${entityName}_TotalValue`;

    const tableSettings = performanceTotalValueChartSettings(zipFileName);

    const tableExportPayload = [
      {
        data: timeSeries.data,
        mappings: tableSettings.headerMapping,
        settings: tableSettings,
      },
    ];

    await exportHandler({
      zipFileName,
      tableExportPayload,
      imagesExportPayload: getPerformanceTotalValueImagesExportPayload(
        zipFileName || '',
        id
      ),
    } as unknown as BundledExportProps);

    setExportInProgress(false);
  };

  useEffect(() => {
    if (ref.current) {
      setChartWidth(ref.current.offsetWidth);
    }

    // Optional: Add event listener for window resize to update width
    const handleResize = () => {
      if (ref.current) {
        setChartWidth(ref.current.offsetWidth);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div ref={ref}>
      <ResponsiveContainer
        height={560}
        width="100%"
        id={`${id}-performance-total-value-chart`}
      >
        <ComposedChart
          data={chartData as any}
          margin={{top: 35, left: 30, right: 40, bottom: 72}}
          onMouseLeave={handleMouseLeave}
          onMouseMove={handleMouseMove}
        >
          <defs>
            <linearGradient
              id="nearCastingGradient"
              x1="24.4311"
              y1="6.58801e-06"
              x2="458.185"
              y2="407.236"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor={`#rgb(var(--colors-nearcasting-gradient2))`} />
              <stop
                offset="1"
                stopColor={`rgb(var(--colors-nearcasting-gradient1))`}
              />
            </linearGradient>
            <linearGradient
              id="costGradient"
              x1="50%"
              y1="0%"
              x2="50%"
              y2="100%"
            >
              <stop
                offset="0%"
                style={{
                  stopColor: 'rgba(var(--colors-orange-peel),1)',
                  stopOpacity: 1,
                }}
              />
              <stop
                offset="100%"
                style={{
                  stopColor: 'rgba(var(--colors-orange-peel),0.35)',
                  stopOpacity: 0.35,
                }}
              />
            </linearGradient>
            <linearGradient
              id="valuationGradient"
              x1="50%"
              y1="0%"
              x2="50%"
              y2="100%"
            >
              <stop
                offset="0%"
                style={{
                  stopColor: '#7327EF',
                  stopOpacity: 0.93,
                }}
              />
              <stop
                offset="100%"
                style={{
                  stopColor: '#9103F0',
                  stopOpacity: 0.93,
                }}
              />
            </linearGradient>
            <linearGradient
              id="realisedGradient"
              x1="50%"
              y1="0%"
              x2="50%"
              y2="100%"
            >
              <stop
                offset="0%"
                style={{
                  stopColor: 'rgba(var(--colors-gray-5),0.80)',
                  stopOpacity: 0.8,
                }}
              />
              <stop
                offset="100%"
                style={{
                  stopColor: 'rgba(var(--colors-gray-7),0.56)',
                  stopOpacity: 0.56,
                }}
              />
            </linearGradient>
            <linearGradient
              id="signOffDataGradient"
              gradientTransform="rotate(0)"
            >
              <stop offset="0%" stopColor={`rgba(65, 65, 65, 0.8)`} />
              <stop offset="50%" stopColor={`rgba(65, 65, 65, 0.51)`} />
              <stop offset="100%" stopColor={`rgba(52, 51, 51, 0.2)`} />
            </linearGradient>
          </defs>
          <CartesianGrid
            vertical={false}
            strokeDasharray="2"
            stroke={`rgb(var(--colors-gray-6))`}
            x={0}
            width={1920}
          />
          {!isNearCastingEmpty && (
            <ReferenceArea
              yAxisId={0}
              x1={new Date(nearCastingStartingFrom).getTime()}
              x2={chartData.slice(-1)[0].x.getTime() + halfBarWidthInTime}
              ifOverflow="visible"
              fill="url(#nearCastingGradient)"
              fillOpacity={0.3}
            />
          )}
          <YAxis
            axisLine={false}
            tickLine={false}
            width={1}
            label={{
              value: currency,
              position: 'top',
              dx: 0,
              dy: -13,
              fontSize: '12px',
              fontWeight: '500',
              fill: `rgb(var( --colors-gray-3))`,
            }}
            tick={
              <CustomYAxisTick
                showAxis={true}
                xOffset={10}
                yOffset={4}
                fill="gray-3"
                fontSize={12}
                format={yAxisFormat}
                fontWeight={500}
              />
            }
          />
          <XAxis
            dataKey="x"
            scale="time"
            type="number"
            domain={[from.getTime(), to.getTime()]}
            interval={0}
            axisLine={false}
            height={60}
            tickLine={false}
            ticks={getXAxisTicks as any}
            padding={calcXaxisPadding(chartData.length)}
            tick={
              <NavXAXisTick
                showAxis={true}
                fill="gray-3"
                fontSize={12}
                yOffset={15}
                xOffset={chartData.length > 7 ? 0 : -5}
                xAxisFormat={chartData?.length > 7 ? 'yyyy' : `QQ 'YY`}
              />
            }
          />
          <Tooltip
            cursor={false}
            offset={45}
            content={
              <FundPerformanceMoicTooltip
                payloadOrder={totalValueTooltipOrder}
                customTooltipLabels={customTooltipLabels}
                headerKey="quarter"
                currency={currency}
              />
            }
          />

          <Bar
            isAnimationActive={isAnimated}
            hide={!isAnimated}
            stackId="0"
            dataKey="Total cost"
            fill="url(#costGradient)"
            stroke={`rgb(var(--colors-orange-peel))`}
            strokeOpacity={0}
            barSize={24}
            onMouseEnter={onMouseEnterBar}
            onMouseLeave={onMouseLeaveBar}
          >
            {timeSeries?.data?.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill="url(#costGradient)"
                opacity={selectedBar === index ? 0.5 : 1}
              />
            ))}
          </Bar>
          <Bar
            isAnimationActive={isAnimated}
            hide={!isAnimated}
            stackId="1"
            dataKey="Total realised"
            fill="url(#realisedGradient)"
            stroke={`rgb(var(--colors-gray-6))`}
            strokeOpacity={0}
            barSize={24}
            onMouseEnter={onMouseEnterBar}
            onMouseLeave={onMouseLeaveBar}
          >
            {timeSeries?.data?.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill="url(#realisedGradient)"
                opacity={selectedBar === index ? 0.5 : 1}
              />
            ))}
          </Bar>
          <Bar
            isAnimationActive={isAnimated}
            hide={!isAnimated}
            stackId="1"
            dataKey="Current valuation"
            fill="url(#valuationGradient)"
            stroke={`rgb(var(--colors-lan-violet))`}
            strokeOpacity={0}
            barSize={24}
            onMouseEnter={onMouseEnterBar}
            onMouseLeave={onMouseLeaveBar}
          >
            {timeSeries?.data?.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill="url(#valuationGradient)"
                opacity={selectedBar === index ? 0.5 : 1}
              />
            ))}
          </Bar>
          {isAnimated && (
            <>
              <Line
                isAnimationActive
                type="stepAfter"
                strokeWidth={3}
                dataKey="Total value"
                connectNulls
                stroke={'rgb(var(--colors-white))'}
                dot={false}
                activeDot={{
                  stroke: 'rgb(var(--colors-white))',
                  fill: 'rgb(var(--colors-white))',
                  strokeWidth: 5,
                  strokeOpacity: 0.5,
                  r: 6.5,
                }}
              >
                {/*LP-3253 - hide chart values label*/}
                {/*<LabelList*/}
                {/*  offset={5}*/}
                {/*  dataKey="Total value"*/}
                {/*  position="insideBottomLeft"*/}
                {/*  fill="rgb(var(--colors-white))"*/}
                {/*  fontSize={12}*/}
                {/*  formatter={LabelFormatter}*/}
                {/*/>*/}
              </Line>
              {!isNearCastingEmpty && (
                <Line
                  isAnimationActive
                  type="stepAfter"
                  strokeWidth={3}
                  dataKey="NC Total value"
                  strokeDasharray="6 4"
                  connectNulls
                  stroke={'rgb(var(--colors-white))'}
                  dot={false}
                  activeDot={{
                    stroke: 'rgb(var(--colors-white))',
                    fill: 'rgb(var(--colors-white))',
                    strokeWidth: 5,
                    strokeOpacity: 0.5,
                    r: 6.5,
                  }}
                >
                  {/*LP-3253 - hide chart values label*/}
                  {/*<LabelList*/}
                  {/*  offset={5}*/}
                  {/*  dataKey="NC Total value"*/}
                  {/*  position="insideBottomLeft"*/}
                  {/*  fill="rgb(var(--colors-white))"*/}
                  {/*  fontSize={12}*/}
                  {/*  formatter={LabelFormatter}*/}
                  {/*/>*/}
                </Line>
              )}
            </>
          )}

          {signOffDate && (
            <ReferenceArea
              x1={new Date(signOffDate).getTime()}
              shape={props => (
                <LiveDataChartShape
                  {...props}
                  rightMargin={100}
                  fill="url(#signOffDataGradient)"
                />
              )}
            >
              {hovered && (
                <Label
                  value={'In-flight data'}
                  content={LiveDataChartLabelContent}
                />
              )}
            </ReferenceArea>
          )}
          <Legend
            iconSize={12}
            verticalAlign="top"
            align="right"
            content={
              <NavCustomLegend
                id="performance-total-value-chart-legend"
                kpiToggle={{
                  actions: genPortfolioLegendActions(isConsolidatedData),
                  value: activeKpi,
                  onChange: setActiveKpi,
                }}
                items={legendItems || totalValueLegendItems}
                buttonVariant={SegmentedControlVariants.Tertiary}
                className={styles.legendRow}
                showBenchmarking={false}
                exportButton={
                  showExport ? (
                    <IconButton
                      className="performance-chart-export"
                      onClick={handleBundledExport}
                      styleType={ButtonStyle.Primary}
                      icon="export"
                      loading={exportInProgress}
                      id="performance-total-value-chart-export-btn"
                    />
                  ) : undefined
                }
              />
            }
            wrapperStyle={{
              paddingLeft: '20px',
              paddingBottom: '56px',
            }}
            formatter={value => (
              <span
                style={{
                  color: 'rgb(var(--colors-gray-4))',
                  fontSize: 12,
                }}
              >
                {value}
              </span>
            )}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

export default PerformanceTotalValueChart;
