import axios from 'axios';
import config from '../config';
import {
  DataCollection,
  DataCollectionWorkflowTableRowItem,
  UpdateActiveWorkflowPayload,
  ManageWorkflowOptions,
  StartWorkflowPayloadExternal,
  StartWorkflowPayloadInternal,
} from '../types/';

export const getDataCollection = async (token: string, page?: number) => {
  const {data} = await axios.get<DataCollection[]>(
    `${config.APP_URI}/data-collections`,
    {
      params: {
        page: page || 1,
      },
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );
  return data[0];
};

export const getManageWorkflowOptions = async (token: string, id: string) => {
  const {data} = await axios.get<ManageWorkflowOptions>(
    `${config.APP_URI}/data-collections/workflow/options/${id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );
  return data;
};

export const startWorkflow = async (
  token: string,
  id: string,
  payload: Omit<
    StartWorkflowPayloadExternal | StartWorkflowPayloadInternal,
    'id' | 'reportingGroupId'
  >
) => {
  const {data} = await axios.put<DataCollectionWorkflowTableRowItem>(
    `${config.APP_URI}/data-collections/workflow/start/${id}`,
    payload,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );
  return data;
};

export const updateActiveWorkflow = async (
  token: string,
  id: string,
  payload: Omit<UpdateActiveWorkflowPayload, 'id' | 'reportingGroupId'>
) => {
  const {data} = await axios.put<DataCollectionWorkflowTableRowItem>(
    `${config.APP_URI}/data-collections/workflow/update-active-workflow/${id}`,
    payload,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );
  return data;
};

export const stopWorkflow = async (token: string, id: string) => {
  const {data} = await axios.put<DataCollectionWorkflowTableRowItem>(
    `${config.APP_URI}/data-collections/workflow/stop/${id}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );
  return data;
};
