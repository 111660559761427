import React, {useMemo} from 'react';
import classnames from 'classnames';
import {
  DropdownWrapperAlignmentType,
  Func,
  GroupedSelectOption,
  SelectOption,
} from '../../../types/';
import {DropdownWrapper, DropdownOption} from '../index';
import styles from './SelectDropdown.module.scss';
import {useTranslation} from 'react-i18next';
import {formatWithLocale} from '../../../utils';

interface SelectDropdownProps {
  id: string;
  label?: string;
  onChange: Func<[GroupedSelectOption['value']], void>;
  options: GroupedSelectOption[] | GroupedSelectOption[][];
  value: GroupedSelectOption['value'];
  placeHolderKey?: string;
  disabled?: boolean;
  loading?: boolean;
  className?: string;
  alignment?: DropdownWrapperAlignmentType;
  fullContent?: boolean;
}

const SelectDropdown: React.FC<SelectDropdownProps> = ({
  id,
  label,
  options,
  value,
  onChange,
  placeHolderKey,
  disabled = false,
  loading = false,
  fullContent = false,
  className = '',
  alignment,
}) => {
  const {t} = useTranslation();
  const currentKey = useMemo(() => {
    let currentKey: string | number = '';
    const getCurrentKeyWithDate = (item: GroupedSelectOption) =>
      item?.date && item?.format
        ? `${item.label} ${formatWithLocale(new Date(item.date), item.format)}`
        : item.label;
    options.forEach(item => {
      if (Array.isArray(item)) {
        item.forEach(item => {
          if (item.value === value) {
            currentKey = getCurrentKeyWithDate(item);
          }
        });
      } else if (item.value === value) {
        currentKey = getCurrentKeyWithDate(item);
      }
    });
    return currentKey;
  }, [options, value]);

  const handleClickOption =
    (handleCloseDropdown: Func<[], void>) => (value: SelectOption['value']) => {
      onChange(value);
      handleCloseDropdown();
    };

  const getOptionLabel = (item: GroupedSelectOption) => {
    const label = item.label as string;
    const translatedLabel: string = t(label as string, label as string); // If there is no matching key pass label as default value
    return item?.date && item?.format
      ? `${translatedLabel} ${formatWithLocale(
          new Date(item.date),
          item.format
        )}`
      : (translatedLabel as string);
  };

  return (
    <DropdownWrapper
      label={t(placeHolderKey || currentKey)}
      subLabel={t(label || '')}
      disabled={disabled}
      loading={loading}
      className={className}
      alignment={alignment}
      id={id}
    >
      {({handleCloseDropdown}) => (
        <div id={id} data-test={id} className={styles.dropdown}>
          <div
            className={classnames(
              styles.dropdownInner,
              fullContent ? styles.fullContent : ''
            )}
          >
            {options.map((item, idx) =>
              Array.isArray(item) ? (
                <div key={idx} className={styles.group}>
                  {item[0]?.groupLabel ? (
                    <div className={styles.groupLabel}>
                      {t(item[0].groupLabel)}
                    </div>
                  ) : null}
                  <div className={styles.groupItems}>
                    {item.map(item2 => (
                      <DropdownOption
                        id={`${id}-${item2.id}`}
                        value={item2.value}
                        label={getOptionLabel(item2)}
                        isActive={item2.value === value}
                        handleClickOption={handleClickOption(
                          handleCloseDropdown
                        )}
                        key={item2.value}
                        disabled={item2.disabled}
                      />
                    ))}
                  </div>
                </div>
              ) : (
                <DropdownOption
                  id={`${id}-${item.id}`}
                  value={item.value}
                  label={getOptionLabel(item)}
                  isActive={item.value === value}
                  handleClickOption={handleClickOption(handleCloseDropdown)}
                  key={item.value}
                  iconName={item.value === 'new' ? 'plus' : 'checkbox'}
                  disabled={item.disabled}
                />
              )
            )}
          </div>
        </div>
      )}
    </DropdownWrapper>
  );
};

export default SelectDropdown;
