import React, {ChangeEvent, useEffect, useRef, useState} from 'react';
import {useIMask} from 'react-imask';
import {InputProps, StyleVariant} from '../../../../types';
import Input from '../../input/Input';
import {ReportingTableErrorMessages} from '../../../../types';

interface MaskedNumberInputProps extends InputProps {
  type?: string;
  label: string;
  initialValue?: string;
  invalid?: boolean;
  maskType: string;
  suffix?: string;
  disabled?: boolean;
}

const MaskedNumberInput = ({
  label,
  name,
  errorMessage,
  showErrorMessage = false,
  initialValue,
  onChange,
  maskType,
  placeholder,
  suffix,
  disabled = false,
  ...props
}: MaskedNumberInputProps) => {
  const [maskedErrorMessage, setMaskedErrorMessage] = useState('');
  const initialValueRef = useRef<number | string | undefined>(
    initialValue ?? ''
  );
  // IMask number options
  const numberMaskOptions = {
    mask: Number,
    skipInvalid: false,
    overwrite: false,
    lazy: false,

    scale: 2,
    thousandsSeparator: ',',
    padFractionalZeros: true,
    normalizeZeros: true,
    radix: '.',
    mapToRadix: ['.'],

    min: -Infinity,
    max: Infinity,
  };

  // IMask percentage options
  const percentMaskOptions = {
    mask: 'percent',
    autofix: true,
    lazy: false,
    blocks: {
      percent: {
        mask: Number,
        expose: true,
        skipInvalid: false,
        overwrite: false,

        scale: 2,
        thousandsSeparator: ',',
        padFractionalZeros: true,
        normalizeZeros: true,
        radix: '.',
        mapToRadix: ['.'],

        min: 0,
        max: 100,
      },
    },
  };

  let opts;

  switch (maskType) {
    case 'number':
      opts = numberMaskOptions;
      break;
    case 'percent':
      opts = percentMaskOptions;
      break;
    default:
      opts = numberMaskOptions;
  }

  // @ts-ignore
  const {ref, value, setValue} = useIMask<HTMLInputElement>(opts, {
    onAccept: (value, mask) => {
      if (mask.unmaskedValue === initialValueRef.current) return;
      if (onChange) {
        initialValueRef.current = undefined;
        onChange({
          target: {
            value: mask.unmaskedValue ? +mask.unmaskedValue : '',
            name: name,
          },
        } as unknown as ChangeEvent<HTMLInputElement>);
      }
    },
  });

  const handleOnInput = (e: React.FormEvent<HTMLInputElement> | undefined) => {
    setMaskedErrorMessage('');
    const nativeEvent: InputEvent = e?.nativeEvent as InputEvent;
    const inputData = nativeEvent.data;

    if (
      inputData !== '.' &&
      inputData !== '-' &&
      inputData !== '+' &&
      Number.isNaN(Number(inputData))
    ) {
      if (inputData === ',') {
        setMaskedErrorMessage(ReportingTableErrorMessages.dotsOnlyForDecimals);
      } else {
        setMaskedErrorMessage(ReportingTableErrorMessages.numbersOnly);
      }
    }
  };

  useEffect(() => {
    if (value === '' || value === '%') {
      initialValue && setValue(initialValue);
    }
  }, []);

  return (
    <Input
      {...props}
      type="text"
      label={label}
      suffix={suffix}
      name={name}
      placeholder={placeholder}
      errorMessage={errorMessage || maskedErrorMessage}
      showErrorMessage={showErrorMessage || !!maskedErrorMessage}
      validate={() => true}
      defaultValue={initialValue}
      variant={StyleVariant.Secondary}
      maskedRef={ref}
      onInput={handleOnInput}
      onBlur={() => setMaskedErrorMessage('')}
      disabled={disabled}
    />
  );
};

export default MaskedNumberInput;
