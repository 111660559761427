import {TableSettings} from '../../../types';

export const fundTransactionsTableSettings = (
  zipFileName: string,
  columnVisibility: Record<string, boolean>
): TableSettings => {
  return {
    fileName: zipFileName,
    name: 'Portfolio Transactions',
    columnVisibility,
    columnOrder: [
      'companyName',
      'instrumentType',
      'currency',
      'shares',
      'date',
      'cost',
      'realisedGains',
      'investmentIncome',
      'file',
    ],
    meta: {
      columns: {
        companyName: {
          styling: {
            align: 'left',
          },
        },
        instrumentType: {
          styling: {
            align: 'left',
          },
        },
        currency: {
          styling: {
            align: 'left',
          },
        },
        shares: {
          styling: {
            align: 'left',
          },
        },
        date: {
          styling: {
            align: 'left',
          },
          formatter: 'numFmt:dd-mm-yyyy',
        },
        cost: {
          styling: {
            align: 'left',
          },
        },
        realisedGains: {
          styling: {
            align: 'left',
          },
        },
        investmentIncome: {
          styling: {
            align: 'right',
          },
        },
        file: {
          styling: {
            align: 'right',
          },
        },
      },
    },
  };
};
