import React from 'react';
import classnames from 'classnames';
import {valueFormat} from '../../../utils/value-format';
import {SvgLogo} from '../../global/svg-logo/SvgLogo';
import styles from './NavInfo.module.scss';
import {useNavInfo} from '../../../hooks/';

interface NavInfoProps {
  isSticky: boolean;
}

const NavInfo: React.FC<NavInfoProps> = ({isSticky}) => {
  const navInfoData = useNavInfo();

  const {name, color, withLogo, asAt, allCompaniesCount} = navInfoData;

  if (name) {
    return (
      <div className={classnames(styles.wrapper, !isSticky && styles.hidden)}>
        {withLogo ? (
          <SvgLogo title={name} bgColor={color || ''} className={styles.logo} />
        ) : (
          <div
            style={{backgroundColor: `rgb(var(--colors-${color}))`}}
            className={styles.colorLine}
          />
        )}
        <div className={styles.title}>{name}</div>
        <div className={styles.subTitle}>
          {asAt &&
            `${asAt.label} ${valueFormat(asAt.value, asAt.format).value}`}
          {allCompaniesCount && allCompaniesCount}
        </div>
      </div>
    );
  }

  return null;
};

export default NavInfo;
