import React, {useRef} from 'react';
import {useTooltip} from '../../../../../hooks';
import {Position} from '../../../../../context/TooltipContext';
import {UpdateNewsPayload} from '../../../../../types';
import Icon from '../../../Icon/Icon';
import styles from './FavoriteCell.module.scss';

type FavoriteCellProps = {
  id: string;
  favorite: boolean;
  favoriteTooltip: string;
  onUpdateNews: (payload: UpdateNewsPayload) => void;
};
const FavoriteCell: React.FC<FavoriteCellProps> = ({
  id,
  favorite,
  onUpdateNews,
  favoriteTooltip,
}) => {
  const {showTooltip, hideTooltip} = useTooltip();

  const wrapperRef = useRef<HTMLDivElement>(null);

  const handleShowTooltip = () => {
    if (!wrapperRef.current || !favorite) return;
    showTooltip({
      content: favoriteTooltip,
      target: wrapperRef.current,
      position: Position.Top,
      hasArrow: false,
    });
  };

  return (
    <div
      ref={wrapperRef}
      onMouseEnter={handleShowTooltip}
      onMouseLeave={hideTooltip}
      className={styles.favoriteCellWrapper}
    >
      <Icon
        name={favorite ? 'star-filled' : 'star-outline'}
        onClick={event => {
          event.stopPropagation();
          onUpdateNews({
            id: id,
            favorite: !favorite,
          });
        }}
      />
    </div>
  );
};

export default FavoriteCell;
