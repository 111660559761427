import React, {useMemo, useEffect, useState} from 'react';
import classnames from 'classnames';
import {
  Func,
  DropdownWrapperAlignmentType,
  ButtonStyle,
} from '../../../types/index';
import {DropdownWrapper} from '../index';
import {Button} from '../../global';
import styles from './AsAtController.module.scss';
import {format, getQuarter, isValid, parse, setQuarter} from 'date-fns';
import {useTranslation} from 'react-i18next';

interface AsAtControllerProps {
  label: string;
  startDate: string;
  endDate: string;
  onChange: Func<[string], void>;
  value: string;
  alignment?: DropdownWrapperAlignmentType;
  disabled?: boolean;
  className?: string;
}

const AsAtController: React.FC<AsAtControllerProps> = ({
  label,
  value,
  startDate,
  endDate,
  onChange,
  alignment = DropdownWrapperAlignmentType.Right,
  disabled = false,
  className,
}) => {
  const {t} = useTranslation();
  const [activeYear, setActiveYear] = useState<string | null>(
    `${new Date(`${value}`).getFullYear()}`
  );
  const [activeQuarter, setActiveQuarter] = useState<number | null>(
    getQuarter(new Date(value))
  );

  const currentKey = useMemo(() => {
    const year = activeYear
      ? activeYear
      : `${new Date(`${value}`).getFullYear()}`;
    const quarter = activeQuarter ? activeQuarter : getQuarter(new Date(value));
    return `Q${quarter} ${year}`;
  }, [activeYear, value, activeQuarter]);

  const isDisabled = useMemo<boolean>(() => {
    const startYear = new Date(startDate).getFullYear();
    const startQuarter = getQuarter(new Date(startDate));
    const endYear = new Date(endDate).getFullYear();
    const endQuarter = getQuarter(new Date(endDate));
    return startQuarter === endQuarter && startYear === endYear;
  }, [startDate, endDate]);

  const handleClickCancel = (handleCloseDropdown: Func<[], void>) => () => {
    setActiveQuarter(getQuarter(new Date(value)));
    setActiveYear(`${new Date(value).getFullYear()}`);
    handleCloseDropdown();
  };

  const handleClickApply = (handleCloseDropdown: Func<[], void>) => () => {
    if (activeYear !== null && activeQuarter !== null) {
      onChange(
        format(setQuarter(new Date(activeYear), activeQuarter), 'dd-MM-yyyy')
      );
      handleCloseDropdown();
    }
  };

  const isQuarterDisabled = (quarter: number) => {
    if (activeYear === new Date(startDate).getFullYear().toString()) {
      return quarter < getQuarter(new Date(startDate));
    }

    if (activeYear === new Date(endDate).getFullYear().toString()) {
      return quarter > getQuarter(new Date(endDate));
    }
    return false;
  };

  const handleOnYearClick = (year: string) => {
    setActiveYear(`${year}`);

    if (
      year === new Date(startDate).getFullYear().toString() &&
      activeQuarter &&
      activeQuarter < getQuarter(new Date(startDate))
    ) {
      setActiveQuarter(null);
    }

    if (
      year === new Date(endDate).getFullYear().toString() &&
      activeQuarter &&
      activeQuarter > getQuarter(new Date(endDate))
    ) {
      setActiveQuarter(null);
    }
  };

  const years = useMemo(() => {
    const years: string[] = [];
    const startYear = new Date(startDate).getFullYear();
    const endYear = new Date(endDate).getFullYear();
    for (let i = 0; i <= endYear - startYear; i++) {
      years.push(`${startYear + i}`);
    }
    return years;
  }, [startDate, endDate]);

  useEffect(() => {
    const parsedDate = parse(value, 'dd-MM-yyyy', new Date());
    const date = isValid(parsedDate) ? parsedDate : new Date(`${value}`);
    setActiveYear(`${date.getFullYear()}`);
    setActiveQuarter(getQuarter(date));
  }, [value]);

  if (isDisabled) return null;

  return (
    <DropdownWrapper
      label={`${label || ''} ${currentKey}`}
      alignment={alignment}
      className={className}
      disabled={disabled}
    >
      {({handleCloseDropdown}) => (
        <div className={styles.dropdown}>
          <div className={styles.group} role="listbox">
            <div className={styles.groupTitle}>{t('AsAtControler.AsAt')}</div>
            {years.map(item => (
              <div
                className={classnames(
                  styles.groupButton,
                  `${item}` === activeYear && styles.active
                )}
                onClick={() => handleOnYearClick(item)}
                key={item}
                data-test="as-at-year-btn"
                aria-selected={`${item}` === activeYear}
                role="option"
              >
                {item}
              </div>
            ))}
          </div>
          <div className={styles.group} role="listbox">
            <div className={styles.groupTitle}>
              {t('AsAtControler.ChooseQuarter')}
            </div>
            {Array.from(Array(4), (_, idx) => (
              <div
                className={classnames(
                  styles.groupButton,
                  idx + 1 === activeQuarter && styles.active,
                  isQuarterDisabled(idx + 1) && styles.disabled
                )}
                onClick={() => setActiveQuarter(idx + 1)}
                key={idx}
                data-test="as-at-quarter-btn"
                aria-selected={idx + 1 === activeQuarter}
                role="option"
              >
                Q{idx + 1}
              </div>
            ))}
          </div>
          <div className={styles.actions}>
            <Button
              onClick={handleClickApply(handleCloseDropdown)}
              styleType={ButtonStyle.Primary}
              text={t('Global.Apply')}
              className={styles.lightThemePrimaryButton}
              disabled={activeYear === null || activeQuarter === null}
            />
            <Button
              onClick={handleClickCancel(handleCloseDropdown)}
              text={t('Global.Cancel')}
              styleType={ButtonStyle.Secondary}
              className={styles.lightThemeSecondaryButton}
            />
          </div>
        </div>
      )}
    </DropdownWrapper>
  );
};

export default AsAtController;
