import React from 'react';
import styles from './DeltaIndicator.module.scss';
import {DeltaIndicatorProps} from '../../../../../../../types/index';
import {useTooltip} from '../../../../../../../hooks';
import {Position} from '../../../../../../../context/TooltipContext';
import {Icon} from '../../../../../../global';
import {useTranslation} from 'react-i18next';

const DeltaIndicator: React.FC<DeltaIndicatorProps> = ({
  isUp,
  value,
}): JSX.Element => {
  const {t} = useTranslation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const {showTooltip, hideTooltip} = useTooltip();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleMouseEnter = (e: React.MouseEvent) => {
    showTooltip({
      target: e.currentTarget as HTMLElement,
      content: t('Global.CompareQuarters'),
      position: Position.Top,
    });
  };

  return (
    <div
      className={styles.deltaWrapper}
      // onMouseEnter={handleMouseEnter}
      // onMouseLeave={hideTooltip}
    >
      {isUp ? (
        <Icon className={styles.icon} name={'stats-up'} />
      ) : (
        <Icon className={styles.icon} name={'stats-down'} />
      )}
      {value && (
        <div>
          <p className={styles.deltaValue}>
            {isUp && '+'}
            {value}
          </p>
        </div>
      )}
    </div>
  );
};

export default DeltaIndicator;
