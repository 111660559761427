import React from 'react';
import {ColumnDef, createColumnHelper} from '@tanstack/react-table';
import Th from '../cells/th/Th';
import Td from '../cells/td/Td';
import {
  FlexHAlign,
  Func,
  HeaderMapping,
  ReportingPeriodsTableRowItem,
} from '../../../../types';
import {Checkbox, TextTruncation} from '../../index';
import styles from './SelectReportingPeriodsTableColumnDefs.module.scss';

const genSelectReportingPeriodsTableColumnDefs = (
  headerMapping: HeaderMapping[],
  currentSelectedReportingPeriods: String[],
  checkHandler: Func<any, any>
): ColumnDef<ReportingPeriodsTableRowItem, any>[] => {
  const columnHelper = createColumnHelper<ReportingPeriodsTableRowItem>();

  return [
    columnHelper.accessor('isSelected', {
      id: 'isSelected',
      header: props => <th style={{width: '35px'}}></th>,
      cell: props => (
        <Td
          {...props}
          align={FlexHAlign.start}
          width={'35px'}
          canHide={false}
          skipExtend={true}
        >
          <Checkbox
            onChange={e =>
              checkHandler(e.target.checked, props.row.original.id)
            }
            label={''}
            checked={currentSelectedReportingPeriods.includes(
              props.row.original.id
            )}
          />
        </Td>
      ),
    }),
    columnHelper.accessor('portfolioCompany', {
      id: 'portfolioCompany',
      header: props => (
        <Th
          {...props}
          headerMapping={headerMapping}
          align={FlexHAlign.start}
          width={'max(100vw*0.13, 150px)'}
        />
      ),
      cell: props => (
        <Td
          {...props}
          align={FlexHAlign.start}
          canHide={false}
          skipExtend={true}
        >
          <TextTruncation
            text={props?.getValue<string>()}
            tooltipClassname={styles.tooltipWrapper}
          />
        </Td>
      ),
    }),
    columnHelper.accessor('reportingGroup', {
      id: 'reportingGroup',
      header: props => (
        <Th
          {...props}
          headerMapping={headerMapping}
          align={FlexHAlign.start}
          width={'max(100vw*0.13, 150px)'}
        />
      ),
      cell: props => (
        <Td
          {...props}
          align={FlexHAlign.start}
          canHide={false}
          skipExtend={true}
        >
          <TextTruncation
            text={props?.getValue<string>()}
            tooltipClassname={styles.tooltipWrapper}
          />
        </Td>
      ),
    }),
    columnHelper.accessor(row => row.period?.value, {
      id: 'period',
      header: props => (
        <Th
          headerMapping={headerMapping}
          {...props}
          align={FlexHAlign.start}
          width={'max(100vw*0.09, 120px)'}
        />
      ),
      cell: props => (
        <Td
          {...props}
          align={FlexHAlign.start}
          nestedAccessorFn={original => original.period}
          skipExtend={true}
        />
      ),
    }),
    columnHelper.accessor('template', {
      id: 'template',
      header: props => (
        <Th
          {...props}
          headerMapping={headerMapping}
          align={FlexHAlign.start}
          width={'max(100vw*0.09, 120px)'}
        />
      ),
      cell: props => (
        <Td {...props} align={FlexHAlign.start} skipExtend={true}>
          <TextTruncation
            text={props?.getValue<string>()}
            tooltipClassname={styles.tooltipWrapper}
          />
        </Td>
      ),
    }),
    columnHelper.accessor('report', {
      id: 'report',
      header: props => (
        <Th
          {...props}
          headerMapping={headerMapping}
          align={FlexHAlign.start}
          width={'max(100vw*0.04, 65px)'}
        />
      ),
      cell: props => (
        <Td {...props} align={FlexHAlign.start} skipExtend={true}>
          <TextTruncation
            text={props?.getValue<string>()}
            tooltipClassname={styles.tooltipWrapper}
          />
        </Td>
      ),
    }),
    columnHelper.accessor('files', {
      id: 'files',
      header: props => (
        <Th
          {...props}
          headerMapping={headerMapping}
          align={FlexHAlign.end}
          width={'50px'}
        />
      ),
      cell: props => (
        <Td
          {...props}
          align={FlexHAlign.end}
          skipExtend={true}
          emptyValue={'-'}
        />
      ),
    }),
    columnHelper.accessor(row => row.status, {
      id: 'status',
      header: props => (
        <Th
          headerMapping={headerMapping}
          {...props}
          align={FlexHAlign.start}
          width={'max(100vw*0.05, 70px)'}
        />
      ),
      cell: props => (
        <Td {...props} align={FlexHAlign.start} skipExtend={true} />
      ),
    }),
  ];
};

export default genSelectReportingPeriodsTableColumnDefs;
