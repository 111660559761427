import {
  AddTemplateConfigDetailsCustomGroupPayload,
  ClientAdminTemplateConfigDetails,
  FormStatus,
  UpdateReportingGroupTemplatePayload,
  UpdateTemplateConfigDetailsCustomGroupPayload,
  UserRole,
  AddTemplateConfigDetailsCustomMetricsPayload,
  UpdateTemplateConfigDetailsCustomMetricsPayload,
  UpdateTemplateConfigDetailsGroupOrderPayload,
  TemplateConfigDetailsCustomGroup,
  TemplateConfigDetailsCustomGroupItem,
  ForecastGroupsAndMetricsFormGroup,
  UpdateForecastGroupsAndMetricsPayload,
} from '../../types';
import {all, call, put, select, takeEvery} from 'redux-saga/effects';
import {acquireAuthResult, msalInstance} from '../../index';
import {
  addTemplateConfigDetailsCustomGroup,
  addTemplateConfigDetailsCustomMetric,
  getTemplateConfigDetailsData,
  removeReportingGroupTemplate,
  removeTemplateConfigDetailsMetric,
  removeTemplateConfigDetailsCustomGroup,
  updateReportingGroupTemplate,
  updateTemplateConfigDetailsCustomGroup,
  updateTemplateConfigDetailsCustomMetrics,
  updateTemplateConfigDetailsGroupsOrder,
  getForecastGroupAndMetricsFormOptions,
  updateForecastGroupsAndMetrics,
} from '../../api/getClientAdminTemplateConfigDetails';
import {errorHandlerAction} from '../../actions';
import {
  addTemplateConfigDetailsCustomGroupAction,
  addTemplateConfigDetailsCustomMetricAction,
  clearClientAdminTemplateConfigDetailsData,
  removeReportingGroupTemplateAction,
  removeTemplateConfigDetailsCustomGroupAction,
  removeTemplateConfigDetailsCustomMetricAction,
  setClientAdminTemplateConfigDetailsData,
  setClientAdminTemplateConfigDetailsError,
  setClientAdminTemplateConfigDetailsFormError,
  setClientAdminTemplateConfigDetailsFormStatus,
  setClientAdminTemplateConfigDetailsIsDeleteInProgress,
  setTemplateName,
  updateReportingGroupTemplateAction,
  updateTemplateConfigDetailsCustomGroupAction,
  updateTemplateConfigDetailsCustomMetricAction,
  updateTemplateConfigDetailsGroupsOrderAction,
  setClientAdminTemplateConfigDetailsNewGroup,
  updateClientAdminTemplateConfigDetailsGroup,
  deleteClientAdminTemplateConfigDetailsGroup,
  deleteClientAdminTemplateConfigCustomMetric,
  getForecastGroupsAndMetricsOptionsAction,
  setForecastGroupsAndMetricsOptions,
  updateForecastGroupsAndMetricsAction,
  setClientAdminTemplateConfigGroups,
} from '../../reducers/clientAdminTemplateConfigDetailsSlice';
import {PayloadAction} from '@reduxjs/toolkit';
import {push, replace} from 'connected-react-router';
import routePaths from '../../routePaths';
import {RootState} from '../../store';
import {responseErrorResolver} from '../../utils';
import {clearClientAdminTemplateConfigData} from '../../reducers/clientAdminTemplateConfigSlice';
import i18n from '../../i18n';

export const clientAdminTemplateConfigDetailsSaga =
  function* clientAdminTemplateConfigDetailsSaga({payload}: any): any {
    const id: string = payload?.match?.params?.id;
    if (id) {
      const accountInfo = msalInstance.getAllAccounts();
      if (accountInfo?.length > 0) {
        const userRole =
          accountInfo[0].idTokenClaims?.extension_ApplicationRole;
        if (userRole !== UserRole.ClientAdmin) {
          return yield put(replace(routePaths.HOME));
        }
        yield put(clearClientAdminTemplateConfigDetailsData());
        try {
          const {accessToken} = yield call(acquireAuthResult, msalInstance);
          const response: ClientAdminTemplateConfigDetails = yield call(
            getTemplateConfigDetailsData,
            accessToken,
            id
          );
          yield put(setClientAdminTemplateConfigDetailsData(response));
        } catch (err) {
          console.warn(err);
          yield put(errorHandlerAction({error: err}));
        }
      }
    }
  };

export const updateReportingGroupTemplateSaga =
  function* updateReportingGroupTemplateSaga({
    payload,
  }: PayloadAction<UpdateReportingGroupTemplatePayload>) {
    if (payload) {
      yield call(templateConfigDetailsErrorHelperSaga);
      try {
        const {accessToken} = yield call(acquireAuthResult, msalInstance);
        yield put(
          setClientAdminTemplateConfigDetailsFormStatus(FormStatus.Pending)
        );
        const response: {name: string} = yield call(
          updateReportingGroupTemplate,
          accessToken,
          payload.id,
          payload
        );
        yield put(setTemplateName(response));
        yield put(
          setClientAdminTemplateConfigDetailsFormStatus(FormStatus.Success)
        );
      } catch (err) {
        console.warn(err);
        yield call(
          templateConfigDetailsResponseErrorHelperSaga,
          err,
          i18n.t('DataCollection.ReportingGroupTemplate.Update.Error')
        );
      }
    }
  };

export const removeReportingGroupTemplateSaga =
  function* removeReportingGroupTemplateSaga({
    payload,
  }: PayloadAction<Pick<UpdateReportingGroupTemplatePayload, 'id'>>) {
    if (payload) {
      yield call(templateConfigDetailsErrorHelperSaga);
      yield put(setClientAdminTemplateConfigDetailsIsDeleteInProgress(true));
      try {
        const {accessToken} = yield call(acquireAuthResult, msalInstance);
        yield put(
          setClientAdminTemplateConfigDetailsFormStatus(FormStatus.Pending)
        );
        yield call(removeReportingGroupTemplate, accessToken, payload.id);
        yield put(
          setClientAdminTemplateConfigDetailsFormStatus(FormStatus.Ready)
        );
        yield put(setClientAdminTemplateConfigDetailsIsDeleteInProgress(false));
        yield put(clearClientAdminTemplateConfigData());
        yield put(
          push(routePaths.ADMIN_DATA_COLLECTION_TEMPLATE_CONFIGURATION)
        );
      } catch (err) {
        console.warn(err);
        yield call(
          templateConfigDetailsResponseErrorHelperSaga,
          err,
          i18n.t('DataCollection.ReportingGroupTemplate.Delete.Error')
        );
      }
    }
  };

export const addTemplateConfigDetailsCustomGroupSaga =
  function* addTemplateConfigDetailsCustomGroupSaga({
    payload,
  }: PayloadAction<AddTemplateConfigDetailsCustomGroupPayload>) {
    const templateId: string | undefined = yield select(
      (state: RootState) =>
        state.dataCollectionAdministration.templateConfigurationDetails.data?.id
    );
    if (payload && templateId) {
      yield call(templateConfigDetailsErrorHelperSaga);
      try {
        const {accessToken} = yield call(acquireAuthResult, msalInstance);
        yield put(
          setClientAdminTemplateConfigDetailsFormStatus(FormStatus.Pending)
        );
        const response: TemplateConfigDetailsCustomGroup<TemplateConfigDetailsCustomGroupItem> =
          yield call(addTemplateConfigDetailsCustomGroup, accessToken, payload);
        yield put(setClientAdminTemplateConfigDetailsNewGroup(response));
        yield put(
          setClientAdminTemplateConfigDetailsFormStatus(FormStatus.Success)
        );
      } catch (err: any) {
        console.warn(err);
        yield call(
          templateConfigDetailsResponseErrorHelperSaga,
          err,
          i18n.t('DataCollection.ReportingGroupTemplate.AddNewGroup.Error')
        );
      }
    }
  };

export const updateTemplateConfigDetailsCustomGroupSaga =
  function* updateTemplateConfigDetailsCustomGroupSaga({
    payload,
  }: PayloadAction<UpdateTemplateConfigDetailsCustomGroupPayload>) {
    const templateId: string | undefined = yield select(
      (state: RootState) =>
        state.dataCollectionAdministration.templateConfigurationDetails.data?.id
    );
    if (payload && templateId) {
      yield call(templateConfigDetailsErrorHelperSaga);
      try {
        const {accessToken} = yield call(acquireAuthResult, msalInstance);
        yield put(
          setClientAdminTemplateConfigDetailsFormStatus(FormStatus.Pending)
        );

        const response: TemplateConfigDetailsCustomGroup<TemplateConfigDetailsCustomGroupItem> =
          yield call(
            updateTemplateConfigDetailsCustomGroup,
            accessToken,
            payload.parentId,
            payload
          );
        yield put(updateClientAdminTemplateConfigDetailsGroup(response));
        yield put(
          setClientAdminTemplateConfigDetailsFormStatus(FormStatus.Success)
        );
      } catch (err: any) {
        console.warn(err);
        yield call(
          templateConfigDetailsResponseErrorHelperSaga,
          err,
          i18n.t('DataCollection.ReportingGroupTemplate.EditGroup.Error')
        );
      }
    }
  };

export const addTemplateConfigDetailsCustomMetricSaga =
  function* addTemplateConfigDetailsCustomMetricSaga({
    payload,
  }: PayloadAction<AddTemplateConfigDetailsCustomMetricsPayload>) {
    const templateId: string | undefined = yield select(
      (state: RootState) =>
        state.dataCollectionAdministration.templateConfigurationDetails.data?.id
    );
    if (payload && templateId) {
      yield call(templateConfigDetailsErrorHelperSaga);
      try {
        const {accessToken} = yield call(acquireAuthResult, msalInstance);
        yield put(
          setClientAdminTemplateConfigDetailsFormStatus(FormStatus.Pending)
        );
        const response: TemplateConfigDetailsCustomGroup<TemplateConfigDetailsCustomGroupItem> =
          yield call(
            addTemplateConfigDetailsCustomMetric,
            accessToken,
            payload
          );
        yield put(updateClientAdminTemplateConfigDetailsGroup(response));
        yield put(
          setClientAdminTemplateConfigDetailsFormStatus(FormStatus.Success)
        );
      } catch (err: any) {
        console.warn(err);
        yield call(
          templateConfigDetailsResponseErrorHelperSaga,
          err,
          i18n.t('DataCollection.ReportingGroupTemplate.AddNewMetric.Error')
        );
      }
    }
  };

export const updateTemplateConfigDetailsCustomMetricSaga =
  function* updateTemplateConfigDetailsCustomMetricSaga({
    payload,
  }: PayloadAction<UpdateTemplateConfigDetailsCustomMetricsPayload>) {
    const templateId: string | undefined = yield select(
      (state: RootState) =>
        state.dataCollectionAdministration.templateConfigurationDetails.data?.id
    );
    if (payload && templateId) {
      yield call(templateConfigDetailsErrorHelperSaga);
      try {
        const {accessToken} = yield call(acquireAuthResult, msalInstance);
        yield put(
          setClientAdminTemplateConfigDetailsFormStatus(FormStatus.Pending)
        );

        const response: TemplateConfigDetailsCustomGroup<TemplateConfigDetailsCustomGroupItem> =
          yield call(
            updateTemplateConfigDetailsCustomMetrics,
            accessToken,
            payload.id,
            payload
          );
        yield put(updateClientAdminTemplateConfigDetailsGroup(response));
        yield put(
          setClientAdminTemplateConfigDetailsFormStatus(FormStatus.Success)
        );
      } catch (err: any) {
        console.warn(err);
        yield call(
          templateConfigDetailsResponseErrorHelperSaga,
          err,
          i18n.t('DataCollection.ReportingGroupTemplate.EditMetric.Error')
        );
      }
    }
  };

export const removeTemplateConfigDetailsCustomMetricSaga =
  function* removeTemplateConfigDetailsCustomMetricSaga({
    payload,
  }: PayloadAction<{id: string; groupId: string}>) {
    if (payload) {
      yield call(templateConfigDetailsErrorHelperSaga);
      try {
        const {accessToken} = yield call(acquireAuthResult, msalInstance);
        yield put(
          setClientAdminTemplateConfigDetailsFormStatus(FormStatus.Pending)
        );
        yield put(setClientAdminTemplateConfigDetailsIsDeleteInProgress(true));
        yield call(removeTemplateConfigDetailsMetric, accessToken, payload.id);
        yield put(
          deleteClientAdminTemplateConfigCustomMetric({
            id: payload.id,
            groupId: payload.groupId,
          })
        );
        yield put(
          setClientAdminTemplateConfigDetailsFormStatus(FormStatus.Success)
        );
        yield put(setClientAdminTemplateConfigDetailsIsDeleteInProgress(false));
      } catch (err) {
        console.warn(err);
        yield call(
          templateConfigDetailsResponseErrorHelperSaga,
          err,
          i18n.t('DataCollection.ReportingGroupTemplate.RemoveMetric.Error')
        );
      }
    }
  };

export const removeTemplateConfigDetailsCustomGroupSaga =
  function* removeTemplateConfigDetailsCustomGroupSaga({
    payload,
  }: PayloadAction<{id: string}>) {
    const templateId: string | undefined = yield select(
      (state: RootState) =>
        state.dataCollectionAdministration.templateConfigurationDetails.data?.id
    );
    if (payload && templateId) {
      yield call(templateConfigDetailsErrorHelperSaga);
      try {
        const {accessToken} = yield call(acquireAuthResult, msalInstance);
        yield put(
          setClientAdminTemplateConfigDetailsFormStatus(FormStatus.Pending)
        );
        yield put(setClientAdminTemplateConfigDetailsIsDeleteInProgress(true));
        yield call(
          removeTemplateConfigDetailsCustomGroup,
          accessToken,
          payload.id
        );
        yield put(
          deleteClientAdminTemplateConfigDetailsGroup({id: payload.id})
        );
        yield put(
          setClientAdminTemplateConfigDetailsFormStatus(FormStatus.Success)
        );
        yield put(setClientAdminTemplateConfigDetailsIsDeleteInProgress(false));
      } catch (err: any) {
        console.warn(err);
        yield call(
          templateConfigDetailsResponseErrorHelperSaga,
          err,
          i18n.t('DataCollection.ReportingGroupTemplate.RemoveGroup.Error')
        );
      }
    }
  };

export const updateTemplateConfigDetailsGroupsOrderSaga =
  function* updateTemplateConfigDetailsGroupsOrderSaga({
    payload,
  }: PayloadAction<UpdateTemplateConfigDetailsGroupOrderPayload>) {
    if (payload) {
      yield call(templateConfigDetailsErrorHelperSaga);
      try {
        const {accessToken} = yield call(acquireAuthResult, msalInstance);
        yield put(
          setClientAdminTemplateConfigDetailsFormStatus(FormStatus.Pending)
        );
        yield call(
          updateTemplateConfigDetailsGroupsOrder,
          accessToken,
          payload.id,
          payload
        );
        yield put(
          setClientAdminTemplateConfigDetailsFormStatus(FormStatus.Success)
        );
      } catch (error: any) {
        yield put(
          setClientAdminTemplateConfigDetailsFormStatus(FormStatus.Ready)
        );
        yield put(
          setClientAdminTemplateConfigDetailsError(
            i18n.t(
              'DataCollection.ReportingGroupTemplate.ReorderingGroups.Error'
            )
          )
        );
        console.warn(error);
      }
    }
  };

export const getForecastGroupsAndMetricsFormOptionsSaga =
  function* getForecastGroupsAndMetricsFormOptionsSaga({
    payload,
  }: PayloadAction<{id: string}>): any {
    yield call(templateConfigDetailsErrorHelperSaga);
    try {
      yield put(setForecastGroupsAndMetricsOptions(null));
      yield put(
        setClientAdminTemplateConfigDetailsFormStatus(FormStatus.Pending)
      );
      const {accessToken} = yield call(acquireAuthResult, msalInstance);
      const response: ForecastGroupsAndMetricsFormGroup[] = yield call(
        getForecastGroupAndMetricsFormOptions,
        accessToken,
        payload.id
      );
      yield put(setForecastGroupsAndMetricsOptions(response));
      yield put(
        setClientAdminTemplateConfigDetailsFormStatus(FormStatus.Ready)
      );
    } catch (err) {
      console.warn(err);
      yield call(
        templateConfigDetailsResponseErrorHelperSaga,
        err,
        i18n.t(
          'DataCollection.ReportingGroupTemplate.FetchTemplateOptions.Error'
        )
      );
    }
  };

export const updateForecastGroupsAndMetricsSaga =
  function* updateForecastGroupsAndMetricsSaga({
    payload,
  }: PayloadAction<UpdateForecastGroupsAndMetricsPayload>): any {
    yield call(templateConfigDetailsErrorHelperSaga);
    try {
      yield put(
        setClientAdminTemplateConfigDetailsFormStatus(FormStatus.Pending)
      );

      const {accessToken} = yield call(acquireAuthResult, msalInstance);
      const response: TemplateConfigDetailsCustomGroup<TemplateConfigDetailsCustomGroupItem>[] =
        yield call(updateForecastGroupsAndMetrics, accessToken, payload);

      yield put(setClientAdminTemplateConfigGroups(response));
      yield put(
        setClientAdminTemplateConfigDetailsFormStatus(FormStatus.Success)
      );
    } catch (err) {
      console.warn(err);
      yield call(
        templateConfigDetailsResponseErrorHelperSaga,
        err,
        i18n.t('DataCollection.ReportingTemplate.UpdateForecastMetrics.Error')
      );
    }
  };

const templateConfigDetailsErrorHelperSaga =
  function* templateConfigDetailsErrorHelperSaga() {
    const error: string | null = yield select(
      (state: RootState) =>
        state.dataCollectionAdministration.templateConfigurationDetails.error
    );
    const formError: string | null = yield select(
      (state: RootState) =>
        state.dataCollectionAdministration.templateConfigurationDetails
          .formError
    );
    if (error) {
      yield put(setClientAdminTemplateConfigDetailsError(null));
    }
    if (formError) {
      yield put(setClientAdminTemplateConfigDetailsFormError(null));
    }
  };

const templateConfigDetailsResponseErrorHelperSaga =
  function* templateConfigDetailsResponseErrorHelperSaga(
    err: any,
    placeholder: string
  ) {
    yield put(setClientAdminTemplateConfigDetailsFormStatus(FormStatus.Ready));
    yield put(setClientAdminTemplateConfigDetailsIsDeleteInProgress(false));
    const {error, formError} = responseErrorResolver(err, placeholder);
    yield put(setClientAdminTemplateConfigDetailsError(error));
    yield put(setClientAdminTemplateConfigDetailsFormError(formError));
  };

export const clientAdminTemplateConfigDetailsSagaWatcher =
  function* clientAdminTemplateConfigDetailsSagaWatcher() {
    yield all([
      takeEvery(
        addTemplateConfigDetailsCustomGroupAction.type,
        addTemplateConfigDetailsCustomGroupSaga
      ),
      takeEvery(
        updateTemplateConfigDetailsCustomGroupAction.type,
        updateTemplateConfigDetailsCustomGroupSaga
      ),
      takeEvery(
        removeTemplateConfigDetailsCustomGroupAction.type,
        removeTemplateConfigDetailsCustomGroupSaga
      ),
      takeEvery(
        addTemplateConfigDetailsCustomMetricAction.type,
        addTemplateConfigDetailsCustomMetricSaga
      ),
      takeEvery(
        updateTemplateConfigDetailsCustomMetricAction.type,
        updateTemplateConfigDetailsCustomMetricSaga
      ),
      takeEvery(
        updateTemplateConfigDetailsGroupsOrderAction.type,
        updateTemplateConfigDetailsGroupsOrderSaga
      ),
      takeEvery(
        updateReportingGroupTemplateAction.type,
        updateReportingGroupTemplateSaga
      ),
      takeEvery(
        removeReportingGroupTemplateAction.type,
        removeReportingGroupTemplateSaga
      ),
      takeEvery(
        removeTemplateConfigDetailsCustomMetricAction.type,
        removeTemplateConfigDetailsCustomMetricSaga
      ),
      takeEvery(
        getForecastGroupsAndMetricsOptionsAction.type,
        getForecastGroupsAndMetricsFormOptionsSaga
      ),
      takeEvery(
        updateForecastGroupsAndMetricsAction.type,
        updateForecastGroupsAndMetricsSaga
      ),
    ]);
  };
