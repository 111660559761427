import {createAction, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {
  AddTemplateConfigDetailsCustomGroupPayload,
  ClientAdminTemplateConfigDetails,
  ForecastGroupsAndMetricsFormGroup,
  FormStatus,
  TemplateConfigDetailsCustomGroup,
  TemplateConfigDetailsCustomGroupItem,
  UpdateForecastGroupsAndMetricsPayload,
  UpdateReportingGroupTemplatePayload,
  UpdateTemplateConfigDetailsCustomGroupPayload,
} from '../types';
import {
  AddTemplateConfigDetailsCustomMetricsPayload,
  UpdateTemplateConfigDetailsCustomMetricsPayload,
} from '../types/templateConfigDetailsCustomMetricsForm';
import {UpdateTemplateConfigDetailsGroupOrderPayload} from '../types/templateGroupsReorderingPanel';

export const addTemplateConfigDetailsCustomGroupAction = createAction(
  'client-admin/template-configuration-details/add-custom-group',
  (payload: AddTemplateConfigDetailsCustomGroupPayload) => {
    return {
      payload,
    };
  }
);

export const updateTemplateConfigDetailsCustomGroupAction = createAction(
  'client-admin/template-configuration-details/update-custom-group',
  (payload: UpdateTemplateConfigDetailsCustomGroupPayload) => {
    return {
      payload,
    };
  }
);

export const removeTemplateConfigDetailsCustomGroupAction = createAction(
  'client-admin/template-configuration/details/remove-custom-group',
  (id: string) => {
    return {
      payload: {
        id,
      },
    };
  }
);

export const addTemplateConfigDetailsCustomMetricAction = createAction(
  'client-admin/template-configuration-details/add-custom-metrics',
  (payload: AddTemplateConfigDetailsCustomMetricsPayload) => {
    return {
      payload,
    };
  }
);

export const updateTemplateConfigDetailsCustomMetricAction = createAction(
  'client-admin/template-configuration-details/update-custom-metrics',
  (payload: UpdateTemplateConfigDetailsCustomMetricsPayload) => {
    return {
      payload,
    };
  }
);

export const removeTemplateConfigDetailsCustomMetricAction = createAction(
  'client-admin/template-configuration-details/remove-custom-metrics',
  (id: string, groupId: string) => {
    return {
      payload: {
        id,
        groupId,
      },
    };
  }
);

export const updateTemplateConfigDetailsGroupsOrderAction = createAction(
  'client-admin/template-configuration-details/update-groups-order',
  (payload: UpdateTemplateConfigDetailsGroupOrderPayload) => {
    return {
      payload,
    };
  }
);

export const updateReportingGroupTemplateAction = createAction(
  'client-admin/template-configuration-details/update-template',
  (payload: UpdateReportingGroupTemplatePayload) => {
    return {
      payload,
    };
  }
);

export const removeReportingGroupTemplateAction = createAction(
  'client-admin/template-configuration-details/remove-template',
  (id: string) => {
    return {
      payload: {
        id,
      },
    };
  }
);

export const getForecastGroupsAndMetricsOptionsAction = createAction(
  'client-admin/template-configuration-details/get-forecast-form-options',
  (id: string) => {
    return {
      payload: {
        id,
      },
    };
  }
);

export const updateForecastGroupsAndMetricsAction = createAction(
  'client-admin/template-configuration-details/update-forecast-groups-metrics',
  (payload: UpdateForecastGroupsAndMetricsPayload) => {
    return {
      payload,
    };
  }
);

interface ClientAdminTemplateConfigDetailsSlice {
  data: ClientAdminTemplateConfigDetails | null;
  formStatus: FormStatus;
  error: string | null;
  formError: Record<string, string> | null;
  isDeleteInProgress: boolean;
}

const initialState: ClientAdminTemplateConfigDetailsSlice = {
  data: null,
  formStatus: FormStatus.Ready,
  error: null,
  formError: null,
  isDeleteInProgress: false,
};

const clientAdminTemplateConfigDetailsSlice = createSlice({
  name: 'client-admin/template-configuration-details',
  initialState,
  reducers: {
    setClientAdminTemplateConfigDetailsData(
      state: ClientAdminTemplateConfigDetailsSlice,
      action: PayloadAction<ClientAdminTemplateConfigDetails>
    ) {
      state.data = action.payload;
    },
    setClientAdminTemplateConfigDetailsNewGroup(
      state: ClientAdminTemplateConfigDetailsSlice,
      action: PayloadAction<
        TemplateConfigDetailsCustomGroup<TemplateConfigDetailsCustomGroupItem>
      >
    ) {
      if (state.data) {
        state.data.data = [...state.data.data, action.payload];
      }
    },
    updateClientAdminTemplateConfigDetailsGroup(
      state: ClientAdminTemplateConfigDetailsSlice,
      action: PayloadAction<
        TemplateConfigDetailsCustomGroup<TemplateConfigDetailsCustomGroupItem>
      >
    ) {
      if (state.data) {
        const updatedData = state.data.data.map(item => {
          if (item.id === action.payload.id) {
            return action.payload;
          }
          return item;
        });
        state.data.data = updatedData;
      }
    },
    deleteClientAdminTemplateConfigDetailsGroup(
      state: ClientAdminTemplateConfigDetailsSlice,
      action: PayloadAction<{id: string}>
    ) {
      if (state.data) {
        const updatedData = state.data.data.filter(
          group => group.id !== action.payload.id
        );
        state.data.data = updatedData;
      }
    },
    deleteClientAdminTemplateConfigCustomMetric(
      state: ClientAdminTemplateConfigDetailsSlice,
      action: PayloadAction<{id: string; groupId: string}>
    ) {
      if (state.data) {
        const updatedData = state.data.data.map(item => {
          if (action.payload.groupId === item.id) {
            return {
              ...item,
              data: item.data.filter(metric => metric.id !== action.payload.id),
            };
          }
          return item;
        });
        state.data.data = updatedData;
      }
    },
    setTemplateName(
      state: ClientAdminTemplateConfigDetailsSlice,
      action: PayloadAction<{name: string}>
    ) {
      if (state.data) {
        state.data.templateName = action.payload.name;
      }
    },
    clearClientAdminTemplateConfigDetailsData(
      state: ClientAdminTemplateConfigDetailsSlice
    ) {
      state.data = null;
    },
    setClientAdminTemplateConfigGroups(
      state: ClientAdminTemplateConfigDetailsSlice,
      action: PayloadAction<
        Array<
          TemplateConfigDetailsCustomGroup<TemplateConfigDetailsCustomGroupItem>
        >
      >
    ) {
      if (state.data) {
        state.data.data = action.payload;
      }
    },
    setClientAdminTemplateConfigDetailsFormStatus(
      state: ClientAdminTemplateConfigDetailsSlice,
      action: PayloadAction<FormStatus>
    ) {
      state.formStatus = action.payload;
    },
    setClientAdminTemplateConfigDetailsIsDeleteInProgress(
      state: ClientAdminTemplateConfigDetailsSlice,
      action: PayloadAction<boolean>
    ) {
      state.isDeleteInProgress = action.payload;
    },
    setClientAdminTemplateConfigDetailsError(
      state: ClientAdminTemplateConfigDetailsSlice,
      action: PayloadAction<string | null>
    ) {
      state.error = action.payload;
    },
    setClientAdminTemplateConfigDetailsFormError(
      state: ClientAdminTemplateConfigDetailsSlice,
      action: PayloadAction<Record<string, string> | null>
    ) {
      state.formError = action.payload;
    },
    setForecastGroupsAndMetricsOptions(
      state: ClientAdminTemplateConfigDetailsSlice,
      action: PayloadAction<ForecastGroupsAndMetricsFormGroup[] | null>
    ) {
      if (state.data) {
        state.data.forecastGroupAndMetricsOptions = action.payload;
      }
    },
  },
});

export default clientAdminTemplateConfigDetailsSlice.reducer;
export const {
  setClientAdminTemplateConfigDetailsData,
  setClientAdminTemplateConfigDetailsNewGroup,
  updateClientAdminTemplateConfigDetailsGroup,
  deleteClientAdminTemplateConfigDetailsGroup,
  deleteClientAdminTemplateConfigCustomMetric,
  setTemplateName,
  clearClientAdminTemplateConfigDetailsData,
  setClientAdminTemplateConfigGroups,
  setClientAdminTemplateConfigDetailsFormError,
  setClientAdminTemplateConfigDetailsFormStatus,
  setClientAdminTemplateConfigDetailsError,
  setClientAdminTemplateConfigDetailsIsDeleteInProgress,
  setForecastGroupsAndMetricsOptions,
} = clientAdminTemplateConfigDetailsSlice.actions;
