import {NavTimeSeries, TicksType} from '../types/index';
import {
  formatISO,
  startOfQuarter,
  endOfQuarter,
  startOfYear,
  endOfYear,
} from 'date-fns';

export type DomainRange = {
  from: Date;
  to: Date;
  length: number;
};

export const calcDomainRange = (
  timeSeries: NavTimeSeries[] | null,
  range?: [number, number]
): DomainRange => {
  if (timeSeries && range) {
    const rangeFrom =
      range[0] >= timeSeries.length ? timeSeries.length - 1 : range[0];
    const rangeTo =
      range[1] >= timeSeries.length ? timeSeries.length - 1 : range[1];
    return {
      from: new Date(timeSeries[rangeFrom].x),
      to: new Date(timeSeries[rangeTo].x),
      length: timeSeries.length,
    };
  }
  if (!timeSeries || timeSeries.length === 0) {
    return {
      from: new Date(),
      to: new Date(),
      length: 0,
    };
  }

  return {
    from: new Date(timeSeries[0].x),
    to: new Date(timeSeries[timeSeries.length - 1].x),
    length: timeSeries.length,
  };
};

export const arrayOfStringValuesToDateConverter = (data: NavTimeSeries[]) => {
  return data.map(item => ({
    ...item,
    x: new Date(item.x),
  }));
};

export const dateToMilliseconds = (
  start: Date,
  end: Date
): {start: number; end: number} => {
  return {
    start: start.getTime(),
    end: end.getTime(),
  };
};

export const calcXaxisTicks = (
  data: NavTimeSeries[],
  xAxisTicksType: TicksType,
  isPeriodEndLabel = false
): Array<string> => {
  const array = data.reduce((acc: Array<string>, item: NavTimeSeries) => {
    if (xAxisTicksType === TicksType.Year) {
      const tick = isPeriodEndLabel
        ? formatISO(endOfYear(new Date(item.x)))
        : formatISO(startOfYear(new Date(item.x)));
      if (!acc.includes(tick)) return [...acc, tick];
    }
    if (xAxisTicksType === TicksType.Quarter) {
      const tick = isPeriodEndLabel
        ? formatISO(endOfQuarter(new Date(item.x)))
        : formatISO(startOfQuarter(new Date(item.x)));
      if (!acc.includes(tick)) return [...acc, tick];
    }
    return acc;
  }, []);
  if (isPeriodEndLabel) {
    array.splice(-1);
    return array;
  }
  array.splice(0, 1);
  return array;
};

export const calcXaxisPadding = (
  numberOfNodes: number
): {left: number; right: number} => {
  if (numberOfNodes > 7) {
    return {
      left: 50,
      right: 50,
    };
  }
  if (numberOfNodes > 4) {
    return {
      left: 300,
      right: 300,
    };
  }
  return {
    left: 500,
    right: 500,
  };
};

export const calcXaxisTickXOffset = (numberOfNodes: number): number => {
  if (numberOfNodes > 7) return 11;
  if (numberOfNodes > 2) return 22;
  return 17;
};
