import React from 'react';
import {SectionHeader} from '../../dashboard';
import {Func, OwnershipControl} from '../../../types';
import styles from './OwnershipAndControlDashboard.module.scss';
import {FundExposureChart, DealTeamWidget} from '../../';

export interface OwnershipControlDashboardProps extends OwnershipControl {
  onEditDealTeamHandler: Func<[], void>;
  onEditFundExposureHandler: Func<[], void>;
  exposureChartPlaceholder?: string;
}

const OwnershipAndControlDashboard: React.FC<
  OwnershipControlDashboardProps
> = ({
  title,
  ownershipData,
  investment,
  dealTeam,
  onEditDealTeamHandler,
  onEditFundExposureHandler,
  exposureChartPlaceholder,
}) => {
  return (
    <div className={styles.wrapper}>
      <SectionHeader
        label={title}
        labelType={'large'}
        onClick={() => null}
        withActionButton={false}
        className={styles.sectionHeader}
      />
      <div className={styles.widgets}>
        <div className={styles.widgetsItem}>
          <FundExposureChart
            {...ownershipData}
            placeHolder={exposureChartPlaceholder}
            onEditHandler={onEditFundExposureHandler}
            showEditPen={true}
          />
        </div>
        <div className={styles.widgetsItem}>
          <DealTeamWidget {...dealTeam} onEditHandler={onEditDealTeamHandler} />
        </div>
        {/* // [LP-3574] TODO: Uncomment max-width: 33%; in CSS for this file when its time to make the Investment Card visible again - Aleksa Jukic */}
        {/* <div className={styles.widgetsItem}>
          <PortfolioExposureWidget {...investment} />
        </div> */}
      </div>
    </div>
  );
};

export default OwnershipAndControlDashboard;
