import {createAction, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {
  CompanyInvestmentOverview,
  InvestedFundTransactionData,
  TransactionsData,
} from '../types/index';
import {updateTransactionUploadedFileCount} from '../utils';

export const filterCompanyInvestmentOverviewTransactionsDataAction =
  createAction(
    'company-details-investment-overview/filter-transactions-data',
    (portfolioId: string, fundId?: string) => {
      return {
        payload: {
          portfolioId,
          fundId,
        },
      };
    }
  );

interface CompanyDetailsInvestmentOverviewData {
  data: CompanyInvestmentOverview | null;
  transactionsFilteringInProgress: boolean;
  selectedTransactions: InvestedFundTransactionData[] | [];
  selectedTransaction: InvestedFundTransactionData | null;
}

const initialState: CompanyDetailsInvestmentOverviewData = {
  data: null,
  transactionsFilteringInProgress: false,
  selectedTransactions: [],
  selectedTransaction: null,
};

const companyDetailsInvestmentOverviewSlice = createSlice({
  name: 'company-details-investment-overview',
  initialState,
  reducers: {
    setCompanyInvestmentOverviewData(
      state: CompanyDetailsInvestmentOverviewData,
      action: PayloadAction<CompanyInvestmentOverview>
    ) {
      state.data = action.payload;
    },

    clearCompanyInvestmentOverviewData(
      state: CompanyDetailsInvestmentOverviewData
    ) {
      state.data = null;
    },
    setCompanyInvestmentOverviewTransactionsFilteredData(
      state: CompanyDetailsInvestmentOverviewData,
      action: PayloadAction<TransactionsData>
    ) {
      if (state.data) {
        state.data.transactions = action.payload;
      }
    },
    setCompanyInvestmentOverviewTransactionsFilteringInProgress(
      state: CompanyDetailsInvestmentOverviewData,
      action: PayloadAction<boolean>
    ) {
      state.transactionsFilteringInProgress = action.payload;
    },
    setCompanyInvestmentOverviewSelectedTransactions(
      state: CompanyDetailsInvestmentOverviewData,
      action: PayloadAction<InvestedFundTransactionData[] | []>
    ) {
      state.selectedTransactions = action.payload;
    },
    setCompanyInvestmentOverviewSelectedTransaction(
      state: CompanyDetailsInvestmentOverviewData,
      action: PayloadAction<InvestedFundTransactionData | null>
    ) {
      state.selectedTransaction = action.payload;
    },
    setCompanyInvestmentOverviewSelectedTransactionsUploadedFilesCount(
      state: CompanyDetailsInvestmentOverviewData,
      action: PayloadAction<string[] | []>
    ) {
      action.payload.forEach((transactionId: string) => {
        if (state.data?.transactions.data) {
          updateTransactionUploadedFileCount(
            state.data.transactions.data,
            transactionId,
            'add'
          );
        }
        if (state.data?.transactions.dataLocal) {
          updateTransactionUploadedFileCount(
            state.data.transactions.dataLocal,
            transactionId,
            'add'
          );
        }
      });
    },
    setCompanyInvestmentOverviewSelectedTransactionsDeletedFilesCount(
      state: CompanyDetailsInvestmentOverviewData,
      action: PayloadAction<string[] | []>
    ) {
      action.payload.forEach((transactionId: string) => {
        if (state.data?.transactions.data) {
          updateTransactionUploadedFileCount(
            state.data.transactions.data,
            transactionId,
            'subtract'
          );
        }
        if (state.data?.transactions.dataLocal) {
          updateTransactionUploadedFileCount(
            state.data.transactions.dataLocal,
            transactionId,
            'subtract'
          );
        }
      });
    },
  },
});

export const {
  setCompanyInvestmentOverviewData,
  clearCompanyInvestmentOverviewData,
  setCompanyInvestmentOverviewTransactionsFilteredData,
  setCompanyInvestmentOverviewTransactionsFilteringInProgress,
  setCompanyInvestmentOverviewSelectedTransactions,
  setCompanyInvestmentOverviewSelectedTransaction,
  setCompanyInvestmentOverviewSelectedTransactionsUploadedFilesCount,
  setCompanyInvestmentOverviewSelectedTransactionsDeletedFilesCount,
} = companyDetailsInvestmentOverviewSlice.actions;

export default companyDetailsInvestmentOverviewSlice.reducer;
