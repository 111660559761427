import {createSlice, PayloadAction} from '@reduxjs/toolkit';

interface CurrentPage {
  name: string | null;
}

const initialState: CurrentPage = {name: null};

const pageSlice = createSlice({
  name: 'page',
  initialState,
  reducers: {
    setCurrentPage(state: CurrentPage, action: PayloadAction<{name: string}>) {
      const {payload} = action;
      state.name = payload.name;
    },
  },
});

export const {setCurrentPage} = pageSlice.actions;
export default pageSlice.reducer;
