import React from 'react';
import classnames from 'classnames';
import {Link} from 'react-router-dom';
import routePaths from '../../../../../routePaths';
import {FundInfoCellProps, StyleVariant} from '../../../../../types';
import styles from './FundInfoCell.module.scss';

const FundInfoCell: React.FC<FundInfoCellProps> = ({
  value,
  variant = StyleVariant.Primary,
}): JSX.Element => {
  return (
    <div className={classnames(styles.wrapper, styles[`wrapper__${variant}`])}>
      {value.map(({fundId, fundColor, fundName}, idx) => (
        <div key={idx} className={styles.content}>
          <div
            className={styles.fundColor}
            style={{background: `rgb(var(--colors-${fundColor}))`}}
          />
          {variant === StyleVariant.Primary && (
            <Link
              to={`${routePaths.FUNDS}/${fundId}/overview`}
              className={styles.fundName}
            >
              {fundName}
            </Link>
          )}
        </div>
      ))}
    </div>
  );
};

export default FundInfoCell;
