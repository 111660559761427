import {HeaderMapping} from '../types';

export const formatToHyphenatedString = (value: string): string => {
  return value
    .toLowerCase()
    .replaceAll(' ', '-')
    .replaceAll('/', '-')
    .replaceAll('.', '-');
};

export const getTableColumnTestAttr = (
  headerMapping: HeaderMapping[],
  columnId: string
): string => {
  const columnLabel =
    headerMapping.find(columnHeader => columnHeader.columnId === columnId)
      ?.label ?? '';

  return `table-column-${formatToHyphenatedString(columnLabel)}`;
};
