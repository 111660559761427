import React, {useEffect, useRef} from 'react';
import {CheckboxProps, StyleVariant} from '../../../types';
import styles from './Checkbox.module.scss';
import classnames from 'classnames';

const Checkbox: React.FC<CheckboxProps> = ({
  label,
  isIndeterminate,
  children,
  variant = StyleVariant.Primary,
  ...inputProps
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current) {
      if (isIndeterminate) {
        inputRef.current.checked = false;
        inputRef.current.indeterminate = true;
      } else {
        inputRef.current.checked = inputProps.checked;
        inputRef.current.indeterminate = false;
      }
    }
  }, [inputProps.checked, isIndeterminate, inputRef]);

  return (
    <label
      className={classnames(styles.wrapper, styles[`wrapper__${variant}`])}
    >
      <input
        ref={inputRef}
        {...inputProps}
        type="checkbox"
        className={styles.input}
      />
      <span className={styles.icon} />
      {children ? (
        typeof children === 'function' ? (
          children({label})
        ) : (
          children
        )
      ) : (
        <span className={styles.label}>{label}</span>
      )}
    </label>
  );
};

export default Checkbox;
