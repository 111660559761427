import React, {useState} from 'react';
import {ResponsiveContainer, Treemap, Tooltip} from 'recharts';
import {getTreemapDataWithColors} from '../../../utils';
import {Func} from '../../../types';
import {useChartAnimated} from '../../../hooks';
import TreemapNode from './TreemapNode';
import TreemapTooltip from './TreemapTooltip';
import {
  TreemapColorRange,
  TreemapNodeData,
  TreemapData,
} from '../../../types/fundOperational';
interface TreemapType {
  treemapData: TreemapData;
  onNodeSelect: Func<[TreemapNodeData], void>;
  selectedNode: TreemapNodeData | null;
  colors: TreemapColorRange[];
}

const TreemapChart: React.FC<TreemapType> = ({
  treemapData,
  onNodeSelect,
  selectedNode,
  colors,
}) => {
  const [animationEnded, setAnimationEnded] = useState(false);
  const [hoveredId, setHoveredId] = useState('');
  const {ref, isAnimated} = useChartAnimated();

  const data = getTreemapDataWithColors(treemapData, colors);

  const handleNodeSelect = (node: TreemapNodeData) => {
    onNodeSelect(node);
  };

  return (
    <div
      ref={ref}
      style={{
        opacity: isAnimated ? 1 : 0,
      }}
    >
      <ResponsiveContainer width="100%" aspect={4 / 2} id="treemap-chart">
        <Treemap
          data={[data]}
          dataKey="value"
          aspectRatio={1 / 2}
          isAnimationActive={isAnimated}
          onAnimationEnd={() => setAnimationEnded(true)}
          content={
            <TreemapNode
              canBeHovered={animationEnded}
              hoveredId={hoveredId}
              onHover={setHoveredId}
              selectedNode={selectedNode}
              handleNodeSelect={handleNodeSelect}
            />
          }
        >
          <Tooltip content={<TreemapTooltip />} />
          {hoveredId && animationEnded && (
            <use xlinkHref={`#${hoveredId}`} pointerEvents={'none'} />
          )}
        </Treemap>
      </ResponsiveContainer>
    </div>
  );
};

export default TreemapChart;
