import React, {useRef} from 'react';
import {Icon} from '../../../index';
import type {Identifier, XYCoord} from 'dnd-core';
import {CustomizeOrderOption, Func} from '../../../../../types';
import {useDrag, useDrop} from 'react-dnd';
import styles from './DragPanel.module.scss';
import classnames from 'classnames';

interface DragPanelProps extends CustomizeOrderOption {
  index: number;
  moveCard: Func<[number, number, number | undefined], void>;
  dropCard: Func<[], void>;
  parentIndex?: number;
}

interface DragItem {
  index: number;
  id: string;
  type: string;
}
const DragPanel: React.FC<DragPanelProps> = ({
  label,
  moveCard,
  id,
  index,
  dropCard,
  subItems,
  groupLabel,
  parentIndex,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [{handlerId}, drop] = useDrop<
    DragItem,
    void,
    {handlerId: Identifier | null}
  >({
    accept: `card${parentIndex !== undefined ? parentIndex : ''}`,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex, parentIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{isDragging}, drag] = useDrag({
    type: `card${parentIndex !== undefined ? parentIndex : ''}`,
    item: () => {
      return {id, index, className: classnames(styles.option, styles.dragging)};
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
    end: () => {
      if (!ref.current) {
        return;
      }
      dropCard();
    },
  });

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  return (
    <div
      ref={ref}
      key={id}
      className={classnames(
        styles.option,
        subItems && styles.group,
        isDragging && styles.dragging
      )}
      style={{opacity}}
      data-handler-id={handlerId}
    >
      <div className={styles.inner}>
        <Icon className={styles.optionIcon} name="drag-handle" />
        <span className={styles.optionLabel}>{label}</span>
        {groupLabel && (
          <div className={styles.groupLabel}>
            {subItems?.length} {groupLabel}
          </div>
        )}
      </div>
      {subItems && (
        <div className={styles.subItems}>
          {subItems.map((item, itemIdx) => (
            <DragPanel
              key={item.id}
              index={itemIdx}
              moveCard={moveCard}
              dropCard={dropCard}
              parentIndex={index}
              id={item.id}
              label={item.label}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default DragPanel;
