import React, {useEffect, useRef} from 'react';
import classnames from 'classnames';
import styles from './EditableCell.module.scss';

type EditableCellProps = React.TextareaHTMLAttributes<any> & {
  isRight?: boolean;
};

// auto-height logic for textarea
const setAutoHeightValue = (textarea: HTMLTextAreaElement) => {
  textarea.style.height = '0px';
  textarea.style.height = `${textarea.scrollHeight}px`;
};

const EditableCell = ({
  name,
  value,
  onChange,
  onBlur,
  isRight,
  ...props
}: EditableCellProps): JSX.Element => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (textareaRef.current) {
      setAutoHeightValue(textareaRef.current);
      const valueLength = textareaRef.current?.value?.length;
      textareaRef.current.setSelectionRange(valueLength, valueLength);
      textareaRef.current.focus();
    }
  }, [textareaRef]);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setAutoHeightValue(e.target);
    if (!!onChange) {
      onChange(e);
    }
  };
  return (
    <td className={styles.cell}>
      <div className={styles.textareaWrapper}>
        <textarea
          ref={textareaRef}
          name={name}
          value={value}
          className={classnames(styles.textarea, {
            [styles.textareaRight]: isRight,
          })}
          onChange={handleChange}
          onBlur={onBlur}
          {...props}
        />
      </div>
    </td>
  );
};

export default EditableCell;
