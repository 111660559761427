import {
  ClientDashboard,
  FundData,
  FundSummaryWidgetProps,
} from './clientDashboard';

export interface FundWidgetAndTableData extends Omit<FundData, 'widgets'> {
  data: Array<FundSummaryWidgetProps>;
}
export interface Funds
  extends Pick<ClientDashboard, 'id' | 'type' | 'clientName' | 'asAt'> {
  activeFunds: FundWidgetAndTableData;
  inactiveFunds: FundWidgetAndTableData;
}

export enum ActiveDashboardView {
  Table = 'table',
  Tiles = 'tiles',
  BarChart = 'barChart',
  TreemapChart = 'treemapChart',
  ScatteredBubbles = 'scatteredBubbles',
}

export type FundsRow = Omit<
  FundSummaryWidgetProps,
  'clickHandler' | 'activeTab' | 'isFundOnboarding'
>;
