import {useMemo} from 'react';
import {Subscription, UserProfileData} from '../types';
import {
  enabledFundMonitoringUploadDocumentsPaths,
  enabledPortfolioMonitoringUploadDocumentsPaths,
} from '../routePaths';
import {matchPath} from 'react-router';

const useIsUploadDocumentsEnabled = (
  userProfile: UserProfileData | null,
  pathname: string
) => {
  return useMemo(() => {
    const uploadDocumentsPaths = userProfile?.subscriptions?.includes(
      Subscription.PortfolioMonitoring
    )
      ? enabledPortfolioMonitoringUploadDocumentsPaths
      : enabledFundMonitoringUploadDocumentsPaths;

    return uploadDocumentsPaths.some(path => {
      return matchPath(pathname, {
        path: path,
        exact: true,
        strict: false,
      });
    });
  }, [pathname, userProfile]);
};

export default useIsUploadDocumentsEnabled;
