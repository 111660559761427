import React from 'react';
import {
  HeaderMapping,
  FlexHAlign,
  ExitedInvestmentsRow,
} from '../../../../../types';
import {ColumnDef, createColumnHelper} from '@tanstack/react-table';
import Th from '../../cells/th/Th';
import Td from '../../cells/td/Td';
import NameCell from '../../cells/name-cell/NameCell';
import ExpanderCell from '../../cells/expander-cell/ExpanderCell';
import FundBarsCell from '../../cells/fund-bars-cell/FundBarsCell';
import routePaths from '../../../../../routePaths';
import {consolidatedCompanyColor} from '../../../../../constants/companies';
import {getTableColumnTestAttr} from '../../../../../utils/strings';

const genExitedInvestmentsTableColumns = (
  headerMapping: HeaderMapping[]
): ColumnDef<ExitedInvestmentsRow, any>[] => {
  const columnHelper = createColumnHelper<ExitedInvestmentsRow>();

  return [
    columnHelper.accessor('companyName', {
      id: 'companyName',
      header: props => (
        <Th
          {...props}
          headerMapping={headerMapping}
          align={FlexHAlign.start}
          width={'max(100vw*0.18, 150px)'}
        />
      ),
      cell: props => (
        <Td
          {...props}
          align={FlexHAlign.start}
          width={'320px'}
          canHide={false}
          id={getTableColumnTestAttr(headerMapping, props.column.id)}
        >
          <NameCell
            name={props?.getValue<string>()}
            icon={props.row.original.fundColor ?? consolidatedCompanyColor}
            iconUrl={props.row.original.companyIcon ?? null}
            expanded={props.row.getIsExpanded()}
            // TODO: Linking to Investment Overview for V1, later on according to subscription
            linkTo={`${routePaths.PORTFOLIO_COMPANIES}/${props.row.original.id}/investment-overview`}
          />
        </Td>
      ),
    }),
    columnHelper.accessor('fundColor', {
      id: 'funds',
      header: props => (
        <Th {...props} headerMapping={headerMapping} align={FlexHAlign.start} />
      ),
      cell: props => (
        <Td
          {...props}
          align={FlexHAlign.start}
          id={getTableColumnTestAttr(headerMapping, props.column.id)}
        >
          <FundBarsCell
            funds={
              !props.row.original.investmentDetails
                ? [
                    {
                      fundColor: props.row.original.fundColor!,
                      fundName: props.row.original.fundName!,
                    },
                  ]
                : props.row.original.investmentDetails.map(item => ({
                    fundColor: item.fundColor!,
                    fundName: item.fundName!,
                  }))
            }
          />
        </Td>
      ),
    }),
    columnHelper.accessor('currency', {
      id: 'currency',
      header: props => (
        <Th {...props} headerMapping={headerMapping} align={FlexHAlign.start} />
      ),
      cell: props => (
        <Td
          {...props}
          align={FlexHAlign.start}
          id={getTableColumnTestAttr(headerMapping, props.column.id)}
        />
      ),
    }),
    columnHelper.accessor(row => row.firstInvestment, {
      id: 'firstInvestment',
      header: props => (
        <Th {...props} headerMapping={headerMapping} align={FlexHAlign.start} />
      ),
      cell: props => (
        <Td
          {...props}
          align={FlexHAlign.start}
          id={getTableColumnTestAttr(headerMapping, props.column.id)}
        />
      ),
    }),
    columnHelper.accessor(row => row.exitDate?.value, {
      id: 'exitDate',
      header: props => (
        <Th {...props} headerMapping={headerMapping} align={FlexHAlign.end} />
      ),
      cell: props => (
        <Td
          {...props}
          align={FlexHAlign.end}
          nestedAccessorFn={original => original.exitDate}
          id={getTableColumnTestAttr(headerMapping, props.column.id)}
        />
      ),
    }),
    columnHelper.accessor(row => row.totalCost.value, {
      id: 'totalCost',
      header: props => (
        <Th {...props} headerMapping={headerMapping} align={FlexHAlign.end} />
      ),
      cell: props => (
        <Td
          {...props}
          align={FlexHAlign.end}
          nestedAccessorFn={original => original.totalCost}
          id={getTableColumnTestAttr(headerMapping, props.column.id)}
        />
      ),
    }),
    columnHelper.accessor(row => row.totalGainLoss?.value, {
      id: 'totalGainLoss',
      header: props => (
        <Th {...props} headerMapping={headerMapping} align={FlexHAlign.end} />
      ),
      cell: props => (
        <Td
          {...props}
          align={FlexHAlign.end}
          nestedAccessorFn={original => original.totalGainLoss}
          id={getTableColumnTestAttr(headerMapping, props.column.id)}
          emptyValue={'-'}
        />
      ),
    }),
    columnHelper.accessor(row => row.roi?.value, {
      id: 'roi',
      header: props => (
        <Th {...props} headerMapping={headerMapping} align={FlexHAlign.end} />
      ),
      cell: props => (
        <Td
          {...props}
          align={FlexHAlign.end}
          nestedAccessorFn={original => original.roi}
          id={getTableColumnTestAttr(headerMapping, props.column.id)}
        />
      ),
    }),
    columnHelper.accessor(row => row.moic?.value, {
      id: 'moic',
      header: props => (
        <Th {...props} headerMapping={headerMapping} align={FlexHAlign.end} />
      ),
      cell: props => (
        <Td
          {...props}
          align={FlexHAlign.end}
          nestedAccessorFn={original => original.moic}
          id={getTableColumnTestAttr(headerMapping, props.column.id)}
        />
      ),
    }),
    columnHelper.accessor(row => row.irr?.value, {
      id: 'irr',
      header: props => (
        <Th {...props} headerMapping={headerMapping} align={FlexHAlign.end} />
      ),
      cell: props => (
        <Td
          {...props}
          align={FlexHAlign.end}
          nestedAccessorFn={original => original.irr}
          id={getTableColumnTestAttr(headerMapping, props.column.id)}
        />
      ),
    }),
    columnHelper.display({
      id: 'expander',
      header: props => (
        <Th
          {...props}
          align={FlexHAlign.center}
          width={'40px'}
          headerMapping={headerMapping}
        />
      ),
      cell: props => {
        const row = props.row;
        const isExpanded = props.row.getIsExpanded();

        return row.getCanExpand() ? (
          <Td
            {...props}
            align={FlexHAlign.center}
            canHide={false}
            id="table-column-expand"
          >
            <ExpanderCell expanded={isExpanded} />
          </Td>
        ) : null;
      },
    }),
  ];
};

export default genExitedInvestmentsTableColumns;
