import React from 'react';
import styles from './MetricsChartTooltip.module.scss';
import {
  MetricsChartTooltipMetricItem,
  MetricsChartTooltipProps,
  MetricUnitType,
} from '../../../../types';
import {ValueFormat} from '../../../global';
import {valueFormat} from '../../../../utils/value-format';
import classnames from 'classnames';

const MetricsChartTooltip: React.FC<MetricsChartTooltipProps> = ({
  active,
  payload,
  dateFormat = "MMM 'yy",
}) => {
  if (active && payload?.length) {
    // Helper to group metrics by reporting type
    const groupBy = (arr: {[index: string]: any}[], property: string) => {
      return arr.reduce((acc: {[index: string]: any}, cur) => {
        acc[cur[property]] = [...(acc[cur[property]] || []), cur];
        return acc;
      }, {});
    };

    const metrics = groupBy(payload[0].payload.metrics, 'reportingType');

    let reportingTypes = Object.keys(metrics);

    return (
      <div
        className={classnames(
          styles.tooltipWrapper,
          'monitoring-metrics-chart-tooltip'
        )}
      >
        <p className={styles.date}>
          {valueFormat(payload[0].payload.date, dateFormat).value}
        </p>
        {reportingTypes.map(reportingType => (
          <div className={styles.metricGroup} key={reportingType}>
            <p className={styles.metricGroupTitle}>{reportingType}</p>
            {metrics[reportingType].map(
              (metric: MetricsChartTooltipMetricItem, idx: number) => (
                <div className={styles.metricValues} key={idx}>
                  <span
                    className={styles.color}
                    style={{background: `rgb(var(--colors-${metric.color}))`}}
                  ></span>
                  <span>{metric.chartLabel}:</span>
                  {metric.unitType === MetricUnitType.Currency ? (
                    <ValueFormat
                      value={metric.value as number}
                      currency={metric.currency}
                      format={'en-GB'}
                    />
                  ) : (
                    <p>
                      {metric.value}{' '}
                      {metric.unitType === MetricUnitType.Percentage
                        ? '%'
                        : null}
                    </p>
                  )}
                </div>
              )
            )}
          </div>
        ))}
      </div>
    );
  }
  return null;
};

export default MetricsChartTooltip;
