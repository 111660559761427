import React from 'react';
import Lottie from 'lottie-react';
import classnames from 'classnames';
import {StyleVariant} from '../../../types/index';
import barsAnimation from '../../../assets/lottie/bars.json';
import companiesAnimation from '../../../assets/lottie/companies.json';
import fundsAnimation from '../../../assets/lottie/funds.json';
import dataCollectionAnimation from '../../../assets/lottie/data-collection.json';
import documentExplorerAnimation from '../../../assets/lottie/document-explorer.json';
import styles from './Loader.module.scss';

interface LoaderProps {
  loaderType: string;
  variant?: StyleVariant;
  className?: string;
}

const Loader: React.FC<LoaderProps> = ({
  loaderType,
  className,
  variant = StyleVariant.Primary,
}) => {
  const renderAnimation = (): JSX.Element | null => {
    switch (loaderType) {
      case 'bars':
        return (
          <Lottie animationData={barsAnimation} className={styles.loader} />
        );
      case 'companies':
        return (
          <Lottie
            animationData={companiesAnimation}
            className={styles.loader}
          />
        );
      case 'funds':
        return (
          <Lottie animationData={fundsAnimation} className={styles.loader} />
        );
      case 'data-collection':
        return (
          <Lottie
            animationData={dataCollectionAnimation}
            className={styles.loader}
          />
        );
      case 'document-explorer':
        return (
          <Lottie
            animationData={documentExplorerAnimation}
            className={styles.loader}
          />
        );
      default:
        return null;
    }
  };
  return (
    <div
      className={classnames(
        styles.wrapper,
        styles[`wrapper__${variant}`],
        className
      )}
    >
      <div className={styles.inner}>{renderAnimation()}</div>
    </div>
  );
};

export default Loader;
