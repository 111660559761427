import {all, call, put, select, takeEvery} from 'redux-saga/effects';
import {acquireAuthResult, msalInstance} from '../../';
import {CompanyAdminReportingGroup, Reporting} from '../../types';
import {getHistoricalReportingData, getReporting} from '../../api/getReporting';
import {
  clearReportingData,
  getHistoricalReporting,
  setHistoricalReportingData,
  setIsHistoricalDataLoading,
  setReportingData,
} from '../../reducers/reportingSlice';
import {generalResponseErrorResolver} from '../../utils';
import {RootState} from '../../store';
import {setGlobalInformation} from '../../reducers/globalInformationSlice';
import {
  setReportingTableData,
  setCollapseReset,
  setHistoricalReportingTableData,
} from '../../reducers/reportingTableSlice';

export const companyAdminReportingSaga = function* companyAdminReportingSaga({
  payload,
}: any): any {
  const data: Reporting | null = yield select(
    (state: RootState) => state.companyAdmin.reporting.data
  );
  // Clear previous page state if we already have data
  if (data?.data) yield put(clearReportingData());

  const urlTab = payload?.location?.query?.tab;

  try {
    const {accessToken} = yield call(acquireAuthResult, msalInstance);
    const response: Reporting = yield call(getReporting, accessToken);
    yield put(setReportingData({reporting: response, urlTab}));
    yield put(
      setReportingTableData(response.data.map(item => item.reportingData))
    );
  } catch (err) {
    console.warn(err);
    const responseError = generalResponseErrorResolver(err);
    if (responseError) {
      yield put(setGlobalInformation(responseError));
    }
  }
};

export const getHistoricalReportingSaga = function* getHistoricalReportingSaga({
  payload,
}: any): any {
  if (payload) {
    try {
      yield put(setIsHistoricalDataLoading(true));
      yield put(
        setCollapseReset({
          reportingTableTemplateType: payload.templateType,
          collapseReset: true,
        })
      );
      const {accessToken} = yield call(acquireAuthResult, msalInstance);
      const response: CompanyAdminReportingGroup = yield call(
        getHistoricalReportingData,
        accessToken,
        payload.id
      );

      yield put(
        setHistoricalReportingData({
          reportingGroup: response,
          templateType: payload.templateType,
        })
      );
      yield put(setHistoricalReportingTableData(response.reportingData));
      yield put(setIsHistoricalDataLoading(false));
    } catch (err) {
      yield put(setIsHistoricalDataLoading(false));
      console.warn(err);
      const responseError = generalResponseErrorResolver(err);
      if (responseError) {
        yield put(setGlobalInformation(responseError));
      }
    }
  }
};

export const companyAdminReportingSagaWatcher =
  function* companyAdminReportingSagaWatcher() {
    yield all([
      takeEvery(getHistoricalReporting.type, getHistoricalReportingSaga),
    ]);
  };
