import React, {ReactElement, useState} from 'react';
import styles from './SelectTransactions.module.scss';
import {
  ButtonStyle,
  DocumentTransactionData,
  Func,
  HeaderMapping,
} from '../../../../types';
import {Button, GenericTable, IconButton} from '../../../global';
import {useTranslation} from 'react-i18next';
import genSelectTransactionsTableColumnDefs from '../../../global/table/select-transactions-table/SelectTransactionsColumnDefs';
import classnames from 'classnames';
import {selectTransactionsTableDefaultSorting} from '../../../../constants';

type SelectTransactionsProps = {
  title: string;
  transactions: {
    headerMapping: HeaderMapping[];
    data: DocumentTransactionData[];
  } | null;
  selectedTransactions: string[];
  selectedEntityTags: string[];
  onDone: Func<any, void>;
  onCancel: Func<[], void>;
};

const SelectTransactions = ({
  title,
  transactions,
  selectedTransactions,
  selectedEntityTags,
  onDone,
  onCancel,
}: SelectTransactionsProps) => {
  const [currentSelectedTransactions, setCurrentSelectedTransactions] =
    useState<String[]>(selectedTransactions);

  const [currentSelectedEntityTags, setCurrentSelectedEntityTags] =
    useState<String[]>(selectedEntityTags);

  const {t} = useTranslation();

  const checkHandler = (
    checked: boolean,
    transaction: DocumentTransactionData
  ) => {
    if (checked) {
      setCurrentSelectedTransactions(prev => [...prev, transaction.id]);

      const addedEntityTags: string[] = [];

      transaction.entityTags.forEach(
        tag =>
          !currentSelectedEntityTags.includes(tag) && addedEntityTags.push(tag)
      );

      setCurrentSelectedEntityTags(prev => [...prev, ...addedEntityTags]);
    } else {
      setCurrentSelectedTransactions(prev =>
        prev.filter(item => item !== transaction.id)
      );
    }
  };

  const isDoneDisabled =
    selectedTransactions.sort().toString() ===
    currentSelectedTransactions.sort().toString();

  const onDoneHandler = () => {
    const selectedTransactions = transactions?.data?.filter(transaction =>
      currentSelectedTransactions.includes(transaction.id)
    );
    onDone(
      currentSelectedTransactions,
      currentSelectedEntityTags,
      selectedTransactions
    );
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h3 className={styles.title}>{title}</h3>
        <IconButton
          styleType={ButtonStyle.Primary}
          icon={'cross'}
          onClick={onCancel}
        />
      </div>
      {transactions ? (
        <GenericTable
          data={transactions.data}
          columns={genSelectTransactionsTableColumnDefs(
            transactions.headerMapping,
            currentSelectedTransactions,
            checkHandler
          )}
          expandedView={(): ReactElement => <></>}
          getRowCanExpand={() => false}
          defaultSorting={selectTransactionsTableDefaultSorting}
          className={classnames(styles.transactions, styles.scroll)}
        />
      ) : null}
      <div className={styles.footer}>
        <Button
          styleType={ButtonStyle.Primary}
          text={t('Global.Done')}
          onClick={onDoneHandler}
          disabled={isDoneDisabled}
        />
        <Button
          styleType={ButtonStyle.Secondary}
          text={t('Global.Cancel')}
          onClick={onCancel}
        />
      </div>
    </div>
  );
};

export default SelectTransactions;
