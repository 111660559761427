import React, {useMemo} from 'react';
import {StyleVariant, SelectOption} from '../../../types';
import PrevNextButtons from '../../prevn-next-buttons/PrevNextButtons';
import {useFormState} from '../../../hooks';
import {addFeeFormSchema} from '../../../constants/formSchemas';
import {Input, SelectInput} from '../../global';
import {valueFormat} from '../../../utils';
import styles from './TransactionsFeeForm.module.scss';
import {DatePickerDropdown} from '../../global';
import {useTranslation} from 'react-i18next';

const getDateLabel = (date: Date) => {
  if (!date) {
    return '';
  }

  return valueFormat(date.toString(), 'dd-MM-yyyy').value.toString();
};

const feeTypesOptions: SelectOption[] = [
  {
    id: 'legal-fee',
    value: 'Legal Fee',
    label: 'Legal Fee',
  },
  {
    id: 'management-fee',
    value: 'Management Fee',
    label: 'Management Fee',
  },
  {
    id: 'other-fee',
    value: 'Other Fee',
    label: 'Other Fee',
  },
];

const FeeForm = ({
  minDate,
  maxDate,
  handleSubmit,
  handleCloseForm,
  initialData,
  submitText,
  title,
}: any) => {
  const {t} = useTranslation();
  const handleKeyDown = (e: any) => {
    const allowedChars = '.0123456789-';
    const allowedControlChars = [
      'Backspace',
      'Delete',
      'Tab',
      'Enter',
      'Escape',
      'ArrowLeft',
      'ArrowRight',
    ];

    if (allowedChars.includes(e.key) || allowedControlChars.includes(e.key)) {
      setFormState({
        ...formState,
        valuation: e.target.value,
      });
    } else {
      e.preventDefault();
    }
  };
  const handleDateChange = (date?: Date) => {
    setFormState({
      ...formState,
      date: date?.toISOString() || '',
    });
  };

  const feeTransactionsInitialState = useMemo(
    () => ({
      vendor: initialData?.vendor || '',
      amount: initialData?.amount?.toString() || '',
      date: initialData?.date || '',
      fee_type: initialData?.fee_type || initialData?.feeType || '',
      comment: initialData?.comment || '',
    }),
    []
  );

  const {formState, validate, errors, handleChange, setFormState} =
    useFormState<any>(feeTransactionsInitialState, addFeeFormSchema);

  const onClose = () => {
    handleCloseForm();
    if (
      !initialData?.data &&
      !initialData?.comment &&
      !initialData?.date &&
      !initialData?.value
    ) {
      setFormState({...feeTransactionsInitialState});
    }
  };

  const onAdd = (e: any) => {
    e.preventDefault();
    handleSubmit(formState);
    onClose();
  };

  const formHasErrors =
    errors.amount.message || errors.comment.message || errors.vendor.message;
  const isFormDisabled =
    !!formState.amount &&
    !!formState.date &&
    !!formState.fee_type &&
    !formHasErrors;

  return (
    <form className={styles.formWrapper}>
      <div className={styles.header}>{title}</div>
      <div className={styles.formInputRowWrapper}>
        <div className={styles.formInputRow}>
          <div className={styles.formInputWrapper}>
            <Input
              type="text"
              name="vendor"
              placeholder={t('Funds.FeeForm.InputPlaceholder.Supplier')}
              value={formState.vendor}
              onChange={handleChange}
              className={styles.formInput}
              label={t('Global.Supplier')}
              validate={validate}
              errorMessage={t(errors.vendor.message || '')}
            />
          </div>
          <div className={styles.formInputWrapper}>
            <SelectInput
              name="fee_type"
              type="text"
              placeholder={t('Funds.FeeForm.SelectInputPlaceholder.FeType')}
              label={t('Global.FeeType')}
              variant={StyleVariant.Secondary}
              value={formState.fee_type}
              options={feeTypesOptions}
              onChange={handleChange}
              validate={validate}
              autoComplete="off"
              disabled={false}
              required
            />
          </div>
          <div className={styles.formInputWrapper}>
            <div className={styles.formInput}>
              <div className={styles.formInputLabel}>
                <span>{t('Global.Date')}</span>
                <span className={styles.asterisk}>*</span>
              </div>
              <DatePickerDropdown
                className={styles.dateInput}
                label={getDateLabel(formState.date)}
                value={new Date(formState.date)}
                setValue={handleDateChange}
                availableRange={{from: minDate, to: maxDate}}
              />
            </div>
          </div>
          <div className={styles.formInputWrapper}>
            <Input
              type="number"
              name="amount"
              placeholder={t('Funds.FeeForm.InputPlaceholder.Amount')}
              value={formState.amount}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              className={styles.formInput}
              label={t('Global.Amount')}
              validate={validate}
              errorMessage={t(errors.amount.message || '')}
              required
            />
          </div>
          <div className={styles.formInputWrapper}>
            <Input
              type="text"
              name="comment"
              value={formState.comment}
              onChange={handleChange}
              className={styles.formInput}
              placeholder={t('Funds.FeeForm.InputPlaceholder.Comment')}
              label={t('Global.Comment')}
              validate={validate}
              errorMessage={t(errors.comment.message || '')}
            />
          </div>
        </div>
      </div>
      <div className={styles.buttonsWrapper}>
        <PrevNextButtons
          isSubmitDisabled={!isFormDisabled}
          handlePrimary={onAdd}
          handleSecondary={onClose}
          primaryText={submitText}
          secondaryText={t('Global.Cancel')}
        />
      </div>
    </form>
  );
};

export default FeeForm;
