import React from 'react';
import styles from './FundExposureChart.module.scss';
import {SkeletonLoader} from '../../global';

const FundExposureChartSkeleton: React.FC = () => {
  return (
    <div className={styles.wrapper}>
      <p className={styles.title}>
        <SkeletonLoader width="80%" />
      </p>
      <div className={styles.content}>
        <div className={styles.chart}>
          <div className={styles.chartInner}>
            <SkeletonLoader isCircle width={100} height={100} />
          </div>
        </div>
        <div className={styles.legend}>
          {Array.from(Array(4)).map((_, index) => (
            <div className={styles.legendItem} key={index}>
              <SkeletonLoader width="50%" />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FundExposureChartSkeleton;
