import {createAction, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {NearCastingScenario} from '../types';

export const getScenarioOnePageReportMainDataAction = createAction(
  'scenario-one-page-report-main/get-scenario-main-info-data',
  (payload: {id: string}) => {
    return {
      payload,
    };
  }
);

export const getScenarioOnePageReportFundPerformanceDataAction = createAction(
  'scenario-one-page-report-main/get-scenario-fund-performance-data',
  (payload: {id: string}) => {
    return {
      payload,
    };
  }
);

interface ScenarioOnePageReportMainData {
  data: NearCastingScenario | null;
  isLoading: boolean;
}

const initialState: ScenarioOnePageReportMainData = {
  data: null,
  isLoading: false,
};

const scenarioOnePageReportMainSlice = createSlice({
  name: 'scenario-one-page-report-main',
  initialState,
  reducers: {
    setScenarioOnePageReportMainData(
      state: ScenarioOnePageReportMainData,
      action: PayloadAction<NearCastingScenario>
    ) {
      state.data = action.payload;
    },
    clearScenarioOnePageReportMainData(state: ScenarioOnePageReportMainData) {
      state.data = null;
    },
    setLoading(state: ScenarioOnePageReportMainData) {
      state.isLoading = !state.isLoading;
    },
  },
});

export const {
  setScenarioOnePageReportMainData,
  setLoading,
  clearScenarioOnePageReportMainData,
} = scenarioOnePageReportMainSlice.actions;

export default scenarioOnePageReportMainSlice.reducer;
