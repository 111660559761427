// Workflow form fields constants

import {ManageWorkflowFormState, WorkflowType} from '../types';

type WorkflowTypeMandatoryFieldsType = {
  [K in keyof typeof WorkflowType]: Array<keyof ManageWorkflowFormState>;
};

export const WorkflowTypeMandatoryFields: WorkflowTypeMandatoryFieldsType = {
  ExternalCollection: ['reportingCurrency'],
  InternalCollection: ['reportingCurrency', 'internalAssigneesList'],
};
