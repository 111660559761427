import {createAction, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {
  CompanyAdminReportingGroup,
  FormStatus,
  Reporting,
  ReportingSliceData,
  ReportingTableTemplateType,
  Tab,
} from '../types';
import {getActiveReportingTab} from '../utils';

export const getHistoricalReporting = createAction(
  'company-admin/get-historical-reporting',
  (id: string, templateType: string) => {
    return {
      payload: {
        id,
        templateType,
      },
    };
  }
);

interface ReportingSlice {
  data: ReportingSliceData | null;
  pageTabs: Tab[];
  activeTab: Tab | null;
  activeSubmitColumnId: string | null;
  columnSubmittingStatus: FormStatus;
  isHistoricalDataLoading: boolean;
}

const initialState: ReportingSlice = {
  data: null,
  pageTabs: [],
  activeTab: null,
  activeSubmitColumnId: null,
  columnSubmittingStatus: FormStatus.Ready,
  isHistoricalDataLoading: false,
};

const reportingSlice = createSlice({
  name: 'company-admin/reporting',
  initialState,
  reducers: {
    clearReportingData(state: ReportingSlice) {
      state.data = initialState.data;
      state.pageTabs = initialState.pageTabs;
      state.activeTab = initialState.activeTab;
      state.activeSubmitColumnId = initialState.activeSubmitColumnId;
      state.columnSubmittingStatus = initialState.columnSubmittingStatus;
      state.isHistoricalDataLoading = initialState.isHistoricalDataLoading;
    },
    setReportingData(
      state: ReportingSlice,
      action: PayloadAction<{
        reporting: Reporting;
        urlTab?: string;
        templateType?: string;
      }>
    ) {
      state.data = {
        ...action.payload.reporting,
        data: action.payload.reporting.data.map(({reportingData, ...data}) => ({
          ...data,
          reportingTableDataType:
            reportingData.type as ReportingTableTemplateType,
        })),
      };
      const getPageTabs = action.payload.reporting.data.map(item => ({
        label: item.label,
        value: item.label.toLowerCase(),
        id: item.id,
      }));
      state.pageTabs = getPageTabs;
      state.activeTab = action.payload.urlTab
        ? getActiveReportingTab(
            action.payload.reporting.data,
            action.payload.urlTab
          )
        : getPageTabs[0];
    },
    setHistoricalReportingData(
      state: ReportingSlice,
      action: PayloadAction<{
        reportingGroup: CompanyAdminReportingGroup;
        templateType?: string;
      }>
    ) {
      const activeReportingGroupIdx = state.data?.data.findIndex(
        data => data.reportingTableDataType === action.payload.templateType
      );

      if (
        activeReportingGroupIdx !== undefined &&
        state.data?.data[activeReportingGroupIdx]
      ) {
        const {reportingData, ...data} = action.payload.reportingGroup;
        state.data.data[activeReportingGroupIdx] = {
          ...data,
          reportingTableDataType:
            reportingData.type as ReportingTableTemplateType,
        };
      }
    },
    setActivePageTab(state: ReportingSlice, action: PayloadAction<Tab>) {
      state.activeTab = action.payload;
    },
    setIsHistoricalDataLoading(
      state: ReportingSlice,
      action: PayloadAction<boolean>
    ) {
      state.isHistoricalDataLoading = action.payload;
    },
  },
});

export default reportingSlice.reducer;

export const {
  clearReportingData,
  setReportingData,
  setHistoricalReportingData,
  setActivePageTab,
  setIsHistoricalDataLoading,
} = reportingSlice.actions;
