import {createAction} from '@reduxjs/toolkit';
import {UpdateLayoutPropertyPayload} from '../types/userLayoutProperty';

export const updateLayoutSettingsAction = createAction(
  'user-profile/save-layout-settings',
  (payload: UpdateLayoutPropertyPayload) => {
    return {
      payload,
    };
  }
);
