import React, {useEffect, useRef, useState} from 'react';
import {IconButton, Tag} from '../../../..';
import {ButtonStyle, StyleVariant, TagItem} from '../../../../../../types';
import styles from './TagsCell.module.scss';
import {useTooltip} from '../../../../../../hooks';
import {Position} from '../../../../../../context/TooltipContext';
import classnames from 'classnames';
import {defaultTagColor} from '../../../../../../constants';

type TagsCellProps = {
  tagItems: Array<TagItem>;
  editable?: boolean;
};

type TooltipContentProps = {
  description: string[];
};

const TagsCell: React.FC<TagsCellProps> = ({tagItems, editable = false}) => {
  const tagsContainerRef = useRef<HTMLDivElement>(null);
  const [hiddenTagsCount, setHiddenTagsCount] = useState(0);
  const [lastVisibleTag, setLastVisibleTag] = useState<number | undefined>(
    undefined
  );
  const [lastVisibleTagWidth, setLastVisibleTagWidth] = useState<
    number | undefined
  >(undefined);

  const {showTooltip, hideTooltip} = useTooltip();

  useEffect(() => {
    const tagsContainer = tagsContainerRef.current;
    if (!tagsContainer) return;

    const updateVisibleTags = () => {
      const tagsContainer = tagsContainerRef.current;
      if (!tagsContainer) return;

      const tagElements = Array.from(tagsContainer.children).filter(
        tag => !tag.classList.contains('hiddenCounterTag')
      );
      const totalTagsCount = tagElements.length;
      const tagMinWidth = 80;
      const tagCounterReservedWidth = 50;
      const marginOfLastTag = 8;
      let availableWidth = tagsContainer.offsetWidth - marginOfLastTag;
      let totalWidth = 0;
      let visibleTagsCount = 0;

      for (let idx = 0; idx < tagElements.length; idx++) {
        const element = tagElements[idx];
        const tagElement = element as HTMLElement;
        const tagWidth = tagElement.offsetWidth;
        let nextTotalWidth = totalWidth + tagWidth;
        const hasMoreTags = totalTagsCount > idx + 1;

        let currTagWidth: number = 0;
        const nextAvailableWidth = hasMoreTags
          ? availableWidth - tagCounterReservedWidth
          : availableWidth;
        currTagWidth = nextAvailableWidth - totalWidth;

        if (nextTotalWidth > availableWidth) {
          if (currTagWidth < tagMinWidth) {
            break;
          }

          visibleTagsCount++;
          setLastVisibleTag(idx);
          setLastVisibleTagWidth(currTagWidth);
          totalWidth += currTagWidth;
          break;
        }

        if (totalWidth + tagWidth > nextAvailableWidth) {
          visibleTagsCount++;
          setLastVisibleTag(idx);
          setLastVisibleTagWidth(currTagWidth);
          totalWidth += currTagWidth;
          break;
        }

        if (tagWidth > nextAvailableWidth) {
          visibleTagsCount++;
          setLastVisibleTag(idx);
          setLastVisibleTagWidth(currTagWidth);
          totalWidth += currTagWidth;
          break;
        }

        visibleTagsCount++;
        setLastVisibleTag(idx);
        setLastVisibleTagWidth(tagWidth);
        totalWidth += tagWidth;
      }

      const newHiddenTagsCount = tagItems.length - visibleTagsCount;
      if (hiddenTagsCount !== newHiddenTagsCount)
        setHiddenTagsCount(newHiddenTagsCount);
    };

    const resizeObserver = new ResizeObserver(entries => {
      updateVisibleTags();
    });
    resizeObserver.observe(tagsContainer);

    // Call updateVisibleTags function immediately to set initial widths
    updateVisibleTags();

    return () => {
      resizeObserver.disconnect();
    };
  }, [tagItems]);

  const TooltipContent = ({description}: TooltipContentProps) => {
    return (
      <>
        {description.map((label, idx) => (
          <p key={idx}>{label}</p>
        ))}
      </>
    );
  };

  const getHandleShowTooltip =
    (tagItems: Array<TagItem>, skip: boolean = true) =>
    (e: React.MouseEvent) => {
      const description = tagItems.map(tag => tag.label);

      if (skip)
        showTooltip({
          content: <TooltipContent description={description} />,
          position: Position.Top,
          target: e.currentTarget as HTMLElement,
          className: styles.tooltip,
        });
    };

  const handleHideTooltip = () => hideTooltip();

  return (
    <div className={styles.wrapper}>
      <div ref={tagsContainerRef} className={styles.tagsContainer}>
        {tagItems.map((tag: TagItem, idx: number) => (
          <Tag
            key={tag.id}
            className={classnames(styles.tagMarginRight, {
              [styles.tag]: !tag.color || tag.color === defaultTagColor,
            })}
            style={{
              visibility:
                lastVisibleTag !== undefined && idx > lastVisibleTag
                  ? 'hidden'
                  : 'visible',
              order:
                lastVisibleTag !== undefined && idx > lastVisibleTag ? 2 : 0,
            }}
            label={tag.label}
            color={tag.color}
            variant={StyleVariant.Secondary}
            width={idx === lastVisibleTag ? lastVisibleTagWidth : undefined}
            onMouseEnter={getHandleShowTooltip([tag], lastVisibleTag === idx)}
            onMouseLeave={handleHideTooltip}
          />
        ))}
        {hiddenTagsCount > 0 && (
          <Tag
            style={{order: 1}}
            className={classnames(
              styles.tagMarginRight,
              styles.tag,
              'hiddenCounterTag'
            )}
            label={`+${hiddenTagsCount}`}
            variant={StyleVariant.Secondary}
            onMouseEnter={getHandleShowTooltip(
              tagItems.slice(-hiddenTagsCount)
            )}
            onMouseLeave={handleHideTooltip}
          />
        )}
      </div>
      {editable && (
        <div>
          <IconButton
            className={styles.editButton}
            styleType={ButtonStyle.Secondary}
            icon="pencil"
            onClick={() => {}}
          />
        </div>
      )}
    </div>
  );
};

export default TagsCell;
