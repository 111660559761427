import {Configuration, PopupRequest} from '@azure/msal-browser';
import config from './config';

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
  auth: {
    clientId: config.MSAL_CLIENT_ID || '',
    authority: config.MSAL_AUTHORITY,
    knownAuthorities: [config.MSAL_KNOWN_AUTHORITIES || ''],
    redirectUri: config.MSAL_REDIRECT_URI,
    postLogoutRedirectUri: config.MSAL_POST_LOGOUT_REDIRECT_URI,
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: config.MSAL_STORE_AUTH_STATE_IN_COOKIE,
    secureCookies: config.MSAL_SECURE_COOKIES,
  },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: PopupRequest = {
  scopes: [config.API_SCOPES],
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft-ppe.com/v1.0/me',
};
