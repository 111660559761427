import axios from 'axios';
import config from '../config';
import {SearchResult} from '../types/index';

type ApiSearchResult = {
  search: SearchResult;
};

export const search = async (token: string, name: string, page?: number) => {
  const {data} = await axios.get<ApiSearchResult>(`${config.APP_URI}/search`, {
    params: {
      page: page || 1,
      name,
    },
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
  });
  return data.search;
};
