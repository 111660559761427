import React, {useEffect, useRef, useState} from 'react';
import {ButtonSize, Func} from '../../../types';
import classnames from 'classnames';
import {Icon} from '..';
import styles from './SegmentedControl.module.scss';
import {useTranslation} from 'react-i18next';

export enum SegmentedControlVariants {
  Primary = 'primary',
  Secondary = 'secondary',
  Tertiary = 'tertiary',
}

type RadioOption = {
  id: string;
  label?: string;
  value: string | number;
  color?: string;
  withCircle?: boolean;
  icon?: string;
  disabled?: boolean;
};

type ActiveBlockStyle = {
  left: number | string;
  width: number | string;
};

interface SegmentedControlProps {
  id: string;
  buttonSize?: ButtonSize;
  options: RadioOption[];
  value?: RadioOption['value'] | null;
  onChange: Func<[RadioOption['value']], void>;
  name?: string;
  variant?: SegmentedControlVariants;
  className?: string;
  disabled?: boolean;
}

const SegmentedControl: React.FC<SegmentedControlProps> = ({
  id,
  options,
  value,
  onChange,
  name = 'name',
  variant = SegmentedControlVariants.Primary,
  className,
  disabled,
  buttonSize = ButtonSize.Medium,
}) => {
  const {t} = useTranslation();
  const [activeBlockStyle, setActiveBlockStyle] = useState<ActiveBlockStyle>({
    left: 0,
    width: '100%',
  });
  const [isAnimationEnabled, setIsAnimationEnabled] = useState(false);
  const refOptions = useRef<HTMLSpanElement[]>([]);

  const handleMouseOver = (target: HTMLSpanElement) => {
    if (value) return false;
    setActiveBlockStyle({
      left: target.offsetLeft,
      width: target.offsetWidth,
    });
  };

  const handleChange = (value: string | number) => {
    setIsAnimationEnabled(true);
    onChange(value);
  };

  useEffect(() => {
    if (value && refOptions.current.length > 0) {
      const idx = options.findIndex(item => item.value === value);
      if (idx > -1) {
        setActiveBlockStyle({
          left: refOptions.current[idx].offsetLeft,
          width: refOptions.current[idx].offsetWidth,
        });
        return;
      }
      setActiveBlockStyle({
        left: refOptions.current[0].offsetLeft,
        width: refOptions.current[0].offsetWidth,
      });
    }
  }, [value, options, refOptions]);

  return (
    <div
      id={id}
      className={classnames(
        styles.wrapper,
        styles[`wrapper__${variant}`],
        styles[`wrapper__${buttonSize}`],
        className
      )}
      data-test={id}
    >
      <div
        style={{
          ...activeBlockStyle,
          opacity: !value ? 0 : 1,
          transition: isAnimationEnabled ? '' : 'none',
        }}
        className={classnames(
          styles.activeBlock,
          styles['activeBlock__' + variant],
          styles[`activeBlock__${buttonSize}`]
        )}
      />
      {options.map((option, idx) => (
        <label
          className={classnames(styles.option, styles['option__' + variant])}
          key={option.value}
          data-test={`segmented-control-option-${option.value}`}
          aria-selected={value === option.value}
        >
          <input
            id={`${id}-${option.id}`}
            type="radio"
            name={name}
            value={option.value}
            checked={value === option.value}
            onChange={() => handleChange(option.value)}
            disabled={option.disabled || !!disabled}
          />
          <span
            ref={elem =>
              elem && !refOptions.current[idx] && refOptions.current.push(elem)
            }
            className={classnames(
              styles.optionInner,
              value === option.value && styles['optionInner__' + variant],
              styles[`optionInner__${buttonSize}`]
            )}
            onMouseEnter={e => handleMouseOver(e.currentTarget)}
          >
            {option.withCircle && (
              <div
                className={styles.withCircle}
                style={{
                  backgroundColor:
                    option.value === value
                      ? option.color
                      : `rgb(var(--colors-gray-5))`,
                }}
              />
            )}
            {option.icon && (
              <Icon
                className={classnames(
                  styles.icon,
                  value === option.value && styles.icon__active,
                  styles[`icon__${buttonSize}`]
                )}
                name={option.icon}
              />
            )}
            {t(option.label || '')}
          </span>
        </label>
      ))}
    </div>
  );
};

export default SegmentedControl;
