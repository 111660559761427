import React, {Fragment} from 'react';
import {Link} from 'react-router-dom';
import {DropdownLinkList, DropdownWrapper, Icon} from '../../global';
import {StyleVariant, Func, UserRole} from '../../../types/';
import styles from './NavAccount.module.scss';
import {useTranslation} from 'react-i18next';
import routePaths from '../../../routePaths';

interface NavAccountProps {
  userName?: string;
  userMail?: string;
  userRole?: UserRole;
  userImage?: string;
  onClick: () => void;
}

const NavAccount: React.FC<NavAccountProps> = ({
  userName = '',
  userMail = '',
  userRole = '',
  userImage = '',
  onClick,
}): JSX.Element => {
  const {t} = useTranslation();
  const handleClick =
    (handleCloseDropdown: Func<[], void>) =>
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      handleCloseDropdown();
      onClick();
    };
  return (
    <DropdownWrapper
      variant={StyleVariant.Secondary}
      label={
        <div className={styles.circle}>
          {userImage ? (
            <img src={userImage} alt="" />
          ) : (
            <Icon className={styles.userIcon} name={'user'} />
          )}
        </div>
      }
    >
      {({handleCloseDropdown}) => (
        <DropdownLinkList
          options={[
            <Fragment>
              <div
                className={styles.username}
                data-test="dropdown-link-username"
              >
                {t('NavigationMenu.Greeting', {userName})}
              </div>
              <div className={styles.email} data-test="dropdown-link-usermail">
                {userMail}
              </div>
            </Fragment>,
            userRole === UserRole.ClientAdmin ? (
              <Link
                to={routePaths.ADMIN_USER_USERS}
                onClick={handleCloseDropdown}
                data-test="dropdown-link-user-administration"
              >
                {t('Global.UserAdministration')}
              </Link>
            ) : null,
            userRole === UserRole.ClientAdmin ? (
              <Link
                to={routePaths.ADMIN_DATA_COLLECTION_TEMPLATE_CONFIGURATION}
                onClick={handleCloseDropdown}
                data-test="dropdown-link-data-collection-administration"
              >
                {t('DataCollectionAdministration.Label')}
              </Link>
            ) : null,
            <button
              onClick={handleClick(handleCloseDropdown)}
              data-test="dropdown-link-logout"
            >
              {t('NavigationMenu.LogOut')}
            </button>,
          ]}
        />
      )}
    </DropdownWrapper>
  );
};

export default NavAccount;
