import React, {Fragment, useEffect, useMemo, useState} from 'react';
import {
  DateRange,
  DropdownWrapperAlignmentType,
  MetricsChartProps,
  MetricsChartType,
  MonitoringChartDateRange,
} from '../../../types';
import styles from './MetricsChart.module.scss';
import {
  Area,
  CartesianGrid,
  ComposedChart,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import CustomYAxisTick from '../../global/custom-y-axis-tick/CustomYAxisTick';
import {
  DateRangePickerDropdown,
  Icon,
  LoadingOverlay,
  MetricsController,
  SegmentedControl,
} from '../../global';
import MetricsChartTooltip from './components/MetricsChartTooltip';
import {getMonitoringXAxisConfig} from '../../../utils';
import {useBreakpoint} from '../../../hooks';
import {
  addMonths,
  addYears,
  startOfMonth,
  startOfQuarter,
  startOfYear,
} from 'date-fns';
import {
  companyMonitoringChartDateRangeOptions,
  metricsChartDateRangePresets,
} from '../../../constants';
import {useTranslation} from 'react-i18next';

const MetricsChart: React.FC<MetricsChartProps> = ({
  id,
  data,
  config,
  onChangeController,
  locale,
  isRequestPending,
  reportingInterval,
}) => {
  const [dateRange, setDateRange] = useState({
    to: addMonths(startOfMonth(new Date()), -1),
    from: addYears(startOfMonth(new Date()), -1),
  });
  const [dateRangeType, setDateRangeType] = useState(
    MonitoringChartDateRange.OneYear
  );
  const {isLarge, isXLarge, isXXlarge} = useBreakpoint();
  const {t} = useTranslation();

  const {ticks, format} = useMemo(
    () =>
      getMonitoringXAxisConfig(
        dateRange.from,
        dateRange.to,
        reportingInterval,
        isLarge,
        isXLarge,
        isXXlarge
      ),
    [dateRange, reportingInterval, isXLarge, isLarge, isXXlarge]
  );

  const dateRangeOptions = useMemo(() => {
    let current = startOfMonth(new Date());
    if (reportingInterval === 'quarterly') {
      current = startOfQuarter(new Date());
    }
    return {
      [MonitoringChartDateRange.Custom]: addMonths(current, -3).getTime(),
      [MonitoringChartDateRange.SixMonths]: addMonths(current, -6).getTime(),
      [MonitoringChartDateRange.OneYear]: addMonths(current, -12).getTime(),
      [MonitoringChartDateRange.ThreeYears]: addYears(current, -3).getTime(),
      [MonitoringChartDateRange.FiveYears]: addYears(current, -5).getTime(),
      [MonitoringChartDateRange.TenYears]: addYears(current, -10).getTime(),
    };
  }, [reportingInterval]);

  const getYAxisLabel = (yAxisPosition: number) => {
    const labels = config
      .filter(item => item.yAxisPosition === yAxisPosition)
      .map(item => {
        if (item.unitLabel === 'Reporting currency') {
          return item.currency;
        }
        return item.unitLabel;
      });
    const sameLabels = labels.every(item => item === labels[0]);
    if (labels.length > 0 && sameLabels) return labels[0];
    return null;
  };

  const handleChangeYAxis = (id: string) => (value: number) => {
    const configItem = config.find(config => config.id === id);
    if (configItem) {
      onChangeController({
        ...configItem,
        yAxisPosition: !!value ? 1 : 0,
      });
    }
  };

  const handleChangeChartType = (id: string) => (value: MetricsChartType) => {
    const configItem = config.find(config => config.id === id);
    if (configItem) {
      onChangeController({
        ...configItem,
        chartType: value,
      });
    }
  };

  const handleRemoveConfigItem = (id: string) => () => {
    const configItem = config.find(config => config.id === id);
    if (configItem) {
      onChangeController({
        ...configItem,
        chartType: MetricsChartType.LineChart,
        yAxisPosition: 0,
        isSelected: false,
      });
    }
  };

  const handleChangeDatePicker = (value: DateRange) => {
    if (reportingInterval === 'monthly') {
      setDateRange({
        from: startOfMonth(value.from as Date),
        to: startOfMonth(value.to as Date),
      });
    }
    if (reportingInterval === 'quarterly') {
      setDateRange({
        from: startOfQuarter(value.from as Date),
        to: startOfQuarter(value.to as Date),
      });
    }
  };

  const handleChangeDateRangeType = (value: MonitoringChartDateRange) => {
    setDateRangeType(value);
    setDateRange(prev => ({...prev, from: new Date(dateRangeOptions[value])}));
  };

  const handleCancelDatePicker = () => {
    setDateRange({
      from: addMonths(startOfMonth(new Date()), -2),
      to: addMonths(startOfMonth(new Date()), -1),
    });
  };

  useEffect(() => {
    if (reportingInterval === 'quarterly') {
      setDateRange(prev => ({
        to: startOfQuarter(prev.to),
        from: startOfQuarter(prev.from),
      }));
    }
    if (reportingInterval === 'monthly') {
      setDateRange(prev => ({
        to: startOfMonth(prev.to),
        from: startOfMonth(prev.from),
      }));
    }
  }, [reportingInterval]);

  const handleApplyColor = (id: string, color: string) => {
    const configItem = config.find(config => config.id === id);
    if (configItem) {
      onChangeController({
        ...configItem,
        color,
      });
    }
  };

  return (
    <div id={id} className={styles.wrapper}>
      <div className={styles.dateRangeWrapper}>
        <DateRangePickerDropdown
          rangePresets={metricsChartDateRangePresets}
          availableDateRange={{
            from: addYears(startOfYear(new Date()), -10),
            to: addMonths(startOfMonth(new Date()), -1),
          }}
          dateFormat={'dd-MM-yyyy'}
          onApply={handleChangeDatePicker}
          onCancel={handleCancelDatePicker}
          selectedDateRange={dateRange}
          alignment={DropdownWrapperAlignmentType.Left}
          disabled={dateRangeType !== MonitoringChartDateRange.Custom}
        />
        <SegmentedControl
          id={`${id}-segmented-control-date-range-options`}
          value={dateRangeType}
          options={companyMonitoringChartDateRangeOptions}
          onChange={value =>
            handleChangeDateRangeType(value as MonitoringChartDateRange)
          }
          className={styles.dateRangeSelector}
        />
      </div>
      <div className={styles.chart} id={'monitoring-metrics-chart'}>
        {isRequestPending ? (
          <LoadingOverlay className={styles.overlay} />
        ) : null}
        {config.length === 0 ? (
          <div className={styles.placeholder}>
            {!isRequestPending ? (
              <Fragment>
                <Icon className={styles.placeholderIcon} name={'add-chart'} />
                <div className={styles.placeholderTitle}>
                  {t('MetricsChart.Title')}
                </div>
                <div className={styles.placeholderText}>
                  {t('MetricsChart.Description')}
                </div>
              </Fragment>
            ) : null}
          </div>
        ) : (
          <Fragment>
            <div className={styles.controllersWrapper}>
              {config.map((metricConfig, idx) => (
                <MetricsController
                  key={metricConfig.chartLabel + idx}
                  setYAxisLeft={handleChangeYAxis(metricConfig.id)}
                  setChartType={handleChangeChartType(metricConfig.id)}
                  onRemove={handleRemoveConfigItem(metricConfig.id)}
                  onApplyColor={handleApplyColor}
                  {...metricConfig}
                />
              ))}
            </div>
            <div className={styles.chartWrapper}>
              <ResponsiveContainer id="metrics-chart" width="100%" height={590}>
                <ComposedChart
                  data={data}
                  margin={{top: 50, left: 100, right: 100, bottom: 85}}
                >
                  <defs>
                    {config.map((item, idx) => (
                      <linearGradient
                        key={item.chartLabel}
                        id={`metrics-area-gradient-${idx}`}
                        gradientTransform="rotate(90)"
                      >
                        <stop
                          offset="0%"
                          stopColor={`rgba(var(--colors-${item.color}),0.32`}
                        />
                        <stop
                          offset="100%"
                          stopColor={`rgba(var(--colors-${item.color}),0.12)`}
                        />
                      </linearGradient>
                    ))}
                  </defs>
                  <CartesianGrid
                    vertical={false}
                    strokeDasharray="2"
                    stroke={`rgb(var(--colors-gray-6))`}
                    width={1920}
                    x={-100}
                  />
                  <rect
                    x={-100}
                    y={500}
                    width={1920}
                    height={48}
                    rx={0}
                    fill="rgb(var(--colors-gray-8))"
                  ></rect>
                  <XAxis
                    allowDataOverflow={true}
                    dataKey="date"
                    axisLine={false}
                    height={100}
                    tickLine={false}
                    domain={[dateRange.from.getTime(), dateRange.to.getTime()]}
                    interval={0}
                    type="number"
                    ticks={ticks}
                    tick={
                      <CustomYAxisTick
                        showAxis={true}
                        xOffset={0}
                        yOffset={115}
                        fill="gray-3"
                        fontSize={12}
                        fontWeight={500}
                        format={format}
                      />
                    }
                  />
                  <YAxis
                    axisLine={false}
                    tickLine={false}
                    domain={['auto', 'auto']}
                    yAxisId={0}
                    width={1}
                    label={{
                      dx: -75,
                      dy: -30,
                      fill: 'rgb(var(--colors-gray-3))',
                      value: getYAxisLabel(0),
                      position: 'insideTopLeft',
                      fontSize: 12,
                      offset: 0,
                    }}
                    tickCount={7}
                    tick={
                      <CustomYAxisTick
                        showAxis={true}
                        xOffset={-50}
                        yOffset={4}
                        fill="gray-3"
                        fontSize={12}
                        fontWeight={500}
                        locale={locale}
                        format={'auto'}
                      />
                    }
                  />
                  <YAxis
                    axisLine={false}
                    tickLine={false}
                    domain={['auto', 'auto']}
                    yAxisId={1}
                    width={1}
                    label={{
                      dx: 75,
                      dy: -30,
                      fill: 'rgb(var(--colors-gray-3))',
                      value: getYAxisLabel(1),
                      position: 'insideTopRight',
                      fontSize: 12,
                      offset: 0,
                    }}
                    tickCount={7}
                    orientation="right"
                    tick={
                      <CustomYAxisTick
                        showAxis={true}
                        xOffset={50}
                        yOffset={4}
                        fill="gray-3"
                        fontSize={12}
                        fontWeight={500}
                        locale={locale}
                        format={'auto'}
                      />
                    }
                  />
                  <Tooltip
                    content={<MetricsChartTooltip dateFormat={format} />}
                  />
                  {config.map((metricConfig, idx) =>
                    metricConfig.chartType === MetricsChartType.LineChart ? (
                      <Line
                        key={metricConfig.chartLabel}
                        dataKey={metricConfig.chartLabel}
                        dot={false}
                        type={'stepBefore'}
                        stroke={`rgb(var(--colors-${metricConfig.color}))`}
                        yAxisId={metricConfig.yAxisPosition}
                        strokeWidth={2}
                        activeDot={{
                          stroke: `rgb(var(--colors-${metricConfig.color}))`,
                          fill: `rgb(var(--colors-${metricConfig.color}))`,
                          strokeWidth: 5,
                          strokeOpacity: 0.5,
                          r: 6.5,
                        }}
                      />
                    ) : metricConfig.chartType ===
                      MetricsChartType.AreaChart ? (
                      <Area
                        dot={false}
                        key={metricConfig.chartLabel}
                        dataKey={metricConfig.chartLabel}
                        type={'stepBefore'}
                        stroke={'none'}
                        fill={`url(#metrics-area-gradient-${idx})`}
                        yAxisId={metricConfig.yAxisPosition}
                        fillOpacity={1}
                        activeDot={{
                          stroke: `rgb(var(--colors-${metricConfig.color}))`,
                          fill: `rgb(var(--colors-${metricConfig.color}))`,
                          strokeWidth: 5,
                          strokeOpacity: 0.5,
                          r: 6.5,
                        }}
                      />
                    ) : null
                  )}
                </ComposedChart>
              </ResponsiveContainer>
            </div>
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default MetricsChart;
