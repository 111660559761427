import {RootState} from '../store';
import {createSelector} from '@reduxjs/toolkit';

const documents = (state: RootState) => state.documents;

const documentsSelector = createSelector([documents], documents => {
  return {
    dataLoaded: !!documents.documentExplorer.data,
    label: documents.documentExplorer.data?.label || 'Documents',
    documentsCount: documents.documentExplorer.data?.documentsCount || '',
  };
});

export default documentsSelector;
