export const fundInvestmentBarChartFilterKpis = [
  {
    name: 'asAt',
    label: 'As at',
  },
  {
    name: 'fundName',
    label: 'Funds',
    placeHolderKey: 'Select funds...',
  },
  {
    name: 'companyName',
    label: 'Companies',
    placeHolderKey: 'Select companies...',
  },
  {
    name: 'currency',
    label: 'Currency',
  },
  {
    name: 'sector',
    label: 'Sector',
    placeHolderKey: 'Select sector...',
  },
  {
    name: 'region',
    label: 'Region',
    placeHolderKey: 'Select region...',
  },
  {
    name: 'reset',
    label: 'Reset filters',
  },
  {
    name: 'yAxis',
    label: 'Y Axis',
  },
  {
    name: 'yAxisRange',
  },
];

export const fundInvestmentBubbleChartFilterKpis = [
  ...fundInvestmentBarChartFilterKpis,
  {
    name: 'xAxis',
    label: 'X Axis',
  },
  {
    name: 'xAxisRange',
  },
  {
    name: 'zAxis',
    label: 'Z Axis',
  },
  {
    name: 'zAxisRange',
  },
];

export const fundInvestmentTreemapChartFilterKpis = [
  ...fundInvestmentBarChartFilterKpis,
  {
    name: 'xAxis',
    label: 'X Axis',
  },
  {
    name: 'xAxisRange',
  },
];

export const portfolioMonitoringBarChartFilterKpis = [
  {
    name: 'asAt',
    label: 'As at',
  },
  {
    name: 'currency',
    label: 'Currency',
  },
  {
    name: 'companyName',
    label: 'Companies',
    placeHolderKey: 'Select companies...',
  },
  {
    name: 'reset',
    label: 'Reset filters',
  },
  {
    name: 'yAxis',
    label: 'Y Axis',
  },
  {
    name: 'yAxisRange',
  },
];

export const portfolioMonitoringBubbleChartFilterKpis = [
  ...portfolioMonitoringBarChartFilterKpis,
  {
    name: 'xAxis',
    label: 'X Axis',
  },
  {
    name: 'xAxisRange',
  },
  {
    name: 'zAxis',
    label: 'Z Axis',
  },
  {
    name: 'zAxisRange',
  },
];

export const portfolioMonitoringTreemapChartFilterKpis = [
  ...portfolioMonitoringBarChartFilterKpis,
  {
    name: 'xAxis',
    label: 'X Axis',
  },
  {
    name: 'xAxisRange',
  },
];

export const axisMetricsOptions = [
  {
    id: 'valuation',
    value: 'valuation',
    label: 'Valuation',
  },
  {
    id: 'totalCost',
    value: 'totalCost',
    label: 'Total Cost',
  },
  {
    id: 'roi',
    value: 'roi',
    label: 'ROI',
  },
  {
    id: 'irr',
    value: 'irr',
    label: 'IRR',
  },
  {
    id: 'totalValue',
    value: 'totalValue',
    label: 'Total value',
  },
  {
    id: 'cost',
    value: 'cost',
    label: 'Cost',
  },
  {
    id: 'equityCost',
    value: 'equityCost',
    label: 'Equity cost',
  },
  {
    id: 'debtCost',
    value: 'debtCost',
    label: 'Debt cost',
  },
  {
    id: 'otherCosts',
    value: 'otherCosts',
    label: 'Other costs',
  },
  {
    id: 'investmentIncome',
    value: 'investmentIncome',
    label: 'Investment income',
  },
  {
    id: 'unrealisedGainLoss',
    value: 'unrealisedGainLoss',
    label: 'Unrealised gain/loss',
  },
  {
    id: 'realisedLoss',
    value: 'realisedLoss',
    label: 'Realised gain/loss',
  },
  {
    id: 'totalRealised',
    value: 'totalRealised',
    label: 'Total realised',
  },
];
