import React, {TdHTMLAttributes} from 'react';
import classnames from 'classnames';
import styles from './ReportingTableEmptyCell.module.scss';
import {ReportingTableCell} from '../../../../../types/reportingTable';

type ReportingTableEmptyCellProps = {
  cell: ReportingTableCell;
  classNames?: string;
} & TdHTMLAttributes<HTMLTableCellElement>;

const ReportingTableEmptyCell = ({
  classNames,
  cell,
  ...props
}: ReportingTableEmptyCellProps) => {
  const Tag = cell?.afterSeparator ? 'td' : 'th';

  return (
    <Tag
      {...props}
      className={classnames(styles.cell, {
        [styles.headerSide]: !cell?.afterSeparator,
        [styles.dataSide]: cell?.afterSeparator,
        [`${classNames}`]: !!classNames,
        [styles.notVisible]: !cell.isVisible && !props.colSpan,
      })}
    >
      <div className={styles.cellInnerWrapper}>&nbsp;</div>
    </Tag>
  );
};

export default ReportingTableEmptyCell;
