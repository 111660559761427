import React from 'react';
import styles from './RadioInput.module.scss';
import {ExportDropdownOption} from '../../../types';
import {useTranslation} from 'react-i18next';

interface RadioInputProps {
  option: ExportDropdownOption;
  checked: boolean;
  handleChange: Function;
}

const RadioInput: React.FC<RadioInputProps> = ({
  option,
  checked,
  handleChange,
}) => {
  const {t} = useTranslation();

  return (
    <div className={styles.wrapper}>
      <input
        className={styles.radio}
        type="radio"
        id={option.label}
        name={`Export option: ${option.value}`}
        value={option.value}
        checked={checked}
        onChange={() => handleChange(option)}
      />
      <label className={styles.label} htmlFor={option.label}>
        {t(option.label)}
      </label>
    </div>
  );
};

export default RadioInput;
