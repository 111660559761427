import React from 'react';
import {Row} from '@tanstack/react-table';
import {FeeAnalysisTableItemRow} from '../../../../types';
import TransactionsTable from '../transactions-table/TransactionsTable';
import genFeeAnalysisTransactionsTableColumnDefs from './FeeAnalysisSubTableColumnDefs';
import styles from './FeeAnalysisTableExpandedRow.module.scss';

const FeeAnalysisTableExpandedRow = ({
  row,
}: {
  row: Row<FeeAnalysisTableItemRow>;
}) => {
  return (
    <div className={styles.expandedRow}>
      <TransactionsTable
        data={row.original.transactionData.data}
        columns={genFeeAnalysisTransactionsTableColumnDefs(
          row.original.transactionData.headerMapping,
          row.original.transactionData.footerMapping
        )}
        getRowCanExpand={() => false}
        expandedView={() => <></>}
        className={styles.table}
      />
    </div>
  );
};

export default FeeAnalysisTableExpandedRow;
