import React, {useRef, useState} from 'react';
import RadioInput from '../radio-input/RadioInput';
import styles from './ExportDropdown.module.scss';
import {Button, IconButton} from '../../global';
import classnames from 'classnames';
import {useOnOutsideClick} from '../../../hooks';
import {
  ExportDropdownOption,
  ExportDropdownProps,
  ButtonStyle,
} from '../../../types';
import {useTranslation} from 'react-i18next';

const ExportDropdown: React.FC<ExportDropdownProps> = ({
  exportOptions,
  exportHandler,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const elemRef = useRef<HTMLDivElement>(null);
  const [selectedOption, setSelectedOption] = useState(exportOptions[0]);

  const {t} = useTranslation();

  const handleChange = (value: ExportDropdownOption) => {
    setSelectedOption(value);
  };

  const handleExport = async () => {
    try {
      setIsExporting(true);
      await exportHandler(selectedOption);
      setIsExporting(false);
      closeDropdown();
    } catch (error) {
      setIsExporting(false);
      closeDropdown();
      console.error(error);
    }
  };

  const closeDropdown = () => {
    setIsOpen(false);
  };

  useOnOutsideClick(elemRef, closeDropdown);

  return (
    <div ref={elemRef} className={styles.wrapper} data-test="export-btn">
      <IconButton onClick={() => setIsOpen(prev => !prev)} icon="export" />
      <div className={classnames(styles.dropdown, isOpen && styles.isOpen)}>
        <div className={styles.title}>Export</div>
        <div>
          {exportOptions.map((option, index) => (
            <RadioInput
              option={option}
              checked={selectedOption.value === option.value}
              handleChange={handleChange}
              key={option.value}
            />
          ))}
        </div>
        <div className={styles.actions}>
          <Button
            onClick={handleExport}
            text={t('Global.Export')}
            className={styles.lightThemePrimaryButton}
            loading={isExporting}
          />
          <Button
            onClick={closeDropdown}
            text={t('Global.Cancel')}
            styleType={ButtonStyle.Secondary}
            className={styles.lightThemeSecondaryButton}
          />
        </div>
      </div>
    </div>
  );
};

export default ExportDropdown;
