import React from 'react';
import classnames from 'classnames';
import styles from './ReportingTableForecastStatusCell.module.scss';
import {ActivityBadge, Button, Icon} from '../../../index';
import {ButtonSize, ButtonStyle, Func} from '../../../../../types';
import {ReportingTableColumnStatusLabel} from '../../../../../types/reportingTable';
import {valueFormat} from '../../../../../utils/value-format';
import {useTranslation} from 'react-i18next';

type ReportingTableStatusForecastCellProps = {
  readonly: boolean;
  cell: any;
  classNames?: string;
  onColumnSubmit?: Func<[columndId: string], void>;
  colSpan: number;
  dataColumnCount: number;
  onPrevious: Func<any, void>;
  onNext: Func<any, void>;
};

const ReportingTableStatusForecastCell = ({
  readonly,
  cell,
  classNames,
  onColumnSubmit,
  colSpan,
  dataColumnCount,
  onNext,
  onPrevious,
}: ReportingTableStatusForecastCellProps) => {
  const {t} = useTranslation();

  const defaultCollapsedDataColumnCount: number = 6;
  const defaultNonCollapsedDataColumnCount: number = 3;

  return (
    <td
      className={classnames(styles.cell, {
        [`${classNames}`]: !!classNames,
        [styles.notVisible]:
          !cell?.data?.value || !cell?.data?.value?.isActivePeriod,
      })}
      colSpan={colSpan}
    >
      <div
        className={classnames(styles.cellInnerWrapper, {
          [styles.fakeColumn]: !cell?.data,
        })}
      >
        <div className={styles.headerLeft}>
          <span className={styles.forecastDuration}>
            {cell?.data?.value?.label}
          </span>
          <div>
            {cell?.data?.value?.status ===
              ReportingTableColumnStatusLabel.Submitted && !readonly ? (
              <div className={styles.submitBadge}>
                <Icon className={styles.submitBadgeIcon} name="check" />
              </div>
            ) : (
              <ActivityBadge
                label={cell?.data?.value?.reportingStatus}
                color={
                  cell?.data?.value?.reportingStatus ===
                  ReportingTableColumnStatusLabel.Overdue
                    ? 'red'
                    : cell?.data?.value?.reportingStatus ===
                      ReportingTableColumnStatusLabel.Submitted
                    ? 'lan-green'
                    : 'gray-7'
                }
                textColor={
                  cell?.data?.value?.reportingStatus ===
                  ReportingTableColumnStatusLabel.Submitted
                    ? 'black'
                    : 'white'
                }
                fontSize={14}
                fontWeight={400}
              />
            )}
          </div>
          <span className={styles.forecastDueDate}>
            {cell?.data?.value?.status ? `${cell?.data?.value?.status} ` : ''}
            {valueFormat(cell.data?.value?.date, cell?.data?.format).value}
          </span>
        </div>

        {
          <div className={styles.headerRight}>
            {dataColumnCount > defaultCollapsedDataColumnCount && (
              <span className={styles.forecastPagination}>
                {cell?.data?.value?.isOnFinalPage ? '7 - 12' : '1 - 6'}{' '}
                {t('DataCollection.ReportingTable.ForecastStatus.Label')}
              </span>
            )}
            {colSpan !== defaultNonCollapsedDataColumnCount &&
              dataColumnCount > defaultCollapsedDataColumnCount &&
              !cell?.data?.value?.isOnFinalPage && (
                <Button
                  icon="arrow-right"
                  size={ButtonSize.Medium}
                  styleType={ButtonStyle.Secondary}
                  text={
                    readonly
                      ? t('DataCollection.ReportingTable.ForecastStatus.Button')
                      : t(
                          'DataCollection.ReportingTable.ForecastStatus.Button.Submit'
                        )
                  }
                  onClick={() => {
                    onNext();
                  }}
                  disabled={cell?.disabled}
                />
              )}
            {colSpan !== defaultNonCollapsedDataColumnCount &&
              dataColumnCount > defaultCollapsedDataColumnCount &&
              cell?.data?.value?.isOnFinalPage && (
                <Button
                  icon="arrow-left"
                  size={ButtonSize.Medium}
                  styleType={ButtonStyle.Secondary}
                  text={t(
                    'DataCollection.ReportingTable.ForecastStatus.Button.PrevPage'
                  )}
                  onClick={() => {
                    onPrevious();
                  }}
                  disabled={cell?.disabled}
                />
              )}
            {colSpan !== defaultNonCollapsedDataColumnCount &&
              cell?.data?.value?.status !==
                ReportingTableColumnStatusLabel.Submitted &&
              ((colSpan !== defaultNonCollapsedDataColumnCount &&
                dataColumnCount > defaultCollapsedDataColumnCount &&
                !!cell?.data?.value?.isOnFinalPage &&
                !readonly) ||
                (colSpan !== defaultNonCollapsedDataColumnCount &&
                  dataColumnCount <= defaultCollapsedDataColumnCount &&
                  !readonly)) && (
                <Button
                  icon="airplane-right"
                  size={ButtonSize.Medium}
                  styleType={ButtonStyle.Primary}
                  text={t(
                    'DataCollection.ReportingTable.ForecastStatus.Button.SubmitAll'
                  )}
                  onClick={() =>
                    onColumnSubmit && onColumnSubmit(cell.columnId)
                  }
                  disabled={cell?.disabled}
                />
              )}
          </div>
        }
      </div>
    </td>
  );
};

export default ReportingTableStatusForecastCell;
