import {RefObject, useEffect, useRef, useState} from 'react';
import {useIntersectionObserver} from './index';

function useChartAnimated(): {
  ref: RefObject<HTMLDivElement>;
  isAnimated: boolean;
} {
  const [isAnimated, setIsAnimated] = useState(false);

  const ref = useRef<HTMLDivElement>(null);
  const inViewport = useIntersectionObserver(ref, {freezeOnceVisible: true});
  useEffect(() => {
    if (inViewport && !isAnimated) {
      setIsAnimated(true);
    }
  }, [isAnimated, inViewport]);

  return {ref, isAnimated};
}

export default useChartAnimated;
