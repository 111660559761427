import React from 'react';
import {Icon} from '../index';
import styles from './InfoPlaceholder.module.scss';

interface InfoPlaceholderProps {
  icon: string;
  title: string;
  subtitle?: string;
}
const InfoPlaceholder: React.FC<InfoPlaceholderProps> = ({
  icon,
  title,
  subtitle,
}) => {
  return (
    <div className={styles.wrapper}>
      <Icon className={styles.icon} name="info" />
      <div className={styles.title}>{title}</div>
      {!!subtitle && <div>{subtitle}</div>}
    </div>
  );
};

export default InfoPlaceholder;
