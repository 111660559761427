import {
  ComponentWidgetType,
  ExportDropdownOption,
  ExposureChartType,
  TableSettings,
} from '../../../types';

export const fundExposureCurrencyWidgetSettings = (
  zipFileName: string
): TableSettings => {
  return {
    fileName: zipFileName,
    name: 'Currency Exposure',
    columnVisibility: {},
    headerMapping: [
      {
        columnId: 'name',
        label: 'Currency',
      },
      {
        columnId: 'value',
        label: 'Amount',
      },
    ],
    columnOrder: ['name', 'value'],
    meta: {
      columns: {
        name: {
          styling: {
            align: 'right',
          },
        },
        value: {
          styling: {
            align: 'right',
          },
        },
      },
    },
  };
};

export const fundExposureGeographicWidgetSettings = (
  zipFileName: string
): TableSettings => {
  return {
    fileName: zipFileName,
    name: 'Geographical Exposure',
    columnVisibility: {},
    headerMapping: [
      {
        columnId: 'name',
        label: 'Region',
      },
      {
        columnId: 'value',
        label: 'Amount',
      },
    ],
    columnOrder: ['name', 'value'],
    meta: {
      columns: {
        name: {
          styling: {
            align: 'right',
          },
        },
        value: {
          styling: {
            align: 'right',
          },
        },
      },
    },
  };
};

export const fundExposureInvestorWidgetSettings = (
  zipFileName: string
): TableSettings => {
  return {
    fileName: zipFileName,
    name: 'Investor Exposure',
    columnVisibility: {},
    headerMapping: [
      {
        columnId: 'name',
        label: 'Investor type',
      },
      {
        columnId: 'value',
        label: 'Count',
      },
    ],
    columnOrder: ['name', 'value'],
    meta: {
      columns: {
        name: {
          styling: {
            align: 'right',
          },
        },
        value: {
          styling: {
            align: 'right',
          },
        },
      },
    },
  };
};

export const fundExposureInvestorsWidgetSettings = (
  zipFileName: string
): TableSettings => {
  return {
    fileName: zipFileName,
    name: 'Investors by type',
    columnVisibility: {},
    headerMapping: [
      {
        columnId: 'name',
        label: 'Investor type',
      },
      {
        columnId: 'value',
        label: 'Count',
      },
    ],
    columnOrder: ['name', 'value'],
    meta: {
      columns: {
        name: {
          styling: {
            align: 'right',
          },
        },
        value: {
          styling: {
            align: 'right',
          },
        },
      },
    },
  };
};

export const fundExposureCapitalInvestorWidgetSettings = (
  zipFileName: string
): TableSettings => {
  return {
    fileName: zipFileName,
    name: 'Capital committed by investor type',
    columnVisibility: {},
    headerMapping: [
      {
        columnId: 'name',
        label: 'Investor type',
      },
      {
        columnId: 'value',
        label: 'Value',
      },
    ],
    columnOrder: ['name', 'value'],
    meta: {
      columns: {
        name: {
          styling: {
            align: 'right',
          },
        },
        value: {
          styling: {
            align: 'right',
          },
        },
      },
    },
  };
};

export const exportOverviewExposuresOptions: ExportDropdownOption[] = [
  {
    label: 'Currency exposure',
    value: 'overview-currency-exposure',
  },
  {
    label: 'Geographical exposure',
    value: 'overview-geographical-exposure',
  },
  {
    label: 'Investor by type',
    value: 'overview-investor-by-type',
  },
];

export const exportStructureExposuresOptions: ExportDropdownOption[] = [
  {
    label: 'Investors by type',
    value: 'structure-investor-by-type',
  },
  {
    label: 'Capital committed by investor type',
    value: 'structure-capital-committed',
  },
];

// Helpers
export const generateDataForExport = (
  widgets: ComponentWidgetType[],
  option: ExportDropdownOption
) => {
  let selectedWidgetData = widgets[0]?.data?.find(
    widget =>
      widget?.label && hyphenUp(widget?.label) === hyphenUp(option.label)
  );

  const timeSeries = selectedWidgetData
    ? (selectedWidgetData?.timeSeries as ExposureChartType)
    : null;

  return timeSeries?.data;
};

export const imagesExportPayload = (
  option: ExportDropdownOption,
  zipFileName: string
) => {
  return {
    id: `exposure-chart-${hyphenUp(option.label)}`,
    fileName: zipFileName,
    opts: [
      {
        fileName: `${zipFileName}_TransparentBackground`,
        type: 'image/svg+xml',
        ext: 'svg',
        bgColor: 'transparent',
      },
      {
        fileName: `${zipFileName}_WithBackground`,
        type: 'image/svg+xml',
        ext: 'svg',
        bgColor: 'black',
      },
      {
        fileName: `${zipFileName}_TransparentBackground`,
        type: 'image/png',
        ext: 'png',
        bgColor: 'transparent',
      },
      {
        fileName: `${zipFileName}_WithBackground`,
        type: 'image/png',
        ext: 'png',
        bgColor: 'black',
      },
    ],
  };
};

export const pickCorrectSettings = (
  option: ExportDropdownOption,
  zipFileName: string
) => {
  let settings;
  switch (option.label) {
    case 'Currency exposure':
      settings = fundExposureCurrencyWidgetSettings(zipFileName);
      break;
    case 'Geographical exposure':
      settings = fundExposureGeographicWidgetSettings(zipFileName);
      break;
    case 'Investor by type':
      settings = fundExposureInvestorWidgetSettings(zipFileName);
      break;
    case 'Investors by type':
      settings = fundExposureInvestorsWidgetSettings(zipFileName);
      break;
    case 'Capital committed by investor type':
      settings = fundExposureCapitalInvestorWidgetSettings(zipFileName);
      break;
    default:
      settings = null;
  }

  return settings;
};

export const hyphenUp = (name: string) => {
  return name.split(' ').join('-').toLowerCase();
};
