import React, {ChangeEvent, MouseEvent, useMemo} from 'react';
import {Toggle} from '../';
import {useTranslation} from 'react-i18next';
import {useTooltip} from '../../../hooks';
import {activeDataVisibilitySwitchPages} from '../../../constants';
import {Position} from '../../../context/TooltipContext';
import {useAppDispatch, useAppSelector} from '../../../hooks/useReduxHooks';
import {setGlobalInformation} from '../../../reducers/globalInformationSlice';
import {
  ButtonStyle,
  GlobalInformationActionType,
  ModalHandlerReduxActions,
} from '../../../types';
import {updateUserDataVisibilityAction} from '../../../reducers/userProfileServiceSlice';
import styles from './DataVisibilityToggle.module.scss';

const DataVisibilityToggle: React.FC = () => {
  const {data, isLoading} = useAppSelector(state => state.userProfile);
  const {name: currentPage} = useAppSelector(state => state.page);
  const {t} = useTranslation();
  const {showTooltip, hideTooltip} = useTooltip();
  const dispatch = useAppDispatch();

  const isDataVisibilitySwitchEnabled = useMemo(
    () => activeDataVisibilitySwitchPages.includes(currentPage || ''),
    [currentPage]
  );

  const handleShowTooltip = (event: MouseEvent<HTMLSpanElement>) => {
    showTooltip({
      target: event.target as HTMLElement,
      content: isDataVisibilitySwitchEnabled
        ? t('Navigation.DataVisibilityToggle.Enabled.TooltipMessage')
        : t('Navigation.DataVisibilityToggle.Disabled.TooltipMessage'),
      position: Position.Left,
      className: styles.tooltip,
      hasArrow: false,
    });
  };

  const handleChangeToggle = (event: ChangeEvent<HTMLInputElement>) => {
    if (!data) return;
    if (!event.target.checked) {
      dispatch(
        updateUserDataVisibilityAction({
          value: !event.target.checked,
          userId: data.id,
        })
      );
      return;
    }
    dispatch(
      setGlobalInformation({
        type: 'info',
        message: t('Navigation.DataVisibility.ConfirmationModalMessage'),
        icon: 'warning',
        isDismissable: true,
        actions: [
          {
            text: t('Global.SwitchMode'),
            style: ButtonStyle.Secondary,
            action: GlobalInformationActionType.ReduxAction,
            callback: {
              fnKey:
                ModalHandlerReduxActions.UserProfileDataVisibilitySignedOffToggle,
              arguments: {value: false, userId: data.id},
            },
            themeOverride: 'lightThemePrimaryButton',
          },
          {
            text: t('Global.Cancel'),
            style: ButtonStyle.Primary,
            action: GlobalInformationActionType.Dismiss,
            themeOverride: 'lightThemeSecondaryButton',
          },
        ],
      })
    );
  };

  return (
    <div
      className={styles.wrapper}
      onMouseEnter={handleShowTooltip}
      onMouseLeave={hideTooltip}
    >
      <Toggle
        disabled={isLoading || !isDataVisibilitySwitchEnabled}
        label={t('Navigation.DataVisibilityToggle.ToggleLabel')}
        onChange={handleChangeToggle}
        checked={!data?.isSignedOffData}
      />
    </div>
  );
};

export default DataVisibilityToggle;
