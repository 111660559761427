export const navLegendActions = [
  {
    id: 'nav',
    label: 'NAV',
    value: 'NAV',
    color: `rgb(var(--colors-orange-peel))`,
    withCircle: true,
  },
  {
    id: 'jCurve',
    label: 'Global.jCurve',
    value: 'jCurve',
    color: `rgb(var(--colors-lan-violet))`,
    withCircle: true,
  },
];

export const navLegendItems = [
  {
    id: 'contributions',
    label: 'Contributions',
    value: 'contributions',
    color: `rgb(var(--colors-lan-rose))`,
  },
  {
    id: 'distributions',
    label: 'Distributions',
    value: 'distributions',
    color: `rgb(var(--colors-lan-blue))`,
  },
];

export const moicLegendItems = [
  {
    id: 'gross_irr',
    label: 'Gross IRR',
    value: 'gross_irr',
    color: `rgb(var(--colors-orange-peel))`,
  },
  {
    id: 'gross_irr',
    label: 'Net IRR',
    value: 'net_irr',
    color: `rgb(var(--colors-lan-violet))`,
  },
  {
    id: 'moic',
    label: 'MOIC',
    value: 'moic',
    color: `rgb(var(--colors-lan-blue))`,
  },
];

export const piccLegendItems = [
  {
    id: 'picc',
    label: 'PICC',
    value: 'picc',
    color: `rgb(var(--colors-lan-blue))`,
  },
  {
    id: 'tvpi',
    label: 'TVPI',
    value: 'tvpi',
    color: `rgb(var(--colors-orange-peel))`,
    readonly: true,
  },
  {
    id: 'rvpi',
    label: 'RVPI',
    value: 'rvpi',
    color: `rgb(var(--colors-lan-violet))`,
    readonly: true,
  },
  {
    id: 'dpi',
    label: 'DPI',
    value: 'dpi',
    color: `rgb(var(--colors-gray-5))`,
    readonly: true,
  },
];

export const piccTooltipOrder = {
  'NC PICC': 0,
  PICC: 0,
  'NC TVPI': 1,
  TVPI: 1,
  'NC RVPI': 2,
  RVPI: 2,
  'NC DPI': 3,
  DPI: 3,
};
