export const httpRegex = /^(http|https):/;

export const completeUrlRegex = new RegExp(
  [
    '^',
    '(https?:\\/\\/)?',
    '((?:[-a-zA-Z0-9]+\\.)+[a-zA-Z]{2,6})',
    '(\\:[0-9]{1,5})?',
    '(\\/[-a-zA-Z0-9@:%_+.~#?&/=]*)?',
    '$',
  ].join('')
);

export const facebookUrlRegex =
  /(?:(?:http|https):\/\/)?(?:www.)?(mbasic.facebook|m\.facebook|facebook|fb)\.(com|me)\/(?:(?:\w\.)*#!\/)?(?:pages\/)?(?:[\w\-.]*\/)*([\w\-.]*)/;

export const instagramUrlRegex =
  /(?:(?:http|https):\/\/)?(?:www.)?(?:instagram.com|instagr.am|instagr.com)\/(\w+)/;

export const twitterUrlRegex =
  /(?:https?:)?\/\/(?:www\.|m\.)?twitter\.com\/(\w{2,15})\/?(?:\?\S+)?(?:#\S+)?$/;

export const youtubeUrlRegex =
  /^(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.+/;

export const linkedInUrlRegex =
  /^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)\/([-a-zA-Z0-9]+)\/*/;

export const fileNameRegex = /^[^"\\/:|<>*?]*$/i; // This regex will pass everything except these characters: " \ / : | < > * ?
export const dotOnStartOrEndRegex = /^(?!.*\.$)[^.].*[^.]?$/i; // This regex won't pass if there is a dot (.) at the start or end of a string
export const valuationNumberRegex = /^(\d+|\d{1,3}(,\d{3})*)(\.\d*)?$/;
