import axios, {AxiosError} from 'axios';
import {
  ButtonStyle,
  FormResponseError,
  GlobalInformationActionType,
  GlobalInformationProps,
} from '../types';
import i18n from '../i18n';

const getUserFriendlyErrorMessage = (
  err: AxiosError<FormResponseError>
): string => {
  if (err.response?.data?.detail === 'email: Email already exists!') {
    return i18n.t('Global.ErrorMessage.EmailExists');
  }
  return err.response?.data?.detail || i18n.t('Global.ErrorMessage.Unknown');
};

export const usersResponseErrorResolver = (
  err: any
): GlobalInformationProps | null => {
  if (axios.isAxiosError(err) && err.response) {
    const error = err as AxiosError<FormResponseError>;
    if (error.response?.status === 422) {
      return {
        type: 'info',
        message: getUserFriendlyErrorMessage(error),
        icon: 'info',
        isDismissable: true,
        actions: [
          {
            text: i18n.t('Global.CloseWindow'),
            style: ButtonStyle.Primary,
            action: GlobalInformationActionType.Dismiss,
            themeOverride: 'lightThemeSecondaryButton',
            customClassName: 'singleButton',
          },
        ],
      };
    }
    return {
      type: 'error',
      message: error.response?.data?.detail || 'Unknown api error',
      icon: 'warning',
      isDismissable: true,
      actions: [
        {
          text: i18n.t('Global.CloseWindow'),
          style: ButtonStyle.Primary,
          action: GlobalInformationActionType.Dismiss,
        },
      ],
    };
  }

  return {
    type: 'error',
    message: i18n.t('Global.ErrorMessage.Unknown'),
    icon: 'warning',
    isDismissable: true,
    actions: [
      {
        text: i18n.t('Global.CloseWindow'),
        style: ButtonStyle.Primary,
        action: GlobalInformationActionType.Dismiss,
      },
    ],
  };
};
