import {all, call, put, takeEvery} from 'redux-saga/effects';
import {acquireAuthResult, msalInstance} from '../..';
import {PayloadAction} from '@reduxjs/toolkit';
import {errorHandlerAction} from '../../actions';
import {
  clearScenarioDetailsTransactionsCapitalData,
  deleteTransactionInCapitalTransactionAction,
  deleteTransactionInFeeAndOtherTransactionAction,
  editScenarioDetailsCapitalTransactionAction,
  editScenarioDetailsOtherAndFeeTransactionAction,
  getScenarioDetailsTransactionsAction,
  importFundCapitalTransactionsAction,
  importFundFeeTransactionsAction,
  importOtherTransactionsAction,
  postScenarioDetailsCapitalTransactionAction,
  postScenarioDetailsFeeTransactionAction,
  postScenarioDetailsOtherTransactionAction,
  saveAndCalculateTransactionAction,
  setLoading,
  setNewCapitalTransaction,
  setNewFeeTransaction,
  setNewOtherTransaction,
  setScenarioTransactionsCapitalData,
  setUpdatedScenarioDetailsTransactionsData,
  updateTransactionsDataOnSaveAction,
} from '../../reducers/scenarioDetailsTransactionsSlice';
import {
  addNewCapitalTransaction,
  addNewFeeOrOtherTransaction,
  deleteOtherTransactionsCapitalTransaction,
  deleteOtherTransactionsFeeAndOtherTransaction,
  getTransactionsCalc,
  importFundEntityTransactions,
  importFundFeeTransactions,
  importOtherTransactions,
  updateOtherTransactionsCapitalTransaction,
  updateOtherTransactionsOtherAndFeeTransaction,
} from '../../api/getScenarioTransactions';
import {
  NearCastingCapitalTransaction,
  NearCastingCapitalTransactionPayload,
  NearCastingFeeOrOtherTransactionPayload,
  NearCastingFeeTransaction,
  NearCastingOtherTransaction,
  NearCastingScenario,
  UploadSingleTransactionsFile,
} from '../../types';
import i18n from '../../i18n';
import {getScenarioData} from '../../api/getScenarioMainInfo';
import {
  callToastError,
  callToastSuccess,
  DEFAULT_ERROR_TOAST_OPTIONS,
  DEFAULT_SUCCESSFUL_TOAST_OPTIONS,
  scenarioImportErrorResolver,
} from '../../utils';

export const scenarioDetailsTransactionsSaga =
  function* scenarioDetailsTransactionsSaga({
    payload,
  }: PayloadAction<{
    match: any;
    id: string;
  }>) {
    const id: string = payload?.match?.params?.id;
    if (id) {
      yield put(setLoading());
      yield put(clearScenarioDetailsTransactionsCapitalData());
      try {
        const {accessToken} = yield call(acquireAuthResult, msalInstance);
        const response: NearCastingScenario = yield call(
          getScenarioData,
          accessToken,
          id
        );
        yield put(setScenarioTransactionsCapitalData(response));
        yield put(setLoading());
      } catch (err) {
        console.warn(err);
        yield put(setLoading());
        yield put(errorHandlerAction({error: err}));
      }
    }
  };

export const updateTransactionsDataSaga = function* updateTransactionsDataSaga({
  payload,
}: PayloadAction<{
  match: any;
  id: string;
}>) {
  const {id} = payload;
  if (id) {
    yield put(setLoading());
    try {
      const {accessToken} = yield call(acquireAuthResult, msalInstance);
      const response: NearCastingScenario = yield call(
        // @ts-ignore
        getScenarioData,
        accessToken,
        id
      );
      yield put(setUpdatedScenarioDetailsTransactionsData(response));
      yield put(setLoading());
    } catch (err) {
      console.warn(err);
      yield put(setLoading());
      yield put(errorHandlerAction({error: err}));
    }
  }
};

export const scenarioDetailsNewCapitalTransaction =
  function* scenarioDetailsNewCapitalTransaction({
    payload,
  }: PayloadAction<NearCastingCapitalTransactionPayload>) {
    const id: string = payload?.scenario;
    if (id) {
      // yield put(setLoading());
      try {
        const {accessToken} = yield call(acquireAuthResult, msalInstance);
        const response: NearCastingCapitalTransaction = yield call(
          addNewCapitalTransaction,
          accessToken,
          payload
        );
        yield put(setNewCapitalTransaction(response));
        // yield put(setLoading());
      } catch (err) {
        console.warn(err);
        // yield put(setLoading());
        yield put(errorHandlerAction({error: err}));
      }
    }
  };

export const scenarioDetailsNewFeeTransaction =
  function* scenarioDetailsNewFeeTransaction({
    payload,
  }: PayloadAction<NearCastingFeeOrOtherTransactionPayload>) {
    const id: string = payload?.scenario;
    if (id) {
      // yield put(setLoading());
      try {
        const {accessToken} = yield call(acquireAuthResult, msalInstance);
        const response: NearCastingFeeTransaction = yield call(
          addNewFeeOrOtherTransaction,
          accessToken,
          payload
        );
        yield put(setNewFeeTransaction(response));
        // yield put(setLoading());
      } catch (err) {
        console.warn(err);
        // yield put(setLoading());
        yield put(errorHandlerAction({error: err}));
      }
    }
  };

export const scenarioDetailsNewOtherTransaction =
  function* scenarioDetailsNewOtherTransaction({
    payload,
  }: PayloadAction<NearCastingFeeOrOtherTransactionPayload>) {
    const id: string = payload?.scenario;
    if (id) {
      // yield put(setLoading());
      try {
        const {accessToken} = yield call(acquireAuthResult, msalInstance);
        const response: NearCastingOtherTransaction = yield call(
          addNewFeeOrOtherTransaction,
          accessToken,
          payload
        );
        yield put(setNewOtherTransaction(response));
        // yield put(setLoading());
      } catch (err) {
        console.warn(err);
        // yield put(setLoading());
        yield put(errorHandlerAction({error: err}));
      }
    }
  };

export const scenarioDetailsTransactionsSave =
  function* scenarioDetailsTransactionsSave({
    payload,
  }: PayloadAction<{
    id: string;
  }>) {
    yield put(setLoading());
    if (payload.id) {
      try {
        const {accessToken} = yield call(acquireAuthResult, msalInstance);
        yield call(getTransactionsCalc, accessToken, payload.id);
        yield call(
          callToastSuccess,
          i18n.t('Calculation completed'),
          DEFAULT_SUCCESSFUL_TOAST_OPTIONS
        );
        yield put(setLoading());
        yield put(updateTransactionsDataOnSaveAction(payload.id));
      } catch (err) {
        console.warn(err);
        yield call(
          callToastError,
          i18n.t(
            'An error has occurred during the calculation. Please try again'
          ),
          DEFAULT_ERROR_TOAST_OPTIONS
        );
        yield put(setLoading());
        yield put(errorHandlerAction({error: err}));
      }
    }
  };

export const deleteTransactionInCapitalTransactionSaga =
  function* deleteTransactionInCapitalTransactionSaga({
    payload,
  }: PayloadAction<{
    id: string;
    scenarioId: string;
  }>) {
    try {
      const {accessToken} = yield call(acquireAuthResult, msalInstance);
      yield call(
        deleteOtherTransactionsCapitalTransaction,
        accessToken,
        payload.id
      );
      const response: NearCastingScenario = yield call(
        getScenarioData,
        accessToken,
        payload.scenarioId
      );

      yield put(setScenarioTransactionsCapitalData(response));
    } catch (err) {
      console.warn(err);
      yield put(setLoading());
      yield put(errorHandlerAction({error: err}));
    }
  };

export const deleteTransactionInFeeAndOtherTransactionSaga =
  function* deleteTransactionInFeeAndOtherTransactionSaga({
    payload,
  }: PayloadAction<{
    id: string;
    scenarioId: string;
  }>) {
    try {
      const {accessToken} = yield call(acquireAuthResult, msalInstance);
      yield call(
        deleteOtherTransactionsFeeAndOtherTransaction,
        accessToken,
        payload.id
      );
      const response: NearCastingScenario = yield call(
        getScenarioData,
        accessToken,
        payload.scenarioId
      );

      yield put(setScenarioTransactionsCapitalData(response));
    } catch (err) {
      console.warn(err);
      yield put(setLoading());
      yield put(errorHandlerAction({error: err}));
    }
  };

export const editTransactionInCapitalTransactionSaga =
  function* editTransactionInCapitalTransactionSaga({
    payload,
  }: PayloadAction<{
    data: NearCastingCapitalTransactionPayload;
    transactionId: string;
    scenarioId: string;
  }>) {
    try {
      const {accessToken} = yield call(acquireAuthResult, msalInstance);
      yield call(
        updateOtherTransactionsCapitalTransaction,
        accessToken,
        payload.data,
        payload.transactionId
      );
      const response: NearCastingScenario = yield call(
        getScenarioData,
        accessToken,
        payload.scenarioId
      );

      yield put(setScenarioTransactionsCapitalData(response));
    } catch (err) {
      console.warn(err);
      yield put(setLoading());
      yield put(errorHandlerAction({error: err}));
    }
  };

export const editTransactionInOtherAndFeeTransactionSaga =
  function* editTransactionInOtherAndFeeTransactionSaga({
    payload,
  }: PayloadAction<{
    data: NearCastingFeeOrOtherTransactionPayload;
    transactionId: string;
    scenarioId: string;
  }>) {
    try {
      const {accessToken} = yield call(acquireAuthResult, msalInstance);
      yield call(
        updateOtherTransactionsOtherAndFeeTransaction,
        accessToken,
        payload.data,
        payload.transactionId
      );
      const response: NearCastingScenario = yield call(
        getScenarioData,
        accessToken,
        payload.scenarioId
      );

      yield put(setScenarioTransactionsCapitalData(response));
    } catch (err) {
      console.warn(err);
      yield put(setLoading());
      yield put(errorHandlerAction({error: err}));
    }
  };

export const importFundCapitalTransactionsSaga =
  function* importFundCapitalTransactionsSaga({
    payload,
  }: PayloadAction<{
    scenarioId: string;
    file: UploadSingleTransactionsFile;
  }>) {
    try {
      const {accessToken} = yield call(acquireAuthResult, msalInstance);
      const {scenarioId, file} = payload;
      const response: NearCastingScenario = yield call(
        importFundEntityTransactions,
        accessToken,
        scenarioId,
        file.file
      );

      yield put(setScenarioTransactionsCapitalData(response));
      yield call(
        callToastSuccess,
        i18n.t('Global.TransactionMessage.Success'),
        DEFAULT_SUCCESSFUL_TOAST_OPTIONS
      );
    } catch (err) {
      const {errorMessage} = scenarioImportErrorResolver(err);
      yield call(callToastError, errorMessage, DEFAULT_ERROR_TOAST_OPTIONS);
      console.warn(err);
      yield put(errorHandlerAction({error: err}));
    }
  };

export const importFundFeeTransactionsSaga =
  function* importFundFeeTransactionsSaga({
    payload,
  }: PayloadAction<{
    scenarioId: string;
    file: UploadSingleTransactionsFile;
  }>) {
    try {
      const {accessToken} = yield call(acquireAuthResult, msalInstance);
      const {scenarioId, file} = payload;
      const response: NearCastingScenario = yield call(
        importFundFeeTransactions,
        accessToken,
        scenarioId,
        file.file
      );

      yield put(setScenarioTransactionsCapitalData(response));
      yield call(
        callToastSuccess,
        i18n.t('Global.TransactionMessage.Success'),
        DEFAULT_SUCCESSFUL_TOAST_OPTIONS
      );
    } catch (err) {
      const {errorMessage} = scenarioImportErrorResolver(err);
      yield call(callToastError, errorMessage, DEFAULT_ERROR_TOAST_OPTIONS);
      console.warn(err);
      yield put(errorHandlerAction({error: err}));
    }
  };

export const importOtherTransactionsSaga =
  function* importOtherTransactionsSaga({
    payload,
  }: PayloadAction<{
    scenarioId: string;
    file: UploadSingleTransactionsFile;
  }>) {
    try {
      const {accessToken} = yield call(acquireAuthResult, msalInstance);
      const {scenarioId, file} = payload;
      const response: NearCastingScenario = yield call(
        importOtherTransactions,
        accessToken,
        scenarioId,
        file.file
      );

      yield put(setScenarioTransactionsCapitalData(response));
      yield call(
        callToastSuccess,
        i18n.t('Global.TransactionMessage.Success'),
        DEFAULT_SUCCESSFUL_TOAST_OPTIONS
      );
    } catch (err) {
      const {errorMessage} = scenarioImportErrorResolver(err);
      yield call(callToastError, errorMessage, DEFAULT_ERROR_TOAST_OPTIONS);
      console.warn(err);
      yield put(errorHandlerAction({error: err}));
    }
  };

export const scenarioDetailsTransactionsSagaWatcher =
  function* scenarioDetailsTransactionsSagaWatcher() {
    yield all([
      takeEvery(
        getScenarioDetailsTransactionsAction.type,
        scenarioDetailsTransactionsSaga
      ),
      takeEvery(
        postScenarioDetailsCapitalTransactionAction.type,
        scenarioDetailsNewCapitalTransaction
      ),
      takeEvery(
        postScenarioDetailsFeeTransactionAction.type,
        scenarioDetailsNewFeeTransaction
      ),
      takeEvery(
        postScenarioDetailsOtherTransactionAction.type,
        scenarioDetailsNewOtherTransaction
      ),
      takeEvery(
        saveAndCalculateTransactionAction.type,
        scenarioDetailsTransactionsSave
      ),
      takeEvery(
        updateTransactionsDataOnSaveAction.type,
        updateTransactionsDataSaga
      ),
      takeEvery(
        deleteTransactionInCapitalTransactionAction.type,
        deleteTransactionInCapitalTransactionSaga
      ),
      takeEvery(
        deleteTransactionInFeeAndOtherTransactionAction.type,
        deleteTransactionInFeeAndOtherTransactionSaga
      ),
      takeEvery(
        editScenarioDetailsCapitalTransactionAction.type,
        editTransactionInCapitalTransactionSaga
      ),
      takeEvery(
        editScenarioDetailsOtherAndFeeTransactionAction.type,
        editTransactionInOtherAndFeeTransactionSaga
      ),
      takeEvery(
        importFundCapitalTransactionsAction.type,
        importFundCapitalTransactionsSaga
      ),
      takeEvery(
        importFundFeeTransactionsAction.type,
        importFundFeeTransactionsSaga
      ),
      takeEvery(
        importOtherTransactionsAction.type,
        importOtherTransactionsSaga
      ),
    ]);
  };
