import React from 'react';
import styles from './CustomBarTooltip.module.scss';
import {TooltipProps} from 'recharts/index';
import {Icon} from '../../../../../../global';
import {useTranslation} from 'react-i18next';

type BarTooltipProps = Pick<TooltipProps, 'active' | 'payload'>;

const CustomBarTooltip: React.FC<BarTooltipProps> = ({active, payload}) => {
  const {t} = useTranslation();
  if (active && payload?.length) {
    if (payload[0].dataKey === 'noData') {
      return (
        <div className={styles.tooltipWrapper}>
          <p className={styles.quarter}>{payload[0].payload.quarter}</p>
          <div className={styles.values}>
            <Icon className={styles.icon} name={'warning'} />{' '}
            {t('Global.DataNotAvailable')}
          </div>
        </div>
      );
    }

    return (
      <div className={styles.tooltipWrapper}>
        <p className={styles.quarter}>{payload[0].payload.quarter}</p>
        <div className={styles.values}>
          <div
            className={styles.dot}
            style={{background: `rgb(var(--colors-orange-peel))`}}
          />
          <span>{t('TVPI')}: </span>
          {payload[0].payload.TVPI?.toFixed(2)}
        </div>
        <div className={styles.values}>
          <div
            className={styles.dot}
            style={{background: `rgb(var(--colors-lan-violet))`}}
          />
          <span>{t('RVPI')}: </span>
          {payload[0].payload.RVPI?.toFixed(2)}
        </div>
        <div className={styles.values}>
          <div
            className={styles.dot}
            style={{background: `rgb(var(--colors-gray-5))`}}
          />
          <span>{t('DPI')}: </span>
          {payload[0].payload.DPI?.toFixed(2)}
        </div>
      </div>
    );
  }

  return null;
};

export default CustomBarTooltip;
