import axios from 'axios';
import config from '../config';
import {CompanyMonitoring, MetricConfigItem, SelectOption} from '../types/';

export interface MonitoringMetricsChartConfigPayload {
  id?: string;
  name: string;
  workflowId: string;
  selectedMetrics: MetricConfigItem[];
}

export interface SaveMonitoringMetricsChartConfigResponse {
  id: string; // saved chart id
  savedCharts: SelectOption[];
  selectedMetrics: MetricConfigItem[];
}

export interface GetMonitoringMetricsChartConfigResponse {
  id: string; // saved chart id
  name: string;
  selectedMetrics: MetricConfigItem[];
}

export const getCompanyDetailsMonitoring = async (
  token: string,
  id: string
) => {
  const {data} = await axios.get<CompanyMonitoring>(
    `${config.APP_URI}/portfolio-company-monitoring/${id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );
  return data;
};

// POST and PUT (save and update)
export const saveCompanyDetailsMonitoringMetricsChartConfig = async (
  token: string,
  payload: MonitoringMetricsChartConfigPayload
) => {
  const axiosMethod = payload.id ? axios.put : axios.post;
  const url = payload.id
    ? `${config.APP_URI}/portfolio-company-monitoring-saved-charts/${payload.id}`
    : `${config.APP_URI}/portfolio-company-monitoring-saved-charts`;
  const {data} = await axiosMethod<SaveMonitoringMetricsChartConfigResponse>(
    url,
    {
      name: payload.name,
      workflowId: payload.workflowId,
      selectedMetrics: payload.selectedMetrics,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );
  return data;
};

export const getCompanyDetailsMonitoringMetricsChartConfig = async (
  token: string,
  id: string
) => {
  const {data} = await axios.get<GetMonitoringMetricsChartConfigResponse>(
    `${config.APP_URI}/portfolio-company-monitoring-saved-charts/${id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );
  return data;
};

export const deleteCompanyDetailsMonitoringMetricsChartConfig = async (
  token: string,
  id: string
) => {
  const {data} = await axios.delete(
    `${config.APP_URI}/portfolio-company-monitoring-saved-charts/${id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );
  return data;
};

export const getCompanyDetailsMonitoringHistoricalData = async (
  token: string,
  id: string
) => {
  const {data} = await axios.get(
    `${config.APP_URI}/portfolio-company-monitoring-history/${id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );
  return data;
};
