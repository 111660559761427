import {
  add,
  differenceInCalendarMonths,
  format,
  lastDayOfMonth,
  setDate,
  sub,
} from 'date-fns';
import {DateRange} from '../types';

export const previouslySelectedRange = (
  selectedDateRange: DateRange
): DateRange => {
  return selectedDateRange;
};

export const sinceStartRange = (initialDateRange: DateRange): DateRange => {
  return initialDateRange;
};

export const last7DaysRange = (): DateRange => {
  const from = sub(new Date(), {days: 6});
  const to = new Date();
  return {from, to};
};

export const thisMonthRange = (): DateRange => {
  const from = setDate(new Date(), 1);
  const to = lastDayOfMonth(from);
  return {from, to};
};

export const last30DaysRange = (): DateRange => {
  const from = sub(new Date(), {days: 30});
  const to = new Date();
  return {
    from,
    to,
  };
};

export const lastMonthRange = (): DateRange => {
  const from = setDate(sub(new Date(), {months: 1}), 1);
  const to = lastDayOfMonth(from);
  return {from, to};
};

export const lastQuarterRange = (): DateRange => {
  const from = setDate(sub(new Date(), {months: 3}), 1);
  const to = lastDayOfMonth(add(from, {months: 2}));
  return {from, to};
};
export const yearToDateRange = (): DateRange => {
  const from = sub(new Date(), {months: 12});
  const to = new Date();
  return {from, to};
};
export const last12MonthsRange = (): DateRange => {
  const from = setDate(sub(new Date(), {months: 12}), 1);
  const to = lastDayOfMonth(add(from, {months: 11}));
  return {from, to};
};

export const getNumberOfMonths = (dateRange: DateRange): number => {
  return (
    differenceInCalendarMonths(dateRange.to as Date, dateRange.from as Date) + 1
  );
};

export const formatDateRangeDate = (date: Date) => {
  return format(date as Date, 'dd-MM-yyyy');
};
