import React from 'react';
import styles from './UploadStatus.module.scss';
import {FileUploadStatus} from '../../../types';
import classnames from 'classnames';

interface UploadStatusProps {
  status: FileUploadStatus;
}
const UploadStatus: React.FC<UploadStatusProps> = ({status}) => {
  return (
    <div className={classnames(styles.wrapper, styles[`wrapper__${status}`])} />
  );
};

export default UploadStatus;
