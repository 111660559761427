import React from 'react';
import {ValueFormat} from '../../../../global';
import {ActiveItem} from '../../FundExposureChart';
import styles from './FundExposureChartTooltip.module.scss';
import {useTranslation} from 'react-i18next';

interface FundExposureChartTooltipProps {
  content: ActiveItem | null;
}

const FundExposureChartTooltip: React.FC<FundExposureChartTooltipProps> = ({
  content,
}) => {
  const {t} = useTranslation();
  if (!content) return null;
  return (
    <div className={styles.wrapper}>
      <div
        className={styles.dot}
        style={{
          backgroundColor: content.color,
        }}
      />
      <b>{t(content.name)}:</b>{' '}
      <span>
        <ValueFormat value={content.value} format={'.0f%'} />
      </span>
    </div>
  );
};

export default FundExposureChartTooltip;
