import axios from 'axios';
import config from '../config';
import {FundPortfolio, InvestmentPerformanceData} from '../types/index';

export const getFundDetailsPortfolio = async (token: string, id: string) => {
  const {data} = await axios.get<FundPortfolio>(
    `${config.APP_URI}/fund-portfolios/${id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );
  return data;
};

export const getInvestmentPerformanceData = async (
  token: string,
  id: string,
  page: string,
  isFundCurrency: string,
  investmentGroupId: string = '',
  currency: string = ''
) => {
  const {data} = await axios.get<Array<InvestmentPerformanceData>>(
    `${config.APP_URI}/fund-portfolios/performance/${id}?page=${page}&isFundCurrency=${isFundCurrency}&investmentGroupId=${investmentGroupId}&currency=${currency}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );
  return data;
};
