import React, {useCallback, useMemo, useState} from 'react';
import {Pie, PieChart, ResponsiveContainer, Sector} from 'recharts';
import {valueFormat} from '../../../../../utils/value-format';
import {useChartAnimated} from '../../../../../hooks';
import {Func} from '../../../../../types/index';
import styles from '../../CapitalWidget.module.scss';

interface ChartProps {
  onMouseEnter?: Func<[string], void>;
  onMouseLeave?: Func<[], void>;
  currency?: string;
  data: {
    name: string;
    value: number;
    fill: string;
    isTotal?: boolean;
  }[];
}

const Chart: React.FC<ChartProps> = ({
  data,
  onMouseEnter,
  onMouseLeave,
  currency,
}) => {
  const [activeIndex, setActiveIndex] = useState(-1);
  const [activePie, setActivePie] = useState(-1);

  const {ref, isAnimated} = useChartAnimated();

  const onPieEnter = (pieIndex: number) => (data: any, index: number) => {
    setActiveIndex(index);
    setActivePie(pieIndex);
    if (onMouseEnter) {
      onMouseEnter(data.payload.name);
    }
  };

  const onPieLeave = () => {
    setActiveIndex(-1);
    setActivePie(-1);
    if (onMouseLeave) {
      onMouseLeave();
    }
  };

  const renderActiveShape = useCallback((props: any) => {
    const {
      cx,
      cy,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
    } = props;

    if (!payload.name) {
      return <Sector {...props} />;
    }

    return (
      <g>
        {currency && (
          <text
            fontSize={20}
            x={cx}
            y={cy - 10}
            textAnchor="middle"
            fill={`rgb(var(--colors-lantern-main-color))`}
          >
            {currency}
          </text>
        )}
        <text
          fontSize={14}
          x={cx}
          y={cy - 10}
          dy={currency ? 20 : 8}
          textAnchor="middle"
          fill={`rgb(var(--colors-lantern-main-color))`}
        >
          {valueFormat(payload.value, 'en-GB').value}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius - 3}
          outerRadius={outerRadius + 3}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
      </g>
    );
  }, []);

  const chartData = useMemo(() => {
    const chartData: any[] = [[]];
    let sum = 0;
    data.forEach((item, idx, array) => {
      if (item.isTotal) {
        sum = sum + Number(item.value);
        chartData[0].push({...item, value: Number(item.value)});
      } else {
        const newArr = array.filter(item => !item.isTotal);
        const newData = newArr.map(item2 =>
          item2.name === item.name
            ? {...item, value: Number(item.value)}
            : {
                name: '',
                value: Number(item2.value),
                fill: `rgb(var(--colors-gray-7))`,
                stroke: 'none',
              }
        );
        chartData.push(newData);
      }
    });
    return chartData.reverse();
  }, [data]);

  return (
    <div className={styles.chartInner} ref={ref}>
      <ResponsiveContainer width="100%" height="100%">
        <PieChart>
          {chartData.map((data, index) => (
            <Pie
              isAnimationActive={isAnimated}
              hide={!isAnimated}
              startAngle={90}
              endAngle={-270}
              key={index}
              data={data}
              dataKey="value"
              outerRadius={`${100 - index * 7}%`}
              innerRadius={`${97 - index * 7}%`}
              activeIndex={activePie === index ? activeIndex : -1}
              activeShape={renderActiveShape}
              onMouseEnter={onPieEnter(index)}
              onMouseLeave={onPieLeave}
            />
          ))}
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

export default Chart;
