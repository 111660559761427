import {TableSettings} from '../../../../../types';

export const performanceTotalValueChartSettings = (
  zipFileName: string
): TableSettings => {
  return {
    fileName: zipFileName,
    name: 'Total Value',
    columnVisibility: {quarter: false},
    headerMapping: [
      {
        columnId: 'Total cost',
        label: 'Total cost',
      },
      {
        columnId: 'Total realised',
        label: 'Total realised',
      },
      {
        columnId: 'Current valuation',
        label: 'Current valuation',
      },
      {
        columnId: 'Total value',
        label: 'Total value',
      },
      {
        columnId: 'quarter',
        label: 'Quarter',
      },
      {
        columnId: 'date',
        label: 'Date',
      },
    ],
    columnOrder: [
      'Total cost',
      'Total realised',
      'Current valuation',
      'Total value',
      'quarter',
      'date',
    ],
    meta: {
      columns: {
        'Total cost': {
          styling: {
            align: 'right',
          },
        },
        'Total realised': {
          styling: {
            align: 'right',
          },
        },
        'Total value': {
          styling: {
            align: 'right',
          },
        },
        'Current valuation': {
          styling: {
            align: 'right',
          },
        },
        quarter: {
          styling: {
            align: 'right',
          },
        },
        date: {
          styling: {
            align: 'right',
          },
          formatter: 'numFmt:dd-mm-yyyy',
        },
      },
    },
  };
};

export const getPerformanceTotalValueImagesExportPayload = (
  zipFileName: string,
  id: string | undefined
) => {
  return {
    id: `${id}-performance-total-value-chart`,
    fileName: zipFileName,
    opts: [
      {
        fileName: `${zipFileName}_TransparentBackground`,
        type: 'image/svg+xml',
        ext: 'svg',
        bgColor: 'transparent',
        elsToFilter: ['performance-chart-export', 'recharts-tooltip-wrapper'],
      },
      {
        fileName: `${zipFileName}_WithBackground`,
        type: 'image/svg+xml',
        ext: 'svg',
        bgColor: '#202020',
        elsToFilter: ['performance-chart-export', 'recharts-tooltip-wrapper'],
      },
      {
        fileName: `${zipFileName}_TransparentBackground`,
        type: 'image/png',
        ext: 'png',
        bgColor: 'transparent',
        elsToFilter: ['performance-chart-export', 'recharts-tooltip-wrapper'],
      },
      {
        fileName: `${zipFileName}_WithBackground`,
        type: 'image/png',
        ext: 'png',
        bgColor: '#202020',
        elsToFilter: ['performance-chart-export', 'recharts-tooltip-wrapper'],
      },
    ],
  };
};
