import axios from 'axios';
import {ActiveCompanyData} from '../types/index';
import config from '../config';

export interface DashboardCompaniesParams {
  kpiName?: 'total_cost' | 'irr' | 'valuation';
  order?: 'asc' | 'desc';
  maxResults?: number;
  page?: number;
}

export const getClientDashboardCompanies = async (
  token: string,
  params: DashboardCompaniesParams
) => {
  const {kpiName, order, page, maxResults} = params;
  const {data} = await axios.get<{widgets: ActiveCompanyData[]}>(
    `${config.APP_URI}/dashboards/companies`,
    {
      params: {
        kpiName: kpiName || 'total_cost',
        'order[kpiValue]': order || 'asc',
        maxResults: maxResults || 6,
        page: page || 1,
      },

      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );
  return data.widgets;
};
