import React from 'react';
import styles from './BarChartLabel.module.scss';

const BarChartLabel = (props: any) => {
  const {x, y, height} = props;
  const curY = height >= 0 ? y : y - Math.abs(height);
  return (
    <foreignObject
      transform={`translate(${x},${curY}) `}
      width={props.width}
      height={Math.abs(props.height)}
      className={styles.wrapper}
    >
      <div
        style={{
          width: Math.abs(height) - 10,
        }}
        className={styles.labelWrapper}
      >
        {props.value}
      </div>
    </foreignObject>
  );
};

export default BarChartLabel;
