import React, {useMemo} from 'react';
import {
  Func,
  NearCastingFundCapitalTransactionData,
  SelectOption,
  StyleVariant,
} from '../../../types';
import PrevNextButtons from '../../prevn-next-buttons/PrevNextButtons';
import {useFormState} from '../../../hooks';
import {addCapitalFormSchema} from '../../../constants/formSchemas';
import {Input, SelectInput} from '../../global';
import {valueFormat} from '../../../utils';
import styles from './TransactionsCapitalForm.module.scss';
import {DatePickerDropdown} from '../../global';
import {useTranslation} from 'react-i18next';

const getDateLabel = (date: Date) => {
  if (!date) {
    return '';
  }

  return valueFormat(date.toString(), 'dd-MM-yyyy').value.toString();
};

export interface CapitalFormInitialData {
  type: string;
  comment?: string;
  date?: Date;
  value?: number;
}

interface CapitalFormProps {
  minDate: Date;
  maxDate: Date;
  submitText: String;
  transactionsTypeOptions: SelectOption[];
  handleSubmit: Func<[NearCastingFundCapitalTransactionData], void>;
  handleCloseForm: Func<[void], void>;
  initialData: CapitalFormInitialData;
  title: string;
}

const CapitalForm: React.FC<CapitalFormProps> = ({
  minDate,
  maxDate,
  submitText,
  transactionsTypeOptions,
  handleSubmit,
  handleCloseForm,
  initialData,
  title,
}: any) => {
  const {t} = useTranslation();
  const handleKeyDown = (e: any) => {
    const allowedChars =
      formState.type === 'contribution' ? '.0123456789-' : '.0123456789';
    const allowedControlChars = [
      'Backspace',
      'Delete',
      'Tab',
      'Enter',
      'Escape',
      'ArrowLeft',
      'ArrowRight',
    ];

    if (allowedChars.includes(e.key) || allowedControlChars.includes(e.key)) {
      setFormState({
        ...formState,
        valuation: e.target.value,
      });
    } else {
      e.preventDefault();
    }
  };
  const handleDateChange = (date?: Date) => {
    setFormState({
      ...formState,
      date: date?.toISOString() || '',
    });
  };

  const capitalTransactionsInitialState = useMemo(
    () => ({
      type: initialData.type || '',
      comment: initialData.comment || '',
      date: initialData.date || '',
      value: initialData.value?.toString() || '',
    }),
    [initialData]
  );

  const {formState, validate, errors, handleChange, setFormState} =
    useFormState<any>(capitalTransactionsInitialState, addCapitalFormSchema);

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value || '';

    if (formState.type === 'contribution') {
      value = (+value > 0 ? +value * -1 : +value).toString(); // keeping value negative
    }
    setFormState({
      ...formState,
      value,
    });
  };

  const handleTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newType = e.target.value;

    if (newType === formState.type) return;

    setFormState({...formState, type: newType, value: ''});
  };

  const onClose = () => {
    handleCloseForm();
    if (
      !initialData?.type &&
      !initialData?.comment &&
      !initialData?.date &&
      !initialData?.value
    ) {
      setFormState({...capitalTransactionsInitialState});
    }
  };

  const onAdd = (e: any) => {
    e.preventDefault();
    handleSubmit(formState);
    onClose();
  };

  const formHasErrors = errors.value.message || errors.comment.message;
  const isFormDisabled =
    !!formState.value && !!formState.date && !!formState.type && !formHasErrors;

  return (
    <form className={styles.formWrapper}>
      <div className={styles.header}>{title}</div>
      <div className={styles.formInputRowWrapper}>
        <div className={styles.formInputRow}>
          <div className={styles.formInputWrapper}>
            <SelectInput
              name="type"
              type="text"
              placeholder={t('Transactions.SelectInput.Placeholder')}
              label={t('Transactions.SelectInput.Label')}
              variant={StyleVariant.Secondary}
              value={formState.type}
              options={transactionsTypeOptions}
              onChange={handleTypeChange}
              validate={validate}
              autoComplete="off"
              disabled={false}
              required
            />
          </div>
          <div className={styles.formInputWrapper}>
            <div className={styles.formInput}>
              <div className={styles.formInputLabel}>
                <span>{t('Global.Date')}</span>
                <span className={styles.asterisk}>*</span>
              </div>
              <DatePickerDropdown
                className={styles.dateInput}
                label={getDateLabel(formState.date)}
                value={new Date(formState.date)}
                setValue={handleDateChange}
                availableRange={{from: minDate, to: maxDate}}
              />
            </div>
          </div>
          <div className={styles.formInputWrapper}>
            <Input
              type="number"
              name="value"
              placeholder={t('Global.EnterAmount')}
              value={formState.value}
              onChange={handleValueChange}
              onKeyDown={handleKeyDown}
              className={styles.formInput}
              label={t('Global.Amount')}
              validate={validate}
              errorMessage={t(errors.value.message || '')}
              required
            />
          </div>
          <div className={styles.formInputWrapper}>
            <Input
              type="text"
              name="comment"
              value={formState.comment}
              onChange={handleChange}
              className={styles.formInput}
              placeholder={t('Global.AddComment')}
              label={t('Global.Comment')}
              validate={validate}
              errorMessage={t(errors.comment.message || '')}
            />
          </div>
        </div>
      </div>
      <div className={styles.buttonsWrapper}>
        <PrevNextButtons
          isSubmitDisabled={!isFormDisabled}
          handlePrimary={onAdd}
          handleSecondary={onClose}
          primaryText={submitText}
          secondaryText={t('Global.Cancel')}
        />
      </div>
    </form>
  );
};

export default CapitalForm;
