export enum FlexHAlign {
  start = 'start',
  center = 'center',
  end = 'end',
}

export enum StyleVariant {
  Primary = 'primary',
  Secondary = 'secondary',
}

export enum CompanyStatus {
  Active = 'active',
  Exited = 'exited',
  WrittenOff = 'writtenOff',
}

export enum FundPerformanceKpiGroups {
  Nav = 'nav',
  Moic = 'netIrrGrossIrrMOIC',
  Picc = 'piccDpiRvpiTvpi',
}

export enum DropdownWrapperAlignmentType {
  Right = 'right',
  Left = 'left',
  Centered = 'centered',
}

export enum TicksType {
  Year,
  Quarter,
}

export enum StructureChartOrientation {
  TOP_TO_BOTTOM = 'top-to-bottom',
  LEFT_TO_RIGHT = 'left-to-right',
}

export enum LanternButtonStyles {
  Primary = 'primary',
  PrimaryDark = 'primaryDark',
  Secondary = 'secondary',
  Tertiary = 'tertiary',
  TertiaryLight = 'tertiaryLight',
}

export enum TableScrollButtonsPosition {
  Primary = 'primary',
  Secondary = 'secondary',
}

export enum InsightWidgetTooltipPosition {
  Right = 'right',
  Left = 'left',
  Bottom = 'bottom',
}

export enum DateRangePresetTypes {
  sinceStart = 'Since start',
  last7Days = 'Last 7 days',
  thisMonth = 'This month',
  last30Days = 'Last 30 days',
  lastMonth = 'Last month',
  lastQuarter = 'Last quarter',
  yearToDate = 'Year to date',
  last12Months = 'Last 12 months',
  custom = 'Custom',
}

export enum FeeAnalysisTabs {
  FeeTypes = 'fee-types',
  SupplierFees = 'supplier-fees',
}

export enum FeeAnalysisViewTypeTabs {
  ChartFeesView = 'chart-view',
  TableFeesView = 'table-view',
}

export enum MaskedDateInputMask {
  dayMonthYearDashBetween = 'dd-`MM-`yyyy',
  dayMonthYearSlashBetween = 'dd/`MM/`yyyy',
  dayMonthYearDotBetween = 'dd.`MM.`yyyy',
  monthDayYearDashBetween = '`MM-`dd-yyyy',
  monthDayYearSlashBetween = '`MM/`dd/yyyy',
  monthDayYearDotBetween = '`MM.`dd.yyyy',
}

export enum MaskedDateInputPattern {
  dayMonthYearDashBetween = 'dd-MM-yyyy',
  dayMonthYearSlashBetween = 'dd/MM/yyyy',
  dayMonthYearDotBetween = 'dd.MM.yyyy',
}

export enum FundState {
  Active = 'active',
  Inactive = 'inactive',
}

export enum FundScenarioState {
  Draft = 'draft',
  Published = 'published',
  Stale = 'stale',
  Archived = 'archived',
}

export enum UserRole {
  FundManager = 'ROLE_FUND_MANAGER', // 'fundManager'
  ClientAdmin = 'ROLE_MANAGER', // 'clientAdmin'
  CompanyAdmin = 'ROLE_COMPANY_ADMIN', // company admin (reporting)
}

export enum UserStatus {
  Active = 'active',
  Pending = 'pending',
  Removed = 'removed',
  Locked = 'locked',
}
// @TODO Move all general enums to this file

export enum AdminUserPageTabs {
  Users = 'users',
}

export enum AdminDataCollectionPageTabs {
  TemplateConfiguration = 'template-configuration',
  WorkflowConfiguration = 'workflow-configuration',
  PortfolioCompanyUsers = 'portfolio-company-users',
}

export enum ActivityStatuses {
  Pending = 'pending',
  Draft = 'draft',
  Overdue = 'overdue',
  Submitted = 'submitted',
}

export enum ButtonStyle {
  Primary = 'primary',
  Secondary = 'secondary',
  Confirmative = 'confirmative',
  Warning = 'warning',
}

export enum ButtonSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

export enum Size {
  ExtraSmall = 'extraSmall',
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
  ExtraLarge = 'extraLarge',
}

export enum WorkflowStatus {
  Active = 'active',
  Pending = 'pending',
  EditToStart = 'editToStart',
}

export enum ActivityStatusType {
  Default = 'default',
  Workflow = 'workflow',
}

export enum UserWorkflowStatus {
  Active = 'active',
  EditToStart = 'editToStart',
}

export enum FormStatus {
  Ready = 'ready',
  Pending = 'pending',
  Success = 'success',
}

export enum MetricType {
  SystemBased = 'system_based',
  Custom = 'custom',
}

export enum CheckboxState {
  Empty = 'empty',
  Checked = 'checked',
  Indeterminate = 'indeterminate',
}

export enum TemplateReportingType {
  Actuals = 'actuals',
  Budget = 'budget',
  ESG = 'eSG', // BE made a typo
}

export enum PaginationType {
  Dots = 'dots',
  Numbers = 'numbers',
}

export enum GlobalInformationActionType {
  Logout = 'logout',
  Dismiss = 'dismiss',
  Redirect = 'redirect',
  ReduxAction = 'reduxAction',
}

export enum MetricsChartType {
  AreaChart = 'AreaChart',
  LineChart = 'LineChart',
}

export enum MonitoringChartDateRange {
  Custom = 'Custom',
  SixMonths = 'SixMonths',
  OneYear = 'OneYear',
  ThreeYears = 'ThreeYears',
  FiveYears = 'FiveYears',
  TenYears = 'TenYears',
}

export enum MetricUnitType {
  SingleSelect = 'SingleSelect',
  MultiSelect = 'MultiSelect',
  Text = 'Text',
  Currency = 'Currency',
  Percentage = 'Percentage',
  Number = 'Number',
}

export enum TemplateConfigStructureType {
  Questionnaire = 'questionnaire',
  Table = 'table',
}

export enum QuestionnaireInputTypes {
  SingleSelectInput = 'SingleSelectInput',
  MultiSelectInput = 'MultiSelectInput',
  TextInput = 'TextInput',
  PercentageInput = 'PercentageInput',
  NumberInput = 'NumberInput',
  CurrencyInput = 'CurrencyInput',
}

export enum DocumentsPageTabs {
  DocumentExplorer = 'document-explorer',
  TransactionPivot = 'transaction-pivot',
}

export enum DocumentExplorerSearchType {
  Tags = 'tags',
  AskAI = 'ask-ai',
}

export enum DocumentExplorerDateFilterType {
  All = 'all',
  Today = 'today',
  Last7Days = 'last7Days',
  Last30Days = 'last30Days',
  ThisYear = 'thisYear',
  LastYear = 'lastYear',
}

export enum FileUploadStatus {
  Ready = 'ready',
  Pending = 'pending',
  Success = 'success',
  Failure = 'failure',
}

export enum InfoHeaderTypeNames {
  FundInfo = 'fund-info',
  DonutChart = 'DonutChart',
  Icon = 'icon',
  Pill = 'pill',
  Link = 'link',
  Expandable = 'expandable',
  ItemsList = 'itemsList',
  FundBars = 'fund-bars',
  String = 'string',
  FinancialValue = 'financial-value',
}

export enum FileAlreadyExist {
  ReplaceFile = 'replace',
  KeepBoth = 'keep',
}

export enum FileUploadContext {
  UploadDocuments = 'uploadDocuments',
  DocumentExplorer = 'documentExplorer',
}

export enum WorkflowType {
  ExternalCollection = 'external_collection',
  InternalCollection = 'internal_collection',
}

export enum Subscription {
  FundMonitoring = 'Fund Monitoring',
  PortfolioMonitoring = 'Portfolio Monitoring',
}

export enum PathName {
  Home = 'HOME',
  Funds = 'FUNDS',
  FundOverview = 'FUND_OVERVIEW',
  FundOperational = 'FUND_OPERATIONAL',
  FundStructure = 'FUND_STRUCTURE',
  FundPortfolio = 'FUND_PORTFOLIO',
  FundPortfolioTransactions = 'FUND_PORTFOLIO_TRANSACTIONS',
  PortfolioCompanies = 'PORTFOLIO_COMPANIES',
  PortfolioCompanyDetails = 'PORTFOLIO_COMPANY_DETAILS',
  PortfolioCompanyCompany = 'PORTFOLIO_COMPANY_COMPANY',
  PortfolioCompanyInvestmentOverview = 'PORTFOLIO_COMPANY_INVESTMENT_OVERVIEW',
  PortfolioCompanyMonitoring = 'PORTFOLIO_COMPANY_MONITORING',
  DataCollectionDetails = 'DATA_COLLECTION_DETAILS',
  Reporting = 'REPORTING',
}

export enum SectionTag {
  Dashboard = 'Dashboard',
  UserAdministration = 'User Administration',
  TemplateConfiguration = 'Template Configuration',
  WorkflowConfiguration = 'Workflow Configuration',
  PortfolioCompanyUsers = 'Portfolio Company Users',
  Funds = 'Funds',
  FundOverview = 'Fund Overview',
  FundOperational = 'Fund Operational',
  FundStructure = 'Fund Structure',
  FundPortfolioSummary = 'Fund Portfolio Summary',
  FundPortfolioTransactions = 'Fund Portfolio Transactions',
  PortfolioCompanies = 'Portfolio Companies',
  PortfolioCompanyInvestmentOverview = 'Portfolio Company Investment Overview',
  PortfolioCompany = 'Portfolio Company',
  PortfolioCompanyMonitoring = 'Portfolio Company Monitoring',
  DataCollectionActuals = 'Data Collection - Actuals',
  DataCollectionForecast = 'Data Collection - Forecast', // Pending
  DataCollectionESG = 'Data Collection - ESG',
  DataCollectionDetails = 'Data Collection Details',
  DataCollectionReporting = 'Data Collection Reporting',
  DataCollectionInternal = 'Data Collection (Internal)', // Pending
  DocumentExplorer = 'Document Explorer',
  ReportingPeriod = 'Reporting Period',
}

export enum SearchDirection {
  Asc = 'asc',
  Desc = 'desc',
}

export enum FileEditSidePanelDialogOption {
  SelectTransactions = 'SelectTransactions',
  SelectReportingPeriods = 'SelectReportingPeriods',
}

export enum FileActionTypes {
  Edit = 'Edit',
  Download = 'Download',
  Delete = 'Delete',
}

export enum ModalHandlerReduxActions {
  UserProfileDataVisibilitySignedOffToggle = 'userProfileDataVisibilitySignedOffToggle',
  PortfolioCompaniesSaveFilterConfig = 'portfolioCompaniesSaveFilterConfig',
}

export enum FilterSectionComponentType {
  MultiSelectDropdown = 'MultiSelectDropdown',
  SelectDropdown = 'SelectDropdown',
  MultiRangeSlider = 'MultiRangeSlider',
  AsAt = 'AsAt',
  Button = 'Button',
}

export enum DataVisualisaitonChartType {
  BarChart = 'BarChart',
  TreemapChart = 'TreemapChart',
  BubbleChart = 'BubbleChart',
  SavedBarChart = 'SavedBarChart',
  SavedTreemapChart = 'SavedTreemapChart',
  SavedBubbleChart = 'SavedBubbleChart',
}

export enum DataVisualisationDataSource {
  FundInvestment = 'fund-investment',
  PortfolioMonitoring = 'portfolio-monitoring',
}
