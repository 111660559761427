import {DateRangePresetTypes} from '../types';
import {
  last12MonthsRange,
  last30DaysRange,
  last7DaysRange,
  lastMonthRange,
  lastQuarterRange,
  previouslySelectedRange,
  sinceStartRange,
  thisMonthRange,
  yearToDateRange,
} from '../utils/date-range-presets';
import {DateRangePreset} from '../ui-components/global/date-range-picker/component/date-range-presets/DateRangePresets';
import {SortingState} from '@tanstack/react-table';

export const entitiesDefaultOption = {
  id: 'all',
  label: 'Funds.FeeAnalysisDashboard.SectionHeader.DropdownOption',
  value: 'all',
};

export const FeeAnalysisHeaderTitlePlaceholder = 'Segment fees';
export const FeeAnalysisHeaderSubtitlePlaceholderFeeTypes =
  'FeeAnalysis.Header.Subtitle.Placeholder.FeeTypes';

export const FeeAnalysisHeaderSubtitlePlaceholderSupplierFee =
  'FeeAnalysis.Header.Subtitle.Placeholder.SupplierFee';

export const feeAnalysisDateRangePickerPresets: DateRangePreset[] = [
  {label: DateRangePresetTypes.sinceStart, handler: sinceStartRange},
  {label: DateRangePresetTypes.last7Days, handler: last7DaysRange},
  {label: DateRangePresetTypes.thisMonth, handler: thisMonthRange},
  {label: DateRangePresetTypes.last30Days, handler: last30DaysRange},
  {label: DateRangePresetTypes.lastMonth, handler: lastMonthRange},
  {
    label: DateRangePresetTypes.lastQuarter,
    handler: lastQuarterRange,
  },
  {label: DateRangePresetTypes.yearToDate, handler: yearToDateRange},
  {
    label: DateRangePresetTypes.last12Months,
    handler: last12MonthsRange,
  },
  {
    label: DateRangePresetTypes.custom,
    handler: previouslySelectedRange,
  },
];

export const feeAnalysisTableDefaultSorting: SortingState = [
  {
    desc: false,
    id: 'category',
  },
];
