import {all, call, put, takeEvery} from 'redux-saga/effects';
import {acquireAuthResult, msalInstance} from '../..';
import {PayloadAction} from '@reduxjs/toolkit';
import {errorHandlerAction} from '../../actions';
import {
  getScenarioOnePageReportPortfolioDataAction,
  setLoading,
  setScenarioOnePageReportPortfolioData,
  getScenarioOnePageReportCompanyPerformanceDataAction,
  setLoadingCompanyPerformance,
  setScenarioOnePageReportCompanyPerformanceData,
  clearScenarioOnePageReportCompanyPerformanceData,
} from '../../reducers/scenarioOnePageReportPortfolioSlice';
import {
  ScenarioPortfolioResponse,
  getScenarioPortfolioData,
  getPortfolioCompanyPerformanceData,
  ScenarioPortfolioCompanyPerformanceResponse,
} from '../../api/getScenarioPortfolio';
import {
  setScenarioOnePageReportCapitalData,
  setScenarioOnePageReportPerformanceReportedData,
} from '../../reducers/scenarioOnePageReportOverviewSlice';
import {setScenarioOnePageReportPortfolioPerformanceReportedData} from '../../reducers/scenarioOnePageReportPortfolioPerformanceSlice';
import {ScenarioReportPortfolioSummaryData} from '../../types';

export const scenarioOnePageReportPortfolioSaga =
  function* scenarioOnePageReportPortfolioSaga({
    payload,
  }: PayloadAction<{
    match: any;
  }>) {
    const id: string = payload?.match?.params?.id;
    if (id) {
      yield put(setLoading());
      try {
        const {accessToken} = yield call(acquireAuthResult, msalInstance);
        const response: ScenarioPortfolioResponse = yield call(
          getScenarioPortfolioData,
          accessToken,
          id
        );
        yield put(
          setScenarioOnePageReportPortfolioData({
            headerData: response.headerData,
            companies: response.companies,
          })
        );
        yield put(
          setScenarioOnePageReportCapitalData({
            nearCastingData: response.capitalData,
            reportedData: response.reportedCapitalData,
          })
        );
        yield put(
          setScenarioOnePageReportPerformanceReportedData({
            reportedData: response.reportedFundPerformanceData?.widgets[0],
          })
        );
        yield put(
          setScenarioOnePageReportPortfolioPerformanceReportedData({
            portfolioSummary: response.reportedPortfolioPerformanceData
              .widgets[0] as ScenarioReportPortfolioSummaryData,
          })
        );
        yield put(setLoading());
      } catch (err) {
        console.warn(err);
        yield put(setLoading());
        yield put(errorHandlerAction({error: err}));
      }
    }
  };

export const scenarioOnePageReportCompanyPerformanceSaga =
  function* scenarioOnePageReportCompanyPerformanceSaga({
    payload,
  }: PayloadAction<{
    id: string;
  }>) {
    const id: string = payload?.id;
    if (id) {
      yield put(setLoadingCompanyPerformance());
      yield put(clearScenarioOnePageReportCompanyPerformanceData());
      try {
        const {accessToken} = yield call(acquireAuthResult, msalInstance);
        const response: ScenarioPortfolioCompanyPerformanceResponse =
          yield call(getPortfolioCompanyPerformanceData, accessToken, id);
        yield put(
          setScenarioOnePageReportCompanyPerformanceData({
            reportedData: response.reportedData,
            nearCastingData: response.nearcastingData,
          })
        );
        yield put(setLoadingCompanyPerformance());
      } catch (err) {
        console.warn(err);
        yield put(setLoadingCompanyPerformance());
        yield put(errorHandlerAction({error: err}));
      }
    }
  };

export const ScenarioOnePageReportPortfolioSagaWatcher =
  function* ScenarioOnePageReportPortfolioSagaWatcher() {
    yield all([
      takeEvery(
        getScenarioOnePageReportPortfolioDataAction.type,
        scenarioOnePageReportPortfolioSaga
      ),
      takeEvery(
        getScenarioOnePageReportCompanyPerformanceDataAction.type,
        scenarioOnePageReportCompanyPerformanceSaga
      ),
    ]);
  };
