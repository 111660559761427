import React from 'react';
import {
  useBreakpoint,
  useChartAnimated,
  useChartStyles,
  useChartHover,
} from '../../../../../hooks';
import {getMissingDataIndex} from './utils/getMissingDataIndex';

import {
  Area,
  AreaChart,
  CartesianGrid,
  Label,
  LabelList,
  ReferenceArea,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import CustomYAxisTick from '../../../../global/custom-y-axis-tick/CustomYAxisTick';
import CustomXAxisTick from '../../../../global/custom-x-axis-tick/CustomXAxisTick';
import CustomAreaTooltip from './components/custom-area-tooltip/CustomAreaTooltip';
import {
  ChartDataCategory,
  KpiTabs,
  SummaryWidgetChartProps,
} from '../../../../../types';
import AreaChartNoData from './components/area-chart-no-data/AreaChartNoData';
import AreaChartMissingData from './components/are-chart-missing-data/AreChartMissingData';
import LiveDataChartShape from '../../../../global/live-data-chart-shape/LiveDataChartShape';
import {valueFormat} from '../../../../../utils';
import {LiveDataChartLabelContent} from '../../../../global';

interface FundAreaChartProps extends SummaryWidgetChartProps {
  nearCasting?: ChartDataCategory;
  activeTab?: KpiTabs;
  isNearCastingEmpty?: boolean;
}

const FundAreaChart: React.FC<FundAreaChartProps> = ({
  timeSeries,
  label,
  currency,
  tooltipFormat,
  yAxisFormat,
  isTrendingUp = true,
  nearCasting,
  activeTab,
  isNearCastingEmpty,
  signOffDate,
}) => {
  const {isXSmall, isSmall} = useBreakpoint();
  const {ref, isAnimated} = useChartAnimated();
  const {hovered, handleMouseMove, handleMouseLeave} = useChartHover();

  const {xAxisFontSize, areaChartNoData, areaChartMisingData, areaChartYAxis} =
    useChartStyles();

  //@ts-ignore
  const nearCastingStartingFrom = timeSeries?.data?.slice(-1)?.quarter;

  const chartDataTimeSeriesArray = isNearCastingEmpty
    ? timeSeries?.data || []
    : [...(timeSeries?.data || []), ...(nearCasting?.timeSeries?.data || [])];

  const getXAxisTicks = () => {
    let ticks = chartDataTimeSeriesArray.map((item: any) => {
      return item.quarter;
    });
    return ticks?.slice(1);
  };

  const onMouseMove = () => {
    if (!isXSmall && !isSmall) {
      handleMouseMove();
    }
  };
  const onMouseLeave = () => {
    if (!isXSmall && !isSmall) {
      handleMouseLeave();
    }
  };

  return (
    <div ref={ref}>
      <ResponsiveContainer height={244} width="100%">
        <AreaChart
          data={chartDataTimeSeriesArray as any}
          onMouseMove={onMouseMove}
          onMouseLeave={onMouseLeave}
          margin={{
            left: -1,
            top: 44,
            bottom: 10,
            right: 50,
          }}
        >
          <defs>
            <linearGradient id="chartGradientUp">
              <stop
                offset="0%"
                stopColor={`rgba(var(--colors-harlequin),0.3`}
              />
              <stop
                offset="100%"
                stopColor={`rgba(var(--colors-japanese-laurel),0.24)`}
              />
            </linearGradient>
            <linearGradient id="chartGradientDown">
              <stop offset="15.3%" stopColor={`rgb(var(--colors-lan-rose)`} />
              <stop
                offset="100%"
                stopColor={`rgba(var(--colors-lan-rose),0.27)`}
              />
            </linearGradient>
            <linearGradient id="xAxisGradient" gradientTransform="rotate(90)">
              <stop
                offset="15.3%"
                stopColor={`rgba(var(--colors-lan-rose),0.27`}
              />
              <stop
                offset="100%"
                stopColor={`rgba(var(--colors-lan-rose),0.27)`}
              />
            </linearGradient>
            <linearGradient
              id="nearCastingGradient"
              gradientTransform="rotate(127)"
            >
              <stop offset="2.91%" stopColor={`rgba(117, 23, 200,0.28)`} />
              <stop offset="100%" stopColor={`rgba(71, 45, 227,0.12)`} />
            </linearGradient>
            <linearGradient
              id="signOffDataGradient"
              gradientTransform="rotate(0)"
            >
              <stop offset="0%" stopColor={`rgba(65, 65, 65, 0.8)`} />
              <stop offset="50%" stopColor={`rgba(65, 65, 65, 0.51)`} />
              <stop offset="100%" stopColor={`rgba(52, 51, 51, 0.2)`} />
            </linearGradient>
          </defs>

          <CartesianGrid
            vertical={false}
            strokeDasharray="2"
            stroke={`rgb(var(--colors-gray-6))`}
            width={1000}
          />
          <XAxis
            dataKey="quarter"
            interval={0}
            tickLine={false}
            axisLine={false}
            ticks={getXAxisTicks()}
            tick={
              <CustomXAxisTick
                yOffset={20}
                showAxis={isSmall || isXSmall || hovered}
                fill="gray-3"
                isXSmall={isXSmall}
                fontSize={xAxisFontSize}
              />
            }
          />
          {!isNearCastingEmpty && (
            <CartesianGrid
              vertical={false}
              strokeDasharray="2"
              stroke={`rgb(var(--colors-gray-6))`}
              width={1000}
            />
          )}
          <Tooltip
            content={
              <CustomAreaTooltip format={tooltipFormat} currency={currency} />
            }
          />
          <Area
            type="stepAfter"
            dataKey="noData"
            isAnimationActive={isAnimated}
            hide={!isAnimated}
            fillOpacity={0}
            strokeOpacity={0}
          >
            <LabelList
              content={
                (<AreaChartNoData labelStyles={areaChartNoData} />) as any
              }
              dataKey="noData"
            />
          </Area>
          <Area
            isAnimationActive={isAnimated}
            hide={!isAnimated}
            label={
              (
                <AreaChartMissingData
                  labelData={getMissingDataIndex(
                    chartDataTimeSeriesArray,
                    label
                  )}
                  labelStyles={areaChartMisingData}
                />
              ) as any
            }
            type="stepAfter"
            dataKey="NAV"
            stroke={
              isTrendingUp
                ? `rgb(var(--colors-harlequin))`
                : `rgb(var(--colors-lan-rose))`
            }
            fill={
              isTrendingUp ? 'url(#chartGradientUp)' : 'url(#chartGradientDown)'
            }
            fillOpacity={0.16}
          />
          <Area
            isAnimationActive={isAnimated}
            hide={!isAnimated}
            type="stepAfter"
            dataKey="Net IRR"
            stroke={
              isTrendingUp
                ? `rgb(var(--colors-harlequin))`
                : `rgb(var(--colors-lan-rose))`
            }
            fill={
              isTrendingUp ? 'url(#chartGradientUp)' : 'url(#chartGradientDown)'
            }
            fillOpacity={0.22}
          />
          {!isNearCastingEmpty && (
            <ReferenceLine
              x={nearCastingStartingFrom}
              strokeWidth={1}
              stroke="#626262"
            />
          )}

          {!isNearCastingEmpty && (
            <ReferenceArea
              x1={nearCastingStartingFrom}
              fill="url(#nearCastingGradient)"
              strokeOpacity={0.5}
              fillOpacity={0.5}
            />
          )}

          {!isNearCastingEmpty && (
            <Area
              connectNulls
              isAnimationActive={isAnimated}
              hide={!isAnimated}
              type="stepAfter"
              dataKey={activeTab === KpiTabs.Nav ? 'NC NAV' : 'NC Net IRR'}
              stroke={
                activeTab === KpiTabs.NetIrr
                  ? `rgb(var(--colors-orange-peel))`
                  : `rgb(var(--colors-lan-rose))`
              }
              fill="#7517C8"
              fillOpacity={0.5}
              strokeDasharray="20 10"
            />
          )}

          <YAxis
            axisLine={false}
            tickLine={false}
            width={1}
            tick={
              <CustomYAxisTick
                showAxis={isSmall || isXSmall || hovered}
                xOffset={areaChartYAxis?.xOffset}
                yOffset={areaChartYAxis?.yOffset}
                format={yAxisFormat}
                fill="gray-3"
                fontSize={areaChartYAxis?.fontSize}
              />
            }
          />

          {signOffDate && (
            <ReferenceArea
              x1={valueFormat(signOffDate, "QQ 'YY").value}
              shape={props => (
                <LiveDataChartShape
                  {...props}
                  topMargin={44}
                  rightMargin={50}
                  bottomMargin={90}
                  fill="url(#signOffDataGradient)"
                />
              )}
            >
              {(isSmall || isXSmall || hovered) && (
                <Label
                  value={'In-flight data'}
                  content={LiveDataChartLabelContent}
                />
              )}
            </ReferenceArea>
          )}
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default FundAreaChart;
