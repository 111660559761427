import {createSelector} from '@reduxjs/toolkit';
import i18n from '../i18n';
import {RootState} from '../store';

const dataCollectionAdmin = (state: RootState) =>
  state.dataCollectionAdministration;

const dataCollectionAdminSelector = createSelector(
  [dataCollectionAdmin],
  dataCollectionAdmin => {
    return {
      dataLoaded:
        !!dataCollectionAdmin.templateConfiguration.data ||
        !!dataCollectionAdmin.templateConfigurationDetails.data ||
        !!dataCollectionAdmin.workflowConfiguration.data ||
        !!dataCollectionAdmin.portfolioCompanyUsers.data,
      label:
        dataCollectionAdmin.templateConfiguration.data?.label ||
        dataCollectionAdmin.templateConfigurationDetails.data?.label ||
        dataCollectionAdmin.workflowConfiguration.data?.label ||
        dataCollectionAdmin.portfolioCompanyUsers.data?.label ||
        i18n.t('DataCollectionAdministration.Label'),
    };
  }
);

export default dataCollectionAdminSelector;
