import {createAction, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {FundListPerformanceResponse} from '../api/getFundPerformanceData';
import {RootState} from '../store';
import {Funds} from '../types/index';

export const getFundPerformanceDataAction = createAction(
  'funds/get-performance-data',
  (fundId: string, fundType: string) => {
    return {
      payload: {
        fundId,
        fundType,
      },
    };
  }
);

interface FundsSlice {
  data: Funds | null;
}

const initialState: FundsSlice = {
  data: null,
};

const fundsSlice = createSlice({
  name: 'funds',
  initialState,
  reducers: {
    setFunds(state: FundsSlice, action: PayloadAction<Funds>) {
      state.data = action.payload;
    },
    clearFunds(state: FundsSlice) {
      state.data = null;
    },
    setFundPerformanceData(
      state: FundsSlice,
      action: PayloadAction<{
        performanceResponse: FundListPerformanceResponse;
        fundId: string;
        fundType: 'activeFunds' | 'inactiveFunds';
      }>
    ) {
      const {
        performanceResponse,
        fundId,
        fundType = 'activeFunds',
      } = action.payload;

      if (state.data) {
        const updatedFundsData = state.data[fundType].data.map(item =>
          item.id === fundId
            ? {
                ...item,
                fundPerformanceData: {
                  ...performanceResponse.data,
                  headerData: performanceResponse.headerData,
                },
              }
            : item
        );
        state.data[fundType].data = updatedFundsData;
      }
    },
  },
});

export const {setFunds, clearFunds, setFundPerformanceData} =
  fundsSlice.actions;

export default fundsSlice.reducer;

export const selectFunds = (state: RootState) => state.funds.data;
