import axios from 'axios';
import config from '../config';
import {ComponentWidgetType, FundStructure} from '../types';

export const getFundDetailsStructure = async (token: string, id: string) => {
  const {data} = await axios.get<FundStructure>(
    `${config.APP_URI}/fund-structures/${id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );
  return data;
};

export const getFundEntityCapitalData = async (
  token: string,
  fundId: string,
  date: string
) => {
  const {data} = await axios.get<ComponentWidgetType[]>(
    `${config.APP_URI}/fund-structure/capital/${fundId}?date=${date}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );
  return data;
};

export const getFundInvestorsData = async (
  token: string,
  fundId: string,
  sortValue: string,
  sortDirection: string
) => {
  const {data} = await axios.get<ComponentWidgetType[]>(
    `${config.APP_URI}/fund-structure/investors/${fundId}?sorting=${sortValue}&order=${sortDirection}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );
  return data;
};
