import React from 'react';
import {Icon} from '../';
import styles from './NoPreviewAvailable.module.scss';
import classnames from 'classnames';
import {t} from 'i18next';

interface NoPreviewAvailableProps {
  icon?: string;
  label?: string;
  className?: string;
}

const NoPreviewAvailable: React.FC<NoPreviewAvailableProps> = ({
  icon = 'no-preview',
  label = t('Documents.FileEditForm.NoPreviewAvailable.Label'),
  className,
}) => {
  return (
    <div className={classnames(styles.wrapper, className)}>
      <Icon className={styles.icon} name={icon} />
      <p className={styles.label}>{label}</p>
    </div>
  );
};

export default NoPreviewAvailable;
