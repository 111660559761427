import React, {useCallback, useMemo, useRef, useState} from 'react';
import classnames from 'classnames';
import {StyleVariant, TimelineChartProps} from '../../../types/index';
import YearLine, {EventData} from './components/year-line/YearLine';
import {Icon} from '../index';
import {LabelPosition} from './components/event-point/EventPoint';
import {valueFormat} from '../../../utils/value-format';
import styles from './TimeLineChart.module.scss';
import {formatWithLocale} from '../../../utils';

export interface TimelineChartComponentProps extends TimelineChartProps {
  isHoverStateDisabled?: boolean;
  variant?: StyleVariant;
}

const TimeLineChart: React.FC<TimelineChartComponentProps> = ({
  data,
  scaledYears,
  isHoverStateDisabled,
  variant = StyleVariant.Primary,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [startEventPosition, setStartEventPosition] = useState<number>(0);
  const [todayPointPosition, setTodayPointPosition] = useState<number>(0);
  const [hoveredEventState, setHoveredEventState] = useState({
    fromToday: false,
    position: 0,
    date: '',
    range: '',
  });

  const wrapperRef = useRef<HTMLDivElement>(null);

  const {end, yearsArray, events, today} = useMemo(() => {
    const start = new Date(data[0].time);
    const end = new Date(data[data.length - 1].time);
    const years = end.getFullYear() - start.getFullYear();
    const today = new Date();

    let yearsArray = [];
    for (let i = 0; i < years; i++) {
      yearsArray.push({
        label: `${start.getFullYear() + i}`,
        time: +(start.getFullYear() + i),
      });
    }

    let events: EventData[] = data
      .filter((_, i) => i > 0 && i < data.length - 1)
      .map((item, idx) => {
        if (variant === StyleVariant.Secondary) {
          if ((idx + 1) % 5 === 0) {
            return {
              ...item,
              labelPosition: LabelPosition.five,
            };
          }
          if ((idx + 1) % 4 === 0) {
            return {
              ...item,
              labelPosition: LabelPosition.four,
            };
          }
        }
        if ((idx + 1) % 3 === 0) {
          return {
            ...item,
            labelPosition: LabelPosition.three,
          };
        }
        if ((idx + 1) % 2 === 0) {
          return {
            ...item,
            labelPosition: LabelPosition.two,
          };
        }
        return {
          ...item,
          labelPosition: LabelPosition.one,
        };
      })
      .sort((a, b) => new Date(a.time).getTime() - new Date(b.time).getTime());

    return {
      start,
      end,
      years,
      yearsArray,
      events,
      today,
    };
  }, [data]);

  const handleHoverEvent = (
    date: string | number,
    target: HTMLElement | null
  ) => {
    if (isHoverStateDisabled) return;
    if (wrapperRef.current) {
      if (target) {
        const firstDate = new Date(events[0].time).getTime();
        const eventDate = new Date(date).getTime();
        const todayDate = today.getTime();
        let diff: number;
        if (eventDate > todayDate) {
          diff = (eventDate - todayDate) / 31556952000;
        } else {
          diff = (eventDate - firstDate) / 31556952000;
        }
        const range = `${valueFormat(diff, '%d years, %d months').value}`;
        setHoveredEventState({
          fromToday: eventDate > todayDate,
          position:
            wrapperRef.current.getBoundingClientRect().right -
            target.getBoundingClientRect().right,
          date: formatWithLocale(new Date(date), 'dd-MM-yyyy'),
          range,
        });
      } else {
        setHoveredEventState({
          position: 0,
          date: '',
          range: '',
          fromToday: false,
        });
      }
    }
  };

  const handleSetFirstEventRef = useCallback(
    (target: HTMLDivElement) => {
      if (wrapperRef.current) {
        setStartEventPosition(
          target.getBoundingClientRect().left -
            wrapperRef.current.getBoundingClientRect().left
        );
      }
    },
    [wrapperRef]
  );

  const handleSetTodayPoint = useCallback(
    (target: HTMLDivElement) => {
      if (wrapperRef.current) {
        setTodayPointPosition(
          target.getBoundingClientRect().left -
            wrapperRef.current.getBoundingClientRect().left +
            3
        );
      }
    },
    [wrapperRef]
  );

  return (
    <div
      className={classnames(
        styles.wrapper,
        isExpanded && styles.expanded,
        styles[variant]
      )}
      data-test="time-line-chart-wrapper"
    >
      <button
        onClick={() => setIsExpanded(prev => !prev)}
        className={classnames(
          styles.toggleButton,
          isExpanded && styles.expanded
        )}
      >
        <Icon name={'chevron-down'} />
      </button>
      <div className={styles.line}>
        <div className={styles.lineInner} ref={wrapperRef}>
          <div className={styles.endPoint}>
            <span>{end.getFullYear()}</span>
          </div>
          {yearsArray.map(item => (
            <YearLine
              onHoverEvent={handleHoverEvent}
              setFirstEventRef={handleSetFirstEventRef}
              setTodayPointRef={handleSetTodayPoint}
              today={today}
              isExpanded={isExpanded}
              key={item.time}
              {...item}
              events={events}
              scaled={scaledYears?.includes(item.time) || false}
              variant={variant}
            />
          ))}
          <div
            className={styles.eventGraph}
            style={{
              left: hoveredEventState.fromToday
                ? todayPointPosition
                : startEventPosition,
              right: hoveredEventState.position || '',
              opacity: hoveredEventState.position ? 1 : 0,
              visibility: hoveredEventState.position ? 'visible' : 'hidden',
            }}
          >
            <div>{hoveredEventState.date}</div>
            <div>{hoveredEventState.range}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TimeLineChart;
