import React from 'react';
import {ResponsiveContainer, Sankey, Tooltip} from 'recharts';
import {SankeyData} from '../../../types';
import {updateSankeyData} from '../../../utils/sankey';
import SankeyLink from './components/SankeyLink';
import SankeyNode from './components/SankeyNode';
import SankeyTooltip from './components/SankeyTooltip/SankeyTooltip';

interface SankeyChartProps extends SankeyData {
  tooltipFormat: string;
}

const SankeyChart: React.FC<SankeyChartProps> = (
  props: SankeyChartProps
): JSX.Element => {
  const updatedSankeyData = updateSankeyData(props);

  return (
    <ResponsiveContainer
      id="sankey-chart"
      width="100%"
      height={props.nodes.length * 60}
    >
      <Sankey
        margin={{right: 600, top: 25, bottom: 25}}
        data={updatedSankeyData}
        nodeWidth={8}
        nodePadding={18}
        linkCurvature={0.51}
        iterations={0}
        link={<SankeyLink />}
        node={
          <SankeyNode
            containerWidth={960}
            color={updatedSankeyData.color}
            total={updatedSankeyData.totalFees}
            currency={updatedSankeyData.currency}
            sankeyLinksData={updatedSankeyData.links}
          />
        }
      >
        <Tooltip
          content={
            <SankeyTooltip
              currency={updatedSankeyData.currency}
              tooltipFormat={props.tooltipFormat}
              sankeyLinksData={updatedSankeyData.links}
            />
          }
        />
      </Sankey>
    </ResponsiveContainer>
  );
};

export default SankeyChart;
