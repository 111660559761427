import React from 'react';
import classnames from 'classnames';
import {ReactComponent as Table} from '../../../assets/icons/table.svg';
import {ReactComponent as Tiles} from '../../../assets/icons/tiles.svg';
import {ReactComponent as Chart} from '../../../assets/icons/pie-chart.svg';
import {ReactComponent as GraphChart} from '../../../assets/icons/chart.svg';
import {ReactComponent as AShares} from '../../../assets/icons/a-shares.svg';
import {ReactComponent as BShares} from '../../../assets/icons/b-shares.svg';
import {ReactComponent as ExportPdf} from '../../../assets/icons/export-pdf.svg';
import {ReactComponent as ExportExcel} from '../../../assets/icons/export-excel.svg';
import {ReactComponent as ExportPpt} from '../../../assets/icons/export-ppt.svg';
import {ReactComponent as ExportCsv} from '../../../assets/icons/export-csv.svg';
import {ReactComponent as Funds} from '../../../assets/icons/funds.svg';
import {ReactComponent as Companies} from '../../../assets/icons/companies.svg';
import {ReactComponent as DataCollection} from '../../../assets/icons/data-collection.svg';
import {ReactComponent as Home} from '../../../assets/icons/home.svg';
import {ReactComponent as Tasks} from '../../../assets/icons/tasks.svg';
import {ReactComponent as Shortcuts} from '../../../assets/icons/shortcuts.svg';
import {ReactComponent as Activity} from '../../../assets/icons/activity.svg';
import {ReactComponent as News} from '../../../assets/icons/news.svg';
import {ReactComponent as DiagramPlaceholder} from '../../../assets/icons/icon-diagram-placeholder.svg';
import {ReactComponent as StatsUp} from '../../../assets/icons/stats-up.svg';
import {ReactComponent as StatsDown} from '../../../assets/icons/stats-down.svg';
import {ReactComponent as Eye} from '../../../assets/icons/eye-icon.svg';
import {ReactComponent as ThreeDots} from '../../../assets/icons/three-dots-vertical.svg';
import {ReactComponent as User} from '../../../assets/icons/user.svg';
import {ReactComponent as ChevronUp} from '../../../assets/icons/chevron-up.svg';
import {ReactComponent as ChevronDown} from '../../../assets/icons/chevron-down.svg';
import {ReactComponent as ChevronLeft} from '../../../assets/icons/chevron-left.svg';
import {ReactComponent as ChevronRight} from '../../../assets/icons/chevron-right.svg';
import {ReactComponent as Notification} from '../../../assets/icons/notification.svg';
import {ReactComponent as Search} from '../../../assets/icons/search.svg';
import {ReactComponent as Cross} from '../../../assets/icons/cross.svg';
import {ReactComponent as Onboarding} from '../../../assets/icons/onboarding.svg';
import {ReactComponent as BrushTraveller} from '../../../assets/icons/brush-traveller.svg';
import {
  ReactComponent as Checkbox,
  ReactComponent as Check,
} from '../../../assets/icons/check.svg';
import {ReactComponent as TableSort} from '../../../assets/icons/table-sort.svg';
import {ReactComponent as Debt} from '../../../assets/icons/debt.svg';
import {ReactComponent as Convertibles} from '../../../assets/icons/convertibles.svg';
import {ReactComponent as Equity} from '../../../assets/icons/equity.svg';
import {ReactComponent as LinkedIn} from '../../../assets/icons/linkedin.svg';
import {ReactComponent as Facebook} from '../../../assets/icons/facebook.svg';
import {ReactComponent as Export} from '../../../assets/icons/export.svg';
import {ReactComponent as Ellipse} from '../../../assets/icons/ellipse.svg';
import {ReactComponent as ZoomIn} from '../../../assets/icons/zoom-in.svg';
import {ReactComponent as ZoomOut} from '../../../assets/icons/zoom-out.svg';
import {ReactComponent as GeneralPartner} from '../../../assets/icons/generalPartner.svg';
import {ReactComponent as Holding} from '../../../assets/icons/holding.svg';
import {ReactComponent as Portfolio} from '../../../assets/icons/portfolio.svg';
import {ReactComponent as OperatingEntities} from '../../../assets/icons/operatingEntities.svg';
import {ReactComponent as InvestmentAdvisor} from '../../../assets/icons/investmentAdvisor.svg';
import {ReactComponent as TopToBottom} from '../../../assets/icons/tree-view-top-to-bottom.svg';
import {ReactComponent as LeftToRight} from '../../../assets/icons/tree-view-left-to-right.svg';
import {ReactComponent as Info} from '../../../assets/icons/info-outline.svg';
import {ReactComponent as DateRangePresetChecked} from '../../../assets/icons/date-range-preset-checked.svg';
import {ReactComponent as DragHandle} from '../../../assets/icons/drag-handle.svg';
import {ReactComponent as Pencil} from '../../../assets/icons/pencil.svg';
import {ReactComponent as Percentage} from '../../../assets/icons/percentage.svg';
import {ReactComponent as ExclamationCircle} from '../../../assets/icons/exclamation-circle.svg';
import {ReactComponent as Warning} from '../../../assets/icons/warning.svg';
import {ReactComponent as TrashCan} from '../../../assets/icons/trash-can.svg';
import {ReactComponent as AssignUser} from '../../../assets/icons/assign-user.svg';
import {ReactComponent as Plus} from '../../../assets/icons/plus.svg';
import {ReactComponent as Submit} from '../../../assets/icons/submit.svg';
import {ReactComponent as Twitter} from '../../../assets/icons/twitter.svg';
import {ReactComponent as Refresh} from '../../../assets/icons/refresh.svg';
import {ReactComponent as ArrowLeft} from '../../../assets/icons/arrow-left.svg';
import {ReactComponent as ArrowRight} from '../../../assets/icons/arrow-right.svg';
import {ReactComponent as DiagramUp} from '../../../assets/icons/diagram-up.svg';
import {ReactComponent as DataCollectionIdea} from '../../../assets/icons/data-collection-idea.svg';
import {ReactComponent as Remove} from '../../../assets/icons/remove.svg';
import {ReactComponent as Save} from '../../../assets/icons/save.svg';
import {ReactComponent as Suitcase} from '../../../assets/icons/suitcase.svg';
import {ReactComponent as List} from '../../../assets/icons/list.svg';
import {ReactComponent as Download} from '../../../assets/icons/download.svg';
import {ReactComponent as Order} from '../../../assets/icons/order.svg';
import {ReactComponent as Youtube} from '../../../assets/icons/youtube.svg';
import {ReactComponent as Instagram} from '../../../assets/icons/instagram.svg';
import {ReactComponent as AirplaneRight} from '../../../assets/icons/airplane-right.svg';
import {ReactComponent as SwitchRight} from '../../../assets/icons/switch-right.svg';
import {ReactComponent as SwitchLeft} from '../../../assets/icons/switch-left.svg';
import {ReactComponent as LineChart} from '../../../assets/icons/line-chart.svg';
import {ReactComponent as BarChart} from '../../../assets/icons/bar-chart.svg';
import {ReactComponent as BarChartFilled} from '../../../assets/icons/bar-chart-filled.svg';
import {ReactComponent as AreaChart} from '../../../assets/icons/area-chart.svg';
import {ReactComponent as AddChart} from '../../../assets/icons/add-chart.svg';
import {ReactComponent as Document} from '../../../assets/icons/document.svg';
import {ReactComponent as DocumentUpload} from '../../../assets/icons/document-upload.svg';
import {ReactComponent as Lock} from '../../../assets/icons/lock.svg';
import {ReactComponent as PDF} from '../../../assets/icons/pdf.svg';
import {ReactComponent as Excel} from '../../../assets/icons/excel.svg';
import {ReactComponent as Multidot} from '../../../assets/icons/multidot.svg';
import {ReactComponent as Word} from '../../../assets/icons/word.svg';
import {ReactComponent as NoDocument} from '../../../assets/icons/no-document.svg';
import {ReactComponent as NoPreview} from '../../../assets/icons/no-preview.svg';
import {ReactComponent as LogoSecondaryWhite} from '../../../assets/logos/Lantern-secondary-logo-white.svg';
import {ReactComponent as StarFilled} from '../../../assets/icons/star-filled.svg';
import {ReactComponent as StarOutline} from '../../../assets/icons/star-outline.svg';
import {ReactComponent as LogoDark} from '../../../assets/icons/logo-dark.svg';
import {ReactComponent as LogoLight} from '../../../assets/icons/logo-light.svg';
import {ReactComponent as Loader} from '../../../assets/icons/loader.svg';
import {ReactComponent as LogoGradient} from '../../../assets/icons/logo-gradient.svg';
import {ReactComponent as Image} from '../../../assets/icons/image.svg';
import {ReactComponent as Powerpoint} from '../../../assets/icons/powerpoint.svg';
import {ReactComponent as Audio} from '../../../assets/icons/audio.svg';
import {ReactComponent as Video} from '../../../assets/icons/video.svg';
import {ReactComponent as UpdateDocument} from '../../../assets/icons/update-document.svg';
import {ReactComponent as ArrowUp} from '../../../assets/icons/arrow-up.svg';
import {ReactComponent as ArrowDown} from '../../../assets/icons/arrow-down.svg';
import {ReactComponent as ContentCopy} from '../../../assets/icons/content-copy.svg';
import {ReactComponent as Note} from '../../../assets/icons/note.svg';
import {ReactComponent as ScatteredBubbles} from '../../../assets/icons/scattered-bubbles.svg';
import {ReactComponent as Treemap} from '../../../assets/icons/treemap.svg';
import {ReactComponent as Undo} from '../../../assets/icons/undo.svg';
import styles from './Icon.module.scss';
import {Func} from '../../../types';
import {useTooltip} from '../../../hooks';
import {Position} from '../../../context/TooltipContext';

interface IconProps {
  name: string;
  className?: string;
  onClick?: Func<[React.MouseEvent], void>;
  tooltip?:
    | string
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>;
  tooltipPosition?: Position;
  tooltipClasses?: string;
  hasTooltipArrow?: boolean;
}
const Icon: React.FC<IconProps> = ({
  name,
  className,
  onClick = () => null,
  tooltip,
  tooltipPosition,
  tooltipClasses,
  hasTooltipArrow,
}) => {
  const {showTooltip, hideTooltip} = useTooltip();

  const isTooltipEnabled = !!tooltip;
  const handleMouseEnter = (event: React.MouseEvent<HTMLDivElement>) => {
    if (!tooltip || !event.currentTarget) return;
    showTooltip({
      target: event.currentTarget,
      content: tooltip,
      position: tooltipPosition || Position.Top,
      className: tooltipClasses,
      hasArrow: hasTooltipArrow,
    });
  };
  const renderIcon = (): JSX.Element | null => {
    switch (name) {
      case 'table-sort':
        return <TableSort />;
      case 'checkbox':
        return <Checkbox />;
      case 'brush-traveller':
        return <BrushTraveller />;
      case 'onboarding':
        return <Onboarding />;
      case 'search':
        return <Search />;
      case 'cross':
        return <Cross />;
      case 'notification':
        return <Notification />;
      case 'user':
        return <User />;
      case 'chevron-up':
        return <ChevronUp />;
      case 'chevron-down':
        return <ChevronDown />;
      case 'chevron-left':
        return <ChevronLeft />;
      case 'chevron-right':
        return <ChevronRight />;
      case 'three-dots':
        return <ThreeDots />;
      case 'eye':
        return <Eye />;
      case 'stats-up':
        return <StatsUp />;
      case 'stats-down':
        return <StatsDown />;
      case 'diagram-placeholder':
        return <DiagramPlaceholder />;
      case 'table':
        return <Table />;
      case 'list':
        return <List />;
      case 'tiles':
        return <Tiles />;
      case 'chart':
        return <Chart />;
      case 'a-shares':
        return <AShares />;
      case 'b-shares':
        return <BShares />;
      case 'convertibles':
        return <Convertibles />;
      case 'debt':
        return <Debt />;
      case 'equity':
        return <Equity />;
      case 'export-csv':
        return <ExportCsv />;
      case 'export-pdf':
        return <ExportPdf />;
      case 'export-excel':
        return <ExportExcel />;
      case 'export-ppt':
        return <ExportPpt />;
      case 'news':
        return <News />;
      case 'activity':
        return <Activity />;
      case 'shortcuts':
        return <Shortcuts />;
      case 'tasks':
        return <Tasks />;
      case 'funds':
        return <Funds />;
      case 'companies':
        return <Companies />;
      case 'data-collection':
        return <DataCollection />;
      case 'data-collection-idea':
        return <DataCollectionIdea />;
      case 'home':
        return <Home />;
      case 'linkedin':
        return <LinkedIn />;
      case 'facebook':
        return <Facebook />;
      case 'export':
        return <Export />;
      case 'ellipse':
        return <Ellipse />;
      case 'zoom-in':
        return <ZoomIn />;
      case 'zoom-out':
        return <ZoomOut />;
      case 'General Partner':
      case 'Account':
        return <GeneralPartner />;
      case 'Holding Company':
        return <Holding />;
      case 'Portfolio Company':
        return <Portfolio />;
      case 'Operating Entity':
      case 'Fund Entity':
        return <OperatingEntities />;
      case 'Investee Fund (Closed Ended with Shares)':
      case 'Fund':
        return <InvestmentAdvisor />;
      case 'top-to-bottom':
        return <TopToBottom />;
      case 'left-to-right':
        return <LeftToRight />;
      case 'info':
        return <Info />;
      case 'date-range-preset-checked':
        return <DateRangePresetChecked />;
      case 'drag-handle':
        return <DragHandle />;
      case 'pencil':
        return <Pencil />;
      case 'exclamation-circle':
        return <ExclamationCircle />;
      case 'check':
        return <Check />;
      case 'warning':
        return <Warning />;
      case 'trash-can':
        return <TrashCan />;
      case 'submit':
        return <Submit />;
      case 'assign-user':
        return <AssignUser />;
      case 'plus':
        return <Plus />;
      case 'percentage':
        return <Percentage />;
      case 'download':
        return <Download />;
      case 'twitter':
        return <Twitter />;
      case 'refresh':
        return <Refresh />;
      case 'arrow-left':
        return <ArrowLeft />;
      case 'arrow-right':
        return <ArrowRight />;
      case 'diagram-up':
        return <DiagramUp />;
      case 'remove':
        return <Remove />;
      case 'save':
        return <Save />;
      case 'suitcase':
        return <Suitcase />;
      case 'order':
        return <Order />;
      case 'youtube':
        return <Youtube />;
      case 'instagram':
        return <Instagram />;
      case 'airplane-right':
        return <AirplaneRight />;
      case 'switch-right':
        return <SwitchRight />;
      case 'switch-left':
        return <SwitchLeft />;
      case 'graph-chart':
        return <GraphChart />;
      case 'line-chart':
        return <LineChart />;
      case 'bar-chart':
        return <BarChart />;
      case 'bar-chart-filled':
        return <BarChartFilled />;
      case 'area-chart':
        return <AreaChart />;
      case 'add-chart':
        return <AddChart />;
      case 'document-upload':
        return <DocumentUpload />;
      case 'document':
        return <Document />;
      case 'lock':
        return <Lock />;
      case 'pdf':
        return <PDF />;
      case 'excel':
        return <Excel />;
      case 'word':
        return <Word />;
      case 'no-document':
        return <NoDocument />;
      case 'no-preview':
        return <NoPreview />;
      case 'logo-secondary-white':
        return <LogoSecondaryWhite />;
      case 'star-filled':
        return <StarFilled />;
      case 'star-outline':
        return <StarOutline />;
      case 'logo-dark':
        return <LogoDark />;
      case 'logo-light':
        return <LogoLight />;
      case 'logo-gradient':
        return <LogoGradient />;
      case 'loader':
        return <Loader />;
      case 'image':
        return <Image />;
      case 'powerpoint':
        return <Powerpoint />;
      case 'audio':
        return <Audio />;
      case 'video':
        return <Video />;
      case 'update-document':
        return <UpdateDocument />;
      case 'multidot':
        return <Multidot />;
      case 'arrow-up':
        return <ArrowUp />;
      case 'arrow-down':
        return <ArrowDown />;
      case 'content-copy':
        return <ContentCopy />;
      case 'note':
        return <Note />;
      case 'scattered-bubbles':
        return <ScatteredBubbles />;
      case 'treemap':
        return <Treemap />;
      case 'undo':
        return <Undo />;
      default:
        return null;
    }
  };
  return (
    <div
      className={classnames(styles.wrapper, className)}
      onClick={onClick}
      onMouseEnter={isTooltipEnabled ? handleMouseEnter : undefined}
      onMouseLeave={isTooltipEnabled ? hideTooltip : undefined}
    >
      {renderIcon()}
    </div>
  );
};

export default Icon;
