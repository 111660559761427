import {PayloadAction} from '@reduxjs/toolkit';
import {call, put, takeEvery} from 'redux-saga/effects';

import {acquireAuthResult, msalInstance} from '../../index';
import {
  getFilePreviewDataAction,
  setFilePreviewData,
  setFilePreviewIsOpen,
  setFilePreviewIsRequestPending,
} from '../../reducers/filePreviewSlice';
import {FilePreviewData} from '../../types';
import {getFilePreviewData} from '../../api/getFilePreview';

export const filePreviewSaga = function* filePreviewSaga({
  payload,
}: PayloadAction<{id: string}>) {
  const {id} = payload;

  try {
    const {accessToken} = yield call(acquireAuthResult, msalInstance);

    yield put(setFilePreviewIsRequestPending(true));

    const response: FilePreviewData = yield call(
      getFilePreviewData,
      accessToken,
      id
    );

    yield put(setFilePreviewData(response));
    yield put(setFilePreviewIsOpen(true));
    yield put(setFilePreviewIsRequestPending(false));
  } catch (e) {
    yield put(setFilePreviewIsOpen(false));
    yield put(setFilePreviewIsRequestPending(false));
    console.warn(e);
  }
};

export const filePreviewSagaWatcher = function* filePreviewSagaWatcher() {
  yield takeEvery(getFilePreviewDataAction.type, filePreviewSaga);
};
