import React, {useMemo} from 'react';
import {
  PortfolioExposureLabelsType,
  PortfolioExposureTableType,
} from '../../../../../types/index';
import styles from './ChartTooltip.module.scss';
import {ValueFormat} from '../../../../global';

interface ChartTooltipProps {
  data: PortfolioExposureTableType[];
  labels: PortfolioExposureLabelsType;
  activeCategory: string;
  currency?: string;
}

const ChartTooltip: React.FC<ChartTooltipProps> = ({
  data,
  activeCategory,
  labels,
  currency,
}) => {
  const values: PortfolioExposureTableType | undefined = useMemo(() => {
    if (!activeCategory) return;
    return data.find(item => item.category === activeCategory);
  }, [activeCategory, data]);

  if (!values) return null;
  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>{values.category}</div>
      <ul className={styles.list}>
        <li>
          <div
            className={styles.dot}
            style={{backgroundColor: `rgb(var(--colors-${values.color}))`}}
          />
          <b>{labels.count}:</b> {values.count}
        </li>
        <li>
          <div
            className={styles.dot}
            style={{
              backgroundColor: `rgba(var(--colors-${values.color}), .75)`,
            }}
          />
          <b>{labels.cost}:</b> {currency}{' '}
          <ValueFormat value={values.cost} format={'en-GB'} />
        </li>
        <li>
          <div
            className={styles.dot}
            style={{backgroundColor: `rgba(var(--colors-${values.color}), .5)`}}
          />
          <b>{labels.valuation}:</b> {currency}{' '}
          <ValueFormat value={values.valuation} format={'en-GB'} />
        </li>
      </ul>
    </div>
  );
};

export default ChartTooltip;
