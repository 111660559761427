import React, {Fragment, useEffect, useRef, useState} from 'react';
import classnames from 'classnames';
import {useMsal} from '@azure/msal-react';
import {useScrollPosition} from '../../hooks';
import {useAppDispatch, useAppSelector} from '../../hooks/useReduxHooks';
import routePaths from '../../routePaths';
import {clearSearchResults, searchAction} from '../../reducers/searchSlice';
import {
  MobileNavMenu,
  NavAccount,
  NavBurger,
  NavLogo,
  NavMenu,
  NavSearch,
} from './';
import NavInfo from './nav-info/NavInfo';
import {UserRole} from '../../types';
import styles from './Navigation.module.scss';
import UploadDocuments from '../global/upload-documents/UploadDocuments';
import {DataVisibilityToggle} from '../global';

interface NavigationProps {
  isUploadDocumentsEnabled: boolean;
  releaseDataCollectionEnabled: any;
  releaseDocumentRepositoryEnabled: any;
  releaseInFlightModeEnabled: any;
}

const Navigation: React.FC<NavigationProps> = ({
  isUploadDocumentsEnabled,
  releaseDataCollectionEnabled,
  releaseDocumentRepositoryEnabled,
  releaseInFlightModeEnabled,
}): JSX.Element | null => {
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);
  const {accounts, instance} = useMsal();
  const {y: scrollPosition} = useScrollPosition();
  const prevPosition = useRef<number>(scrollPosition);
  const [isSticky, setIsSticky] = useState(false);

  const userName = (accounts[0] || {})?.idTokenClaims?.given_name;
  const emails = (accounts[0] || {})?.idTokenClaims?.emails;
  const userMail = emails && emails[0];
  const userRole = (accounts[0] || {})?.idTokenClaims
    ?.extension_ApplicationRole;
  const isUserLoggedIn = accounts.length > 0;

  const dispatch = useAppDispatch();
  const {isLoading, searchResults, isActive} = useAppSelector(
    state => state.search
  );

  // get user profile role
  const {data: userProfile} = useAppSelector(state => state.userProfile);

  const isCompanyAdmin = !!userProfile?.userRole.includes(
    UserRole.CompanyAdmin
  );
  const isAdmin =
    !!userProfile?.userRole.includes(UserRole.FundManager) ||
    !!userProfile?.userRole.includes(UserRole.ClientAdmin);

  const onSearchValueChange = (val: string): void => {
    dispatch(searchAction(val));
  };

  const onSearchClearValue = (): void => {
    dispatch(clearSearchResults());
  };

  useEffect(() => {
    if (scrollPosition === 0) {
      setIsSticky(false);
    } else {
      if (prevPosition.current > scrollPosition) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    }
    prevPosition.current = scrollPosition;
  }, [prevPosition, scrollPosition]);

  return userProfile ? (
    <nav className={classnames(styles.navbar, isSticky && styles.sticky)}>
      <div className={styles.navLeft}>
        <NavLogo
          logoGradientId={'desktop-navigation'}
          className={styles.logo}
          linkTo={isAdmin ? routePaths.HOME : routePaths.REPORTING}
        />
        {isUserLoggedIn && (
          <NavBurger
            isOpen={isMobileNavOpen}
            handleClick={setIsMobileNavOpen}
          />
        )}
        <NavInfo isSticky={isSticky} />
      </div>
      <ul className={styles.topNav}>
        {isUserLoggedIn ? (
          <Fragment>
            <li className={styles.navListSearch}>
              {isAdmin ? (
                <NavSearch
                  className={styles.navSearch}
                  isActive={isActive}
                  isLoading={isLoading}
                  searchResults={searchResults}
                  handleChangeValue={onSearchValueChange}
                  handleClearValue={onSearchClearValue}
                />
              ) : null}
            </li>
            {/*
              @TODO As a part of ticket LP-1286 we'll remove notification icon until we implement the desired functionality.
              */}
            {/*<li*/}
            {/*  className={classnames(*/}
            {/*    styles.navItemNotificaiton,*/}
            {/*    styles.disabled*/}
            {/*  )}*/}
            {/*>*/}
            {/*  <NavNotification />*/}
            {/*</li>*/}

            {isAdmin && releaseInFlightModeEnabled ? (
              <li className={styles.navItemToggle}>
                <DataVisibilityToggle />
              </li>
            ) : null}

            <li className={styles.navItemAccount}>
              {userProfile.reportingCompany ? (
                <div className={styles.navItemUserProfile}>
                  {userProfile.reportingCompany}
                </div>
              ) : null}
              <NavAccount
                userName={userName as string}
                userMail={userMail}
                userRole={userRole as UserRole}
                onClick={() => instance.logoutRedirect()}
              />
            </li>
          </Fragment>
        ) : null}
      </ul>
      {isUserLoggedIn && (
        <NavMenu
          isAdmin={isAdmin}
          isCompanyAdmin={isCompanyAdmin}
          isSticky={isSticky}
          releaseDataCollectionEnabled={releaseDataCollectionEnabled}
          releaseDocumentRepositoryEnabled={releaseDocumentRepositoryEnabled}
        />
      )}
      {isUserLoggedIn && (
        <MobileNavMenu
          isMenuOpen={isMobileNavOpen}
          onMenuClose={() => setIsMobileNavOpen(false)}
          userName={userName as string}
          userMail={userMail}
          userRole={userRole as UserRole}
          isAdmin={isAdmin}
          isCompanyAdmin={isCompanyAdmin}
          onUserLogOut={() => instance.logoutRedirect()}
          releaseDataCollectionEnabled={releaseDataCollectionEnabled}
          releaseDocumentRepositoryEnabled={releaseDocumentRepositoryEnabled}
        />
      )}
      {releaseDocumentRepositoryEnabled && isUserLoggedIn ? (
        <UploadDocuments
          isFloatingButtonVisible={isUploadDocumentsEnabled}
          userRoles={userProfile.userRole}
        />
      ) : null}
    </nav>
  ) : null;
};

export default Navigation;
