import React from 'react';
import classnames from 'classnames';
import {PerformanceWidget} from '../';
import {PortfolioPerformanceDataProps} from '../../../types/index';
import styles from './PortfolioPerformanceDashboard.module.scss';

interface PortfolioPerformanceDashboardProps
  extends PortfolioPerformanceDataProps {
  className?: string;
  id: string;
}

const PortfolioPerformanceDashboard: React.FC<
  PortfolioPerformanceDashboardProps
> = ({widgets, className = '', id, signOffDate}): JSX.Element => {
  return (
    <div className={classnames(styles.wrapper, className)}>
      {widgets.map((item, i) => (
        <PerformanceWidget
          key={i}
          type={item.type}
          headerData={item.headerData}
          totalCost={item.totalCost}
          roiIrr={item.roiIrr}
          totalValue={item.totalValue}
          id={id}
          signOffDate={signOffDate}
        />
      ))}
    </div>
  );
};

export default PortfolioPerformanceDashboard;
