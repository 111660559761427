import React, {useRef} from 'react';
import styles from './ImpactCell.module.scss';
import {useTooltip} from '../../../../../hooks';
import {Position} from '../../../../../context/TooltipContext';

type ImpactCellProps = {
  value: number;
  summary: string;
};

const ImpactCell = ({value, summary}: ImpactCellProps): JSX.Element => {
  const {showTooltip, hideTooltip} = useTooltip();
  const wrapperRef = useRef<HTMLDivElement>(null);
  let val = value < -1 ? -1 : value > 1 ? 1 : value;
  const impactValue = ((val + 1) * 100) / 2;

  const handleMouseEnter = () => {
    if (!wrapperRef.current) return;
    showTooltip({
      content: summary,
      position: Position.Top,
      target: wrapperRef.current,
      className: styles.tooltip,
    });
  };

  return (
    <div
      className={styles.wrapper}
      ref={wrapperRef}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={hideTooltip}
    >
      <div className={styles.rect}>
        <div
          className={styles.triangleWrap}
          style={{
            left: `calc(${impactValue}% - 4px)`,
          }}
        >
          <div className={styles.triangle} />
        </div>
      </div>
    </div>
  );
};

export default ImpactCell;
