import React from 'react';
import {Icon} from '../../index';
import {useTranslation} from 'react-i18next';
import styles from './DocumentExplorerNotFoundPlaceholder.module.scss';

const DocumentExplorerNotFoundPlaceholder: React.FC = () => {
  const {t} = useTranslation();
  return (
    <div className={styles.notFoundContainer}>
      <Icon name={'no-document'} className={styles.notFoundContainerIcon} />
      <div className={styles.notFoundContainerTitle}>
        {t('Documents.DocumentExplorer.NotFoundTitle')}
      </div>
      <div className={styles.notFoundContainerSubTitle}>
        {t('Documents.DocumentExplorer.NotFoundSubtitle')}
      </div>
    </div>
  );
};

export default DocumentExplorerNotFoundPlaceholder;
