import {useState} from 'react';
import {Func} from '../types';

const useChartHover = (): {
  hovered: boolean;
  handleMouseLeave: Func<[], void>;
  handleMouseMove: Func<[], void>;
} => {
  const [hovered, setHovered] = useState(false);

  const handleMouseMove = () => {
    if (hovered) return;
    setHovered(true);
  };

  const handleMouseLeave = () => {
    if (!hovered) return;
    setHovered(false);
  };

  return {
    hovered,
    handleMouseLeave,
    handleMouseMove,
  };
};

export default useChartHover;
