/**
 * Route paths
 */
const routePaths = {
  HOME: '/',
  LOGIN: '/login',
  LOGOUT: '/logout',
  NOT_FOUND: '/not-found',
  FUNDS: '/funds',
  // fund details
  FUND_DETAILS: '/funds/:id',
  FUND_OVERVIEW: '/funds/:id/overview',
  FUND_OPERATIONAL: '/funds/:id/operational',
  FUND_STRUCTURE: '/funds/:id/structure',
  FUND_PORTFOLIO: '/funds/:id/portfolio-summary',
  FUND_PORTFOLIO_TRANSACTIONS: '/funds/:id/portfolio-transactions',
  FUND_NEAR_CASTING: '/funds/:id/near-casting',
  // near-casting scenarios
  SCENARIOS: '/scenarios',
  SCENARIO: '/scenarios/funds/:fundId/scenarios',
  SCENARIO_MAIN: '/scenarios/funds/:fundId/scenarios/create',
  SCENARIO_MAIN_WITH_ID: '/scenarios/funds/:fundId/scenarios/:id/main',
  SCENARIO_VALUATION: '/scenarios/funds/:fundId/scenarios/:id/valuation',
  SCENARIO_TRANSACTION: '/scenarios/funds/:fundId/scenarios/:id/transactions',
  SCENARIO_REPORT: '/scenarios/funds/:fundId/scenarios/:id/report',

  PORTFOLIO_COMPANIES: '/portfolio',
  // company details
  PORTFOLIO_COMPANY_DETAILS: '/portfolio/:id',
  PORTFOLIO_COMPANY_INVESTMENT_OVERVIEW: '/portfolio/:id/investment-overview',
  PORTFOLIO_COMPANY_COMPANY: '/portfolio/:id/company',
  PORTFOLIO_COMPANY_MONITORING: '/portfolio/:id/monitoring',
  // admin user
  ADMIN_USER: '/admin/user',
  ADMIN_USER_USERS: '/admin/user/users',
  // admin data collection
  ADMIN_DATA_COLLECTION: '/admin/data-collection',
  ADMIN_DATA_COLLECTION_TEMPLATE_CONFIGURATION:
    '/admin/data-collection/template-configuration',
  ADMIN_DATA_COLLECTION_WORKFLOW_CONFIGURATION:
    '/admin/data-collection/workflow-configuration',
  ADMIN_DATA_COLLECTION_TEMPLATE_CONFIGURATION_DETAILS:
    '/admin/data-collection/template-configuration/:id',
  ADMIN_DATA_COLLECTION_PORTFOLIO_COMPANY_USERS:
    '/admin/data-collection/portfolio-company-users',
  // data-collection
  DATA_COLLECTION: '/data-collection',
  DATA_COLLECTION_DETAILS: '/data-collection/:id',
  // company admin reporting
  REPORTING: '/reporting',
  // documents
  DOCUMENTS: '/documents',
  DOCUMENTS_DOCUMENT_EXPLORER: '/documents/document-explorer',
  DOCUMENTS_TRANSACTION_PIVOT: '/documents/transaction-pivot',
};
export const disabledNavRoutePaths = [routePaths.LOGIN, routePaths.LOGOUT];

export const enabledFundMonitoringUploadDocumentsPaths = [
  routePaths.FUND_OVERVIEW,
  routePaths.FUND_OPERATIONAL,
  routePaths.FUND_STRUCTURE,
  routePaths.FUND_PORTFOLIO,
  routePaths.FUND_PORTFOLIO_TRANSACTIONS,
  routePaths.PORTFOLIO_COMPANY_INVESTMENT_OVERVIEW,
];

export const enabledPortfolioMonitoringUploadDocumentsPaths = [
  ...enabledFundMonitoringUploadDocumentsPaths,
  routePaths.PORTFOLIO_COMPANY_COMPANY,
  routePaths.PORTFOLIO_COMPANY_MONITORING,
];

export default routePaths;
