import {createAction, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {
  DataCollection,
  DataCollectionWorkflowTableRowItem,
  FormStatus,
  ManageWorkflowOptions,
  StartWorkflowPayloadExternal,
  StartWorkflowPayloadInternal,
  UpdateActiveWorkflowPayload,
} from '../types';

export const getManageWorkflowOptionsAction = createAction(
  'data-collection/get-workflow-options',
  (id: string) => {
    return {
      payload: {
        id,
      },
    };
  }
);

export const startWorkflowAction = createAction(
  'data-collection/start-workflow',
  (payload: StartWorkflowPayloadExternal | StartWorkflowPayloadInternal) => {
    return {
      payload,
    };
  }
);

export const updateActiveWorkflowAction = createAction(
  'data-collection/update-active-workflow',
  (payload: UpdateActiveWorkflowPayload) => {
    return {
      payload,
    };
  }
);

export const stopWorkflowActions = createAction(
  'data-collection/stop-workflow',
  (
    payload: Pick<
      StartWorkflowPayloadExternal | StartWorkflowPayloadInternal,
      'id' | 'reportingGroupId'
    >
  ) => {
    return {
      payload,
    };
  }
);

interface DataCollectionSlice {
  data: DataCollection | null;
  manageWorkflowStatus: FormStatus;
  error: string | null;
  formError: Record<string, string> | null;
  manageWorkflowOptions: ManageWorkflowOptions | null;
}

const initialState: DataCollectionSlice = {
  data: null,
  manageWorkflowStatus: FormStatus.Ready,
  error: null,
  formError: null,
  manageWorkflowOptions: null,
};

const dataCollectionSlice = createSlice({
  name: 'data-collection/main',
  initialState,
  reducers: {
    setDataCollectionData(
      state: DataCollectionSlice,
      action: PayloadAction<DataCollection>
    ) {
      state.data = action.payload;
    },
    clearDataCollectionData(state: DataCollectionSlice) {
      state.data = null;
    },
    setManageWorkflowFormStatus(
      state: DataCollectionSlice,
      action: PayloadAction<FormStatus>
    ) {
      state.manageWorkflowStatus = action.payload;
    },
    setDataCollectionError(
      state: DataCollectionSlice,
      action: PayloadAction<string | null>
    ) {
      state.error = action.payload;
    },
    setDataCollectionManageWorkflowFormError(
      state: DataCollectionSlice,
      action: PayloadAction<Record<string, string> | null>
    ) {
      state.formError = action.payload;
    },
    setManageWorkflowOptions(
      state: DataCollectionSlice,
      action: PayloadAction<ManageWorkflowOptions | null>
    ) {
      state.manageWorkflowOptions = action.payload;
    },
    updateDataCollectionWorkflowTableItem(
      state: DataCollectionSlice,
      action: PayloadAction<{
        data: DataCollectionWorkflowTableRowItem;
        reportingGroupId: string;
      }>
    ) {
      if (state.data) {
        const groupIdx = state.data.data.findIndex(
          item => item.id === action.payload.reportingGroupId
        );
        const itemIdx = state.data.data[groupIdx]?.data.findIndex(
          item => item.id === action.payload.data.id
        );
        if (itemIdx > -1 && groupIdx > -1) {
          state.data.data[groupIdx].data[itemIdx] = action.payload.data;
        }
      }
    },
  },
});

export default dataCollectionSlice.reducer;

export const {
  setDataCollectionData,
  clearDataCollectionData,
  setManageWorkflowFormStatus,
  setDataCollectionError,
  setDataCollectionManageWorkflowFormError,
  setManageWorkflowOptions,
  updateDataCollectionWorkflowTableItem,
} = dataCollectionSlice.actions;
