import React from 'react';
import styles from './CustomAreaTooltip.module.scss';
import {TooltipProps} from 'recharts/index';
import {Icon, ValueFormat} from '../../../../../../global';
import {useTranslation} from 'react-i18next';

interface CustomTooltipProps extends TooltipProps {
  format?: string;
  currency?: string | null;
}

const CustomAreaTooltip: React.FC<CustomTooltipProps> = ({
  active,
  payload,
  currency,
  format,
}) => {
  const {t} = useTranslation();
  if (active && payload?.length) {
    if (payload[0].dataKey === 'noData') {
      return (
        <div className={styles.tooltipWrapper}>
          <p className={styles.quarter}>{payload[0].payload.quarter}</p>
          <div className={styles.value}>
            <Icon className={styles.icon} name={'warning'} />{' '}
            {t('Global.DataNotAvailable')}
          </div>
        </div>
      );
    }
    if (
      payload[0].dataKey === 'NC NAV' ||
      payload[0].dataKey === 'NC Net IRR'
    ) {
      let displayValue = '';

      if (payload[0].dataKey === 'NC NAV') {
        displayValue = 'NAV';
      } else {
        displayValue = 'Net IRR';
      }

      return (
        <div className={styles.tooltipWrapper}>
          <p className={styles.quarter}>{payload[0].payload.quarter}</p>
          <div className={styles.value}>
            <span>{displayValue}</span>
            <ValueFormat
              currency={currency}
              value={payload[0].value.toString()}
              format={format}
            />
          </div>
        </div>
      );
    }
    return (
      <div className={styles.tooltipWrapper}>
        <p className={styles.quarter}>{payload[0].payload.quarter}</p>
        <div className={styles.value}>
          <span>{t(payload[0].name)}: </span>
          <ValueFormat
            currency={currency}
            value={payload[0].value.toString()}
            format={format}
          />
        </div>
      </div>
    );
  }

  return null;
};

export default CustomAreaTooltip;
