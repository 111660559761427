import axios from 'axios';
import config from '../config';
import {ClientAdminPortfolioCompanyUsers} from '../types';

export const getClientAdminPortfolioCompanyUsers = async (token: string) => {
  const {data} = await axios.get<ClientAdminPortfolioCompanyUsers[]>(
    `${config.APP_URI}/admin/portfolio-company-users`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );
  return data[0];
};
