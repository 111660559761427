import {Icon} from '../index';
import React from 'react';
import styles from './Tag.module.scss';
import {StyleVariant, TagProps} from '../../../types';
import classnames from 'classnames';
import {getFontColorByBackgroundColor} from '../../../utils';
import {defaultTagColor} from '../../../constants';

const Tag = ({
  className,
  style,
  label,
  onRemove,
  variant = StyleVariant.Primary,
  color = defaultTagColor,
  width,
  ...restOfProps
}: TagProps) => {
  return (
    <div
      {...restOfProps}
      style={{
        backgroundColor: `rgb(var(--colors-${color || 'gray-7'}))`,
        color: `rgb(var(--colors-${getFontColorByBackgroundColor(
          color || 'gray-7'
        )}))`,
        ...style,
        ...(width && {minWidth: `${width}px`}),
      }}
      className={classnames(styles.tag, styles[`tag__${variant}`], className, {
        [styles.truncated]: !!width,
      })}
    >
      <span className={styles.label}>{label}</span>
      {onRemove && (
        <button
          style={{
            color: `rgb(var(--colors-${getFontColorByBackgroundColor(
              color || 'gray-7'
            )}))`,
          }}
          onClick={onRemove}
          className={styles.removeButton}
        >
          <Icon className={styles.removeButtonIcon} name={'cross'} />
        </button>
      )}
    </div>
  );
};

export default Tag;
