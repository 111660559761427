import React from 'react';
import {valueFormat} from '../../../utils/value-format';
import styles from './ValueFormat.module.scss';

interface ValueFormatProps {
  value?: string | number;
  format?: string;
  currency?: string | null;
}
// do not use this comp as a child of SVG element
const ValueFormat: React.FC<ValueFormatProps> = ({value, format, currency}) => {
  const {value: formattedValue, suffix} = valueFormat(value, format);
  return (
    <div className={styles.wrapper}>
      {currency ? `${currency} ` : ''}
      {formattedValue || ''}
      {suffix && <sub className={styles.suffix}>{suffix}</sub>}
    </div>
  );
};

export default ValueFormat;
