import React, {useEffect, useRef, useState} from 'react';
import ReactDOM from 'react-dom';
import classnames from 'classnames';
import {useEscapeKey, useOnOutsideClick} from '../../../hooks';
import {ButtonStyle, ModalProps, StyleVariant} from '../../../types';
import {IconButton} from '../index';
import styles from './Modal.module.scss';
import {useTranslation} from 'react-i18next';

const Modal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  closeOnOutsideClick = true,
  closeOnEscapeKeyPress = true,
  title,
  className,
  children,
  variant = StyleVariant.Primary,
  footerChildren,
  subTitle,
  isPortal = true,
  isDismissable = true,
  sidePanelDialog,
  isSidePanelDialogOpen,
  closeButtonStyleType,
  style,
  zIndex = 10,
  isFullViewport = false,
}) => {
  const {t} = useTranslation();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [animated, setAnimated] = useState(false);

  useOnOutsideClick(wrapperRef, closeOnOutsideClick ? onClose : () => {});

  useEscapeKey(closeOnEscapeKeyPress ? onClose : () => {});

  useEffect(() => {
    const timerId = setTimeout(() => {
      setAnimated(isOpen);
    }, 200);
    return () => {
      clearTimeout(timerId);
    };
  }, [isOpen]);

  const renderContent = () => {
    return (
      <div className={styles.overlay} style={{zIndex: zIndex}}>
        <div
          className={classnames(
            styles.wrapper,
            styles[`wrapper__${variant}`],
            className,
            animated && styles.animated,
            isSidePanelDialogOpen && styles.sidePanelDialogOpen,
            isFullViewport && styles.fullViewport
          )}
          ref={wrapperRef}
          style={style}
        >
          {isDismissable ? (
            <IconButton
              styleType={
                closeButtonStyleType
                  ? closeButtonStyleType
                  : variant === StyleVariant.Primary
                  ? ButtonStyle.Secondary
                  : ButtonStyle.Primary
              }
              icon={'cross'}
              onClick={onClose}
              className={styles.closeButton}
            />
          ) : null}
          {!!title && (
            <div className={styles.header}>
              <div className={styles.title}>{t(title)}</div>
              {subTitle && <div className={styles.subTitle}>{t(subTitle)}</div>}
            </div>
          )}
          <div className={styles.content}>{children}</div>
          {!!footerChildren && (
            <div className={styles.footer}>{footerChildren}</div>
          )}
        </div>
        {isSidePanelDialogOpen ? (
          <div className={styles.sidePanelDialog}>{sidePanelDialog}</div>
        ) : null}
      </div>
    );
  };

  if (!isOpen) return null;

  return isPortal
    ? ReactDOM.createPortal(renderContent(), document.body)
    : renderContent();
};

export default Modal;
