import React, {Fragment, useEffect, useMemo, useRef} from 'react';
import classnames from 'classnames';
import {useTooltip} from '../../../../../hooks';
import {Position} from '../../../../../context/TooltipContext';
import {Func, TimelineChartEvent} from '../../../../../types';
import {Icon, ValueFormat} from '../../../index';
import {EventData} from '../year-line/YearLine';
import styles from './EventPoint.module.scss';
import {formatWithLocale} from '../../../../../utils';

export enum LabelPosition {
  one = 'one',
  two = 'two',
  three = 'three',
  four = 'four',
  five = 'five',
}

interface EventPointProps extends EventData {
  currentYear: {
    to: number;
    from: number;
  };
  isExpanded: boolean;
  isFirstEvent: boolean;
  setFirstEventRef: Func<[HTMLDivElement], void>;
  onHoverEvent: Func<[string | number, HTMLDivElement | null], void>;
}

interface TooltipContentProps {
  label?: TimelineChartEvent[];
  icon?: string;
  time: string | number | undefined;
}

const TooltipContent: React.FC<TooltipContentProps> = ({label, time, icon}) => {
  return (
    <>
      {icon && <Icon className={styles.icon} name={icon} />}
      <div>
        {label?.map((item, idx) => (
          <div key={idx}>
            <ValueFormat value={item.value} format={item.format} />
          </div>
        ))}
        <div>{time && formatWithLocale(new Date(time), 'dd-MM-yyyy')}</div>
      </div>
    </>
  );
};

const EventPoint: React.FC<EventPointProps> = ({
  label,
  currentYear,
  time,
  isExpanded,
  labelPosition,
  setFirstEventRef,
  isFirstEvent,
  onHoverEvent,
  color,
  icon,
}) => {
  const {showTooltip, hideTooltip} = useTooltip();
  const wrapperRef = useRef<HTMLDivElement>(null);

  const {isRendered, rightPosition} = useMemo(() => {
    const valueTimestamp = new Date(time).getTime();
    return {
      rightPosition:
        ((currentYear.to - valueTimestamp) /
          (currentYear.to - currentYear.from)) *
        100,
      isRendered:
        valueTimestamp >= currentYear.from && valueTimestamp <= currentYear.to,
    };
  }, [currentYear, time]);

  const handleMouseLeave = () => {
    if (isExpanded) {
      onHoverEvent('', null);
    } else {
      hideTooltip();
    }
  };

  const handleMouseEnter = (e: React.MouseEvent) => {
    if (isExpanded) {
      if (!isFirstEvent && time) {
        onHoverEvent(time, e.currentTarget as HTMLDivElement);
      }
    } else {
      showTooltip({
        content: <TooltipContent label={label} icon={icon} time={time} />,
        position: Position.Top,
        target: e.currentTarget as HTMLElement,
        className: styles.tooltip,
        hasArrow: false,
      });
    }
  };

  useEffect(() => {
    if (wrapperRef.current && isRendered && isFirstEvent) {
      setFirstEventRef(wrapperRef.current);
    }
  }, [wrapperRef, isRendered, isFirstEvent, setFirstEventRef]);

  if (!isRendered) {
    return null;
  }

  return (
    <Fragment>
      <span
        className={classnames(
          styles.line,
          styles[labelPosition],
          !isExpanded && styles.collapsed
        )}
        style={{
          backgroundColor: color ? `rgb(var(--colors-${color}))` : '',
          right: rightPosition + '%',
        }}
      />
      <div
        ref={wrapperRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className={classnames(styles.wrapper, isExpanded && styles.expanded)}
        style={{
          right: rightPosition + '%',
        }}
      >
        <span
          className={styles.point}
          style={{backgroundColor: color ? `rgb(var(--colors-${color}))` : ''}}
        />
        <span
          className={classnames(
            styles.label,
            isExpanded && styles.expanded,
            styles[labelPosition]
          )}
        >
          <div
            className={classnames(styles.labelText, icon && styles.withIcon)}
          >
            {icon && <Icon className={styles.icon} name={icon} />}
            <div className={styles.value}>
              {label?.map((item, idx) => (
                <div key={idx}>
                  {item.currency && item.currency}{' '}
                  <ValueFormat value={item.value} format={item.format} />
                </div>
              ))}
              {isFirstEvent && (!label || label.length < 2) && (
                <div className={styles.value}>
                  {time && formatWithLocale(new Date(time), 'dd-MM-yyyy')}
                </div>
              )}
            </div>
          </div>
        </span>
      </div>
    </Fragment>
  );
};

export default EventPoint;
