import React, {useEffect, useMemo, useRef, useState} from 'react';
import {
  Bar,
  Brush,
  CartesianGrid,
  Cell,
  ComposedChart,
  Label,
  LabelList,
  Legend,
  Line,
  ReferenceArea,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import {
  fundPerformancePiccChartSettings,
  getImagesExportPayload,
} from './FundPerformancePiccChartSettings';
import {ReactComponent as BrushTraveller} from '../../../../../assets/icons/brush-traveller.svg';
import FundPerformanceMoicTooltip from '../fund-performance-moic-chart/components/FundPerformanceMoicTooltip';
import CustomYAxisTick from '../../../../global/custom-y-axis-tick/CustomYAxisTick';
import {
  BundledExportProps,
  ButtonStyle,
  Func,
  NavTimeSeries,
  TabData,
  TicksType,
} from '../../../../../types';
import NavCustomLegend from '../nav-custom-legend/NavCustomLegend';
import {piccLegendItems, piccTooltipOrder} from '../../consts';
import {getHalfBarWidthInTime, valueFormat} from '../../../../../utils';
import {
  IconButton,
  LiveDataChartLabelContent,
  LiveDataChartShape,
} from '../../../../global';
import styles from './FundPerformancePiccChart.module.scss';
import {
  calcDomainRange,
  calcXaxisTicks,
  dateToMilliseconds,
} from '../../../../../utils/benchmarking';
import NavXAXisTick from '../fund-performance-nav-chart/components/NavXAxisTick';
import useChartHover from '../../../../../hooks/useChartHover';

interface FundPerformancePiccChartProps extends TabData {
  exportHandler: Func<[BundledExportProps], void>;
  entityName?: string;
  nearCastingData?: TabData;
  showExport?: boolean;
}

const CHART_X_MARGINS = 140;

const FundPerformancePiccChart: React.FC<FundPerformancePiccChartProps> = ({
  timeSeries: reportedTimeSeries,
  nearCastingData,
  format,
  exportHandler,
  entityName,
  id,
  showExport = true,
  signOffDate,
}) => {
  const timeSeries = useMemo(
    () => ({
      data: [
        ...(reportedTimeSeries.data || []),
        ...(nearCastingData?.timeSeries?.data || []),
      ],
    }),
    [reportedTimeSeries, nearCastingData]
  );
  const containerRef = useRef<HTMLDivElement>(null);
  const [chartWidth, setChartWidth] = useState(0);
  const [selectedBar, setSelectedBar] = useState(null);
  const [showBenchmarking, setShowBenchmarking] = useState(false);
  const [exportInProgress, setExportInProgress] = useState<boolean>(false);
  const [range, setRange] = useState<number>(
    (timeSeries.data || []).length - 1
  );
  const [rangeIndexes, setRangeIndexes] = useState<[number, number]>([
    0,
    (timeSeries.data?.length || 1) - 1,
  ]);
  const [activeCharts, setActiveCharts] = useState<string[]>(
    piccLegendItems.map(({value}) => value)
  );
  const {hovered, handleMouseMove, handleMouseLeave} = useChartHover();

  const isNearCastingEmpty =
    !nearCastingData?.timeSeries || !nearCastingData?.timeSeries?.data?.length;
  const nearCastingStartingFrom = (
    reportedTimeSeries?.data?.slice(-1)[0] as any
  )?.date;

  const chartData = useMemo(() => {
    return (
      timeSeries.data?.map(item => ({
        ...item,
        x: new Date((item as any).date as string),
      })) || []
    );
  }, [timeSeries]);

  const {from, to} = useMemo(() => {
    if (chartData) {
      return calcDomainRange(chartData, rangeIndexes);
    }
    return {from: new Date(), to: new Date(), length: 0};
  }, [chartData, rangeIndexes]);

  const halfBarWidthInTime = useMemo(
    () => getHalfBarWidthInTime(to, from, range, chartWidth - CHART_X_MARGINS),
    [to, from, range, chartWidth]
  );

  const xAxisTicksType = useMemo<TicksType>(() => {
    const rangeIdx = range >= chartData.length ? chartData.length - 1 : range;
    const {start, end} =
      chartData && chartData.length > 0
        ? dateToMilliseconds(chartData[0].x, chartData[rangeIdx].x)
        : dateToMilliseconds(new Date(), new Date());

    const yearsRange = Math.floor((end - start) / 3.154e10);

    if (yearsRange >= 3) return TicksType.Year;
    return TicksType.Quarter;
  }, [chartData, range]);

  const getXAxisTicks = useMemo(() => {
    return calcXaxisTicks(
      chartData as NavTimeSeries[],
      xAxisTicksType,
      true
    ).map((item: string) => new Date(item));
  }, [xAxisTicksType, chartData]);

  const xAxisFormat = useMemo(() => {
    if (xAxisTicksType === TicksType.Year) return 'yyyy';
    return "QQ 'YY";
  }, [xAxisTicksType]);

  const onBrushChange = ({startIndex, endIndex}: any) => {
    setRange(endIndex - startIndex);
    setRangeIndexes([startIndex, endIndex]);
  };

  const CustomTraveller = (props: any) => {
    return <BrushTraveller x={props.x - 7} y={props.y} />;
  };

  const LabelFormatter = (value: Number) => {
    if (value !== 0) {
      return valueFormat(value.valueOf(), format).value;
    }
  };
  const onBenchmarkToggle = () => {
    setShowBenchmarking(prevState => !prevState);
  };
  const onMouseEnter = (e: any, index: any) => {
    setSelectedBar(index);
    handleMouseMove();
  };
  const onMouseLeave = () => {
    setSelectedBar(null);
    handleMouseLeave();
  };

  const handleBundledExport = async () => {
    setExportInProgress(true);

    const zipFileName = `${entityName}_FundPerformance_PICC`;

    const tableSettings = fundPerformancePiccChartSettings(zipFileName);

    const tableExportPayload = [
      {
        data: timeSeries.data,
        mappings: tableSettings.headerMapping,
        settings: tableSettings,
      },
    ];

    await exportHandler({
      zipFileName,
      tableExportPayload,
      imagesExportPayload: getImagesExportPayload(zipFileName),
    } as unknown as BundledExportProps);

    setExportInProgress(false);
  };

  const getBarProps = (dataKey: string, stroke: string, fill: string) => ({
    dataKey,
    stackId: 1,
    yAxisId: 1,
    fill,
    barSize: 56,
    minPointSize: 0,
    stroke,
    strokeOpacity: 0,
    onMouseEnter,
    onMouseLeave,
  });

  useEffect(() => {
    if (containerRef.current) {
      setChartWidth(containerRef.current.offsetWidth);
    }

    const handleResize = () => {
      if (containerRef.current) {
        setChartWidth(containerRef.current.offsetWidth);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const lineProps = {
    strokeWidth: 2,
    connectNulls: true,
    stroke: 'rgb(var(--colors-lan-blue))',
    dot: false,
    yAxisId: 1,
    activeDot: {
      stroke: 'rgb(var(--colors-lan-blue))',
      fill: 'rgb(var(--colors-lan-blue))',
      strokeWidth: 5,
      strokeOpacity: 0.5,
      r: 6.5,
    },
  };

  const brushLineProps = {
    dot: false,
    connectNulls: true,
    stroke: 'rgb(var(--colors-lan-blue))',
  };

  return (
    <div className={styles.wrapper} ref={containerRef}>
      <ResponsiveContainer
        width="100%"
        height={590}
        id="performance-picc-chart"
      >
        <ComposedChart
          data={chartData as any}
          margin={{top: 35, left: 80, right: 60}}
          reverseStackOrder={false}
          onMouseMove={handleMouseMove}
          onMouseLeave={handleMouseLeave}
        >
          <defs>
            <linearGradient
              id="nearCastingGradient"
              x1="24.4311"
              y1="6.58801e-06"
              x2="458.185"
              y2="407.236"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor={`rgb(var(--colors-nearcasting-gradient1))`} />
              <stop
                offset="1"
                stopColor={`rgb(var(--colors-nearcasting-gradient2))`}
              />
            </linearGradient>
            <linearGradient
              id="signOffDataGradient"
              gradientTransform="rotate(0)"
            >
              <stop offset="0%" stopColor={`rgba(65, 65, 65, 0.8)`} />
              <stop offset="50%" stopColor={`rgba(65, 65, 65, 0.51)`} />
              <stop offset="100%" stopColor={`rgba(52, 51, 51, 0.2)`} />
            </linearGradient>
          </defs>
          <defs>
            <linearGradient id="DpiGradient" gradientTransform="rotate(90)">
              <stop offset="0%" stopColor={`rgba(var(--colors-gray-5),0.56)`} />
              <stop
                offset="100%"
                stopColor={`rgba(var(--colors-gray-7),0.8)`}
              />
            </linearGradient>
          </defs>
          <defs>
            <linearGradient id="RvpiGradient" gradientTransform="rotate(180)">
              <stop
                offset="26.46%"
                stopColor={`rgba(var(--colors-lan-violet),0.93)`}
                stopOpacity="0.93"
              />
              <stop
                offset="-87.14%"
                stopColor={`rgba(var(--colors-lan-blue),0.93)`}
                stopOpacity="0.93"
              />
            </linearGradient>
          </defs>
          <CartesianGrid
            vertical={false}
            strokeDasharray="2"
            stroke={`rgb(var(--colors-gray-6))`}
            width={1920}
            x={50}
          />
          {id === 'fund-performance-row' && (
            <rect
              y={437}
              width="100%"
              height={52}
              // TODO: Replace color after updating the wrapper structure of the chart
              fill="#272727"
              fillOpacity={0.75}
            />
          )}
          <XAxis
            dataKey="x"
            domain={[from.getTime(), to.getTime()]}
            type="number"
            scale="time"
            interval={0}
            axisLine={false}
            height={60}
            tickLine={false}
            ticks={getXAxisTicks as any}
            tick={
              <NavXAXisTick
                showAxis={true}
                fill="gray-3"
                fontSize={12}
                yOffset={30}
                xAxisFormat={xAxisFormat}
              />
            }
          />
          <YAxis
            axisLine={false}
            tickLine={false}
            yAxisId={1}
            width={1}
            tick={
              <CustomYAxisTick
                showAxis={true}
                xOffset={-45}
                yOffset={4}
                fill="gray-3"
                fontSize={12}
                fontWeight={500}
              />
            }
          />
          {!isNearCastingEmpty && (
            <ReferenceArea
              yAxisId={1}
              x1={new Date(nearCastingStartingFrom).getTime()}
              x2={chartData.slice(-1)[0].x.getTime() + halfBarWidthInTime}
              ifOverflow="visible"
              fill="url(#nearCastingGradient)"
              fillOpacity={0.3}
            />
          )}

          <Bar
            {...getBarProps(
              'DPI',
              'rgb(var(--colors-gray-5))',
              'url(#DpiGradient)'
            )}
          >
            {timeSeries.data?.map((_, index) => (
              <Cell
                key={`cell-${index}`}
                opacity={selectedBar === index ? 0.5 : 1}
              />
            ))}
          </Bar>
          <Bar
            {...getBarProps(
              'RVPI',
              'rgb(var(--colors-lan-violet))',
              'url(#RvpiGradient)'
            )}
          >
            {timeSeries.data?.map((bar, index) => (
              <Cell
                key={`cell-${index}`}
                opacity={selectedBar === index ? 0.5 : 1}
              />
            ))}
            <LabelList
              dataKey="TVPI"
              position="top"
              fill="rgb(var(--colors-orange-peel))"
              fontSize={12}
              formatter={LabelFormatter}
            />
          </Bar>
          {!isNearCastingEmpty && (
            <>
              <Bar
                {...getBarProps(
                  'NC DPI',
                  'rgb(var(--colors-gray-5))',
                  'url(#DpiGradient)'
                )}
              >
                {timeSeries.data?.map((_, index) => (
                  <Cell
                    key={`cell-${index}`}
                    opacity={selectedBar === index ? 0.5 : 1}
                  />
                ))}
              </Bar>
              <Bar
                {...getBarProps(
                  'NC RVPI',
                  'rgb(var(--colors-lan-violet))',
                  'url(#RvpiGradient)'
                )}
              >
                {timeSeries.data?.map((_, index) => (
                  <Cell
                    key={`cell-${index}`}
                    opacity={selectedBar === index ? 0.5 : 1}
                  />
                ))}
                <LabelList
                  dataKey="NC TVPI"
                  position="top"
                  fill="rgb(var(--colors-orange-peel))"
                  fontSize={12}
                  formatter={LabelFormatter}
                />
              </Bar>
            </>
          )}

          <Line
            {...lineProps}
            type="stepAfter"
            hide={!activeCharts.includes('picc')}
            dataKey="PICC"
          />
          {!isNearCastingEmpty && (
            <Line
              {...lineProps}
              type="stepAfter"
              hide={!activeCharts.includes('picc')}
              dataKey="NC PICC"
              strokeDasharray="6 4"
            />
          )}

          <Tooltip
            content={
              <FundPerformanceMoicTooltip
                payloadOrder={piccTooltipOrder}
                additionalItem={{
                  color: 'rgb(var(--colors-orange-peel))',
                  label: 'TVPI',
                }}
              />
            }
            offset={28}
          />

          {signOffDate && (
            <ReferenceArea
              yAxisId={1}
              isFront={true}
              x1={new Date(signOffDate).getTime()}
              shape={props => (
                <LiveDataChartShape
                  {...props}
                  rightMargin={100}
                  fill="url(#signOffDataGradient)"
                />
              )}
            >
              {hovered && (
                <Label
                  value={'In-flight data'}
                  content={LiveDataChartLabelContent}
                />
              )}
            </ReferenceArea>
          )}
          <Legend
            verticalAlign="top"
            align="left"
            iconSize={12}
            content={
              <NavCustomLegend
                id="fund-performance-picc-chart-legend"
                items={piccLegendItems}
                benchmarking={undefined} //This will be done on the back end in next sprint
                onBenchmarkToggle={onBenchmarkToggle}
                showBenchmarking={showBenchmarking}
                activeCharts={activeCharts}
                setActiveCharts={setActiveCharts}
                exportButton={
                  showExport ? (
                    <IconButton
                      className="performance-chart-export"
                      onClick={handleBundledExport}
                      styleType={ButtonStyle.Primary}
                      icon="export"
                      loading={exportInProgress}
                      id="performance-picc-chart-export-btn"
                    />
                  ) : undefined
                }
              />
            }
            wrapperStyle={{
              paddingBottom: '64px',
              paddingTop: '35px',
              marginLeft: '-22px',
            }}
            formatter={value => (
              <span
                style={{
                  color: 'rgb(var(--colors-gray-3))',
                  fontSize: 14,
                  fontWeight: 500,
                }}
              >
                {value}
              </span>
            )}
          />

          <Brush
            className="performance-chart-brush"
            fill={`rgb(var(--colors-black))`}
            dataKey="quarter"
            height={100}
            travellerWidth={0}
            traveller={<CustomTraveller />}
            tickFormatter={tick => ''}
            onChange={onBrushChange}
          >
            <ComposedChart
              data={timeSeries.data as any}
              reverseStackOrder={true}
            >
              <CartesianGrid
                vertical={true}
                horizontal={false}
                strokeDasharray="2"
                stroke={`rgb(var(--colors-gray-6))`}
              />
              {(signOffDate || !isNearCastingEmpty) && (
                <XAxis
                  dataKey="x"
                  domain={[
                    chartData[0].x.getTime(),
                    chartData.slice(-1)[0].x.getTime(),
                  ]}
                  scale="time"
                  type="number"
                  axisLine={false}
                  height={0}
                  interval={0}
                  tickLine={false}
                />
              )}
              {!isNearCastingEmpty && (
                <ReferenceArea
                  x1={new Date(nearCastingStartingFrom).getTime()}
                  ifOverflow="visible"
                  fill="url(#nearcastingGradient)"
                  fillOpacity={0.3}
                />
              )}
              {signOffDate && (
                <ReferenceArea
                  x1={new Date(signOffDate).getTime()}
                  ifOverflow="visible"
                  shape={props => (
                    <LiveDataChartShape
                      {...props}
                      bottomMargin={30}
                      fill="url(#signOffDataGradient)"
                    />
                  )}
                />
              )}
              <Line {...brushLineProps} dataKey="PICC" type="stepAfter" />
              {!isNearCastingEmpty && (
                <Line
                  {...brushLineProps}
                  dataKey={'NC PICC'}
                  type="stepAfter"
                  strokeDasharray="6 4"
                />
              )}
              <Bar
                dataKey="DPI"
                fill="none"
                barSize={56}
                minPointSize={20}
                stroke="none"
                strokeOpacity={0}
              />
            </ComposedChart>
          </Brush>
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

export default FundPerformancePiccChart;
