import React from 'react';
import {TooltipWrapperProps} from '../../../types';
import {useTooltip} from '../../../hooks';
import {Position} from '../../../context/TooltipContext';
import styles from './TooltipWrapper.module.scss';

const TooltipWrapper: React.FC<TooltipWrapperProps> = ({
  isTooltipVisible = true,
  tooltipValue,
  tooltipPosition,
  children,
}) => {
  const {showTooltip, hideTooltip} = useTooltip();
  const handleMouseEnter = (e: React.MouseEvent) => {
    showTooltip({
      target: e.currentTarget as HTMLElement,
      content: tooltipValue,
      position: tooltipPosition || Position.Top,
      hasArrow: false,
    });
  };

  const tooltipConditionsPassed = isTooltipVisible && tooltipValue;

  return (
    <div
      onMouseEnter={tooltipConditionsPassed ? handleMouseEnter : undefined}
      onMouseLeave={hideTooltip}
      className={styles.tooltipWrapper}
    >
      {children}
    </div>
  );
};

export default TooltipWrapper;
