import {
  ActiveCompanyData,
  ExitedCompanyData,
  WrittenOffCompanyData,
} from '../types/index';

export const capitalizeString = (str: string): string => {
  if (!str) return '';
  return `${str[0].toUpperCase()}${str.slice(1)}`;
};

export const capitalizeStringSentence = (
  str: string,
  separator: string = ' '
): string => {
  return str
    .split(separator)
    .map(word => word[0].toUpperCase() + word.substring(1))
    .join('');
};

export const isActiveCompany = (
  data: ActiveCompanyData | ExitedCompanyData | WrittenOffCompanyData
): data is ActiveCompanyData =>
  (data as ActiveCompanyData).type === 'ActiveCompanyData';

export const isExitedCompany = (
  data: ActiveCompanyData | ExitedCompanyData | WrittenOffCompanyData
): data is ExitedCompanyData =>
  (data as ExitedCompanyData).type === 'ExitedCompanyData';

export const isWrittenOffCompany = (
  data: ActiveCompanyData | ExitedCompanyData | WrittenOffCompanyData
): data is WrittenOffCompanyData =>
  (data as WrittenOffCompanyData).type === 'WrittenOffCompanyData';
