import React, {useMemo} from 'react';
import styles from './Calendar.module.scss';
import {getDaysOfWeek} from '../../../utils';
import CalendarPicker from '../calendar-picker/CalendarPicker';
import {CalendarPickerProps} from '../../../types';

const Calendar = ({
  availableRange,
  range,
  setRange,
  selectedPreset,
  isRangePicker = true,
}: CalendarPickerProps): JSX.Element => {
  const daysOfWeek = useMemo(() => getDaysOfWeek(), []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.daysInWeek}>
        {daysOfWeek.days.map((day: string, key: number) => {
          return (
            <div key={key}>
              <span>{day}</span>
            </div>
          );
        })}
      </div>
      <CalendarPicker
        selectedPreset={selectedPreset}
        range={range}
        setRange={setRange}
        availableRange={availableRange}
        isRangePicker={isRangePicker}
      />
    </div>
  );
};

export default Calendar;
