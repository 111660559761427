import React from 'react';
import {
  useChartAnimated,
  useChartStyles,
  useChartHover,
} from '../../../../../hooks/';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Label,
  LabelList,
  Legend,
  ReferenceArea,
  ResponsiveContainer,
  Tooltip,
  XAxis,
} from 'recharts';
import {SummaryWidgetChartProps} from '../../../../../types';
import CustomXAxisTick from '../../../../global/custom-x-axis-tick/CustomXAxisTick';
import CustomBarTooltip from './components/custom-bar-tooltip/CustomBarTooltip';
import NoDataChartLabel from '../../../../global/no-data-chart-label/NoDataChartLabel';
import {useTranslation} from 'react-i18next';
import {valueFormat} from '../../../../../utils';
import LiveDataChartShape from '../../../../global/live-data-chart-shape/LiveDataChartShape';
import {LiveDataChartLabelContent} from '../../../../global';

const FundBarChart: React.FC<SummaryWidgetChartProps> = ({
  timeSeries,
  signOffDate,
}): JSX.Element => {
  const {t} = useTranslation();
  const {
    barChartBarSize,
    barChartLabelFontSize,
    barChartNoData,
    barLegendIconSize,
    barLegendFontSize,
    xAxisFontSize,
  } = useChartStyles();
  const {hovered, handleMouseMove, handleMouseLeave} = useChartHover();

  const LabelFormatter = (value: Number) => {
    if (value !== 0) {
      return value.toFixed(2);
    }
  };
  const {ref, isAnimated} = useChartAnimated();

  return (
    <div ref={ref}>
      <ResponsiveContainer height={240} width="100%">
        <BarChart
          data={timeSeries?.data as any}
          barSize={barChartBarSize}
          onMouseMove={handleMouseMove}
          onMouseLeave={handleMouseLeave}
          margin={{top: 44, bottom: 5}}
        >
          <defs>
            <linearGradient id="DpiGradient" gradientTransform="rotate(90)">
              <stop offset="0%" stopColor={`rgba(var(--colors-gray-5),0.56)`} />
              <stop
                offset="100%"
                stopColor={`rgba(var(--colors-gray-7),0.8)`}
              />
            </linearGradient>
            <linearGradient
              id="signOffDataGradient"
              gradientTransform="rotate(0)"
            >
              <stop offset="0%" stopColor={`rgba(65, 65, 65, 0.8)`} />
              <stop offset="50%" stopColor={`rgba(65, 65, 65, 0.51)`} />
              <stop offset="100%" stopColor={`rgba(52, 51, 51, 0.2)`} />
            </linearGradient>
          </defs>
          <defs>
            <linearGradient id="RvpiGradient" gradientTransform="rotate(180)">
              <stop
                offset="26.46%"
                stopColor={`rgba(var(--colors-lan-violet),0.93)`}
                stopOpacity="0.93"
              />
              <stop
                offset="-87.14%"
                stopColor={`rgba(var(--colors-lan-blue),0.93)`}
                stopOpacity="0.93"
              />
            </linearGradient>
          </defs>
          <CartesianGrid
            vertical={false}
            strokeDasharray="2"
            stroke={`rgb(var(--colors-gray-6))`}
          />
          <Tooltip cursor={false} content={<CustomBarTooltip />} offset={20} />

          <XAxis
            dataKey="quarter"
            tickLine={false}
            axisLine={false}
            interval={0}
            tick={
              <CustomXAxisTick
                showAxis={hovered}
                fill="gray-3"
                fontSize={xAxisFontSize}
                yOffset={15}
              />
            }
          />
          <Bar
            dataKey="DPI"
            stackId={1}
            fill="url(#DpiGradient)"
            isAnimationActive={isAnimated}
            hide={!isAnimated}
          ></Bar>
          <Bar
            dataKey="RVPI"
            stackId={1}
            fill="url(#RvpiGradient)"
            isAnimationActive={isAnimated}
            hide={!isAnimated}
          >
            <LabelList
              dataKey="TVPI"
              position="top"
              fill="rgb(var(--colors-orange-peel))"
              fontSize={barChartLabelFontSize}
              formatter={LabelFormatter}
            />
          </Bar>
          <Bar
            isAnimationActive={isAnimated}
            hide={!isAnimated}
            dataKey="noData"
            stackId={1}
            shape={<NoDataChartLabel labelStyles={barChartNoData} />}
          />
          {signOffDate && (
            <ReferenceArea
              x1={valueFormat(signOffDate, "QQ 'YY").value}
              shape={props => (
                <LiveDataChartShape
                  {...props}
                  topMargin={44}
                  bottomMargin={100}
                  fill="url(#signOffDataGradient)"
                />
              )}
            >
              {hovered && (
                <Label
                  value={'In-flight data'}
                  content={LiveDataChartLabelContent}
                />
              )}
            </ReferenceArea>
          )}
          <Legend
            height={1}
            verticalAlign="bottom"
            align="left"
            iconSize={barLegendIconSize}
            payload={
              hovered
                ? []
                : [
                    {
                      value: t('TVPI'),
                      type: 'circle',
                      color: 'rgb(var(--colors-orange-peel))',
                    },
                    {
                      value: t('RVPI'),
                      type: 'circle',
                      color: 'rgb(var(--colors-lan-violet))',
                    },
                    {
                      value: t('DPI'),
                      type: 'circle',
                      color: 'rgb(var(--colors-gray-5))',
                    },
                  ]
            }
            wrapperStyle={{
              paddingLeft: '20px',
              bottom: 25,
            }}
            formatter={value => (
              <span
                style={{
                  color: 'rgb(var(--colors-gray-3))',
                  fontSize: barLegendFontSize,
                }}
              >
                {value}
              </span>
            )}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default FundBarChart;
