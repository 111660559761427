import React, {FocusEvent, useEffect, useState} from 'react';
import styles from './TextArea.module.scss';
import classnames from 'classnames';
import {Icon} from '../index';
import {TextAreaProps} from '../../../types';
import {useTranslation} from 'react-i18next';

const TextArea: React.FC<TextAreaProps> = ({
  className = '',
  variant,
  label,
  labelIconProps,
  placeholder = '',
  validate,
  errorMessage,
  showErrorMessage = false,
  resizable,
  ...textAreaProps
}) => {
  const {t} = useTranslation();
  const [blur, setBlur] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const handleBlur = (event: FocusEvent<HTMLTextAreaElement>) => {
    setBlur(true);
    setIsValid(validate({[`${textAreaProps.name}`]: textAreaProps.value}));
    textAreaProps.onBlur && textAreaProps.onBlur(event);
  };

  useEffect(() => {
    setIsValid(validate({[`${textAreaProps.name}`]: textAreaProps.value}));
  }, [textAreaProps.value]);

  return (
    <div className={classnames(styles.wrapper, styles[`wrapper__${variant}`])}>
      <label className={styles.inputLabel}>
        {t(label)}
        {labelIconProps?.labelIcon && (
          <Icon
            name={labelIconProps.labelIcon}
            className={labelIconProps.labelIconClassName}
            tooltip={labelIconProps.labelIconTooltipContent}
            tooltipPosition={labelIconProps.labelIconTooltipContentPosition}
            tooltipClasses={labelIconProps.labelIconTooltipClasses}
            hasTooltipArrow={labelIconProps.labelIconTooltipArrow}
          />
        )}
      </label>
      <div className={styles.inputField}>
        <textarea
          {...textAreaProps}
          placeholder={t(placeholder)}
          style={{resize: resizable ? 'vertical' : 'none'}}
          onChange={textAreaProps.onChange}
          onBlur={handleBlur}
          onFocus={() => {
            if (!isValid && errorMessage) {
              setBlur(false);
            }
          }}
          data-validate={isValid.toString()}
          className={classnames(
            styles.input,
            showErrorMessage
              ? styles.inputInvalid
              : blur
              ? isValid
                ? styles.inputValid
                : styles.inputInvalid
              : '',
            className
          )}
        />
        <span className={styles.errorMessage}>{errorMessage}</span>
        <Icon
          className={classnames(styles.icon, styles['icon__danger'])}
          name={'exclamation-circle'}
        />
        <Icon
          className={classnames(styles.icon, styles['icon__success'])}
          name={'check'}
        />
      </div>
    </div>
  );
};

export default TextArea;
