import React, {Suspense} from 'react';
import {SectionHeader} from '../../dashboard';
import {FundStructureChartData} from '../../../types/fundStructure';
// import {StructureChart} from '../../global';
import styles from './FundStructureChartDashboard.module.scss';
import {IconButton} from '../../global';
import {ButtonStyle} from '../../../types';
import {eventBus} from '../../global/structure-chart/lib/EventBus';

const StructureChart = React.lazy(
  () => import('../../../ui-components/global/structure-chart/StructureChart')
);

interface FundStructureChartDashboardProps extends FundStructureChartData {
  id: string;
}

const FundStructureChartDashboard: React.FC<
  FundStructureChartDashboardProps
> = props => {
  const exportSvg = () => {
    eventBus.dispatch('export-svg');
  };

  return (
    <div>
      <SectionHeader
        label={props.label}
        labelType={'large'}
        onClick={(): void => {}}
        withActionButton={false}
        className={styles.structureChartHeader}
        childrenRight={[
          <IconButton
            onClick={exportSvg}
            styleType={ButtonStyle.Primary}
            icon="export"
          />,
        ]}
      />
      <Suspense fallback={null}>
        <StructureChart id={props.id} {...props.data} />
      </Suspense>
    </div>
  );
};

export default FundStructureChartDashboard;
