import React, {Fragment, ReactElement} from 'react';
import styles from './QuestionnaireTopicRow.module.scss';
import classnames from 'classnames';
import {Icon} from '../../../index';
import {useTooltip} from '../../../../../hooks';
import {Position} from '../../../../../context/TooltipContext';

type QuestionnaireTopicRowProps = {
  name: string;
  description?: string | null;
  questions: Array<{
    question: string;
    mandatory: boolean;
    answer: ReactElement;
  }>;
};

type TooltipContentProps = {
  description: string;
};

const QuestionnaireTopicRow = ({
  name,
  description,
  questions,
}: QuestionnaireTopicRowProps) => {
  const {showTooltip, hideTooltip} = useTooltip();

  const TooltipContent = ({description}: TooltipContentProps) => {
    return <>{description}</>;
  };

  const handleMouseEnter = (e: React.MouseEvent) => {
    console.warn({description});
    description &&
      showTooltip({
        content: <TooltipContent description={description} />,
        position: Position.Top,
        target: e.currentTarget as HTMLElement,
        className: styles.tooltip,
        hasArrow: false,
      });
  };

  return (
    <div className={classnames(styles.row)}>
      <div className={classnames(styles.groupRowLeft)}>
        <span className={styles.groupHeaderCellSpan}>
          {name}
          <span onMouseEnter={handleMouseEnter} onMouseLeave={hideTooltip}>
            <Icon className={styles.infoIcon} name={'info'} />
          </span>
        </span>
      </div>
      <div className={classnames(styles.groupRowRight)}>
        {questions.map(({question, mandatory, answer}) => {
          return (
            <Fragment key={`${question}`}>
              <p className={styles.question}>
                {question}
                {mandatory && <span className={styles.mandatoryMark}>*</span>}
              </p>
              {answer}
            </Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default QuestionnaireTopicRow;
