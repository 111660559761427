import React from 'react';
import classnames from 'classnames';
import {ActivityBadgeProps} from '../../../types/index';
import styles from './ActivityBadge.module.scss';
import {useTranslation} from 'react-i18next';
import {formatToHyphenatedString} from '../../../utils/strings';

const ActivityBadge: React.FC<ActivityBadgeProps> = ({
  label = '',
  color,
  textColor = 'black',
  fontSize = 16,
  fontWeight = 500,
}): JSX.Element => {
  const {t} = useTranslation();
  return (
    <div
      className={classnames(styles.wrapper)}
      style={color ? {backgroundColor: `rgb(var(--colors-${color}))`} : {}}
      data-test={`badge-${formatToHyphenatedString(label)}`}
    >
      <div
        style={{
          color: `rgb(var(--colors-${textColor}))`,
          fontSize: fontSize,
          fontWeight: fontWeight,
        }}
      >
        {t(label)}
      </div>
    </div>
  );
};

export default ActivityBadge;
