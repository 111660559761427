import React, {useEffect} from 'react';
import {DateRange, DateRangePresetTypes, Func} from '../../../../../types';
import styles from './DateRangePresets.module.scss';
import {Icon} from '../../../index';
import classnames from 'classnames';
import {useTranslation} from 'react-i18next';

export type DateRangePreset = {
  label: DateRangePresetTypes;
  handler: Func<[DateRange], DateRange>;
};

type DateRangePresetsProps = {
  rangePresets: DateRangePreset[];
  setRange: Func<[DateRange], void>;
  initialDateRange: DateRange;
  previouslySelectedDateRange: DateRange | null;
  selectedPreset: DateRangePresetTypes;
  setSelectedPreset: Func<[DateRangePresetTypes], void>;
  dateRange: DateRange | undefined;
};
const DateRangePresets = ({
  setRange,
  rangePresets,
  initialDateRange,
  selectedPreset,
  setSelectedPreset,
}: DateRangePresetsProps): JSX.Element => {
  const {t} = useTranslation();
  useEffect(() => {
    const rangePreset = rangePresets.find(
      item => item.label === selectedPreset
    );

    if (rangePreset) {
      if (rangePreset.label !== DateRangePresetTypes.custom) {
        setRange(rangePreset.handler(initialDateRange));
      }
      setSelectedPreset(selectedPreset);
    }
  }, [selectedPreset, initialDateRange]);

  return (
    <div className={styles.wrapper}>
      <ul>
        {rangePresets.map(rangePreset => {
          return (
            <li
              className={classnames({
                [`${styles.disabledClick}`]:
                  rangePreset.label === DateRangePresetTypes.custom,
              })}
              key={rangePreset.label}
              onClick={() => {
                setSelectedPreset(rangePreset.label);
              }}
            >
              <span
                className={classnames(styles.icon, {
                  [`${styles.icon__checked}`]:
                    rangePreset.label === selectedPreset,
                })}
              >
                <Icon name={'date-range-preset-checked'} />
              </span>
              <span
                className={classnames({
                  [`${styles.text__checked}`]:
                    rangePreset.label === selectedPreset,
                })}
              >
                {t(rangePreset.label)}
              </span>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default DateRangePresets;
