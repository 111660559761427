import {useAppSelector} from './useReduxHooks';
import {fundDetailsSelector} from '../selectors';

const useFundsDetails = () => {
  const state = useAppSelector(state => state);
  const data = fundDetailsSelector(state);
  return data;
};

export default useFundsDetails;
