import React from 'react';
import classnames from 'classnames';
import styles from './NavBurger.module.scss';

interface Props {
  isOpen: boolean;
  handleClick?: (value: boolean) => void;
  className?: string;
}

const NavBurger: React.FC<Props> = ({
  handleClick = null,
  isOpen = false,
  className = '',
}): JSX.Element => {
  const handleOnClick = (): void => {
    handleClick && handleClick(!isOpen);
  };

  return (
    <div
      className={classnames(styles.wrapper, className)}
      data-test="navigation-mobile"
    >
      <div
        className={classnames(styles.navBurger, isOpen && styles.open)}
        onClick={handleOnClick}
      >
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  );
};

export default NavBurger;
