import React, {useEffect, useState} from 'react';

const useTableScroll = ({
  scrollContainerRef,
}: {
  scrollContainerRef: React.RefObject<HTMLElement>;
}) => {
  const [isScrollable, setIsScrollable] = useState(false);
  const [scrollPosition, setScrollPosition] = useState<
    'left' | 'middle' | 'right'
  >('left');

  const handleScrollBy = (scrollBy: number) => {
    scrollContainerRef.current?.scrollBy({left: scrollBy, behavior: 'smooth'});
  };

  const handleScrollContainer = (e: React.UIEvent) => {
    const scrollLeft = e.currentTarget.scrollLeft;
    const scrollWidth =
      e.currentTarget.scrollWidth - e.currentTarget.clientWidth;
    if (scrollLeft === 0) {
      setScrollPosition('left');
    } else if (scrollLeft === scrollWidth) {
      setScrollPosition('right');
    } else {
      setScrollPosition('middle');
    }
  };

  useEffect(() => {
    const handleResize = () => {
      if (scrollContainerRef.current) {
        setIsScrollable(
          scrollContainerRef.current.clientWidth <
            scrollContainerRef.current.scrollWidth
        );
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return {
    isScrollable,
    scrollPosition,
    handleScrollBy,
    handleScrollContainer,
  };
};

export default useTableScroll;
