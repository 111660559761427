import {createAction, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {
  ReportingGroupTemplateFormState,
  ClientAdminTemplateConfiguration,
  FormStatus,
  TemplateConfigReportingGroup,
  TemplateConfigReportingGroupItem,
} from '../types';

export const addNewTemplateConfigTemplateAction = createAction(
  'client-admin/template-configuration/add-new-template',
  (payload: ReportingGroupTemplateFormState) => {
    return {
      payload,
    };
  }
);

interface ClientAdminTemplateConfigSlice {
  data: ClientAdminTemplateConfiguration | null;
  addNewTemplateStatus: FormStatus;
  error: string | null;
  formError: Record<string, string> | null;
}

const initialState: ClientAdminTemplateConfigSlice = {
  data: null,
  addNewTemplateStatus: FormStatus.Ready,
  error: null,
  formError: null,
};

const clientAdminTemplateConfigSlice = createSlice({
  name: 'client-admin/template-configuration',
  initialState,
  reducers: {
    setClientAdminTemplateConfigData(
      state: ClientAdminTemplateConfigSlice,
      action: PayloadAction<ClientAdminTemplateConfiguration>
    ) {
      state.data = action.payload;
    },
    setClientAdminTemplateConfigDataGroup(
      state: ClientAdminTemplateConfigSlice,
      action: PayloadAction<
        TemplateConfigReportingGroup<TemplateConfigReportingGroupItem>
      >
    ) {
      if (state.data) {
        const updatedData = state.data.data.map(item => {
          if (item.id === action.payload.id) {
            return action.payload;
          }
          return item;
        });
        state.data.data = updatedData;
      }
    },
    setAddNewTemplateStatus(
      state: ClientAdminTemplateConfigSlice,
      action: PayloadAction<FormStatus>
    ) {
      state.addNewTemplateStatus = action.payload;
    },
    setAddNewTemplateError(
      state: ClientAdminTemplateConfigSlice,
      action: PayloadAction<string | null>
    ) {
      state.error = action.payload;
    },
    setAddNewTemplateFormError(
      state: ClientAdminTemplateConfigSlice,
      action: PayloadAction<Record<string, string> | null>
    ) {
      state.formError = action.payload;
    },
    clearClientAdminTemplateConfigData(state: ClientAdminTemplateConfigSlice) {
      state.data = null;
    },
  },
});

export default clientAdminTemplateConfigSlice.reducer;

export const {
  setClientAdminTemplateConfigData,
  setAddNewTemplateStatus,
  setAddNewTemplateError,
  setAddNewTemplateFormError,
  clearClientAdminTemplateConfigData,
  setClientAdminTemplateConfigDataGroup,
} = clientAdminTemplateConfigSlice.actions;
