import React, {ChangeEvent, useMemo} from 'react';
import {
  ButtonStyle,
  MetricConfigItem,
  MetricsPanelGroup,
  MetricsPanelProps,
  StyleVariant,
} from '../../../types';
import styles from './MetricsPanel.module.scss';
import {Accordion, Checkbox, IconButton} from '../../global';
import classnames from 'classnames';

const MetricsPanel: React.FC<MetricsPanelProps> = ({
  label,
  isCollapsed,
  data,
  onCollapse,
  onChangeCheckbox,
  isRequestPending,
}) => {
  const reportingGroupLabels: string[] = useMemo(() => {
    return data.map(item => item.label);
  }, [data]);

  const accordionGroups: MetricsPanelGroup[] = useMemo(() => {
    return data.reduce(
      (acc: MetricsPanelGroup[], reportingGroup, reportingGroupIdx, data) => {
        reportingGroup.metricsGroups.forEach(group => {
          const groupIdx = acc.findIndex(g => g.label === group.label);
          if (groupIdx < 0) {
            acc = [
              ...acc,
              {
                label: group.label,
                metrics: group.metrics.map(metric => ({
                  label: metric.label,
                  data: data.map(repGroup => {
                    if (repGroup.label === reportingGroup.label) {
                      return {
                        reportingGroupLabel: repGroup.label,
                        metric,
                      };
                    }
                    return {
                      reportingGroupLabel: repGroup.label,
                      metric: null,
                    };
                  }),
                })),
              },
            ];
            return;
          }

          group.metrics.forEach(metric => {
            const metricIdx = acc[groupIdx].metrics.findIndex(
              m => m.label === metric.label
            );
            if (metricIdx < 0) {
              acc[groupIdx].metrics = [
                ...acc[groupIdx].metrics,
                {
                  label: metric.label,
                  data: data.map(repGroup => {
                    if (repGroup.label === reportingGroup.label) {
                      return {
                        reportingGroupLabel: repGroup.label,
                        metric,
                      };
                    }
                    return {
                      reportingGroupLabel: repGroup.label,
                      metric: null,
                    };
                  }),
                },
              ];
              return;
            }
            acc[groupIdx].metrics[metricIdx] = {
              ...acc[groupIdx].metrics[metricIdx],
              data: data.map(repGroup => {
                if (repGroup.label === reportingGroup.label) {
                  return {
                    reportingGroupLabel: repGroup.label,
                    metric,
                  };
                }
                const existedGroup = acc[groupIdx].metrics[metricIdx].data.find(
                  g => g?.reportingGroupLabel === repGroup.label
                );

                if (existedGroup) return existedGroup;
                return {
                  reportingGroupLabel: repGroup.label,
                  metric: null,
                };
              }),
            };
          });
        });
        return acc;
      },
      []
    );
  }, [data]);

  const handleChangeCheckbox =
    (metric: MetricConfigItem) => (event: ChangeEvent<HTMLInputElement>) => {
      onChangeCheckbox({
        ...metric,
        isSelected: event.target.checked,
      });
    };

  return (
    <div
      className={classnames(styles.wrapper, isCollapsed && styles.collapsed)}
    >
      <div className={styles.top}>
        <div className={styles.title}>{label}</div>
        {reportingGroupLabels.map((group, idx) => (
          <div className={styles.topGroupLabel} key={idx}>
            {group}
          </div>
        ))}
        <IconButton
          className={styles.expandButton}
          styleType={ButtonStyle.Secondary}
          onClick={onCollapse}
          icon={'chevron-left'}
        />
      </div>
      <div className={styles.accordionsWrapper}>
        {accordionGroups.map((group, idx) => (
          <Accordion
            styleVariant={StyleVariant.Secondary}
            key={idx}
            title={group.label}
            isDefaultExpanded={idx === 0}
          >
            {group.metrics.map((metric, idx) => (
              <div key={idx} className={styles.metricItem}>
                <div className={styles.metricLabel}>{metric.label}</div>
                {metric.data.map((item, idx) => (
                  <div className={styles.checkboxWrapper} key={idx}>
                    <div className={styles.checkboxLabel}>
                      {item.reportingGroupLabel}
                    </div>
                    {item.metric ? (
                      <div className={styles.checkbox}>
                        <Checkbox
                          onChange={handleChangeCheckbox(item.metric)}
                          label={''}
                          checked={item.metric.isSelected}
                          disabled={isRequestPending}
                        />
                      </div>
                    ) : null}
                  </div>
                ))}
              </div>
            ))}
          </Accordion>
        ))}
      </div>
    </div>
  );
};

export default MetricsPanel;
