import useBreakpoint from './useBreakpoint';

const useChartStyles = () => {
  const {isXSmall, isSmall, isMedium, isLarge, isXLarge, isXXlarge} =
    useBreakpoint();
  const chartStyles = {
    barChartPaddingTop: '9px',
    xAxisFontSize: 12,
    areaChartNoData: {
      fontSize: 11,
      x: -42,
      y: 2,
      width: 85,
      height: 48,
      rx: 25,
      textY: 2.15,
      textX: 0,
    },
    areaChartMisingData: {
      lineY1: 50,
      lineY2: 180,
      rectX: 32,
      rectY: 15,
      rectWidth: 65,
      rectHeight: 34,
      rectRx: 20,
      fontSize: 12,
      textY: 37,
    },
    YAxisBackground: {
      x: '80%',
      width: 35,
      height: 195,
      rx: 20,
    },
    areaChartYAxis: {
      xOffset: 30,
      yOffset: 4,
      fontSize: 12,
    },
    barChartBarSize: 25,
    barChartLabelFontSize: '12px',
    barChartNoData: {
      rectX: 9,
      rectY: -2,
      rectWidth: 50,
      rectHeight: 40,
      rectRx: 20,
      fontSize: 10,
      textX: 14,
    },
    barLegendIconSize: 10,
    barLegendFontSize: '12px',
  };
  if (isXSmall) {
    return chartStyles;
  }
  if (isSmall) {
    return {
      ...chartStyles,
      areaChartNoData: {
        fontSize: 12,
        x: -45,
        y: 1.95,
        width: 90,
        height: 55,
        rx: 30,
        textY: 2.2,
      },
      areaChartMisingData: {
        rectX: 40,
        rectWidth: 80,
        rectHeight: 35,
      },
      YAxisBackground: {
        x: '86.5%',
        width: 35,
        height: 195,
        rx: 20,
      },
      areaChartYAxis: {
        xOffset: 30,
        yOffset: 4,
        fontSize: 12,
      },
      barChartBarSize: 40,
      barChartNoData: {
        rectX: -15,
        rectY: -5,
        rectWidth: 70,
        rectHeight: 45,
        rectRx: 25,
        fontSize: 11,
        textX: 20,
      },
    };
  }
  if (isMedium) {
    return {
      ...chartStyles,
      areaChartNoData: {
        fontSize: 12,
        x: -45,
        y: 1.95,
        width: 90,
        height: 55,
        rx: 30,
        textY: 2.2,
      },
      areaChartMisingData: {
        rectX: 40,
        rectY: 15,
        rectWidth: 80,
        rectHeight: 35,
      },
      YAxisBackground: {
        x: '89.7%',
        width: 35,
        height: 195,
        rx: 20,
      },
      barChartBarSize: 50,
      barChartNoData: {
        rectX: 9,
        rectY: -5,
        rectWidth: 80,
        rectHeight: 45,
        rectRx: 25,
        fontSize: 12,
        textX: 25,
      },
      barLegendIconSize: 12,
      barLegendFontSize: '14px',
    };
  }
  if (isLarge) {
    return {
      ...chartStyles,
      areaChartNoData: {
        fontSize: 12,
        x: -48,
        y: 1.95,
        width: 95,
        height: 55,
        textY: 2.2,
      },
      areaChartMisingData: {
        rectX: 40,
        rectY: 15,
        rectWidth: 80,
        rectHeight: 35,
      },
      YAxisBackground: {
        x: '86.7%',
        width: 35,
        height: 195,
        rx: 20,
      },
      barChartBarSize: 35,
      barChartNoData: {
        rectX: 1.5,
        rectY: -5,
        rectWidth: 75,
        rectHeight: 45,
        rectRx: 25,
        fontSize: 12,
        textX: 19,
      },
      barLegendIconSize: 12,
      barLegendFontSize: '14px',
    };
  }
  if (isXLarge) {
    return {
      ...chartStyles,
      areaChartNoData: {
        fontSize: 12,
        x: -45,
        y: 1.95,
        width: 100,
        height: 55,
        rx: 30,
        textY: 2.2,
        textX: 5,
      },
      areaChartMisingData: {
        rectX: 40,
        rectY: 15,
        rectWidth: 80,
        rectHeight: 35,
      },
      YAxisBackground: {
        x: '89.7%',
        width: 35,
        height: 195,
        rx: 20,
      },
      barChartBarSize: 40,
      barChartNoData: {
        rectX: 4,
        rectY: -5,
        rectWidth: 90,
        rectHeight: 45,
        rectRx: 25,
        fontSize: 12,
        textX: 20,
      },
      barLegendIconSize: 12,
      barLegendFontSize: '14px',
    };
  }
  if (isXXlarge) {
    return {
      ...chartStyles,
      xAxisFontSize: 14,
      areaChartNoData: {
        fontSize: 14,
        x: -55,
        y: 1.95,
        width: 120,
        height: 60,
        rx: 30,
        textY: 2.3,
        textX: 5,
      },
      areaChartMisingData: {
        rectX: 50,
        rectY: 12,
        rectWidth: 100,
        rectHeight: 40,
        fontSize: 14,
      },
      YAxisBackground: {
        x: '89%',
        width: 35,
        height: 195,
        rx: 20,
      },
      areaChartYAxis: {
        xOffset: 30,
        yOffset: 4,
        fontSize: 14,
      },
      barChartBarSize: 40,
      barChartLabelFontSize: '14px',
      barChartNoData: {
        rectX: 4,
        rectY: -5,
        rectWidth: 90,
        rectHeight: 45,
        rectRx: 25,
        fontSize: 12,
        textX: 20,
      },
      barLegendIconSize: 12,
      barLegendFontSize: '14px',
    };
  }
  return chartStyles;
};

export default useChartStyles;
