import React from 'react';
import {useLocation} from 'react-router-dom';
import {ActiveCompanyData} from '../../../../../types/index';
import CompanyDelta from '../company-delta/CompanyDelta';
import BaseInfo from '../base-info/BaseInfo';
import FinancialData from '../financial-data/FinancialData';
import homeLayoutStyles from '../../CompanyWidget.module.scss';
import pageLayoutStyles from '../../CompanyWidgetTemp.module.scss';

const ActiveCompany: React.FC<ActiveCompanyData> = props => {
  const {
    fundColor,
    irr,
    totalCost,
    valuation,
    currency,
    sortingValue,
    instrumentTypes,
  } = props;
  const {pathname} = useLocation();
  const styles = pathname === '/' ? homeLayoutStyles : pageLayoutStyles;
  return (
    <div
      className={styles.wrapper}
      style={{borderColor: `rgb(var(--colors-${fundColor}))`}}
      data-test="company-widget"
    >
      <BaseInfo
        data={props}
        instrumentTypes={instrumentTypes}
        rightTopComponent={
          sortingValue ? (
            <CompanyDelta
              data={{irr, totalCost, valuation}}
              sortValue={sortingValue}
            />
          ) : null
        }
      />
      <div className={styles.bottom} data-test="company-widget-tab-row">
        <FinancialData data={totalCost} currency={currency} />
        <FinancialData data={valuation} currency={currency} />
        <FinancialData data={irr!} />
      </div>
    </div>
  );
};

export default ActiveCompany;
