import {createAction, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {
  ScenarioReportInvestmentData,
  ScenarioReportPortfolioSummaryData,
} from '../types/scenarioOnePageReport';

export const getScenarioOnePageReportPortfolioPerformanceDataAction =
  createAction(
    'scenario-one-page-report-portfolio-performance/get-scenario-portfolio-performance-data',
    (payload: {id: string}) => {
      return {
        payload,
      };
    }
  );

interface ScenarioOnePageReportPortfolioPerformanceData {
  portfolioSummary: {
    reportedData: ScenarioReportPortfolioSummaryData | null;
    nearCastingData: ScenarioReportPortfolioSummaryData | null;
  } | null;
  investmentFocus: ScenarioReportInvestmentData[] | null;
  isLoading: boolean;
}

const initialState: ScenarioOnePageReportPortfolioPerformanceData = {
  portfolioSummary: null,
  investmentFocus: null,
  isLoading: false,
};

const scenarioOnePageReportPerformanceSlice = createSlice({
  name: 'scenario-one-page-report-portfolio-performance',
  initialState,
  reducers: {
    setScenarioOnePageReportPortfolioPerformanceNearCastingData(
      state: ScenarioOnePageReportPortfolioPerformanceData,
      action: PayloadAction<{
        portfolioSummary: ScenarioReportPortfolioSummaryData | null;
        investmentFocus: ScenarioReportInvestmentData[] | null;
      }>
    ) {
      state.portfolioSummary = {
        ...state.portfolioSummary,
        reportedData: state.portfolioSummary?.reportedData || null,
        nearCastingData: action.payload.portfolioSummary,
      };
      state.investmentFocus = action.payload.investmentFocus;
    },
    setScenarioOnePageReportPortfolioPerformanceReportedData(
      state: ScenarioOnePageReportPortfolioPerformanceData,
      action: PayloadAction<{
        portfolioSummary: ScenarioReportPortfolioSummaryData | null;
      }>
    ) {
      state.portfolioSummary = {
        ...state.portfolioSummary,
        nearCastingData: state.portfolioSummary?.nearCastingData || null,
        reportedData: action.payload.portfolioSummary,
      };
    },
    clearScenarioOnePageReportPortfolioPerformanceData(
      state: ScenarioOnePageReportPortfolioPerformanceData
    ) {
      state.portfolioSummary = null;
      state.investmentFocus = null;
    },
    setLoading(state: ScenarioOnePageReportPortfolioPerformanceData) {
      state.isLoading = !state.isLoading;
    },
  },
});

export const {
  setScenarioOnePageReportPortfolioPerformanceNearCastingData,
  setScenarioOnePageReportPortfolioPerformanceReportedData,
  setLoading,
  clearScenarioOnePageReportPortfolioPerformanceData,
} = scenarioOnePageReportPerformanceSlice.actions;

export default scenarioOnePageReportPerformanceSlice.reducer;
