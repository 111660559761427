import React from 'react';
import {
  KpiTabs,
  SummaryWidgetChartProps,
  NavData,
  StyleVariant,
} from '../../../../../types';
import DeltaIndicator from '../fund-widget-header/components/delta-indicator/DeltaIndicator';
import {ValueFormat} from '../../../../global';
import {useLocation} from 'react-router-dom';
import homeLayoutStyles from './FundTabsRow.module.scss';
import pageLayoutStyles from './FundTabsRowTemp.module.scss';
import classnames from 'classnames';

export interface FundTabsRowProps {
  clickHandler?: (id: KpiTabs) => void;
  activeTab?: KpiTabs;
  nav?: SummaryWidgetChartProps | NavData | null;
  netIrr?: SummaryWidgetChartProps | null;
  dpiRvpiTvpi?: SummaryWidgetChartProps | null;
  netIrrGrossIrrMOIC?: SummaryWidgetChartProps | null;
  piccDpiRvpiTvpi?: SummaryWidgetChartProps | null;
  currency?: string;
  isXSmall?: boolean;
  isSmall?: boolean;
  className?: string;
  variant?: StyleVariant;
}

const FundTabsRow: React.FC<FundTabsRowProps> = ({
  clickHandler,
  activeTab,
  nav,
  netIrr,
  dpiRvpiTvpi,
  netIrrGrossIrrMOIC,
  piccDpiRvpiTvpi,
  currency,
  isXSmall,
  isSmall,
  className,
  variant = StyleVariant.Primary,
}): JSX.Element => {
  const {pathname} = useLocation();
  const styles = pathname === '/' ? homeLayoutStyles : pageLayoutStyles;

  const handleClickTab = (tab: KpiTabs) => () => {
    if (clickHandler) {
      clickHandler(tab);
    }
  };
  const {dpiData, rvpiData, tvpiData} = dpiRvpiTvpi || {};
  const {grossIrrData, netIrrData, moicData} = netIrrGrossIrrMOIC || {};
  const {
    piccData,
    dpiData: piccDpiData,
    rvpiData: piccRvpiData,
    tvpiData: piccTvpiData,
  } = piccDpiRvpiTvpi || {};

  const dpiRvpiTvpiData = [dpiData, rvpiData, tvpiData].filter(
    val => val?.value
  );
  const netIrrGrossIrrMOICData = [netIrrData, grossIrrData, moicData].filter(
    val => val?.value
  );

  const piccDpiRvpiTvpiData = [
    piccData,
    piccDpiData,
    piccRvpiData,
    piccTvpiData,
  ].filter(val => val?.value);

  return (
    <div
      className={classnames(
        styles.tabsWrapper,
        styles[`tabsWrapper__${variant}`],
        className
      )}
      data-test="fund-summary-widget-tab-row"
    >
      <div
        onClick={handleClickTab(KpiTabs.Nav)}
        className={classnames(
          styles.tab,
          activeTab === KpiTabs.Nav && styles.activeTab
        )}
        data-test="fund-summary-widget-nav-tab"
        aria-selected={activeTab === KpiTabs.Nav}
      >
        <div>
          <div className={styles.tabNameWrapper}>
            <p className={styles.tabName}>{nav?.label}</p>
            {activeTab === KpiTabs.Nav &&
              (isXSmall || isSmall) &&
              nav?.deltaIndicator && (
                <DeltaIndicator {...nav?.deltaIndicator} />
              )}
          </div>
          <div className={styles.tabNameValueWrapper}>
            <div className={styles.tabValue}>
              <span>{currency ? currency : nav!.currency} </span>
              <ValueFormat value={nav?.value} format={nav?.format} />
            </div>
            {activeTab === KpiTabs.Nav &&
              !isXSmall &&
              !isSmall &&
              nav?.deltaIndicator && (
                <DeltaIndicator {...nav?.deltaIndicator} />
              )}
          </div>
        </div>
      </div>
      <div
        onClick={handleClickTab(KpiTabs.NetIrr)}
        className={classnames(
          styles.tab,
          activeTab === KpiTabs.NetIrr && styles.activeTab
        )}
        data-test="fund-summary-widget-net-irr-tab"
        aria-selected={activeTab === KpiTabs.NetIrr}
      >
        <div>
          {netIrrGrossIrrMOIC && (
            <div className={styles.row}>
              {netIrrGrossIrrMOICData.map((item, i) => (
                <div className={styles.kpiWrapper} key={i}>
                  <p className={styles.tabName}>{item?.label}</p>
                  <div className={styles.tabValue}>
                    <ValueFormat value={item?.value} format={item?.format} />
                  </div>
                </div>
              ))}
              {activeTab === KpiTabs.NetIrr &&
                !isXSmall &&
                !isSmall &&
                netIrrGrossIrrMOIC?.deltaIndicator && (
                  <DeltaIndicator {...netIrrGrossIrrMOIC?.deltaIndicator} />
                )}
            </div>
          )}
          {netIrr && (
            <div>
              <div className={styles.tabNameWrapper}>
                <p className={styles.tabName}>{netIrr?.label} </p>
                {activeTab === KpiTabs.NetIrr &&
                  (isXSmall || isSmall) &&
                  netIrr?.deltaIndicator && (
                    <DeltaIndicator {...netIrr?.deltaIndicator} />
                  )}
              </div>
              <div className={styles.tabNameValueWrapper}>
                <div className={styles.tabValue}>
                  <ValueFormat value={netIrr?.value} format={netIrr?.format} />
                </div>
                {activeTab === KpiTabs.NetIrr &&
                  !isXSmall &&
                  !isSmall &&
                  netIrr?.deltaIndicator && (
                    <DeltaIndicator {...netIrr?.deltaIndicator} />
                  )}
              </div>
            </div>
          )}
        </div>
      </div>
      <div
        onClick={handleClickTab(KpiTabs.DpiRvpi)}
        className={classnames(
          styles.tab,
          activeTab === KpiTabs.DpiRvpi && styles.activeTab
        )}
        data-test="fund-summary-widget-rvpi-tvpi-tab"
        aria-selected={activeTab === KpiTabs.DpiRvpi}
      >
        <div className={styles.row}>
          {dpiRvpiTvpiData.map((item, i) => (
            <div className={styles.kpiWrapper} key={i}>
              <p className={styles.tabName}>{item?.label}</p>
              <div className={styles.tabValue}>
                <ValueFormat value={item?.value} format={item?.format} />
              </div>
            </div>
          ))}
          {piccDpiRvpiTvpiData.map((item, i) => (
            <div className={styles.kpiWrapper} key={i}>
              <p className={styles.tabName}>{item?.label}</p>
              <div className={styles.tabValue}>
                <ValueFormat value={item?.value} format={item?.format} />
              </div>
            </div>
          ))}
          {activeTab === KpiTabs.DpiRvpi &&
            !isXSmall &&
            (dpiRvpiTvpi?.deltaIndicator ||
              piccDpiRvpiTvpi?.deltaIndicator) && (
              <DeltaIndicator
                {...(piccDpiRvpiTvpi
                  ? piccDpiRvpiTvpi?.deltaIndicator
                  : dpiRvpiTvpi?.deltaIndicator)}
              />
            )}
        </div>
      </div>
    </div>
  );
};

export default FundTabsRow;
