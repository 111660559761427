import React from 'react';
import styles from './FilePreview.module.scss';
import classnames from 'classnames';
import {Func} from '../../../types';
import {ButtonSpinner} from '../index';

interface FilePreviewProps {
  className?: string;
  filePreviewData: string;
  handleOnFilePreviewClick?: Func<[], void>;
  isFilePreviewDataLoading: boolean;
}

const FilePreview: React.FC<FilePreviewProps> = ({
  filePreviewData,
  className,
  handleOnFilePreviewClick,
  isFilePreviewDataLoading,
}) => {
  return (
    <div
      className={classnames(
        styles.wrapper,
        handleOnFilePreviewClick && styles.isClickable,
        isFilePreviewDataLoading && styles.isLoading,
        className
      )}
      onClick={handleOnFilePreviewClick}
    >
      <img
        className={styles.filePreview}
        src={`data:image/png;base64,${filePreviewData}`}
        alt="File preview"
      />

      {isFilePreviewDataLoading ? (
        <div className={styles.spinnerWrapper}>
          <ButtonSpinner className={styles.spinner} />
        </div>
      ) : null}
    </div>
  );
};

export default FilePreview;
