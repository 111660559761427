import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useFormState} from '../../../hooks';
import {Button, Input, SelectInput} from '../../global';
import styles from './TransactionForm.module.scss';
import cs from 'classnames';
import {valueFormat} from '../../../utils';
import {transactionFormSchema, createDynamicSchema} from '../../../constants';
import {
  ButtonStyle,
  FormStatus,
  SelectOption,
  StyleVariant,
} from '../../../types';
import {DatePickerDropdown} from '../../global';
import {useTranslation} from 'react-i18next';

const getDateLabel = (date: Date) => {
  if (!date) {
    return '';
  }

  return valueFormat(date.toString(), 'dd-MM-yyyy').value.toString();
};

export const transactionTypeSelectOptions: SelectOption[] = [
  {
    id: 'buy',
    value: 'buy',
    label: 'Buy',
  },
  {
    id: 'sell',
    value: 'sell',
    label: 'Sell',
  },
  {
    id: 'income',
    value: 'income',
    label: 'Income',
  },
];

export const instrumentSelectOptions: SelectOption[] = [
  {
    id: 'debt',
    value: 'debt',
    label: 'Debt',
  },
  {
    id: 'equity',
    value: 'equity',
    label: 'Equity',
  },
  {
    id: 'other',
    value: 'other',
    label: 'Other',
  },
];

type Instrument = 'debt' | 'equity' | 'other';

export const getInstrumentLabel = (value: Instrument): string | undefined => {
  return instrumentSelectOptions
    .find(option => {
      return option.value.toString() === value;
    })
    ?.label.toString();
};

export interface FormDataProps {
  id?: string;
  instrument: string;
  date: string;
  transactionType: string;
  invested: string;
  shares: string;
  realisedGains: string;
  investmentIncome: string;
  comment: string;
}

interface TransactionFormProps {
  minDate: Date;
  maxDate: Date;
  onSubmit: (formState: FormDataProps) => void;
  onCancel: () => void;
  data: any;
  formStatus: FormStatus;
}

const TransactionForm = ({
  onSubmit,
  data,
  onCancel,
  minDate,
  maxDate,
  formStatus,
}: TransactionFormProps) => {
  const {t} = useTranslation();
  const transactionInitialState = useMemo(() => {
    let transactionType = data.transactionType ?? 'buy';
    if (data.invested && data.shares && data.invested < 0 && data.shares < 0) {
      transactionType = 'sell';
    }

    return {
      id: data.id,
      instrument: data.instrument ?? '',
      date: data.date ? new Date(data.date) : '',
      transactionType,
      invested: data.invested?.toString() ?? '',
      shares: data.shares ? data.shares : '',
      realisedGains: data.realisedGains?.toString() ?? '',
      investmentIncome: data.investmentIncome?.toString() ?? '',
      comment: data.comment ?? '',
    };
  }, [
    data.comment,
    data.date,
    data.instrument,
    data.shares,
    data.transactionType,
    data.id,
    data.invested,
    data.investmentIncome,
    data.realisedGains,
  ]);

  const {
    formState,
    validate,
    errors,
    handleChange,
    setCurrentSchema,
    setFormState,
  } = useFormState<any>(transactionInitialState, transactionFormSchema);

  const [isInitialEdited, setIsInitialEdited] = useState(false);

  useEffect(() => {
    // Perform dynamic validation based on current state
    if (formState.transactionType) {
      const isModifiedEdit =
        formState.transactionType !== transactionInitialState.transactionType &&
        !!formState.id;
      const dynamicSchema = createDynamicSchema(formState.transactionType);
      setFormState({
        ...formState,
        invested:
          isModifiedEdit || isInitialEdited
            ? ''
            : transactionInitialState.invested,
        shares:
          isModifiedEdit || isInitialEdited
            ? ''
            : transactionInitialState.shares,
        realisedGains:
          isModifiedEdit || isInitialEdited
            ? ''
            : transactionInitialState.realisedGains,
        investmentIncome:
          isModifiedEdit || isInitialEdited
            ? ''
            : transactionInitialState.investmentIncome,
      });
      setCurrentSchema(dynamicSchema);
      if (isModifiedEdit) {
        setIsInitialEdited(true);
      }
    }
  }, [formState.transactionType]);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const isValid = validate(formState);
    if (isValid) {
      onSubmit(formState);
    }
  };

  const onChange = useCallback(
    (e: any) => {
      // Reset invalid state on any input change
      handleChange(e);
    },
    [handleChange]
  );

  const handleDateChange = (date?: Date) => {
    if (date) {
      setFormState({
        ...formState,
        date,
      });
    }
  };

  const handleKeyDown = (e: any, name: string) => {
    let allowedChars = '';
    if (
      (name === 'invested' && formState.transactionType === 'buy') ||
      name === 'investmentIncome'
    ) {
      allowedChars = '.0123456789';
    }

    if (
      (name === 'invested' && formState.transactionType !== 'buy') ||
      name === 'realisedGains'
    ) {
      allowedChars = '.0123456789-';
    }

    if (name === 'shares' && formState.transactionType === 'sell') {
      allowedChars = '-0123456789';
    }

    if (name === 'shares' && formState.transactionType !== 'sell') {
      allowedChars = '0123456789';
    }

    const allowedControlChars = [
      'Backspace',
      'Delete',
      'Tab',
      'Enter',
      'Escape',
      'ArrowLeft',
      'ArrowRight',
    ];

    if (allowedChars.includes(e.key) || allowedControlChars.includes(e.key)) {
      setFormState({
        ...formState,
        [name]: e.target.value,
      });
    } else {
      e.preventDefault();
    }
  };

  const handleNegativeValueSet = (e: any, name: string) => {
    if (!e.target.value) {
      setFormState({
        ...formState,
        [name]: '',
      });
    } else if (formState.transactionType === 'sell') {
      const investedValue =
        +e.target.value > 0 ? +e.target.value * -1 : +e.target.value * 1;
      setFormState({
        ...formState,
        [name]: investedValue.toString(),
      });
    } else {
      setFormState({
        ...formState,
        [name]: (+e.target.value).toString(),
      });
    }
  };

  const isDisabledShares =
    !formState.transactionType ||
    (formState.transactionType && formState.transactionType === 'income');
  const isDisabledGain =
    !formState.transactionType ||
    (formState.transactionType && formState.transactionType !== 'sell');
  const isDisabledInvested =
    !formState.transactionType ||
    (formState.transactionType && formState.transactionType !== 'income');

  const mandatoryFieldsFilled =
    !!formState?.date &&
    !!formState?.instrument &&
    !!formState?.transactionType;

  const formHasErrors = Object.values(errors)?.some(({message}) => !!message);

  const isFormDisabled =
    formHasErrors ||
    !mandatoryFieldsFilled ||
    formStatus === FormStatus.Pending;

  return (
    <form className={styles.formWrapper}>
      <div className={styles.header}>
        {formState.id ? 'Edit transaction' : 'Add a new transaction'}
      </div>
      <div className={styles.formInputRowWrapper}>
        <div className={styles.formInputRow}>
          <div className={styles.formInputWrapper}>
            <SelectInput
              name="transactionType"
              type="text"
              placeholder={t('Transactions.SelectInput.Placeholder')}
              label={t('Transactions.SelectInput.Label')}
              variant={StyleVariant.Secondary}
              value={formState.transactionType}
              options={transactionTypeSelectOptions}
              errorMessage={t(errors.transactionType.message || '')}
              onChange={onChange}
              autoComplete="off"
              validate={validate}
              required
              disabled={false}
              style={{appearance: 'none'}} // TODO move to SelectInput styles
            />
          </div>
          <div className={styles.formInputWrapper}>
            <div className={styles.formInput}>
              <div className={styles.formInputLabel}>
                <span>{t('Global.Date')}</span>
                <span className={styles.asterisk}>*</span>
              </div>
              <DatePickerDropdown
                className={styles.dateInput}
                label={getDateLabel(formState.date)}
                value={formState.date}
                setValue={handleDateChange}
                availableRange={{from: minDate, to: maxDate}}
              />
            </div>
          </div>
          <div className={styles.formInputWrapper}>
            <SelectInput
              name="instrument"
              type="text"
              placeholder={t('TransactionForm.Placeholder.Instrument')}
              label={t('Global.InstrumentType')}
              variant={StyleVariant.Secondary}
              value={formState.instrument}
              options={instrumentSelectOptions}
              onChange={onChange}
              validate={validate}
              autoComplete="off"
              disabled={false}
              errorMessage={t(errors.instrument.message || '')}
              required
              style={{appearance: 'none'}} // TODO move to SelectInput styles
            />
          </div>
          <div className={styles.formInputWrapper}>
            <Input
              type="number"
              name="invested"
              placeholder={t('Global.EnterAmount')}
              value={formState.invested}
              onChange={e => handleNegativeValueSet(e, 'invested')}
              onKeyDown={e => handleKeyDown(e, 'invested')}
              className={styles.formInput}
              disabled={!formState.transactionType}
              label={t('Global.Amount')}
              validate={validate}
              errorMessage={t(errors.invested.message || '')}
              required={false}
            />
          </div>
          <div className={styles.formInputWrapper}>
            <Input
              disabled={isDisabledShares}
              onKeyDown={e => handleKeyDown(e, 'shares')}
              type="number"
              name="shares"
              value={formState.shares}
              onChange={e => handleNegativeValueSet(e, 'shares')}
              className={cs({
                [styles.formInput]: true,
                [styles.disabledFormInput]: isDisabledShares,
              })}
              placeholder={t('TransactionForm.Placeholder.Shares')}
              label={t('Global.Shares')}
              validate={validate}
              required={false}
              errorMessage={t(
                (!isDisabledShares && errors.shares?.message) || ''
              )}
            />
          </div>
          <div className={styles.formInputWrapper}>
            <Input
              disabled={isDisabledGain}
              onKeyDown={e => handleKeyDown(e, 'realisedGains')}
              type="number"
              name="realisedGains"
              value={formState.realisedGains}
              onChange={handleChange}
              placeholder={t('TransactionForm.Placeholder.Realized')}
              className={cs({
                [styles.formInput]: true,
                [styles.disabledFormInput]: isDisabledGain,
              })}
              label={t('TransactionForm.InputLabel.Realized')}
              validate={validate}
              errorMessage={t(
                (!isDisabledGain && errors.realisedGains?.message) || ''
              )}
              required={false}
            />
          </div>
          <div className={styles.formInputWrapper}>
            <Input
              type="number"
              disabled={isDisabledInvested}
              name="investmentIncome"
              value={formState.investmentIncome}
              placeholder={t('TransactionForm.Placeholder.Investment')}
              onChange={handleChange}
              onKeyDown={e => handleKeyDown(e, 'investmentIncome')}
              className={cs({
                [styles.formInput]: true,
                [styles.disabledFormInput]: isDisabledInvested,
              })}
              label={t('TransactionForm.InputLabel.Investment')}
              validate={validate}
              errorMessage={t(
                (!isDisabledInvested && errors.investmentIncome?.message) || ''
              )}
              required={false}
            />
          </div>
          <div className={styles.formInputWrapper}>
            <Input
              type="text"
              name="comment"
              value={formState.comment}
              onChange={handleChange}
              className={styles.formInput}
              placeholder={t('Global.AddComment')}
              label={t('Global.Comment')}
              validate={validate}
              errorMessage={t(errors.comment.message || '')}
            />
          </div>
        </div>
      </div>
      <div className={styles.buttonsWrapper}>
        <Button
          onClick={onCancel}
          styleType={ButtonStyle.Secondary}
          disabled={formStatus === FormStatus.Pending}
          text={t('Global.Cancel')}
        />
        <Button
          onClick={handleSubmit}
          styleType={ButtonStyle.Primary}
          text={t(formState.id ? 'Global.Done' : 'Global.Add')}
          loading={formStatus === FormStatus.Pending}
          disabled={isFormDisabled}
        />
      </div>
    </form>
  );
};

export default TransactionForm;
