import React, {useMemo, useRef, useState} from 'react';
import styles from './Questionnaire.module.scss';
import {
  ButtonStyle,
  Func,
  QuestionnaireInputTypes,
  ReportingTableCell,
  ReportingTableData,
  ReportingTableRow,
  ReportingTableRowTypes,
  StyleVariant,
} from '../../../types';
import {
  ActivityBadge,
  Button,
  IconButton,
  MaskedNumberInput,
  MultiSelect,
  SelectInput,
  TextArea,
} from '../';
import QuestionnaireGroupHeaderRow from './components/questionnaire-group-header-row/QuestionnaireGroupHeaderRow';
import QuestionnaireGroupSubHeaderRow from './components/questionnaire-group-sub-header-row/QuestionnaireGroupSubHeaderRow';
import QuestionnaireTopicRow from './components/questionnaire-topic-row/QuestionnaireTopicRow';
import {formatWithLocale, valueFormat} from '../../../utils';
import {useTranslation} from 'react-i18next';

type QuestionnaireProps = {
  isHistorical: boolean;
  reportingData: ReportingTableData;
  onInputSubmit: Func<[ReportingTableCell], void>;
  onQuestionnaireSubmit: Func<[columnId: string], void>;
};

const Questionnaire = ({
  isHistorical,
  reportingData,
  onInputSubmit,
  onQuestionnaireSubmit,
}: QuestionnaireProps) => {
  const questionnaireInputClassName = 'questionnaire-input';

  const {t} = useTranslation();
  const [currentColIdx, setCurrentColIdx] = useState<number>(
    reportingData.headerLength
  );
  const [questionnaireInputErrors, setQuestionnaireInputErrors] = useState<
    Map<string, string>
  >(new Map());

  const questionnaireRef = useRef<HTMLDivElement>(null);

  const firstColIdx = useMemo<number>(
    () => reportingData.headerLength,
    [reportingData.headerLength]
  );

  const lastColIdx = useMemo<number>(
    () => reportingData.headerLength + reportingData.rowLength - 1,
    [reportingData.headerLength, reportingData.rowLength]
  );

  const dueDate = useMemo(() => {
    const cell = reportingData.statusRows.find(
      row => row.type === ReportingTableRowTypes.StatusPeriodDateRow
    )?.cells[currentColIdx];

    return cell?.data?.value
      ? valueFormat(cell.data.value, cell.data.format).value
      : '';
  }, [reportingData.statusRows, currentColIdx]);

  const submissionDate = useMemo(() => {
    const cell = reportingData.statusRows.find(
      row => row.type === ReportingTableRowTypes.StatusSubmissionDateRow
    )?.cells[currentColIdx];

    const validDate = cell?.data?.value.split(' ').slice(-1);

    return cell?.data?.value
      ? formatWithLocale(new Date(validDate), cell.data.format)
      : '';
  }, [reportingData.statusRows, currentColIdx]);

  const currentColumn = useMemo<{
    id: string;
    disabled: boolean;
    submitted: boolean;
  }>(() => {
    const statusCell = reportingData.statusRows.find(
      row => row.type === ReportingTableRowTypes.StatusHeaderRow
    )?.cells[currentColIdx];

    return !!statusCell
      ? {
          id: statusCell.columnId,
          disabled: !!statusCell.disabled,
          submitted: !!statusCell.data?.submitted,
        }
      : {id: '', disabled: false, submitted: false};
  }, [currentColIdx, reportingData.statusRows]);

  const onPrevious = () => {
    // Reset questionnaireInputErrors on pagination
    if (questionnaireInputErrors.size > 0)
      setQuestionnaireInputErrors(new Map());

    setCurrentColIdx((prev: number) => {
      if (prev <= firstColIdx) return prev;
      return prev - 1;
    });
  };

  const onNext = () => {
    // Reset questionnaireInputErrors on pagination
    if (questionnaireInputErrors.size > 0)
      setQuestionnaireInputErrors(new Map());

    setCurrentColIdx((prev: number) => {
      if (prev >= lastColIdx) return prev;
      return prev + 1;
    });
  };

  const handleInputSubmit = (cell: ReportingTableCell, value: any) => {
    // Remove cell id from the questionnaireInputErrors after we start entering data
    if (questionnaireInputErrors.has(cell.id)) {
      const newErrors = new Map(questionnaireInputErrors);
      newErrors.delete(cell.id);
      setQuestionnaireInputErrors(newErrors);
    }

    onInputSubmit({
      ...cell,
      data: {
        ...cell.data,
        value,
      },
    } as ReportingTableCell);
  };

  const validateMandatoryInputs = (currentColumnId: string): boolean => {
    const inputsWithErrors: Map<string, string> = new Map();
    reportingData.dataRows.forEach((row: ReportingTableRow) => {
      let cell: ReportingTableCell | undefined;
      if (row.type === ReportingTableRowTypes.GroupRow)
        cell = row.cells.find(
          (cell: ReportingTableCell) => cell.columnId === currentColumnId
        );

      // Collect all mandatory cells without values
      if (!!cell && cell?.data?.mandatory && !cell?.data?.value)
        inputsWithErrors.set(cell.id, 'This field is Mandatory.');
    });

    if (inputsWithErrors.size > 0 && !!questionnaireRef.current) {
      const firstInputWithError: HTMLElement | null =
        // Get the first input that has an error
        questionnaireRef.current.querySelector(
          `.${questionnaireInputClassName}-${
            inputsWithErrors.keys().next().value
          }`
        );

      if (!!firstInputWithError) {
        // Scroll to input that has error and focus it
        firstInputWithError.scrollIntoView({
          block: 'center',
          behavior: 'smooth',
        });
        firstInputWithError.focus({preventScroll: true});
      }

      setQuestionnaireInputErrors(inputsWithErrors);
      return false;
    }

    return true;
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.pagination}>
        <span className={styles.paginationYearText}>{dueDate}</span>
        <div className={styles.paginationSection}>
          <span className={styles.paginationSectionText}>
            {reportingData.rowLength} {t('Global.periods')}
          </span>
          <IconButton
            styleType={ButtonStyle.Primary}
            onClick={onPrevious}
            icon={'chevron-left'}
            disabled={currentColIdx === firstColIdx}
          />
          <IconButton
            styleType={ButtonStyle.Primary}
            onClick={onNext}
            icon={'chevron-right'}
            disabled={currentColIdx === lastColIdx}
          />
        </div>
        <div className={styles.paginationSection}>
          <span className={styles.paginationSectionText}>
            {t('Global.Due')} {submissionDate}
          </span>
          {currentColumn.submitted ? (
            <ActivityBadge
              label={t('Global.Submitted')}
              color="lan-green"
              textColor="black"
              fontSize={14}
              fontWeight={400}
            />
          ) : (
            <Button
              text={t('Global.Submit')}
              onClick={() => {
                if (questionnaireInputErrors.size > 0)
                  setQuestionnaireInputErrors(new Map());

                if (validateMandatoryInputs(currentColumn.id))
                  onQuestionnaireSubmit(currentColumn.id);
              }}
              disabled={
                isHistorical ||
                currentColumn.submitted ||
                currentColumn.disabled
              }
            />
          )}
        </div>
      </div>
      <div ref={questionnaireRef} className={styles.questionnaire}>
        {reportingData.dataRows.map(
          (row: ReportingTableRow, rowIdx: number) => {
            if (row.type === ReportingTableRowTypes.GroupHeaderRow) {
              const groupHeaderLabel = row.cells[0]?.data?.value;
              return (
                <QuestionnaireGroupHeaderRow
                  key={`QuestionnaireGroupHeaderRow-${row.id}-${rowIdx}`}
                  name={groupHeaderLabel}
                  info={
                    rowIdx === 0 ? (
                      <span>
                        Questions marked with{' '}
                        <span className={styles.mandatoryMark}>*</span> are
                        mandatory.
                      </span>
                    ) : (
                      <></>
                    )
                  }
                />
              );
            }

            if (row.type === ReportingTableRowTypes.GroupSubHeaderRow) {
              const groupSubHeaderName = row.cells[0].data?.value;
              return (
                <QuestionnaireGroupSubHeaderRow
                  key={`QuestionnaireGroupSubHeaderRow-${row.id}-${rowIdx}`}
                  name={groupSubHeaderName}
                />
              );
            }

            if (row.type === ReportingTableRowTypes.GroupRow) {
              const question = row.cells[1].data?.value;
              const mandatory = row.cells[2].data?.value;
              const unitLabel = row.cells[4].data?.value;
              const answers = row.questionnaire?.answers ?? [];
              const groupRowCell = row.cells[currentColIdx];
              const numberInputMaskType =
                groupRowCell.type === QuestionnaireInputTypes.PercentageInput
                  ? 'percent'
                  : groupRowCell.type === QuestionnaireInputTypes.CurrencyInput
                  ? 'currency'
                  : 'number';

              return (
                <QuestionnaireTopicRow
                  key={`QuestionnaireTopicRow-${row.id}-${rowIdx}`}
                  name={row.cells[0].data?.value}
                  description={row.cells[0].data?.description}
                  questions={[
                    {
                      question,
                      mandatory,
                      answer:
                        groupRowCell.type ===
                        QuestionnaireInputTypes.MultiSelectInput ? (
                          <MultiSelect
                            className={`${questionnaireInputClassName}-${groupRowCell.id}`}
                            variant={StyleVariant.Secondary}
                            key={`MultiSelect-${groupRowCell.id}`}
                            label={t('Global.Options')}
                            placeholder={t('Global.ChooseOptions')}
                            options={answers}
                            withSearch={true}
                            onChange={event => {
                              handleInputSubmit(
                                groupRowCell,
                                event.target.value
                              );
                            }}
                            value={groupRowCell.data?.value ?? []}
                            errorMessage={
                              questionnaireInputErrors.get(groupRowCell.id) ??
                              ''
                            }
                            disabled={
                              isHistorical || !!groupRowCell.data?.submitted
                            }
                            controllable={false}
                          />
                        ) : groupRowCell.type ===
                          QuestionnaireInputTypes.SingleSelectInput ? (
                          <SelectInput
                            className={`${questionnaireInputClassName}-${groupRowCell.id}`}
                            variant={StyleVariant.Secondary}
                            key={`SelectInput-${groupRowCell.id}`}
                            label={t('Global.Options')}
                            placeholder={t('Global.ChooseOptions')}
                            options={answers}
                            onChange={event => {
                              handleInputSubmit(
                                groupRowCell,
                                event.target.value
                              );
                            }}
                            value={groupRowCell.data?.value}
                            errorMessage={
                              questionnaireInputErrors.get(groupRowCell.id) ??
                              ''
                            }
                            disabled={
                              isHistorical || !!groupRowCell.data?.submitted
                            }
                            controllable={false}
                          />
                        ) : groupRowCell.type ===
                          QuestionnaireInputTypes.TextInput ? (
                          <TextArea
                            className={`${questionnaireInputClassName}-${groupRowCell.id}`}
                            id={`TextArea-${groupRowCell.id}`}
                            key={`TextArea-${groupRowCell.id}`}
                            variant={StyleVariant.Secondary}
                            label={t('Global.Text')}
                            defaultValue={groupRowCell.data?.value}
                            errorMessage={
                              questionnaireInputErrors.get(groupRowCell.id) ??
                              ''
                            }
                            showErrorMessage={questionnaireInputErrors.has(
                              groupRowCell.id
                            )}
                            validate={() => true}
                            placeholder={''}
                            onChange={event => {
                              handleInputSubmit(
                                groupRowCell,
                                event.target.value
                              );
                            }}
                            disabled={
                              isHistorical || !!groupRowCell.data?.submitted
                            }
                          />
                        ) : groupRowCell.type ===
                            QuestionnaireInputTypes.NumberInput ||
                          groupRowCell.type ===
                            QuestionnaireInputTypes.CurrencyInput ||
                          groupRowCell.type ===
                            QuestionnaireInputTypes.PercentageInput ? (
                          <MaskedNumberInput
                            className={`${questionnaireInputClassName}-${groupRowCell.id}`}
                            variant={StyleVariant.Secondary}
                            key={`MaskedNumberInput-${numberInputMaskType}-${row.cells[currentColIdx].id}`}
                            label={t('Global.Number')}
                            suffix={unitLabel}
                            maskType={numberInputMaskType}
                            errorMessage={
                              questionnaireInputErrors.get(groupRowCell.id) ??
                              ''
                            }
                            showErrorMessage={questionnaireInputErrors.has(
                              groupRowCell.id
                            )}
                            validate={() => true}
                            initialValue={groupRowCell.data?.value}
                            onChange={event => {
                              handleInputSubmit(
                                groupRowCell,
                                event.target.value
                              );
                            }}
                            disabled={
                              isHistorical || !!groupRowCell.data?.submitted
                            }
                          />
                        ) : (
                          <></>
                        ),
                    },
                  ]}
                />
              );
            }

            return null;
          }
        )}
      </div>
    </div>
  );
};

export default Questionnaire;
