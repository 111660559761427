import {createSelector} from '@reduxjs/toolkit';
import {selectClientDashboard} from '../reducers/clientDashboardSlice';
import {selectCompanies} from '../reducers/companiesSlice';
import {selectFunds} from '../reducers/fundsSlice';
import {RootState} from '../store';

const fundDetails = (state: RootState) => state.fundDetails;
const companyDetails = (state: RootState) => state.companyDetails;

const navInfoSelector = createSelector(
  [
    selectClientDashboard,
    selectCompanies,
    selectFunds,
    fundDetails,
    companyDetails,
  ],
  (clientDashboard, companies, funds, fundDetails, companyDetails) => {
    const fundDetailsData =
      fundDetails.fundOverview.data ||
      fundDetails.fundOperational.data ||
      fundDetails.fundStructure.data ||
      fundDetails.fundPortfolio.data ||
      fundDetails.fundNearCasting.data ||
      fundDetails.fundPortfolioTransactions.data;
    const {fundName, asAt: asAtFund, fundColor} = fundDetailsData || {};

    const companyDetailsData =
      companyDetails.company?.data ||
      companyDetails.companyInvestmentOverview?.data ||
      companyDetails.companyMonitoring?.data;

    const {
      companyName,
      asAt: asAtCompany,
      fundColor: companyColor,
    } = companyDetailsData || {};
    return {
      clientDashboard,
      companies,
      funds,
      fundName,
      asAtFund,
      fundColor,
      companyColor,
      companyName,
      asAtCompany,
    };
  }
);

export default navInfoSelector;
