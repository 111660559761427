import React, {useMemo, useState} from 'react';
import {SectionHeader} from '../../dashboard';
import styles from './FundPortfolioTransactionsDashboard.module.scss';
import {
  ButtonStyle,
  Func,
  PortfolioTransactionData,
  PortfolioTransactions,
  PortfolioTransactionsRow,
  SectionTag,
} from '../../../types';
import {
  genPortfolioTransactionsTableColumnDefs,
  IconButton,
  SelectDropdown,
  Toggle,
  TransactionsTable,
} from '../../global';
import {exportsWorker} from '../../../utils/export';
import {
  downloadXLSXExport,
  extractUploadDocumentsParamsFromUrl,
} from '../../../utils';
import {fundTransactionsTableSettings} from './FundTransactionsTableSettings';
import {useTranslation} from 'react-i18next';
import {useAppDispatch, useAppSelector} from '../../../hooks/useReduxHooks';
import {
  setFundPortfolioSelectedTransaction,
  setFundPortfolioSelectedTransactions,
} from '../../../reducers/fundDetailsPortfolioTransactionsSlice';
import {fundPortfolioTransactionsDefaultSorting} from '../../../constants';
import {
  getUploadDocumentsDataAction,
  setIsUploadDocumentsModalOpen,
} from '../../../reducers/uploadDocumentsSlice';
import {useNavInfo} from '../../../hooks';
import {Row} from '@tanstack/react-table';

type FundPortfolioTransactionsDashboardProps = {
  transactions: PortfolioTransactions;
  filterTransactionsData: Func<[string?], void>;
  transactionsFilteringInProgress: boolean;
  fundName: string;
  signOffDate?: string | null;
};

const ALL_COMPANIES = 'all-companies';

const FundPortfolioTransactionsDashboard: React.FC<
  FundPortfolioTransactionsDashboardProps
> = ({
  transactions,
  filterTransactionsData,
  transactionsFilteringInProgress = false,
  fundName,
  signOffDate,
}) => {
  const {selectedTransactions} = useAppSelector(
    state => state.fundDetails.fundPortfolioTransactions
  );

  const {releaseDocumentRepositoryEnabled} = useAppSelector(
    state => state.featureFlags.data
  );

  const dispatch = useAppDispatch();

  const navInfoData = useNavInfo();

  const [isTransactionCurrency, setIsTransactionCurrency] =
    useState<boolean>(false);

  const [exportInProgress, setExportInProgress] = useState(false);

  const [allCheckboxState, setAllCheckboxState] = useState<{
    checked: boolean;
    isIndeterminate: boolean;
  }>({checked: false, isIndeterminate: false});

  const [filterValue, setFilterValue] = useState('all-companies');

  const {t} = useTranslation();

  const fundTransactionsTableColumnVisibility = useMemo(
    () => ({
      isSelected: releaseDocumentRepositoryEnabled,
      file: releaseDocumentRepositoryEnabled,
      upload: releaseDocumentRepositoryEnabled,
    }),
    [releaseDocumentRepositoryEnabled]
  );

  const handleTransactionSelection = (
    checked: boolean,
    transaction?: PortfolioTransactionData
  ) => {
    let payload = [];
    if (checked) {
      payload = transaction
        ? [...selectedTransactions, transaction]
        : [...transactions?.data];
      dispatch(setFundPortfolioSelectedTransactions(payload));
    } else {
      payload = transaction
        ? selectedTransactions.filter(item => item.id !== transaction.id)
        : [];
      dispatch(setFundPortfolioSelectedTransactions(payload));
    }

    const selectedCount = payload.length;
    const totalTransactions = transactions.data.length;

    if (selectedCount === totalTransactions) {
      setAllCheckboxState({checked: true, isIndeterminate: false});
    } else if (selectedCount === 0) {
      setAllCheckboxState({checked: false, isIndeterminate: false});
    } else {
      setAllCheckboxState({checked: true, isIndeterminate: true});
    }
  };

  const handleOpenUploadDocumentsModal = (
    transaction: PortfolioTransactionData
  ) => {
    const uploadDocumentParams = extractUploadDocumentsParamsFromUrl(
      navInfoData.sectionTag
    );
    dispatch(setFundPortfolioSelectedTransaction(transaction));
    dispatch(setIsUploadDocumentsModalOpen(true));
    dispatch(
      getUploadDocumentsDataAction({
        ...uploadDocumentParams,
        sections: [
          SectionTag.FundPortfolioTransactions,
          SectionTag.PortfolioCompanyInvestmentOverview,
        ],
        transactions: [transaction.id],
        columnIds: null,
      })
    );
  };

  const handleChangeFilter = (value: string) => {
    dispatch(setFundPortfolioSelectedTransactions([]));
    setAllCheckboxState({checked: false, isIndeterminate: false});
    setIsTransactionCurrency(false);
    setFilterValue(value);
    if (value === ALL_COMPANIES) {
      filterTransactionsData();
      return;
    }
    filterTransactionsData(value);
  };

  const handleTransactionCurrencyToggle = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(setFundPortfolioSelectedTransactions([]));
    setAllCheckboxState({checked: false, isIndeterminate: false});
    setIsTransactionCurrency(event.target.checked);
  };

  const portfolioCompaniesOptions = () => {
    const companies = transactions.portfolioCompanies.map(option => {
      return {id: option.id, label: option.name, value: option.id};
    });

    return [
      {
        id: ALL_COMPANIES,
        label: t(
          'Funds.FundPortfolioTransactionsDashboard.SectionHeader.DropdownOption'
        ),
        value: ALL_COMPANIES,
      },
      ...companies,
    ];
  };

  const getZipFileName = () => {
    if (filterValue !== ALL_COMPANIES) {
      const portfolioCompany = transactions.portfolioCompanies.find(
        option => option.id === filterValue
      )?.name;

      return `${fundName.split(' ').join('')}_${
        portfolioCompany ? portfolioCompany.split(' ').join('') : ''
      }_${t('Global.Transactions')}`;
    }

    return `${fundName.split(' ').join('')}_${t('Global.Transactions')}`;
  };

  const exportFundTransactionsTable = async () => {
    setExportInProgress(true);

    const zipFileName = getZipFileName();

    const exportedData = await exportsWorker.exportToXLSX([
      {
        data: isTransactionCurrency
          ? transactions.dataLocal
          : transactions.data,
        mappings: transactions.headerMapping,
        settings: fundTransactionsTableSettings(
          zipFileName,
          fundTransactionsTableColumnVisibility
        ),
      },
    ]);

    setExportInProgress(false);

    downloadXLSXExport(exportedData);
  };

  const getRowClassNames = (row: Row<PortfolioTransactionsRow>): string => {
    if (!signOffDate || !row.original.date) return '';
    if (
      new Date(signOffDate).getTime() >=
      new Date(row.original.date.value).getTime()
    )
      return '';
    return styles.liveDataTableRow;
  };

  return (
    <div className={styles.wrapper}>
      <SectionHeader
        label={transactions.label}
        labelType={'large'}
        className={styles.sectionHeader}
        onClick={() => {}}
        withActionButton={false}
        childrenRight={[
          <div className={styles.transactionsSectionRight}>
            <SelectDropdown
              id="fund-portfolio-transactions-dashboard-select-dropdown"
              onChange={value => handleChangeFilter(value as string)}
              options={portfolioCompaniesOptions()}
              value={filterValue}
              disabled={transactionsFilteringInProgress}
              loading={transactionsFilteringInProgress}
            />
            <Toggle
              checked={isTransactionCurrency}
              label={t('Toggle.ShowInTransactionCurrency')}
              onChange={handleTransactionCurrencyToggle}
              disabled={
                !transactions.hasDataLocal || transactionsFilteringInProgress
              }
            />
            <IconButton
              onClick={exportFundTransactionsTable}
              styleType={ButtonStyle.Primary}
              icon="export"
              loading={exportInProgress}
              disabled={transactionsFilteringInProgress}
              id="fund-portfolio-transactions-export-btn"
            />
          </div>,
        ]}
      />

      <TransactionsTable<PortfolioTransactionsRow>
        data={
          isTransactionCurrency ? transactions.dataLocal : transactions.data
        }
        columns={genPortfolioTransactionsTableColumnDefs(
          transactions.headerMapping,
          isTransactionCurrency
            ? transactions.footerMappingDataLocal
            : transactions.footerMapping,
          selectedTransactions,
          allCheckboxState,
          handleTransactionSelection,
          handleOpenUploadDocumentsModal
        )}
        meta={{
          getRowClassNames,
        }}
        expandedView={() => <></>}
        getRowCanExpand={() => false}
        scrollableFromWidth={'890px'}
        defaultSorting={fundPortfolioTransactionsDefaultSorting}
        columnVisibility={fundTransactionsTableColumnVisibility}
      />
    </div>
  );
};

export default FundPortfolioTransactionsDashboard;
