import React, {useState} from 'react';
import {DropdownWrapper} from '../index';
import {
  BenchmarkControllerProps,
  ControllerOption,
  Func,
  ButtonStyle,
} from '../../../types/index';
import {Button} from '../../global';
import classnames from 'classnames';
import styles from './BenchmarkController.module.scss';
import {useTranslation} from 'react-i18next';

const BenchmarkController: React.FC<BenchmarkControllerProps> = ({
  options,
  onBenchmarkOptionChange = null,
  dropdownLabel = 'Choose parameters',
}) => {
  const [activeKpi, setActiveKpi] = useState(options[0]);
  const {t} = useTranslation();
  const [activeSource, setActiveSource] = useState(
    options[0].value[0].value.length > 0
      ? options[0].value[0].value[0]
      : options[0].value[1].value[0]
  );

  const [buttonLabel, setButtonLabel] = useState(dropdownLabel);

  const changeActiveKpi = (item: ControllerOption) => {
    setActiveKpi(item);
    setActiveSource(
      item.value[0].value.length > 0
        ? item.value[0].value[0]
        : item.value[1].value[0]
    );
  };

  const handleClickApply = (handleCloseDropdown: Func<[], void>) => () => {
    onBenchmarkOptionChange && onBenchmarkOptionChange(activeKpi, activeSource);
    setButtonLabel(`${activeKpi.label}, ${activeSource.label}`);
    handleCloseDropdown();
  };

  return (
    <DropdownWrapper label={buttonLabel}>
      {({handleCloseDropdown}) => (
        <div className={styles.dropdown}>
          <div className={styles.group}>
            <div className={styles.groupTitle}>Benchmark</div>
            {options.map((item, index) => (
              <button
                className={classnames(
                  styles.groupButton,
                  item.name === activeKpi.name && styles.active
                )}
                onClick={() => changeActiveKpi(item)}
                key={index}
              >
                {item.label}
              </button>
            ))}
          </div>
          <div className={styles.group}>
            <div className={styles.groupTitle}>Source</div>
            {activeKpi.value.map((source, index) => {
              return (
                <div key={index}>
                  {source.value.length > 0 && (
                    <div className={styles.sourceWrapper}>
                      <div className={styles.sourceTitle}>{source.label}</div>

                      {source.value.map((item, index) => {
                        return (
                          <button
                            className={classnames(
                              styles.groupButton,
                              activeSource === item && styles.active
                            )}
                            onClick={() => setActiveSource(item)}
                            key={index}
                          >
                            {item.label}
                          </button>
                        );
                      })}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
          <div className={styles.actions}>
            <Button
              onClick={handleClickApply(handleCloseDropdown)}
              styleType={ButtonStyle.Primary}
              text={t('Global.Apply')}
              className={styles.actionButton}
            />
            <Button
              onClick={handleCloseDropdown}
              text={t('Global.Cancel')}
              styleType={ButtonStyle.Secondary}
              className={styles.actionButton}
            />
          </div>
        </div>
      )}
    </DropdownWrapper>
  );
};

export default BenchmarkController;
