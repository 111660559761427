import React, {Fragment, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import {useAppDispatch} from '../../../hooks/useReduxHooks';
import {
  ActiveCompanyData,
  CompanyFinancialData,
  DataVisualisaitonChartType,
  KpiFilterOption,
  SelectOption,
} from '../../../types';
import routePaths from '../../../routePaths';
import {
  BubbleChartWidget,
  FilterSection,
  InfoPlaceholder,
  LoadingOverlay,
  MultiRangeSlider,
  SelectDropdown,
} from '../../global';
import styles from './CompaniesBubbleChartDashboard.module.scss';
import {filterCompaniesBarChartData} from '../../../utils';
import {
  getActiveCompaniesQuarterlyDataAction,
  updateChartFilter,
} from '../../../reducers/portfolioCompaniesChartDataSlice';

interface CompaniesBubbleChartDashboardProps {
  data: ActiveCompanyData[];
  filterData: KpiFilterOption[];
  bubbleChartDataRequestPending: boolean;
  areFiltersDisabled?: boolean;
}
const CompaniesBubbleChartDashboard: React.FC<
  CompaniesBubbleChartDashboardProps
> = ({
  data,
  filterData,
  bubbleChartDataRequestPending,
  areFiltersDisabled = false,
}) => {
  const {t} = useTranslation();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const filteredChartData = useMemo(
    () => filterCompaniesBarChartData(data, filterData),
    [data, filterData]
  );

  const {bubbleChartTimeSeries, advancedFilters, zAxisDomain} = useMemo(() => {
    const yAxisKey = filterData.find(item => item.name === 'yAxis');
    const xAxisKey = filterData.find(item => item.name === 'xAxis');
    const zAxisKey = filterData.find(item => item.name === 'zAxis');
    const xRangeFilter = filterData.find(item => item.name === 'xAxisRange');
    const yRangeFilter = filterData.find(item => item.name === 'yAxisRange');
    const zRangeFilter = filterData.find(item => item.name === 'zAxisRange');

    const bubbleChartTimeSeries = filteredChartData
      .map(item => {
        return {
          id: item.id,
          title: item.companyName,
          x: xAxisKey?.value
            ? (
                item[
                  xAxisKey.value as keyof ActiveCompanyData
                ] as CompanyFinancialData
              )?.value
            : null,
          y: yAxisKey?.value
            ? (
                item[
                  yAxisKey.value as keyof ActiveCompanyData
                ] as CompanyFinancialData
              )?.value
            : null,
          z: zAxisKey?.value
            ? (
                item[
                  zAxisKey.value as keyof ActiveCompanyData
                ] as CompanyFinancialData
              )?.value
            : null,
          additionalData: {
            id: item.id,
          },
        };
      })
      .filter(item => {
        const byXrange =
          xRangeFilter && item.x
            ? item.x >= (xRangeFilter.value as number[])[0] &&
              item.x <= (xRangeFilter.value as number[])[1]
            : true;
        const byYrange =
          yRangeFilter && item.y
            ? item.y >= (yRangeFilter.value as number[])[0] &&
              item.y <= (yRangeFilter.value as number[])[1]
            : true;
        const byZrange =
          zRangeFilter && item.z
            ? item.z >= (zRangeFilter.value as number[])[0] &&
              item.z <= (zRangeFilter.value as number[])[1]
            : true;
        return byXrange && byYrange && byZrange;
      });

    const advancedFilters = [
      {
        data: yAxisKey,
        range: yRangeFilter,
        label: 'Global.YAxis',
        dataName: 'yAxis',
        rangeName: 'yAxisRange',
      },
      {
        data: xAxisKey,
        range: xRangeFilter,
        label: 'Global.XAxis',
        dataName: 'xAxis',
        rangeName: 'xAxisRange',
      },
      {
        data: zAxisKey,
        range: zRangeFilter,
        label: 'Global.Radius',
        dataName: 'zAxis',
        rangeName: 'zAxisRange',
      },
    ];
    const zAxisDomain = zRangeFilter
      ? [zRangeFilter.options[0] as number, zRangeFilter.options[1] as number]
      : undefined;
    return {bubbleChartTimeSeries, advancedFilters, zAxisDomain};
  }, [filteredChartData, filterData]);

  const filterDataForSection = useMemo(
    () =>
      filterData.filter(
        item =>
          item.name !== 'yAxis' &&
          item.name !== 'yAXisRange' &&
          item.name !== 'xAxis' &&
          item.name !== 'zAxis' &&
          item.name !== 'zAxisRange' &&
          item.name !== 'xAxisRange'
      ),
    [filterData]
  );

  const handleChangeFilter = (payload: {
    name: string;
    value: string | number | string[] | number[];
  }) => {
    if (payload.name === 'asAt') {
      dispatch(
        getActiveCompaniesQuarterlyDataAction(
          payload.name,
          payload.value as string,
          DataVisualisaitonChartType.BubbleChart
        )
      );
    }
    dispatch(
      updateChartFilter({
        ...payload,
        chartType: DataVisualisaitonChartType.BubbleChart,
      })
    );
  };

  const handleClickBar = ({id}: {id: string}) => {
    history.push(`${routePaths.PORTFOLIO_COMPANIES}/${id}/investment-overview`);
  };

  const bubbleChartTooltipData = useMemo(() => {
    let options: Record<string, any> = {};
    advancedFilters.forEach((advancedFilter: any) => {
      const filterLabel = t(advancedFilter.label);
      const optionLabel =
        advancedFilter.data?.options?.find(
          (item: any) => item.value === advancedFilter.data.value
        )?.label ?? '';

      options[advancedFilter.dataName] = {
        filterLabel,
        optionLabel,
      };
    });

    return options;
  }, [advancedFilters, t]);

  const showBubbleChart = bubbleChartTimeSeries.some(
    company => company.x && company.y
  );

  return (
    <div className={styles.wrapper}>
      {!areFiltersDisabled ? (
        <Fragment>
          <div className={styles.filterWrapper}>
            <FilterSection
              config={filterDataForSection}
              handleChange={handleChangeFilter}
              isDisabled={bubbleChartDataRequestPending}
            />
          </div>
          <div className={styles.additionalFilter}>
            {advancedFilters.map(
              ({data, range, label, dataName, rangeName}, index) =>
                data && range ? (
                  <div className={styles.additionalFilterInner} key={index}>
                    <div className={styles.additionalFilterLabel}>
                      {t(label)}:
                    </div>
                    <SelectDropdown
                      id={''}
                      className={styles.select}
                      onChange={value =>
                        handleChangeFilter({name: dataName, value})
                      }
                      options={data.options as SelectOption[]}
                      value={data.value as string}
                      disabled={bubbleChartDataRequestPending}
                    />
                    <MultiRangeSlider
                      min={range.options[0] as number}
                      max={range.options[1] as number}
                      minValue={(range.value as number[])[0]}
                      maxValue={(range.value as number[])[1]}
                      step={0.01}
                      format={'auto'}
                      onChange={({max, min}) =>
                        handleChangeFilter({
                          name: rangeName,
                          value: [min, max],
                        })
                      }
                    />
                  </div>
                ) : null
            )}
          </div>
        </Fragment>
      ) : null}

      <div className={styles.overlayWrapper}>
        {showBubbleChart ? (
          <BubbleChartWidget<Pick<ActiveCompanyData, 'id'>>
            className={styles.chart}
            timeSeries={bubbleChartTimeSeries}
            yAxisLabel={bubbleChartTooltipData.yAxis.optionLabel}
            yAxisFilterLabel={bubbleChartTooltipData.yAxis.filterLabel}
            yAxisFormat={'auto'}
            xAxisLabel={bubbleChartTooltipData.xAxis.optionLabel}
            xAxisFilterLabel={bubbleChartTooltipData.xAxis.filterLabel}
            xAxisFormat={'auto'}
            onBubbleClick={handleClickBar}
            zAxisLabel={bubbleChartTooltipData.zAxis.optionLabel}
            zAxisFilterLabel={bubbleChartTooltipData.zAxis.filterLabel}
            zAxisFormat={'auto'}
            zAxisDomain={zAxisDomain}
          />
        ) : (
          <InfoPlaceholder
            icon={'info'}
            title={t(
              'PortfolioCompanies.CompaniesChartDashboard.ChartPlaceholderTitlePlural'
            )}
            subtitle={t(
              'PortfolioCompanies.CompaniesChartDashboard.ChartPlaceholderSubTitle'
            )}
          />
        )}

        <div className={styles.chartLabel}>
          {t('Global.PortfolioCompanies')}
        </div>
        {bubbleChartDataRequestPending ? <LoadingOverlay /> : null}
      </div>
    </div>
  );
};

export default CompaniesBubbleChartDashboard;
