import {
  DocumentExplorerDateFilterType,
  DocumentExplorerSearchType,
  FileEditFormState,
} from '../types';
import {uploadFileFormInitialState} from './uploadFileForm';
import {SortingState} from '@tanstack/react-table';

export const searchTypeOptions = [
  {
    id: 'tags',
    label: 'Global.Tags',
    value: DocumentExplorerSearchType.Tags,
  },
  {
    id: 'ask-ai',
    label: 'Documents.SegmentedControl.Option.AskAi',
    value: DocumentExplorerSearchType.AskAI,
    icon: 'logo-gradient',
  },
];

export const privateSelectOptions = [
  {
    id: 'all',
    label: 'Global.All',
    value: '',
  },
  {
    id: 'shared',
    label: 'Shared',
    value: 'false',
  },
  {
    id: 'private',
    label: 'Private',
    value: 'true',
  },
];

export const dateSelectOptions = [
  {
    id: 'all',
    label: 'Global.All',
    value: DocumentExplorerDateFilterType.All,
  },
  {
    id: 'today',
    label: 'Global.Today',
    value: DocumentExplorerDateFilterType.Today,
  },
  {
    id: 'last-7-days',
    label: 'DocumentExplorer.DateSelectOption.Last7Days',
    value: DocumentExplorerDateFilterType.Last7Days,
  },
  {
    id: 'last-30-days',
    label: 'DocumentExplorer.DateSelectOption.Last30Days',
    value: DocumentExplorerDateFilterType.Last30Days,
  },
  {
    id: 'this-year',
    label: 'DocumentExplorer.DateSelectOption.ThisYear',
    value: DocumentExplorerDateFilterType.ThisYear,
  },
  {
    id: 'last-year',
    label: 'DocumentExplorer.DateSelectOption.LastYear',
    value: DocumentExplorerDateFilterType.LastYear,
  },
];

export const fileEditFormInitialState: FileEditFormState = {
  ...uploadFileFormInitialState,
  fileName: '',
  transactions: [],
  columns: [],
};

export const documentRepositoryTableDefaultSorting: SortingState = [
  {
    desc: true,
    id: 'lastModified',
  },
];

export const selectTransactionsTableDefaultSorting: SortingState = [
  {
    desc: true,
    id: 'date',
  },
];

export const portfolioTableDefaultSorting: SortingState = [
  {
    desc: false,
    id: 'companyName',
  },
];

export const selectReportingPeriodsTableDefaultSorting: SortingState = [
  {
    desc: true,
    id: 'period',
  },
];
