import React, {useMemo} from 'react';
import {TooltipPayload, TooltipProps} from 'recharts/index';
import {valueFormat} from '../../../../../../utils/value-format';

import styles from './FundPerformanceMoicTooltip.module.scss';
import {useTranslation} from 'react-i18next';

interface CustomTooltipPayload extends TooltipPayload {
  stroke?: string;
}

type FundPerformanceTooltipProps = Pick<TooltipProps, 'active'> & {
  activeDataKey?: string;
  format?: string;
  headerKey?: string;
  currency?: string;
  payload?: CustomTooltipPayload[] | undefined;
  payloadOrder?: {
    [key: string]: number;
  };
  additionalItem?: {
    color: string;
    label: string;
  };
  customTooltipLabels?: {
    [key: string]: string;
  };
};

const Keys = ['PICC', 'DPI', 'RVPI', 'TVPI', 'Total value', 'Net IRR', 'ROI'];
const NCKeys = Keys.map(key => `NC ${key}`);

const FundPerformanceMoicTooltip: React.FC<FundPerformanceTooltipProps> = ({
  active,
  payload,
  format,
  headerKey = 'quarter',
  currency,
  additionalItem,
  payloadOrder,
  customTooltipLabels,
}) => {
  const {t} = useTranslation();
  const data = useMemo(() => {
    if (!payload || !additionalItem || !payload.length) return payload;
    return [
      ...payload,
      {
        name: additionalItem.label,
        dataKey: additionalItem.label,
        value:
          payload[0].payload[additionalItem.label] ||
          payload[0].payload[`NC ${additionalItem.label}`],
        stroke: additionalItem.color,
      },
    ];
  }, [payload, additionalItem]);

  const sortedData = useMemo(() => {
    if (!payloadOrder || !data?.length) return data;
    return data.sort((a, b) => {
      if (
        payloadOrder[a.dataKey as string] === undefined ||
        payloadOrder[b.dataKey as string] === undefined
      )
        return 0;
      return (
        payloadOrder[a.dataKey as string] - payloadOrder[b.dataKey as string]
      );
    });
  }, [payloadOrder, data]);

  const getValue = ({dataKey, value}: {dataKey: string; value: number}) => {
    if (
      !dataKey.includes('MOIC') &&
      !dataKey.includes('ROI') &&
      !dataKey.includes('Net IRR')
    ) {
      return (
        <div>
          {currency && ` ${currency} `}
          {valueFormat(value as number, 'en-GB').value}
          {valueFormat(value as number, format).suffix}
        </div>
      );
    }
    if (dataKey.includes('Net IRR')) {
      return (
        <div>
          {valueFormat(value as number, '.1f%').value}
          {valueFormat(value as number, '.1f%').suffix}
        </div>
      );
    }
    if (dataKey.includes('ROI')) {
      return <div>{valueFormat(value as number, '.2f').value}</div>;
    }
    return <div>{value}</div>;
  };

  if (active && payload?.length) {
    const isLastReportedPoint =
      !!payload.find(({dataKey}) => NCKeys.includes(dataKey as string)) &&
      !!payload.find(({dataKey}) => Keys.includes(dataKey as string));
    return (
      <div className={styles.tooltipWrapper}>
        <p className={styles.quarter}>{payload[0].payload[headerKey]}</p>
        {sortedData
          ?.filter(
            kpi =>
              !(isLastReportedPoint && NCKeys.includes(kpi.dataKey as string))
          )
          ?.map((kpi, index) => {
            const name = kpi.name.replace('NC ', '');
            return (
              <div key={index}>
                {kpi.dataKey && kpi.dataKey !== headerKey && (
                  <div className={styles.values}>
                    <span
                      className={styles.circle}
                      style={{background: kpi.stroke}}
                    ></span>
                    <span>
                      {t(
                        customTooltipLabels ? customTooltipLabels[name] : name
                      )}
                      :
                    </span>

                    {getValue({
                      dataKey: kpi.dataKey as string,
                      value: kpi.value,
                    })}
                  </div>
                )}
              </div>
            );
          })}
      </div>
    );
  }
  return null;
};

export default FundPerformanceMoicTooltip;
