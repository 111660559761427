import {KpiTabs} from '../types/index';

export const fundSectionHeaderOptions = [
  {id: 'nav', label: 'Global.Nav', value: KpiTabs.Nav},
  {id: 'net-irr', label: 'Global.NetIRR', value: KpiTabs.NetIrr},
  {id: 'dpi-rvpi-tvpi', label: 'Global.DPI-RVPI-TVPI', value: KpiTabs.DpiRvpi},
];

export const fundSectionHeaderDropdownOptions = [
  [
    {id: 'nav', label: 'Global.Nav', value: KpiTabs.Nav},
    {id: 'net-irr', label: 'Global.NetIRR', value: KpiTabs.NetIrr},
    {
      id: 'dpi-rvpi-tvpi',
      label: 'Global.DPI-RVPI-TVPI',
      value: KpiTabs.DpiRvpi,
    },
  ],
];

export const activeCompaniesSortingOptions = [
  [
    {
      id: 'irr-desc',
      label: 'CompaniesDashboard.SortingOption.IRRDesc',
      value: 'irr-desc',
    },
    {
      id: 'irr-asc',
      label: 'CompaniesDashboard.SortingOption.IRRAsc',
      value: 'irr-asc',
    },
  ],
  [
    {
      id: 'totalCost-desc',
      label: 'CompaniesDashboard.SortingOption.TotalCostDesc',
      value: 'totalCost-desc',
    },
    {
      id: 'totalCost-asc',
      label: 'CompaniesDashboard.SortingOption.TotalCostAsc',
      value: 'totalCost-asc',
    },
  ],
  [
    {
      id: 'valuation-desc',
      label: 'CompaniesDashboard.SortingOption.CurrentValuationDesc',
      value: 'valuation-desc',
    },
    {
      id: 'valuation-asc',
      label: 'CompaniesDashboard.SortingOption.CurrentValuationAsc',
      value: 'valuation-asc',
    },
  ],
];

export const dashboardCompaniesSortingOptions = [
  [
    {
      id: 'irr-desc',
      label: 'CompaniesDashboard.SortingOption.IRRDesc',
      value: 'irr-desc',
    },
    {
      id: 'irr-asc',
      label: 'CompaniesDashboard.SortingOption.IRRAsc',
      value: 'irr-asc',
    },
  ],
  [
    {
      id: 'total_cost-desc',
      label: 'CompaniesDashboard.SortingOption.TotalCostDesc',
      value: 'total_cost-desc',
    },
    {
      id: 'total_cost-asc',
      label: 'CompaniesDashboard.SortingOption.TotalCostAsc',
      value: 'total_cost-asc',
    },
  ],
  [
    {
      id: 'valuation-desc',
      label: 'CompaniesDashboard.SortingOption.CurrentValuationDesc',
      value: 'valuation-desc',
    },
    {
      id: 'valuation-asc',
      label: 'CompaniesDashboard.SortingOption.CurrentValuationAsc',
      value: 'valuation-asc',
    },
  ],
];

export const exitedCompaniesSortingOptions = [
  [
    {
      id: 'irr-desc',
      label: 'CompaniesDashboard.SortingOption.IRRDesc',
      value: 'irr-desc',
    },
    {
      id: 'irr-asc',
      label: 'CompaniesDashboard.SortingOption.IRRAsc',
      value: 'irr-asc',
    },
  ],
  [
    {
      id: 'totalCost-desc',
      label: 'CompaniesDashboard.SortingOption.TotalCostDesc',
      value: 'totalCost-desc',
    },
    {
      id: 'totalCost-asc',
      label: 'CompaniesDashboard.SortingOption.TotalCostAsc',
      value: 'totalCost-asc',
    },
  ],
  [
    {
      id: 'roi-desc',
      label: 'CompaniesDashboard.SortingOption.ROIDesc',
      value: 'roi-desc',
    },
    {
      id: 'roi-asc',
      label: 'CompaniesDashboard.SortingOption.ROIAsc',
      value: 'roi-asc',
    },
  ],
];

export const writtenOffCompaniesSortingOptions = [
  [
    {
      id: 'realisedLoss-desc',
      label: 'Funds.CompaniesSortingOption.RealisedLossDesc',
      value: 'realisedLoss-desc',
    },
    {
      id: 'realisedLoss-asc',
      label: 'Funds.CompaniesSortingOption.RealisedLossAsc',
      value: 'realisedLoss-asc',
    },
  ],
];
