import {ModalHandlerReduxActions} from '../types';
import {updateUserDataVisibilityAction} from '../reducers/userProfileServiceSlice';
import {saveChartFilterConfigAction} from '../reducers/portfolioCompaniesChartDataSlice';

export const modalHandlerReduxActions = {
  [ModalHandlerReduxActions.UserProfileDataVisibilitySignedOffToggle]:
    updateUserDataVisibilityAction,
  [ModalHandlerReduxActions.PortfolioCompaniesSaveFilterConfig]:
    saveChartFilterConfigAction,
};
