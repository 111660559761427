import axios from 'axios';
import config from '../config';
import {FundPortfolioTransactions} from '../types';

export const getFundDetailsPortfolioTransactions = async (
  token: string,
  id: string
) => {
  const {data} = await axios.get<FundPortfolioTransactions>(
    `${config.APP_URI}/fund-portfolio-transactions/${id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );
  return data;
};

export const getFundPortfolioTransactionsById = async (
  token: string,
  fundId: string,
  portfolioId?: string
) => {
  const {data} = await axios.get<any>(
    `${config.APP_URI}/fund-portfolio-transaction/transactions/${fundId}${
      portfolioId ? `?portfolioId=${portfolioId}` : ''
    }`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );
  return data;
};
