import {all, call, put, takeEvery} from 'redux-saga/effects';
import {acquireAuthResult, msalInstance} from '../..';
import {PayloadAction} from '@reduxjs/toolkit';
import {errorHandlerAction} from '../../actions';
import {
  clearScenarioDetailsReportData,
  getScenarioDetailsReportDataAction,
  setLoading,
  setScenarioDetailsReportData,
} from '../../reducers/scenarioDetailsReportSlice';
import {getFundDetailsPredictOverview} from '../../api/getFundDetailsPredictOverview';
import {FundPredictOverview} from '../../types';
import {
  ScenarioPortfolioResponse,
  getScenarioPortfolioData,
} from '../../api/getScenarioPortfolio';

export const scenarioDetailsReportSaga = function* scenarioDetailsReportSaga({
  payload,
}: PayloadAction<{
  match: any;
  fundId: string;
  id: string;
}>) {
  const {fundId, id} = payload?.match?.params;
  if (fundId) {
    yield put(setLoading());
    yield put(clearScenarioDetailsReportData());
    try {
      const {accessToken} = yield call(acquireAuthResult, msalInstance);

      const [response, scenarioReportResponse]: [
        FundPredictOverview,
        ScenarioPortfolioResponse
      ] = yield all([
        call(getFundDetailsPredictOverview, accessToken, fundId),
        ...(id ? [call(getScenarioPortfolioData, accessToken, id)] : []),
      ]);

      yield put(
        setScenarioDetailsReportData({
          ...response,
          fundPerformanceData:
            scenarioReportResponse?.reportedFundPerformanceData ||
            response.fundPerformanceData,
        })
      );
      yield put(setLoading());
    } catch (err) {
      console.warn(err);
      yield put(setLoading());
      yield put(errorHandlerAction({error: err}));
    }
  }
};

export const scenarioDetailsReportSagaWatcher =
  function* scenarioDetailsReportSagaWatcher() {
    yield all([
      takeEvery(
        getScenarioDetailsReportDataAction.type,
        scenarioDetailsReportSaga
      ),
    ]);
  };
