import {SearchDirection} from '../types';

type SearchOptions<T> = {
  searchTarget?: keyof T | null;
  excludeTarget?: T[] | null;
  sortDirection?: SearchDirection | null;
};

export function searchByValue<T>(
  array: T[],
  searchValue: string,
  {
    searchTarget = null,
    excludeTarget = null,
    sortDirection = null,
  }: SearchOptions<T>
): T[];

export function searchByValue<T>(
  array: T[][],
  searchValue: string,
  {
    searchTarget = null,
    excludeTarget = null,
    sortDirection = null,
  }: SearchOptions<T>
): T[][];

export function searchByValue<T>(
  array: (T | T[])[],
  searchValue: string,
  {
    searchTarget = null,
    excludeTarget = null,
    sortDirection = null,
  }: SearchOptions<T>
): (T | T[])[];

export function searchByValue<T>(
  array: (T | T[])[],
  searchValue: string,
  {
    searchTarget = null,
    excludeTarget = null,
    sortDirection = null,
  }: SearchOptions<T>
): (T | T[])[] {
  const regexp = new RegExp(searchValue.trim().toLowerCase());

  let filteredOptions = array
    .map(item =>
      Array.isArray(item)
        ? item.filter(item => {
            if (searchTarget && typeof item[searchTarget] === 'string') {
              const searchTargetValue = item[searchTarget] as string;
              return (
                searchTargetValue
                  .toString()
                  .toLowerCase()
                  .trim()
                  .search(regexp) >= 0
              );
            }
            return false;
          })
        : item
    )
    .filter(item => {
      if (Array.isArray(item)) {
        return !!item.length;
      }

      if (searchTarget && typeof item[searchTarget] === 'string') {
        const searchTargetValue = item[searchTarget] as string;
        return (
          searchTargetValue.toString().toLowerCase().trim().search(regexp) >= 0
        );
      }

      return false;
    });

  // If there are exclude targets specified, filter out any items from the filteredOptions where
  // the search target matches any of the exclude targets.
  if (excludeTarget) {
    let tempFilteredOptions: (T | T[])[] = [];
    filteredOptions.forEach(subArray => {
      if (Array.isArray(subArray)) {
        const filteredSubArray = subArray.filter(
          item => !isTargetExcluded(item, excludeTarget, searchTarget)
        );

        if (filteredSubArray.length > 0) {
          tempFilteredOptions.push(filteredSubArray);
        }
        filteredOptions = tempFilteredOptions;
      } else {
        filteredOptions = filteredOptions.filter(item => {
          if (!Array.isArray(item)) {
            return !isTargetExcluded(item, excludeTarget, searchTarget);
          }
          return false;
        });
      }
    });
  }

  // If sortDirection is specified, sort the filtered options based on the specified searchTarget and sortDirection.
  if (sortDirection) {
    filteredOptions.forEach((subArray, index) => {
      if (Array.isArray(subArray)) {
        filteredOptions[index] = subArray.sort((a, b) =>
          compareObjects(a, b, searchTarget, sortDirection)
        );
      } else {
        (filteredOptions as T[]).sort((a, b) =>
          compareObjects(a, b, searchTarget, sortDirection)
        );
      }
    });
  }

  return filteredOptions;
}

function isTargetExcluded<T>(
  item: T,
  excludeTarget: T[],
  searchTarget: keyof T | null
): boolean {
  if (searchTarget && typeof item[searchTarget] === 'string') {
    const searchTargetValue = item[searchTarget] as string;
    return excludeTarget.some(tag => {
      const tagValue = tag[searchTarget] as string;
      return tagValue.toLowerCase() === searchTargetValue.toLowerCase();
    });
  }

  return false;
}

function compareObjects<T>(
  a: T,
  b: T,
  searchTarget: keyof T | null,
  sortDirection: SearchDirection
): number {
  if (
    searchTarget &&
    typeof a[searchTarget] === 'string' &&
    typeof b[searchTarget] === 'string'
  ) {
    const aValue = a[searchTarget] as string;
    const bValue = b[searchTarget] as string;

    return sortDirection === SearchDirection.Asc
      ? aValue.localeCompare(bValue)
      : bValue.localeCompare(aValue);
  }

  return 0;
}
