import ValueFormat from '../../../value-format/ValueFormat';
import React, {PropsWithChildren} from 'react';
import {CellContext, RowData} from '@tanstack/react-table';
import ValueWithDeltaCell from '../value-with-delta-cell/ValueWithDeltaCell';
import {FlexHAlign} from '../../../../../types/index';
import classNames from 'classnames';
import styles from './Td.module.scss';

type Props<TData> = CellContext<TData, unknown> &
  PropsWithChildren<{
    align: string;
    colSpan?: number | undefined;
    width?: string;
    padding?: string;
    canHide?: boolean;
    className?: string;
    wrapperClassName?: string;
    nestedAccessorFn?(obj: TData): any;
    showDeltaIndicator?: boolean;
    skipExtend?: boolean;
    emptyValue?: JSX.Element | string;
    onDoubleClick?: () => void;
    onClick?: () => void;
    id?: string;
  }>;

// @TODO Should support hiding delta indicator when funds are inactive
const Td = <TData extends RowData>({
  row,
  cell,
  getValue,
  children,
  align = FlexHAlign.center,
  colSpan = 1,
  width,
  padding = '0',
  canHide = true,
  className,
  nestedAccessorFn,
  showDeltaIndicator = true,
  skipExtend = false,
  onDoubleClick,
  onClick,
  emptyValue = ' ',
  wrapperClassName,
  id,
}: Props<TData>): JSX.Element => {
  let data: any;
  nestedAccessorFn
    ? (data = nestedAccessorFn(row.original))
    : (data = getValue<string>());

  return (
    <td
      style={{
        width,
      }}
      colSpan={colSpan}
      key={cell.id}
      onDoubleClick={onDoubleClick}
      onClick={onClick}
      className={wrapperClassName}
      data-test={id}
    >
      <div
        className={classNames(
          styles.wrapper,
          data === 'Subsidary A Cost' ||
            (data === 'Subsidary B Cost' && styles.isSubsidary),
          styles[`wrapper__${align}`],
          styles[
            `wrapper__${
              row.getIsExpanded() && !skipExtend && canHide
                ? 'hidden'
                : 'visible'
            }`
          ],
          className
        )}
      >
        {children ? (
          children
        ) : nestedAccessorFn ? (
          data?.deltaIndicator && showDeltaIndicator ? (
            <ValueWithDeltaCell
              value={data?.value}
              format={data?.format}
              isUp={data.deltaIndicator.isUp}
            />
          ) : data?.value || data?.value === 0 ? (
            <ValueFormat value={data?.value} format={data?.format} />
          ) : (
            emptyValue
          )
        ) : !!data ? (
          <span>{data}</span>
        ) : (
          emptyValue
        )}
      </div>
    </td>
  );
};

export default Td;
