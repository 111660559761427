import {ActivityStatuses, WorkflowStatus} from '../types';

export const getActivityColor = (item: ActivityStatuses | boolean): string => {
  switch (item) {
    case ActivityStatuses.Overdue:
      return 'rgb(var(--colors-red))';
    case ActivityStatuses.Pending:
      return 'rgb(var(--colors-gray-5))';
    case ActivityStatuses.Submitted:
    case true:
      return 'rgb(var(--colors-lan-green))';
    case false:
      return 'rgb(var(--colors-gray-5))';
    default:
      return 'rgb(var(--colors-gray-5))';
  }
};

export const getWorkflowActivityStatus = (item: WorkflowStatus): string => {
  switch (item) {
    case WorkflowStatus.Active:
      return 'rgb(var(--colors-lan-green))';
    case WorkflowStatus.Pending:
      return 'rgb(var(--colors-orange-peel))';
    case WorkflowStatus.EditToStart:
    default:
      return 'rgb(var(--colors-gray-5))';
  }
};
