import React, {useEffect, useState} from 'react';
import classnames from 'classnames';
import {Func, PaginationType} from '../../../types';
import {Icon} from '../index';
import styles from './Pagination.module.scss';
import {usePagination} from '../../../hooks';
import {DOTS} from '../../../hooks/usePagination';

interface PaginationProps {
  pages: number;
  onChange: Func<[number], void>;
  className?: string;
  type?: PaginationType;
  page?: number;
}

const Pagination: React.FC<PaginationProps> = ({
  pages,
  onChange,
  className = '',
  type = PaginationType.Dots,
  page,
}): JSX.Element | null => {
  const [currentPage, setCurrentPage] = useState<number>(1);

  const pagination = usePagination({pages, currentPage, type});
  const onNextPageClick = (): void => {
    onChange(currentPage + 1);
    setCurrentPage(prevPage => prevPage + 1);
  };

  const onPrevPageClick = (): void => {
    onChange(currentPage - 1);
    setCurrentPage(prevPage => prevPage - 1);
  };

  const onClickPage = (page: number): void => {
    onChange(page);
    setCurrentPage(page);
  };

  useEffect(() => {
    // If we update currentPage outside of this component
    page && setCurrentPage(page);
  }, [page]);

  // If there are no pages or only one page, we should not show the pagination - Aleksa Jukic
  if (pages < 2) return null;

  return (
    <div className={classnames(styles.wrapper, className)}>
      <div className={styles.iconWrapper}>
        {currentPage !== 1 && (
          <div className={styles.previous} onClick={onPrevPageClick}>
            <Icon name={'chevron-left'} />
          </div>
        )}
      </div>
      {pagination.map((item, idx) => (
        <div
          onClick={() => item !== DOTS && onClickPage(item as number)}
          key={item + '-' + idx}
          className={classnames(
            styles.page,
            styles[`page__${type}`],
            currentPage === item && styles.active
          )}
        >
          {item}
        </div>
      ))}
      <div className={styles.iconWrapper}>
        {currentPage !== pages && (
          <div className={styles.next} onClick={onNextPageClick}>
            <Icon className={styles.icon} name={'chevron-right'} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Pagination;
