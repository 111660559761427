import axios from 'axios';
import config from '../config';
import {Reporting} from '../types/';
import {SubmitMultipleCellsPayload} from '../types/reportingTable';

export const getReporting = async (token: string, page?: number) => {
  const {data} = await axios.get<Reporting[]>(
    `${config.APP_URI}/company-admin/reporting`,
    {
      params: {
        page: page || 1,
      },
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );
  return data[0];
};

export const submitColumn = async (token: string, id: string) => {
  const {data} = await axios.put(
    `${config.APP_URI}/columns/${id}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );
  return data;
};

export const submitAllColumns = async (token: string, columnIds: string[]) => {
  const {data} = await axios.put(
    `${config.APP_URI}/columns/submit-period`,
    {
      columns: columnIds,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );
  return data;
};

export const submitDataCell = async (
  token: string,
  id: string,
  value: number | null
) => {
  const {data} = await axios.put(
    `${config.APP_URI}/cells/${id}`,
    {value},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );
  return data;
};

export const updateMultipleCells = async (
  token: string,
  payload: SubmitMultipleCellsPayload
) => {
  const {data} = await axios.put(
    `${config.APP_URI}/cells/multiple-cells-update`,
    payload,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );
  return data;
};

export const getHistoricalReportingData = async (
  token: string,
  workflowId: string
) => {
  const {data} = await axios.get<Reporting[]>(
    `${config.APP_URI}/company-admin/reporting/history/${workflowId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );
  return data;
};

export const unlockSubmittedColumn = async (
  token: string,
  columnId: string
) => {
  const {data} = await axios.put(
    `${config.APP_URI}/columns/${columnId}/unlock`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );

  return data;
};

export const cancelUpdateColumn = async (token: string, columnId: string) => {
  const {data} = await axios.put(
    `${config.APP_URI}/columns/${columnId}/revert`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );
  return data;
};

export const updateColumnData = async (token: string, columnId: string) => {
  const {data} = await axios.put(
    `${config.APP_URI}/columns/${columnId}/update`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );

  return data;
};

export const exportReportingData = async (
  token: string,
  workflowId: string
) => {
  const response = await axios.get(
    `${config.APP_URI}/data-collection-details/${workflowId}/export`,
    {
      responseType: 'blob',
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );
  const href = window.URL.createObjectURL(response.data);

  const anchorElement = document.createElement('a');

  anchorElement.href = href;

  // 1) Get the value of content-disposition header
  const contentDisposition = response.headers['content-disposition'];

  // 2) set the fileName variable to the default value
  let fileName = 'file';

  // 3) if the header is set, extract the filename
  if (contentDisposition) {
    const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
    if (fileNameMatch?.length === 2) {
      fileName = fileNameMatch[1];
    }
  }

  anchorElement.download = fileName;

  document.body.appendChild(anchorElement);
  anchorElement.click();

  document.body.removeChild(anchorElement);
  window.URL.revokeObjectURL(href);
  return response.data;
};
