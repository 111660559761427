import React from 'react';
import {
  ActiveCompanyData,
  ExitedCompanyData,
  StyleVariant,
  GenericExpandedRow,
} from '../../../../types/index';
import {Loader, PerformanceWidget} from '../../../';
import styles from './InvestmentsTableExpandedRow.module.scss';

const InvestmentsTableExpandedRow = <
  TData extends ActiveCompanyData | ExitedCompanyData
>({
  row,
}: GenericExpandedRow<TData>): JSX.Element => {
  return (
    <div className={styles.wrapper}>
      {row.original.performanceData ? (
        <PerformanceWidget
          {...row.original.performanceData[0].performanceData}
          isConsolidatedData={
            row.original.performanceData[0].isConsolidatedData
          }
          styleType={StyleVariant.Secondary}
          portfolioCompanyName={row.original.companyName}
          id="fund-investment-table-row"
          signOffDate={row.original.signOffDate}
        />
      ) : (
        <Loader loaderType={'funds'} />
      )}
    </div>
  );
};

export default InvestmentsTableExpandedRow;
