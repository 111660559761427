import {QuestionnaireInputTypes, ReportingTableCellTypes} from '../types';

export const reportingTableInputTypes: Array<ReportingTableCellTypes> = [
  ReportingTableCellTypes.DataCell,
];

export const questionnaireInputTypes: Array<QuestionnaireInputTypes> = [
  QuestionnaireInputTypes.SingleSelectInput,
  QuestionnaireInputTypes.MultiSelectInput,
  QuestionnaireInputTypes.TextInput,
  QuestionnaireInputTypes.NumberInput,
  QuestionnaireInputTypes.PercentageInput,
  QuestionnaireInputTypes.CurrencyInput,
];
