import React, {useEffect, useState, FocusEvent} from 'react';
import {InputProps, StyleVariant} from '../../../types';
import styles from './Input.module.scss';
import {Icon} from '../index';
import classnames from 'classnames';
import {useTranslation} from 'react-i18next';

const Input: React.FC<InputProps> = ({
  id,
  label,
  labelIconProps,
  placeholder = '',
  errorMessage,
  showErrorMessage = false,
  validate,
  variant = StyleVariant.Primary,
  autoCompleteValue,
  maskedRef,
  suffix,
  className,

  ...inputProps
}) => {
  const {t} = useTranslation();
  const [blur, setBlur] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    setBlur(true);
    setIsValid(validate({[`${inputProps.name}`]: inputProps.value}));
    inputProps.onBlur && inputProps.onBlur(event);
  };

  useEffect(() => {
    setIsValid(validate({[`${inputProps.name}`]: inputProps.value}));
  }, [inputProps.value]);

  return (
    <div className={classnames(styles.wrapper, styles[`wrapper__${variant}`])}>
      {label ? (
        <label className={styles.inputLabel}>
          {t(label)}
          {inputProps.required && (
            <span className={styles.asterisk}>*</span>
          )}{' '}
          {suffix ? <span>{`(${suffix})`}</span> : null}
          {labelIconProps?.labelIcon && (
            <Icon
              name={labelIconProps.labelIcon}
              className={labelIconProps.labelIconClassName}
              tooltip={labelIconProps.labelIconTooltipContent}
              tooltipPosition={labelIconProps.labelIconTooltipContentPosition}
              tooltipClasses={labelIconProps.labelIconTooltipClasses}
              hasTooltipArrow={labelIconProps.labelIconTooltipArrow}
            />
          )}
        </label>
      ) : null}
      <div className={styles.inputField}>
        {autoCompleteValue && (
          <span className={styles.autoCompleteInput}>{autoCompleteValue}</span>
        )}
        <input
          {...inputProps}
          placeholder={suffix ? suffix : t(placeholder)}
          onChange={inputProps.onChange}
          onBlur={handleBlur}
          onFocus={() => {
            if (!isValid && errorMessage) {
              setBlur(false);
            }
          }}
          ref={maskedRef}
          data-validate={isValid.toString()}
          className={classnames(
            styles.input,
            showErrorMessage
              ? styles.inputInvalid
              : blur
              ? isValid && errorMessage
                ? styles.inputInvalid
                : isValid
                ? styles.inputValid
                : styles.inputInvalid
              : '',
            className && className
          )}
          data-test={id}
        />
        <span className={styles.errorMessage}>{errorMessage}</span>
        <Icon
          className={classnames(styles.icon, styles['icon__danger'])}
          name={'exclamation-circle'}
        />
        <Icon
          className={classnames(styles.icon, styles['icon__success'])}
          name={'check'}
        />
      </div>
    </div>
  );
};

export default Input;
