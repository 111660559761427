import React from 'react';
import classnames from 'classnames';
import styles from './ReportingTableMandatoryIndicatorCell.module.scss';
import {ActivityStatus} from '../../../index';
import {ActivityStatuses, StyleVariant} from '../../../../../types';
import {ReportingTableCell} from '../../../../../types/reportingTable';

type ReportingTableMandatoryIndicatorCellProps = {
  cell: ReportingTableCell;
  classNames?: string;
  verticalAlign?: 'top' | 'middle' | 'bottom';
  textAlign?: 'left' | 'center' | 'right';
};

const ReportingTableMandatoryIndicatorCell = ({
  cell,
  classNames,
  verticalAlign = 'middle',
  textAlign = 'center',
}: ReportingTableMandatoryIndicatorCellProps) => {
  return (
    <th
      id="blank"
      headers="blank"
      style={{
        verticalAlign,
        textAlign,
        paddingTop: verticalAlign === 'top' ? '19px' : '0',
      }}
      className={classnames(styles.cell, {
        [`${classNames}`]: !!classNames,
        [styles.notVisible]: !cell.isVisible,
      })}
    >
      <div className={styles.cellInnerWrapper}>
        <ActivityStatus
          activity={
            !cell.data?.value
              ? ActivityStatuses.Draft
              : ActivityStatuses.Submitted
          }
          variant={StyleVariant.Secondary}
        />
      </div>
    </th>
  );
};

export default ReportingTableMandatoryIndicatorCell;
