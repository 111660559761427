import React, {useEffect, useMemo, useState} from 'react';
import styles from './UploadDocuments.module.scss';
import {ConfirmationModal, FileEditForm, IconButton} from '../..';
import {
  ButtonStyle,
  FileEditFormPayload,
  FileUploadContext,
  FormStatus,
  InvestedFundTransactionData,
  PortfolioTransactionData,
  SectionTag,
  UserRole,
} from '../../../types';
import {useNavInfo, useTooltip} from '../../../hooks';
import {Position} from '../../../context/TooltipContext';
import {Trans, useTranslation} from 'react-i18next';
import {extractUploadDocumentsParamsFromUrl} from '../../../utils';
import UploadDocumentsModal from './components/upload-documents-modal/UploadDocumentsModal';
import {useAppDispatch, useAppSelector} from '../../../hooks/useReduxHooks';
import {
  getUploadDocumentsDataAction,
  getUploadDocumentsFileEditOptionsAction,
  putUploadDocumentsFileEditUpdateAction,
  setUploadDocumentsError,
  setUploadDocumentsFormError,
  setUploadDocumentsFormStatus,
  setIsUploadDocumentsModalOpen,
  uploadDocumentsDeleteFileAction,
  uploadDocumentsDownloadFileAction,
  uploadDocumentsUploadFileAction,
} from '../../../reducers/uploadDocumentsSlice';
import {setFundPortfolioSelectedTransaction} from '../../../reducers/fundDetailsPortfolioTransactionsSlice';
import {setCompanyInvestmentOverviewSelectedTransaction} from '../../../reducers/companyDetailsInvestmentOverview';

type UploadDocumentsProps = {
  isFloatingButtonVisible: boolean;
  userRoles: UserRole[];
};

const UploadDocuments = ({
  isFloatingButtonVisible,
  userRoles,
}: UploadDocumentsProps) => {
  const {
    data,
    error,
    formError,
    formStatus,
    isRequestPending,
    fileEditFormOptions,
    downloadPendingIds,
    isUploadDocumentsModalOpen,
  } = useAppSelector(state => state.uploadDocuments);
  const {
    selectedTransactions: fundSelectedTransactions,
    selectedTransaction: fundSelectedTransaction,
  } = useAppSelector(state => state.fundDetails.fundPortfolioTransactions);
  const {
    selectedTransactions: companySelectedTransactions,
    selectedTransaction: companySelectedTransaction,
  } = useAppSelector(state => state.companyDetails.companyInvestmentOverview);

  const {t} = useTranslation();
  const {showTooltip, hideTooltip} = useTooltip();
  const [currentFileForDelete, setCurrentFileForDelete] = useState<{
    name: string;
    id: string;
  } | null>(null);
  const [isFileEditFormOpen, setIsFileEditFormOpen] = useState(false);

  const dispatch = useAppDispatch();

  const navInfoData = useNavInfo();

  const uploadDocumentTags = useMemo(() => {
    return data ? [...data.entityTags, ...data.sectionTags] : [];
  }, [data]);

  const selectedTransactions = useMemo(() => {
    let selectedTransaction = null;
    let selectedTransactions:
      | InvestedFundTransactionData[]
      | PortfolioTransactionData[] = [];
    let selectedTransactionName = {};
    if (navInfoData.sectionTag === SectionTag.FundPortfolioTransactions) {
      if (fundSelectedTransaction) {
        selectedTransaction = fundSelectedTransaction;
        selectedTransactionName = {
          companyName: fundSelectedTransaction.companyName ?? '',
        };
      } else if (fundSelectedTransactions) {
        selectedTransactions = fundSelectedTransactions;
      }
    } else if (
      navInfoData.sectionTag === SectionTag.PortfolioCompanyInvestmentOverview
    ) {
      if (companySelectedTransaction) {
        selectedTransaction = companySelectedTransaction;
        selectedTransactionName = {
          fundName: companySelectedTransaction.fundName ?? '',
        };
      } else if (companySelectedTransactions) {
        selectedTransactions = companySelectedTransactions;
      }
    }

    if (selectedTransaction) {
      return {
        info: [
          {
            id: selectedTransaction.id,
            ...selectedTransactionName,
            shares: selectedTransaction.shares ?? null,
            currency: selectedTransaction.currency ?? '',
            cost: selectedTransaction.cost ?? null,
            date: selectedTransaction.date ?? null,
          },
        ],
        ids: [selectedTransaction.id],
      };
    }

    if (selectedTransactions.length) {
      return {
        info: selectedTransactions.map(transaction => {
          const name =
            'companyName' in transaction
              ? {companyName: transaction.companyName}
              : 'fundName' in transaction
              ? {fundName: transaction.fundName}
              : {};
          return {
            id: transaction.id,
            ...name,
            shares: transaction.shares ?? null,
            currency: transaction.currency ?? '',
            cost: transaction.cost ?? null,
            date: transaction.date ?? null,
          };
        }),
        ids: selectedTransactions.map(transaction => transaction.id),
      };
    }

    return {info: null, ids: []};
  }, [
    fundSelectedTransaction,
    fundSelectedTransactions,
    companySelectedTransaction,
    companySelectedTransactions,
  ]);

  const handleMouseEnter = (e: React.MouseEvent) => {
    showTooltip({
      content: t('Global.Documents'),
      position: Position.Top,
      target: e.currentTarget as HTMLElement,
    });
  };

  const handleOpenUploadDocumentsModal = () => {
    const uploadDocumentParams = extractUploadDocumentsParamsFromUrl(
      navInfoData.sectionTag
    );

    if (
      SectionTag.PortfolioCompanyInvestmentOverview ===
        navInfoData.sectionTag &&
      selectedTransactions.ids.length
    ) {
      uploadDocumentParams.sections?.push(SectionTag.FundPortfolioTransactions);
    } else if (
      SectionTag.FundPortfolioTransactions === navInfoData.sectionTag &&
      selectedTransactions.ids.length
    ) {
      uploadDocumentParams.sections?.push(
        SectionTag.PortfolioCompanyInvestmentOverview
      );
    }

    dispatch(setIsUploadDocumentsModalOpen(true));
    dispatch(
      getUploadDocumentsDataAction({
        ...uploadDocumentParams,
        transactions: selectedTransactions.ids?.length
          ? selectedTransactions.ids
          : null,
        columnIds: null,
      })
    );
  };

  const handleCloseUploadDocumentsModal = () => {
    if (!currentFileForDelete && !isFileEditFormOpen) {
      dispatch(setIsUploadDocumentsModalOpen(false));
      dispatch(setFundPortfolioSelectedTransaction(null));
      dispatch(setCompanyInvestmentOverviewSelectedTransaction(null));
    }
  };

  const columns = useMemo(() => {
    return data ? data.reportingPeriods.map(item => item.id) : [];
  }, [data?.reportingPeriods]);

  const onDropHandler = (fileList: FileList) => {
    if (fileList) {
      const payload = {
        entityTags: data?.entityTags.map(tag => tag.id) || [],
        sectionTags: data?.sectionTags.map(tag => tag.id) || [],
        customTags: [],
        isPrivate: false,
        files: fileList,
        transactions: selectedTransactions.ids,
        columns,
        fileUploadContext: FileUploadContext.UploadDocuments,
      };
      dispatch(uploadDocumentsUploadFileAction(payload));
    }
  };

  const handleOpenDeleteModal = (value: {id: string; name: string}) => {
    setCurrentFileForDelete(value);
  };

  const handleCancelDeleteFile = () => {
    if (!isRequestPending) {
      setCurrentFileForDelete(null);
    }
  };

  const handleConfirmDeleteFile = () => {
    if (!currentFileForDelete) return;
    dispatch(
      uploadDocumentsDeleteFileAction(
        currentFileForDelete.id,
        FileUploadContext.UploadDocuments,
        selectedTransactions.ids
      )
    );
  };

  const handleDownloadFile = (id: string) => {
    dispatch(
      uploadDocumentsDownloadFileAction(id, FileUploadContext.UploadDocuments)
    );
  };

  const handleOpenEditModal = (id: string) => {
    setIsFileEditFormOpen(true);
    dispatch(getUploadDocumentsFileEditOptionsAction(id));
  };

  const handleCloseFileEditModal = () => {
    if (formStatus !== FormStatus.Pending) {
      setIsFileEditFormOpen(false);
    }
  };

  const handleFileEditSubmit = (formData: FileEditFormPayload) => {
    dispatch(putUploadDocumentsFileEditUpdateAction(formData));
  };

  const resetErrors = () => {
    if (error) {
      dispatch(setUploadDocumentsError(null));
    }
    if (formError) {
      dispatch(setUploadDocumentsFormError(null));
    }
  };

  useEffect(() => {
    if (!isRequestPending && !!currentFileForDelete) {
      setCurrentFileForDelete(null);
    }

    if (formStatus === FormStatus.Success) {
      dispatch(setUploadDocumentsFormStatus(FormStatus.Ready));
      setIsFileEditFormOpen(false);
    }
  }, [isRequestPending, formStatus]);

  return (
    <div className={styles.wrapper}>
      {isFloatingButtonVisible && (
        <div onMouseEnter={handleMouseEnter} onMouseLeave={hideTooltip}>
          <IconButton
            onClick={handleOpenUploadDocumentsModal}
            styleType={ButtonStyle.Primary}
            icon="document-upload"
            className={styles.uploadButton}
            id="documents-upload-button"
          />
        </div>
      )}

      <UploadDocumentsModal
        isOpen={isUploadDocumentsModalOpen}
        isLoading={isRequestPending && !currentFileForDelete}
        onClose={handleCloseUploadDocumentsModal}
        onDropHandler={onDropHandler}
        tags={uploadDocumentTags}
        data={data?.data ?? null}
        handleOpenDeleteModal={handleOpenDeleteModal}
        handleDownloadFile={handleDownloadFile}
        downloadPendingIds={downloadPendingIds}
        userRole={userRoles}
        handleOpenEditModal={handleOpenEditModal}
        sectionTag={navInfoData.sectionTag}
        transactionsInfo={selectedTransactions.info}
        reportingPeriodsInfo={data?.reportingPeriods ?? null}
      />
      {!!currentFileForDelete && (
        <ConfirmationModal
          confirmButtonText={t('Global.Delete')}
          isOpen={!!currentFileForDelete}
          message={
            <Trans
              i18nKey={`Global.Notification.Delete`}
              values={{name: currentFileForDelete.name}}
            />
          }
          onClose={handleCancelDeleteFile}
          onConfirm={handleConfirmDeleteFile}
          isLoading={isRequestPending}
        />
      )}
      {isFileEditFormOpen ? (
        <FileEditForm
          isOpen={isFileEditFormOpen}
          onClose={handleCloseFileEditModal}
          onSubmit={handleFileEditSubmit}
          options={fileEditFormOptions}
          entityTagsOptions={data?.tagOptions?.entityTags ?? []}
          sectionTagsOptions={data?.tagOptions?.sectionTags ?? []}
          customTagsOptions={data?.tagOptions?.customTags ?? []}
          formStatus={formStatus}
          error={error}
          formError={formError}
          resetErrors={resetErrors}
          userRole={userRoles}
        />
      ) : null}
    </div>
  );
};

export default UploadDocuments;
