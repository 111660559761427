import React, {useEffect, useMemo, useState} from 'react';
import {
  DateRangePickerDropdown,
  GenericTable,
  IconButton,
  LoadingOverlay,
  MultiSelectDropdown,
  SankeyChart,
  SankeyChartPlaceholder,
  SectionHeader,
  Toggle,
  TreemapChart,
} from '../../';
import {
  entitiesDefaultOption,
  feeAnalysisDateRangePickerPresets,
  FeeAnalysisHeaderSubtitlePlaceholderFeeTypes,
  FeeAnalysisHeaderSubtitlePlaceholderSupplierFee,
  feeAnalysisTableDefaultSorting,
} from '../../../constants/';
import {
  ButtonStyle,
  FeeAnalysisData,
  FeeAnalysisTabs,
  FeeAnalysisViewTypeTabs,
  FeesData,
  Func,
  OperationalDateRange,
  SelectOption,
  StyleVariant,
  TreemapNodeData,
} from '../../../types';
import FeeAnalysisHeader from '../fee-analysis-header/FeeAnalysisHeader';
import styles from './FeeAnalysisDashboard.module.scss';
import {
  getTreemapImageExportPayload,
  getTreemapTableExportPayload,
} from '../../global/custom-treemap/TreemapChartSettings';
import {downloadBundledExports} from '../../../utils/export';
import {
  genSankeyImageExportPayload,
  genSankeyTableExportPayload,
} from '../../global/sankey-chart/SankeyChartSettings';
import {format, parseISO} from 'date-fns';
import {useTranslation} from 'react-i18next';
import {genFeeAnalysisTableColumns} from '../../global/table/fee-analysis-table/FeeAnalysisColumnDefs';
import FeeAnalysisTableExpandedRow from '../../global/table/fee-analysis-table/FeeAnalysisTableExpandedRow';
import {downloadXLSXExport, exportTableToXlsx} from '../../../utils';

interface FeeAnalysisDashboardProps extends FeeAnalysisData {
  id: string;
  updateFeeAnalysisDataHandler: Func<[string, string, boolean, string], void>;
  fundName: string;
  feeAnalysisUpdateInProgress: boolean;
}

const FeeAnalysisDashboard: React.FC<FeeAnalysisDashboardProps> = ({
  id,
  title,
  entities = [],
  availableDateRange,
  selectedDateRange,
  feeTypes,
  supplierFees,
  updateFeeAnalysisDataHandler,
  fundName,
  feeAnalysisUpdateInProgress,
}): JSX.Element => {
  const {t} = useTranslation();
  const [activeFeeTypeData, setActiveFeeTypeData] =
    useState<FeesData>(feeTypes);

  const [selectedNode, setSelectedNode] = useState<TreemapNodeData | null>(
    null
  );

  const [treemapExportInProgress, setTreemapExportInProgress] =
    useState<boolean>(false);

  const [selectedEntities, setSelectedEntities] = useState<string>(
    entities
      .filter(entity => entity.isSelected)
      .map(entity => entity.id)
      .join(',') || entitiesDefaultOption.value
  );

  const [activeTabGroup, setActiveTabGroup] = useState<FeeAnalysisTabs>(
    FeeAnalysisTabs.FeeTypes
  );

  const [activeTabViewGroup, setActiveTabViewGroup] =
    useState<FeeAnalysisViewTypeTabs>(FeeAnalysisViewTypeTabs.ChartFeesView);

  const [sankeyExportInProgress, setSankeyExportInProgress] =
    useState<boolean>(false);

  const [includeManagementFees, setIncludeManagementFees] =
    useState<boolean>(false);

  const getSankeySubtitle = () => {
    if (selectedNode?.sankeyData.subtitle) {
      return selectedNode?.sankeyData.subtitle;
    }

    if (activeTabGroup === FeeAnalysisTabs.FeeTypes) {
      return FeeAnalysisHeaderSubtitlePlaceholderFeeTypes;
    }

    return FeeAnalysisHeaderSubtitlePlaceholderSupplierFee;
  };

  const changeActiveFeeType = (value: FeeAnalysisTabs): void => {
    setActiveTabGroup(value);
    setSelectedNode(null);
  };

  const changeActiveFeeView = (value: FeeAnalysisViewTypeTabs): void => {
    setActiveTabViewGroup(value);
  };

  const formattedEntitiesForSelectDropdown: SelectOption[] = useMemo(() => {
    const formattedEntities = entities.map(entity => ({
      id: entity.id,
      label: entity.label,
      value: entity.id,
    }));
    return [entitiesDefaultOption, ...formattedEntities];
  }, [entities]);

  const fetchUpdatedFeeAnalysisData = (
    entityId: string,
    dateRange: OperationalDateRange,
    includeManagementFees: boolean
  ): void => {
    setSelectedEntities(entityId);

    const {from, to} = dateRange;

    updateFeeAnalysisDataHandler(from, to, includeManagementFees, entityId);
  };

  const handleUpdateMultiplySelect = (
    value: string | number,
    checked: boolean
  ) => {
    let payloadArr: (string | number)[] =
      value === entitiesDefaultOption.value
        ? [value]
        : selectedEntities
        ? selectedEntities
            .split(',')
            .filter(item => item !== entitiesDefaultOption.value)
        : [];

    if (value !== entitiesDefaultOption.value) {
      payloadArr = checked
        ? [...payloadArr, value]
        : payloadArr.filter(item => item !== value);
    }

    const payload = payloadArr.join(',');
    fetchUpdatedFeeAnalysisData(
      payload,
      changeJsDateToFormattedDateString(
        selectedDateRange || availableDateRange
      ),
      includeManagementFees
    );
  };

  const changeDateStringToJsDate = (
    range: OperationalDateRange
  ): {to: Date; from: Date; format: string} => {
    return {
      from: parseISO(range.from),
      to: parseISO(range.to),
      format: range.format,
    };
  };

  const changeJsDateToFormattedDateString = (
    range: OperationalDateRange
  ): OperationalDateRange => ({
    from: format(new Date(range.from), range.format),
    to: format(new Date(range.to), availableDateRange.format),
    format: range.format,
  });

  useEffect(() => {
    setActiveFeeTypeData(
      activeTabGroup === FeeAnalysisTabs.FeeTypes ? feeTypes : supplierFees
    );
    setSelectedNode(null);
  }, [feeTypes, supplierFees, activeTabGroup]);

  const exportSankeyChartHandler = async () => {
    setSankeyExportInProgress(true);

    const zipFileName = `${fundName.split(' ').join('')}_FeeAnalysisBreakdown_${
      activeTabGroup === FeeAnalysisTabs.FeeTypes ? 'FeeTypes' : 'SupplierFees'
    }`;

    const tableExportPayload = genSankeyTableExportPayload(
      selectedNode?.sankeyData,
      activeTabGroup === FeeAnalysisTabs.FeeTypes,
      zipFileName
    );
    const imagesExportPayload = genSankeyImageExportPayload(zipFileName);

    await downloadBundledExports({
      zipFileName,
      tableExportPayload,
      imagesExportPayload,
    });

    setSankeyExportInProgress(false);
  };

  const handleTreemapExport = async () => {
    setTreemapExportInProgress(true);

    const isFeeTypeActive = activeTabGroup === FeeAnalysisTabs.FeeTypes;
    const zipFileName = `${fundName.split(' ').join('')}_FeeAnalysis_${
      isFeeTypeActive ? 'FeeTypes' : 'SupplierFees'
    }`;

    if (activeTabViewGroup === FeeAnalysisViewTypeTabs.TableFeesView) {
      const preparedDataForExport = getTreemapTableExportPayload(
        activeFeeTypeData.tableData,
        isFeeTypeActive,
        zipFileName
      );
      const exportedData = await exportTableToXlsx(preparedDataForExport);
      await downloadXLSXExport(exportedData);
      setTreemapExportInProgress(false);
      return;
    }

    await downloadBundledExports({
      zipFileName,
      tableExportPayload: getTreemapTableExportPayload(
        activeFeeTypeData.tableData,
        isFeeTypeActive,
        zipFileName
      ),
      imagesExportPayload: getTreemapImageExportPayload(zipFileName),
    });

    setTreemapExportInProgress(false);
  };

  return (
    <div id={id} className={styles.wrapper}>
      <SectionHeader
        className={styles.feeAnalysisHeader}
        label={title}
        labelType={'large'}
        onClick={() => {}}
        withActionButton={false}
        childrenRight={[
          <Toggle
            checked={includeManagementFees}
            disabled={feeAnalysisUpdateInProgress}
            label={t('Funds.FeeAnalysisDashboard.SectionHeader.Toggle')}
            onChange={e => {
              setIncludeManagementFees(e.target.checked);
              fetchUpdatedFeeAnalysisData(
                selectedEntities || entitiesDefaultOption.value,
                changeJsDateToFormattedDateString(
                  selectedDateRange || availableDateRange
                ),
                e.target.checked
              );
            }}
          />,
          <MultiSelectDropdown
            id={`${id}-select-dropdown-entities`}
            onChange={handleUpdateMultiplySelect}
            options={formattedEntitiesForSelectDropdown}
            value={(selectedEntities || entitiesDefaultOption.value).split(',')}
            placeHolderKey={
              'Funds.FeeAnalysisDashboard.SectionHeader.DropdownPlaceholder'
            }
          />,
          <DateRangePickerDropdown
            rangePresets={feeAnalysisDateRangePickerPresets}
            availableDateRange={changeDateStringToJsDate(availableDateRange)}
            selectedDateRange={
              selectedDateRange
                ? changeDateStringToJsDate(selectedDateRange)
                : null
            }
            dateFormat={availableDateRange.format}
            onApply={dateRange =>
              fetchUpdatedFeeAnalysisData(
                selectedEntities || entitiesDefaultOption.value,
                {
                  from: format(
                    dateRange.from as Date,
                    availableDateRange.format
                  ),
                  to: format(dateRange.to as Date, availableDateRange.format),
                  format: availableDateRange.format,
                },
                includeManagementFees
              )
            }
            onCancel={() => false}
          />,
        ]}
      />
      <div className={styles.content}>
        <FeeAnalysisHeader
          id={`${id}-fee-analysis-header`}
          title={activeFeeTypeData.title}
          subtitle={activeFeeTypeData.subtitle}
          currency={activeFeeTypeData.currency}
          format={activeFeeTypeData.format}
          value={activeFeeTypeData.totalFees}
          activeFeeType={activeTabGroup}
          activeFeeViewType={activeTabViewGroup}
          changeActiveFeeType={changeActiveFeeType}
          changeActiveFeeViewType={changeActiveFeeView}
          className={styles.feeAnalysisHeaderTreemap}
          disabled={feeAnalysisUpdateInProgress}
          exportButton={
            <IconButton
              onClick={handleTreemapExport}
              styleType={ButtonStyle.Primary}
              icon="export"
              loading={treemapExportInProgress}
              disabled={feeAnalysisUpdateInProgress}
            />
          }
        />

        {activeTabViewGroup === FeeAnalysisViewTypeTabs.TableFeesView && (
          <div className={styles.overlayWrapper}>
            <GenericTable
              defaultSorting={feeAnalysisTableDefaultSorting}
              data={activeFeeTypeData.tableData.data}
              columns={genFeeAnalysisTableColumns(
                activeFeeTypeData.tableData.headerMapping
              )}
              expandedView={FeeAnalysisTableExpandedRow}
              getRowCanExpand={() => true}
              expandedHandler={row => row.toggleExpanded()}
              className={styles.feeAnalysisTable}
            />
            {feeAnalysisUpdateInProgress ? <LoadingOverlay /> : null}
          </div>
        )}

        {activeTabViewGroup === FeeAnalysisViewTypeTabs.ChartFeesView && (
          <>
            <div className={styles.overlayWrapper}>
              <TreemapChart
                treemapData={activeFeeTypeData.data[0]}
                colors={activeFeeTypeData.colors}
                onNodeSelect={setSelectedNode}
                selectedNode={selectedNode}
              />
              {feeAnalysisUpdateInProgress ? <LoadingOverlay /> : null}
            </div>

            <FeeAnalysisHeader
              id={`${id}-fee-analysis-header-sankey`}
              title={
                selectedNode?.sankeyData.title ||
                t('Funds.FeeAnalysisDashboard.FeeAnalysisHeader')
              }
              subtitle={t(getSankeySubtitle())}
              currency={selectedNode?.sankeyData.currency}
              format={selectedNode?.sankeyData.format}
              value={selectedNode?.sankeyData.totalFees}
              variant={StyleVariant.Secondary}
              className={styles.feeAnalysisHeaderSankey}
              disabled={feeAnalysisUpdateInProgress}
              exportButton={
                <IconButton
                  onClick={exportSankeyChartHandler}
                  styleType={ButtonStyle.Primary}
                  icon="export"
                  loading={sankeyExportInProgress}
                  disabled={!selectedNode}
                />
              }
            />
            {selectedNode ? (
              <SankeyChart
                {...selectedNode.sankeyData}
                tooltipFormat={selectedNode.tooltipFormat}
              />
            ) : (
              <SankeyChartPlaceholder />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default FeeAnalysisDashboard;
