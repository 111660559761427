import {
  AdminUserPageTabs,
  AdminDataCollectionPageTabs,
  Tab,
  DocumentsPageTabs,
} from '../types';

export const fundDetailsTabs: Tab[] = [
  {label: 'Funds.FundDetail.TabHeader.Overview', id: 1, value: 'overview'},
  {
    label: 'Funds.FundDetail.TabHeader.Operational',
    id: 2,
    value: 'operational',
  },
  {label: 'Funds.FundDetail.TabHeader.Structure', id: 3, value: 'structure'},
  {
    label: 'Funds.FundDetail.TabHeader.PortfolioSummary',
    id: 4,
    value: 'portfolio-summary',
  },
  {
    label: 'Funds.FundDetail.TabHeader.PortfolioTransactions',
    id: 5,
    value: 'portfolio-transactions',
  },
];

export const scenarioDetailsTabs: Tab[] = [
  {label: 'Funds.ScenarioDetailsTabs.TabHeader.Main', id: 1, value: 'main'},
  {label: 'Global.Valuation', id: 2, value: 'valuation'},
  {
    label: 'Funds.ScenarioDetailsTabs.TabHeader.Other',
    id: 3,
    value: 'transactions',
  },
];

export const portfolioInvestmentsTabs: Tab[] = [
  {label: 'Global.Active', id: 1, value: 'active'},
  {label: 'Global.Exited', id: 2, value: 'exited'},
  {label: 'Global.WrittenOff', id: 3, value: 'writtenOff'},
];

export const companyDetailsTabs: Tab[] = [
  {
    label: 'Portfolio.CompanyDetails.Tab.InvestmentOverview',
    id: 1,
    value: 'investment-overview',
  },
  {label: 'Global.Company', id: 2, value: 'company', disabled: false},
  {
    label: 'Portfolio.CompanyDetails.Tab.Monitoring',
    id: 3,
    value: 'monitoring',
    disabled: false,
  },
];

export const adminUserPageTabs: Tab[] = [
  {label: 'Users', id: 1, value: AdminUserPageTabs.Users},
];

export const adminDataCollectionPageTabs: Tab[] = [
  {
    label: 'Admin.DataCollection.TabTitle.TemplateConfig',
    id: 1,
    value: AdminDataCollectionPageTabs.TemplateConfiguration,
  },
  {
    label: 'Admin.DataCollection.TabTitle.WorkflowConfig',
    id: 2,
    value: AdminDataCollectionPageTabs.WorkflowConfiguration,
  },
  {
    label: 'Admin.DataCollection.TabTitle.Portfolio',
    id: 3,
    value: AdminDataCollectionPageTabs.PortfolioCompanyUsers,
  },
];

export const documentPageTabs: Tab[] = [
  {
    label: 'Document explorer',
    id: 1,
    value: DocumentsPageTabs.DocumentExplorer,
  },
  // {
  //   label: 'Transaction pivot',
  //   id: 2,
  //   value: DocumentsPageTabs.TransactionPivot,
  // },
];
