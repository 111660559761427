import React, {
  ChangeEvent,
  FocusEvent,
  KeyboardEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import styles from './ManageWorkflowForm.module.scss';
import {
  ButtonStyle,
  DropdownWrapperAlignmentType,
  SelectOption,
  StyleVariant,
  WorkflowStatus,
  ManageWorkflowFormProps,
  WorkflowType,
  ManageWorkflowFormState,
} from '../../../types';
import {
  Button,
  ButtonSpinner,
  DropdownWrapper,
  Input,
  Modal,
  SelectInput,
  ValueFormat,
  WorkflowActivityStatus,
} from '../../global';
import {
  useFormState,
  useIsSubmitDisabled,
  useOnOutsideClick,
} from '../../../hooks';
import {
  externalCollectionSchema,
  internalCollectionSchema,
  manageWorkflowInitialState,
} from '../../../constants';
import {valueFormat} from '../../../utils';
import classnames from 'classnames';
import {useTranslation} from 'react-i18next';
import {alterSchema} from '../../../utils/alterSchema';
import {Position} from '../../../context/TooltipContext';
import {WorkflowTypeMandatoryFields} from '../../../constants/mandatoryFormFields';

const ManageWorkflowForm: React.FC<ManageWorkflowFormProps> = ({
  isOpen,
  onClose,
  onSubmit,
  inProgress,
  error,
  title,
  options,
  formError,
  resetErrors,
}) => {
  const [initialState, setInitialState] = useState(manageWorkflowInitialState);

  const schemaMap = {
    [WorkflowType.InternalCollection]: internalCollectionSchema,
    [WorkflowType.ExternalCollection]: externalCollectionSchema,
  };

  const {t} = useTranslation();

  const [mandatoryFields, setMandatoryFields] = useState<
    Array<keyof ManageWorkflowFormState>
  >([]);

  const {
    formState,
    isFormStateChanged,
    validate,
    errors,
    handleChange,
    setFormState,
    setCurrentSchema,
    resetSchemaValidationErrors,
    hasEmptyMandatoryFields,
  } = useFormState(
    initialState,
    alterSchema(
      schemaMap,
      options?.workflowCollectionType.value || '',
      externalCollectionSchema
    ),
    mandatoryFields
  );
  const [assigneeOptions, setAssigneeOptions] = useState<string[]>([]);
  const [currentAssigneeOption, setCurrentAssigneeOption] = useState(0);
  const [isFormInputsDisabled, setIsFormInputsDisabled] = useState(true);
  const [isUpdating, setIsUpdating] = useState(false);
  const autocompleteWrapperRef = useRef<HTMLDivElement>(null);

  const {isSubmitDisabled, fieldError} = useIsSubmitDisabled(
    formError,
    errors,
    error,
    isFormStateChanged
  );

  const genPeriodOptions = (): SelectOption[] => {
    const selectedPeriodInterval = options?.reportingInterval.options.find(
      interval => interval.value === formState.reportingInterval
    );
    return (
      selectedPeriodInterval?.periodList.map(periodOption => ({
        id: periodOption,
        label: valueFormat(periodOption, selectedPeriodInterval.format).value,
        value: periodOption,
      })) || []
    );
  };

  const determineCurrentMandatoryFields = (collectionType: WorkflowType) => {
    if (collectionType === WorkflowType.ExternalCollection) {
      setMandatoryFields(WorkflowTypeMandatoryFields.ExternalCollection);
      return;
    }

    setMandatoryFields(WorkflowTypeMandatoryFields.InternalCollection);
  };

  const handleOnChangeWorkflowType = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    if (error || formError) {
      resetErrors();
    }

    if (e.target.value === formState.workflowCollectionType) return;

    resetSchemaValidationErrors();
    setFormState(initialState);
    const newSchema = alterSchema(schemaMap, e.target.value);
    setCurrentSchema(newSchema);
    handleChange(e);
    determineCurrentMandatoryFields(e.target.value as WorkflowType);
  };

  const handleOnChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    if (error || formError) {
      resetErrors();
    }
    handleChange(e);
  };

  const handleIntervalChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (error || formError) {
      resetErrors();
    }
    setFormState(prev => ({
      ...prev,
      reportingInterval: e.target.value,
      firstPeriod:
        options?.reportingInterval.options.find(
          interval => interval.value === e.target.value
        )?.periodList[0] || '',
    }));
  };

  const submitButtonStyle =
    options?.workflowStatus.id === WorkflowStatus.Active
      ? ButtonStyle.Warning
      : ButtonStyle.Confirmative;

  const submitButtonText =
    options?.workflowStatus.id === WorkflowStatus.Active
      ? t('DataCollection.StopWorkflow')
      : t('DataCollection.ConfirmWorkflowModal.Button.Start');

  const isWorkflowActive = options?.workflowStatus.id === WorkflowStatus.Active;

  const isReportingCurrencyAlreadySet = !!options?.reportingCurrency.value;

  const handleChangeAssignee = (event: ChangeEvent<HTMLInputElement>) => {
    if (error || formError) {
      resetErrors();
    }
    handleChange(event);
    if (!event.target.value) {
      setAssigneeOptions([]);
      setCurrentAssigneeOption(0);
    }
    if (event.target.value && options?.assigneesList) {
      const regexp = new RegExp(event.target.value.trim().toLowerCase());
      const matchedOptions = options?.assigneesList.filter(
        item => item.toString().toLowerCase().trim().search(regexp) >= 0
      );
      setAssigneeOptions(matchedOptions);
      setCurrentAssigneeOption(0);
    }
  };

  const handleClickOption = (value: string) => {
    if (error || formError) {
      resetErrors();
    }
    handleChange({
      target: {value, name: 'portfolioCompanyAssignee'},
    } as ChangeEvent<HTMLInputElement>);
    setAssigneeOptions([]);
    setCurrentAssigneeOption(0);
  };

  const handleKeyDownAssignee = (event: KeyboardEvent<HTMLInputElement>) => {
    if (error || formError) {
      resetErrors();
    }
    if (
      (event.key === 'Tab' ||
        event.key === 'Enter' ||
        event.key === 'ArrowRight') &&
      assigneeOptions[currentAssigneeOption]
    ) {
      event.preventDefault();
      handleChange({
        target: {
          value: assigneeOptions[currentAssigneeOption],
          name: 'portfolioCompanyAssignee',
        },
      } as ChangeEvent<HTMLInputElement>);
      setAssigneeOptions([]);
      setCurrentAssigneeOption(0);
    }

    if (event.key === 'ArrowDown' && assigneeOptions.length) {
      event.preventDefault();
      setCurrentAssigneeOption(prev =>
        prev === assigneeOptions.length - 1 ? 0 : ++prev
      );
    }

    if (event.key === 'ArrowUp' && assigneeOptions.length) {
      event.preventDefault();
      setCurrentAssigneeOption(prev =>
        prev === 0 ? assigneeOptions.length - 1 : --prev
      );
    }
  };

  const handleBlurInput = (event: FocusEvent<HTMLInputElement>) => {
    if (error || formError) {
      resetErrors();
    }
    if (
      !autocompleteWrapperRef.current ||
      autocompleteWrapperRef.current.contains(event.target as Node)
    ) {
      return;
    }
    setAssigneeOptions([]);
    setCurrentAssigneeOption(0);
  };

  useOnOutsideClick(autocompleteWrapperRef, () => {
    setAssigneeOptions([]);
    setCurrentAssigneeOption(0);
  });

  const handleSubmit = (isUpdate: boolean = false) => {
    setIsUpdating(isUpdate);
    if (formState.workflowStatus.id !== WorkflowStatus.Active || isUpdate) {
      const validated = validate(formState);
      if (!validated) return;
    }

    onSubmit(
      {
        ...formState,
        requestDateLabel:
          (options?.requestDate.options.find(
            item => item.value === formState.requestDate
          )?.label as string) || '',
        reminderDateLabel:
          (options?.reminderDate.options.find(
            item => item.value === formState.reminderDate
          )?.label as string) || '',
        reportingIntervalLabel:
          (options?.reportingInterval.options.find(
            item => item.value === formState.reportingInterval
          )?.label as string) || '',
        templateLabel:
          (options?.template.options.find(
            item => item.value === formState.templateId
          )?.label as string) || '',
        internalAssigneesListLabel:
          (options?.internalAssigneesList.options.find(
            item => item.value === formState.internalAssigneesList
          )?.label as string) || '',
        dueDateLabel:
          (options?.dueDate.options.find(
            item => item.value === formState.dueDate
          )?.label as string) || '',
        ownerName:
          (options?.owner.options.find(item => item.value === formState.ownerId)
            ?.label as string) || '',
      },
      isUpdate
    );
  };

  useEffect(() => {
    if (options) {
      setInitialState({
        id: options.id,
        internalAssigneesList: options.internalAssigneesList.value,
        portfolioCompanyAssignee: options.portfolioCompanyAssignee || '',
        reportingCurrency: options.reportingCurrency.value || '',
        workflowCollectionType: options.workflowCollectionType.value,
        requestDate:
          options.requestDate.value ||
          (options.requestDate.options[0]?.value as string),
        reminderDate:
          options.reminderDate.value ||
          (options.reminderDate.options[0]?.value as string),
        dueDate:
          options.dueDate.value ||
          (options.dueDate.options[0]?.value as string),
        templateId: options.template.value,
        reportingInterval: options.reportingInterval.value,
        firstPeriod: options.firstPeriod,
        ownerId: options.owner.value,
        workflowStatus: options.workflowStatus,
      });

      setCurrentSchema(
        alterSchema(
          schemaMap,
          options?.workflowCollectionType.value || '',
          externalCollectionSchema
        )
      );
    }

    setIsFormInputsDisabled(
      options?.workflowStatus.id === WorkflowStatus.Active
    );
  }, [options]);

  const startWorkflowCTA = (
    <div className={styles.buttons}>
      <Button
        styleType={submitButtonStyle}
        text={submitButtonText}
        onClick={() => handleSubmit()}
        loading={inProgress}
        disabled={fieldError || !!formError || !!hasEmptyMandatoryFields}
      />
      <Button
        styleType={ButtonStyle.Secondary}
        text={t('Global.Cancel')}
        onClick={onClose}
        disabled={inProgress}
      />
    </div>
  );

  const updateWorkflowCTA = (
    <div className={styles.buttons}>
      <Button
        styleType={ButtonStyle.Primary}
        text={isFormInputsDisabled ? t('Global.Update') : t('Global.Save')}
        onClick={() =>
          isFormInputsDisabled
            ? setIsFormInputsDisabled(false)
            : handleSubmit(true)
        }
        loading={inProgress && isUpdating}
        disabled={(!isFormInputsDisabled && isSubmitDisabled) || inProgress}
      />
      <Button
        styleType={ButtonStyle.Secondary}
        text={t('Global.Cancel')}
        onClick={onClose}
        disabled={inProgress}
      />
      <Button
        styleType={submitButtonStyle}
        text={submitButtonText}
        onClick={() => handleSubmit()}
        loading={inProgress && !isUpdating}
        className={styles.stopWorkflowButton}
        disabled={isUpdating}
      />
    </div>
  );

  // External collection specific fields
  const scheduledRequestDateInput = (
    <SelectInput
      label={t('DataCollection.ManageWorkflowForm.Label.Scheduled')}
      name={'requestDate'}
      variant={StyleVariant.Secondary}
      value={formState.requestDate}
      onChange={handleOnChange}
      options={options?.requestDate.options}
      disabled={isFormInputsDisabled}
      errorMessage={t(formError?.requestDate || '')}
    />
  );

  const scheduledReminderDateInput = (
    <SelectInput
      label={t('DataCollection.ManageWorkflowForm.Label.Reminder')}
      name={'reminderDate'}
      variant={StyleVariant.Secondary}
      value={formState.reminderDate}
      onChange={handleOnChange}
      options={options?.reminderDate.options}
      disabled={isFormInputsDisabled}
      errorMessage={t(formError?.reminderDate || '')}
    />
  );

  const portfolioCompanyAssignee = (
    <div>
      <div ref={autocompleteWrapperRef} className={styles.dropdownWrapper}>
        <DropdownWrapper
          isOpen={assigneeOptions.length > 0}
          alignment={DropdownWrapperAlignmentType.Left}
          className={styles.dropdownWrapper}
          customButton={() => (
            <div className={styles.assigneeInputWrapper}>
              <Input
                autoComplete={'off'}
                onKeyDown={handleKeyDownAssignee}
                onBlur={handleBlurInput}
                placeholder={t(
                  'DataCollection.ManageWorkflowForm.Placeholder.AddCompanyEmail'
                )}
                label={t(
                  'DataCollection.ManageWorkflowForm.Label.PortfolioCompany'
                )}
                errorMessage={t(
                  errors?.portfolioCompanyAssignee?.message ||
                    formError?.portfolioCompanyAssignee ||
                    ''
                )}
                showErrorMessage={!!formError?.portfolioCompanyAssignee}
                variant={StyleVariant.Secondary}
                validate={validate}
                value={formState.portfolioCompanyAssignee}
                onChange={handleChangeAssignee}
                name={'portfolioCompanyAssignee'}
                disabled={isFormInputsDisabled}
                className={styles.assigneeInput}
              />
            </div>
          )}
        >
          <div className={styles.assigneeOptions}>
            {assigneeOptions.map((option, idx) => (
              <div
                key={idx}
                onClick={() => handleClickOption(option)}
                role="button"
                className={classnames(
                  styles.assigneeOption,
                  currentAssigneeOption === idx && styles.selected
                )}
              >
                {option}
              </div>
            ))}
          </div>
        </DropdownWrapper>
      </div>
    </div>
  );

  // Internal collection specific fields
  const internalAssigneesList = (
    <SelectInput
      label={t('DataCollection.ManageWorkflowForm.Label.InternalAssignee')}
      name={'internalAssigneesList'}
      variant={StyleVariant.Secondary}
      value={formState.internalAssigneesList}
      onChange={handleOnChange}
      options={options?.internalAssigneesList.options}
      validate={validate}
      disabled={isFormInputsDisabled}
      placeholder={t(
        'DataCollection.ManageWorkflowForm.SelectInput.Placeholder'
      )}
      errorMessage={t(errors.internalAssigneesList?.message || '')}
    />
  );

  const scheduledDueDateInput = (
    <SelectInput
      label={t('DataCollection.ManageWorkflowForm.Label.DueDate')}
      name={'dueDate'}
      variant={StyleVariant.Secondary}
      value={formState.dueDate}
      onChange={handleOnChange}
      options={options?.dueDate.options}
      disabled={isFormInputsDisabled}
      errorMessage={t(formError?.dueDate || '')}
    />
  );

  // Workflow type tooltip content
  const workflowTypeTooltipContent = (
    <>
      <p className={styles.worfklowTooltipParagraph}>
        {t('Select how you would like to collect the data')}
      </p>
      <div className={styles.workflowTooltipCollectionInfo}>
        <strong className={styles.workflowTooltipHeading}>
          {t('Global.InternalCollection')}
        </strong>
        <p>{t('Data is to be submitted by an internal user')}</p>
        <div className={styles.workflowTooltipSeparator}></div>
        <strong className={styles.workflowTooltipHeading}>
          {t('Global.ExternalCollection')}
        </strong>
        <p>
          {t(
            'Invite an external user to submit portfolio company data, including automated workflow'
          )}
        </p>
      </div>
    </>
  );

  return (
    <Modal
      title={title}
      subTitle={
        !isWorkflowActive && options ? t('Choose and assign the workflow') : ''
      }
      isOpen={isOpen}
      onClose={onClose}
      variant={StyleVariant.Secondary}
      footerChildren={
        options && formState.workflowCollectionType
          ? options?.workflowStatus.id !== WorkflowStatus.Active
            ? startWorkflowCTA
            : updateWorkflowCTA
          : null
      }
    >
      {options ? (
        <div className={styles.wrapper}>
          {error && <div className={styles.errorMessage}>{error}</div>}
          <SelectInput
            label={t('Global.Workflow')}
            name={'workflowCollectionType'}
            variant={StyleVariant.Secondary}
            value={formState.workflowCollectionType}
            onChange={handleOnChangeWorkflowType}
            options={options.workflowCollectionType.options}
            disabled={isFormInputsDisabled}
            placeholder={t('Choose a workflow')}
            labelIconProps={{
              labelIcon: 'info',
              labelIconTooltipContent: workflowTypeTooltipContent,
              labelIconTooltipContentPosition: Position.Left,
              labelIconTooltipClasses: styles.workflowTooltipWrapper,
              labelIconTooltipArrow: false,
            }}
          />

          {formState.workflowCollectionType && (
            <>
              {formState.workflowCollectionType ===
                WorkflowType.ExternalCollection && (
                <>{portfolioCompanyAssignee}</>
              )}

              {formState.workflowCollectionType ===
                WorkflowType.InternalCollection && <>{internalAssigneesList}</>}

              <SelectInput
                label={t('Global.ReportingCurrency')}
                name={'reportingCurrency'}
                placeholder={t(
                  'DataCollection.ManageWorkflowForm.SelectInput.Placeholder'
                )}
                variant={StyleVariant.Secondary}
                options={options.reportingCurrency.options}
                onChange={handleOnChange}
                validate={validate}
                value={formState.reportingCurrency}
                errorMessage={t(
                  errors?.reportingCurrency.message ||
                    formError?.reportingCurrency ||
                    ''
                )}
                disabled={isReportingCurrencyAlreadySet}
                withSeparator={true}
              />
              {formState.workflowCollectionType ===
                WorkflowType.ExternalCollection && (
                <>
                  {scheduledRequestDateInput}
                  {scheduledReminderDateInput}
                </>
              )}
              {formState.workflowCollectionType ===
                WorkflowType.InternalCollection && <>{scheduledDueDateInput}</>}
              <SelectInput
                label={t('Global.Template')}
                name={'templateId'}
                variant={StyleVariant.Secondary}
                value={formState.templateId}
                onChange={handleOnChange}
                options={options.template.options}
                disabled={true}
                errorMessage={t(formError?.templateId || '')}
              />
              <SelectInput
                label={t('Global.ReportingInterval')}
                name={'reportingInterval'}
                variant={StyleVariant.Secondary}
                value={formState.reportingInterval}
                onChange={handleIntervalChange}
                options={options.reportingInterval.options}
                disabled={true}
                errorMessage={t(formError?.reportingInterval || '')}
              />
              <SelectInput
                label={t('Global.FirstPeriod')}
                variant={StyleVariant.Secondary}
                value={formState.firstPeriod}
                onChange={handleOnChange}
                name={'firstPeriod'}
                options={genPeriodOptions()}
                disabled={true}
                errorMessage={t(formError?.firstPeriod || '')}
              />
              <SelectInput
                label={t('Global.Owner')}
                name={'ownerId'}
                variant={StyleVariant.Secondary}
                value={formState.ownerId}
                onChange={handleOnChange}
                options={options?.owner.options}
                disabled={isFormInputsDisabled}
                errorMessage={t(formError?.userId || '')}
              />
            </>
          )}

          {options.workflowStatus.id === WorkflowStatus.Active && (
            <div className={styles.status}>
              <div className={styles.statusLabel}>Workflow</div>
              <WorkflowActivityStatus
                status={options.workflowStatus.id}
                label={options.workflowStatus.label}
              />
              {options.workflowStatus.date && (
                <div className={styles.statusDate}>
                  {t('Global.since')}{' '}
                  <ValueFormat
                    value={options.workflowStatus.date}
                    format={'dd-MM-yyyy'}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      ) : (
        <div className={styles.spinnerWrapper}>
          <ButtonSpinner className={styles.spinner} />
        </div>
      )}
    </Modal>
  );
};

export default ManageWorkflowForm;
