import React, {Fragment} from 'react';
import {useLocation} from 'react-router-dom';
import {WrittenOffCompanyData} from '../../../../../types/index';
import BaseInfo from '../base-info/BaseInfo';
import FinancialData from '../financial-data/FinancialData';
import {ValueFormat} from '../../../../global';
import homeLayoutStyles from '../../CompanyWidget.module.scss';
import pageLayoutStyles from '../../CompanyWidgetTemp.module.scss';

const WrittenOffCompany: React.FC<WrittenOffCompanyData> = props => {
  const {fundColor, realisedLoss, currency, writtenOffDate} = props;

  const {pathname} = useLocation();
  const styles = pathname === '/' ? homeLayoutStyles : pageLayoutStyles;

  return (
    <div
      className={styles.wrapper}
      style={{borderColor: `rgb(var(--colors-${fundColor}))`}}
      data-test="company-widget"
    >
      <BaseInfo
        data={props}
        rightTopComponent={
          <Fragment>
            <div>Written off</div>
            <ValueFormat value={writtenOffDate?.value} format={"QQ 'YY"} />
          </Fragment>
        }
      />
      <div className={styles.bottom} data-test="company-widget-tab-row">
        <FinancialData data={realisedLoss} currency={currency} />
      </div>
    </div>
  );
};

export default WrittenOffCompany;
