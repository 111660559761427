import React, {useEffect, useRef, useState} from 'react';

const TreemapNode = (props: any) => {
  const {
    depth,
    x,
    y,
    width,
    height,
    name,
    value,
    color,
    id,
    handleNodeSelect,
    selectedNode,
    sankeyData,
    tooltipFormat,
    currency,
    hoveredId,
    onHover,
    percentage,
    canBeHovered,
  } = props;
  const [showText, setShowText] = useState<boolean>(true);

  const textTopRef = useRef<SVGTextElement>(null);
  const textBottomRef = useRef<SVGTextElement>(null);

  const isHovered = hoveredId === `treemap-node-${id}` && canBeHovered;

  const curX = x === 0 ? x + 5 : x;
  const curY = y === 0 ? y + 5 : y;
  const curWidth = x === 0 && width > 5 ? width - 5 : width;
  const curHeight = y === 0 && height > 5 ? height - 5 : height;

  const onMouseEnter = () => {
    onHover(`treemap-node-${id}`);
  };

  const onMouseLeave = () => {
    onHover(``);
  };

  useEffect(() => {
    const topText = textTopRef?.current?.getBoundingClientRect();
    const bottomText = textBottomRef?.current?.getBoundingClientRect();
    const topTextWidth = topText?.width || 0;
    const bottomTextHeight = bottomText?.height || 0;

    if (topTextWidth + 12 > curWidth || bottomTextHeight + 10 > curHeight) {
      setShowText(false);
    }
  }, [textTopRef, textBottomRef, curWidth, curHeight]);

  return (
    <g id={`treemap-node-${id}`}>
      <filter id="treemapNodeShadow" colorInterpolationFilters="sRGB">
        <feDropShadow dx="0" dy="0" stdDeviation="5" floodOpacity="0.3" />
      </filter>
      <rect
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={() =>
          handleNodeSelect({
            id,
            name,
            value,
            tooltipFormat,
            color,
            sankeyData,
            currency,
          })
        }
        x={isHovered ? curX - 5 : curX}
        y={isHovered ? curY - 5 : curY}
        width={curWidth > 2 ? curWidth - 2 + (isHovered ? 10 : 0) : curWidth}
        height={
          curHeight > 2 ? curHeight - 2 + (isHovered ? 10 : 0) : curHeight
        }
        style={{
          fill: depth === 2 ? color : 'none',
          transition: '.2s ease',
          cursor: 'pointer',
          stroke: isHovered ? color : '',
          strokeOpacity: isHovered ? 0.5 : '',
          filter: isHovered ? 'url(#treemapNodeShadow)' : '',
        }}
      />
      {depth === 2 && showText ? (
        <>
          <text
            ref={textTopRef}
            x={curX + 12}
            y={curY + 22}
            fill="#fff"
            fontSize={14}
            fontWeight={600}
            fillOpacity={selectedNode?.id === id ? 0.6 : 1}
          >
            {name}
          </text>
          <text
            ref={textBottomRef}
            x={curX + 12}
            y={curY + 42}
            fill="#fff"
            fontSize={14}
            fillOpacity={selectedNode?.id === id ? 0.6 : 1}
          >
            {percentage}%
          </text>
        </>
      ) : null}
    </g>
  );
};

export default TreemapNode;
