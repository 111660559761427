import React, {useMemo, useState} from 'react';
import {DropdownWrapper} from '../index';
import DateRangePicker, {
  DateRangePickerProps,
} from '../date-range-picker/DateRangePicker';
import {formatWithLocale} from '../../../utils';
import {DateRange, DropdownWrapperAlignmentType} from '../../../types';

interface DateRangePickerDropdownProps
  extends Pick<DateRangePickerProps, 'onApply' | 'onCancel' | 'rangePresets'> {
  label?: string;
  availableDateRange: DateRange;
  selectedDateRange: DateRange | null;
  dateFormat: string;
  alignment?: DropdownWrapperAlignmentType;
  disabled?: boolean;
}

const DateRangePickerDropdown = ({
  label,
  availableDateRange,
  selectedDateRange,
  dateFormat,
  onApply,
  onCancel,
  alignment,
  disabled,
  rangePresets,
}: DateRangePickerDropdownProps) => {
  const [currentSelectedDateRange, setCurrentSelectedDateRange] =
    useState<DateRange>(selectedDateRange || availableDateRange);

  const dateRangeLabel = useMemo(() => {
    const from = formatWithLocale(
      currentSelectedDateRange.from as Date,
      dateFormat
    );
    const to = formatWithLocale(
      currentSelectedDateRange.to as Date,
      dateFormat
    );
    return `${from} - ${to}`;
  }, [currentSelectedDateRange, dateFormat]);

  const applyDateRangeHandler = (dateRange: DateRange): void => {
    setCurrentSelectedDateRange(dateRange);
    onApply(dateRange);
  };

  return (
    <DropdownWrapper
      label={label || dateRangeLabel}
      alignment={alignment}
      disabled={disabled}
    >
      {({handleCloseDropdown, isOpen}) => (
        <DateRangePicker
          rangePresets={rangePresets}
          isOpen={isOpen}
          initialDateRange={availableDateRange}
          previouslySelectedDateRange={selectedDateRange}
          dateFormat={dateFormat}
          onApply={dateRange => {
            applyDateRangeHandler(dateRange);
            handleCloseDropdown();
          }}
          onCancel={() => {
            onCancel();
            handleCloseDropdown();
          }}
        />
      )}
    </DropdownWrapper>
  );
};

export default DateRangePickerDropdown;
