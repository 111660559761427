import React, {useState} from 'react';
import styles from './EntityPreviewCapital.module.scss';
import {
  CapitalData,
  StyleVariant,
  DropdownWrapperAlignmentType,
  ExportDropdownOption,
} from '../../../../../types/index';
import {CapitalWidget, CapitalWidgetSkeleton} from '../../../../fund-overview';
import {AsAtController} from '../../../../global';
import {getFundStructureCapitalData} from '../../../../../reducers/fundDetailsStructureSlice';
import {useAppDispatch} from '../../../../../hooks/useReduxHooks';
import classnames from 'classnames';
import ExportDropdown from '../../../../global/export-dropdown/ExportDropdown';
import {proxy} from 'comlink';
import {
  entitiesCapitalExportOptions,
  genImagesExportPayload,
  genTableExportPayload,
} from '../../../../fund-overview/capital-widget/CapitalWidgetSettings';
import {downloadZippedExport, htmlToImage} from '../../../../../utils';
import {exportsWorker} from '../../../../../utils/export';
import {useTranslation} from 'react-i18next';

interface EntityPreviewCapitalProps {
  fundName: string;
  data: CapitalData;
  fundEntityId: string;
}

const EntityPreviewCapital: React.FC<EntityPreviewCapitalProps> = ({
  fundName,
  data,
  fundEntityId,
}) => {
  const {t} = useTranslation();
  const [currentDate, setCurrentDate] = useState(data.asAt.value);
  const dispatch = useAppDispatch();
  const handleChangeAsAt = (date: string) => {
    setCurrentDate(date);
    handleUpdateData(fundEntityId, date);
  };

  const handleUpdateData = (fundId: string, date: string) => {
    dispatch(getFundStructureCapitalData({fundId, date}));
  };

  const exportHandler = async (option: ExportDropdownOption) => {
    const isContributions =
      option.value === 'structure-entity-table-contributions';
    const zipFileName = `${fundName.split(' ').join('')}_Capital${
      option.label
    }`;

    const tableExportPayload = genTableExportPayload(
      data,
      option,
      isContributions,
      zipFileName
    );
    const imagesExportPayload = genImagesExportPayload(zipFileName, option);

    const exportedData = await exportsWorker.exportZippedChartData(
      tableExportPayload,
      imagesExportPayload,
      proxy(htmlToImage)
    );

    downloadZippedExport({
      name: zipFileName,
      blob: exportedData,
    });
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <div className={styles.headerText}>{t('Global.Capital')}</div>
        <div className={styles.headerContent}>
          <AsAtController
            label={t('Global.asAt')}
            startDate={data.firstDate.value}
            endDate={data.lastDate.value}
            value={currentDate}
            onChange={handleChangeAsAt}
            alignment={DropdownWrapperAlignmentType.Right}
          />
          <ExportDropdown
            exportOptions={entitiesCapitalExportOptions}
            exportHandler={exportHandler}
          />
        </div>
      </div>
      <div
        className={classnames(
          styles.widgets,
          data.isLoading && styles.isLoading
        )}
      >
        {!data.isLoading
          ? data.widgets.map((widget, i) => (
              <div className={styles.widgetWrap} key={`${widget.id}-${i}`}>
                <div className={styles.widgetInner}>
                  <CapitalWidget
                    variant={StyleVariant.Secondary}
                    id={widget.id}
                    label={widget.label}
                    data={widget.data}
                    currency={widget.currency}
                  />
                </div>
              </div>
            ))
          : Array.from(Array(2)).map((_, idx) => (
              <div key={idx} className={styles.widgetItem}>
                <CapitalWidgetSkeleton />
              </div>
            ))}
      </div>
    </div>
  );
};
export default EntityPreviewCapital;
