import {PayloadAction} from '@reduxjs/toolkit';
import {
  ButtonStyle,
  FormStatus,
  GlobalInformationActionType,
  ReportingTableCell,
  ReportingTableData,
  ReportingTableRow,
  ReportingTableTemplateType,
} from '../../types';
import {
  actionChannel,
  all,
  call,
  delay,
  fork,
  put,
  race,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import {acquireAuthResult, msalInstance} from '../../index';
import {
  cancelUpdateColumn,
  exportReportingData,
  submitAllColumns,
  submitColumn,
  submitDataCell,
  unlockSubmittedColumn,
  updateColumnData,
  updateMultipleCells,
} from '../../api/getReporting';
import {
  cancelUpdateColumnAction,
  exportReportingDataAction,
  saveReportingDataFromClipboard,
  setActiveReportingTableRowsData,
  setActiveSubmitColumnId,
  setActiveUnlockedColumnId,
  setColumnSubmitButtonAsDisabled,
  setColumnSubmittingStatus,
  setColumnUnlockingStatus,
  setDataCellData,
  setExportPending,
  setForecastIsOnFinalPage,
  submitColumnData,
  submitColumnsData,
  submitDataCellAction,
  submitMultipleCellsData,
  unlockColumnAction,
  updateColumnCellData,
  updateColumnDataAction,
  updateSubmittedColumnsCellData,
  validateColumnsAndShowNext,
  validateColumnsAndSubmit,
  validateColumnsData,
} from '../../reducers/reportingTableSlice';
import {
  generalResponseErrorResolver,
  getVisibleColumnsIds,
  pasteToMultipleCells,
  validateAllColumnsCells,
} from '../../utils';
import {setGlobalInformation} from '../../reducers/globalInformationSlice';
import i18n from '../../i18n';

export const submitDataCellSaga = function* submitDataCellSaga({
  payload,
}: PayloadAction<{cell: ReportingTableCell}>) {
  try {
    const {accessToken} = yield call(acquireAuthResult, msalInstance);
    const response: {value: number} = yield call(
      submitDataCell,
      accessToken,
      payload.cell.id,
      payload.cell.data?.value
    );
    yield put(
      setDataCellData({
        rowId: payload.cell.rowId,
        cellId: payload.cell.id,
        value: response.value,
      })
    );
  } catch (err) {
    console.warn(err);
  }
};

export const submitColumnDataSaga = function* submitColumnDataSaga({
  payload,
}: PayloadAction<{
  columnId: string;
  reportingTemplateType: ReportingTableTemplateType;
}>) {
  if (payload) {
    try {
      yield put(setColumnSubmittingStatus(FormStatus.Pending));
      const {accessToken} = yield call(acquireAuthResult, msalInstance);
      yield call(submitColumn, accessToken, payload.columnId);
      yield put(
        updateColumnCellData({
          columnId: payload.columnId,
          submitted: true,
          isLocked: true,
          reportingTableTemplateType: payload.reportingTemplateType,
        })
      );
      yield put(setColumnSubmittingStatus(FormStatus.Ready));
    } catch (err) {
      yield put(setColumnSubmittingStatus(FormStatus.Ready));
      console.warn(err);
      const responseError = generalResponseErrorResolver(err);
      if (responseError) {
        yield put(setGlobalInformation(responseError));
      }
    }
  }
};

export const submitMultipleCellsSaga = function* submitMultipleCellsSaga({
  payload,
}: PayloadAction<{
  dataRows: ReportingTableRow[];
  clipboardData: any;
  cellId: string;
  reportingTableTemplateType: ReportingTableTemplateType;
}>): any {
  if (payload) {
    // Apply data from clipboard to reporting table state
    const data = pasteToMultipleCells(
      payload.clipboardData,
      payload.cellId,
      payload.dataRows,
      payload.reportingTableTemplateType
    );

    // Disable submit buttons until submission is successful
    yield put(
      setColumnSubmitButtonAsDisabled({
        columnIds: Array.from(data.columnIds),
        disabled: true,
      })
    );

    // Store data form clipboard
    yield put(
      saveReportingDataFromClipboard({
        dataRows: data.dataRows,
        reportingTableTemplateType: payload.reportingTableTemplateType,
      })
    );

    try {
      const {accessToken} = yield call(acquireAuthResult, msalInstance);
      yield call(updateMultipleCells, accessToken, data.cellsPayload);
      // Enable submit buttons after submission is successful
      yield put(
        setColumnSubmitButtonAsDisabled({
          columnIds: Array.from(data.columnIds),
          disabled: false,
        })
      );
    } catch (err) {
      const responseError = generalResponseErrorResolver(err);
      if (responseError) {
        return yield put(setGlobalInformation(responseError));
      }
      // if not 401 auth error, show modal for multiple cells error
      yield put(
        setColumnSubmitButtonAsDisabled({
          columnIds: Array.from(data.columnIds),
          disabled: false,
        })
      );
      const errorMsg = {
        type: 'error',
        message: i18n.t('Global.ErrorMessage.TryAgain'),
        icon: 'warning',
        isDismissable: true,
        actions: [
          {
            text: i18n.t('Global.CloseWindow'),
            style: ButtonStyle.Primary,
            action: GlobalInformationActionType.Dismiss,
          },
        ],
      };

      yield put(setGlobalInformation(errorMsg));
      console.warn(err);
    }
  }
};

const debouncedSubmitDataCellDataSaga =
  function* debouncedSubmitDataCellDataSaga(action: any) {
    yield put(
      setColumnSubmitButtonAsDisabled({
        columnIds: [action.payload.cell.columnId],
        disabled: true,
      })
    );
    const {cancelled} = yield race({
      delayed: delay(1500),
      cancelled: take(
        (act: any) =>
          act.type === submitDataCellAction.type &&
          act.payload.cell.id === action.payload.cell.id
      ),
    });

    if (!cancelled) {
      yield call(submitDataCellSaga, action);
      yield put(
        setColumnSubmitButtonAsDisabled({
          columnIds: [action.payload.cell.columnId],
          disabled: false,
        })
      );
    }
  };

const validateColumnsSaga = function* validateColumnsSaga(
  dataRows: ReportingTableRow[],
  columnIds: string[],
  reportingTableTemplateType: ReportingTableTemplateType
) {
  let cellsWithError: Array<string> = [];
  const dataRowsWithErrors = validateAllColumnsCells(
    dataRows,
    cellsWithError,
    columnIds
  );

  if (cellsWithError.length > 0) {
    yield put(
      validateColumnsData({
        dataRows: dataRowsWithErrors,
        reportingTableTemplateType,
      })
    );
  }

  return cellsWithError.length === 0; // return true if valid, false otherwise
};

const validateColumnsAndShowNextSaga =
  function* validateColumnsAndShowNextSaga({
    payload,
  }: PayloadAction<{
    data: ReportingTableData;
    collapsed: boolean;
    activeOffset: number;
    justVisible: boolean;
  }>) {
    const columnIds = getVisibleColumnsIds(
      payload.data.dataRows,
      payload.justVisible
    );
    const valid: boolean = yield call(
      validateColumnsSaga,
      payload.data.dataRows,
      columnIds,
      payload.data.type as ReportingTableTemplateType
    );
    if (valid) {
      yield put(
        setActiveReportingTableRowsData({
          reportingData: payload.data,
          collapsed: payload.collapsed,
          activeOffset: payload.activeOffset,
        })
      );
      yield put(
        setForecastIsOnFinalPage({
          isOnFinalPage: true,
          reportingTableTemplateType: payload.data
            .type as ReportingTableTemplateType,
        })
      );
    }
  };

const validateColumnsAndSubmitSaga = function* validateColumnsAndSubmitSaga({
  payload,
}: PayloadAction<{
  data: ReportingTableData;
}>) {
  const columnIds = getVisibleColumnsIds(payload.data.dataRows, false);
  const valid: boolean = yield call(
    validateColumnsSaga,
    payload.data.dataRows,
    columnIds,
    payload.data.type as ReportingTableTemplateType
  );
  if (valid) {
    yield put(setActiveSubmitColumnId(columnIds[0]));
  }
};

const unlockColumnSaga = function* unlockColumnSaga({
  payload,
}: PayloadAction<{
  columnId: string;
  reportingTableTemplateType: ReportingTableTemplateType;
}>) {
  if (payload) {
    try {
      yield put(setColumnUnlockingStatus(FormStatus.Pending));
      yield put(setActiveUnlockedColumnId(payload.columnId));
      const {accessToken} = yield call(acquireAuthResult, msalInstance);
      yield call(unlockSubmittedColumn, accessToken, payload.columnId);
      yield put(
        updateColumnCellData({
          columnId: payload.columnId,
          submitted: true,
          isLocked: false,
          reportingTableTemplateType: payload.reportingTableTemplateType,
        })
      );
    } catch (err) {
      console.warn(err);
      const responseError = generalResponseErrorResolver(err);
      if (responseError) {
        yield put(setGlobalInformation(responseError));
      }
    } finally {
      yield put(setColumnUnlockingStatus(FormStatus.Ready));
      yield put(setActiveUnlockedColumnId(null));
    }
  }
};

export const submitColumnsDataSaga = function* submitColumnDataSaga({
  payload,
}: PayloadAction<{columnIds: string[]}>) {
  if (payload) {
    try {
      yield put(setColumnSubmittingStatus(FormStatus.Pending));
      const {accessToken} = yield call(acquireAuthResult, msalInstance);
      yield call(submitAllColumns, accessToken, payload.columnIds);
      yield put(updateSubmittedColumnsCellData({columns: payload.columnIds}));
      yield put(setColumnSubmittingStatus(FormStatus.Ready));
    } catch (err) {
      yield put(setColumnSubmittingStatus(FormStatus.Ready));
      console.warn(err);
      const responseError = generalResponseErrorResolver(err);
      if (responseError) {
        yield put(setGlobalInformation(responseError));
      }
    }
  }
};

export const updateColumnDataSaga = function* updateColumnDataSaga({
  payload,
}: PayloadAction<{
  columnId: string;
  reportingTemplateType: ReportingTableTemplateType;
}>) {
  if (payload) {
    try {
      yield put(setColumnSubmittingStatus(FormStatus.Pending));
      const {accessToken} = yield call(acquireAuthResult, msalInstance);
      yield call(updateColumnData, accessToken, payload.columnId);
      yield put(
        updateColumnCellData({
          columnId: payload.columnId,
          submitted: true,
          isLocked: true,
          reportingTableTemplateType: payload.reportingTemplateType,
        })
      );
      yield put(setColumnSubmittingStatus(FormStatus.Ready));
    } catch (err) {
      yield put(setColumnSubmittingStatus(FormStatus.Ready));
      console.warn(err);
      const responseError = generalResponseErrorResolver(err);
      if (responseError) {
        yield put(setGlobalInformation(responseError));
      }
    }
  }
};

export const cancelUpdateColumnSaga = function* cancelUpdateColumnSaga({
  payload,
}: PayloadAction<{
  columnId: string;
  reportingTemplateType: ReportingTableTemplateType;
}>) {
  if (payload) {
    try {
      yield put(setColumnSubmittingStatus(FormStatus.Pending));
      const {accessToken} = yield call(acquireAuthResult, msalInstance);
      const response: {cells: {id: string; value: number | null}[]} =
        yield call(cancelUpdateColumn, accessToken, payload.columnId);
      yield put(
        updateColumnCellData({
          columnId: payload.columnId,
          submitted: true,
          isLocked: true,
          reportingTableTemplateType: payload.reportingTemplateType,
          cells: response.cells,
        })
      );
      yield put(setColumnSubmittingStatus(FormStatus.Ready));
    } catch (err) {
      yield put(setColumnSubmittingStatus(FormStatus.Ready));
      console.warn(err);
      const responseError = generalResponseErrorResolver(err);
      if (responseError) {
        yield put(setGlobalInformation(responseError));
      }
    }
  }
};

const exportReportingDataSaga = function* exportReportingDataSaga({
  payload,
}: PayloadAction<{workflowId: string}>) {
  if (payload) {
    try {
      yield put(setExportPending(true));
      const {accessToken} = yield call(acquireAuthResult, msalInstance);
      yield call(exportReportingData, accessToken, payload.workflowId);
    } catch (err) {
      console.warn(err);
      const responseError = generalResponseErrorResolver(err);
      if (responseError) {
        yield put(setGlobalInformation(responseError));
      }
    } finally {
      yield put(setExportPending(false));
    }
  }
};

const watchDataCellSubmitSaga = function* watchDataCellSubmitSaga() {
  type SubmitDataCellDataType = typeof submitDataCellAction;
  type ActionChannelType = typeof submitDataCellAction.type;
  const channel: ActionChannelType = yield actionChannel(
    submitDataCellAction.type
  );

  while (true) {
    const action: SubmitDataCellDataType = yield take(channel);
    yield fork(debouncedSubmitDataCellDataSaga, action);
  }
};

export const reportingTableSagaWatcher = function* reportingTableSagaWatcher() {
  yield all([
    fork(watchDataCellSubmitSaga),
    takeLatest(submitColumnsData.type, submitColumnsDataSaga),
    takeEvery(submitMultipleCellsData.type, submitMultipleCellsSaga),
    takeLatest(submitColumnData.type, submitColumnDataSaga),
    takeLatest(exportReportingDataAction.type, exportReportingDataSaga),
    takeEvery(validateColumnsAndShowNext.type, validateColumnsAndShowNextSaga),
    takeEvery(validateColumnsAndSubmit.type, validateColumnsAndSubmitSaga),
    takeEvery(unlockColumnAction.type, unlockColumnSaga),
    takeEvery(cancelUpdateColumnAction.type, cancelUpdateColumnSaga),
    takeEvery(updateColumnDataAction.type, updateColumnDataSaga),
  ]);
};
