import axios from 'axios';
import {
  AskAIQueryPayload,
  DocumentExplorer,
  FileEditFormOptions,
  FileEditFormPayload,
} from '../types';
import config from '../config';

export const getDocumentExplorerData = async (token: string, page?: number) => {
  const {data} = await axios.get<DocumentExplorer[]>(
    `${config.APP_URI}/document-explorer`,
    {
      params: {
        page: page || 1,
      },
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );
  return data[0];
};

export const getFileEditFormOptions = async (token: string, id: string) => {
  const {data} = await axios.get<FileEditFormOptions>(
    `${config.APP_URI}/document/${id}/options`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );
  return data;
};

export const putFileEditUpdate = async (
  token: string,
  payload: FileEditFormPayload
) => {
  const {id, ...rest} = payload;
  const {data} = await axios.put<FileEditFormPayload>(
    `${config.APP_URI}/document/${id}`,
    rest,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );
  return data;
};

export const postAskAiQuery = async (
  token: string,
  payload: AskAIQueryPayload
) => {
  const {data} = await axios.post(
    `${config.APP_URI}/document-explorer/askai`,
    payload,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );
  return data;
};

export const getDocumentTransactionsByEntityTags = async (
  token: string,
  entityTags: string[]
) => {
  const {data} = await axios.get(
    `${config.APP_URI}/document/transactions?entity_tags=${entityTags}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );
  return data;
};

export const getDocumentReportingPeriodsByEntityTags = async (
  token: string,
  entityTags: string[]
) => {
  const {data} = await axios.get(
    `${config.APP_URI}/document/reporting-periods?entity_tags=${entityTags}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );
  return data;
};
