import {put, all, takeLatest} from 'redux-saga/effects';
import {clearCompanyDetailsDataAction} from '../../actions';
import {clearCompanyInvestmentOverviewData} from '../../reducers/companyDetailsInvestmentOverview';
import {clearCompanyDetailsCompanyData} from '../../reducers/companyDetailsCompanySlice';
import {clearCompanyMonitoringData} from '../../reducers/companyDetailsMonitoringSlice';

export const clearCompanyDetailsSaga = function* clearCompanyDetailsSaga() {
  yield all([
    put(clearCompanyDetailsCompanyData()),
    put(clearCompanyInvestmentOverviewData()),
    put(clearCompanyMonitoringData()),
  ]);
};

export const companyDetailsSagaWatcher = function* companyDetailsSagaWatcher() {
  yield all([
    takeLatest(clearCompanyDetailsDataAction.type, clearCompanyDetailsSaga),
  ]);
};
