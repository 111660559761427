import React from 'react';
import {useTranslation} from 'react-i18next';
import styles from './NoDocumentsAvailable.module.scss';
import Icon from '../../../Icon/Icon';

const NoDocumentsAvailable: React.FC = () => {
  const {t} = useTranslation();
  return (
    <div className={styles.wrapper}>
      <Icon name={'no-document'} className={styles.noDocumentsContainerIcon} />
      <span>{t('Documents.NoDocumentsAvailable.Label')}</span>
    </div>
  );
};

export default NoDocumentsAvailable;
