import {createAction, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {
  ActiveCompanyData,
  AxisMetricsOption,
  ChartDataRequestPendingPayload,
  DataVisualisaitonChartType,
  DataVisualisationDataSource,
  KpiFilterOption,
  PortfolioCompaniesChartData,
  QuarterlyDataAvailability,
} from '../types/';
import {
  generateChartFilterConfig,
  getDataVisualisationChartDataKeys,
  handleChangeFilterCompaniesFilter,
} from '../utils/';

export const getActiveCompaniesQuarterlyDataAction = createAction(
  'companies/get-active-companies-data',
  (
    name: string,
    date?: string,
    chartType?: DataVisualisaitonChartType,
    filterConfig?: KpiFilterOption[]
  ) => {
    return {
      payload: {
        name,
        date,
        chartType,
        filterConfig,
      },
    };
  }
);

export const saveChartFilterConfigAction = createAction(
  'companies/save-filter-config',
  (chartType: any) => {
    return {
      payload: {
        chartType,
      },
    };
  }
);

export interface PortfolioCompaniesChartDataSlice {
  data: PortfolioCompaniesChartData;
}

export const initialState: PortfolioCompaniesChartDataSlice = {
  data: {
    barChartData: null,
    barChartFilter: null,
    savedBarChartData: null,
    savedBarChartFilter: null,
    treemapData: null,
    treemapFilter: null,
    savedTreemapData: null,
    savedTreemapFilter: null,
    bubbleChartData: null,
    bubbleChartFilter: null,
    savedBubbleChartData: null,
    savedBubbleChartFilter: null,
    barChartDataRequestPending: false,
    savedBarChartDataRequestPending: false,
    treemapDataRequestPending: false,
    savedTreemapDataRequestPending: false,
    bubbleChartDataRequestPending: false,
    savedBubbleChartDataRequestPending: false,
    dataSource: DataVisualisationDataSource.FundInvestment,
  },
};

const portfolioCompaniesChartDataSlice = createSlice({
  name: 'portfolio-companies-chart-data',
  initialState,
  reducers: {
    setPortfolioCompaniesChartData(
      state: PortfolioCompaniesChartDataSlice,
      action: PayloadAction<{
        companies: ActiveCompanyData[];
        quarterlyData: QuarterlyDataAvailability;
        axisMetricsOptions: AxisMetricsOption[];
        chartTypes: DataVisualisaitonChartType[];
        filterConfig?: KpiFilterOption[];
      }>
    ) {
      action.payload.chartTypes.forEach(chart => {
        const {chartKey, filterKey, filterKpis} =
          getDataVisualisationChartDataKeys(chart, state.data.dataSource);

        const filter =
          action.payload.filterConfig ||
          generateChartFilterConfig(
            action.payload.companies,
            filterKpis,
            action.payload.quarterlyData,
            action.payload.axisMetricsOptions,
            state.data.dataSource
          );

        state.data[chartKey] = action.payload.companies;
        state.data[filterKey] = filter;
      });
    },
    updateChartFilter(
      state: PortfolioCompaniesChartDataSlice,
      action: PayloadAction<{
        name: string;
        value: string | number | string[] | number[];
        chartType: DataVisualisaitonChartType;
      }>
    ) {
      const {chartKey, filterKey} = getDataVisualisationChartDataKeys(
        action.payload.chartType,
        state.data.dataSource
      );

      if (state.data && state.data[filterKey] && state.data[chartKey]) {
        // Apply filtering
        state.data[filterKey] = handleChangeFilterCompaniesFilter(
          action.payload.name,
          action.payload.value,
          state.data[filterKey] || [],
          state.data[chartKey] || []
        );
      }
    },
    editSavedFilterConfig(
      state: PortfolioCompaniesChartDataSlice,
      action: PayloadAction<{
        chartType: DataVisualisaitonChartType;
        savedFilter: KpiFilterOption[];
        data: ActiveCompanyData[];
      }>
    ) {
      const {chartKey, filterKey} = getDataVisualisationChartDataKeys(
        action.payload.chartType,
        state.data.dataSource
      );

      state.data[filterKey] = action.payload.savedFilter;
      state.data[chartKey] = action.payload.data;
    },
    setChartDataRequestPending(
      state: PortfolioCompaniesChartDataSlice,
      action: PayloadAction<
        ChartDataRequestPendingPayload | Array<ChartDataRequestPendingPayload>
      >
    ) {
      const updateRequestPending = (
        pendingPayload: ChartDataRequestPendingPayload
      ) => {
        const {requestPendingKey, isLoading} = pendingPayload;
        state.data[requestPendingKey] = isLoading;
      };

      if (action.payload instanceof Array) {
        action.payload.forEach(pendingPayload => {
          updateRequestPending(pendingPayload);
        });
      } else {
        updateRequestPending(action.payload);
      }
    },
    setChartDataSource(
      state: PortfolioCompaniesChartDataSlice,
      action: PayloadAction<{
        dataSource: DataVisualisationDataSource;
      }>
    ) {
      state.data.dataSource = action.payload.dataSource;
    },
  },
});

export const {
  setPortfolioCompaniesChartData,
  updateChartFilter,
  editSavedFilterConfig,
  setChartDataRequestPending,
  setChartDataSource,
} = portfolioCompaniesChartDataSlice.actions;
export default portfolioCompaniesChartDataSlice.reducer;
