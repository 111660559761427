import React, {MouseEvent} from 'react';
import styles from './FundBarsCell.module.scss';
import classnames from 'classnames';
import {useTooltip} from '../../../../../hooks';
import {Position} from '../../../../../context/TooltipContext';

type FundBarsCellProps = {
  funds: {fundColor: string; fundName: string}[];
};

const FundBarsCell = ({funds}: FundBarsCellProps): JSX.Element => {
  const {showTooltip, hideTooltip} = useTooltip();
  const handleShowTooltip = (
    event: MouseEvent<HTMLSpanElement>,
    fundName: string
  ) => {
    showTooltip({
      target: event.target as HTMLElement,
      content: fundName,
      position: Position.Top,
    });
  };
  return (
    <div className={styles.wrapper}>
      {funds &&
        funds.map(({fundColor, fundName}, i) => (
          <span
            onMouseLeave={hideTooltip}
            onMouseEnter={event => handleShowTooltip(event, fundName)}
            className={classnames(styles.bar)}
            style={{
              backgroundColor: `rgb(var(--colors-${fundColor}))`,
            }}
            key={`bar-cell-${i}`}
          ></span>
        ))}
    </div>
  );
};

export default FundBarsCell;
