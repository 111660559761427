import React, {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {valueFormat} from '../../../utils';
import homeLayoutStyles from './PageHeader.module.scss';
import pageLayoutStyles from './PageHeaderTemp.module.scss';
import {
  CompanyStatus,
  FundState,
  ActivityBadgeProps,
  Size,
} from '../../../types';
import ActivityBadge from '../activity-badge/ActivityBadge';
import {EntityLogo, Icon} from '../../global';
import useBreakpoint from '../../../hooks/useBreakpoint';

interface PageHeaderProps {
  id: string;
  title?: string;
  asAt?: {
    label: string;
    value: string | number;
    format?: string;
  };
  withLogo?: boolean;
  color?: string;
  count?: string | number;
  fundStatus?: FundState;
  companyStatus?: CompanyStatus;
  iconUrl?: string | null;
}

const PageHeader: React.FC<PageHeaderProps> = ({
  id,
  title,
  asAt,
  withLogo,
  color,
  count,
  fundStatus,
  companyStatus,
  iconUrl,
}) => {
  const {pathname} = useLocation();
  const styles = pathname === '/' ? homeLayoutStyles : pageLayoutStyles;
  const [activityBadgeInfo, setActivityBadgeInfo] =
    useState<ActivityBadgeProps | null>(null);
  const [showAsAtWarningIcon, setShowAsAtWarningIcon] = useState(false);
  const [clientLogoBgColor, setClientLogoBgColor] = useState('pacific-blue');
  const {isXSmall, isSmall} = useBreakpoint();

  useEffect(() => {
    if (fundStatus) {
      switch (fundStatus) {
        case FundState.Inactive:
          setActivityBadgeInfo({
            label: 'Inactive fund',
            color: 'gray-6',
            textColor: 'white',
          });
          setShowAsAtWarningIcon(true);
          break;
        default:
          setActivityBadgeInfo(null);
          setShowAsAtWarningIcon(false);
      }
    }
  }, [fundStatus]);

  useEffect(() => {
    if (companyStatus) {
      switch (companyStatus) {
        case CompanyStatus.Active:
          setActivityBadgeInfo({
            label: 'Global.Active',
            color: 'lan-green',
            textColor: 'black',
          });
          setShowAsAtWarningIcon(false);
          break;
        case CompanyStatus.Exited:
          setActivityBadgeInfo({
            label: 'Global.Exited',
            color: 'lan-violet',
            textColor: 'white',
          });
          setShowAsAtWarningIcon(true);
          break;
        case CompanyStatus.WrittenOff:
          setActivityBadgeInfo({
            label: 'Global.WrittenOff',
            color: 'gray-6',
            textColor: 'white',
          });
          setShowAsAtWarningIcon(true);
          break;
        default:
          setActivityBadgeInfo(null);
          setShowAsAtWarningIcon(false);
      }
    }
  }, [companyStatus]);

  useEffect(() => {
    // Calculate Client logo background color based on the client name. We should receive client colors from the BE eventually.
    if (title) {
      const letter = title[0].toLowerCase() ?? '';
      switch (letter) {
        case 'w':
          setClientLogoBgColor('green-haze');
          break;
        case 'd':
          setClientLogoBgColor('pacific-blue');
          break;
        case 'm':
          setClientLogoBgColor('flirt');
          break;
        case 'j':
          setClientLogoBgColor('pompadour');
          break;
        case 'h':
          setClientLogoBgColor('revolver');
          break;
        default:
          setClientLogoBgColor('pacific-blue');
      }
    }
  }, [title]);

  return (
    <div id={id} className={styles.wrapper}>
      {withLogo && (
        <EntityLogo
          name={title ?? ''}
          color={color ?? clientLogoBgColor}
          url={iconUrl ?? null}
          size={
            styles === homeLayoutStyles && (isXSmall || isSmall)
              ? Size.Medium
              : Size.Large
          }
          className={styles.logo}
        />
      )}
      {!withLogo && color && (
        <div
          style={{
            backgroundColor:
              fundStatus === FundState.Inactive
                ? `rgb(var(--colors-gray-4))`
                : `rgb(var(--colors-${color}))`,
          }}
          className={styles.colorLine}
        />
      )}
      <div id={`${id}-content`} className={styles.content}>
        <div id={`${id}-content-title`} className={styles.title}>
          <div>{title}</div>
          {count && (
            <div
              id={`${id}-content-title-count`}
              className={styles.titleCounter}
            >
              {count}
            </div>
          )}
          {activityBadgeInfo && (
            <ActivityBadge
              label={activityBadgeInfo.label}
              color={activityBadgeInfo.color}
              textColor={activityBadgeInfo.textColor}
            />
          )}
        </div>

        <div id={`${id}-content-subtitle`} className={styles.subTitle}>
          {asAt && showAsAtWarningIcon && (
            <div className={styles.warning}>
              <Icon className={styles.asAtIcon} name={'warning'} />
              <span>Data below is </span>
            </div>
          )}
          {asAt && (
            <>
              {asAt.label} {valueFormat(asAt.value, asAt.format).value}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default PageHeader;
