import React from 'react';
import {ComponentWidgetType} from '../../../types';
import FundExposureChart from '../fund-exposure-chart/FundExposureChart';
import styles from './FundExposureWidget.module.scss';

const FundExposureWidget: React.FC<ComponentWidgetType> = ({data}) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.charts}>
        {data?.map(item => (
          <div className={styles.chartsItems} key={item.label}>
            <FundExposureChart {...item} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default FundExposureWidget;
