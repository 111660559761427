import React from 'react';
import classnames from 'classnames';
import styles from './ReportingTableGroupHeaderCell.module.scss';
import {ReportingTableCell} from '../../../../../types';
import {useTranslation} from 'react-i18next';

type ReportingTableGroupHeaderCellProps = {
  cell: ReportingTableCell;
  classNames?: string;
};

const ReportingTableGroupHeaderCell = ({
  cell,
  classNames,
}: ReportingTableGroupHeaderCellProps) => {
  const {t} = useTranslation();

  return (
    <th
      id="blank"
      headers="blank"
      className={classnames(styles.cell, {
        [`${classNames}`]: !!classNames,
        [styles.notVisible]: !cell.isVisible,
      })}
    >
      <div className={styles.groupTableHeader}>
        {cell.data?.value}{' '}
        <span className={styles.groupHeaderCellSpan}>{t('Global.Group')}</span>
      </div>
    </th>
  );
};

export default ReportingTableGroupHeaderCell;
