import React, {Suspense, useEffect} from 'react';
import AppLayout from '../layouts/app-layout/AppLayout';
import {renderRoutes} from 'react-router-config';
import {RouterProps} from '../types/index';
import {useAppSelector} from '../hooks/useReduxHooks';
import {MsalProvider} from '@azure/msal-react';
import {IPublicClientApplication} from '@azure/msal-browser';
import Navigation from '../ui-components/navigation/Navigation';
import {disabledNavRoutePaths} from '../routePaths';
import {ToolTipProvider} from '../context/TooltipContext';
import {
  FileUploadProgressPopup,
  ModalHandler,
  UserSessionTimeOutHandler,
} from '../ui-components/';
import '../styles/index.scss';
import useIsUploadDocumentsEnabled from '../hooks/useIsUploadDocumentsEnabled';
import 'react-toastify/dist/ReactToastify.css';

interface Props extends RouterProps {
  pca: IPublicClientApplication;
}

const App: React.FC<Props> = (props: Props): JSX.Element => {
  const theme = useAppSelector(state => state.theme.currentTheme);

  const {
    releaseDataCollectionEnabled,
    releaseDocumentRepositoryEnabled,
    releaseInFlightModeEnabled,
  } = useAppSelector(state => state.featureFlags.data);

  const {data: userProfile} = useAppSelector(state => state.userProfile);
  const {route, pca, location} = props;
  const isNavigationDisabled: boolean = disabledNavRoutePaths.includes(
    location.pathname
  );

  const isUploadDocumentsEnabled = useIsUploadDocumentsEnabled(
    userProfile,
    location.pathname
  );

  useEffect(() => {
    document.body.dataset.theme = theme;
  }, [theme]);

  return (
    <MsalProvider instance={pca}>
      <ToolTipProvider>
        <AppLayout>
          {!isNavigationDisabled && (
            <Navigation
              isUploadDocumentsEnabled={isUploadDocumentsEnabled}
              releaseDataCollectionEnabled={releaseDataCollectionEnabled}
              releaseDocumentRepositoryEnabled={
                releaseDocumentRepositoryEnabled
              }
              releaseInFlightModeEnabled={releaseInFlightModeEnabled}
            />
          )}
          <Suspense fallback={null}>
            <UserSessionTimeOutHandler>
              <ModalHandler>{renderRoutes(route.routes)}</ModalHandler>
              <FileUploadProgressPopup />
            </UserSessionTimeOutHandler>
          </Suspense>
        </AppLayout>
      </ToolTipProvider>
    </MsalProvider>
  );
};

export default App;
