import React, {ChangeEvent, KeyboardEvent, useEffect, useRef} from 'react';
import styles from './DocumentExplorerSearchInput.module.scss';
import {Func} from '../../../types';
import {Icon} from '../../global';
import classnames from 'classnames';

interface DocumentExplorerSearchInputProps {
  value: string;
  onChange: Func<[string], void>;
  placeholder?: string;
  onEnterPress?: Func<[], void>;
}
const DocumentExplorerSearchInput: React.FC<
  DocumentExplorerSearchInputProps
> = ({value, onChange, placeholder, onEnterPress}) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    onChange(event.target.value);
  };

  const handleKeyPress = (event: KeyboardEvent<HTMLTextAreaElement>) => {
    if (!event.shiftKey && event.key === 'Enter' && onEnterPress) {
      event.preventDefault();
      onEnterPress();
    }
  };

  const isMoreThenOneRow = (textAreaRef.current?.scrollHeight || 0) > 48;

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = '0px';
      const scrollHeight = textAreaRef.current.scrollHeight;
      textAreaRef.current.style.height = scrollHeight + 'px';
    }
  }, [textAreaRef, value]);

  return (
    <div
      className={classnames(
        styles.wrapper,
        isMoreThenOneRow && styles.moreThenOneRow
      )}
    >
      <Icon name={'search'} className={styles.icon} />
      <textarea
        onKeyDown={handleKeyPress}
        ref={textAreaRef}
        placeholder={placeholder}
        className={styles.textarea}
        value={value}
        onChange={handleChange}
      ></textarea>
    </div>
  );
};

export default DocumentExplorerSearchInput;
