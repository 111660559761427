import axios from 'axios';
import config from '../config';

export const getFilePreviewData = async (token: string, id: string) => {
  const {data} = await axios.get(`${config.APP_URI}/document/${id}/preview`, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
  });
  return data;
};
