import {all, call, put, takeEvery} from 'redux-saga/effects';
import {acquireAuthResult, msalInstance} from '../../index';
import {updateLayoutProperty} from '../../api/setUserLayoutProperty';
import {updateLayoutSettingsAction} from '../../reducers/userLayourPropertySlice';
import {UpdateLayoutPropertyPayload} from '../../types/userLayoutProperty';
import {errorHandlerAction} from '../../actions';

export const updateLayoutSettingsSaga = function* updateLayoutSettingsSaga({
  payload,
}: {
  payload: UpdateLayoutPropertyPayload;
  type: string;
}) {
  if (payload) {
    try {
      const {accessToken} = yield call(acquireAuthResult, msalInstance);
      yield call(updateLayoutProperty, accessToken, payload);
    } catch (error) {
      console.warn(error);
      yield put(errorHandlerAction({error}));
    }
  }
};

export const userProfileSagaWatcher = function* userProfileSagaWatcher() {
  yield all([
    takeEvery(updateLayoutSettingsAction.type, updateLayoutSettingsSaga),
  ]);
};
