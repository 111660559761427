import {createSelector} from '@reduxjs/toolkit';
import {RootState} from '../store';
import {FormStatus} from '../types';

const userAdmin = (state: RootState) => state.userAdministration;

const userAdminSelector = createSelector([userAdmin], userAdmin => {
  return {
    dataLoaded: !!userAdmin.users.data,
    label: userAdmin.users.data?.label || 'User administration',
    allowedDomains: userAdmin.users.data?.users.allowedDomains || [],
    updateInProgress:
      userAdmin.users.isRequestPending ||
      userAdmin.users.formStatus === FormStatus.Pending,
  };
});

export default userAdminSelector;
