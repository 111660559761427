import {
  addMonths,
  addQuarters,
  differenceInMonths,
  differenceInQuarters,
} from 'date-fns';

export const getMonitoringXAxisConfig = (
  from: Date,
  to: Date,
  reportingInterval: string,
  isLarge: boolean,
  isXLarge: boolean,
  isXXLarge: boolean
): {ticks: number[]; format: string} => {
  let ticksCount = 0;
  let format = '';
  let ticks: number[] = [];

  if (reportingInterval === 'monthly') {
    ticksCount = differenceInMonths(to, from);
    format = "MMM 'yy";
    ticks = Array.from(Array(ticksCount + 1)).map((_, idx) =>
      addMonths(to, -idx).getTime()
    );
  }

  if (reportingInterval === 'quarterly') {
    ticksCount = differenceInQuarters(to, from);
    format = "QQ 'YY";
    ticks = Array.from(Array(ticksCount + 1)).map((_, idx) =>
      addQuarters(to, -idx).getTime()
    );
  }

  if (isLarge) {
    ticks = ticks.filter((_, idx) => !(idx % Math.ceil(ticksCount / 12)));
  }
  if (isXLarge || isXXLarge) {
    ticks = ticks.filter((_, idx) => !(idx % Math.ceil(ticksCount / 18)));
  }
  return {
    ticks,
    format,
  };
};
