import {createSlice, PayloadAction, createAction} from '@reduxjs/toolkit';
import {UserProfileData} from '../types';

export const setUserAnalyticsDataAction = createAction(
  'user-profile/set-user-analytics',
  (userProfileData: UserProfileData) => {
    return {
      payload: {
        userProfileData,
      },
    };
  }
);

export const updateUserDataVisibilityAction = createAction(
  'user-profile/update-user-data-visibility',
  (payload: {value: boolean; userId: string}) => {
    return {
      payload,
    };
  }
);

export interface UserProfileServiceData {
  data: UserProfileData | null;
  isLoading: boolean;
}

const initialState: UserProfileServiceData = {
  data: null,
  isLoading: false,
};

const userProfileServiceSlice = createSlice({
  name: 'user-profile',
  initialState,
  reducers: {
    getUserProfileServiceData(state: UserProfileServiceData) {
      state.isLoading = true;
    },
    setUserProfileServiceData(
      state: UserProfileServiceData,
      action: PayloadAction<UserProfileData>
    ) {
      state.data = action.payload;
      state.isLoading = false;
    },
    setUserProfileServiceLoading(
      state: UserProfileServiceData,
      action: PayloadAction<boolean>
    ) {
      state.isLoading = action.payload;
    },
    setUserProfileServiceShowSignedOffData(
      state: UserProfileServiceData,
      action: PayloadAction<boolean>
    ) {
      if (state.data) {
        state.data.isSignedOffData = action.payload;
      }
    },
  },
});

export const {
  getUserProfileServiceData,
  setUserProfileServiceData,
  setUserProfileServiceLoading,
  setUserProfileServiceShowSignedOffData,
} = userProfileServiceSlice.actions;
export default userProfileServiceSlice.reducer;
