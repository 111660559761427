import {createAction, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {
  ActiveCompanyData,
  Companies,
  CompaniesSortPayload,
  CompaniesTiles,
  CompanyFinancialData,
  ExitedCompanyData,
  InvestmentPerformanceData,
  WrittenOffCompanyData,
} from '../types';

import {RootState} from '../store';

export const getCompanyPerformanceDataAction = createAction(
  'companies/get-company-performance-data',
  (companyId: string, companyType: string, currency: string) => {
    return {
      payload: {
        companyId,
        companyType,
        currency,
      },
    };
  }
);

interface CompaniesSlice {
  data: Companies | null;
  tilesData: CompaniesTiles | null;
}

const initialState: CompaniesSlice = {
  data: null,
  tilesData: null,
};

const companiesSlice = createSlice({
  name: 'companies',
  initialState,
  reducers: {
    setCompaniesList(state: CompaniesSlice, action: PayloadAction<Companies>) {
      state.data = action.payload;
    },
    clearCompaniesList(state: CompaniesSlice) {
      state.data = null;
    },
    setTilesData(state: CompaniesSlice, action: PayloadAction<CompaniesTiles>) {
      state.tilesData = action.payload;
    },
    clearTilesData(state: CompaniesSlice) {
      state.tilesData = null;
    },
    setCompanyPerformanceData(
      state: CompaniesSlice,
      action: PayloadAction<{
        performanceData: Array<InvestmentPerformanceData>;
        companyId: string;
        companyType: 'active' | 'exited';
        currency: string;
      }>
    ) {
      const {
        performanceData,
        companyId,
        companyType = 'active',
        currency,
      } = action.payload;

      if (state.data) {
        const updatedCompanyData = state.data[companyType].data.map(item =>
          item.id === companyId && item.currency === currency
            ? {
                ...item,
                performanceData,
              }
            : item
        ) as ActiveCompanyData[] | ExitedCompanyData[];
        state.data[companyType].data = updatedCompanyData;
      }
    },
    sortCompanies(
      state: CompaniesSlice,
      action: PayloadAction<CompaniesSortPayload>
    ) {
      const {status, sortValue, sortDirection} = action.payload;
      if (state.tilesData) {
        const sortableData = state.tilesData[status];
        const field = sortValue as keyof (typeof sortableData)[0];
        const newData = sortableData.sort((a, b) => {
          if (!a[field] || !b[field]) return 0;
          const aValue = (a[field] as CompanyFinancialData).value || 0;
          const bValue = (b[field] as CompanyFinancialData).value || 0;
          return sortDirection === 'asc' ? aValue - bValue : bValue - aValue;
        });

        state.tilesData[status] = newData as ActiveCompanyData[] &
          ExitedCompanyData[] &
          WrittenOffCompanyData[];
      }
    },
  },
});

export const {
  setCompaniesList,
  clearCompaniesList,
  setTilesData,
  clearTilesData,
  sortCompanies,
  setCompanyPerformanceData,
} = companiesSlice.actions;

export default companiesSlice.reducer;

export const selectCompanies = (state: RootState) => state.companies.data;
