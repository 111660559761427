import {useMemo} from 'react';

function useMultiAxesTicksCalculation({
  data,
  leftAxisDataKeys = [],
  rightAxisDataKeys = [],
}: {
  data: Record<string, any>[];
  leftAxisDataKeys: string[];
  rightAxisDataKeys: string[];
}) {
  const {minLeft, maxLeft, minRight, maxRight} = useMemo(() => {
    return data.reduce(
      (acc: any, dataItem) => {
        const leftData = leftAxisDataKeys.map(key => dataItem[key] ?? 0);
        const rightData = rightAxisDataKeys.map(key => dataItem[key] ?? 0);
        const curMinLeft = Math.min(...leftData);
        const curMaxLeft = Math.max(...leftData);
        const curMinRight = Math.min(...rightData);
        const curMaxRight = Math.max(...rightData);
        if (curMinLeft < acc.minLeft) {
          acc.minLeft = curMinLeft;
        }
        if (curMaxLeft > acc.maxLeft) {
          acc.maxLeft = curMaxLeft;
        }
        if (curMinRight < acc.minRight) {
          acc.minRight = curMinRight;
        }
        if (curMaxRight > acc.maxRight) {
          acc.maxRight = curMaxRight;
        }
        return acc;
      },
      {minLeft: 0, maxLeft: 0, minRight: 0, maxRight: 0}
    );
  }, [data, leftAxisDataKeys, rightAxisDataKeys]);

  const {maxLeftYAxis, minLeftAxis, maxRightAxis, minRightAxis} =
    useMemo(() => {
      let biggerLeft =
        Math.abs(maxLeft) > Math.abs(minLeft)
          ? Math.abs(maxLeft)
          : Math.abs(minLeft);
      let biggerRight =
        Math.abs(maxRight) > Math.abs(minRight)
          ? Math.abs(maxRight)
          : Math.abs(minRight);
      let smallerLeft =
        Math.abs(maxLeft) > Math.abs(minLeft)
          ? Math.abs(minLeft)
          : Math.abs(maxLeft);
      let smallerRight =
        Math.abs(maxRight) > Math.abs(minRight)
          ? Math.abs(minRight)
          : Math.abs(maxRight);

      const ratioLeft = Number(Math.abs(smallerLeft) / Math.abs(biggerLeft));
      const ratioRight = Number(Math.abs(smallerRight) / Math.abs(biggerRight));

      if (ratioRight === ratioLeft) {
        if (minRight === 0 && maxLeft === 0) {
          return {
            maxLeftYAxis: +minLeft,
            minLeftAxis: minLeft,
            maxRightAxis: maxRight,
            minRightAxis: -maxRight,
          };
        }
        if (minLeft === 0 && maxRight === 0) {
          return {
            maxLeftYAxis: maxLeft,
            minLeftAxis: -maxLeft,
            maxRightAxis: +minRight,
            minRightAxis: minRight,
          };
        }
        return {
          maxLeftYAxis: maxLeft,
          minLeftAxis: minLeft,
          maxRightAxis: maxRight,
          minRightAxis: minRight,
        };
      }
      const {ratio, side}: {ratio: number; side: 'right' | 'left'} =
        Math.abs(ratioRight - 1) < Math.abs(ratioLeft - 1)
          ? {ratio: ratioRight || 1, side: 'right'}
          : {ratio: ratioLeft || 1, side: 'left'};
      const ratioDirection: 'top' | 'bottom' =
        side === 'right'
          ? Math.abs(minRight) > Math.abs(maxRight)
            ? 'top'
            : 'bottom'
          : Math.abs(minLeft) > Math.abs(maxLeft)
          ? 'top'
          : 'bottom';

      let maxLeftYAxis = 0;
      let minLeftAxis = 0;
      let maxRightAxis = 0;
      let minRightAxis = 0;

      if (side === 'right') {
        if (ratioDirection === 'top') {
          minRightAxis = -Number(biggerRight.toFixed(6));
          maxRightAxis = Number((biggerRight * ratio).toFixed(6));
          minLeftAxis =
            biggerLeft === Math.abs(minLeft)
              ? -Number(biggerLeft).toFixed(6)
              : -Number(biggerLeft / ratio).toFixed(6);
          maxLeftYAxis =
            biggerLeft === Math.abs(maxLeft)
              ? Number(biggerLeft.toFixed(6))
              : Number((biggerLeft * ratio).toFixed(6));
        }
        if (ratioDirection === 'bottom') {
          minRightAxis = -Number((biggerRight * ratio).toFixed(6));
          maxRightAxis = Number(biggerRight.toFixed(6));
          minLeftAxis =
            biggerLeft === Math.abs(minLeft)
              ? -Number(biggerLeft).toFixed(6)
              : -Number(biggerLeft * ratio).toFixed(6);
          maxLeftYAxis =
            biggerLeft === Math.abs(maxLeft)
              ? Number(biggerLeft.toFixed(6))
              : Number((biggerLeft / ratio).toFixed(6));
        }
      }
      if (side === 'left') {
        if (ratioDirection === 'top') {
          minLeftAxis = -Number(biggerLeft.toFixed(6));
          maxLeftYAxis = Number((biggerLeft * ratio).toFixed(6));
          minRightAxis =
            biggerRight === Math.abs(minRight)
              ? -Number(biggerRight).toFixed(6)
              : -Number(biggerRight / ratio).toFixed(6);
          maxRightAxis =
            biggerRight === Math.abs(maxRight)
              ? Number(biggerRight.toFixed(6))
              : Number((biggerRight * ratio).toFixed(6));
        }
        if (ratioDirection === 'bottom') {
          minLeftAxis = -Number((biggerLeft * ratio).toFixed(6));
          maxLeftYAxis = Number(biggerLeft.toFixed(6));
          minRightAxis =
            biggerRight === Math.abs(minRight)
              ? -Number(biggerRight).toFixed(6)
              : -Number(biggerRight * ratio).toFixed(6);
          maxRightAxis =
            biggerRight === Math.abs(maxRight)
              ? Number(biggerRight.toFixed(6))
              : Number((biggerRight / ratio).toFixed(6));
        }
      }

      return {
        maxLeftYAxis,
        minLeftAxis,
        maxRightAxis,
        minRightAxis,
      };
    }, [data, minLeft, maxLeft, minRight, maxRight]);

  const {ticksLeftAxis, ticksRightAxis} = useMemo(() => {
    const biggerLeft = Math.max(maxLeftYAxis, Math.abs(minLeftAxis));
    const biggerRight = Math.max(maxRightAxis, Math.abs(minRightAxis));

    const stepLeft = Math.abs(biggerLeft / 5);
    const stepRight = Math.abs(biggerRight / 5);
    const positiveLeftSteps = Math.ceil(Math.abs(maxLeftYAxis) / stepLeft);
    const negativeLeftSteps = Math.ceil(Math.abs(minLeftAxis) / stepLeft);
    const positiveRightSteps = Math.ceil(Math.abs(maxRightAxis) / stepRight);
    const negativeRightSteps = Math.ceil(Math.abs(minRightAxis) / stepRight);

    let ticksLeftAxis: number[] = [];

    for (let i = 0; i >= -negativeLeftSteps; i--) {
      ticksLeftAxis = [i * stepLeft, ...ticksLeftAxis];
    }
    for (let i = 1; i <= positiveLeftSteps; i++) {
      ticksLeftAxis = [...ticksLeftAxis, i * stepLeft];
    }

    let ticksRightAxis: number[] = [];
    for (let i = 0; i >= -negativeRightSteps; i--) {
      ticksRightAxis = [i * stepRight, ...ticksRightAxis];
    }
    for (let i = 1; i <= positiveRightSteps; i++) {
      ticksRightAxis = [...ticksRightAxis, i * stepRight];
    }
    return {ticksLeftAxis, ticksRightAxis};
  }, [maxLeftYAxis, minLeftAxis, maxRightAxis, minRightAxis]);

  return {
    maxLeftYAxis,
    minLeftAxis,
    maxRightAxis,
    minRightAxis,
    ticksRightAxis,
    ticksLeftAxis,
  };
}

export default useMultiAxesTicksCalculation;
