import React from 'react';
import classnames from 'classnames';
import styles from './ReportingTableHeaderCell.module.scss';
import {ReportingTableCell} from '../../../../../types';
import {useTooltip} from '../../../../../hooks';
import {Position} from '../../../../../context/TooltipContext';

type ReportingTableHeaderCellProps = {
  cell: ReportingTableCell;
  classNames?: string;
  verticalAlign?: 'top' | 'middle' | 'bottom';
  textAlign?: 'left' | 'center' | 'right';
};
interface TooltipContentProps {
  description: string;
}

const ReportingTableHeaderCell = ({
  cell,
  classNames,
  verticalAlign = 'middle',
  textAlign = 'center',
}: ReportingTableHeaderCellProps) => {
  const {showTooltip, hideTooltip} = useTooltip();
  const TooltipContent: React.FC<TooltipContentProps> = ({description}) => {
    return <>{description}</>;
  };

  const handleMouseEnter = (e: React.MouseEvent) => {
    cell.data?.description &&
      showTooltip({
        content: <TooltipContent description={cell.data?.description} />,
        position: Position.Top,
        target: e.currentTarget as HTMLElement,
        className: styles.tooltip,
        hasArrow: false,
      });
  };
  return (
    <th
      id="blank"
      headers="blank"
      style={{textAlign, verticalAlign}}
      className={classnames(styles.cell, {
        [`${classNames}`]: !!classNames,
        [styles.notVisible]: !cell.isVisible,
        [styles.clickable]: !!cell.data?.description,
      })}
    >
      <div className={styles.tableHeader}>
        <span onMouseEnter={handleMouseEnter} onMouseLeave={hideTooltip}>
          {cell.data?.value}
        </span>
      </div>
    </th>
  );
};

export default ReportingTableHeaderCell;
