import React, {useMemo} from 'react';
import classnames from 'classnames';
import styles from './SvgLogo.module.scss';

interface SvgLogoProps {
  title?: string;
  svgImage?: JSX.Element | string;
  className?: string;
  bgColor?: string;
}

export const SvgLogo: React.FC<SvgLogoProps> = ({
  title,
  svgImage,
  className = '',
  bgColor,
}) => {
  const classNames = useMemo(() => {
    const letter = !!title?.length ? title[0].toLowerCase() : '';
    return {
      [className]: !!className,
      [styles.wrapper]: true,
      [styles[`letter_${letter}`]]: !!letter,
    };
  }, [title, className]);
  return (
    <div
      style={{background: bgColor ? `rgb(var(--colors-${bgColor}))` : ''}}
      className={classnames(classNames)}
    >
      {svgImage ? (
        typeof svgImage === 'string' ? (
          <img src={svgImage} alt={title} />
        ) : (
          svgImage
        )
      ) : !!title ? (
        title[0]
      ) : null}
    </div>
  );
};
