import React, {useState} from 'react';
import {
  ButtonStyle,
  ImportScenarioTransactionsModalProps,
} from '../../../types';
import {Button, FileUploader, Icon, LoadingSpinner, Modal} from '../../global';

import styles from './ImportScenarioTransactionsModal.module.scss';
import {useTranslation} from 'react-i18next';
import CompanyTransactionsImportTemplate from '../../../assets/templates/company_transactions_import.xlsx';
import FeeTransactionsImportTemplate from '../../../assets/templates/fee_transactions_import.xlsx';
import OtherTransactionsImportTemplate from '../../../assets/templates/other_transactions_import.xlsx';
import FundCapitalTransactionsImportTemplate from '../../../assets/templates/fund_capital_transactions_import.xlsx';

// max file size for scenario transactions is 10 MiB for all cases
const MAX_FILE_SIZE_BYTES = 10 * 1024 * 1024;

const ImportScenarioTransactionsModal: React.FC<
  ImportScenarioTransactionsModalProps
> = ({
  isOpen,
  onClose,
  isLoading,
  templateId,
  handleDeleteFile,
  onUploadFileClick,
  onDropHandler,
}) => {
  const {t} = useTranslation();
  const [uploadedFileName, setUploadedFileName] = useState<string | undefined>(
    undefined
  );
  const [invalidSize, setInvalidSize] = useState(false);

  const onDropHandlerInternal = (fileList: FileList) => {
    const file = fileList.item(0)!;
    if (file.size > MAX_FILE_SIZE_BYTES) {
      setInvalidSize(true);
      return;
    }
    setUploadedFileName(file.name);
    onDropHandler(fileList);
  };

  const onDeleteFileClick = () => {
    if (invalidSize) {
      setInvalidSize(false);
    }
    setUploadedFileName(undefined);
    handleDeleteFile();
  };

  const buildFileDownloadLink = () => {
    switch (templateId) {
      case 'company_transactions_import':
        return CompanyTransactionsImportTemplate;
      case 'fee_transactions_import':
        return FeeTransactionsImportTemplate;
      case 'fund_capital_transactions_import':
        return FundCapitalTransactionsImportTemplate;
      case 'other_transactions_import':
        return OtherTransactionsImportTemplate;
    }
  };

  return (
    <Modal
      title={t('Funds.ImportScenarioTransactionsModal.Title')}
      onClose={onClose}
      closeOnOutsideClick={false}
      isOpen={isOpen}
      className={styles.modalWrapper}
      closeButtonStyleType={ButtonStyle.Secondary}
    >
      <div className={styles.templateHolder}>
        <Icon name={'content-copy'} />
        <p className={styles.templateTitle}>Template</p>
        <p className={styles.templateSubtitle}>
          Download and use the template with required data format
        </p>
        <a
          href={buildFileDownloadLink()}
          download={`${templateId}.xlsx`}
          target="_blank"
          rel="noreferrer"
        >
          <Button
            text={t('Funds.ImportScenarioTransactionsModal.ButtonLabel.Use')}
            className={styles.useTemplateButton}
          />
        </a>
      </div>
      <div>
        {uploadedFileName || invalidSize ? (
          <div className={styles.fileUploadedContent}>
            <Icon name="note" />
            {invalidSize ? (
              <>
                <p>{t('Funds.ImportScenarioTransactionsModal.Error.Size')}</p>
              </>
            ) : (
              <>
                <p>File Uploaded</p>
                <p className={styles.uploadedFileName}>{uploadedFileName}</p>
              </>
            )}
            <Button
              onClick={onDeleteFileClick}
              className={styles.deleteFileButton}
              text={invalidSize ? t('Global.TryAgain') : t('Global.Delete')}
            />
          </div>
        ) : (
          <FileUploader
            className={styles.fileUploader}
            onDropHandler={onDropHandlerInternal}
          >
            {isLoading ? (
              <LoadingSpinner className={styles.loadingSpinner} />
            ) : (
              <div />
            )}
          </FileUploader>
        )}
        <div className={styles.buttons}>
          <Button
            onClick={onClose}
            text={t('Global.Cancel')}
            className={styles.cancelButton}
          />
          {uploadedFileName && (
            <Button
              text={t('Global.Done')}
              className={styles.doneButton}
              onClick={onUploadFileClick}
            />
          )}
        </div>
      </div>
    </Modal>
  );
};

export default ImportScenarioTransactionsModal;
