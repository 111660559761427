import React, {Fragment, useEffect, useMemo, useState} from 'react';
import classnames from 'classnames';
import {
  ButtonStyle,
  CompanyMonitoring,
  DropdownWrapperAlignmentType,
  FormStatus,
  Func,
  GroupedSelectOption,
  HeaderMapping,
  MetricConfigItem,
  MetricsChartDataItem,
  MetricsChartTooltipMetricItem,
  MetricsTableHeaderMapping,
  SaveChartFormState,
  SelectOption,
} from '../../../types';
import {
  Button,
  ConfirmationModal,
  IconButton,
  MetricsChart,
  MetricsPanel,
  MetricsTable,
  SaveChartForm,
  SectionHeader,
  SelectDropdown,
} from '../../';
import styles from './MonitoringDashboard.module.scss';
import {useTranslation} from 'react-i18next';
import {valueFormat} from '../../../utils/value-format';

interface MonitoringDashboardProps
  extends Pick<
    CompanyMonitoring,
    'metricsConfig' | 'metricsData' | 'companyName'
  > {
  id: string;
  locale?: string;
  savedCharts: SelectOption[] | null;
  handleSetSelectedMetricsConfigItem: Func<[MetricConfigItem], void>;
  handleUpdateMetricConfig: Func<[MetricConfigItem], void>;
  handleSaveMetricsChartConfig: Func<
    [SaveChartFormState, MetricConfigItem[]],
    void
  >;
  handleChangeMonitoringFormStatus: Func<[FormStatus], void>;
  handleGetSavedChartMetricsChartConfig: Func<[string], void>;
  handleDeleteSavedChartMetricsChartConfig: Func<[string], void>;
  handleExport: Func<[MetricsChartDataItem[], HeaderMapping[]], void>;
  formStatus: FormStatus;
  error: string;
  formError: Record<string, string> | null;
  resetErrors: Func<[void], void>;
  isRequestPending: boolean;
  historicalData: GroupedSelectOption[][];
  onChangeHistoricalTemplate: Func<[string], void>;
  isExportPending: boolean;
}

const MonitoringDashboard: React.FC<MonitoringDashboardProps> = ({
  id,
  metricsConfig,
  metricsData,
  locale,
  savedCharts = [],
  handleSetSelectedMetricsConfigItem,
  handleUpdateMetricConfig,
  handleSaveMetricsChartConfig,
  handleChangeMonitoringFormStatus,
  handleGetSavedChartMetricsChartConfig,
  handleDeleteSavedChartMetricsChartConfig,
  formStatus,
  error,
  formError,
  resetErrors,
  isRequestPending,
  historicalData,
  onChangeHistoricalTemplate,
  handleExport,
  isExportPending,
}): JSX.Element => {
  const {t} = useTranslation();
  const [isSideBarCollapsed, setIsSideBarCollapsed] = useState(false);
  const [currentChart, setCurrentChart] = useState<string>('new');
  const [savingChartName, setSavingChartName] = useState<string | null>(null);
  const [isSaveChartFormOpen, setIsSaveChartFormOpen] = useState(false);
  const [isDeleteChartFormOpen, setIsDeleteChartFormOpen] = useState(false);
  const [selectedHistoricalTemplate, setSelectedHistoricalTemplate] = useState(
    (historicalData.length ? historicalData : [[]])[0][0]?.value
  );

  const selectedConfig: MetricConfigItem[] = useMemo(
    () =>
      [
        ...metricsConfig.data.flatMap(reportingGroup =>
          reportingGroup.metricsGroups.flatMap(group => group.metrics)
        ),
      ]
        .filter(item => item.isSelected)
        .sort((a, b) => a.orderId - b.orderId),
    [metricsConfig]
  );

  const dropDownOptions: SelectOption[] = useMemo(() => {
    return [
      ...(savedCharts || []),
      {
        id: 'new',
        label:
          'Portfolio.MonitoringDashboard.SectionHeader.SelectDropdownLabel.Chart',
        value: 'new',
      },
    ];
  }, [savedCharts]);

  const currentSelectedChart = useMemo(() => {
    return dropDownOptions.reduce(
      (acc: SaveChartFormState, item) => {
        if (item.value === currentChart && currentChart !== 'new') {
          acc = {
            id: item.value,
            name: item.label as string,
          };
        }
        return acc;
      },
      {
        id: '',
        name: '',
      }
    );
  }, [currentChart, dropDownOptions]);

  const handleSubmitSaveChartMetricsConfig = (state: SaveChartFormState) => {
    handleSaveMetricsChartConfig(state, selectedConfig);
    setSavingChartName(state.name);
  };

  const handleSetCurrentSavedConfig = (val: string | number) => {
    handleGetSavedChartMetricsChartConfig(val as string);
    setCurrentChart(val as string);
  };

  const handleChangeHistoricalTemplate = (value: string) => {
    setSelectedHistoricalTemplate(value);
    setCurrentChart('new');
    onChangeHistoricalTemplate(value);
  };

  const handleCloseSaveModal = () => {
    if (formStatus === FormStatus.Ready) {
      setIsSaveChartFormOpen(false);
      if (formError || error) {
        resetErrors();
      }
    }
  };

  useEffect(() => {
    if (formStatus === FormStatus.Success) {
      handleChangeMonitoringFormStatus(FormStatus.Ready);
      setIsSaveChartFormOpen(false);
      setIsDeleteChartFormOpen(false);
      setCurrentChart(
        (dropDownOptions.find(item => item.label === savingChartName)
          ?.value as string) || 'new'
      );
      setSavingChartName(null);
    }
  }, [
    dropDownOptions,
    formStatus,
    handleChangeMonitoringFormStatus,
    savingChartName,
  ]);

  const tableHeaderMapping: MetricsTableHeaderMapping[] = useMemo(() => {
    return [
      {columnId: 'date', label: 'Date', renderLabel: 'Date'},
      ...selectedConfig.map(metricConfig => ({
        columnId: metricConfig.chartLabel,
        label: `${metricConfig.chartLabel} ${
          metricConfig.unitLabel === 'Reporting currency'
            ? metricConfig.currency
            : metricConfig.unitLabel
        }`,
        renderLabel: () => (
          <Fragment>
            {metricConfig.chartLabel} <br /> (
            {metricConfig.unitLabel === 'Reporting currency'
              ? metricConfig.currency
              : metricConfig.unitLabel}
            )
          </Fragment>
        ),
      })),
      {columnId: 'emptyColumn', label: '', renderLabel: ''},
    ];
  }, [selectedConfig]);

  const chartTableData: MetricsChartDataItem[] = useMemo(() => {
    return metricsData.reduce((acc: MetricsChartDataItem[], dataItem) => {
      let chartDataItem: MetricsChartDataItem = {};
      dataItem.metrics.forEach(metric => {
        if (selectedConfig.some(ss => ss.chartLabel === metric.chartLabel)) {
          chartDataItem[metric.chartLabel] =
            metric.value !== null ? +metric.value : metric.value;
        }
      });
      if (Object.keys(chartDataItem).length === 0) return acc;
      chartDataItem.date = new Date(dataItem.date);

      const metrics: MetricsChartTooltipMetricItem[] = [];

      selectedConfig.forEach(configMetric => {
        const metric = dataItem.metrics.find(
          metric => metric.chartLabel === configMetric.chartLabel
        );

        if (metric) {
          metrics.push({
            ...metric,
            color: configMetric.color,
          });
        }
      });

      chartDataItem.metrics = metrics;

      return [...acc, chartDataItem];
    }, []);
  }, [metricsData, selectedConfig]);

  const handleClickExport = async () => {
    const serializableTableData = chartTableData.map(item => ({
      ...item,
      date: valueFormat(item.date as string, 'dd-MM-yyyy').value,
    }));
    const serializableHeaderMapping: HeaderMapping[] = tableHeaderMapping
      .filter(item => item.columnId !== 'emptyColumn')
      .map(item => ({
        columnId: item.columnId,
        label: item.label,
      }));
    handleExport(serializableTableData, serializableHeaderMapping);
  };

  return (
    <div id={id} className={styles.wrapper}>
      <SectionHeader
        childrenLeft={[
          ...(historicalData?.length > 0
            ? [
                <SelectDropdown
                  id={`${id}-select-dropdown-reports`}
                  label={t('Global.Reports')}
                  alignment={DropdownWrapperAlignmentType.Left}
                  onChange={value =>
                    handleChangeHistoricalTemplate(value as string)
                  }
                  options={historicalData ?? []}
                  value={selectedHistoricalTemplate}
                  disabled={isRequestPending} // should be true if request is pending
                />,
              ]
            : []),
        ]}
        childrenRight={[
          <SelectDropdown
            id={`${id}-select-dropdown-chart`}
            onChange={handleSetCurrentSavedConfig}
            options={dropDownOptions}
            value={currentChart}
            disabled={formStatus === FormStatus.Pending || isRequestPending}
            className={styles.dropdownWrapper}
          />,
          <Button
            id={`${id}-chart-save`}
            text={t(currentChart === 'new' ? 'Global.Save' : 'Global.Update')}
            icon={'save'}
            onClick={() => setIsSaveChartFormOpen(true)}
            disabled={
              selectedConfig.length === 0 ||
              formStatus === FormStatus.Pending ||
              isRequestPending
            }
          />,
          <Button
            id={`${id}-chart-delete`}
            text={t('Global.Delete')}
            styleType={ButtonStyle.Secondary}
            icon={'trash-can'}
            onClick={() => setIsDeleteChartFormOpen(true)}
            disabled={
              currentChart === 'new' ||
              formStatus === FormStatus.Pending ||
              isRequestPending
            }
          />,
          <IconButton
            id={`${id}-chart-export`}
            onClick={handleClickExport}
            styleType={ButtonStyle.Primary}
            icon="export"
            loading={isExportPending}
            disabled={formStatus === FormStatus.Pending || isRequestPending}
          />,
        ]}
        onClick={() => null}
        withActionButton={false}
        className={styles.monitoringSectionHeader}
      />
      <div
        className={classnames(
          styles.layout,
          isSideBarCollapsed && styles.collapsed
        )}
      >
        <div className={styles.sidebar}>
          <MetricsPanel
            data={metricsConfig.data}
            label={metricsConfig.label}
            onCollapse={() => setIsSideBarCollapsed(prev => !prev)}
            isCollapsed={isSideBarCollapsed}
            onChangeCheckbox={handleSetSelectedMetricsConfigItem}
            isRequestPending={isRequestPending}
          />
        </div>
        <div className={styles.main}>
          <MetricsChart
            id={`${id}-metrics-chart`}
            data={chartTableData}
            config={selectedConfig}
            onChangeController={handleUpdateMetricConfig}
            locale={locale}
            isRequestPending={isRequestPending}
            reportingInterval={metricsConfig.reportingInterval}
          />
          <MetricsTable
            id={`${id}-metrics-table`}
            data={chartTableData}
            config={selectedConfig}
            isRequestPending={isRequestPending}
            tableHeaderMapping={tableHeaderMapping}
          />
        </div>
      </div>
      {isSaveChartFormOpen ? (
        <SaveChartForm
          data={currentSelectedChart}
          isOpen={isSaveChartFormOpen}
          onSubmit={handleSubmitSaveChartMetricsConfig}
          onClose={handleCloseSaveModal}
          inProgress={formStatus === FormStatus.Pending}
          formError={formError}
          error={error}
          resetErrors={resetErrors}
        />
      ) : null}
      {isDeleteChartFormOpen && (
        <ConfirmationModal
          isOpen={isDeleteChartFormOpen}
          onClose={() =>
            formStatus === FormStatus.Ready && setIsDeleteChartFormOpen(false)
          }
          onConfirm={() => {
            handleDeleteSavedChartMetricsChartConfig(currentSelectedChart.id);
          }}
          message={`Are you sure you want to delete chart ${currentSelectedChart.name}?`}
          confirmButtonText="Delete chart"
          cancelButtonText="Cancel"
          isLoading={formStatus === FormStatus.Pending}
        />
      )}
    </div>
  );
};

export default MonitoringDashboard;
