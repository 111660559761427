import React from 'react';
import {TimeLineData} from '../../../types/index';
import TimelineWidget from '../../global/timeline-widget/TimelineWidget';
import styles from './FundTimelineDashboard.module.scss';

const FundTimelineDashboard: React.FC<TimeLineData> = ({
  widgets,
}): JSX.Element => {
  return (
    <div className={styles.wrapper}>
      {widgets?.map((widget, idx) => (
        <TimelineWidget
          key={idx}
          type={widget.type}
          headerData={widget.headerData}
          data={widget.data}
        />
      ))}
    </div>
  );
};

export default FundTimelineDashboard;
