import {DocumentLang} from '../types/locale';
import {changeLanguage} from 'i18next';

export const setDocumentLang = (langCode: string): void => {
  document.documentElement.lang = langCode;
  changeLanguage(langCode);
};

export const getDocumentLang = (): DocumentLang => {
  const locale = document.documentElement.lang;
  const camelCaseLocale = locale.replace('-', '');

  return {
    locale,
    camelCaseLocale,
  };
};
