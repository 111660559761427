import {proxy, wrap} from 'comlink';
import {BundledExportProps} from '../types';
import {downloadZippedExport, htmlToImage} from './exports';

export const exportsWorker = wrap<any>(
  new Worker(new URL('../exports.worker.js', import.meta.url))
);

export const downloadBundledExports = async ({
  zipFileName,
  tableExportPayload,
  imagesExportPayload,
}: BundledExportProps) => {
  const exportedData = await exportsWorker.exportZippedChartData(
    tableExportPayload,
    imagesExportPayload,
    proxy(htmlToImage)
  );

  downloadZippedExport({
    name: zipFileName,
    blob: exportedData,
  });
};
