import {createAction, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IPublicClientApplication} from '@azure/msal-browser';

export const aadLoginSuccessAction = createAction<string>('AAD_LOGIN_SUCCESS');

interface MsalAuth {
  msalInstance: IPublicClientApplication | null;
}

const initialState = {msalInstance: null} as MsalAuth;

const authSlice = createSlice({
  name: 'msal-auth',
  initialState,
  reducers: {
    setMsalInstance(
      state: MsalAuth,
      action: PayloadAction<IPublicClientApplication>
    ) {
      const {payload} = action;
      if (payload) {
        state.msalInstance = payload;
      }
    },
  },
});

export const {setMsalInstance} = authSlice.actions;
export default authSlice.reducer;
