import React, {useMemo, useState} from 'react';
import {InfoHeaderProps} from '../../global/info-header/InfoHeader';
import styles from './GeneralInformationDashboard.module.scss';
import {IconButton} from '../../global';
import {InfoHeader} from '../../fund-overview';
import {useTranslation} from 'react-i18next';
import {SectionHeader} from '../../dashboard';
import {Func} from '../../../types';

interface GeneralInformationDashboardProps extends InfoHeaderProps {
  onEditHandler: Func<[], void>;
  onExportHandler: Func<[string], void>;
}

const GeneralInformationDashboard: React.FC<
  GeneralInformationDashboardProps
> = ({data, onEditHandler, onExportHandler, ...restProps}) => {
  const {t} = useTranslation();
  const areAllValuesNullable = useMemo(() => {
    if (!data) return true;
    for (let i = 0; i < data.length; i++) {
      if (!!data[i].value) return false;
    }
    return true;
  }, [data]);

  const [isExporting, setIsExporting] = useState(false);

  const handleExport = async () => {
    try {
      const exportHandlerButtonInfo = 'generalInformation';
      setIsExporting(true);
      await onExportHandler(exportHandlerButtonInfo);
      setIsExporting(false);
    } catch (error) {
      setIsExporting(false);
      console.error(error);
    }
  };

  if (areAllValuesNullable) {
    return (
      <div className={styles.placeholder}>
        {t('Portfolio.CompanyDashboard.IconButton.Label.AddEditInfo')}
        {onEditHandler && (
          <IconButton
            className={styles.editButton}
            onClick={onEditHandler}
            icon={'pencil'}
          />
        )}
      </div>
    );
  }
  return (
    <>
      <SectionHeader
        onClick={() => null}
        className={styles.sectionHeader}
        withActionButton={false}
        childrenRight={[
          <IconButton onClick={onEditHandler} icon="pencil" />,
          <IconButton
            onClick={handleExport}
            icon="export"
            loading={isExporting}
          />,
        ]}
      />
      <InfoHeader data={data} className={styles.infoHeader} {...restProps} />
    </>
  );
};

export default GeneralInformationDashboard;
