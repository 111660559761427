import React, {Fragment} from 'react';
import classnames from 'classnames';
import styles from './ReportingTableStatusCell.module.scss';
import {ActivityBadge, Button, Icon, IconButton} from '../../../index';
import {ButtonSize, FormStatus, Func} from '../../../../../types';
import {useTranslation} from 'react-i18next';

type ReportingTableStatusCellProps = {
  readonly: boolean;
  isHistorical: boolean;
  cell: any;
  classNames?: string;
  onColumnSubmit?: Func<[columndId: string, submitted?: boolean], void>;
  onColumnUnlock?: Func<[columnId: string], void>;
  onColumnCancel?: Func<[columnId: string], void>;
  justifyContent?: 'flex-start' | 'center' | 'flex-end';
  columnUnlockingStatus?: FormStatus;
  activeUnlockedColumnId?: string | null;
};

const ReportingTableStatusCell = ({
  readonly,
  isHistorical,
  cell,
  classNames,
  onColumnSubmit,
  onColumnUnlock,
  justifyContent = 'flex-end',
  columnUnlockingStatus,
  activeUnlockedColumnId,
  onColumnCancel,
}: ReportingTableStatusCellProps) => {
  const {t} = useTranslation();

  return (
    <td
      className={classnames(styles.cell, {
        [`${classNames}`]: !!classNames,
        [styles.notVisible]: !cell.isVisible,
      })}
    >
      <div
        style={{justifyContent}}
        className={classnames(styles.cellInnerWrapper, {
          [styles.fakeColumn]: !cell?.data,
        })}
      >
        {cell?.data?.value &&
          (readonly ? (
            <ActivityBadge
              label={cell.data.value}
              color={
                cell.data.value === 'Overdue'
                  ? 'red'
                  : cell.data.value === 'Submitted'
                  ? 'lan-green'
                  : 'gray-7'
              }
              textColor={cell.data.value === 'Submitted' ? 'black' : 'white'}
              fontSize={14}
              fontWeight={400}
            />
          ) : (
            !!onColumnSubmit &&
            (cell.data.isLocked ? (
              <Fragment>
                {onColumnUnlock && (
                  <IconButton
                    loading={
                      columnUnlockingStatus === FormStatus.Pending &&
                      activeUnlockedColumnId === cell.columnId
                    }
                    className={styles.editPenButton}
                    onClick={() => {
                      onColumnUnlock(cell.columnId);
                    }}
                    buttonSize={ButtonSize.Small}
                    icon={'pencil'}
                  />
                )}
                <div className={styles.submitBadge}>
                  <Icon className={styles.submitBadgeIcon} name="check" />
                </div>
              </Fragment>
            ) : (
              <Fragment>
                {cell.data.submitted &&
                  !cell.data.isLocked &&
                  !!onColumnCancel && (
                    <IconButton
                      buttonSize={ButtonSize.Small}
                      className={styles.editPenButton}
                      onClick={() => onColumnCancel(cell.columnId)}
                      icon={'cross'}
                    />
                  )}
                <Button
                  size={ButtonSize.Small}
                  text={t(
                    !cell.data.submitted && !cell.data.isLocked
                      ? 'Global.Submit'
                      : 'Global.Update'
                  )}
                  onClick={() => {
                    onColumnSubmit(cell.columnId, cell.data?.submitted);
                  }}
                  disabled={!!cell?.disabled || isHistorical}
                />
              </Fragment>
            ))
          ))}
      </div>
    </td>
  );
};

export default ReportingTableStatusCell;
