import axios from 'axios';
import config from '../config';
import {ClientDashboard} from '../types/index';

export const getClientDashboard = async (token: string, page?: number) => {
  const {data} = await axios.get<ClientDashboard[]>(
    `${config.APP_URI}/dashboards`,
    {
      params: {
        page: page || 1,
      },

      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );
  return data[0];
};
