import {ColumnDef, createColumnHelper} from '@tanstack/react-table';
import {
  FlexHAlign,
  Func,
  HeaderMapping,
  NewsItemRow,
  UpdateNewsPayload,
} from '../../../../types';
import Th from '../cells/th/Th';
import Td from '../cells/td/Td';
import styles from './LatestNewsTable.module.scss';
import {Icon, ValueFormat} from '../../../';
import ImpactCell from './components/ImpactCell';
import ImpactTooltip from './components/ImpactTooltip';
import TitleCell from './components/TitleCell';
import ExpanderCell from '../cells/expander-cell/ExpanderCell';
import React from 'react';
import ResolvedCell from './components/ResolvedCell';
import FavoriteCell from './components/FavoriteCell';

export const genLatestNewsColumnDefs = (
  headerMapping: HeaderMapping[],
  onUpdateNews: Func<[UpdateNewsPayload], void>
): ColumnDef<NewsItemRow, any>[] => {
  const columnHelper = createColumnHelper<NewsItemRow>();
  return [
    columnHelper.accessor('title', {
      id: 'title',
      header: props => (
        <Th
          {...props}
          headerMapping={headerMapping}
          align={FlexHAlign.start}
          width={'min(55vw, 1400px)'}
        />
      ),
      cell: props => (
        <Td
          {...props}
          align={FlexHAlign.start}
          className={styles.cell}
          skipExtend={true}
        >
          <TitleCell
            title={props.row.original.title}
            externalResource={props.row.original.externalResource}
            summary={props.row.original.summary}
          />
        </Td>
      ),
    }),
    columnHelper.accessor('impact', {
      id: 'impact',
      header: props => (
        <Th {...props} headerMapping={headerMapping} align={FlexHAlign.start}>
          <ImpactTooltip />
        </Th>
      ),
      cell: props => (
        <Td
          {...props}
          align={FlexHAlign.start}
          className={styles.cell}
          skipExtend={true}
        >
          <ImpactCell
            value={props.row.original.impact}
            summary={props.row.original.impactSummary}
          />
        </Td>
      ),
    }),
    columnHelper.accessor('resolved', {
      id: 'resolved',
      header: props => (
        <Th {...props} headerMapping={headerMapping} align={FlexHAlign.start} />
      ),
      cell: props => (
        <Td
          {...props}
          align={FlexHAlign.start}
          className={styles.cell}
          skipExtend={true}
        >
          <ResolvedCell
            onUpdateNews={onUpdateNews}
            id={props.row.original.id}
            checked={props.row.original.resolved}
            resolvedTooltip={props.row.original.resolvedTooltip}
          />
        </Td>
      ),
    }),
    // columnHelper.accessor('company', {
    //   id: 'company',
    //   header: props => (
    //     <Th {...props} headerMapping={headerMapping} align={FlexHAlign.start} />
    //   ),
    //   cell: props => (
    //     <Td {...props} align={FlexHAlign.start} className={styles.cell} />
    //   ),
    // }),
    columnHelper.accessor(row => row.date?.value, {
      id: 'date',
      header: props => (
        <Th {...props} headerMapping={headerMapping} align={FlexHAlign.start} />
      ),
      cell: props => (
        <Td
          {...props}
          align={FlexHAlign.start}
          className={styles.cell}
          nestedAccessorFn={original => original.date}
          skipExtend={true}
        >
          <ValueFormat
            value={props.row.original.date.value}
            format={props.row.original.date.format}
          />
        </Td>
      ),
    }),
    columnHelper.accessor('favorite', {
      id: 'favorite',
      header: props => (
        <Th
          {...props}
          headerMapping={headerMapping}
          align={FlexHAlign.start}
          className={styles.starTh}
          width={'50px'}
        >
          <Icon name="star-filled" />
        </Th>
      ),
      cell: props => (
        <Td {...props} align={FlexHAlign.center} skipExtend={true}>
          <FavoriteCell
            onUpdateNews={onUpdateNews}
            id={props.row.original.id}
            favorite={props.row.original.favorite}
            favoriteTooltip={props.row.original.favoriteTooltip}
          />
        </Td>
      ),
    }),
    columnHelper.display({
      id: 'expander',
      header: props => (
        <Th
          {...props}
          align={FlexHAlign.center}
          width={'40px'}
          headerMapping={headerMapping}
        />
      ),
      cell: props => {
        const row = props.row;
        const isExpanded = row.getIsExpanded();

        return row.getCanExpand() ? (
          <Td {...props} align={FlexHAlign.center} canHide={false}>
            <ExpanderCell expanded={isExpanded} />
          </Td>
        ) : null;
      },
    }),
  ];
};

export default genLatestNewsColumnDefs;
