import React from 'react';
import {Icon} from '../../../../global';
import styles from './FundOnboarding.module.scss';

type FundOnboardingProps = {
  label: string | undefined;
};

const FundOnboarding: React.FC<FundOnboardingProps> = ({
  label,
}): JSX.Element => {
  return (
    <div className={styles.wrapper}>
      <Icon name={'onboarding'} />
      <p className={styles.infoText}>{label}</p>
    </div>
  );
};

export default FundOnboarding;
