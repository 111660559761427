import React from 'react';
import {Icon} from '../../../index';
import {Link} from 'react-router-dom';
import styles from './ChevronLinkCell.module.scss';

interface ChevronLinkCellProps {
  linkTo: string;
}
const ChevronLinkCell: React.FC<ChevronLinkCellProps> = ({linkTo}) => {
  return (
    <Link to={linkTo} className={styles.link}>
      <Icon name={'chevron-right'} />
    </Link>
  );
};

export default ChevronLinkCell;
