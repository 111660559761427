import {createSelector} from '@reduxjs/toolkit';
import {RootState} from '../store';

const userProfile = (state: RootState) => state.userProfile;

const userProfileSelector = createSelector([userProfile], userProfile => {
  const data = userProfile.data;
  const {id, userRole} = data || {};

  return {
    userId: id,
    userRole: userRole,
  };
});

export default userProfileSelector;
