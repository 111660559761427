import {ColumnDef, createColumnHelper} from '@tanstack/react-table';
import {
  ActiveCompanyData,
  FlexHAlign,
  TreemapChartNodeData,
} from '../../../../types';
import Th from '../cells/th/Th';
import Td from '../cells/td/Td';
import {getTableColumnTestAttr} from '../../../../utils/strings';
import React from 'react';
import {ValueFormat} from '../../index';
import i18n from '../../../../i18n';
import routePaths from '../../../../routePaths';
import NameCell from '../cells/name-cell/NameCell';
import {consolidatedCompanyColor} from '../../../../constants';

const genTreemapChartViewTableColumns = ({
  tileScaleLabel,
  colorLabel,
}: {
  tileScaleLabel?: string;
  colorLabel?: string;
}): ColumnDef<
  TreemapChartNodeData<
    Pick<ActiveCompanyData, 'id' | 'companyIcon' | 'fundColor'>
  >,
  any
>[] => {
  const columnHelper =
    createColumnHelper<
      TreemapChartNodeData<
        Pick<ActiveCompanyData, 'id' | 'companyIcon' | 'fundColor'>
      >
    >();
  const headerMapping = [
    {
      columnId: 'title',
      label: i18n.t('Global.PortfolioCompany'),
    },
    {
      columnId: 'tileScaleValue',
      label: tileScaleLabel ?? '',
    },
    {
      columnId: 'colorValue',
      label: colorLabel ?? '',
    },
  ];

  return [
    columnHelper.accessor(row => row.title, {
      id: 'title',
      header: props => (
        <Th headerMapping={headerMapping} {...props} align={FlexHAlign.start} />
      ),
      cell: props => (
        <Td
          {...props}
          align={FlexHAlign.start}
          id={getTableColumnTestAttr(headerMapping, props.column.id)}
        >
          <NameCell
            name={props?.getValue<string>()}
            icon={
              props.row.original.additionalData?.fundColor ??
              consolidatedCompanyColor
            }
            iconUrl={props.row.original.additionalData?.companyIcon ?? null}
            expanded={false}
            // TODO: Linking to Investment Overview for V1, later on according to subscription
            linkTo={`${routePaths.PORTFOLIO_COMPANIES}/${props.row.original.additionalData?.id}/investment-overview`}
          />
        </Td>
      ),
    }),
    columnHelper.accessor(row => row.tileScaleValue, {
      id: 'tileScaleValue',
      header: props => (
        <Th headerMapping={headerMapping} {...props} align={FlexHAlign.start} />
      ),
      cell: props => (
        <Td
          {...props}
          align={FlexHAlign.start}
          id={getTableColumnTestAttr(headerMapping, props.column.id)}
        >
          {props.row.original.tileScaleValue ? (
            <ValueFormat
              value={props.row.original.tileScaleValue}
              format={'auto'}
            />
          ) : (
            '-'
          )}
        </Td>
      ),
    }),
    columnHelper.accessor(row => row.colorValue, {
      id: 'colorValue',
      header: props => (
        <Th headerMapping={headerMapping} {...props} align={FlexHAlign.start} />
      ),
      cell: props => (
        <Td
          {...props}
          align={FlexHAlign.start}
          id={getTableColumnTestAttr(headerMapping, props.column.id)}
        >
          {props.row.original.colorValue ? (
            <ValueFormat
              value={props.row.original.colorValue}
              format={'auto'}
            />
          ) : (
            '-'
          )}
        </Td>
      ),
    }),
  ];
};

export default genTreemapChartViewTableColumns;
