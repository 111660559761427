import React, {useMemo} from 'react';
import {useAppDispatch} from '../../../hooks/useReduxHooks';
import {
  clearFundDetailsDataAction,
  clearCompanyDetailsDataAction,
} from '../../../actions';
import routePaths from '../../../routePaths';
import {SearchResult} from '../../../types/index';
import {SkeletonLoader} from '../../global';
import {Link} from 'react-router-dom';
import styles from './NavSearchResults.module.scss';
import {useTranslation} from 'react-i18next';

interface NavSearchResultsProps {
  isLoading: boolean;
  searchResults?: SearchResult;
  onClick: () => void;
  searchValue: string;
  isActive: boolean;
}

const NavSearchResults: React.FC<NavSearchResultsProps> = ({
  isLoading,
  searchResults,
  onClick,
  searchValue,
  isActive,
}) => {
  const dispatch = useAppDispatch();
  const {t} = useTranslation();

  const modifiedResults = useMemo(() => {
    return {
      fund: searchResults?.fund?.map(item => ({
        ...item,
        name: item.name.replace(
          new RegExp(searchValue, 'gi'),
          match => `<b class=${styles.highlight}>${match}</b>`
        ),
      })),
      company: searchResults?.company?.map(item => ({
        ...item,
        name: item.name.replace(
          new RegExp(searchValue, 'gi'),
          match => `<b class=${styles.highlight}>${match}</b>`
        ),
      })),
    };
  }, [searchResults, searchValue]);

  if (!isActive) return null;

  if (isLoading) {
    return (
      <div className={styles.dropdown}>
        {Array.from(Array(2), (_, i) => (
          <div key={i} className={styles.group}>
            <div className={styles.groupName}>
              <SkeletonLoader />
            </div>
            <ul className={styles.list}>
              {Array.from(Array(3), (_, i) => (
                <li key={i} className={styles.listItem}>
                  <SkeletonLoader className={styles.skeletonLoader} />
                </li>
              ))}
            </ul>
          </div>
        ))}
        <div className={styles.group}>
          <div className={styles.groupName} />
          <div className={styles.list}>
            <SkeletonLoader className={styles.skeletonLoader} />
          </div>
        </div>
      </div>
    );
  }

  if (
    modifiedResults.company?.length === 0 &&
    modifiedResults.fund?.length === 0
  ) {
    return (
      <div className={styles.dropdown}>
        <div className={styles.group}>
          <div className={styles.groupName} />
          <div className={styles.list}>
            {t('NavSearchResults.Title.NoResultsFor')}
            <b className={styles.highlight}>{searchValue}</b>
          </div>
        </div>
        <div className={styles.group}>
          <div className={styles.groupName} />
          <div className={styles.list}>
            <span className={styles.hint}>
              {t('NavSearchResults.HintDescription')}
            </span>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.dropdown}>
      {modifiedResults?.fund?.length && (
        <div className={styles.group}>
          <div className={styles.groupName}>Funds</div>
          <ul className={styles.list}>
            {modifiedResults?.fund?.map(item => (
              <li key={item.id} className={styles.listItem}>
                <Link
                  to={`${routePaths.FUNDS}/${item.id}/overview`}
                  onClick={() => {
                    dispatch(clearFundDetailsDataAction());
                    onClick();
                  }}
                >
                  <span dangerouslySetInnerHTML={{__html: item.name}} />
                </Link>
              </li>
            ))}
          </ul>
        </div>
      )}
      {modifiedResults?.company?.length && (
        <div className={styles.group}>
          <div className={styles.groupName}>Companies</div>
          <ul className={styles.list}>
            {modifiedResults?.company?.map(item => (
              <li key={item.id} className={styles.listItem}>
                <Link
                  // TODO: for V1 redirect to Investment Overview, later on access according to the subscription
                  to={`${routePaths.PORTFOLIO_COMPANIES}/${item.id}/investment-overview`}
                  onClick={() => {
                    dispatch(clearCompanyDetailsDataAction());
                    onClick();
                  }}
                >
                  <span dangerouslySetInnerHTML={{__html: item.name}} />
                </Link>
              </li>
            ))}
          </ul>
        </div>
      )}
      <div className={styles.group}>
        <div className={styles.groupName} />
        <div className={styles.resultsFooter}>
          <div className={styles.list}>
            {t('NavSearchResults.AllResultsTitle')}{' '}
            <b className={styles.highlight}>{searchValue}</b>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavSearchResults;
