import {createAction, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {
  DocumentTransactionData,
  FileEditFormOptions,
  FileEditFormPayload,
  FileItem,
  FileUploadContext,
  FormStatus,
  HeaderMapping,
  UploadDocuments,
  UploadDocumentsParams,
  UploadFilePayload,
} from '../types';

export const getUploadDocumentsDataAction = createAction(
  'upload-documents/get-upload-documents-data',
  (payload: UploadDocumentsParams) => ({
    payload,
  })
);

export const uploadDocumentsUploadFileAction = createAction(
  'upload-documents/upload-file',
  (payload: UploadFilePayload) => ({
    payload,
  })
);

export const uploadDocumentsDeleteFileAction = createAction(
  'upload-documents/delete-file',
  (
    id: string,
    fileUploadContext: FileUploadContext,
    transactions: string[]
  ) => ({
    payload: {
      id,
      fileUploadContext,
      transactions,
    },
  })
);

export const getUploadDocumentsFileEditOptionsAction = createAction(
  'upload-documents/get-upload-documents-file-edit-form-options',
  (id: string) => {
    return {
      payload: {
        id,
      },
    };
  }
);

export const uploadDocumentsDownloadFileAction = createAction(
  'upload-documents/download-file',
  (id: string, fileUploadContext: FileUploadContext) => {
    return {
      payload: {
        id,
        fileUploadContext,
      },
    };
  }
);

export const putUploadDocumentsFileEditUpdateAction = createAction(
  'upload-documents/put-upload-documents-file-edit-update',
  (payload: FileEditFormPayload) => {
    return {
      payload,
    };
  }
);

interface UploadDocumentsSlice {
  data: UploadDocuments | null;
  formStatus: FormStatus;
  error: string | null;
  formError: Record<string, string> | null;
  isRequestPending: boolean;
  fileEditFormOptions: FileEditFormOptions | null;
  downloadPendingIds: string[];
  isUploadDocumentsModalOpen: boolean;
}

const initialState: UploadDocumentsSlice = {
  data: null,
  formStatus: FormStatus.Ready,
  error: null,
  formError: null,
  isRequestPending: false,
  fileEditFormOptions: null,
  downloadPendingIds: [],
  isUploadDocumentsModalOpen: false,
};

const uploadDocumentsSlice = createSlice({
  name: 'upload-documents',
  initialState,
  reducers: {
    setUploadDocumentsData(
      state: UploadDocumentsSlice,
      action: PayloadAction<UploadDocuments>
    ) {
      state.data = action.payload;
    },
    updateUploadDocumentsData(
      state: UploadDocumentsSlice,
      action: PayloadAction<FileItem>
    ) {
      if (state.data) {
        state.data.data.data = [...state.data.data.data, action.payload];
      }
    },
    deleteUploadDocumentsFile(
      state: UploadDocumentsSlice,
      action: PayloadAction<{deleteBy: 'id' | 'fileName'; value: string}>
    ) {
      const removeFile = (fileItems: FileItem[]) =>
        fileItems.filter(
          file => file[action.payload.deleteBy] !== action.payload.value
        );

      if (state.data) {
        state.data.data.data = removeFile(state.data.data.data);
      }
    },
    setUploadDocumentsError(
      state: UploadDocumentsSlice,
      action: PayloadAction<string | null>
    ) {
      state.error = action.payload;
    },
    setUploadDocumentsFormStatus(
      state: UploadDocumentsSlice,
      action: PayloadAction<FormStatus>
    ) {
      state.formStatus = action.payload;
    },
    setUploadDocumentsFormError(
      state: UploadDocumentsSlice,
      action: PayloadAction<Record<string, string> | null>
    ) {
      state.formError = action.payload;
    },
    setUploadDocumentsIsRequestPending(
      state: UploadDocumentsSlice,
      action: PayloadAction<boolean>
    ) {
      state.isRequestPending = action.payload;
    },
    setUploadDocumentsFileEditOptionsData(
      state: UploadDocumentsSlice,
      action: PayloadAction<FileEditFormOptions | null>
    ) {
      state.fileEditFormOptions = action.payload;
    },
    setIsUploadDocumentsModalOpen(
      state: UploadDocumentsSlice,
      action: PayloadAction<boolean>
    ) {
      state.isUploadDocumentsModalOpen = action.payload;
    },
    addUploadDocumentsDownloadPendingId(
      state: UploadDocumentsSlice,
      action: PayloadAction<string>
    ) {
      state.downloadPendingIds = [...state.downloadPendingIds, action.payload];
    },
    removeUploadDocumentsDownloadPendingId(
      state: UploadDocumentsSlice,
      action: PayloadAction<string>
    ) {
      state.downloadPendingIds = [
        ...state.downloadPendingIds.filter(id => id !== action.payload),
      ];
    },
    updateUploadDocumentsFileData(
      state: UploadDocumentsSlice,
      action: PayloadAction<FileItem>
    ) {
      const updateFile = (fileItems: FileItem[]) =>
        fileItems.map(file => {
          if (file.id === action.payload.id) {
            return action.payload;
          }
          return file;
        });

      if (state.data) {
        state.data.data.data = updateFile(state.data.data.data);
      }
    },
    setDocumentExplorerFileTransactions(
      state: UploadDocumentsSlice,
      action: PayloadAction<{
        headerMapping: HeaderMapping[];
        data: DocumentTransactionData[];
      }>
    ) {
      if (state.fileEditFormOptions) {
        state.fileEditFormOptions.allTransactions = action.payload;
      }
    },
    setReportingPeriodsInfoFilesCount(
      state: UploadDocumentsSlice,
      action: PayloadAction<string[] | []>
    ) {
      action.payload.forEach((columnId: string) => {
        state.data?.reportingPeriods.forEach(period => {
          if (period.id === columnId) {
            period.files += 1;
          }
        });
      });
    },
  },
});

export default uploadDocumentsSlice.reducer;
export const {
  setUploadDocumentsData,
  updateUploadDocumentsData,
  deleteUploadDocumentsFile,
  setUploadDocumentsIsRequestPending,
  setUploadDocumentsError,
  setUploadDocumentsFormError,
  setUploadDocumentsFormStatus,
  setUploadDocumentsFileEditOptionsData,
  addUploadDocumentsDownloadPendingId,
  removeUploadDocumentsDownloadPendingId,
  updateUploadDocumentsFileData,
  setIsUploadDocumentsModalOpen,
  setDocumentExplorerFileTransactions,
  setReportingPeriodsInfoFilesCount,
} = uploadDocumentsSlice.actions;
