import React, {ReactElement} from 'react';
import {EntitiesData, Func, ButtonStyle} from '../../../types';
import {SectionHeader} from '../../dashboard';
import styles from './FundEntitiesDashboard.module.scss';
import {IconButton} from '../../global';

type FundEntitiesDashboardProps = {
  data: EntitiesData;
  table: ReactElement;
  exportHandler?: Func<[void], void>;
  exportInProgress?: boolean;
};
const FundEntitiesDashboard: React.FC<FundEntitiesDashboardProps> = ({
  data,
  table,
  exportHandler = () => null,
  exportInProgress,
}): JSX.Element => {
  return (
    <div className={styles.wrapper}>
      <SectionHeader
        label={data.label}
        labelType={'large'}
        onClick={(): void => {}}
        withActionButton={false}
        className={styles.entitiesSectionHeader}
        childrenRight={[
          <IconButton
            onClick={exportHandler}
            styleType={ButtonStyle.Primary}
            icon="export"
            loading={exportInProgress}
            id="fund-entities-dashboard-export-btn"
          />,
        ]}
      />
      {/* Entities table */}
      {table}
    </div>
  );
};

export default FundEntitiesDashboard;
