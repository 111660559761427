import {
  ColumnOrderState,
  HeaderMapping,
  InfoHeaderType,
  InfoHeaderTypeNames,
  TableMeta,
} from '../types';

interface TransformedDataItem {
  [key: string]: any;
}

interface HeaderMappingInfoHeaderExport extends HeaderMapping {
  type?: InfoHeaderTypeNames;
}

export const generateHeaderMappings = (
  dataArray: InfoHeaderType[]
): HeaderMappingInfoHeaderExport[] => {
  return dataArray.reduce((acc: HeaderMappingInfoHeaderExport[], item) => {
    if (item.label) {
      const columnId = labelToCamelCase(item.label);
      if (!acc.find(mapping => mapping.columnId === columnId)) {
        const mapping: HeaderMappingInfoHeaderExport = {
          columnId,
          label: item.label,
        };

        if ('type' in item && item.type) mapping.type = item.type;

        acc.push(mapping);
      }
    }
    return acc;
  }, []);
};

const labelToCamelCase = (label: string): string => {
  return label
    .toLowerCase()
    .replace(/[^a-zA-Z0-9]+(.)/g, (match, chr) => chr.toUpperCase());
};

export const bundleDataArrayToOneRow = (
  dataArray: InfoHeaderType[]
): TransformedDataItem[] => {
  const transformedObject = dataArray.reduce(
    (acc: TransformedDataItem, item) => {
      if (item.label && item.value) {
        const newKey = labelToCamelCase(item.label);
        acc[newKey] = item.value;
      }
      return acc;
    },
    {}
  );

  return [transformedObject];
};

export const generateTableColumnSettings = (
  headerMap: HeaderMappingInfoHeaderExport[]
): {
  columnOrder: ColumnOrderState;
  columns: TableMeta;
} => {
  const columnOrder = headerMap.map(item => item.columnId);

  const columns: TableMeta = {};
  headerMap.forEach(item => {
    columns[item.columnId] = {
      styling: {
        align:
          item?.type === InfoHeaderTypeNames.FinancialValue ? 'right' : 'left',
      },
      formatter: item.columnId === 'fiscalYearEnd' ? 'numFmt:dd-mm-yyyy' : '',
    };
  });

  return {columnOrder, columns};
};
