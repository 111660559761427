import {all, call, fork} from 'redux-saga/effects';
import routes from './routes';
import {connectedRouterSaga} from './sagas/connectedRouterSagas';
import {searchSagaWatcher} from './sagas/search/searchSaga';
import {getClientDashboardSagaWatcher} from './sagas/dashboard/getClientDashboardSaga';
import {fundDetailsOverviewSagaWatcher} from './sagas/fund-details/fundDetailsOverviewSaga';
import {fundDetailsOperationalSagaWatcher} from './sagas/fund-details/fundDetailsOperationalSaga';
import {fundDetailsStructureSagaWatcher} from './sagas/fund-details/fundDetailsStructureSaga';
import {fundDetailsPortfolioSagaWatcher} from './sagas/fund-details/fundDetailsPortfolioSaga';
import {fundDetailsNearCastingSagaWatcher} from './sagas/fund-details/fundDetailsNearCastingSaga';
import {getAllFundsSagaWatcher} from './sagas/funds/getAllFundsSaga';
import {allCompaniesSagaWatcher} from './sagas/companies/getAllCompaniesSaga';
import {fundDetailsSagaWatcher} from './sagas/fund-details/fundDetailsSaga';
import {companyDetailsSagaWatcher} from './sagas/company-details/companyDetailsSaga';
import {companyDetailsOverviewCompanySagaWatcher} from './sagas/company-details/companyDetailsCompanySaga';
import {companyDetailsInvestmentOverviewSagaWatcher} from './sagas/company-details/companyDetailsInvestmentOverviewSaga';
import {companyDetailsMonitoringSagaWatcher} from './sagas/company-details/companyDetailsMonitoringSaga';
import {userProfileSagaWatcher} from './sagas/user-profile/userLayoutPropertySaga';
import {clientAdminUsersSagaWatcher} from './sagas/clientAdmin/clientAdminUsersSaga';
import {clientAdminTemplateConfigSagaWatcher} from './sagas/clientAdmin/clientAdminTemplateConfigSaga';
import {clientAdminUserSagaWatcher} from './sagas/clientAdmin/clientAdminUserSaga';
import {clientAdminDataCollectionSagaWatcher} from './sagas/clientAdmin/clientAdminDataCollectionSaga';
import {fundDetailsPortfolioTransactionsSagaWatcher} from './sagas/fund-details/fundDetailsPortfolioTransactionsSaga';
import {userAnalyticsSagaWatcher} from './sagas/analytics/userAnalyticsSaga';
import {clientAdminWorkflowConfigSagaWatcher} from './sagas/clientAdmin/clientAdminWorkflowConfigSaga';
import {clientAdminTemplateConfigDetailsSagaWatcher} from './sagas/clientAdmin/clientAdminTemplateConfigDetailsSaga';
import {clientAdminPortfolioCompanyUsersSagaWatcher} from './sagas/clientAdmin/clientAdminPortfolioCompanyUsersSaga';
import {dataCollectionSagaWatcher} from './sagas/data-collection/DataCollectionSaga';
import {companyAdminReportingSagaWatcher} from './sagas/company-admin/CompanyAdminReportingSaga';
import {dataCollectionDetailsSagaWatcher} from './sagas/data-collection-details/DataCollectionDetailsSaga';
import {errorHandlerSagaWatcher} from './sagas/error/errorHandlerSaga';
import {fileUploadSagaWatcher} from './sagas/file-upload/fileUploadSaga';
import {documentExplorerSagaWatcher} from './sagas/documents/documentExplorerSaga';
import {reportingTableSagaWatcher} from './sagas/reporting-table-saga/ReportingTableSaga';
import {uploadDocumentsSagaWatcher} from './sagas/upload-documents/uploadDocumentsSaga';
import {scenarioDetailsSagaWatcher} from './sagas/scenario-details/scenarioDetailsSaga';
import {scenarioDetailsReportSagaWatcher} from './sagas/scenario-details/scenarioDetailsReportSaga';
import {scenarioDetailsMainInfoSagaWatcher} from './sagas/scenario-details/scenarioDetailsMainSaga';
import {scenarioDetailsValuationSagaWatcher} from './sagas/scenario-details/scenarioDetailsValuationSaga';
import {scenarioDetailsTransactionsSagaWatcher} from './sagas/scenario-details/scenarioDetailsTransactionsSaga';
import {ScenarioOnePageReportPortfolioSagaWatcher} from './sagas/scenario-one-page-report/scenarioOnePageReportPortfolioSaga';
import {ScenarioOnePageReportPortfolioPerformanceSagaWatcher} from './sagas/scenario-one-page-report/scenarioOnePageReportPortfolioPerformanceSaga';
import {ScenarioOnePageReportOverviewSagaWatcher} from './sagas/scenario-one-page-report/scenarioOnePageReportOverviewSaga';
import {ScenarioOnePageReportMainSagaWatcher} from './sagas/scenario-one-page-report/scenarioOnePageReportMainSaga';
import {userProfileServiceSagaWatcher} from './sagas/user-profile-service/userProfileServiceSaga';
import {filePreviewSagaWatcher} from './sagas/file-preview/filePreviewSaga';
/**
 * Root redux saga
 */
const rootSaga = function* rootSaga() {
  yield all([
    fork(searchSagaWatcher),
    fork(getClientDashboardSagaWatcher),
    fork(fundDetailsOverviewSagaWatcher),
    fork(fundDetailsOperationalSagaWatcher),
    fork(fundDetailsPortfolioSagaWatcher),
    fork(fundDetailsNearCastingSagaWatcher),
    fork(fundDetailsStructureSagaWatcher),
    fork(fundDetailsPortfolioTransactionsSagaWatcher),
    fork(getAllFundsSagaWatcher),
    fork(allCompaniesSagaWatcher),
    fork(fundDetailsSagaWatcher),
    fork(companyDetailsSagaWatcher),
    fork(companyDetailsInvestmentOverviewSagaWatcher),
    fork(companyDetailsMonitoringSagaWatcher),
    fork(companyDetailsOverviewCompanySagaWatcher),
    fork(companyAdminReportingSagaWatcher),
    fork(userProfileSagaWatcher),
    fork(clientAdminUsersSagaWatcher),
    fork(clientAdminTemplateConfigSagaWatcher),
    fork(clientAdminWorkflowConfigSagaWatcher),
    fork(clientAdminTemplateConfigDetailsSagaWatcher),
    fork(clientAdminPortfolioCompanyUsersSagaWatcher),
    fork(clientAdminUserSagaWatcher),
    fork(clientAdminDataCollectionSagaWatcher),
    fork(userAnalyticsSagaWatcher),
    fork(dataCollectionSagaWatcher),
    fork(dataCollectionDetailsSagaWatcher),
    fork(errorHandlerSagaWatcher),
    fork(fileUploadSagaWatcher),
    fork(filePreviewSagaWatcher),
    fork(documentExplorerSagaWatcher),
    fork(uploadDocumentsSagaWatcher),
    fork(reportingTableSagaWatcher),
    fork(scenarioDetailsSagaWatcher),
    fork(scenarioDetailsReportSagaWatcher),
    fork(scenarioDetailsMainInfoSagaWatcher),
    fork(scenarioDetailsValuationSagaWatcher),
    fork(scenarioDetailsTransactionsSagaWatcher),
    fork(ScenarioOnePageReportPortfolioPerformanceSagaWatcher),
    fork(ScenarioOnePageReportPortfolioSagaWatcher),
    fork(ScenarioOnePageReportOverviewSagaWatcher),
    fork(ScenarioOnePageReportMainSagaWatcher),
    fork(userProfileServiceSagaWatcher),
    // connected router saga should be last in sequence!
    call(connectedRouterSaga, routes),
  ]);
};

export default rootSaga;
