import {useAppSelector} from './useReduxHooks';
import {documentsSelector} from '../selectors';

const useDocumentsData = () => {
  const state = useAppSelector(state => state);
  const data = documentsSelector(state);
  return data;
};

export default useDocumentsData;
