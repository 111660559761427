import React, {Fragment, SyntheticEvent, useEffect, useState} from 'react';
import {Icon} from '../index';
import {Func, CommonStyleProps, StyleVariant} from '../../../types';
import styles from './ColorSelector.module.scss';
import classnames from 'classnames';

type ColorSelectorProps = CommonStyleProps & {
  label?: string;
  colors: string[];
  selected?: string | true;
  onSelect: Func<[string], void>;
  variant?: StyleVariant;
  id?: string;
};

const ColorSelector = ({
  label,
  colors,
  selected,
  onSelect,
  style,
  classNames,
  variant = StyleVariant.Primary,
  id = '', // Use when there are multiple color selectors on a single page
}: ColorSelectorProps) => {
  const [selectedColor, setSelectedColor] = useState<string>('');
  // We have 3 potential states for initial selection on component mount
  // - selected prop is not set so none of the colors are selected
  // - selected prop is set to color which is in the colors array, so that color will be selected
  // - selected prop is set to true and color array has at least one item, so the first item of colors array is selected
  useEffect(() => {
    if (selected && typeof selected === 'string' && colors.includes(selected)) {
      setSelectedColor(selected);
      onSelect(selected);
      return;
    }

    if (selected === true && colors.length > 0) {
      const color = colors[0];
      setSelectedColor(color);
      onSelect(color);
    }
  }, [selected]);

  const onChangeHandler = (event: SyntheticEvent<HTMLInputElement>) => {
    const color = event.currentTarget.value;

    if (color) {
      setSelectedColor(color);
      onSelect(color);
    }
  };

  const genColorFromName = (name: string): string => {
    return `--colors-${name}`;
  };

  return (
    <div
      className={classnames(
        styles.wrapper,
        styles[`wrapper__${variant}`],
        classNames
      )}
      style={style}
    >
      <h1 className={styles.label}>{label}</h1>
      <div className={styles.colors}>
        {colors.map(color => {
          return (
            <Fragment key={color}>
              <input
                className={styles.input}
                type="radio"
                name={color + id}
                id={color + id}
                value={color}
                checked={selectedColor === color}
                onChange={onChangeHandler}
              />
              <label className={styles.inputLabel} htmlFor={color + id}>
                <span
                  className={styles.color}
                  style={{
                    backgroundColor: `rgba(var(${genColorFromName(color)}`,
                  }}
                ></span>
                <div className={styles.inputLabelOverlay}>
                  <Icon className={styles.checkIcon} name={'check'} />
                </div>
              </label>
            </Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default ColorSelector;
