import React from 'react';
import ValueFormat from '../../../value-format/ValueFormat';
import {Icon} from '../../../index';
import styles from './ValueWithDeltaCell.module.scss';

interface ValueWithDeltaCellProps {
  value?: string | number;
  format?: string;
  isUp?: boolean;
  active?: boolean;
}

const ValueWithDeltaCell = ({
  value,
  format,
  isUp,
  active = true,
}: ValueWithDeltaCellProps): JSX.Element => (
  <div className={styles.wrapper}>
    <span>
      <ValueFormat value={value} format={format} />
    </span>
    {active && (
      <span className={styles.delta}>
        {isUp ? (
          <Icon className={styles.icon} name={'stats-up'} />
        ) : (
          <Icon className={styles.icon} name={'stats-down'} />
        )}
      </span>
    )}
  </div>
);

export default ValueWithDeltaCell;
