import React from 'react';
import classnames from 'classnames';
import styles from './ReportingTableStatusDocumentsCell.module.scss';
import IconButton from '../../../icon-button/IconButton';
import {
  ButtonSize,
  ButtonStyle,
  ReportingTableCell,
  ReportingTableOnGetReportingPeriodDocuments,
} from '../../../../../types';

type ReportingTableStatusDocumentsCellProps = {
  cell: ReportingTableCell;
  classNames: string;
  textAlign?: 'left' | 'center' | 'right';
  onGetReportingPeriodDocuments: ReportingTableOnGetReportingPeriodDocuments;
};

const ReportingTableStatusDocumentsCell = ({
  cell,
  classNames,
  textAlign = 'right',
  onGetReportingPeriodDocuments,
}: ReportingTableStatusDocumentsCellProps) => {
  const documentsQuantity = cell?.data?.value;

  const handleGetReportingPeriodDocuments = () => {
    onGetReportingPeriodDocuments(cell.columnId);
  };

  return (
    <td
      headers="blank"
      style={{textAlign}}
      className={classnames(styles.cell, {
        [`${classNames}`]: !!classNames,
        [styles.notVisible]: !cell.isVisible,
      })}
    >
      <div
        className={classnames(styles.cellInnerWrapper, {
          [styles.fakeColumn]: !cell?.data,
        })}
      >
        {cell?.data && (
          <div className={styles.submissionDocumentswrapper}>
            <div className={styles.documents}>
              <IconButton
                onClick={handleGetReportingPeriodDocuments}
                styleType={ButtonStyle.Primary}
                buttonSize={ButtonSize.Small}
                disabled={!documentsQuantity}
                icon="document"
              />
              {documentsQuantity > 0 && <span>{documentsQuantity}</span>}
            </div>
            <IconButton
              onClick={handleGetReportingPeriodDocuments}
              styleType={ButtonStyle.Secondary}
              buttonSize={ButtonSize.Small}
              icon="plus"
            />
          </div>
        )}
      </div>
    </td>
  );
};

export default ReportingTableStatusDocumentsCell;
