import React from 'react';
import {CustomAxisTickProps} from '../../../types/index';

type XAxisProps = Pick<
  CustomAxisTickProps,
  | 'x'
  | 'y'
  | 'payload'
  | 'xOffset'
  | 'yOffset'
  | 'showAxis'
  | 'fill'
  | 'fontSize'
  | 'isXSmall'
  | 'fontWeight'
>;

const CustomXAxisTick: React.FC<XAxisProps> = ({
  x,
  y,
  payload,
  xOffset = 0,
  yOffset = 0,
  showAxis = false,
  fill,
  fontSize = 12,
  isXSmall,
  fontWeight = 400,
}): JSX.Element => {
  return (
    <g transform={`translate(${x},${y})`}>
      {showAxis && (
        <text
          x={xOffset}
          y={yOffset}
          textAnchor="middle"
          fill={`rgb(var(--colors-${fill}))`}
          fontSize={fontSize}
          fontWeight={fontWeight}
        >
          {payload?.value}
        </text>
      )}
    </g>
  );
};

export default CustomXAxisTick;
