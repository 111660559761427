import React from 'react';
import {ColumnDef, createColumnHelper} from '@tanstack/react-table';
import Th from '../../cells/th/Th';
import Td from '../../cells/td/Td';
import NameCell from '../../cells/name-cell/NameCell';
import {
  FlexHAlign,
  HeaderMapping,
  InvestedFundTransactionData,
  InvestmentsTransactionsRow,
} from '../../../../../types/index';
import styles from '../TransactionsTable.module.scss';
import IconButton from '../../../icon-button/IconButton';
import Checkbox from '../../../checkbox/Checkbox';

const genFundTransactionsTableColumnDefs = (
  headerMapping: HeaderMapping[],
  footerMapping: any[],
  currentSelectedTransactions: InvestedFundTransactionData[],
  allCheckboxState: {
    checked: boolean;
    isIndeterminate: boolean;
  },
  handleTransactionSelection: (
    checked: boolean,
    transaction?: InvestedFundTransactionData
  ) => void,
  handleOpenUploadDocumentsModal: (
    transaction: InvestedFundTransactionData
  ) => void
): ColumnDef<InvestmentsTransactionsRow, any>[] => {
  const columnHelper = createColumnHelper<InvestmentsTransactionsRow>();
  return [
    columnHelper.accessor('isSelected', {
      id: 'isSelected',
      header: props => (
        <th className={styles.checkboxWrapper}>
          <Checkbox
            label={''}
            checked={allCheckboxState.checked}
            isIndeterminate={allCheckboxState.isIndeterminate}
            onChange={e => handleTransactionSelection(e.target.checked)}
          />
        </th>
      ),
      cell: props => (
        <Td
          {...props}
          align={FlexHAlign.start}
          canHide={false}
          skipExtend={true}
        >
          <Checkbox
            onChange={e =>
              handleTransactionSelection(e.target.checked, props.row.original)
            }
            label={''}
            checked={
              currentSelectedTransactions &&
              currentSelectedTransactions.some(
                item => item.id === props.row.original.id
              )
            }
          />
        </Td>
      ),
      footer: props => (
        <Th
          {...props}
          footerMapping={footerMapping}
          align={FlexHAlign.start}
          width={'max(100vw*0.01, 30px)'}
          className={styles.footerLabel}
        />
      ),
    }),
    columnHelper.accessor('fundName', {
      id: 'fundName',
      header: props => (
        <Th
          {...props}
          headerMapping={headerMapping}
          align={FlexHAlign.start}
          width={'max(100vw*0.21, 150px)'}
        />
      ),
      cell: props => (
        <Td
          {...props}
          align={FlexHAlign.start}
          width={'max(100vw*0.18, 150px)'}
          canHide={false}
          skipExtend={true}
        >
          <NameCell
            name={props?.getValue<string>()}
            color={
              props.row.original.fundColor
                ? props.row.original.fundColor
                : undefined
            }
            isActive={true}
            expanded={props.row.getIsExpanded()}
            skipExtend={true}
            className={styles.nameCell}
            linkTo={`/funds/${props.row.original.fundId}/overview`}
          />
        </Td>
      ),
      footer: props => (
        <Th
          {...props}
          footerMapping={footerMapping}
          align={FlexHAlign.start}
          width={'max(100vw*0.18, 150px)'}
          padding={'0 0 0 24px'}
          className={styles.footerLabel}
        />
      ),
    }),
    columnHelper.accessor('instrumentType', {
      id: 'instrumentType',
      header: props => (
        <Th {...props} headerMapping={headerMapping} align={FlexHAlign.start} />
      ),
      cell: props => (
        <Td {...props} align={FlexHAlign.start} skipExtend={true} />
      ),
      footer: props => (
        <Th
          {...props}
          footerMapping={footerMapping}
          align={FlexHAlign.start}
          width={'max(100vw*0.18, 150px)'}
          className={styles.footerLabel}
        />
      ),
    }),
    columnHelper.accessor('currency', {
      id: 'currency',
      header: props => (
        <Th {...props} headerMapping={headerMapping} align={FlexHAlign.start} />
      ),
      cell: props => (
        <Td {...props} align={FlexHAlign.start} skipExtend={true} />
      ),
      footer: props => (
        <Th
          {...props}
          footerMapping={footerMapping}
          align={FlexHAlign.start}
          width={'max(100vw*0.02, 96px)'}
          className={styles.footerLabel}
        />
      ),
    }),
    columnHelper.accessor(row => row.shares?.value ?? 0, {
      id: 'shares',
      header: props => (
        <Th {...props} headerMapping={headerMapping} align={FlexHAlign.end} />
      ),
      cell: props => (
        <Td
          {...props}
          align={FlexHAlign.end}
          nestedAccessorFn={original => original.shares}
          skipExtend={true}
        />
      ),
      footer: props => (
        <Th
          {...props}
          footerMapping={footerMapping}
          align={FlexHAlign.end}
          width={'max(100vw*0.18, 150px)'}
          className={styles.footerLabel}
        />
      ),
    }),
    columnHelper.accessor(row => row.date?.value, {
      id: 'date',
      header: props => (
        <Th headerMapping={headerMapping} {...props} align={FlexHAlign.end} />
      ),
      cell: props => (
        <Td
          {...props}
          align={FlexHAlign.end}
          nestedAccessorFn={original => original.date}
          skipExtend={true}
        />
      ),
      footer: props => (
        <Th
          {...props}
          footerMapping={footerMapping}
          align={FlexHAlign.end}
          width={'max(100vw*0.18, 150px)'}
          className={styles.footerLabel}
        />
      ),
    }),
    columnHelper.accessor(row => row.cost?.value ?? 0, {
      id: 'cost',
      header: props => (
        <Th {...props} headerMapping={headerMapping} align={FlexHAlign.end} />
      ),
      cell: props => (
        <Td
          {...props}
          align={FlexHAlign.end}
          nestedAccessorFn={original => original.cost}
          skipExtend={true}
        />
      ),
      footer: props => (
        <Th
          {...props}
          footerMapping={footerMapping}
          align={FlexHAlign.end}
          width={'max(100vw*0.18, 150px)'}
          className={styles.footerLabel}
        />
      ),
    }),
    columnHelper.accessor(row => row.realisedGains?.value ?? 0, {
      id: 'realisedGains',
      header: props => (
        <Th {...props} headerMapping={headerMapping} align={FlexHAlign.end} />
      ),
      cell: props => (
        <Td
          {...props}
          align={FlexHAlign.end}
          nestedAccessorFn={original => original.realisedGains}
          skipExtend={true}
        />
      ),
      footer: props => (
        <Th
          {...props}
          footerMapping={footerMapping}
          align={FlexHAlign.end}
          width={'max(100vw*0.18, 150px)'}
          className={styles.footerLabel}
        />
      ),
    }),
    columnHelper.accessor(row => row.investmentIncome?.value ?? 0, {
      id: 'investmentIncome',
      header: props => (
        <Th {...props} headerMapping={headerMapping} align={FlexHAlign.end} />
      ),
      cell: props => (
        <Td
          {...props}
          align={FlexHAlign.end}
          nestedAccessorFn={original => original.investmentIncome}
          skipExtend={true}
        />
      ),
      footer: props => (
        <Th
          {...props}
          footerMapping={footerMapping}
          align={FlexHAlign.end}
          width={'max(100vw*0.18, 150px)'}
          className={styles.footerLabel}
        />
      ),
    }),
    columnHelper.accessor('file', {
      id: 'file',
      header: props => (
        <Th {...props} headerMapping={headerMapping} align={FlexHAlign.end} />
      ),
      cell: props => (
        <Td
          {...props}
          align={FlexHAlign.end}
          skipExtend={true}
          emptyValue={'-'}
        />
      ),
      footer: props => (
        <Th
          {...props}
          footerMapping={footerMapping}
          align={FlexHAlign.start}
          width={'max(100vw*0.02, 96px)'}
          className={styles.footerLabel}
        />
      ),
    }),
    columnHelper.display({
      id: 'upload',
      header: props => <Th {...props} />,
      cell: props => (
        <Td
          {...props}
          align={FlexHAlign.start}
          canHide={false}
          skipExtend={true}
        >
          <IconButton
            className={styles.actionButton}
            onClick={() => handleOpenUploadDocumentsModal(props.row.original)}
            icon={'document'}
          />
        </Td>
      ),
    }),
  ];
};

export default genFundTransactionsTableColumnDefs;
