import React from 'react';
import styles from './ButtonSpinner.module.scss';
import {Icon} from '../index';
import classnames from 'classnames';

interface ButtonSpinnerProps {
  className?: string;
}

const ButtonSpinner: React.FC<ButtonSpinnerProps> = ({
  className,
}): JSX.Element => {
  return (
    <Icon name="ellipse" className={classnames(styles.wrapper, className)} />
  );
};

export default ButtonSpinner;
