import {createAction, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {
  ClientAdminPortfolioCompanyUsers,
  CompanyUser,
  FormStatus,
} from '../types';

export const updatePortfolioCompanyUserDataAction = createAction(
  'client-admin/portfolio-company-users/update-user-data',
  (payload: CompanyUser) => {
    return {
      payload,
    };
  }
);

export const deletePortfolioCompanyUserDataAction = createAction(
  'client-admin/portfolio-company-users/delete-user',
  (payload: {id: string}) => {
    return {
      payload,
    };
  }
);

export const resendInvitePortfolioCompanyUserDataAction = createAction(
  'client-admin/portfolio-company-users/resend-invite-user',
  (payload: {id: string}) => {
    return {
      payload,
    };
  }
);

export const reactivatePortfolioCompanyUserDataAction = createAction(
  'client-admin/portfolio-company-users/reactivate-user',
  (payload: {id: string}) => {
    return {
      payload,
    };
  }
);

interface ClientAdminPortfolioCompanyUsersData {
  data: ClientAdminPortfolioCompanyUsers | null;
  formStatus: FormStatus;
  isRequestPending: boolean;
  isReinvitePending: boolean;
}

const initialState: ClientAdminPortfolioCompanyUsersData = {
  data: null,
  formStatus: FormStatus.Ready,
  isRequestPending: false,
  isReinvitePending: false,
};

const clientPortfolioCompanyUsersSlice = createSlice({
  name: 'client-admin/portfolio-company-users',
  initialState,
  reducers: {
    setClientAminPortfolioCompanyUsersData(
      state: ClientAdminPortfolioCompanyUsersData,
      action: PayloadAction<ClientAdminPortfolioCompanyUsers>
    ) {
      state.data = action.payload;
    },
    clearClientAdminPortfolioCompanyUsersData(
      state: ClientAdminPortfolioCompanyUsersData
    ) {
      state.data = null;
    },
    updateClientAdminPortfolioCompanyUsersData(
      state: ClientAdminPortfolioCompanyUsersData,
      action: PayloadAction<CompanyUser>
    ) {
      if (state.data) {
        const updatedData = state.data.data.data.map(user => {
          if (user.id === action.payload.id) {
            return action.payload;
          }
          return user;
        });
        state.data.data.data = updatedData;
      }
    },
    setClientAdminPortfolioCompanyUsersFormStatus(
      state: ClientAdminPortfolioCompanyUsersData,
      action: PayloadAction<FormStatus>
    ) {
      state.formStatus = action.payload;
    },
    setClientAdminPortfolioCompanyUsersIsRequestPending(
      state: ClientAdminPortfolioCompanyUsersData,
      action: PayloadAction<boolean>
    ) {
      state.isRequestPending = action.payload;
    },
    setClientAdminPortfolioCompanyUsersIsReinvitePending(
      state: ClientAdminPortfolioCompanyUsersData,
      action: PayloadAction<boolean>
    ) {
      state.isReinvitePending = action.payload;
    },
  },
});

export default clientPortfolioCompanyUsersSlice.reducer;

export const {
  setClientAminPortfolioCompanyUsersData,
  clearClientAdminPortfolioCompanyUsersData,
  updateClientAdminPortfolioCompanyUsersData,
  setClientAdminPortfolioCompanyUsersFormStatus,
  setClientAdminPortfolioCompanyUsersIsRequestPending,
  setClientAdminPortfolioCompanyUsersIsReinvitePending,
} = clientPortfolioCompanyUsersSlice.actions;
