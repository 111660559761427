import {createAction, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {FundPredictOverview} from '../types';

export const getScenarioDetailsReportDataAction = createAction(
  'scenario-details-report/get-scenario-report-data',
  (
    companyId: string,
    companyType: string,
    isFundCurrency: boolean,
    investmentGroupId: string
  ) => {
    return {
      payload: {
        companyId,
        companyType,
        isFundCurrency,
        investmentGroupId,
      },
    };
  }
);

interface ScenarioDetailsReportingData {
  data: FundPredictOverview | null;
  isLoading: boolean;
}

const initialState: ScenarioDetailsReportingData = {
  data: null,
  isLoading: false,
};

const scenarioDetailsReportSlice = createSlice({
  name: 'scenario-details-report',
  initialState,
  reducers: {
    setScenarioDetailsReportData(
      state: ScenarioDetailsReportingData,
      action: PayloadAction<FundPredictOverview>
    ) {
      state.data = action.payload;
    },
    clearScenarioDetailsReportData(state: ScenarioDetailsReportingData) {
      state.data = null;
    },
    setLoading(state: ScenarioDetailsReportingData) {
      state.isLoading = !state.isLoading;
    },
  },
});

export const {
  setScenarioDetailsReportData,
  setLoading,
  clearScenarioDetailsReportData,
} = scenarioDetailsReportSlice.actions;

export default scenarioDetailsReportSlice.reducer;
