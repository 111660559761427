import React, {useState} from 'react';
import {
  ButtonSize,
  ButtonStyle,
  DropdownWrapperAlignmentType,
  MetricsChartType,
  MetricsControllerProps,
} from '../../../types';
import {SegmentedControlVariants} from '../segmented-control/SegmentedControl';
import styles from './MetricsController.module.scss';
import {Button, DropdownWrapper, IconButton, SegmentedControl} from '../index';
import ColorSelector from '../color-selector/ColorSelector';
import {useTranslation} from 'react-i18next';

const MetricsController: React.FC<MetricsControllerProps> = ({
  id,
  chartLabel,
  color,
  yAxisPosition,
  setYAxisLeft,
  chartType,
  setChartType,
  onRemove,
  onApplyColor,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedColor, setSelectedColor] = useState(color);
  const {t} = useTranslation();

  const metricColorOptions = [
    'green-haze',
    'pacific-blue',
    'flirt',
    'pigment-indigo',
    'turbo',
    'orange-peel',
    'chelsea-gem',
    'tropical-forest',
    'allports',
    'pompadour',
    'olive',
    'cinnamon',
  ];

  const handleApply = () => {
    onApplyColor(id, selectedColor);
    setIsDropdownOpen(false);
  };

  const handleCancel = () => {
    setSelectedColor(color);
    setIsDropdownOpen(false);
  };

  return (
    <div className={styles.wrapper}>
      <DropdownWrapper
        isOpen={isDropdownOpen}
        alignment={DropdownWrapperAlignmentType.Left}
        onClickCancel={handleCancel}
        className={styles.dropdownWrapper}
        customButton={() => (
          <button
            className={styles.legend}
            onClick={() => setIsDropdownOpen(true)}
          >
            <div
              style={{backgroundColor: `rgb(var(--colors-${selectedColor}))`}}
              className={styles.legendColor}
            />
            {chartLabel}
          </button>
        )}
        children={() => (
          <div className={styles.dropdownContent}>
            <ColorSelector
              id={id}
              label={t(
                'Portfolio.MonitoringDashboard.MetricsController.ColorSelector'
              )}
              colors={metricColorOptions}
              onSelect={col => setSelectedColor(col)}
              selected={selectedColor}
            />
            <div className={styles.dropdownActions}>
              <Button
                onClick={handleApply}
                text={t('Global.Apply')}
                className={styles.lightThemePrimaryButton}
              />
              <Button
                onClick={handleCancel}
                text={t('Global.Cancel')}
                styleType={ButtonStyle.Secondary}
                className={styles.lightThemeSecondaryButton}
              />
            </div>
          </div>
        )}
      />
      <IconButton
        icon={!yAxisPosition ? 'switch-left' : 'switch-right'}
        onClick={() => setYAxisLeft(!!yAxisPosition ? 0 : 1)}
        buttonSize={ButtonSize.Small}
      />
      <SegmentedControl
        id="metrics-chart-metrics-controller"
        options={[
          {
            id: 'line-chart',
            value: MetricsChartType.LineChart,
            icon: 'line-chart',
          },
          {
            id: 'area-chart',
            value: MetricsChartType.AreaChart,
            icon: 'area-chart',
          },
        ]}
        onChange={val => setChartType(val as MetricsChartType)}
        value={chartType}
        variant={SegmentedControlVariants.Secondary}
        buttonSize={ButtonSize.Small}
        className={styles.segmentedControl}
      />
      <IconButton
        icon="cross"
        onClick={onRemove}
        buttonSize={ButtonSize.Small}
      />
    </div>
  );
};

export default MetricsController;
