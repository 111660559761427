import React, {useMemo} from 'react';
import {valueFormat} from '../../../../utils';
import styles from './TreemapTooltip.module.scss';
import classnames from 'classnames';

const TreemapTooltip = (props: any) => {
  const {payload, colorLabel, tileScaleLabel} = props;

  const payloadHasLength = useMemo(() => !!payload?.length, [payload]);

  const colorValueFormatted = useMemo(
    () =>
      payloadHasLength
        ? valueFormat(payload[0].payload.colorValue, 'auto').value
        : '',
    [payload, payloadHasLength]
  );

  const tileScaleValueFormatted = useMemo(
    () =>
      payloadHasLength
        ? valueFormat(payload[0].payload.tileScaleValue, 'auto').value
        : '',
    [payload, payloadHasLength]
  );

  if (payloadHasLength) {
    return (
      <div className={classnames(styles.wrapper)}>
        <div className={styles.treemapTooltipTitleSection}>
          <p className={styles.name}>{payload[0].payload.title}</p>
        </div>

        <div className={styles.treemapTooltipInfoSection}>
          <p className={styles.name}>{tileScaleLabel}:</p>
          <p className={styles.value}>{tileScaleValueFormatted}</p>
        </div>

        <div className={styles.treemapTooltipInfoSection}>
          <p className={styles.name}>{colorLabel}:</p>
          <p className={styles.value}>{colorValueFormatted}</p>
        </div>
      </div>
    );
  }

  return null;
};

export default TreemapTooltip;
