import React, {Fragment, useMemo, useState} from 'react';
import classnames from 'classnames';
import {proxy} from 'comlink';
import {useCompanyDetails, useFundsDetails} from '../../../hooks';
import {
  PortfolioPerformanceWidgetProps,
  PortfolioPerformanceChartProps,
  StyleVariant,
  BundledExportProps,
  Func,
  ScenarioPortfolioPerformanceData,
} from '../../../types/index';
import {downloadZippedExport, htmlToImage} from '../../../utils';
import PerformanceTotalCostChart from './components/performance-total-cost-chart/PerformanceTotalCostChart';
import PerformanceRoiIrrChart from './components/permformance-roi-irr-chart/PerformanceRoiIrrChart';
import {InfoHeader} from '../../';
import PerformanceTotalValueChart from './components/performance-total-value-chart/PerformanceTotalValueChart';
import styles from './PerformanceWidget.module.scss';
import {exportsWorker} from '../../../utils/export';
import {useTranslation} from 'react-i18next';
import {NavLegendItem} from '../../fund-overview/fund-performance-widget/components/nav-custom-legend/NavCustomLegend';
export interface PerformanceWidgetChartProps
  extends PortfolioPerformanceChartProps {
  activeKpi: string;
  setActiveKpi: (id: string) => void;
  exportHandler: Func<[BundledExportProps], void>;
  currency?: string;
  styleType?: StyleVariant;
  entityName?: string;
  className?: string;
  isConsolidatedData?: boolean;
  id?: string;
  nearCastingData?: ScenarioPortfolioPerformanceData;
  showExport?: boolean;
  legendItems?: NavLegendItem[];
  customTooltipLabels?: {[key: string]: string};
}

const PerformanceWidget: React.FC<PortfolioPerformanceWidgetProps> = ({
  totalCost,
  roiIrr,
  headerData,
  totalValue,
  styleType = StyleVariant.Primary,
  isConsolidatedData = false,
  portfolioCompanyName,
  id = '',
  signOffDate,
}) => {
  const [activeKpi, setActiveKpi] = useState('total-cost');

  const {fundName} = useFundsDetails();
  const {companyName} = useCompanyDetails();
  const {t} = useTranslation();

  const getEntityForExportZipFile = useMemo(() => {
    if (portfolioCompanyName) {
      return portfolioCompanyName.split(' ').join('');
    }
    if (fundName) {
      return fundName.split(' ').join('');
    }
    if (companyName) {
      return companyName.split(' ').join('');
    }
    return t('Global.Performance');
  }, [fundName, companyName, portfolioCompanyName, t]);

  const exportHandler = async ({
    zipFileName,
    tableExportPayload,
    imagesExportPayload,
  }: BundledExportProps) => {
    const exportedData = await exportsWorker.exportZippedChartData(
      tableExportPayload,
      imagesExportPayload,
      proxy(htmlToImage)
    );

    downloadZippedExport({
      name: zipFileName,
      blob: exportedData,
    });
  };

  return (
    <div
      className={classnames(styles.wrapper, styles[`wrapper__${styleType}`])}
    >
      <InfoHeader
        data={headerData}
        className={classnames(styles.wrapper, styles[`wrapper__${styleType}`])}
        gridColumnWidth={180}
      />
      <Fragment>
        {activeKpi === 'total-cost' && (
          <PerformanceTotalCostChart
            {...totalCost}
            activeKpi={activeKpi}
            setActiveKpi={setActiveKpi}
            exportHandler={exportHandler}
            styleType={styleType}
            entityName={getEntityForExportZipFile}
            isConsolidatedData={isConsolidatedData}
            id={id}
            signOffDate={signOffDate}
          />
        )}
        {activeKpi === 'total-value' && (
          <PerformanceTotalValueChart
            {...totalValue}
            activeKpi={activeKpi}
            setActiveKpi={setActiveKpi}
            exportHandler={exportHandler}
            styleType={styleType}
            entityName={getEntityForExportZipFile}
            isConsolidatedData={isConsolidatedData}
            id={id}
            signOffDate={signOffDate}
          />
        )}
        {activeKpi === 'roi-irr' && (
          <PerformanceRoiIrrChart
            {...roiIrr}
            activeKpi={activeKpi}
            setActiveKpi={setActiveKpi}
            exportHandler={exportHandler}
            styleType={styleType}
            entityName={getEntityForExportZipFile}
            isConsolidatedData={isConsolidatedData}
            id={id}
            signOffDate={signOffDate}
          />
        )}
      </Fragment>
    </div>
  );
};

export default PerformanceWidget;
