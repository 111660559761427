import {
  add,
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInMonths,
  differenceInYears,
  eachDayOfInterval,
  format,
  getDaysInMonth,
  parse,
} from 'date-fns';
import {getDocumentLang} from './locale';
import {enGB, enUS} from 'date-fns/locale';
import {
  MaskedDateInputMask,
  SelectOption,
  DaysOfWeek,
  ValidMaskPatterns,
} from '../types';
import {DocumentLang, Locales} from '../types/locale';

export const appDateFncLocales: Record<string, Locale> = {enGB, enUS};

const localeDateFormats: Record<string, Record<string, string>> = {
  'MMM yyyy': {
    'en-GB': 'MMM yyyy',
    'en-US': 'MMM yyyy',
  },
  'MMM yy': {
    'en-GB': 'MMM yy',
    'en-US': 'MMM yy',
  },
  "MMM 'yy": {
    'en-GB': "MMM ''yy",
    'en-US': "MMM ''yy",
  },
  'MMMM yyyy': {
    'en-GB': 'MMMM yyyy',
    'en-US': 'MMMM yyyy',
  },
  MMMM: {
    'en-GB': 'MMMM',
    'en-US': 'MMMM',
  },
  'dd MMM': {
    'en-GB': 'dd MMM',
    'en-US': 'MMM dd',
  },
  'd MMM': {
    'en-GB': 'd MMM',
    'en-US': 'MMM d',
  },
  'dd-MM-yy': {
    'en-GB': 'dd-MM-yy',
    'en-US': 'MM-dd-yy',
  },
  'dd-MM-yyyy': {
    'en-GB': 'dd-MM-yyyy',
    'en-US': 'MM-dd-yyyy',
  },
};

const getLocalisedDateFormat = (formatStr: string): string => {
  const lang = getDocumentLang();
  const localisedDateFormat = localeDateFormats[formatStr] ?? false;

  if (localisedDateFormat) {
    const localFormat = localisedDateFormat[lang.locale];
    if (localFormat) return localFormat;

    throw new Error(
      `Localised date format not found for locale: ${lang.locale}`
    );
  }

  throw new Error(`Date format not specified for format string: ${formatStr}`);
};

export const formatWithLocale = (
  date: Date,
  formatStr: string = 'PP',
  lang?: DocumentLang
): any => {
  if (!lang) lang = getDocumentLang();
  let localisedFormatStr: string = '';

  try {
    localisedFormatStr = getLocalisedDateFormat(formatStr);
  } catch (e) {
    // Log the error for easier problem resolution
    console.warn(e);
  }

  return !!localisedFormatStr
    ? format(date, localisedFormatStr, {
        locale: appDateFncLocales[lang.camelCaseLocale],
      })
    : localisedFormatStr;
};

export const parseWithLocale = (dateStr: string, formatStr: string) => {
  let localisedFormatStr: string = 'dd-MM-yyyy';

  try {
    localisedFormatStr = getLocalisedDateFormat(formatStr);
  } catch (e) {
    // Log the error for easier problem resolution
    console.warn(e);
  }

  return parse(dateStr, localisedFormatStr, new Date());
};

export const getDaysOfWeek = (
  formatStr: 'E..EEE' | 'EEEE' | 'EEEEE' | 'EEEEEE' = 'EEEEE',
  lang?: DocumentLang
): DaysOfWeek => {
  if (!lang) lang = getDocumentLang();
  const locale = appDateFncLocales[lang.camelCaseLocale];
  const weekStartsOn = locale?.options?.weekStartsOn ?? 0;
  // Reference date which starts with Sunday, January 1, 2023,
  // We need it as weekStartsOn setting of date-fnc localisation set Sunday as its 0 index place
  const referenceDate = new Date(2023, 0, 1);
  const start: Date = add(referenceDate, {days: weekStartsOn});
  const end: Date = add(start, {days: 6});

  const daysInInterval = eachDayOfInterval({
    start,
    end,
  });

  const days: Array<string> = daysInInterval.map(day =>
    format(day, formatStr, {locale})
  );

  return {
    weekStartsOn,
    days,
  };
};

export const getLocalisedDateMaskPattern = (
  maskPattern: ValidMaskPatterns
): MaskedDateInputMask => {
  const lang: DocumentLang = getDocumentLang();
  const dashPatterns = {
    [Locales.enGB]: MaskedDateInputMask.dayMonthYearDashBetween,
    [Locales.enUS]: MaskedDateInputMask.monthDayYearDashBetween,
  };
  const dotPatterns = {
    [Locales.enGB]: MaskedDateInputMask.dayMonthYearDotBetween,
    [Locales.enUS]: MaskedDateInputMask.monthDayYearDotBetween,
  };
  const slashPatterns = {
    [Locales.enGB]: MaskedDateInputMask.dayMonthYearSlashBetween,
    [Locales.enUS]: MaskedDateInputMask.monthDayYearSlashBetween,
  };

  const maskPatterns: Record<
    ValidMaskPatterns,
    Record<Locales, MaskedDateInputMask>
  > = {
    [MaskedDateInputMask.dayMonthYearDashBetween]: dashPatterns,
    [MaskedDateInputMask.dayMonthYearDotBetween]: dotPatterns,
    [MaskedDateInputMask.dayMonthYearSlashBetween]: slashPatterns,
    [MaskedDateInputMask.monthDayYearDashBetween]: dashPatterns,
    [MaskedDateInputMask.monthDayYearDotBetween]: dotPatterns,
    [MaskedDateInputMask.monthDayYearSlashBetween]: slashPatterns,
  };

  const patternForLocale = maskPatterns[maskPattern]?.[lang.locale as Locales];
  if (!patternForLocale) {
    throw new Error(
      `No mask pattern found for locale: ${lang.locale} and pattern: ${maskPattern}`
    );
  }
  return patternForLocale;
};

const monthList = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const genMonthOptions = (): SelectOption[] => {
  return monthList.map(month => {
    return {
      id: month.toLowerCase(),
      label: month,
      value: month.toLowerCase(),
    };
  });
};

export const genDayOptions = (monthName: string): SelectOption[] => {
  const monthIndex = monthList
    .map(month => month.toLowerCase())
    .indexOf(monthName);

  const daysInMonth = getDaysInMonth(new Date(2023, monthIndex));

  return Array.from({length: daysInMonth}, (_, i) => {
    return {
      id: (i + 1).toString(),
      label: (i + 1).toString(),
      value: (i + 1).toString(),
    };
  });
};

export const getTimeAgoFormattedValue = (
  date: Date,
  lang?: DocumentLang
): string => {
  if (!lang) lang = getDocumentLang();
  const rtf = new Intl.RelativeTimeFormat(lang.locale, {
    localeMatcher: 'best fit', // other values: "lookup"
    numeric: 'auto', // other values: "auto"
    style: 'long', // other values: "short" or "narrow"
  });
  const today = new Date();
  const minutes = differenceInMinutes(date, today);
  if (Math.abs(minutes) < 60) {
    return rtf.format(minutes, 'minute');
  }
  const hours = differenceInHours(date, today);
  if (Math.abs(hours) < 24) {
    return rtf.format(hours, 'hour');
  }
  const days = differenceInDays(date, today);
  if (Math.abs(days) < 31) {
    return rtf.format(days, 'day');
  }
  const months = differenceInMonths(date, today);
  if (Math.abs(months) < 12) {
    return rtf.format(months, 'month');
  }
  const years = differenceInYears(date, today);
  return rtf.format(years, 'year');
};
