import {useEffect, useState} from 'react';
import {SchemaValidationErrors} from '../types';

function useIsSubmitDisabled(
  formError: Record<string, string> | null,
  errors: SchemaValidationErrors,
  error: string | null,
  isFormStateChanged: boolean
): {isSubmitDisabled: boolean; fieldError: boolean; nestedFieldError: boolean} {
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [fieldError, setFieldError] = useState(true);
  const [nestedFieldError, setNestedFieldError] = useState(true);

  useEffect(() => {
    let field = Object.keys(errors);

    const someFieldHasAnError = field.some(field => errors[field].message);

    setFieldError(someFieldHasAnError);

    const someFieldHasANestedError = field.some(
      field => Object.keys(errors[field].nestedFields).length
    );

    setNestedFieldError(someFieldHasANestedError);

    setIsSubmitDisabled(
      !!error ||
        !!formError ||
        !isFormStateChanged ||
        someFieldHasAnError ||
        someFieldHasANestedError
    );
  }, [formError, errors, error, isFormStateChanged]);

  return {
    isSubmitDisabled,
    fieldError,
    nestedFieldError,
  };
}

export default useIsSubmitDisabled;
