import {createAction, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {
  ClientAdminWorkflowConfiguration,
  ClientAdminWorkflowConfigurationOptions,
  CreatePCWorkflowConfigPayload,
  FormStatus,
  UpdateWorkflowPayload,
} from '../types';

interface ClientAdminWorkflowConfigSlice {
  data: ClientAdminWorkflowConfiguration | null;
  editOptions: ClientAdminWorkflowConfigurationOptions | null;
  editWorkflowFormStatus: FormStatus;
  error: string | null;
  formError: Record<string, string> | null;
}

export const getClientAdminWorkflowEditOptionsAction = createAction<string>(
  'client-admin/workflow-configuration/get-edit-options'
);

export const addAdminWorkflowConfigAction =
  createAction<CreatePCWorkflowConfigPayload>(
    'client-admin/workflow-configuration/add-workflow'
  );

export const updateAdminWorkflowConfigAction =
  createAction<UpdateWorkflowPayload>(
    'client-admin/workflow-configuration/update-workflow'
  );

const initialState: ClientAdminWorkflowConfigSlice = {
  data: null,
  editOptions: null,
  editWorkflowFormStatus: FormStatus.Ready,
  error: null,
  formError: null,
};

const clientAdminWorkflowConfigSlice = createSlice({
  name: 'client-admin/workflow-configuration',
  initialState,
  reducers: {
    setClientAdminWorkflowConfigData(
      state: ClientAdminWorkflowConfigSlice,
      action: PayloadAction<ClientAdminWorkflowConfiguration>
    ) {
      state.data = action.payload;
    },
    clearClientAdminWorkflowConfigData(state: ClientAdminWorkflowConfigSlice) {
      state.data = null;
    },
    setClientAdminWorkflowConfigEditOptions(
      state: ClientAdminWorkflowConfigSlice,
      action: PayloadAction<ClientAdminWorkflowConfigurationOptions | null>
    ) {
      state.editOptions = action.payload;
    },
    setClientAdminEditWorkflowFormStatus(
      state: ClientAdminWorkflowConfigSlice,
      action: PayloadAction<FormStatus>
    ) {
      state.editWorkflowFormStatus = action.payload;
    },
    setClientAdminEditWorkflowError(
      state: ClientAdminWorkflowConfigSlice,
      action: PayloadAction<string | null>
    ) {
      state.error = action.payload;
    },
  },
});

export default clientAdminWorkflowConfigSlice.reducer;

export const {
  setClientAdminWorkflowConfigData,
  clearClientAdminWorkflowConfigData,
  setClientAdminWorkflowConfigEditOptions,
  setClientAdminEditWorkflowFormStatus,
  setClientAdminEditWorkflowError,
} = clientAdminWorkflowConfigSlice.actions;
