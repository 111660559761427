import React, {useMemo} from 'react';
import {useFormState, useIsSubmitDisabled} from '../../../hooks';
import {Input, SelectInput, TextArea} from '../../global';
import styles from './ScenarioForm.module.scss';
import {valueFormat} from '../../../utils/value-format';
import {scenarioMainInfoFormSchema} from '../../../constants';
import PrevNextButtons from '../../prevn-next-buttons/PrevNextButtons';
import {useHistory, useParams} from 'react-router-dom';
import routePaths from '../../../routePaths';
import {
  NearCastingParams,
  NearCastingScenarioFormData,
  SelectOption,
  StyleVariant,
} from '../../../types';
import {addMonths, endOfQuarter} from 'date-fns/esm';
import {useTranslation} from 'react-i18next';
import {DatePickerDropdown} from '../../global/';
import isSameDay from 'date-fns/isSameDay';

const getEndDateOptions = (): SelectOption[] => {
  const currentDate = new Date();
  const currentQuarter = endOfQuarter(currentDate);
  const isEndOfQuarter = isSameDay(currentDate, currentQuarter);

  const quarterPlusOne = endOfQuarter(addMonths(currentQuarter, 3));
  const quarterPlusTwo = endOfQuarter(addMonths(quarterPlusOne, 3));
  return [
    ...(isEndOfQuarter
      ? []
      : [
          {
            id: 'currentQuarter',
            value: currentQuarter.toString(),
            label: valueFormat(
              currentQuarter.toString(),
              "QQ 'YY"
            ).value.toString(),
          },
        ]),
    {
      id: 'quarterPlusOne',
      value: quarterPlusOne.toString(),
      label: valueFormat(quarterPlusOne.toString(), "QQ 'YY").value.toString(),
    },
    {
      id: 'quarterPlusTwo',
      value: quarterPlusTwo.toString(),
      label: valueFormat(quarterPlusTwo.toString(), "QQ 'YY").value.toString(),
    },
  ];
};

interface ScenarioFormProps {
  startDate: Date;
  onSubmit: (formState: NearCastingScenarioFormData) => void;
  scenarioData: any;
  editMode: boolean;
  wasDuplicated: boolean;
}

const ScenarioForm = ({
  startDate,
  onSubmit,
  scenarioData,
  editMode,
  wasDuplicated,
}: ScenarioFormProps) => {
  const {t} = useTranslation();
  const history = useHistory();
  const params = useParams<NearCastingParams>();

  const endDateOptions = getEndDateOptions();

  const scenarioMainInfoInitialState = useMemo(
    // add types
    () =>
      editMode
        ? {
            name: scenarioData.scenarioName,
            startDate: new Date(scenarioData.startDate),
            endDate: endOfQuarter(new Date(scenarioData.endDate)),
            nci: scenarioData.notionalCarriedInterest?.toString() || '',
            description: scenarioData.scenarioDescription,
          }
        : {
            name: '',
            startDate: startDate,
            endDate: new Date(endDateOptions[0]?.value) || '',
            nci: '',
            description: '',
          },
    [scenarioData] // [data] existing scenario data in case of edit | duplicate
  );

  const {
    formState,
    isFormStateChanged,
    validate,
    errors,
    handleChange,
    setFormState,
  } = useFormState<NearCastingScenarioFormData>(
    scenarioMainInfoInitialState,
    scenarioMainInfoFormSchema
  );

  const {isSubmitDisabled} = useIsSubmitDisabled(
    null,
    errors,
    null,
    isFormStateChanged || editMode
  );

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (!isSubmitDisabled && formState.name && formState.endDate) {
      onSubmit(formState);
    }
  };

  const handleDateChange = (date: Date, type: string) => {
    if (type === 'start') {
      setFormState({
        ...formState,
        startDate: date,
      });
    } else {
      setFormState({
        ...formState,
        endDate: date,
      });
    }
  };

  const handleKeyDown = (e: any) => {
    const allowedChars = '.0123456789';
    const allowedControlChars = [
      'Backspace',
      'Delete',
      'Tab',
      'Enter',
      'Escape',
      'ArrowLeft',
      'ArrowRight',
    ];

    if (allowedChars.includes(e.key) || allowedControlChars.includes(e.key)) {
      setFormState({
        ...formState,
        nci: e.target.value,
      });
    } else {
      e.preventDefault();
    }
  };

  const handleClose = () => {
    const fundId = params?.fundId;
    history.push(`${routePaths.FUNDS}/${fundId}/near-casting`);
  };

  return (
    <form className={styles.formWrapper}>
      <div className={styles.formInputRowWrapper}>
        <div className={styles.formInputRow}>
          <div className={styles.formInputWrapper}>
            <Input
              name="name"
              value={formState.name}
              className={styles.formInput}
              onChange={handleChange}
              label={t('ScenarioDetailsMain.ScenarioForm.InputLabel.Scenario')}
              validate={validate}
              disabled={editMode && !wasDuplicated}
              errorMessage={t(errors.name.message || '')}
            />
          </div>
          <div className={styles.formInputWrapper}>
            <div className={styles.formInput}>
              <div className={styles.formInputLabel}>
                {t('ScenarioDetailsMain.ScenarioForm.DateLabel.Start')}
              </div>
              <DatePickerDropdown
                className={styles.dateInput}
                value={formState.startDate}
                setValue={date => handleDateChange(date as Date, 'start')}
                dateFormat="dd-MM-yyyy"
                disabled
              />
            </div>
          </div>
          <div className={styles.formInputWrapper}>
            <div className={styles.formInput}>
              <div className={styles.formInputLabel}>
                {t('ScenarioDetailsMain.ScenarioForm.DateLabel.End')}
              </div>
              <SelectInput
                options={endDateOptions}
                variant={StyleVariant.Secondary}
                handleSelect={date => {
                  handleDateChange(new Date(date), 'end');
                }}
                value={formState.endDate.toString()}
                disabled={editMode && !wasDuplicated}
                className={styles.dateInput}
              />
            </div>
          </div>
          <div className={styles.formInputWrapper}>
            <Input
              type="number"
              name="nci"
              value={formState.nci}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              className={styles.formInput}
              label={t('ScenarioDetailsMain.ScenarioForm.InputLabel.Carried')}
              validate={validate}
              errorMessage={t(errors.nci.message || '')}
            />
          </div>
        </div>
        <div className={styles.description}>
          <TextArea
            name="description"
            value={formState.description}
            onChange={handleChange}
            className={styles.descriptionInput}
            label={t('Global.Description')}
            validate={validate}
            errorMessage={t(errors.description.message || '')}
          />
        </div>
      </div>

      <PrevNextButtons
        handlePrimary={handleSubmit}
        handleSecondary={handleClose}
        primaryText={t('ScenarioDetailsMain.ScenarioForm.PrevNextButtons.Save')}
        secondaryText={t('Global.Close')}
        isSubmitDisabled={!formState.name || !formState.endDate}
      />
    </form>
  );
};

export default ScenarioForm;
