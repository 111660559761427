import React, {Fragment, useMemo, useState} from 'react';
import {getTime} from 'date-fns';
import {
  ActiveCompanyData,
  ActiveDashboardView,
  ChartDataFilterConfig,
  CompanyData,
  DataVisualisaitonChartType,
  Func,
  KpiFilterOption,
  PortfolioCompaniesChartData,
  QuarterlyDataAvailability,
} from '../../../types/index';
import {
  Button,
  CompaniesBarChartDashboard,
  CompaniesBubbleChartDashboard,
  CompaniesTreemapChartDashboard,
  CompanyWidget,
  InfoPlaceholder,
  LoadingOverlay,
  PerformingCompaniesStats,
  SectionHeader,
  SegmentedControl,
  SelectDropdown,
} from '../../';
import {
  barChartOption,
  bubbleOption,
  dashboardCompaniesSortingOptions,
  tileOption,
  treemapOption,
} from '../../../constants';
import CompanyWidgetSkeleton from '../company-widget/CompanyWidgetSkeleton';
import {useTranslation} from 'react-i18next';
import {SegmentedControlVariants} from '../../global/segmented-control/SegmentedControl';
import styles from './CompaniesDashboard.module.scss';

interface CompaniesDashboardProps extends CompanyData {
  id: string;
  onChangeCompaniesSort?: Func<[string, string], void>;
  onActionButtonClick?: Func<[void], void>;
  chartData: PortfolioCompaniesChartData;
  savedChartsFilterConfig: ChartDataFilterConfig[];
  quarterlyData: QuarterlyDataAvailability;
  releaseCompanyDataVisualisationEnabled: any;
  handleEditSavedChartFilterConfig: Func<
    [DataVisualisaitonChartType, KpiFilterOption[], ActiveCompanyData[]],
    void
  >;
}

const CompaniesDashboard: React.FC<CompaniesDashboardProps> = ({
  id,
  companyLabel,
  companyCount,
  performingCompanyStats,
  widgets,
  onChangeCompaniesSort,
  isLoading,
  onActionButtonClick = () => null,
  chartData,
  savedChartsFilterConfig,
  quarterlyData,
  handleEditSavedChartFilterConfig,
  releaseCompanyDataVisualisationEnabled,
}) => {
  const {t} = useTranslation();
  const [sortValue, setSortValue] = useState('irr-desc');
  const [activeView, setActiveView] = useState(ActiveDashboardView.Tiles);

  const handleChangeSort = (value: string) => {
    setSortValue(value);
    if (onChangeCompaniesSort) {
      const valueArr = value.split('-');
      onChangeCompaniesSort(valueArr[0], valueArr[1]);
    }
  };

  const renderCompaniesViewModeSwitchOptions = useMemo(() => {
    const renderTileOption = savedChartsFilterConfig?.length > 0;
    const renderBarChartOption = savedChartsFilterConfig.some(
      el => el.chartType === DataVisualisaitonChartType.BarChart
    );
    const renderTreemapOption = savedChartsFilterConfig.some(
      el => el.chartType === DataVisualisaitonChartType.TreemapChart
    );
    const renderbubbleOption = savedChartsFilterConfig.some(
      el => el.chartType === DataVisualisaitonChartType.BubbleChart
    );

    return [
      ...(renderTileOption ? [tileOption] : []),
      ...(renderBarChartOption ? [barChartOption] : []),
      ...(renderTreemapOption ? [treemapOption] : []),
      ...(renderbubbleOption ? [bubbleOption] : []),
    ];
  }, [savedChartsFilterConfig]);

  const chartVisible = useMemo(() => {
    const quarterlyEndTimestamp = getTime(new Date(quarterlyData.endDate));
    const getAsAtValue = (arr: KpiFilterOption[]): string =>
      arr.find(el => el.name === 'asAt')?.value as string;
    const barChartQuarter = getAsAtValue(chartData.savedBarChartFilter || []);
    const treemapChartQuarter = getAsAtValue(
      chartData.savedTreemapFilter || []
    );
    const bubbleChartQuarter = getAsAtValue(
      chartData.savedBubbleChartFilter || []
    );
    return {
      barChart: getTime(new Date(barChartQuarter)) <= quarterlyEndTimestamp,
      treemapChart:
        getTime(new Date(treemapChartQuarter)) <= quarterlyEndTimestamp,
      bubbleChart:
        getTime(new Date(bubbleChartQuarter)) <= quarterlyEndTimestamp,
    };
  }, [chartData, quarterlyData]);

  const editSavedChartFilterConfig = () => {
    const {chartType, filterConfig, data} =
      activeView === ActiveDashboardView.BarChart
        ? {
            chartType: DataVisualisaitonChartType.BarChart,
            filterConfig: chartData.savedBarChartFilter,
            data: chartData.savedBarChartData,
          }
        : activeView === ActiveDashboardView.TreemapChart
        ? {
            chartType: DataVisualisaitonChartType.TreemapChart,
            filterConfig: chartData.savedTreemapFilter,
            data: chartData.savedTreemapData,
          }
        : activeView === ActiveDashboardView.ScatteredBubbles
        ? {
            chartType: DataVisualisaitonChartType.BubbleChart,
            filterConfig: chartData.savedBubbleChartFilter,
            data: chartData.savedBubbleChartData,
          }
        : {
            chartType: null,
            filterConfig: null,
            data: null,
          };

    if (chartType && filterConfig && data) {
      handleEditSavedChartFilterConfig(chartType, filterConfig, data);
    }
  };

  const renderChartLoading = (
    <div className={styles.chartPlaceholder}>
      <LoadingOverlay />
    </div>
  );

  return (
    <section id={id} className={styles.wrapper}>
      <SectionHeader
        label={companyLabel}
        count={
          activeView === ActiveDashboardView.Tiles ? companyCount : undefined
        }
        buttonText={t('CompanyDashboard.SectionHeader.ShowAllCompanies')}
        buttonId={'dashboard-companies-show-all'}
        withActionButton={activeView === ActiveDashboardView.Tiles}
        onClick={onActionButtonClick}
        childrenLeft={[
          activeView === ActiveDashboardView.Tiles ? (
            <PerformingCompaniesStats
              performingCompanyStats={performingCompanyStats}
            />
          ) : null,
        ]}
        childrenRight={
          widgets.length > 1 && activeView === ActiveDashboardView.Tiles
            ? [
                <SelectDropdown
                  id={`${id}-select-dropdown-sorting`}
                  onChange={value => handleChangeSort(value as string)}
                  options={dashboardCompaniesSortingOptions}
                  value={sortValue}
                  label={t('Global.SortBy')}
                  fullContent
                />,
              ]
            : []
        }
        className={styles.sectionHeader}
      />
      {releaseCompanyDataVisualisationEnabled &&
      renderCompaniesViewModeSwitchOptions?.length > 0 ? (
        <SectionHeader
          onClick={() => null}
          withActionButton={false}
          childrenLeft={[
            <SegmentedControl
              id={`${id}-segmented-control-view-options`}
              options={renderCompaniesViewModeSwitchOptions}
              onChange={val => setActiveView(val as ActiveDashboardView)}
              value={activeView}
              variant={SegmentedControlVariants.Secondary}
            />,
          ]}
          childrenRight={[
            activeView !== ActiveDashboardView.Tiles ? (
              <Button
                text={t('Global.Edit')}
                onClick={() => editSavedChartFilterConfig()}
                id={'dashboard-companies-chart-edit'}
                disabled={
                  (activeView === ActiveDashboardView.BarChart &&
                    (chartData.savedBarChartDataRequestPending ||
                      !chartVisible.barChart)) ||
                  (activeView === ActiveDashboardView.TreemapChart &&
                    (chartData.savedTreemapDataRequestPending ||
                      !chartVisible.treemapChart)) ||
                  (activeView === ActiveDashboardView.ScatteredBubbles &&
                    (chartData.savedBubbleChartDataRequestPending ||
                      !chartVisible.bubbleChart))
                }
              />
            ) : null,
          ]}
          className={styles.secondarySectionHeader}
        />
      ) : null}

      {activeView === ActiveDashboardView.Tiles ? (
        <div className={styles.companiesSection}>
          {isLoading ? (
            <Fragment>
              {Array.from('1'.repeat(6)).map((_, i) => (
                <div key={i} className={styles.sectionWidget}>
                  <CompanyWidgetSkeleton />
                </div>
              ))}
            </Fragment>
          ) : (
            widgets?.map((widget, idx: number) => {
              return (
                <div key={idx} className={styles.sectionWidget}>
                  <CompanyWidget
                    {...widget}
                    sortingValue={sortValue.split('-')[0]}
                  />
                </div>
              );
            })
          )}
        </div>
      ) : null}
      {activeView === ActiveDashboardView.BarChart && (
        <div className={styles.chartDashboardContainer}>
          {chartData.savedBarChartDataRequestPending ? (
            renderChartLoading
          ) : chartVisible.barChart ? (
            <CompaniesBarChartDashboard
              data={chartData.savedBarChartData || []}
              filterData={chartData.savedBarChartFilter || []}
              barChartDataRequestPending={
                chartData.savedBarChartDataRequestPending
              }
              areFiltersDisabled={true}
            />
          ) : (
            <InfoPlaceholder
              icon={'info'}
              title={t('CompanyDashboard.ChartPlaceholder.Title')}
              subtitle={t('CompanyDashboard.ChartPlaceholder.Subtitle')}
            />
          )}
        </div>
      )}
      {activeView === ActiveDashboardView.TreemapChart && (
        <div className={styles.chartDashboardContainer}>
          {chartData.savedTreemapDataRequestPending ? (
            renderChartLoading
          ) : chartVisible.treemapChart ? (
            <CompaniesTreemapChartDashboard
              data={chartData.savedTreemapData || []}
              filterData={chartData.savedTreemapFilter || []}
              treemapDataRequestPending={
                chartData.savedTreemapDataRequestPending
              }
              areFiltersDisabled={true}
            />
          ) : (
            <InfoPlaceholder
              icon={'info'}
              title={t('CompanyDashboard.ChartPlaceholder.Title')}
              subtitle={t('CompanyDashboard.ChartPlaceholder.Subtitle')}
            />
          )}
        </div>
      )}
      {activeView === ActiveDashboardView.ScatteredBubbles && (
        <div className={styles.chartDashboardContainer}>
          {chartData.savedBubbleChartDataRequestPending ? (
            renderChartLoading
          ) : chartVisible.bubbleChart ? (
            <CompaniesBubbleChartDashboard
              data={chartData.savedBubbleChartData || []}
              filterData={chartData.savedBubbleChartFilter || []}
              bubbleChartDataRequestPending={
                chartData.savedBubbleChartDataRequestPending
              }
              areFiltersDisabled={true}
            />
          ) : (
            <InfoPlaceholder
              icon={'info'}
              title={t('CompanyDashboard.ChartPlaceholder.Title')}
              subtitle={t('CompanyDashboard.ChartPlaceholder.Subtitle')}
            />
          )}
        </div>
      )}
    </section>
  );
};

export default CompaniesDashboard;
